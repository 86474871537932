import { Control } from "./control";
import { EventHandler } from "..";

export enum IconPackage {
    Alt,
    Feater,
}

export class Icon extends Control {
    public package: IconPackage = IconPackage.Feater;
    public icon: string = "";

    public getComponentName(): string {
        return "IconComponent";
    }

    private _clickHandlers = new Set<EventHandler>();
    public addClickHandler(handler: EventHandler): void {
        this._clickHandlers.add(handler);
    }
    public removeClickHandler(handler: EventHandler): void {
        this._clickHandlers.delete(handler);
    }
    private notifyClickHandlers(): void {
        const args = {};
        for (const handler of this._clickHandlers) {
            handler(this, args);
        }
    }

    public click(): void {
        this.notifyClickHandlers();
    }
}
