import { Vue, Component } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ICompany, IOffice, IOfficeCreateDto, IOfficeUpdateDto, IUser } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import VaTable from "@/components/common/va-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { OfficeModal } from "./modals/office.modal";
import { getTableColumns } from "./settings-company-offices-defaults";

@Component({
    components: { BButton, VaTable, ModalComponent },
})
export default class SettingsCompanyOffices extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private offices: IOffice[] = [];

    private OfficeUseCase = this.$alt.system.usecase.createOfficeUseCase();

    private officeModal: OfficeModal;

    public constructor() {
        super();

        this.officeModal = new OfficeModal();
        this.officeModal.onCreate = this.create.bind(this);
        this.officeModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Offices, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Offices, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Offices, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectOffices();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected async openModalCreate(): Promise<void> {
        await this.officeModal.show();
    }

    protected async openModalUpdate(office: IOffice): Promise<void> {
        await this.officeModal.show(office);
    }

    private async confirmDelete(office: IOffice): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить филиал: "${office.info.name}"?`,
            "Удаление филиала",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(office);
        }
    }

    private async selectOffices(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.offices = await this.OfficeUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить филиалы:\n${e.message}`);
        }
    }

    private async selectOfficesForUser(): Promise<void> {
        const offices = await this.$alt.system.usecase
            .createEmployeeUseCase()
            .selectOffices(this.company.id, this.user.id);
        this.$info.setOffices(offices);
    }

    private async create(dto: IOfficeCreateDto): Promise<IOffice | null> {
        try {
            this.$alt.loader.show();
            const of = await this.OfficeUseCase.create(this.company.id, dto);
            await this.selectOffices();
            this.selectOfficesForUser(); // асинхронно
            this.$alt.toast.success("Филиал успешно создан.", "Создание");
            return of;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать филиал:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(office: IOffice, dto: IOfficeUpdateDto): Promise<IOffice | null> {
        try {
            this.$alt.loader.show();
            const of = await this.OfficeUseCase.update(this.company.id, office.id, dto);
            await this.selectOffices();
            this.selectOfficesForUser(); // асинхронно
            this.$alt.toast.success(`Филиал "${office.info.name}" успешно изменён.`, "Изменение");
            return of;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить филиал:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(office: IOffice): Promise<boolean> {
        try {
            this.$alt.loader.show();
            await this.OfficeUseCase.delete(this.company.id, office.id);
            await this.selectOffices();
            this.selectOfficesForUser(); // асинхронно
            this.$alt.toast.success(`Филиал "${office.info.name}" успешно удалён.`, "Удаление");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить филиал:\n${e.message}`);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }
}
