import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BTooltip } from "bootstrap-vue";
import VueSelect from "vue-select";
import { IShop, IEmployee, IUser, ISale, ISaleStage, SaleStageType } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";

@Component({
    components: { BTooltip, VueSelect, ControlComponent },
})
export default class SaleCreateHeader extends Vue {
    @Prop({ type: Boolean, required: true })
    private show!: boolean;

    @Prop({ type: Array, required: true })
    private shops!: IShop[];

    @Prop({ type: Object, default: () => null })
    private shop!: IShop;

    @Prop({ type: Array, required: true })
    private stages!: ISaleStage[];

    @Prop({ type: Object, default: () => null })
    private stage!: ISaleStage;

    @Prop({ type: Array, required: true })
    private employees!: IEmployee[];

    @Prop({ type: Object, default: () => null })
    private seller!: IEmployee;

    private user!: IUser;
    private shopCurrent: IShop | null = null;
    private stageCurrent: ISaleStage | null = null;
    private sellerCurrent: IEmployee | null = null;

    @Watch("show")
    private onShowChanged(show: boolean): void {
        if (show) {
            this.initValues();
        }
    }

    @Watch("shop")
    private onShopChanged(): void {
        if (this.shop && this.shop.id !== this.shopCurrent?.id) {
            this.shopCurrent = this.shop;
            this.changeShop(this.shop);
        }
    }

    @Watch("stage")
    private onStageChanged(): void {
        if (this.stage && this.stage.id !== this.stageCurrent?.id) {
            this.stageCurrent = this.stage;
            this.changeStage(this.stage);
        }
    }

    @Watch("seller")
    private onSellerChanged(): void {
        if (this.seller && this.seller.id !== this.sellerCurrent?.id) {
            this.sellerCurrent = this.seller;
            this.changeSeller(this.seller);
        }
    }

    private async initValues(): Promise<void> {
        this.user = await this.$info.getUser();
        this.shopCurrent = this.shop;
        this.stageCurrent = this.stage;
        this.sellerCurrent = this.seller;

        if (!this.shopCurrent) {
            const shop = this.shops && this.shops.length > 0 ? this.shops[0] : null;
            this.changeShop(shop);
        }

        if (!this.stageCurrent) {
            const stage = this.stages.find(s => s.type === SaleStageType.New) ?? null;
            this.changeStage(stage);
        }

        if (!this.sellerCurrent) {
            const seller = this.getSellerFirst();
            this.changeSeller(seller);
        }
    }

    private changeShop(shop: IShop | null): void {
        this.shopCurrent = shop;

        // TODO: проверить, имеет ли выбранный сотрудник права на данный магазин.
        // Если нет, выбрать другого или оставить поле пустым

        // const seller = this.getSellerFirst();
        // this.changeSeller(seller);

        this.$emit("change-shop", this.shopCurrent);
    }

    private changeStage(stage: ISaleStage | null): void {
        this.stageCurrent = stage;

        // TODO: проверить, имеет ли выбранный сотрудник права на данный магазин.
        // Если нет, выбрать другого или оставить поле пустым

        // const seller = this.getSellerFirst();
        // this.changeSeller(seller);

        this.$emit("change-stage", this.stageCurrent);
    }

    private getSellerFirst(): IEmployee | null {
        // TODO: сделать, сотрудники фитровались в зависимости от выбранного магазина и счёта
        return this.employees.find(emp => emp.user === this.user.id) ?? null;
    }

    private changeSeller(seller: IEmployee | null): void {
        this.sellerCurrent = seller;
        this.$emit("change-seller", this.sellerCurrent);
    }
}
