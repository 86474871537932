import { Validator } from "vee-validate";
import { IAccount, IEmployee, IEmployeeCreateDto, IEmployeeUpdateDto } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    CheckBox,
    Control,
    Panel,
    PasswordBox,
    PhoneBox,
    Select,
    TextBox,
} from "@core/components/alt-ui/controls";
import validators from "./validators";
import { EmployeeContext, makeEmptyPermissions } from "./employee-permissions";

Validator.extend("email-employee", validators.validateEmployeeEmailRule);

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface EmployeeModalContext extends EmployeeContext {
    employee?: IEmployee;
    // accounts: IAccount[];
}

export class EmployeeModal extends Modal<EmployeeModalContext> {
    private tbName!: TextBox;
    private tbOfficialName!: TextBox;
    private tbPosition!: TextBox;
    private phPhone!: PhoneBox;
    private tbEmail!: TextBox;
    private chbChangePassword!: CheckBox;
    private pbPassword!: PasswordBox;
    private btnEditPermissions!: Button;
    // private cbAccount!: Select<IAccount>;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context!: EmployeeModalContext;
    private employee: IEmployee | null = null;
    public onCreate: ((dto: IEmployeeCreateDto) => Promise<IEmployee | null>) | null = null;
    public onUpdate: ((employee: IEmployee, dto: IEmployeeUpdateDto) => Promise<IEmployee | null>) | null = null;
    public onEditPermissions: ((employee: IEmployee) => Promise<void>) | null = null;

    public constructor() {
        super("employee-modal", "");
        this.initializeControls();
    }

    public show(context: EmployeeModalContext): Promise<void> {
        this.context = context;
        this.employee = context.employee ?? null;
        this.title = context.employee ? "Изменение сотрудника" : "Новый сотрудник";
        this.initializeControls();
        if (this.employee) {
            this.populateControls(this.employee);
        }
        return super.show();
    }

    protected initializeControls(): void {
        this.tbName = new TextBox();
        this.tbOfficialName = new TextBox();
        this.tbPosition = new TextBox();
        this.tbEmail = new TextBox();
        this.phPhone = new PhoneBox();
        this.chbChangePassword = new CheckBox();
        this.pbPassword = new PasswordBox();
        this.btnEditPermissions = new Button();
        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();
        // this.cbAccount = new Select<IAccount>();

        //

        this.tbName.id = "employees.name";
        this.tbName.label = "Имя";
        this.tbName.validation = "required|max:40";
        this.tbName.text = "";

        this.tbOfficialName.id = "employees.officialName";
        this.tbOfficialName.label = "Официальное имя (для документов)";
        this.tbOfficialName.text = "";

        this.tbPosition.id = "employees.position";
        this.tbPosition.label = "Должность";
        this.tbPosition.validation = "required";
        this.tbPosition.text = "";

        this.phPhone.id = "employees.phone";
        this.phPhone.label = "Телефон";
        this.phPhone.text = "";

        this.tbEmail.id = "employees.email";
        this.tbEmail.label = "Почта (логин)";
        this.tbEmail.validation = "required|email|max:40|email-employee";
        this.tbEmail.text = "";

        this.chbChangePassword.id = "employees.change-password";
        this.chbChangePassword.text = "Установить новый пароль";
        this.chbChangePassword.value = false;
        this.chbChangePassword.visible = false;
        this.chbChangePassword.addValueChangedHandler(this.changeChangePassword.bind(this));

        this.pbPassword.id = "employees.password";
        this.pbPassword.label = "Пароль";
        this.pbPassword.validation = "required|min:6|max:40";
        this.pbPassword.text = "";
        this.pbPassword.visible = true;

        // this.cbAccount.id = "employees.account";
        // this.cbAccount.label = "Счет по умолчанию";
        // this.cbAccount.items = this.context?.accounts ?? [];
        // this.cbAccount.textField = e => e.info.name;
        // this.cbAccount.descriptionField = e => e.info.description;
        // this.cbAccount.selectedIndex = this.cbAccount.items.length > 0 ? 0 : -1;

        this.btnEditPermissions.id = "employees.permissions";
        this.btnEditPermissions.text = "Редактировать права доступа";
        this.btnEditPermissions.class = "w-full mt-0.75";
        this.btnEditPermissions.variant = "outline-primary";
        this.btnEditPermissions.visible = false;
        this.btnEditPermissions.addClickHandler(this.clickEditPermissions.bind(this));

        //

        this.btnCancel.id = "employees.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "employees.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(employee: IEmployee): void {
        this.tbName.text = employee.userRef.info.name;
        this.tbOfficialName.text = employee.userRef.info.officialName ?? "";
        this.tbPosition.text = employee.position;
        this.phPhone.text = employee.userRef.info.contacts.phone ?? "";
        const email = employee.userRef.info.contacts.email?.toLowerCase() ?? "";
        this.tbEmail.text = email;
        this.tbEmail.validation = `required|email|email-employee:${email}`;
        this.chbChangePassword.visible = true;
        this.chbChangePassword.value = false;
        this.pbPassword.visible = false;
        this.btnEditPermissions.visible = true;

        const accountIndex = this.context.accounts.findIndex(e => e.id === employee.defaultAccount?.id);
        // this.cbAccount.selectedIndex = accountIndex;
    }

    public get controls(): Control[] {
        return [
            this.tbName,
            this.tbOfficialName,
            this.tbPosition,
            this.phPhone,
            this.tbEmail,
            this.chbChangePassword,
            this.pbPassword,
            // this.cbAccount,
            this.btnEditPermissions,
            this.pnlFooter,
        ];
    }

    private changeChangePassword(sender: any, e: any): void {
        this.pbPassword.visible = e.value;
    }

    private async clickEditPermissions(sender: any, e: any): Promise<void> {
        if (this.onEditPermissions && this.employee) {
            this.hide();
            await this.onEditPermissions(this.employee);
        }
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.employee ? await this.updateEmployee(this.employee) : await this.createEmployee();

        if (result) {
            this.hide(result);

            if (this.onEditPermissions && !this.employee) {
                await this.onEditPermissions(result);
            }
        }
    }

    private async createEmployee(): Promise<IEmployee | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: IEmployeeCreateDto = {
            user: {
                name: this.tbName.text,
                officialName: this.tbOfficialName.text.length > 0 ? this.tbOfficialName.text : undefined,
                phone: this.phPhone.text.length > 0 ? this.phPhone.text : undefined,
                email: this.tbEmail.text,
                password: this.pbPassword.text,
            },
            settings: {
                // defaultAccount: this.cbAccount.selectedItem?.id,
                position: this.tbPosition.text,
            },
            permissions: makeEmptyPermissions(this.context),
        };

        return await this.onCreate(dto);
    }

    private async updateEmployee(employee: IEmployee): Promise<IEmployee | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IEmployeeUpdateDto = {
            user: {
                name: this.tbName.text,
                officialName: this.tbOfficialName.text.length > 0 ? this.tbOfficialName.text : undefined,
                phone: this.phPhone.text.length > 0 ? this.phPhone.text : undefined,
                email: this.tbEmail.text,
                password: this.pbPassword.text.length > 0 ? this.pbPassword.text : undefined,
            },
            settings: {
                // defaultAccount: this.cbAccount.selectedItem?.id,
                position: this.tbPosition.text,
            },
            // permissions: {},
        };

        return await this.onUpdate(employee, dto);
    }
}
