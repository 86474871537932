
















import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BLink } from "bootstrap-vue";
import { Link } from "./link";

@Component({
    name: "link-component",
    components: { BLink },
})
export default class LinkComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Link;

    public click(): void {
        this.handler.click();
    }
}
