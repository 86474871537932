import { Vue, Component } from "vue-property-decorator";
import { BTabs, BTab } from "bootstrap-vue";
import { Html } from "@core/components/alt-ui/controls";
import SettingsProfileGeneral from "./settings-profile-general.vue";
import SettingsProfilePassword from "./settings-profile-password.vue";
import SettingsProfileSocials from "./settings-profile-socials.vue";

@Component({
    components: {
        BTabs,
        BTab,
        SettingsProfileGeneral,
        SettingsProfilePassword,
        SettingsProfileSocials,
    },
})
export default class SettingsProfile extends Vue {
    private activeTab: number = 0;

    private get tabs(): any[] {
        return [
            {
                id: "profile",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.profile.profile") : "",
                //iconPack: "feather",
                icon: "UserIcon",
                component: "settings-profile-general",
            },
            {
                id: "security",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.profile.security") : "",
                //iconPack: "feather",
                icon: "LockIcon",
                component: "settings-profile-password",
            },
            {
                id: "socials",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.profile.socials") : "",
                //iconPack: "feather",
                icon: "Share2Icon",
                component: "settings-profile-socials",
            },
        ];
    }

    public created(): void {
        if (this.$route.params.id) {
            const index = this.tabs.findIndex((t: any) => t.id === this.$route.params.id);
            if (index >= 0) {
                this.activeTab = index;
            }
        }
    }

    public mounted(): void {
        this.initHeader();
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "settings-profile.header-title";
        hdrTitle.html = `<h2 class="m-0">${this.$i18n.t("main.settings.profile.title")}</h2>`;

        this.$info.ui.setHeaderControls([hdrTitle]);
    }

    private get isLargeScreen(): boolean {
        return this.$info.ui.windowWidth >= 768;
    }

    private changeTab(tabIndex: number): void {
        this.activeTab = tabIndex;

        if (tabIndex === 0) {
            this.$router.push({ name: "settings-profile" }).catch(() => {});
            return;
        }

        const tab = this.tabs[tabIndex];
        if (tab) {
            this.$router.push({ name: "settings-profile", params: { id: tab.id } }).catch(() => {});
        }
    }
}
