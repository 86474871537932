import { SalaryTableItemType } from "../salary-table";
import { SalaryOrderProcessor } from "./salary-order-processor";
import type { ISalaryProcessor } from "./salary-processor";
import { SalarySaleProcessor } from "./salary-sale-processor";
import { SalaryReservedProcessor } from "./salary-reserved-processor";

export { ISalaryProcessor };

export abstract class SalaryProcessorFactory {
    private static registry = [
        { type: SalaryTableItemType.Order, class: SalaryOrderProcessor },
        { type: SalaryTableItemType.Sale, class: SalarySaleProcessor },
        { type: SalaryTableItemType.Reserved, class: SalaryReservedProcessor },
    ];

    public static Create(type: SalaryTableItemType): ISalaryProcessor {
        for (const item of this.registry) {
            if (item.type === type) {
                return new item.class();
            }
        }

        throw new Error("Unknown salary processor type.");
    }
}
