import { ISmsUseCase, ISms, SmsType } from "@lib";
import { ISmsCreateDto, ISmsUpdateDto, ISmsUpdateTemplateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class SmsUseCase implements ISmsUseCase {
    public async select(companyId: string): Promise<ISms[]> {
        try {
            const response = await Api.get<ISms[]>(`/companies/${companyId}/templates/sms`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectByType(companyId: string, type: SmsType): Promise<ISms[]> {
        try {
            const params = { type };
            const response = await Api.get<ISms[]>(`/companies/${companyId}/templates/sms`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ISms> {
        try {
            const response = await Api.get<ISms>(`/companies/${companyId}/templates/sms/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ISmsCreateDto): Promise<ISms> {
        try {
            const response = await Api.post<ISms>(`/companies/${companyId}/templates/sms`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: ISmsCreateDto[]): Promise<ISms[]> {
        try {
            const response = await Api.post<ISms[]>(`/companies/${companyId}/templates/sms/many`, dtos);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: ISmsUpdateDto): Promise<ISms> {
        try {
            const response = await Api.put<ISms>(`/companies/${companyId}/templates/sms/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async updateTemplate(companyId: string, id: string, dto: ISmsUpdateTemplateDto): Promise<ISms> {
        try {
            const response = await Api.patch<ISms>(`/companies/${companyId}/templates/sms/${id}/template`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/templates/sms/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
