import { Tags } from "@core/components/alt-ui/controls";
import { FieldControl } from "../field-control";
import { Uuid } from "@/utils/uuid";

export class TagsFieldControl extends FieldControl<Tags> {
    protected convert(): Tags {
        const control = new Tags();
        control.id = `${this.field.id}_${Uuid.short()}`;
        control.label = this.field.title ?? "";
        control.placeholder = this.field.details1 ?? "";
        control.help = this.field.description ?? "";
        return control;
    }

    public get value(): any {
        return this.control.tags;
    }

    public set value(value: any) {
        this.control.tags = Array.isArray(value) ? value.map(i => String(i)) : [];
    }

    public getValueFormatted(): string {
        return this.control.tags.join(", ");
    }
}
