import { IWork, IWorkCreateDto, IWorkUpdateDto } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, TextBox } from "@core/components/alt-ui/controls";
import { WarrantyControl } from "@/@core/controls/warranty.control";

export class WorksModal extends Modal<IWork> {
    private tbSequence!: TextBox;
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    private wcWarranty!: WarrantyControl;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private work: IWork | null = null;
    public onCreate: ((dto: IWorkCreateDto) => Promise<IWork | null>) | null = null;
    public onUpdate: ((work: IWork, dto: IWorkUpdateDto) => Promise<IWork | null>) | null = null;

    public constructor() {
        super("works-modal", "");
        this.initializeControls();
    }

    public show(work?: IWork): Promise<void> {
        this.work = work ?? null;
        this.title = work ? "Изменение работы" : "Новая работа";
        this.initializeControls();

        if (work) {
            this.populateControls(work);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbSequence = new TextBox();
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.tbCost = new TextBox();
        this.tbPrice = new TextBox();
        this.wcWarranty = new WarrantyControl();

        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbSequence.id = "work.sequence";
        this.tbSequence.label = "Порядок";
        this.tbSequence.validation = "required|numeric|min_value:1|max_value:999";
        this.tbSequence.visible = false;

        this.tbName.id = "work.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.tbDescription.id = "work.description";
        this.tbDescription.label = "Описание";

        this.tbCost.id = "work.cost";
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "money|unsigned";

        this.tbPrice.id = "work.price";
        this.tbPrice.label = "Цена";
        this.tbPrice.validation = "required|money|unsigned";

        this.wcWarranty.id = "work.warranty";
        this.wcWarranty.label = "Гарантия";

        //

        this.btnCancel.id = "work.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "work.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(work: IWork): void {
        this.tbSequence.text = work.sequence.toString();
        this.tbSequence.visible = true;

        this.tbName.text = work.name ?? "";
        this.tbDescription.text = work.description ?? "";
        this.tbCost.text = work.cost?.toString() ?? "";
        this.tbPrice.text = work.price?.toString() ?? "";
        this.wcWarranty.value = work.warranty;
    }

    public get controls(): Control[] {
        return [
            this.tbSequence,
            this.tbName,
            this.tbDescription,
            this.tbCost,
            this.tbPrice,
            this.wcWarranty,
            this.pnlFooter,
        ];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const result = this.work ? await this.update(this.work) : await this.create();

        if (result) {
            this.hide(result);
        }
    }

    private async create(): Promise<IWork | null> {
        if (!this.onCreate) {
            return null;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const cost = costStr.length === 0 ? 0 : parseFloat(costStr);

        const priceStr = this.tbPrice.text.replace(",", ".");
        const price = parseFloat(priceStr);

        const dto: IWorkCreateDto = {
            name: this.tbName.text,
            description: this.tbDescription.text,
            cost: cost,
            price: price,
            warranty: this.wcWarranty.value,
        };

        return await this.onCreate(dto);
    }

    private async update(work: IWork): Promise<IWork | null> {
        if (!this.onUpdate) {
            return null;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const cost = costStr.length === 0 ? 0 : parseFloat(costStr);

        const priceStr = this.tbPrice.text.replace(",", ".");
        const price = parseFloat(priceStr);

        const dto: IWorkUpdateDto = {
            sequence: parseInt(this.tbSequence.text),
            name: this.tbName.text,
            description: this.tbDescription.text,
            cost: cost,
            price: price,
            warranty: this.wcWarranty.value,
        };

        return await this.onUpdate(work, dto);
    }
}
