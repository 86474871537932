import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BListGroup, BListGroupItem, BCardText, BTooltip } from "bootstrap-vue";
import { List } from "./list";
import { Control } from "./control";

@Component({
    name: "list-component",
    components: {
        BListGroup,
        BListGroupItem,
        BCardText,
        BTooltip,
        ControlComponent: () => import("./control.component"),
    },
})
export class ListComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: List;

    public clickItem(item: any): void {
        this.handler.clickItem(item);
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <b-list-group class={this.handler.class} style={this.handler.style}>
                    {this.renderItems()}
                </b-list-group>
            );
        } else {
            return <div />;
        }
    }

    private renderItems(): VNode[] {
        const items: VNode[] = [];

        for (let i = 0; i < this.handler.items.length; i++) {
            const item = this.handler.items[i];

            const classItem = this.handler.classItem ? this.handler.classItem(item, i) : "";
            const styleItem = this.handler.styleItem ? this.handler.styleItem(item, i) : "";

            items.push(
                <b-list-group-item
                    class={`flex ${classItem}`}
                    style={styleItem}
                    v-on:click={() => this.clickItem(item)}
                >
                    {this.renderLeft(item, i)}
                    <div class="flex-column align-items-start w-100">
                        {this.renderHeader(item, i)}
                        {this.renderContent(item, i)}
                        {this.renderFooter(item, i)}
                    </div>
                    {this.renderRight(item, i)}
                </b-list-group-item>,
            );
        }

        return items;
    }

    private renderLeft(item: any, index: number): VNode {
        if (!this.handler.left) {
            return <div />;
        }

        const classLeft = this.handler.classLeft ? this.handler.classLeft(item, index) : "";
        const styleLeft = this.handler.styleLeft ? this.handler.styleLeft(item, index) : "";

        const left = this.handler.left(item, index);
        if (left instanceof Control) {
            return <control-component class={classLeft} style={styleLeft} handler={left} />;
        } else {
            return (
                <b-card-text class={classLeft} style={styleLeft}>
                    {left}
                </b-card-text>
            );
        }
    }

    private renderRight(item: any, index: number): VNode {
        if (!this.handler.right) {
            return <div />;
        }

        const classRight = this.handler.classRight ? this.handler.classRight(item, index) : "";
        const styleRight = this.handler.styleRight ? this.handler.styleRight(item, index) : "";

        const right = this.handler.right(item, index);
        if (right instanceof Control) {
            return (
                <div class="mt-auto mb-auto ml-auto">
                    <control-component class={classRight} style={styleRight} handler={right} />
                </div>
            );
        } else {
            return (
                <div class="ml-auto">
                    <b-card-text class={classRight} style={styleRight}>
                        {right}
                    </b-card-text>
                </div>
            );
        }
    }

    private renderHeader(item: any, index: number): VNode {
        return (
            <div class="flex w-100 justify-content-between">
                {this.renderHeaderTitle(item, index)}
                {this.renderHeaderTools(item, index)}
            </div>
        );
    }

    private renderHeaderTitle(item: any, index: number): VNode {
        if (!this.handler.header) {
            return <div />;
        }

        const header = this.handler.header(item, index);
        if (!header) {
            return <div />;
        }

        const classHeader = this.handler.classHeader ? this.handler.classHeader(item, index) : "";
        const styleHeader = this.handler.styleHeader ? this.handler.styleHeader(item, index) : "";

        if (header instanceof Control) {
            return <control-component class={classHeader} style={styleHeader} handler={header} />;
        } else {
            return (
                <h5 class={`font-bold mb-0 ${classHeader}`} style={styleHeader}>
                    {header}
                </h5>
            );
        }
    }

    private renderHeaderTools(item: any, index: number): VNode {
        if (!this.handler.tools) {
            return <div />;
        }

        const items: VNode[] = [];

        const tools = this.handler.tools(item, index);
        for (const tool of tools) {
            items.push(<control-component handler={tool} />);
        }

        return <div>{items}</div>;
    }

    private renderContent(item: any, index: number): VNode {
        if (!this.handler.content) {
            return <div />;
        }

        const content = this.handler.content(item, index);
        if (!content) {
            return <div />;
        }

        const classContent = this.handler.classContent ? this.handler.classContent(item, index) : "";
        const styleContent = this.handler.styleContent ? this.handler.styleContent(item, index) : "";

        if (content instanceof Control) {
            return <control-component class={classContent} style={styleContent} handler={content} />;
        } else {
            const cl = this.handler.header ? "mt-0.5 mb-0" : "mt-0 mb-0";
            return (
                <b-card-text
                    class={`whitespace-pre-wrap overflow-wrap-anywhere ${cl} ${classContent}`}
                    style={styleContent}
                >
                    {content}
                </b-card-text>
            );
        }
    }

    private renderFooter(item: any, index: number): VNode {
        return (
            <div class="flex w-100 justify-content-between">
                {this.renderFooterLeft(item, index)}
                {this.renderFooterRight(item, index)}
            </div>
        );
    }

    private renderFooterLeft(item: any, index: number): VNode {
        if (!this.handler.footer) {
            return <div />;
        }

        const footer = this.handler.footer(item, index);
        if (!footer) {
            return <div />;
        }

        if (footer instanceof Control) {
            return <control-component handler={footer} />;
        } else {
            const cl = this.handler.header || this.handler.content ? "mt-0.5" : "";
            return <small class={`text-secondary ${cl}`}>{footer}</small>;
        }
    }

    // TODO: отличается от renderFooterLeft только классом font-italic
    private renderFooterRight(item: any, index: number): VNode {
        if (!this.handler.footerRight) {
            return <div />;
        }

        const footerRight = this.handler.footerRight(item, index);
        if (!footerRight) {
            return <div />;
        }

        if (footerRight instanceof Control) {
            return <control-component handler={footerRight} />;
        } else {
            const cl = this.handler.header || this.handler.content ? "mt-0.5" : "";
            return <small class={`text-secondary font-italic ${cl}`}>{footerRight}</small>;
        }
    }
}
