import { Vue, Component } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { Social, IAuthRegistrationResultDto, IAuthSocialsDto, CompanyType } from "@lib";
import { IAuthLoginResultDto } from "@lib";
import { AppException } from "@/exceptions";

export enum SocialAction {
    Registration = "r",
    Login = "l",
    Connection = "c",
}

@Component({
    components: { BButton },
})
export default class Socials extends Vue {
    public message = "Вход с помощью соцсети";

    private action: SocialAction | null = null;
    private social: Social | null = null;

    public async mounted(): Promise<void> {
        try {
            this.initParams();
            await this.initQuery();
        } catch (e: any) {
            this.message = e.message;
        }
    }

    private initParams(): void {
        this.action = (this.$route.params.action as SocialAction) ?? null;
        const actionStr = this.getActionText(this.action);

        this.social = (this.$route.params.social as Social) ?? null;
        const socialStr = this.getSocialName(this.social);

        this.message = `${actionStr} ${socialStr}`;
    }

    private getActionText(action: SocialAction | null): string {
        if (!action) {
            throw new AppException("Действие не указано");
        }

        switch (action) {
            case SocialAction.Registration:
                return "Регистрация с помощью";
            case SocialAction.Login:
                return "Вход с помощью";
            case SocialAction.Connection:
                return "Подключение к";
        }

        throw new AppException("Неизвестное действие");
    }

    private getSocialName(social: Social | null): string {
        if (!social) {
            throw new AppException("Соцсеть не указана");
        }

        switch (social) {
            case Social.Yandex:
                return "Яндекс ID";
            case Social.VK:
                return "VK ID";
            case Social.OK:
                return "Одноклассники";
            case Social.Google:
                return "Google";
        }

        throw new AppException("Неподдерживаемая соцсеть");
    }

    private initQuery(): Promise<void> {
        if (this.$route.query.error && this.$route.query.error_description) {
            //error=access_denied&error_reason=user_denied&error_description=User%20denied%20your%20request
            throw new AppException(this.$route.query.error_description as string);
        }

        switch (this.action) {
            case SocialAction.Registration:
                return this.register();
            case SocialAction.Login:
                return this.login();
            case SocialAction.Connection:
                return this.connect();
        }

        throw new AppException("Неизвестное действие");
    }

    private async register(): Promise<void> {
        try {
            this.$alt.loader.show();

            if (!this.action || !this.social) {
                return;
            }

            const socialUri = this.$router.resolve({
                name: "socials",
                params: { action: this.action, social: this.social },
            }).href;
            const redirectUri = `${window.location.origin}${socialUri}`;

            const companyType = this.$alt.getFromLocalStorage("companyType") as CompanyType | undefined;
            const partnerId = this.$alt.getFromLocalStorage("partner");
            const meta = this.$alt.getFromLocalStorage("meta");

            const dto: IAuthSocialsDto = {
                data: this.$route.query,
                redirectUri: redirectUri,
                companyType: companyType,
                partnerId: partnerId ?? undefined,
                meta: meta ? JSON.parse(meta) : undefined,
            };

            const usecase = this.$alt.system.usecase.createAuthSocialsUseCase();
            const result = await usecase.register(this.social, dto);
            this.applyRegistrationData(result);
            this.createYandexGoal();

            await this.$router.push({ name: "orders", query: { welcome: "" } });
        } catch (e: any) {
            this.message = e.message;
        } finally {
            this.$alt.loader.hide();
        }
    }

    // TODO: повтор кода со страницы регистрации
    private async applyRegistrationData(result: IAuthRegistrationResultDto): Promise<void> {
        this.$alt.removeFromLocalStorage("companyType");
        this.$alt.removeFromLocalStorage("partner");
        this.$alt.removeFromLocalStorage("meta");

        this.$alt.system.token.cleanData();
        this.$alt.system.token.setCompanyToken(result.accessToken);

        this.$info.setAuthInfo(result.data);
        this.$settings.setUserInfo(result.data.user.user.id);
        if (result.data.company) {
            this.$secure.grant(result.data.company.permissions);
        }
    }

    private async login(): Promise<void> {
        try {
            this.$alt.loader.show();

            if (!this.action || !this.social) {
                return;
            }

            const socialUri = this.$router.resolve({
                name: "socials",
                params: { action: this.action, social: this.social },
            }).href;
            const redirectUri = `${window.location.origin}${socialUri}`;

            const dto: IAuthSocialsDto = {
                data: this.$route.query,
                redirectUri: redirectUri,
            };

            const usecase = this.$alt.system.usecase.createAuthSocialsUseCase();
            const result = await usecase.login(this.social, dto);

            if (result.data.company) {
                // company token
                this.applyLoginCompanyData(result);
                // await this.$router.push({ name: "orders", query: { notification: "1" } });
                await this.$router.push({ name: "orders" });
            } else {
                // user-only token
                this.applyLoginUserOnlyData(result);
                await this.$router.push({ name: "switch-company" });
            }
        } catch (e: any) {
            this.message = e.message;
        } finally {
            this.$alt.loader.hide();
        }
    }

    // TODO: повтор кода со страницы входа
    private async applyLoginCompanyData(result: IAuthLoginResultDto): Promise<void> {
        this.$alt.removeFromLocalStorage("companyType");
        this.$alt.removeFromLocalStorage("partner");
        this.$alt.removeFromLocalStorage("meta");

        this.$alt.system.token.cleanData();
        this.$alt.system.token.setCompanyToken(result.accessToken);

        this.$info.setAuthInfo(result.data);
        this.$settings.setUserInfo(result.data.user.user.id);
        if (result.data.company) {
            this.$secure.grant(result.data.company.permissions);
        }
    }

    // TODO: повтор кода со страницы входа
    private async applyLoginUserOnlyData(result: IAuthLoginResultDto): Promise<void> {
        this.$alt.removeFromLocalStorage("companyType");
        this.$alt.removeFromLocalStorage("partner");
        this.$alt.removeFromLocalStorage("meta");

        this.$alt.system.token.cleanData();
        this.$alt.system.token.setUserOnlyToken(result.accessToken);

        this.$info.setAuthInfo(result.data);
        this.$settings.setUserInfo(result.data.user.user.id);
    }

    private async connect(): Promise<void> {
        try {
            this.$alt.loader.show();

            if (!this.action || !this.social) {
                return;
            }

            const socialUri = this.$router.resolve({
                name: "socials",
                params: { action: this.action, social: this.social },
            }).href;
            const redirectUri = `${window.location.origin}${socialUri}`;

            const dto: IAuthSocialsDto = {
                data: this.$route.query,
                redirectUri: redirectUri,
            };

            const usecase = this.$alt.system.usecase.createAuthSocialsUseCase();
            await usecase.connect(this.social, dto);

            await this.$router.push({ name: "settings-profile", params: { id: "socials" } });
        } catch (e: any) {
            this.message = e.message;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private createYandexGoal(): void {
        try {
            (window as any)["ym"](71328052, "reachGoal", "REGISTERED");
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.log(`Yandex Metrik Error: ${e.message}`);
        }
    }
}
