import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import { AlertComponent } from "./alert.component";
import { ButtonComponent } from "./button.component";
import { CheckBoxGroupComponent } from "./check-box-group.component";
import { CheckBoxSelectComponent } from "./check-box-select.component";
import { CheckBoxComponent } from "./check-box.component";
import { CollapseComponent } from "./collapse.component";
import { ColorBoxComponent } from "./color-box.component";
import { ComboBoxComponent } from "./combo-box.component";
import { CompositeComponent } from "./composite.component";
import { DateRangeComponent } from "./date-range.component";
import { DateTimeComponent } from "./date-time.component";
import DraggableComponent from "./draggable.component.vue";
import { GridComponent } from "./grid.component";
import { FileUploadComponent } from "./file-upload.component";
import { HtmlComponent } from "./html.component";
import { IconComponent } from "./icon.component";
import { LabelComponent } from "./label.component";
import LinkComponent from "./link.component.vue";
import { ListComponent } from "./list.component";
import { PanelComponent } from "./panel.component";
import { DividerComponent } from "./divider.component";
import { PasswordBoxComponent } from "./password-box.component";
import { PhoneBoxComponent } from "./phone-box.component";
import { MultiSelectComponent } from "./multi-select.component";
import { NumberBoxComponent } from "./number-box.component";
import { SelectComponent } from "./select.component";
import { SuggestBoxComponent } from "./suggest-box.component";
import { AutoSuggestComponent } from "./auto-suggest.component";
import { TagsComponent } from "./tags.component";
import { TextAreaComponent } from "./text-area.component";
import { TextBoxComponent } from "./text-box.component";
import { DropdownComponent } from "./dropdown.component";
import { Control } from ".";

import TableComponent from "../table/table.component.vue";
import OrderTypeGroupsComponent from "@core/controls/order-type-groups/order-type-groups.component.vue";
import DocumentTemplateEditorComponent from "@core/controls/document-template-editor/document-template-editor.vue";
import BarcodeControlComponent from "@core/controls/barcode/barcode-control.vue";
import PhoneControlComponent from "@core/controls/phone/phone-control.vue";
import BetaFeatureControlComponent from "@core/controls/beta-feature/beta-feature-control.vue";
import OrderClientSearchComponent from "@core/controls/order-client-search/order-client-search-control.vue";
import MultiDropdownComponent from "@core/controls/multi-dropdown/multi-dropdown.component.vue";

// const Registry: Map<string, Function> = new Map([
//     [typeof Button, (context: any) => <button-component handler={context.handler} />],
//     [typeof CheckBoxGroup, (context: any) => <check-box-group-component handler={context.handler} />],
//     [typeof CheckBox, (context: any) => <check-box-component handler={context.handler} />],
//     [typeof Collapse, (context: any) => <collapse-component handler={context.handler} />],
//     [typeof ColorBox, (context: any) => <color-box-component handler={context.handler} />],
//     [typeof ComboBox, (context: any) => <combo-box-component handler={context.handler} />],
//     [typeof Grid, (context: any) => <grid-component handler={context.handler} />],
//     [typeof FileUpload, (context: any) => <file-upload-component handler={context.handler} />],
//     [typeof Html, (context: any) => <html-component handler={context.handler} />],
//     [typeof Label, (context: any) => <label-component handler={context.handler} />],
//     [typeof MultiSelect, (context: any) => <multi-select-component handler={context.handler} />],
//     [typeof Panel, (context: any) => <panel-component handler={context.handler} />],
//     [typeof PasswordBox, (context: any) => <password-box-component handler={context.handler} />],
//     [typeof Select, (context: any) => <select-component handler={context.handler} />],
//     [typeof Tags, (context: any) => <tags-component handler={context.handler} />],
//     [typeof TextArea, (context: any) => <text-area-component handler={context.handler} />],
//     [typeof TextBox, (context: any) => <text-box-component handler={context.handler} />],
// ]);

@Component({
    name: "control-component",
    components: {
        AlertComponent,
        ButtonComponent,
        CheckBoxGroupComponent,
        CheckBoxSelectComponent,
        CheckBoxComponent,
        CollapseComponent,
        ColorBoxComponent,
        ComboBoxComponent,
        CompositeComponent,
        DateRangeComponent,
        DraggableComponent,
        GridComponent,
        FileUploadComponent,
        HtmlComponent,
        IconComponent,
        LabelComponent,
        LinkComponent,
        ListComponent,
        MultiSelectComponent,
        NumberBoxComponent,
        PanelComponent,
        PasswordBoxComponent,
        PhoneBoxComponent,
        SelectComponent,
        SuggestBoxComponent,
        TagsComponent,
        TextAreaComponent,
        TextBoxComponent,
        DividerComponent,
        OrderTypeGroupsComponent,
        DocumentTemplateEditorComponent,
        DateTimeComponent,
        TableComponent,
        PhoneControlComponent,
        BetaFeatureControlComponent,
        OrderClientSearchComponent,
        MultiDropdownComponent,
        AutoSuggestComponent,
        BarcodeControlComponent,
        DropdownComponent,
    },
})
export default class ControlComponent extends Vue {
    //IControlComponent {
    @Prop({ type: Object, required: true })
    private handler!: Control;

    private isComponentRegistered(componentName: string): boolean {
        if (!this.$options.components) {
            return false;
        }

        return !!this.$options.components[componentName];
    }

    public render(): VNode {
        const componentName = this.handler.getComponentName();

        if (!this.isComponentRegistered(componentName)) {
            return <div>Ошибка!</div>;
        }

        const component = this.$options.components ? this.$options.components[componentName] : <div>Ошибка!</div>;

        return <component handler={this.handler} />;
    }
}
