import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Divider } from "./divider";

@Component({
    name: "divider-component",
})
export class DividerComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Divider;

    public render(): VNode {
        if (this.handler.visible) {
            return <div class="border-top my-2" />;
        } else {
            return <div />;
        }
    }
}
