import { Vue, Component } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BButton, BForm, BFormInput, BFormGroup, BLink } from "bootstrap-vue";
import { BCard, BCardTitle, BCardText } from "bootstrap-vue";
import { BInputGroup, BInputGroupPrepend, BInputGroupAppend } from "bootstrap-vue";
import { IAuthResetPasswordDto } from "@lib";
import VaLogo from "@/components/common/va-logo/va-logo-full.vue";

@Component({
    components: {
        BCard,
        BCardTitle,
        BLink,
        BCardText,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        VaLogo,
    },
})
export default class ResetPassword extends Vue {
    private resources = {
        LabelTitleText: "СБРОС ПАРОЛЯ",
        LabelSubtitle1Text: "Сброс пароля",
        LabelSubtitle2Text: "Пожалуйста, введите новый пароль:",
        LabelInvalidTokenText: "Неверная ссылка.",
        TextBoxPassword1Label: "Новый пароль",
        TextBoxPassword2Label: "Повтор пароля",
        LinkLoginText: "Вход на сайт",
        ButtonText: "Сбросить",
        ValidatorId: "resetPasswordForm",
    };

    private token = "";
    private password: string = "";
    private passwordConfirm: string = "";
    private passwordVisible1: boolean = false;
    private passwordVisible2: boolean = false;

    public async mounted(): Promise<void> {
        this.initParams();
    }

    private async initParams(): Promise<void> {
        if (this.$route.params.id) {
            this.token = this.$route.params.id;
        }
    }

    private togglePasswordVisibility1(): void {
        this.passwordVisible1 = !this.passwordVisible1;
    }

    private togglePasswordVisibility2(): void {
        this.passwordVisible2 = !this.passwordVisible2;
    }

    private async reset(): Promise<void> {
        try {
            const valid = await (this.$refs[this.resources.ValidatorId] as any).validate();
            if (!valid) {
                return;
            }

            this.$alt.loader.show();

            const dto: IAuthResetPasswordDto = {
                token: this.token,
                password: this.password,
            };

            const service = this.$alt.system.usecase.createAuthUseCase();
            await service.resetPassword(dto);

            this.$alt.toast.success("Пароль был успешно сброшен.");

            await this.$router.push({ name: "login" });
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
