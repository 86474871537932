import { ICompany, ITemplateLabel, ITemplateLabelUpdateTemplateDto, TemplateLabelType } from "@lib";
import { Control } from "@core/components/alt-ui/controls";
import { Footer } from "@core/controls/footer.control";
import { DocumentTemplateEditor } from "@core/controls/document-template-editor/document-template-editor";
import { Sidebar } from "@core/components/alt-ui/sidebar";
import { goodMacroAutocomplete } from "@/@core/usecases/template/macro-replacers/good-label.macro-list";
import { createDemoGoods, createDemoStore } from "@core/controls/document-template-editor/preview-data";
import { Printer } from "@/@core/usecases/template/printer";
import { GoodPrintContext } from "@/@core/usecases/template/macro-replacers/good-label.macro-replacer";

export interface ITemplateLabelModalContext {
    label: ITemplateLabel;
    company: ICompany;
}

export class SettingsTemplatesLabelsEditModal extends Sidebar<ITemplateLabelModalContext> {
    private editor!: DocumentTemplateEditor;
    private footerPanel!: Footer;

    private context: ITemplateLabelModalContext | null = null;
    public onEdit:
        | ((orig: ITemplateLabel, dto: ITemplateLabelUpdateTemplateDto) => Promise<ITemplateLabel | null>)
        | null = null;

    public constructor() {
        super("labels-edit-modal", "");
        this.initializeControls();
    }

    public show(context: ITemplateLabelModalContext): Promise<void> {
        this.context = context;
        this.title = `Шаблон ${this.context.label.name}`;
        this.initializeControls();
        this.populateControls(this.context.label);
        return super.show();
    }

    private initializeControls(): void {
        this.editor = new DocumentTemplateEditor();
        this.footerPanel = new Footer({
            reverse: true,
            alignement: "start",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(label: ITemplateLabel): void {
        if (!this.context) {
            return;
        }

        this.editor.text = label.template;

        const context: GoodPrintContext = {
            company: this.context.company,
            stores: [createDemoStore()],
            goods: createDemoGoods(),
        };

        if (label.type === TemplateLabelType.GoodLabel) {
            this.editor.preview = template => Printer.previewGoodLabels(template, context, label);
            this.editor.getPreviewHtml = template => Printer.getPreviewHtmlGoodLabels(template, context, label);

            if (label.single === true) {
                this.editor.previewWidth = label.width + 10;
            }

            this.editor.macroAutocompletes = goodMacroAutocomplete;
            //this.editor.macrosDocsLink = `${baseUrl}guide/features/documents/#доступные-макросы-для-заявок`;
        } else {
            this.editor.getPreviewHtml = template => Printer.getPreviewHtmlGoodLabels(template, context, label);
        }
    }

    public get controls(): Control[] {
        return [this.editor];
    }

    public get footerControls(): Control[] {
        return [this.footerPanel];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid || !this.context?.label) {
            return;
        }

        await this.editLabel(this.context.label);

        // не закрываем окно после сохранения
    }

    private async editLabel(label: ITemplateLabel): Promise<ITemplateLabel | null> {
        if (!this.onEdit) {
            return null;
        }

        const dto: ITemplateLabelUpdateTemplateDto = {
            template: this.editor.template ?? "",
        };

        return await this.onEdit(label, dto);
    }
}
