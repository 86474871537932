export { default } from "./va-table.vue";

export class TableApi {
    /** Изменить лимит элементов на странице. */
    public changeLimit(limit: number): void {
        this.notifyChangeLimitListeners(limit);
    }

    private changeLimitListeners = new Set<Function>();
    private notifyChangeLimitListeners(limit: number): void {
        for (const listener of this.changeLimitListeners) {
            listener(limit);
        }
    }
    public addChangeLimitListener(listener: Function): void {
        this.changeLimitListeners.add(listener);
    }

    /** Искать строку. */
    public search(text: string): void {
        this.notifySearchListeners(text);
    }

    private searchListeners = new Set<Function>();
    private notifySearchListeners(text: string): void {
        for (const listener of this.searchListeners) {
            listener(text);
        }
    }
    public addSearchListener(listener: Function): void {
        this.searchListeners.add(listener);
    }
}
