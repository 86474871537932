import { Filter, FilterPair, FilterTableType, FilterType } from "@/utils/filter";
import { ITableFilter } from "@lib";
import { EventHandler, EventHandlerAsync, ValidateEventArgs, VisibilityChangedEventArgs } from "../components/alt-ui";
import { Control } from "../components/alt-ui/controls";

export type LocalFilter = {
    [key: string]: {
        control: Control;
        type: FilterType;
        get: () => any;
        set: (value: any) => void;
    };
};

export abstract class FilterController<T = any, TResult = void> {
    public abstract id: string;
    public abstract title: string;

    public size: "sm" | "md" | "lg" | "xl" = "sm";

    public abstract get controls(): Control[];

    public abstract get footer(): Control;

    public getControlId(controlId: string): string {
        return `${this.id}.${controlId}`;
    }

    //

    public getTableFilter(localFilter: LocalFilter): ITableFilter[] {
        return Object.entries(localFilter).reduce<ITableFilter[]>((tableFilter, [key, filter]) => {
            if (filter.type === FilterType.Equals) {
                return tableFilter.concat(Filter.convertEqualsBack(key, filter.get()));
            }

            if (filter.type === FilterType.In) {
                return tableFilter.concat(Filter.convertInBack(key, filter.get()));
            }

            if (filter.type === FilterType.Between) {
                return tableFilter.concat(Filter.convertBetweenBack(key, filter.get()));
            }

            return tableFilter;
        }, []);
    }

    public getLocalFilter(tableFilter: ITableFilter[]): LocalFilter {
        return tableFilter.reduce<Record<string, any>>((localFilter, filter) => {
            const key = filter.field;

            if (filter.operator === FilterTableType.Equals) {
                localFilter[key] = Filter.convertEquals(key, tableFilter);
            }

            if (filter.operator === FilterTableType.In) {
                localFilter[key] = Filter.convertIn(key, tableFilter);
            }

            if ([FilterTableType.Gte, FilterTableType.Lte].includes(filter.operator as FilterTableType)) {
                localFilter[key] = Filter.convertBetween(key, tableFilter);
            }

            return localFilter;
        }, {});
    }

    //

    public findItemsByIds<T extends Record<string, any>>(items: T[], ids: string[], key = "_id"): T[] {
        return items.reduce((acc: T[], item: T) => {
            if (ids.includes((item as any)[key])) {
                return acc.concat([item]);
            }

            return acc;
        }, []);
    }

    //

    protected _visible: boolean = false;
    protected _hideEvent: Function | null = null;
    protected _errorEvent: Function | null = null;

    public show(data?: T): Promise<TResult> {
        this._visible = true;
        this.notifyVisibleChangedHandlers();

        return new Promise((resolve: any, reject: any) => {
            this._hideEvent = (result: TResult) => resolve(result);
            this._errorEvent = (e: Error) => reject(e);
        });
    }

    public hide<TResult>(result?: TResult): void {
        if (this._hideEvent) {
            this._hideEvent(result);
            this._hideEvent = null;
        }

        this._visible = false;
        this.notifyVisibleChangedHandlers();
    }

    private _visibleChangedHandlers = new Set<EventHandler<VisibilityChangedEventArgs>>();
    public addVisibleChangedHandler(handler: EventHandler<VisibilityChangedEventArgs>): void {
        this._visibleChangedHandlers.add(handler);
    }
    public removeVisibleChangedHandler(handler: EventHandler<VisibilityChangedEventArgs>): void {
        this._visibleChangedHandlers.delete(handler);
    }
    private notifyVisibleChangedHandlers(): void {
        const args: VisibilityChangedEventArgs = { visible: this._visible };
        for (const handler of this._visibleChangedHandlers) {
            handler(this, args);
        }
    }

    //

    public async validate(): Promise<boolean> {
        return await this.notifyValidateHandlers();
    }

    private _validateHandlers = new Set<EventHandlerAsync<ValidateEventArgs>>();
    public addValidateHandler(handler: EventHandlerAsync<ValidateEventArgs>): void {
        this._validateHandlers.add(handler);
    }
    public removeValidateHandler(handler: EventHandlerAsync<ValidateEventArgs>): void {
        this._validateHandlers.delete(handler);
    }
    private async notifyValidateHandlers(): Promise<boolean> {
        const args: ValidateEventArgs = { valid: true };
        for (const handler of this._validateHandlers) {
            await handler(this, args);
            if (!args.valid) {
                return false;
            }
        }
        return true;
    }
}
