import { IGoodCategory, IStore, ITableFilter } from "@lib";
import { Control, MultiSelect } from "@/@core/components/alt-ui/controls";
import { Footer } from "@/@core/controls/footer.control";
import { NumericRangeControl } from "@/@core/controls/numeric-range.control";
import { FilterController } from "@/@core/filters/filter-controller";
import { FilterType, FilterPair } from "@/utils/filter";

export type StoresFilter = Partial<{
    store: string[];
    cost?: FilterPair;
    price?: FilterPair;
    quantity?: FilterPair;
    category?: string[];
}>;

export interface IStoresFilterContext {
    stores: IStore[];
    categories: IGoodCategory[];
    defaultFilter: StoresFilter;
    currentFilter: ITableFilter[];
}

export class StoresFilterController extends FilterController<IStoresFilterContext, void> {
    public id: string = "stores-filter";
    public title: string = "Фильтр";

    public filter = {
        store: {
            control: new MultiSelect<IStore>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.store.control.selectedItems.map(e => e.id);
            },
            set: (ids: string[]): void => {
                this.filter.store.control.selectedItems = this.findItemsByIds(this.context.stores, ids);
            },
        },
        category: {
            control: new MultiSelect<IGoodCategory>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.category.control.selectedItems.map(e => e.id);
            },
            set: (ids: string[]): void => {
                this.filter.category.control.selectedItems = this.findItemsByIds(this.context.categories, ids);
            },
        },
        cost: {
            control: new NumericRangeControl({
                id: this.getControlId("cost"),
                title: "Себестоимость",
                validation: "money|unsigned",
            }),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.cost.control.value;
            },
            set: (v: FilterPair): void => {
                this.filter.cost.control.value = v;
            },
        },
        price: {
            control: new NumericRangeControl({
                id: this.getControlId("price"),
                title: "Цена продажи",
                validation: "money|unsigned",
            }),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.price.control.value;
            },
            set: (v: FilterPair): void => {
                this.filter.price.control.value = v;
            },
        },
        quantity: {
            control: new NumericRangeControl({
                id: this.getControlId("quantity"),
                title: "Количество",
                validation: "numeric",
            }),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.quantity.control.value;
            },
            set: (v: FilterPair): void => {
                this.filter.quantity.control.value = v;
            },
        },
    };

    private ftFooter!: Footer;

    private context!: IStoresFilterContext;

    public onSave: ((filter: ITableFilter[]) => Promise<boolean>) | null = null;

    public init(context: IStoresFilterContext): void {
        this.context = context;

        this.initControls();
        this.initFooter();
        this.populateFilters();
    }

    public show(context: IStoresFilterContext): Promise<void> {
        this.context = context;
        this.initControls();
        this.initFooter();
        this.populateFilters();

        return super.show(context);
    }

    // Init

    private initControls(): void {
        this.filter.store.control.id = this.getControlId("store");
        this.filter.store.control.label = "Склад";
        this.filter.store.control.items = this.context?.stores ?? [];
        this.filter.store.control.textField = e => e.info.name;

        this.filter.category.control.id = this.getControlId("category");
        this.filter.category.control.label = "Категории";
        this.filter.category.control.items = this.context?.categories ?? [];
        this.filter.category.control.textField = e => e.name;
    }

    private initFooter(): void {
        this.ftFooter = new Footer({
            okText: "Сохранить",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateFilters(): void {
        if (!this.context.currentFilter) {
            return;
        }

        const filters = this.getLocalFilter(this.context.currentFilter);

        Object.entries(filters).forEach(([k, filter]) => {
            const key = k as keyof StoresFilter;

            if (!this.filter[key]) {
                return;
            }

            this.filter[key].set(filter as any);
        });
    }

    public get controls(): Control[] {
        return Object.entries(this.filter).map(([_, filter]) => filter.control);
    }

    public get footer(): Control {
        return this.ftFooter;
    }

    // /Init

    public get tableFilter(): ITableFilter[] {
        return this.getTableFilter(this.filter);
    }

    public get localFilter(): StoresFilter {
        return this.getLocalFilter(this.tableFilter);
    }

    public async clickSave(): Promise<void> {
        if (!this.onSave) {
            return;
        }

        const result = await this.onSave(this.tableFilter);

        if (result) {
            this.hide();
        }
    }

    public clickCancel(): void {
        this.hide();
    }
}
