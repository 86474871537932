import { IAccount, ITransaction, ITransferCreateDto, ITransactionUpdateDto } from "@lib";
import { Button, Control, Panel, Select, TextArea, TextBox } from "@core/components/alt-ui/controls";
import { Footer } from "@/@core/controls/footer.control";
import { Modal } from "@/@core/components/alt-ui/modal";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface TransferModalContext {
    selectedAccounts: IAccount[];
    accounts: IAccount[];
}

export class TransferModal extends Modal<TransferModalContext> {
    private cbAccountFrom!: Select<IAccount>;
    private cbAccountTo!: Select<IAccount>;

    private tbAmount!: TextBox;
    private taDescription!: TextArea;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context!: TransferModalContext;
    public onCreate: ((dto: ITransferCreateDto) => Promise<ITransaction[] | null>) | null = null;

    public constructor() {
        super("accounts-transfer-modal", "");

        this.initializeControls();
    }

    public show(context: TransferModalContext): Promise<void> {
        this.context = context;
        this.title = "Перевод средств";

        this.initializeControls();

        return super.show();
    }

    protected initializeControls(): void {
        this.cbAccountFrom = new Select<IAccount>();
        this.cbAccountTo = new Select<IAccount>();
        this.tbAmount = new TextBox();
        this.taDescription = new TextArea();

        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        const initialAccounts = this.getInitialAccounts();

        this.cbAccountFrom.id = "accounts-transfer-modal.account-from";
        this.cbAccountFrom.label = "Со счёта";
        this.cbAccountFrom.items = this.context?.accounts ?? [];
        this.cbAccountFrom.textField = e => e.info.name;
        this.cbAccountFrom.descriptionField = e => e.info.description;
        this.cbAccountFrom.selectedIndex = this.cbAccountFrom.items.length > 0 ? initialAccounts.from : -1;
        this.cbAccountFrom.addChangedHandler((_, event) => {
            this.setAmountValidation();
        });

        this.cbAccountTo.id = "accounts-transfer-modal.account-to";
        this.cbAccountTo.label = "На счёт";
        this.cbAccountTo.items = this.context?.accounts ?? [];
        this.cbAccountTo.textField = e => e.info.name;
        this.cbAccountTo.descriptionField = e => e.info.description;
        this.cbAccountTo.selectedIndex = this.cbAccountFrom.items.length > 1 ? initialAccounts.to : -1;

        //

        this.tbAmount.id = "accounts-transfer-modal.amount";
        this.tbAmount.label = "Сумма";
        this.tbAmount.text = "";
        this.tbAmount.validation = "required|numeric|unsigned";
        this.setAmountValidation();

        this.taDescription.id = "accounts-transfer-modal.description";
        this.taDescription.label = "Описание";
        this.taDescription.text = "";
        this.taDescription.validation = "required";

        //

        this.btnCancel.id = "account-transfer.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "account-transfer.save";
        this.btnSave.text = "Перевести";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    public get controls(): Control[] {
        return [this.cbAccountFrom, this.cbAccountTo, this.tbAmount, this.taDescription, this.pnlFooter];
    }

    private setAmountValidation(): void {
        const account = this.cbAccountFrom.selectedItem;

        if (!account) {
            return;
        }

        this.tbAmount.validation = `required|numeric|unsigned|max_value:${account?.balance}`;
    }

    private getInitialAccounts(): { to: number; from: number } {
        if (!this.context?.selectedAccounts) {
            return {
                to: -1,
                from: -1,
            };
        }

        const isOneSelected = this.context.selectedAccounts.length === 1;

        if (isOneSelected) {
            const accountTo = this.context.selectedAccounts[0];
            const accountFrom = this.context.accounts.find(e => e.id !== accountTo.id);

            if (!accountFrom) {
                return {
                    from: this.context.accounts.indexOf(accountTo),
                    to: this.context.accounts.indexOf(accountTo),
                };
            }

            return {
                from: this.context.accounts.indexOf(accountTo),
                to: this.context.accounts.indexOf(accountFrom),
            };
        }

        const accountTo = this.context.selectedAccounts[0];
        const accountFrom = this.context.selectedAccounts[1];

        return {
            from: this.context.accounts.indexOf(accountTo),
            to: this.context.accounts.indexOf(accountFrom),
        };
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const result = await this.create();

        if (result) {
            this.hide(result);
        }
    }

    private async create(): Promise<ITransaction[] | null> {
        if (!this.onCreate) {
            return null;
        }

        if (!this.cbAccountFrom.selectedItem || !this.cbAccountTo.selectedItem) {
            return null;
        }

        if (this.cbAccountFrom.selectedItem === this.cbAccountTo.selectedItem) {
            return null;
        }

        const dto: ITransferCreateDto = {
            accountFrom: this.cbAccountFrom.selectedItem.id,
            accountTo: this.cbAccountTo.selectedItem.id,
            amount: parseFloat(this.tbAmount.text),
            description: this.taDescription.text,
        };

        return await this.onCreate(dto);
    }
}
