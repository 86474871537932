import moment from "moment";
import { Locale } from "@lib";
import { FilterPair } from "@/utils/filter";
import { datetime } from "@/filters/datetime";
import { moneyFormat } from "@/filters/money";
import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";

export class SalesTableReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;

        const from = dates[0];
        const to = dates[1];

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.sales(options.company, {
            timezone: new Date().getTimezoneOffset().toString(),
            from,
            to,
        });

        const classHeaderNum = "va-td va-td-num text-center text-xl";
        const classHeaderName = "va-td text-center text-xl";
        const classHeaderMoney = "va-td va-td-money text-center text-xl";
        const classSubHeaderMoney = "va-td va-td-money text-center text-xl";

        const classCellNum = "va-td va-td-num text-center text-xl";
        const classCellName = "va-td text-nowrap text-xl";
        const classCellMoney = "va-td va-td-money text-center text-xl";

        const classFooterName = "va-td text-right text-xl font-bold va-td-noborder";
        const classFooterMoney = "va-td va-td-money text-center text-xl font-bold";

        const header: any[] = [
            {
                cells: [
                    {
                        value: "Продажа",
                        class: classHeaderName,
                        rowspan: 2,
                    },
                    {
                        value: "Выручка",
                        class: classHeaderMoney,
                        colspan: 3,
                    },
                    {
                        value: "Расходы",
                        class: classHeaderMoney,
                        colspan: 3,
                    },
                    {
                        value: "Прибыль",
                        class: classHeaderMoney,
                        rowspan: 2,
                    },
                ],
            },
            {
                cells: [
                    // выручка

                    {
                        value: "Товары",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Скидка",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Сумма",
                        class: classSubHeaderMoney,
                    },

                    // расходы

                    {
                        value: "Товары",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Зарплата",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Сумма",
                        class: classSubHeaderMoney,
                    },
                ],
            },
        ];

        const total = {
            count: 0,
            revenueGoods: 0,
            revenueDiscount: 0,
            revenueSum: 0,
            expensesGoods: 0,
            expensesSalary: 0,
            expensesSum: 0,
            profit: 0,
        };

        const locale = Locale.RU;
        const body: any[] = [];
        for (const item of report) {
            total.count += 1;
            total.revenueGoods += item.revenue.goods;
            total.revenueDiscount += item.revenue.discount;
            total.revenueSum += item.revenue.sum;
            total.expensesGoods += item.expenses.goods;
            total.expensesSalary += item.expenses.salary;
            total.expensesSum += item.expenses.sum;
            total.profit += item.profit;

            body.push({
                cells: [
                    {
                        value: `Продажа #${item.sale.number}`,
                        class: classCellName,
                    },

                    // выручка

                    {
                        value: moneyFormat(item.revenue.goods, { locale }),
                        class: classCellMoney,
                    },
                    {
                        value: moneyFormat(item.revenue.discount, { locale }),
                        class: classCellMoney,
                    },
                    {
                        value: moneyFormat(item.revenue.sum, { locale }),
                        class: classCellMoney,
                    },

                    // расходы

                    {
                        value: moneyFormat(item.expenses.goods, { locale }),
                        class: classCellMoney,
                    },

                    {
                        value: moneyFormat(item.expenses.salary, { locale }),
                        class: classCellMoney,
                    },

                    {
                        value: moneyFormat(item.expenses.sum, { locale }),
                        class: classCellMoney,
                    },

                    //

                    {
                        value: moneyFormat(item.profit, { locale }),
                        class: classCellMoney,
                    },
                ],
            });
        }

        const footer: any[] = [
            {
                cells: [
                    {
                        value: "Итого:",
                        class: classFooterName,
                    },

                    // выручка

                    {
                        value: moneyFormat(total.revenueGoods, { locale }),
                        class: classFooterMoney,
                    },
                    {
                        value: moneyFormat(total.revenueDiscount, { locale }),
                        class: classFooterMoney,
                    },
                    {
                        value: moneyFormat(total.revenueSum, { locale }),
                        class: classFooterMoney,
                    },

                    // расходы

                    {
                        value: moneyFormat(total.expensesGoods, { locale }),
                        class: classFooterMoney,
                    },
                    {
                        value: moneyFormat(total.expensesSalary, { locale }),
                        class: classFooterMoney,
                    },
                    {
                        value: moneyFormat(total.expensesSum, { locale }),
                        class: classFooterMoney,
                    },

                    //

                    {
                        value: moneyFormat(total.profit, { locale }),
                        class: classFooterMoney,
                    },
                ],
            },
        ];

        const subtitle = from === to ? datetime(from, "LL") : `${datetime(from, "LL")} - ${datetime(to, "LL")}`;

        return {
            title: "Отчёт по продажам",
            subtitle: subtitle,
            items: [
                {
                    kind: ReportKind.Table,
                    table: {
                        header,
                        body,
                        footer,
                    },
                },
            ],
        };
    }

    private dtFormat(date: number | string | Date): string {
        return moment(date).format("MMM 'YY");
    }
}
