export abstract class DateTimeUtils {
    public static yesterday(): Date {
        const dt = new Date();
        dt.setDate(dt.getDate() - 1);
        return dt;
    }

    public static weekAgo(shift = 0): Date {
        const dt = new Date();
        dt.setDate(dt.getDate() - 7 + shift);
        return dt;
    }

    public static thisWeekFirstDay(): Date {
        const dt = new Date();
        const shift = (dt.getDay() + 6) % 7;
        dt.setDate(dt.getDate() - shift);
        return dt;
    }

    public static thisWeekLastDay(): Date {
        const dt = new Date();
        const shift = 7 - dt.getDay();
        dt.setDate(dt.getDate() + shift);
        return dt;
    }

    public static lastWeekFirstDay(): Date {
        const dt = this.thisWeekFirstDay();
        dt.setDate(dt.getDate() - 7);
        return dt;
    }

    public static lastWeekLastDay(): Date {
        const dt = this.thisWeekLastDay();
        dt.setDate(dt.getDate() - 7);
        return dt;
    }

    public static monthAgo(shift = 0): Date {
        const dt = new Date();
        dt.setMonth(dt.getMonth() - 1);
        dt.setDate(dt.getDate() + shift);
        return dt;
    }

    public static thisMonthFirstDay(): Date {
        const dt = new Date();
        dt.setDate(1);
        return dt;
    }

    public static thisMonthLastDay(): Date {
        const dt = new Date();
        dt.setDate(1);
        dt.setMonth(dt.getMonth() + 1);
        dt.setDate(0);
        return dt;
    }

    public static lastMonthFirstDay(): Date {
        const dt = this.thisMonthFirstDay();
        dt.setMonth(dt.getMonth() - 1);
        return dt;
    }

    public static lastMonthLastDay(): Date {
        const dt = this.thisMonthFirstDay();
        dt.setDate(0);
        return dt;
    }

    public static addDays(days: number): Date {
        const dt = new Date();
        dt.setDate(dt.getDate() + days);
        return dt;
    }

    public static addWeeks(weeks: number): Date {
        const dt = new Date();
        dt.setDate(dt.getDate() + weeks * 7);
        return dt;
    }

    public static addMonths(months: number): Date {
        const dt = new Date();
        dt.setMonth(dt.getMonth() + months);
        return dt;
    }

    public static addYears(years: number): Date {
        const dt = new Date();
        dt.setFullYear(dt.getFullYear() + years);
        return dt;
    }
}
