













import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormInput } from "bootstrap-vue";

@Component({
    name: "alt-input",
    components: { BFormInput },
})
export default class AltInput extends Vue {
    @Prop({ type: String })
    private id!: string;

    @Prop({ type: String, default: "text" })
    private type!: string;

    @Prop({ type: String })
    private placeholder!: string;

    @Prop({ type: Boolean })
    private disabled!: boolean;

    @Prop({ default: null })
    private state!: boolean | null;

    @Prop({ type: String })
    private value!: string;

    public get text(): string {
        return this.value;
    }

    public set text(text: string) {
        this.$emit("input", text);
    }
}
