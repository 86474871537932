import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BAlert } from "bootstrap-vue";
import { Alert } from "./alert";

@Component({
    name: "alert-component",
    components: { BAlert },
})
export class AlertComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Alert;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <b-alert
                    id={this.handler.id}
                    variant={this.handler.variant}
                    class={this.handler.class}
                    style={this.handler.style}
                    show
                >
                    <div class="alert-body flex items-center">
                        {this.renderIcon()}
                        {this.handler.text}
                    </div>
                </b-alert>
            );
        } else {
            return <div />;
        }
    }

    private renderIcon(): VNode {
        if (this.handler.icon) {
            return (
                <div>
                    <feather-icon icon={this.handler.icon} class="mr-50" />
                </div>
            );
        }
        return <div />;
    }
}
