import { ISale, ISaleStage, SaleStageType } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    ComboBoxChangedEventArgs,
    Control,
    Label,
    Panel,
    Select,
    TextArea,
} from "@core/components/alt-ui/controls";

export interface ISaleChangeStageModalContext {
    sale: ISale;
    stages: ISaleStage[];
}

export enum SaleChangeStageModalAnswer {
    Change = "change",
    Complete = "complete",
    Reopen = "reopen",
    Cancel = "cancel",
}

export interface ISaleChangeStageModalResult {
    answer: SaleChangeStageModalAnswer;
    data?: {
        stage: ISaleStage;
        comment: string;
    };
}

export class SaleChangeStageModal extends Modal<ISaleChangeStageModalContext, ISaleChangeStageModalResult> {
    private pnlMain!: Panel;
    private cbStage!: Select<ISaleStage>;
    private taComment!: TextArea;

    private pnlDone!: Panel;
    private lblDoneTitle!: Label;
    private lblDoneDescription!: Label;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;
    private btnComplete!: Button;
    private btnReopen!: Button;

    private context: ISaleChangeStageModalContext | null = null;

    public constructor() {
        super("sale-stage-modal", "Изменение этапа");
        this.initializeControls();
    }

    public show(context: ISaleChangeStageModalContext): Promise<ISaleChangeStageModalResult> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context);
        return super.show();
    }

    protected initializeControls(): void {
        this.cbStage = new Select<ISaleStage>();
        this.cbStage.id = "sale-stage.stage";
        this.cbStage.class = "mb-0.75";
        this.cbStage.label = "Выберите этап";
        this.cbStage.items = this.context?.stages ?? [];
        this.cbStage.textField = opt => opt.name;
        this.cbStage.selectedIndex = this.cbStage.items.length > 0 ? 0 : -1;
        this.cbStage.addChangedHandler(this.onStageChanged.bind(this));

        this.taComment = new TextArea();
        this.taComment.id = "sale-stage.comment";
        this.taComment.label = "Комментарий к изменению этапа";
        this.taComment.text = "";
        this.taComment.class = "mb-0.75";

        this.pnlMain = new Panel();
        this.pnlMain.addControls([this.cbStage, this.taComment]);

        //

        this.lblDoneTitle = new Label();
        this.lblDoneTitle.text = "Продажа закрыта";
        this.lblDoneTitle.class = "text-danger font-semibold mb-0.5";

        this.lblDoneDescription = new Label();
        this.lblDoneDescription.text = "Чтобы изменить этап, сначала откройте продажу в карточке продажи.";
        this.lblDoneDescription.style = "white-space: normal !important;";

        this.pnlDone = new Panel();
        this.pnlDone.addControls([this.lblDoneTitle, this.lblDoneDescription]);
        this.pnlDone.visible = false;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "sale-stage.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "sale-stage.save";
        this.btnSave.text = "Изменить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.btnComplete = new Button();
        this.btnComplete.id = "sale-stage.complete";
        this.btnComplete.text = "Завершить продажу";
        this.btnComplete.class = "ml-0.75";
        this.btnComplete.variant = "primary";
        this.btnComplete.addClickHandler(this.completeSale.bind(this));
        this.btnComplete.visible = false;

        this.btnReopen = new Button();
        this.btnReopen.id = "sale-stage.reopen";
        this.btnReopen.text = "Открыть продажу";
        this.btnReopen.variant = "primary";
        this.btnReopen.addClickHandler(this.reopenSale.bind(this));
        this.btnReopen.visible = false;

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave, this.btnComplete, this.btnReopen]);
    }

    private populateControls(context: ISaleChangeStageModalContext): void {
        const isSaleDone = context.sale.done;

        this.cbStage.selectedIndex = this.cbStage.items.findIndex(i => i.id === context.sale.stage);
        this.pnlMain.visible = !isSaleDone;
        this.pnlDone.visible = isSaleDone ?? false;
        this.btnSave.disabled = isSaleDone ?? false;
    }

    public get controls(): Control[] {
        return [this.pnlMain, this.pnlDone, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide({ answer: SaleChangeStageModalAnswer.Cancel });
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.cbStage.selectedItem) {
            return;
        }

        // если продажа закрыта, этап не меняем
        if (this.context.sale.done) {
            return;
        }

        // если этап не изменился, просто закрываем
        if (this.cbStage.selectedItem.id === this.context.sale.stage) {
            return this.clickCancel(sender, e);
        }

        this.hide({
            answer: SaleChangeStageModalAnswer.Change,
            data: {
                stage: this.cbStage.selectedItem,
                comment: this.taComment.text,
            },
        } as ISaleChangeStageModalResult);
    }

    private async completeSale(): Promise<void> {
        this.hide({ answer: SaleChangeStageModalAnswer.Complete });
    }

    private async reopenSale(): Promise<void> {
        this.hide({ answer: SaleChangeStageModalAnswer.Reopen });
    }

    private onStageChanged(sender: any, e: ComboBoxChangedEventArgs<ISaleStage>): void {
        const isSaleDone = this.context?.sale.done ?? false;

        this.pnlMain.visible = !isSaleDone;
        this.btnSave.visible = !isSaleDone;
        this.pnlDone.visible = isSaleDone;
        this.btnReopen.visible = isSaleDone;

        if (!isSaleDone && e.item?.type === SaleStageType.Closed) {
            this.btnSave.text = "Изменить только этап";
            this.btnSave.variant = "outline-primary";
            this.btnComplete.visible = true;
        } else {
            this.btnSave.text = "Изменить";
            this.btnSave.variant = "primary";
            this.btnComplete.visible = false;
        }
    }

    private isStageChanged(): boolean {
        if (!this.context || !this.cbStage.selectedItem) {
            return false;
        }

        return this.cbStage.selectedItem.id !== this.context.sale.stage;
    }
}
