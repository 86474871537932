export const colors = {
    primary: "#7367F0",
    secondary: "#82868b",
    success: "#28C76F",
    danger: "#EA5455",
    warning: "#FF9F43",
    dark: "#1E1E1E",
};

const themeConfig = {
    app: {
        name: "Alt SC",
        logo: require("@/assets/images/logo/logo.svg"),
    },

    theme: "semi-dark", // options[String]  : "light"(default), "dark", "semi-dark"

    // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
};

export default themeConfig;
