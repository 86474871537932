import { Vue, Component, Prop } from "vue-property-decorator";
import ReportsViewChart from "./reports-view-chart.vue";

@Component({
    name: "reports-view-table",
    components: { ReportsViewChart },
})
export default class ReportsViewTable extends Vue {
    @Prop({ required: true })
    private table!: any;
}
