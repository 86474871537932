import { ICompany } from "@lib";
import * as filters from "@/filters";

export function getDefaultTableColumns(form: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 60,
            hide: false,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Подробнее",
                            icon: "MoreHorizontalIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: (company: ICompany) => true,
                            click: (company: ICompany) => form.show(company),
                        },
                    ],
                };
            },
        },
        {
            colId: "_id",
            field: "_id",
            headerName: "Id",
            sortable: true,
            filter: true,
            width: 180,
            hide: true,
        },
        {
            colId: "createdAt",
            field: "createdAt",
            headerName: "Дата создания",
            sortable: true,
            filter: true,
            width: 150,
            hide: false,

            cellRenderer: function (params: any) {
                return filters.datetime.datetime(params.value, "L LT");
            },
        },
        {
            colId: "name",
            field: "info.name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "owners",
            field: "owners",
            headerName: "Владельцы",
            sortable: false,
            filter: true,
            width: 150,
            hide: false,

            cellRenderer: function (params: any) {
                const company = params.data;
                const owners = company.owners;
                let output = '<div class="my-0.5">';
                for (const owner of owners) {
                    output += `<p class="my-0">${owner.info.officialName ?? owner.info.name}</p>`;
                }
                output += "</div>";
                return output;
            },
        },
        {
            colId: "contacts",
            field: "contacts",
            headerName: "Контакты",
            sortable: false,
            filter: true,
            width: 150,
            hide: false,

            cellRenderer: function (params: any) {
                const company = params.data;
                let output = '<div class="my-0.5">';
                output += `<p class="my-0">${company?.info?.contacts?.phone ?? "телефона нет"}</p>`;
                output += `<p class="my-0">${company?.info?.contacts?.email ?? "почты нет"}</p>`;
                output += "</div>";
                return output;
            },
        },
        {
            colId: "balance",
            field: "balance",
            headerName: "Баланс",
            sortable: true,
            filter: true,
            width: 120,
            hide: false,
            cellRendererFramework: "CellRendererMoney",

            cellRendererParams: (params: any) => {
                const balance = params.value;
                const locale = "ru-RU";
                return {
                    locale: locale,
                    class: balance > 0 ? "text-success" : balance < 0 ? "text-danger" : undefined,
                };
            },
        },
        {
            colId: "pricing.plan",
            field: "pricing.plan",
            headerName: "Тариф",
            sortable: false,
            filter: true,
            width: 120,
            hide: false,
        },
        {
            colId: "orders_sales",
            field: "orders_sales",
            headerName: "Заявки/продажи",
            sortable: false,
            filter: true,
            width: 175,
            hide: false,
            autoHeight: true,

            cellRenderer: function (params: any) {
                const company = params.data;
                const countOrders = company.countOrders;
                const countSales = company.countSales;
                const lastOrderDate = company.lastOrderDate
                    ? filters.datetime.datetime(company.lastOrderDate, "L")
                    : "?";
                const lastSaleDate = company.lastSaleDate ? filters.datetime.datetime(company.lastSaleDate, "L") : "?";
                return `<div class="text-xs my-0.5">
                        <p class="my-0">Заявок: ${countOrders} - ${lastOrderDate},</p>
                        <p class="my-0">Продаж: ${countSales} - ${lastSaleDate}</p>
                        </div>`;
            },
        },
        {
            colId: "structure",
            field: "structure",
            headerName: "Структура",
            sortable: false,
            filter: true,
            width: 175,
            hide: false,
            autoHeight: true,

            cellRenderer: function (params: any) {
                const company = params.data;
                const countOffices = company.countOffices;
                const countShops = company.countShops;
                const countAccounts = company.countAccounts;
                const countStores = company.countStores;
                const countEmployes = company.countEmployes;
                return `<div class="text-xs my-0.5">
                        <p class="my-0">Филиалов: ${countOffices}, Магазинов: ${countShops},</p>
                        <p class="my-0">Счетов: ${countAccounts}, Складов: ${countStores},</p>
                        <p class="my-0">Сотрудников: ${countEmployes}</p>
                        </div>`;
            },
        },
        {
            colId: "meta.utm",
            field: "meta.utm",
            headerName: "UTM-метки",
            sortable: false,
            filter: true,
            width: 175,
            hide: false,
            autoHeight: true,

            cellRenderer: function (params: any) {
                const utm = params.data.meta?.utm;
                if (!utm) {
                    return "";
                }

                let html = `<div class="my-0.5">`;
                if (utm.utm_source) {
                    html += `<p class="my-0">utm_source: ${utm.utm_source}</p>`;
                }

                if (utm.utm_medium) {
                    html += `<p class="my-0">utm_medium: ${utm.utm_medium}</p>`;
                }

                if (utm.utm_campaign) {
                    html += `<p class="my-0">utm_campaign: ${utm.utm_campaign}</p>`;
                }

                if (utm.utm_content) {
                    html += `<p class="my-0">utm_content: ${utm.utm_content}</p>`;
                }

                if (utm.utm_term) {
                    html += `<p class="my-0">utm_term: ${utm.utm_term}</p>`;
                }

                html += "</div>";

                return html;
            },
        },
        {
            colId: "meta",
            field: "meta",
            headerName: "Мета-информация",
            sortable: false,
            filter: true,
            width: 175,
            hide: false,
            autoHeight: true,

            cellRenderer: function (params: any) {
                const meta = params.data.meta;
                if (typeof meta === "string" || typeof meta === "number") {
                    return meta;
                }

                if (Array.isArray(meta)) {
                    return `<div class="my-0.5">${meta.join(", ")}</div>`;
                }

                if (meta && typeof meta === "object") {
                    let html = `<div class="my-0.5">`;
                    for (const [key, value] of Object.entries(meta)) {
                        if (key !== "utm") {
                            html += `<p class="my-0">${key}: ${value}</p>`;
                        }
                    }
                    html += "</div>";

                    return html;
                }

                return "";
            },
        },
    ];
}
