import { PermissionType, PermissionCommonSection, PermissionRight } from "@lib";

export default {
    translateType(value: string) {
        switch (value) {
            case PermissionType.Admin:
                return "Администратор (все права)";

            case PermissionType.Common:
                return "Общее";
            case PermissionType.Offices:
                return "Филиал";
            case PermissionType.Shops:
                return "Магазин";
            case PermissionType.Stores:
                return "Склад";
            case PermissionType.Accounts:
                return "Счёт";
            case PermissionType.Employees:
                return "Сотрудник";
        }
        return value;
    },

    translateSection(value: string) {
        switch (value) {
            // case PermissionCommonSection.Admin:
            //     return "Все права";

            case PermissionCommonSection.Clients:
                return "Клиенты";
            case PermissionCommonSection.Salaries:
                return "Зарплаты";
            case PermissionCommonSection.Reports:
                return "Отчёты";
            case PermissionCommonSection.Employees:
                return "Настройки сотрудников";
            case PermissionCommonSection.Offices:
                return "Настройки филиалов";
            case PermissionCommonSection.Shops:
                return "Настройки магазинов";
            case PermissionCommonSection.Accounts:
                return "Настройки счетов";
            case PermissionCommonSection.Stores:
                return "Настройки складов";
            case PermissionCommonSection.Lists:
                return "Настройки списков";
            case PermissionCommonSection.Templates:
                return "Настройки шаблонов документов";

            // case PermissionSection.OfficeOrders:
            //     return "Заявки";
            // case PermissionSection.StoreGoods:
            //     return "Товары";
            // case PermissionSection.AccountTransactions:
            //     return "Транзакции";
        }
        return value;
    },

    translateRight(value: string) {
        switch (value) {
            //case PermissionRights.All:
            //    return "Все права";

            case PermissionRight.Admin:
                return "Все права";

            case PermissionRight.Read:
                return "Просмотр";
            case PermissionRight.Create:
                return "Создание";
            case PermissionRight.Update:
                return "Редактирование";
            case PermissionRight.Delete:
                return "Удаление";

            case PermissionRight.OrdersRead:
                return "Просмотр заявок";
            case PermissionRight.OrdersCreate:
                return "Создание заявок";
            case PermissionRight.OrdersUpdate:
                return "Редактирование заявок";
            case PermissionRight.OrdersDelete:
                return "Удаление заявок";

            case PermissionRight.SalesRead:
                return "Просмотр продаж";
            case PermissionRight.SalesCreate:
                return "Создание продаж";
            case PermissionRight.SalesUpdate:
                return "Редактирование продаж";
            case PermissionRight.SalesDelete:
                return "Удаление продаж";

            case PermissionRight.GoodsRead:
                return "Просмотр товаров";
            case PermissionRight.GoodsCreate:
                return "Создание товаров";
            case PermissionRight.GoodsUpdate:
                return "Редактирование товаров";
            case PermissionRight.GoodsAsOrderMaterials:
                return "Товары как материалы в заявках";
            case PermissionRight.GoodsSale:
                return "Продажа товаров";
            case PermissionRight.GoodsDelete:
                return "Удаление товаров";

            case PermissionRight.TransactionsRead:
                return "Просмотр транзакций";
            case PermissionRight.TransactionsCreate:
                return "Создание транзакций";
            // case PermissionRights.TransactionsUpdate:
            //     return "Редактирование транзакций";
            case PermissionRight.TransactionsDelete:
                return "Удаление транзакций";

            case PermissionRight.SalaryRead:
                return "Просмотр выплат";
            case PermissionRight.SalaryCreate:
                return "Создание выплат";
            // case PermissionRights.SalaryUpdate:
            //     return "Редактирование выплат";
            case PermissionRight.SalaryDelete:
                return "Удаление выплат";
        }
        return value;
    },
};
