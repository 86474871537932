import { ICompanyUseCase, ICompany, ICompanyUpdateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class CompanyUseCase implements ICompanyUseCase {
    public async get(id: string): Promise<ICompany> {
        try {
            const response = await Api.get<ICompany>(`/companies/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(id: string, dto: ICompanyUpdateDto): Promise<ICompany> {
        try {
            const response = await Api.put<ICompany>(`/companies/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    // TODO: test

    public async testPayment(paymentData: any): Promise<void> {
        try {
            await Api.post<void>("/companies/payment", paymentData);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
