import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import VueSelect from "vue-select";
import { IOrder, IEmployee, IUser } from "@lib";

@Component({
    name: "order-view-manager",
    components: { BButton, VueSelect },
})
export default class OrderViewManager extends Vue {
    @Prop({ required: true })
    private order!: IOrder | null;

    @Prop({ type: Array, required: true })
    private employees!: IEmployee[];

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private managerIdBackup: string = "";
    private managerId: string = "";

    private get isChanged(): boolean {
        return this.managerId !== this.managerIdBackup;
    }

    @Watch("order", { immediate: true, deep: true })
    private onOrderChanged(): void {
        this.initValues();
    }

    private async initValues(): Promise<void> {
        if (this.order && this.order.manager) {
            this.managerId = this.order.manager;
            this.managerIdBackup = this.managerId;
        } else {
            const user = await this.$info.getUser();
            this.managerId = user.id;
            this.managerIdBackup = this.managerId;
        }
    }

    private async applyChanges(): Promise<void> {
        try {
            if (this.managerId === "") {
                throw new Error("Сотрудник не задан.");
            }

            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            const companyId = this.order.company as string;
            const orderId = this.order.id;
            const managerId = this.managerId;

            const usecase = this.$alt.system.usecase.createOrderUseCase();
            const order = await usecase.changeManager(companyId, orderId, managerId);

            this.$emit("order-changed", order);
        } catch {
            this.$alt.toast.error("Не удалось изменить сотрудника.");
        }
    }

    private async cancelChanges(): Promise<void> {
        await this.initValues();
    }
}
