import { Control } from "@core/components/alt-ui/controls";

export type TableActionHandler<T> = (item: T, index: number) => void;
export type TableStringReceiver<T> = (item: T, index: number) => string;
export type TableCellReceiver<T> = (item: T, index: number) => string | Control | null;
export type TableArrayReceiver<T> = (items: T[]) => string | Control | null;

export type TableColumn<T = any> = {
    title?: TableStringReceiver<T> | string;
    cell?: TableCellReceiver<T>;
    width?: number;
    classHeader?: string;
    classCell?: string;
};

export type TableFooter<T = any> = {
    title: TableArrayReceiver<T> | string;
    cell: TableArrayReceiver<T>;
    classFooter?: string;
    classCell?: string;
};

export type TableAction<T = any> = {
    title: TableStringReceiver<T> | string;
    icon: TableStringReceiver<T> | string;
    handler: TableActionHandler<T>;
    class?: string;
};

export class Table<T = any> extends Control {
    public getComponentName(): string {
        return "TableComponent";
    }

    public items: T[] = [];
    public columns: TableColumn<T>[] = [];
    public actions: TableAction<T>[] = [];
    public footer: TableFooter<T>[] = [];
    public noDataText = "Нет данных";
}
