import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./langs/en";
import ru from "./langs/ru";

Vue.use(VueI18n);

const numberFormats = {
    en: {
        currency: {
            style: "currency",
            currency: "USD",
        },
    },
    ru: {
        currency: {
            style: "currency",
            currency: "RUB",
            currencyDisplay: "symbol",
        },
    },
};

export default new VueI18n({
    locale: "ru", // default locale
    messages: {
        en,
        ru,
    },
    numberFormats,
});

// lazy loading
// https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
