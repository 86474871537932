import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { BTooltip } from "bootstrap-vue";
import moment from "moment";
import AltDateTime from "@core/components/alt-controls/alt-date-time.vue";
import { DateTime } from "./date-time";
import { FieldType } from "@lib";

@Component({
    name: "date-time-component",
    components: { AltDateTime, ValidationProvider, BTooltip },
})
export class DateTimeComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: DateTime;

    private get value(): string {
        if (!this.handler.value) {
            return "";
        }

        return moment(this.handler.value).format(this.getFormat());
    }

    private set value(value: string) {
        this.handler.value = value ? new Date(value) : null;
    }

    private getFormat(): string {
        if (this.handler.mode === FieldType.Date) {
            return "YYYY-MM-DD";
        }

        if (this.handler.mode === FieldType.Time) {
            return "HH:mm";
        }

        return "YYYY-MM-DD HH:mm";
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.handler.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderInput(validation),
                        }}
                    ></validation-provider>
                </div>
            );
            //return <div class="m-0">{this.renderInput()}</div>;
        } else {
            return <div />;
        }
    }

    private renderInput(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        items.push(
            <alt-date-time
                id={this.handler.id}
                placeholder={this.handler.placeholder}
                disabled={this.handler.disabled}
                state={errors.length > 0 ? "error" : null}
                mode={this.handler.mode}
                v-model={this.value}
            />,
        );

        if (errors.length > 0) {
            items.push(<small class="text-danger">{errors[0]}</small>);
        }
        return items;
    }
}
