import { IStore, IGood, IGoodCreateDto, IGoodUpdateDto, IGoodCategory, ITemplateLabel, ICompany, Locale } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    Control,
    Dropdown,
    DropdownClickEventArgs,
    Icon,
    MultiSelect,
    Panel,
    Select,
    Tags,
    TextArea,
    TextBox,
} from "@core/components/alt-ui/controls";
import { WarrantyControl } from "@core/controls/warranty.control";
import { Localizer } from "@/i18n/localizer";
import { BarcodeControl } from "@core/controls/barcode/barcode-control";
import { GoodPrintModal } from "./good-print.modal";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface GoodModalContext {
    company: ICompany;
    good?: IGood; // товар для редактирования
    stores: IStore[];
    selectedStores: IStore[];
    categories: IGoodCategory[];
    showBarcode: boolean;
    templates?: ITemplateLabel[];
    goodPrintModal?: GoodPrintModal;
}

export class GoodModal extends Modal<GoodModalContext> {
    private bcBarcode!: BarcodeControl;
    private сbStore!: Select<IStore>;
    private tbSku!: TextBox;
    private tbName!: TextBox;
    private taDescription!: TextArea;
    private msCategory!: MultiSelect<IGoodCategory>;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    private tbQuantity!: TextBox;
    private wcWarranty!: WarrantyControl;
    private tgTags!: Tags;

    private pnlFooterCreate!: Panel;
    private pnlFooterEdit!: Panel;
    private pnlToolsWrapper!: Panel;
    private pnlButtonsWrapper!: Panel;

    private btnMove!: Button;
    private ddPrint!: Dropdown<ITemplateLabel>;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context!: GoodModalContext;
    private categories!: IGoodCategory[];
    public onCreate: ((dto: IGoodCreateDto) => Promise<IGood | null>) | null = null;
    public onUpdate: ((good: IGood, dto: IGoodUpdateDto) => Promise<IGood | null>) | null = null;
    public onClickMove: ((good: IGood) => void) | null = null;

    public constructor() {
        super("good-modal", "");
        this.initializeControls();
    }

    public show(context: GoodModalContext): Promise<void> {
        this.context = context;
        this.categories = this.context.categories.filter(e => !e.archived);

        this.title = context.good ? "Изменение товара" : "Новый товар";

        this.initializeControls();

        if (context.good) {
            this.populateControls(context.good);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.bcBarcode = new BarcodeControl({
            companyId: this.context?.company.id ?? "",
        });
        this.сbStore = new Select<IStore>();
        this.tbSku = new TextBox();
        this.tbName = new TextBox();
        this.taDescription = new TextArea();
        this.msCategory = new MultiSelect<IGoodCategory>();
        this.tbCost = new TextBox();
        this.tbPrice = new TextBox();
        this.tbQuantity = new TextBox();
        this.wcWarranty = new WarrantyControl();
        this.tgTags = new Tags();

        this.btnMove = new Button();
        this.ddPrint = new Dropdown<ITemplateLabel>();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        this.pnlFooterEdit = new Panel();
        this.pnlFooterCreate = new Panel();
        this.pnlToolsWrapper = new Panel();
        this.pnlButtonsWrapper = new Panel();

        //

        this.bcBarcode.visible = this.context?.showBarcode ?? false;

        this.сbStore.id = "good.store";
        this.сbStore.label = "Склад";
        this.сbStore.items = this.context?.stores ?? [];
        this.сbStore.textField = ac => ac.info.name;
        this.сbStore.descriptionField = ac => ac.info.description;
        this.сbStore.selectedIndex = this.сbStore.items.length > 0 ? 0 : -1;

        this.tbSku.id = "good.sku";
        this.tbSku.label = "Артикул";
        this.tbSku.text = "";

        this.tbName.id = "good.name";
        this.tbName.label = "Наименование";
        this.tbName.validation = "required";
        this.tbName.text = "";

        this.taDescription.id = "good.description";
        this.taDescription.label = "Описание";
        this.taDescription.text = "";

        this.msCategory.id = "good.category";
        this.msCategory.label = "Категории";
        this.msCategory.items = this.getAllowedCategories();
        this.msCategory.textField = e => e.name;
        this.msCategory.descriptionField = e => e.description;

        this.tbCost.id = "good.cost";
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "required|money|unsigned";
        this.tbCost.text = "";

        this.tbPrice.id = "good.price";
        this.tbPrice.label = "Цена продажи";
        this.tbPrice.validation = "required|money|unsigned";
        this.tbPrice.text = "";

        this.tbQuantity.id = "good.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = "required|numeric|unsigned";
        this.tbQuantity.text = "";

        this.wcWarranty.id = "good.warranty";
        this.wcWarranty.label = "Гарантия";

        this.tgTags.id = "good.tags";
        this.tgTags.label = "Метки";
        this.tgTags.placeholder = "Введите новую метку";

        //

        this.btnMove.id = "good.move";
        this.btnMove.help = "Переместить";
        this.btnMove.variant = "flat-primary";
        this.btnMove.icon = new Icon();
        this.btnMove.icon.icon = "ShareIcon";
        this.btnMove.addClickHandler(this.clickMove.bind(this));

        this.ddPrint.id = "good.print";
        this.ddPrint.visible = Array.isArray(this.context?.templates) && this.context.templates.length > 0;
        this.ddPrint.hideCaret = true;
        this.ddPrint.variant = "flat-primary";
        this.ddPrint.icon = new Icon();
        this.ddPrint.icon.icon = "PrinterIcon";
        this.ddPrint.items = this.context?.templates ?? [];
        this.ddPrint.help = "Печать";
        this.ddPrint.textField = template => template.name;
        this.ddPrint.addClickHandler(this.openModalPrint.bind(this));

        this.btnCancel.id = "good.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "good.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        if (this.context?.good) {
            this.pnlButtonsWrapper.addControl(this.btnCancel);
            this.pnlButtonsWrapper.addControl(this.btnSave);

            if (this.context.good?.info.quantity > 0) {
                this.pnlToolsWrapper.class = "flex";
                this.pnlToolsWrapper.addControl(this.btnMove);
                this.pnlToolsWrapper.addControl(this.ddPrint);

                this.pnlFooterEdit.class = "flex justify-between mt-2";
                this.pnlFooterEdit.addControl(this.pnlToolsWrapper);
                this.pnlFooterEdit.addControl(this.pnlButtonsWrapper);
            } else {
                this.pnlFooterEdit.class = "flex justify-end mt-2";
                this.pnlFooterEdit.addControl(this.btnCancel);
                this.pnlFooterEdit.addControl(this.btnSave);
            }
        } else {
            this.pnlFooterCreate.class = "flex justify-end mt-2";
            this.pnlFooterCreate.addControl(this.btnCancel);
            this.pnlFooterCreate.addControl(this.btnSave);
        }
    }

    private populateControls(good: IGood): void {
        const locale = good.storeRef?.info.locale ?? Locale.RU;

        // В context.categories лежат все категории, в т.ч и удалённые
        // Мы ищем в них потому что у пользователя мог остаться сохраненный фильтр
        // А сама категория была архивирован
        const categories = good.info.categories
            ?.map(categoryId => this.context.categories.find(e => e.id === categoryId) ?? null)
            .filter(e => e) as IGoodCategory[];

        this.bcBarcode.barcodes = good.info.barcodes ?? [];
        this.сbStore.visible = false;
        this.tbSku.text = good.info.sku ?? "";
        this.tbName.text = good.info.name;
        this.taDescription.text = good.info.description ?? "";
        this.msCategory.selectedItems = categories;
        this.tbCost.text = Localizer.number(good.info.cost, locale);
        this.tbPrice.text = Localizer.number(good.info.price, locale);
        this.tbQuantity.text = good.info.quantity.toString();
        this.wcWarranty.value = good.info.warranty;
        this.tgTags.tags = good.tags ?? [];
    }

    public get controls(): Control[] {
        const common = [
            this.bcBarcode,
            this.сbStore,
            this.tbSku,
            this.tbName,
            this.taDescription,
            this.msCategory,
            this.tbCost,
            this.tbPrice,
            this.tbQuantity,
            this.wcWarranty,
            this.tgTags,
        ] as Control[];

        if (this.context?.good) {
            return common.concat([this.pnlFooterEdit]);
        }

        return common.concat([this.pnlFooterCreate]);
    }

    private getAllowedCategories(): IGoodCategory[] {
        if (!this.context?.good || !this.categories) {
            return [];
        }

        const goodStoreId = this.context.good?.storeRef?.id;

        return this.categories.filter(e => (e.stores as IStore[]).some(s => s.id === goodStoreId) || !e.stores.length);
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.context.good ? await this.updateGood(this.context.good) : await this.createGood();

        if (result) {
            this.hide(result);
        }
    }

    private async clickMove(sender: any, e: any): Promise<void> {
        if (!this.onClickMove) {
            return;
        }

        if (!this.context.good) {
            return;
        }

        this.onClickMove(this.context.good);

        this.hide();
    }

    private async createGood(): Promise<IGood | null> {
        if (!this.onCreate) {
            return null;
        }

        if (!this.сbStore.selectedItem) {
            return null;
        }

        const store = this.сbStore.selectedItem;

        const costStr = this.tbCost.text.replace(",", ".");
        const cost = parseFloat(costStr);

        const priceStr = this.tbPrice.text.replace(",", ".");
        const price = parseFloat(priceStr);

        const dto: IGoodCreateDto = {
            store: store.id,
            info: {
                sku: this.tbSku.text,
                name: this.tbName.text,
                description: this.taDescription.text,
                categories: this.msCategory.selectedItems.map(e => e.id),
                barcodes: this.bcBarcode.barcodes,
                cost: cost,
                price: price,
                quantity: parseInt(this.tbQuantity.text),
                warranty: this.wcWarranty.value,
            },
            tags: this.tgTags.tags,
        };

        return await this.onCreate(dto);
    }

    private async updateGood(good: IGood): Promise<IGood | null> {
        if (!this.onUpdate) {
            return null;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const cost = parseFloat(costStr);

        const priceStr = this.tbPrice.text.replace(",", ".");
        const price = parseFloat(priceStr);

        const dto: IGoodUpdateDto = {
            info: {
                sku: this.tbSku.text,
                name: this.tbName.text,
                description: this.taDescription.text,
                categories: this.msCategory.selectedItems.map(e => e.id),
                barcodes: this.bcBarcode.barcodes,
                cost: cost,
                price: price,
                quantity: parseInt(this.tbQuantity.text),
                warranty: this.wcWarranty.value,
            },
            tags: this.tgTags.tags,
        };

        return await this.onUpdate(good, dto);
    }

    private openModalPrint(sender: Dropdown, args: DropdownClickEventArgs<ITemplateLabel>): void {
        if (this.context.good && this.context.goodPrintModal && args.item) {
            const context = {
                template: args.item,
                goods: [this.context.good],
                stores: this.context.stores,
                company: this.context.company,
            };
            this.context.goodPrintModal.show(context);
            this.hide();
        }
    }
}
