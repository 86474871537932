import { IAccount, IEmployee, ITableFilter, ITransactionItem, IUser } from "@lib";
import { Control, DateRange, MultiSelect } from "@/@core/components/alt-ui/controls";
import { Footer } from "@/@core/controls/footer.control";
import { NumericRangeControl } from "@/@core/controls/numeric-range.control";
import { FilterController } from "@/@core/filters/filter-controller";
import { FilterType, FilterPair } from "@/utils/filter";

export type AccountsFilter = Partial<{
    account: string[];
    item: string[];
    user: string[];
    createdAt: FilterPair;
    value: FilterPair;
}>;

export interface IAccountsFilterContext {
    accounts: IAccount[];
    transactionItems: ITransactionItem[];
    employees: IEmployee[];

    defaultFilter: AccountsFilter;
    currentFilter: ITableFilter[];
}

export class AccountsFilterController extends FilterController<IAccountsFilterContext, void> {
    public id: string = "accounts-filter";
    public title: string = "Фильтр";

    public filter = {
        account: {
            control: new MultiSelect<IAccount>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.account.control.selectedItems.map(account => account.id);
            },
            set: (ids: string[]): void => {
                this.filter.account.control.selectedItems = this.findItemsByIds(this.context.accounts, ids);
            },
        },
        createdAt: {
            control: new DateRange(),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.createdAt.control.value;
            },
            set: (date: FilterPair): void => {
                this.filter.createdAt.control.value = date;
            },
        },
        value: {
            control: new NumericRangeControl({
                id: this.getControlId("value"),
                title: "Сумма",
                validation: "",
            }),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.value.control.value;
            },
            set: (v: FilterPair): void => {
                this.filter.value.control.value = v;
            },
        },
        item: {
            control: new MultiSelect<ITransactionItem>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.item.control.selectedItems.map((item: ITransactionItem) => item.id);
            },
            set: (ids: string[]): void => {
                this.filter.item.control.selectedItems = this.findItemsByIds(this.context.transactionItems, ids);
            },
        },
        user: {
            control: new MultiSelect<IUser>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.user.control.selectedItems.map(e => e.id);
            },
            set: (ids: string[]): void => {
                this.filter.user.control.selectedItems = this.findItemsByIds(
                    this.context.employees.map(e => e.userRef),
                    ids,
                );
            },
        },
    };

    private ftFooter!: Footer;

    private context!: IAccountsFilterContext;

    public onSave: ((filter: ITableFilter[]) => Promise<boolean>) | null = null;

    constructor() {
        super();
    }

    public init(context: IAccountsFilterContext): void {
        this.context = context;

        this.initControls();
        this.initFooter();
        this.populateFilters();
    }

    public show(context: IAccountsFilterContext): Promise<void> {
        this.context = context;
        this.initControls();
        this.initFooter();
        this.populateFilters();

        return super.show(context);
    }

    // Init

    private initControls(): void {
        this.filter.account.control = new MultiSelect<IAccount>();
        this.filter.account.control.id = this.getControlId("account");
        this.filter.account.control.label = "Счёт";
        this.filter.account.control.items = this.context?.accounts ?? [];
        this.filter.account.control.textField = e => e.info.name;

        this.filter.createdAt.control = new DateRange();
        this.filter.createdAt.control.id = this.getControlId("createdAt");
        this.filter.createdAt.control.label = "Дата и время";

        this.filter.value.control = new NumericRangeControl({
            id: this.getControlId("value"),
            title: "Сумма",
            validation: "",
        });

        this.filter.item.control = new MultiSelect<ITransactionItem>();
        this.filter.item.control.id = this.getControlId("item");
        this.filter.item.control.label = "Статья";
        this.filter.item.control.items = this.context?.transactionItems ?? [];
        this.filter.item.control.textField = e => e.name;

        this.filter.user.control = new MultiSelect<IUser>();
        this.filter.user.control.id = this.getControlId("user");
        this.filter.user.control.label = "Сотрудник";
        this.filter.user.control.items = this.context?.employees.map(e => e.userRef) ?? [];
        this.filter.user.control.textField = e => e.info.name;
    }

    private initFooter(): void {
        this.ftFooter = new Footer({
            okText: "Сохранить",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateFilters(): void {
        if (!this.context.currentFilter) {
            return;
        }

        const filters = this.getLocalFilter(this.context.currentFilter);

        Object.entries(filters).forEach(([k, filter]) => {
            const key = k as keyof AccountsFilter;

            if (!this.filter[key]) {
                return;
            }

            this.filter[key].set(filter as any);
        });
    }

    public get controls(): Control[] {
        return Object.entries(this.filter).map(([_, filter]) => filter.control);
    }

    public get footer(): Control {
        return this.ftFooter;
    }

    // /Init

    public get tableFilter(): ITableFilter[] {
        return this.getTableFilter(this.filter);
    }

    public async clickSave(): Promise<void> {
        if (!this.onSave) {
            return;
        }

        const result = await this.onSave(this.tableFilter);

        if (result) {
            this.hide();
        }
    }

    public clickCancel(): void {
        this.hide();
    }
}
