import { IEmployee } from "@lib";

export function getTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: !context.can.update && !context.can.delete ? 50 : 120,
            hide: false,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: async (employee: IEmployee) => await context.openModalUpdate(employee),
                        },
                        {
                            id: "permissions",
                            description: "Права доступа",
                            icon: "ShieldIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-dark cursor-pointer",
                            isVisible: () => context.can.update,
                            click: async (employee: IEmployee) => await context.openModalPermissions(employee),
                        },
                        {
                            id: "salary",
                            description: "Зарплата",
                            iconPack: "va",
                            icon: "money",
                            classes: "h-1 w-1 mr-0.75 hover:text-success cursor-pointer",
                            isVisible: (employee: IEmployee) => context.can.readSalary(employee),
                            click: (employee: IEmployee) => context.redirectToSalary(employee),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (employee: IEmployee) => context.confirmDelete(employee),
                        },
                    ],
                };
            },
        },
        {
            colId: "name",
            field: "userRef.info.name",
            headerName: "Имя",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "position",
            field: "position",
            headerName: "Должность",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,
        },
        {
            colId: "officialName",
            field: "userRef.info.officialName",
            headerName: "Официальное имя",
            sortable: true,
            filter: true,
            width: 250,
            hide: true,
        },
        {
            colId: "about",
            field: "userRef.info.about",
            headerName: "О сотруднике",
            sortable: true,
            filter: true,
            width: 500,
            hide: true,
        },
        {
            colId: "email",
            field: "userRef.info.contacts.email",
            headerName: "Почта",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "phone",
            field: "userRef.info.contacts.phone",
            headerName: "Телефон",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        // {
        //     colId: "defaultAccount",
        //     field: "defaultAccount",
        //     headerName: "Счет по умолчанию",
        //     sortable: true,
        //     filter: true,
        //     width: 250,
        //     hide: false,

        //     cellRendererFramework: "CellRendererChip",
        //     cellRendererParams: (params: any) => {
        //         return {
        //             name: params.value ? params.value.info.name : "Не указан",
        //             color: params.value ? "primary" : "danger",
        //         };
        //     },
        // },
    ];
}
