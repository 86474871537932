import { IField } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    Control,
    Panel,
    TextBox,
    Select,
    Label,
    ComboBoxChangedEventArgs,
} from "@core/components/alt-ui/controls";
import { FieldSettings } from "./field-settings";
import { FieldSettingsCustom } from "./field-settings-custom";
import { FieldSettingsStandart } from "./field-settings-standart";

export type MacroFieldAssociation = { macro: string; field: IField };

export interface IFieldModalContext {
    fields?: IField[];
    field?: IField;

    /** Возможность добавить пользовательское поле. */
    canCreateCustomField?: boolean;

    /** Возможность задать макросы для поля. */
    canSpecifyMacro?: boolean;

    /** Уже задействованные макросы. */
    macros?: MacroFieldAssociation[];
}

export class FieldModal extends Modal<IFieldModalContext, IField> {
    private cbField!: Select<IField>;
    private tbField!: TextBox;
    private lbFieldSettings!: Label;
    private pnlFieldSettings!: FieldSettings;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: IFieldModalContext | null = null;

    public constructor() {
        super("field-modal", "");
        this.initializeControls();
    }

    public show(context: IFieldModalContext): Promise<IField> {
        this.context = context;
        this.initializeControls();

        if (context.field) {
            this.title = "Изменение поля";
            this.btnSave.text = "Изменить";
            this.populateControls(context.field);
        } else {
            this.title = "Новое поле";
            this.btnSave.text = "Добавить";
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.lbFieldSettings = new Label();
        this.lbFieldSettings.text = "Настройки поля";
        this.lbFieldSettings.class = "text-center font-bold mt-1.5 mb-0.5";
        this.lbFieldSettings.visible = false;

        const settingsContext = {
            canSpecifyMacro: this.context?.canSpecifyMacro,
            macros: this.context?.macros ?? [],
        };

        this.pnlFieldSettings = new FieldSettingsStandart(settingsContext);
        this.pnlFieldSettings.visible = false;

        const fields = this.context?.fields ?? [];
        if (this.context?.canCreateCustomField) {
            fields.push({ id: "newfield", title: "<Новое поле>" });
        }

        this.tbField = new TextBox();
        this.tbField.id = "field.title";
        this.tbField.label = "Поле";
        this.tbField.validation = "required";
        this.tbField.disabled = true;
        this.tbField.visible = false;

        this.cbField = new Select<IField>();
        this.cbField.id = "field.newTitle";
        this.cbField.label = "Поле";
        this.cbField.items = this.context?.fields ?? [];
        this.cbField.textField = opt => opt.title;
        this.cbField.addChangedHandler(this.changeField.bind(this));

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "field.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "field.save";
        this.btnSave.text = "Добавить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(field: IField): void {
        this.cbField.visible = false;
        this.tbField.text = field.title ?? "";
        this.tbField.visible = true;
        this.lbFieldSettings.visible = true;

        const settingsContext = {
            field: field,
            canSpecifyMacro: this.context?.canSpecifyMacro,
            macros: this.context?.macros ?? [],
        };

        this.pnlFieldSettings = field.custom
            ? new FieldSettingsCustom(settingsContext)
            : new FieldSettingsStandart(settingsContext);
        this.pnlFieldSettings.visible = true;
    }

    public get controls(): Control[] {
        return [this.cbField, this.tbField, this.lbFieldSettings, this.pnlFieldSettings, this.pnlFooter];
    }

    private changeField(sender: any, e: ComboBoxChangedEventArgs<IField>): void {
        if (!e.item) {
            return;
        }

        const field = e.item;
        this.lbFieldSettings.visible = true;

        if (field.id === "newfield") {
            const settingsContext = {
                canSpecifyMacro: this.context?.canSpecifyMacro,
                macros: this.context?.macros ?? [],
            };

            this.pnlFieldSettings = new FieldSettingsCustom(settingsContext);
            this.pnlFieldSettings.visible = true;
        } else {
            const settingsContext = {
                field: field,
                canSpecifyMacro: this.context?.canSpecifyMacro,
                macros: this.context?.macros ?? [],
            };

            this.pnlFieldSettings = field.custom
                ? new FieldSettingsCustom(settingsContext)
                : new FieldSettingsStandart(settingsContext);
            this.pnlFieldSettings.visible = true;
        }
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        const validSettings = this.pnlFieldSettings.validate();

        if (!valid || !validSettings) {
            return;
        }

        const field = this.context?.field ? this.edit(this.context.field) : this.add();
        if (!field) {
            return;
        }

        this.hide(field);
    }

    private add(): IField | null {
        if (this.cbField.selectedItem?.id === "newfield") {
            // добавление нового кастомного поля
            return this.addNewField();
        }

        // добавление существующего стандартного или кастомного поля
        return this.addExistent();
    }

    private addNewField(): IField | null {
        if (!this.context) {
            return null;
        }

        return this.pnlFieldSettings.getCreatePayload();
    }

    private addExistent(): IField | null {
        if (!this.context) {
            return null;
        }

        return this.pnlFieldSettings.getUpdatePayload();
    }

    private edit(orig: IField): IField | null {
        if (!this.context) {
            return null;
        }

        return this.pnlFieldSettings.getUpdatePayload();
    }
}
