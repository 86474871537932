import { BButton } from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { BarcodeType, IBarcode, ICompanyFeatureBarcodesValidationResult } from "@lib";
import { Control } from "@core/components/alt-ui/controls";
import { Modal2Component } from "@core/components/alt-ui/modal-2";
import { ApiBaseUrl } from "@core/usecases/axios";
import { CompanyFeatureBarcodesUseCase } from "@core/usecases/company/company-feature-barcodes.usecase";
import { BarcodeCreateModal } from "./barcode-create.modal";
import { debounce } from "@/utils/delayed-operation";

export const barcodeList = [
    { id: BarcodeType.EAN13, name: "EAN-13" },
    { id: BarcodeType.EAN8, name: "EAN-8" },
    { id: BarcodeType.UPCA, name: "UPC-A" },
    { id: BarcodeType.CODE128, name: "Code 128" },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
interface BarcodeControlProps {
    companyId: string;
    onChangeType?: (type: BarcodeType) => void;
    selectedType?: BarcodeType;
}

export class BarcodeControl extends Control {
    public getComponentName(): string {
        return "BarcodeControlComponent";
    }

    public barcodeCreateModal: BarcodeCreateModal;

    private companyId: string;

    public _selectedType: BarcodeType | null = null;
    public get selectedType(): BarcodeType | null {
        return this._selectedType;
    }
    public set selectedType(type: BarcodeType | null) {
        this._selectedType = type;
        if (type && this.onChangeType) {
            this.onChangeType(type);
        }
    }

    private _barcodes: IBarcode[] = [];
    public get barcodes(): IBarcode[] {
        return this._barcodes;
    }
    public set barcodes(barcodes: IBarcode[]) {
        this._barcodes = [];
        for (const bc of barcodes) {
            this._barcodes.push({
                type: bc.type,
                code: bc.code,
            });
        }
    }

    public onChangeType: ((type: BarcodeType) => void) | null = null;

    public constructor({ companyId, selectedType, onChangeType }: BarcodeControlProps) {
        super();

        this.companyId = companyId;
        this._selectedType = selectedType ?? null;
        this.onChangeType = onChangeType ?? null;

        this.barcodeCreateModal = new BarcodeCreateModal();
        this.barcodeCreateModal.onCreate = this.onCreate.bind(this);
        this.barcodeCreateModal.onUpdate = this.onUpdate.bind(this);
        this.barcodeCreateModal.onDelete = this.onDelete.bind(this);
        this.barcodeCreateModal.onGenerate = this.onGenerate.bind(this);
        this.barcodeCreateModal.onValidate = this.onValidate.bind(this);
    }

    private onCreate(barcode: IBarcode): void {
        this._barcodes.push(barcode);
    }

    private onUpdate(barcodeIndex: number, barcode: IBarcode): void {
        this._barcodes = this._barcodes.map((e, index) => {
            if (index === barcodeIndex) {
                return barcode;
            }

            return e;
        });
    }

    private onDelete(barcodeIndex: number, barcode: IBarcode): void {
        this._barcodes = this._barcodes.filter((e, i) => i !== barcodeIndex);
    }

    private async onGenerate(type: BarcodeType): Promise<IBarcode> {
        const usecase = new CompanyFeatureBarcodesUseCase();
        const response = await usecase.generate(this.companyId, type);
        return response;
    }

    private async onValidate(barcode: IBarcode): Promise<ICompanyFeatureBarcodesValidationResult> {
        const usecase = new CompanyFeatureBarcodesUseCase();
        const response = await usecase.validate(this.companyId, barcode.type, barcode.code);
        return response;
    }
}

@Component({
    name: "barcode-control-component",
    components: {
        BButton,
        Modal2Component,
        ControlComponent: () => import("../../components/alt-ui/controls/control.component"),
    },
})
export class BarcodeControlComponent extends Vue {
    @Prop({ type: Object })
    private handler!: BarcodeControl;

    public get barcodes(): IBarcode[] {
        return this.handler.barcodes;
    }

    public async showCreateModal(): Promise<void> {
        const company = await this.$info.getCompany();

        this.handler.barcodeCreateModal.show({
            companyId: company.id,
            handler: this.handler,
        });
    }

    public async showEditModal(barcode: IBarcode, barcodeIndex: number): Promise<void> {
        const company = await this.$info.getCompany();

        this.handler.selectedType = null;

        this.handler.barcodeCreateModal.show({
            companyId: company.id,
            barcode,
            barcodeIndex,
            handler: this.handler,
        });
    }

    public getBarcodePreview(code: string, type: BarcodeType): string {
        return `${ApiBaseUrl}barcodes/?code=${code}&type=${type}`;
    }
}

export default BarcodeControlComponent;
