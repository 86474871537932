/** Статус (видимость) плагина. */
export enum PluginStatus {
    /** Опубликован. */
    Published = "published",

    /** Скрыт. */
    Hidden = "hidden",

    /** Доступен, если включен специальный ключ бета-тестирования. */
    Beta = "beta",

    /** Доступен только в режиме разработки. */
    Development = "development",
}
