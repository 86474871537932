import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import VueSelect from "vue-select";
import { BTooltip } from "bootstrap-vue";
import { MultiSelect } from ".";

@Component({
    name: "multi-select-component",
    components: { VueSelect, BTooltip },
})
export class MultiSelectComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: MultiSelect;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                //<div class="form-label-group m-0">
                <div>{this.renderParts()}</div>
            );
        } else {
            return <div />;
        }
    }

    private renderParts(): VNode[] {
        const items: VNode[] = [];

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        items.push(
            <vue-select
                class={this.handler.class}
                style={this.handler.style}
                disabled={this.handler.disabled}
                clearable={false}
                get-option-label={this.handler.textField}
                options={this.handler.items}
                v-model={this.handler.selectedItems}
                multiple={true}
                appendToBody
                scopedSlots={{
                    "no-options": () => "Нет подходящих вариантов",
                    option: (option: any) => this.renderOption(option),
                }}
            />,
        );

        return items;
    }

    private renderOption(option: any): VNode[] {
        const items: VNode[] = [];

        items.push(<span>{this.handler.textField ? this.handler.textField(option) : ""}</span>);

        if (this.handler.descriptionField) {
            items.push(<br />);
            items.push(<small class="text-xs opacity-75 truncate">{this.handler.descriptionField(option)}</small>);
        }

        return items;
    }
}
