import { Control } from "./control";
import { EventHandler } from "..";

export class CollapseOpenedEventArgs {
    opened!: boolean;
}

export class Collapse extends Control {
    public title: string = "";

    private _controls: Control[] = [];

    public get controls(): Control[] {
        return this._controls;
    }

    public getComponentName(): string {
        return "CollapseComponent";
    }

    public addControl(control: Control): void {
        this._controls.push(control);
    }

    public addControls(controls: Control[]): void {
        this._controls.push(...controls);
    }

    private _opened: boolean = false;
    public get opened(): boolean {
        return this._opened;
    }
    public set opened(opened: boolean) {
        if (opened === this._opened) {
            return;
        }

        this._opened = opened;
        this.notifyOpenedHandler();
    }

    private _openedHandlers = new Set<EventHandler<CollapseOpenedEventArgs>>();
    public addOpenedHandler(handler: EventHandler<CollapseOpenedEventArgs>): void {
        this._openedHandlers.add(handler);
    }
    public removeOpenedHandler(handler: EventHandler<CollapseOpenedEventArgs>): void {
        this._openedHandlers.delete(handler);
    }
    private notifyOpenedHandler(): void {
        const args: CollapseOpenedEventArgs = { opened: this._opened };
        for (const handler of this._openedHandlers) {
            handler(this, args);
        }
    }
}
