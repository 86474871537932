import { Label, Panel, TextBox } from "@core/components/alt-ui/controls";

export class NumericRangeControl extends Panel {
    public getComponentName(): string {
        return "PanelComponent";
    }

    private lbLabel!: Label;

    private pnInputWrapper!: Panel;
    private tbFrom!: TextBox;
    private tbTo!: TextBox;

    private _value: [string, string] = ["", ""];
    public get value(): [string, string] {
        return [this.tbFrom.text, this.tbTo.text];
    }

    public set value([from, to]: [string, string]) {
        this.tbFrom.text = from;
        this.tbTo.text = to;
    }

    public constructor({ id, title, validation }: { id: string; title: string; validation: string }) {
        super();

        this.lbLabel = new Label();
        this.lbLabel.id = `${id}.title`;
        this.lbLabel.text = title;
        this.lbLabel.class = "text-sm ml-0.5";

        this.tbFrom = new TextBox();
        this.tbFrom.id = `${id}.from`;
        this.tbFrom.class = "w-full";
        this.tbFrom.placeholder = "от";
        this.tbFrom.validation = validation;

        this.tbTo = new TextBox();
        this.tbTo.id = `${id}.to`;
        this.tbTo.class = "w-full";
        this.tbTo.placeholder = "до";
        this.tbTo.validation = validation;

        this.pnInputWrapper = new Panel();
        this.pnInputWrapper.id = `${id}.wrapper`;
        this.pnInputWrapper.class = "flex space-x-4";
        this.pnInputWrapper.addControls([this.tbFrom, this.tbTo]);

        this.addControls([this.lbLabel, this.pnInputWrapper]);
    }
}
