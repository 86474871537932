export class AltLoader {
    private state = false;

    private onChangedListeners: Function[] = [];
    public set onChanged(listener: Function) {
        this.onChangedListeners.push(listener);
    }

    public show(): void {
        this.state = true;
        this.notifyListeners();
    }

    public hide(): void {
        this.state = false;
        this.notifyListeners();
    }

    private notifyListeners(): void {
        for (const listener of this.onChangedListeners) {
            listener(this.state);
        }
    }
}
