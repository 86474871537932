import { Control, Label, Link, List, Panel } from "../controls";
import { Table, TableColumn } from "../table";

export type TableListColumn<T> = (item: T, index: number) => string | Control | null;
export type OnlyTableColumn<T> = (column: TableColumn<T>, item: T, index: number) => boolean;

export class TableList<T = any> extends Table<T> {
    public mode: "table" | "list" = "table";

    public header: TableListColumn<T> | null = null;
    public left: TableListColumn<T> | null = null;
    public onlyTableColumn: OnlyTableColumn<T> | null = null;

    public get table(): Table<T> | null {
        return this;
    }

    public get list(): List<T> | null {
        const list = new List<T>();
        list.id = this.id;
        list.left = (item, index) => (this.left ? this.left(item, index) : null);
        list.header = (item, index) => (this.header ? this.header(item, index) : null);
        list.content = (item, index) => {
            const sublist = new List();
            sublist.class = "my-0.5";
            sublist.classItem = c => "border-0 items-center px-0";
            sublist.classRight = c => "whitespace-nowrap";
            sublist.content = c => (typeof c.title === "string" ? c.title : c.title(item, index));
            sublist.right = c => c.cell(item, index);
            sublist.items = this.columns.filter(c => !this.onlyTableColumn || !this.onlyTableColumn(c, item, index));
            return sublist;
        };
        list.footer = item => {
            const pnl = new Panel();
            pnl.class = "flex";

            for (let i = 0; i < this.actions.length; ++i) {
                const action = this.actions[i];

                if (pnl.controls.length > 0) {
                    const separator = new Label();
                    separator.text = "|";
                    separator.style = "margin: 0rem 0.5rem !important";

                    pnl.addControl(separator);
                }

                const link = new Link();
                link.text = typeof action.title === "string" ? action.title : action.title(item, i);
                link.click = () => action.handler(item, i);

                pnl.addControl(link);
            }

            return pnl;
        };
        list.items = this.items;

        return list;
    }

    public getComponentName(): string {
        return "TableListComponent";
    }
}
