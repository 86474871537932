import { IAdminFlatAnalyticsResultDto, IAdminAnalyticsUseCase } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class AdminAnalyticsUseCase implements IAdminAnalyticsUseCase {
    public async getFlatAnalytics(): Promise<IAdminFlatAnalyticsResultDto> {
        try {
            const response = await Api.get<IAdminFlatAnalyticsResultDto>("/admin/analytics/flat");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
