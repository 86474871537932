


















import { Component, Prop, Vue } from "vue-property-decorator";
import VueDraggable from "vuedraggable";
import { Control } from "./control";
import { Draggable } from "./draggable";

@Component({
    name: "draggable-component",
    components: {
        VueDraggable,
        ControlComponent: () => import("../controls/control.component"),
    },
})
export default class DraggableComponent extends Vue {
    @Prop({ type: Object })
    private handler!: Draggable;

    private changePosition(e: any): void {
        // console.log(e.moved.element);
        // console.log(e.moved.oldIndex);
        // console.log(e.moved.newIndex);
        // console.log(this.handler.controls);

        // TODO: controls are already moved
        this.handler.notifyDraggedHandlers(e.moved.element, e.moved.oldIndex, e.moved.newIndex);
    }
}
