import { PermissionCommonSection, PermissionRight } from "@lib";
import { IMenuItem } from "./menu-item";

export const MenuItems: IMenuItem[] = [
    // {
    //     name: "Главная",
    //     route: "home",
    //     icon: "HomeIcon",
    //     i18n: "main.dashboard.title",
    // },
    {
        name: "Заявки",
        route: "orders",
        iconPack: "alt",
        icon: "clipboard-chart",
        i18n: "main.orders.title",
        visible: (context: Vue) => true,
    },
    {
        name: "Магазин",
        route: "sales",
        icon: "ShoppingCartIcon",
        i18n: "main.sales.title",
        visible: (context: Vue) => true,
    },
    {
        name: "Финансы",
        route: "accounts",
        iconPack: "alt",
        icon: "finance",
        i18n: "main.accounts.title",
        visible: (context: Vue) => true,
    },
    {
        name: "Склады",
        route: "stores",
        icon: "PackageIcon",
        i18n: "main.stores.title",
        visible: (context: Vue) => true,
    },
    {
        name: "Клиенты",
        route: "clients",
        icon: "UsersIcon",
        i18n: "main.clients.title",
        visible: (context: Vue) => true,
    },
    {
        name: "Отчёты",
        route: "reports",
        icon: "TrendingUpIcon",
        i18n: "main.reports.title",
        visible: (context: Vue) => context.$secure.checkCommon(PermissionCommonSection.Reports, PermissionRight.Read),
    },
    {
        name: "Настройки",
        icon: "SettingsIcon",
        i18n: "main.settings.title",
        visible: (context: Vue) => true,
        submenu: [
            {
                name: "Профиль",
                route: "settings-profile",
                i18n: "main.settings.profile.title",
                visible: (context: Vue) => true,
            },
            {
                name: "Компания",
                route: "settings-company",
                i18n: "main.settings.company.title",
                visible: (context: Vue) => true,
            },
            {
                name: "Списки",
                route: "settings-lists",
                i18n: "main.settings.lists.title",
                visible: (context: Vue) => true,
            },
            {
                name: "Шаблоны",
                route: "settings-templates",
                i18n: "main.settings.templates.title",
                visible: (context: Vue) => true,
            },
            {
                name: "Помощь",
                route: "settings-help",
                i18n: "main.settings.help.title",
                visible: (context: Vue) => true,
            },
        ],
    },
];
