



























import { Vue, Component, Prop } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { IStore, IGoodCategory } from "@lib";
import { StoresFilterController } from "./stores-filter-controller";

@Component({
    name: "stores-chips-bar",
    components: { BButton, VuePerfectScrollbar },
})
export default class StoresChipsBar extends Vue {
    @Prop({ type: Object, required: true })
    private filterController!: StoresFilterController;

    @Prop({ type: Array, required: true })
    private selectedStores!: IStore[];

    public get filter(): any {
        return this.filterController.localFilter;
    }

    public categories: IGoodCategory[] = [];

    public get allowedCategories(): IGoodCategory[] {
        const selectedStores = this.selectedStores.map(e => e.id);

        return this.categories.filter(
            e => (e.stores as IStore[]).some(s => selectedStores.includes(s.id)) || !e.stores.length,
        );
    }

    public get filterCategories() {
        return this.filter.category ?? [];
    }

    public async mounted(): Promise<void> {
        try {
            const company = await this.$info.getCompany();
            const service = this.$alt.system.usecase.createGoodCategoryUseCase();
            this.categories = await service.select(company.id);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    public onClickAll() {
        this.$emit("select-all");
    }

    public onClick(id: string) {
        this.$emit("select", id);
    }
}
