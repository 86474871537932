import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton, BTooltip } from "bootstrap-vue";
import VueSelect from "vue-select";
import { ISale, ISaleStage } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { TextArea } from "@core/components/alt-ui/controls";

@Component({
    name: "sale-view-stage",
    components: {
        BButton,
        BTooltip,
        VueSelect,
        ControlComponent,
    },
})
export default class SaleViewStage extends Vue {
    @Prop({ required: true })
    private sale!: ISale | null;

    @Prop({ type: Array, required: true })
    private stages!: ISaleStage[];

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private stage: ISaleStage | null = null;
    private taComment = new TextArea();

    private get isChanged(): boolean {
        if (this.sale && this.sale.stage && this.stage) {
            return this.sale.stage !== this.stage.id;
        }
        return false;
    }

    @Watch("sale", { immediate: true, deep: true })
    private onSaleChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        if (this.sale && this.sale.stage) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const stage = this.stages.find(st => st.id === this.sale!.stage);
            this.stage = stage ?? null;
        } else {
            this.stage = this.stages.length > 0 ? this.stages[0] : null;
        }

        this.taComment = new TextArea();
        this.taComment.id = "sale-view-stage.comment";
        this.taComment.placeholder = "Комментарий к изменению этапа";
    }

    private async applyChangeStage(): Promise<void> {
        try {
            if (!this.stage) {
                throw new Error("Этап не задан.");
            }

            if (!this.sale) {
                throw new Error("Продажа не задана.");
            }

            const companyId = this.sale.company as string;
            const saleId = this.sale.id;
            const stageId = this.stage.id;
            const comment = this.taComment.text;

            const usecase = this.$alt.system.usecase.createSaleUseCase();
            const sale = await usecase.changeStage(companyId, saleId, stageId, comment);

            this.$emit("sale-changed", sale);
        } catch {
            this.$alt.toast.error("Не удалось изменить этап.");
        }
    }

    private cancelChangeStage(): void {
        this.initValues();
    }
}
