import { MacroAutocomplete } from "@core/controls/document-template-editor/document-template-macros-autocompleter";
import { IMacro, ICompanyMacroOpenContext } from "../macro";

export const companyMacroAutocomplete: MacroAutocomplete[] = [
    {
        type: "autocompleteitem",
        text: "Компания",
        value: "компания",
        nested: [
            { type: "autocompleteitem", text: "Название", value: "название" },
            { type: "autocompleteitem", text: "Описание", value: "описание" },
            { type: "autocompleteitem", text: "Почта", value: "почта" },
            { type: "autocompleteitem", text: "Телефон", value: "телефон" },
            // { type: "autocompleteitem", text: "Улица", value: "улица" },
            // { type: "autocompleteitem", text: "Город", value: "город" },
            // { type: "autocompleteitem", text: "Регион", value: "регион" },
            // { type: "autocompleteitem", text: "Страна", value: "страна" },
            // { type: "autocompleteitem", text: "Почтовый индекс", value: "индекс" },
            { type: "autocompleteitem", text: "Адрес", value: "адрес" },
            { type: "autocompleteitem", text: "Сайт", value: "сайт" },
        ],
    },
];

export const CompanyMacroList: IMacro<ICompanyMacroOpenContext>[] = [
    {
        alias: ["компания.название", "company.name"],
        open: ({ company }): string | undefined => company.info.name,
    },
    {
        alias: ["компания.описание", "company.description"],
        open: ({ company }): string | undefined => company.info.description,
    },
    {
        alias: ["компания.почта", "company.email"],
        open: ({ company }): string | undefined => company.info.contacts.email,
    },
    {
        alias: ["компания.телефон", "company.phone"],
        open: ({ company }): string | undefined => company.info.contacts.phone,
    },
    {
        alias: ["компания.улица", "company.street"],
        open: ({ company }): string | undefined => company.info.contacts.street,
    },
    {
        alias: ["компания.город", "company.city"],
        open: ({ company }): string | undefined => company.info.contacts.city,
    },
    {
        alias: ["компания.регион", "company.region"],
        open: ({ company }): string | undefined => company.info.contacts.region,
    },
    {
        alias: ["компания.страна", "company.country"],
        open: ({ company }): string | undefined => company.info.contacts.country,
    },
    {
        alias: ["компания.индекс", "company.zip"],
        open: ({ company }): string | undefined => company.info.contacts.zip,
    },
    {
        alias: ["компания.адрес", "company.address"],
        open: ({ company }): string | undefined => company.info.contacts.address,
    },
    {
        alias: ["компания.сайт", "company.website"],
        open: ({ company }): string | undefined => company.info.contacts.website,
    },
];
