import { EmployeeOrderSaleReportScope, IEmployee, IEmployeeOrderSaleReportResultDto, Locale } from "@lib";
import { datetime } from "@/filters/datetime";
import { moneyFormat } from "@/filters/money";
import { FilterPair } from "@/utils/filter";
import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";

export class EmployeeOrdersTableReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;
        const reportScope = options.filter.employeeReportScope ?? EmployeeOrderSaleReportScope.Orders;

        const from = dates[0];
        const to = dates[1];

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.employeeOrderSale(options.company, {
            timezone: new Date().getTimezoneOffset().toString(),
            from,
            to,
            employee: options.filter.employee ?? "",
            reportScope,
        });

        const classHeaderNum = "va-td va-td-num text-center";
        const classHeaderName = "va-td text-center";
        const classHeaderMoney = "va-td va-td-money text-center";

        const classCellNum = "va-td va-td-num text-center";
        const classCellName = "va-td text-left";
        const classCellMoney = "va-td va-td-money text-center";

        const classFooterName = "va-td text-right font-bold va-td-noborder";
        const classFooterMoney = "va-td va-td-money text-center font-bold";

        const header = [
            {
                cells: [
                    {
                        value: "#",
                        class: classHeaderNum,
                    },
                    {
                        value: this.getNumberColumnTitle(reportScope),
                        class: classHeaderName,
                    },
                    {
                        value: "Расходы",
                        class: classHeaderMoney,
                    },
                    {
                        value: "Выручка",
                        class: classHeaderMoney,
                    },
                    {
                        value: "Прибыль",
                        class: classHeaderMoney,
                    },
                ],
            },
        ];

        const total = {
            revenueSum: 0,
            expensesSum: 0,
            profit: 0,
        };

        const locale = Locale.RU;
        const body = report.map((reportItem: IEmployeeOrderSaleReportResultDto, index: number) => {
            total.revenueSum += reportItem.revenue;
            total.expensesSum += reportItem.expenses;
            total.profit += reportItem.profit;

            return {
                cells: [
                    {
                        value: (index + 1).toString(),
                        class: classCellNum,
                    },
                    {
                        value: this.getNumberRowTitle(reportItem),
                        class: classCellName,
                    },
                    {
                        value: moneyFormat(reportItem.expenses, { locale }),
                        class: classCellMoney,
                    },
                    {
                        value: moneyFormat(reportItem.revenue, { locale }),
                        class: classCellMoney,
                    },
                    {
                        value: moneyFormat(reportItem.profit, { locale }),
                        class: classCellMoney,
                    },
                ],
            };
        });

        const footer: any[] = [
            {
                cells: [
                    {
                        value: "Итого:",
                        class: classFooterName,
                        colspan: 2,
                    },
                    {
                        value: moneyFormat(total.expensesSum, { locale }),
                        class: classFooterMoney,
                    },
                    {
                        value: moneyFormat(total.revenueSum, { locale }),
                        class: classFooterMoney,
                    },
                    {
                        value: moneyFormat(total.profit, { locale }),
                        class: classFooterMoney,
                    },
                ],
            },
        ];

        const subtitle = from === to ? datetime(from, "LL") : `${datetime(from, "LL")} - ${datetime(to, "LL")}`;

        return {
            title: this.getReportTitle(reportScope, (options.context as any).getEmployee(options.filter.employee)),
            subtitle,
            items: [
                {
                    kind: ReportKind.Table,
                    table: {
                        header,
                        body,
                        footer,
                    },
                },
            ],
        };
    }

    private getReportTitle(reportScope: EmployeeOrderSaleReportScope, employee: IEmployee | undefined): string {
        const empString = employee ? employee.userRef.info.name : "?";
        switch (reportScope) {
            case EmployeeOrderSaleReportScope.Orders:
                return `Отчёт по заявкам: ${empString}`;
            case EmployeeOrderSaleReportScope.Sales:
                return `Отчёт по продажам: ${empString}`;
            case EmployeeOrderSaleReportScope.OrdersAndSales:
                return `Отчёт по заявкам и продажам: ${empString}`;
        }
    }

    private getNumberColumnTitle(reportScope: EmployeeOrderSaleReportScope): string {
        switch (reportScope) {
            case EmployeeOrderSaleReportScope.Orders:
                return "Номер заявки";
            case EmployeeOrderSaleReportScope.Sales:
                return "Номер продажи";
            case EmployeeOrderSaleReportScope.OrdersAndSales:
                return "Номер заявки/продажи";
        }
    }

    private getNumberRowTitle(reportItem: IEmployeeOrderSaleReportResultDto): string {
        if (reportItem.order) {
            return `Заявка №${reportItem.order.number}`;
        }

        if (reportItem.sale) {
            return `Продажа №${reportItem.sale.number}`;
        }

        return "";
    }
}
