import { IAdminAuthUseCase } from "@lib";
import { IAdminAuthLoginDto, IAdminAuthLoginResultDto } from "@lib";
import { IAdminAuthSpyLoginDto, IAuthLoginResultDto } from "@lib";
import { IAdminAuthRefreshResultDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { ApiAuth } from "../axios";

export class AdminAuthUseCase implements IAdminAuthUseCase {
    public async login(dto: IAdminAuthLoginDto): Promise<IAdminAuthLoginResultDto> {
        try {
            const response = await ApiAuth.post<IAdminAuthLoginResultDto>("/admin/auth/login", dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async spyLogin(dto: IAdminAuthSpyLoginDto): Promise<IAuthLoginResultDto> {
        try {
            const response = await ApiAuth.post<IAuthLoginResultDto>("/admin/auth/spy", dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async logout(): Promise<void> {
        try {
            await ApiAuth.post<void>("/admin/auth/logout");
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async refresh(): Promise<IAdminAuthRefreshResultDto> {
        try {
            const response = await ApiAuth.post<IAdminAuthRefreshResultDto>("/admin/auth/refresh");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
