import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { BButton, VBTooltip } from "bootstrap-vue";
import { IShop, ITableFilter } from "@lib";
import { PermissionType, PermissionRight } from "@lib";
import VaAutoButton from "@core/components/va-auto-button";
import { SalesFilterController } from "../sales-filter-controller";
import FilterModal from "@core/filters/filter-modal.vue";

@Component({
    name: "orders-toolbar",
    components: { BButton, VaAutoButton, FilterModal },
    directives: { "b-tooltip": VBTooltip },
})
export default class SalesToolbar extends Vue {
    @Prop({ type: Array, required: true })
    private shops!: IShop[];

    @Prop({ type: Array, required: true })
    private shopsSelected!: IShop[];

    @Prop({ type: Array, default: () => [] })
    private filter!: ITableFilter[];

    @Prop({ type: Object, required: true })
    public filterController!: SalesFilterController;

    @Emit("create")
    private startCreate(): void {
        return;
    }

    @Emit("change-view")
    private changeView(view: string): string {
        return view;
    }

    private get can(): any {
        const shops = this.shopsSelected;
        const secure = this.$secure;
        return {
            get create(): boolean {
                for (const shop of shops) {
                    const valid = secure.check(PermissionType.Shops, shop.id, PermissionRight.SalesCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    private get hasFilter(): boolean {
        return this.filter.length > 0 && !!this.filter.find(f => f.field !== "shop");
    }

    private showFilterModal(): void {
        this.$emit("show-filter");
    }

    private updateFilter(shops: IShop[]): void {
        this.filterController.filter.shop.set(shops.map(shop => shop.id));

        this.$emit("change-filter", this.filterController.tableFilter);
    }
}
