<template>
    <div>
        <h4>You are in home.</h4>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activePrompt: false,
            data: String,
            cards: [],
        };
    },
    methods: {
        onClose() {
            this.activePrompt = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
