import {
    IAccount,
    IEmployee,
    ISalary,
    ISalaryPayItemDto,
    ISalaryPayManyDto,
    ISalaryPaymentDto,
    ISalarySubject,
    Locale,
} from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Html, Panel, Select, TextBox } from "@core/components/alt-ui/controls";
import { ISalaryTableItem, SalaryTableItemType } from "./salary-table";
import { Formatter } from "@/utils/formatter";

export interface ISalaryPayAllModalContext {
    items: ISalaryTableItem[];
    accounts: IAccount[];
    employee?: IEmployee;
}

export class SalaryPayAllModal extends Modal<ISalaryPayAllModalContext> {
    private cbAccount!: Select<IAccount>;
    private htSummary!: Html;
    private tbAmount!: TextBox;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: ISalaryPayAllModalContext | null = null;

    public onPayAll:
        | ((salariesDto: ISalaryPayManyDto, paymentDto: ISalaryPaymentDto) => Promise<ISalary | null>)
        | null = null;

    public constructor() {
        super("salary-pay-all-modal", "Выплатить всё");
        this.initializeControls();
    }

    public show(context: ISalaryPayAllModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        const accounts = this.context?.accounts ?? [];
        const items = this.context?.items ?? [];
        const amount = this.getAmount(items);
        const locale = /*item.meta.locale ??*/ Locale.RU;
        const employee = this.context?.employee ?? null;

        let accountIndex = employee?.defaultAccount ? accounts.findIndex(a => a.id === employee.defaultAccount.id) : -1;
        if (accountIndex < 0) {
            accountIndex = accounts.length > 0 ? 0 : -1;
        }

        this.cbAccount = new Select<IAccount>();
        this.cbAccount.id = "salary-pay-all.account";
        this.cbAccount.label = "Счёт";
        this.cbAccount.items = this.context?.accounts ?? [];
        this.cbAccount.textField = a => {
            const balance = Formatter.money(a.balance, { locale: a.info.locale, currency: a.info.currency });
            const insufficient = a.balance < amount;
            return insufficient ? `${a.info.name} (${balance} - не хватает средств!)` : `${a.info.name} (${balance})`;
        };
        this.cbAccount.descriptionField = a => a.info.description;
        this.cbAccount.selectedIndex = accountIndex;

        this.htSummary = new Html();
        this.htSummary.id = "salary-pa-ally.summary";
        this.htSummary.html = this.getSummaryAll(items);

        this.tbAmount = new TextBox();
        this.tbAmount.id = "salary-pay-all.amount";
        this.tbAmount.label = "К оплате";
        this.tbAmount.text = Formatter.money(amount, { locale });
        this.tbAmount.disabled = true;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "salary-pay-all.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "salary-pay-all.save";
        this.btnSave.text = "Выплатить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private getSummaryAll(items: ISalaryTableItem[]): string {
        let summary = '<div class="ml-2 mt-1 mb-1">';
        for (const item of items) {
            const locale = item.meta.locale as Locale;

            if (item.type === SalaryTableItemType.Reserved) {
                const salaryStr = Formatter.money(item.amount, { locale });
                summary += `${item.name} (${salaryStr})<br/>`;
            }
            if (item.meta.order && !item.meta.work && !item.meta.material) {
                const salaryStr = Formatter.money(item.amount, { locale });
                summary += `Заявка №${item.meta.order.number} (${salaryStr})<br/>`;
            }
            if (item.meta.sale && !item.meta.good) {
                const salaryStr = Formatter.money(item.amount, { locale });
                summary += `Продажа №${item.meta.sale.number} (${salaryStr})<br/>`;
            }
        }
        summary += "</div>";
        return summary;
    }

    private getAmount(items: ISalaryTableItem[]): number {
        let salary = 0;
        for (const item of items) {
            if (item.type === SalaryTableItemType.Reserved) {
                salary += item.amount;
            }
            if (item.meta.order && !item.meta.work && !item.meta.material) {
                salary += item.amount;
            }
            if (item.meta.sale && !item.meta.good) {
                salary += item.amount;
            }
        }
        return salary;
    }

    public get controls(): Control[] {
        return [this.cbAccount, this.htSummary, this.tbAmount, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.onPayAll || !this.cbAccount.selectedItem) {
            return;
        }

        const items: ISalaryPayItemDto[] = [];
        for (const salaryItem of this.context.items) {
            let salaryId: string | undefined = undefined;
            const subject: ISalarySubject[] = [];

            if (salaryItem.type === SalaryTableItemType.Order) {
                subject.push({
                    order: {
                        amount: salaryItem.amount,
                        ref: salaryItem.meta.order?.id as string,
                    },
                });
            }

            if (salaryItem.type === SalaryTableItemType.Sale) {
                subject.push({
                    sale: {
                        amount: salaryItem.amount,
                        ref: salaryItem.meta.sale?.id as string,
                    },
                });
            }

            if (salaryItem.type === SalaryTableItemType.Reserved) {
                salaryId = salaryItem.meta.salary?.id;

                if (salaryItem.amount < 0) {
                    subject.push({
                        fine: {
                            amount: salaryItem.amount,
                            comment: salaryItem.meta.salary?.comment,
                        },
                    });
                } else {
                    subject.push({
                        bonus: {
                            amount: salaryItem.amount,
                            comment: salaryItem.meta.salary?.comment,
                        },
                    });
                }
            }

            items.push({
                amount: salaryItem.amount,
                subject: subject,
                reservedSalaryId: salaryId as string,
            });
        }

        const account = this.cbAccount.selectedItem;
        const salariesDto: ISalaryPayManyDto = {
            items: items,
            account: account.id,
            //comment: undefined,
        };

        const transacDescription = "Выплата";
        const paymentDto: ISalaryPaymentDto = {
            account: account.id,
            description: transacDescription,
        };

        const result = await this.onPayAll(salariesDto, paymentDto);

        if (result) {
            this.hide(result);
        }
    }
}
