import moment from "moment";
import { Locale } from "@lib";
import { datetime } from "@/filters/datetime";
import { getChartOptions } from "../data";
import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";
import { FilterPair } from "@/utils/filter";
import { moneyFormat } from "@/filters/money";

export class BaseChartReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;

        const from = dates[0];
        const to = dates[1];

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.base(options.company, {
            timezone: new Date().getTimezoneOffset().toString(),
            from,
            to,
        });

        const categories = report.dates.map(dt => this.dtFormat(dt));

        const series = [
            {
                name: "Выручка",
                data: report.revenue,
            },
            {
                name: "Расходы",
                data: report.expenses,
            },
            {
                name: "Прибыль",
                data: report.profit,
            },
        ];

        const subtitle = from === to ? datetime(from, "LL") : `${datetime(from, "LL")} - ${datetime(to, "LL")}`;
        const locale = Locale.RU;

        return {
            title: "Отчёт по основным показателям",
            subtitle: subtitle,
            items: [
                {
                    kind: ReportKind.Chart,
                    chart: {
                        type: "line",
                        series: series,
                        options: getChartOptions(categories, (value: any) => moneyFormat(value, { locale })),
                    },
                },
            ],
        };
    }

    private dtFormat(date: number | string | Date): string {
        return moment(date).format("MMM 'YY");
    }
}
