import { Currency, Locale } from "@lib";
import { Formatter } from "@/utils/formatter";

export abstract class Localizer {
    /**
     * Формат числа без разделения тысяч.
     * @param number Число.
     * @param locale Языковый стандарт.
     * @param fract Минимальное число знаков после запятой.
     */
    public static number(number: number, locale: Locale, fract?: number): string {
        return Formatter.number(number, { locale, grouping: false, minFraction: fract });
    }

    /**
     * Формат денег без разделения тысяч. Два знака после запятой.
     * @param number Число.
     * @param locale Языковый стандарт.
     */
    public static money(number: number, locale: Locale): string {
        return Formatter.money(number, { locale, grouping: false, showNullFraction: false });
    }

    /**
     * Формат денег с разделением тысяч и копейками (если они равны 0). Два знака после запятой.
     * @param number Число.
     * @param locale Языковый стандарт.
     */
    public static moneyFull(number: number, locale: Locale): string {
        return Formatter.money(number, { locale, grouping: true, showNullFraction: true });
    }

    /**
     * Формат денег со значком валюты без разделения тысяч. Два знака после запятой.
     * @param number Число.
     * @param currency Валюта.
     * @param locale Языковый стандарт.
     */
    public static currency(number: number, currency: Currency, locale: Locale): string {
        return Formatter.money(number, { locale, currency, grouping: false, showNullFraction: false });
    }

    /**
     * Значок валюты.
     * @param currency Валюта.
     */
    public static currencySymbol(currency: Currency): string {
        return Formatter.currencySymbol(currency);
    }
}
