import { Modal2 } from "@/@core/components/alt-ui/modal-2";
import { IStore, IGood, IGoodMoveDto } from "@lib";
import { Control, Label, Select, TextBox } from "@core/components/alt-ui/controls";
import { Footer } from "@/@core/controls/footer.control";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface GoodMoveModalContext {
    good: IGood;
    stores: IStore[];
}

export class GoodMoveModal extends Modal2<GoodMoveModalContext> {
    private сbStore!: Select<IStore>;
    private lbTitle!: Label;
    private tbQuantity!: TextBox;
    private lbQuantity!: Label;

    private ftFooter!: Footer;

    private context!: GoodMoveModalContext;
    public onConfirm: ((good: IGood, dto: IGoodMoveDto) => Promise<IGood | null>) | null = null;

    public constructor() {
        super("good-move-modal", "");
        this.initializeControls();
    }

    public show(context: GoodMoveModalContext): Promise<void> {
        this.context = context;

        this.title = "Перемещение товара";

        this.initializeControls();

        if (this.context) {
            this.populateControls();
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.lbTitle = new Label();
        this.сbStore = new Select<IStore>();
        this.tbQuantity = new TextBox();
        this.lbQuantity = new Label();

        //

        this.lbTitle.id = "good-move.title";

        this.сbStore.id = "good-move.store";
        this.сbStore.label = "Склад";
        this.сbStore.items = this.context?.stores ?? [];
        this.сbStore.textField = ac => ac.info.name;
        this.сbStore.descriptionField = ac => ac.info.description;
        this.сbStore.selectedIndex = this.сbStore.items.length > 0 ? 0 : -1;

        this.tbQuantity.id = "good-move.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.text = "1";

        this.lbQuantity.id = "good-move.quantity-label";

        //

        this.ftFooter = new Footer({
            okText: "Переместить",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(): void {
        this.lbTitle.text = `Перемещение товара: ${this.context.good.info.name}`;

        this.сbStore.items = this.context.stores.filter(e => e.id !== this.context.good.store);
        this.сbStore.selectedIndex = this.сbStore.items.length > 0 ? 0 : -1;
        this.сbStore.selectedItem = this.сbStore.items[this.сbStore.selectedIndex];

        this.tbQuantity.validation = `required|numeric|unsigned|min_value:1|max_value:${this.context.good.info.quantity}`;

        this.lbQuantity.text = `Всего на складе: ${this.context.good.info.quantity}`;
    }

    public get footer(): Control {
        return this.ftFooter;
    }

    public get controls(): Control[] {
        return [this.lbTitle, this.сbStore, this.tbQuantity, this.lbQuantity];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = await this.confirmMove();

        if (result) {
            this.hide(result);
        }
    }

    private async confirmMove(): Promise<IGood | null> {
        if (!this.onConfirm) {
            return null;
        }

        if (!this.сbStore.selectedItem) {
            return null;
        }

        const dto: IGoodMoveDto = {
            id: this.context.good.id,
            quantity: parseInt(this.tbQuantity.text),
            store: this.сbStore.selectedItem.id,
        };

        return await this.onConfirm(this.context.good, dto);
    }
}
