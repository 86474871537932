import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import {
    ISale,
    ISaleGood,
    IStore,
    IDiscount,
    DiscountType,
    IGood,
    IAccount,
    IShop,
    ISelectQuery,
    Currency,
    IWarranty,
    Locale,
    ICompany,
    PermissionType,
    PermissionRight,
    ISaleGoodDto,
    ISaleGoodCreateDto,
    ISaleGoodPaymentDto,
    ISaleGoodFromStoreDto,
    ISaleGoodUpdateDto,
    PaymentType,
} from "@lib";
import TableListComponent from "@core/components/alt-ui/table-list/table-list.component.vue";
import { TableAction, TableColumn, TableFooter } from "@core/components/alt-ui/table/table";
import { TableList } from "@core/components/alt-ui/table-list";
import { Modal2Component } from "@core/components/alt-ui/modal-2/modal-2.component";
import { Icon, Label, NumberBox, Panel } from "@core/components/alt-ui/controls";
import SaleSearchGoods from "../../sale-create-form/sale-search-goods.vue";
import SaleSearchGoodsOld from "./sale-search-goods.vue";
import { SaleGoodModal } from "./sale-good.modal";
import { currencyHeader, moneyFormat } from "@/filters/money";
import router from "@/router/router";
import { Uuid } from "@/utils/uuid";
import { AppException } from "@/exceptions";

export interface ISaleGoodItem {
    id?: string;
    sku?: string;
    name: string;
    description?: string;
    cost: number;
    price: number;
    quantity: number;
    quantityOrig: number;
    warranty?: IWarranty;

    accountId?: string;
    storeId?: string;
    goodId?: string;
    goodRef?: IGood;
}

@Component({
    name: "sale-view-tabs-goods",
    components: {
        BButton,
        TableListComponent,
        Modal2Component,
        SaleSearchGoods,
        SaleSearchGoodsOld,
    },
})
export default class SaleViewTabsGoods extends Vue {
    @Prop({ required: true })
    private sale!: ISale;

    @Prop({ type: Array, default: () => [] })
    private stores!: IStore[];

    @Prop({ default: () => null })
    private discount!: IDiscount | null;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private company!: ICompany;
    private accounts: IAccount[] = [];
    private items: ISaleGoodItem[] = [];
    private changed: boolean = false;
    private searchUpdateId = 1;
    private searchGoodsNew = true;

    private table: TableList<ISaleGoodItem>;
    private saleGoodModal: SaleGoodModal;

    private get isLargeScreen(): boolean {
        return this.$info.ui.windowWidth >= 768;
    }

    private get can(): any {
        const secure = this.$secure;

        return {
            readGoods(storeId: string): boolean {
                return secure.check(PermissionType.Stores, storeId, PermissionRight.GoodsRead);
            },
            saleGoods(storeId: string): boolean {
                return secure.check(PermissionType.Stores, storeId, PermissionRight.GoodsSale);
            },
        };
    }

    private get availableStores(): IStore[] {
        return this.stores.filter(s => this.can.readGoods(s.id) && this.can.saleGoods(s.id));
    }

    public constructor() {
        super();

        this.table = new TableList<ISaleGoodItem>();
        this.table.id = "sale-goods-table";
        this.table.mode = this.isLargeScreen ? "table" : "list";
        this.table.header = i => i.name.toUpperCase();
        this.table.columns = this.getTableColumns();
        this.table.actions = !this.readonly ? this.getTableActions() : [];
        this.table.footer = this.getTableFooter();
        this.table.noDataText = "Пока нет товаров";
        //this.table.left = (item, index) => (index + 1).toString();
        this.table.onlyTableColumn = (column, item, index) => column.title === "#" || column.title === "Наименование";

        this.saleGoodModal = new SaleGoodModal();
        this.saleGoodModal.onGetGoodFromStore = this.takeGoodFromStore.bind(this);
        this.saleGoodModal.onCreateNewGood = this.createNewGood.bind(this);
    }

    private getTableColumns(): TableColumn<ISaleGoodItem>[] {
        const columns: TableColumn<ISaleGoodItem>[] = [
            {
                title: "#",
                classHeader: "text-left",
                classCell: "text-left",
                cell: (item, index) => (index + 1).toString(),
            },
            {
                title: "Наименование",
                classHeader: "text-left",
                classCell: "text-left",
                cell: item => {
                    const name = new Label();
                    name.class = "text-base";
                    name.text = item.name;

                    if (!item.goodId) {
                        return name;
                    }

                    const icon = new Icon();
                    icon.class = "cursor-pointer text-secondary hover:text-dark w-1 ml-0.75";
                    icon.icon = "ExternalLinkIcon";
                    icon.addClickHandler((s, e) => {
                        //router.push({ name: "stores", query: { id: item.goodId } }).catch(() => {});
                        const routeData = router.resolve({ name: "stores", query: { id: item.goodId } });
                        window.open(routeData.href, "_blank");
                    });

                    const group = new Panel();
                    group.class = "flex";
                    group.addControls([name, icon]);
                    return group;

                    //return item.name;
                },
            },
            {
                title: currencyHeader("Цена", this.currency),
                cell: item => moneyFormat(item.price, { locale: this.locale }),
            },
            {
                title: "Количество",
                cell: item => {
                    if (this.readonly) {
                        const lbl = new Label();
                        lbl.id = Uuid.new();
                        lbl.text = item.quantity.toString();
                        return lbl;
                    }

                    const max = item.goodRef?.info.quantity
                        ? item.quantityOrig + item.goodRef.info.quantity
                        : Number.MAX_VALUE;

                    const number = new NumberBox();
                    number.id = Uuid.new();
                    number.max = max;
                    number.value = item.quantity;
                    number.class = "ml-auto mr-auto";
                    number.style = "max-width: 12rem;";
                    number.disabled = this.readonly;
                    number.addValueChangedHandler((s, e) => {
                        item.quantity = e.value ?? 0;
                        this.goodsChanged();
                    });

                    return number;
                },
            },
            {
                title: currencyHeader("Стоимость", this.currency),
                cell: item => moneyFormat(item.price * item.quantity, { locale: this.locale }),
            },
        ];

        return columns;
    }

    private getTableActions(): TableAction<ISaleGoodItem>[] {
        const actions: TableAction<ISaleGoodItem>[] = [
            {
                title: "Удалить",
                icon: "Trash2Icon",
                class: "hover:text-danger",
                handler: async (item, index) => {
                    this.confirmDeleteItem(item);
                },
            },
        ];

        return actions;
    }

    private getTableFooter(): TableFooter<ISaleGoodItem>[] {
        if (!this.items || this.items.length === 0) {
            return [];
        }

        const footer: TableFooter<ISaleGoodItem>[] = [];

        footer.push({
            title: "Итого:",
            cell: i => moneyFormat(this.totalPrice, { locale: this.locale }),
        });

        if (this.totalDiscount > 0) {
            footer.push({
                title: this.discountText.length > 0 ? `Скидка ${this.discountText}:` : "Скидка:",
                cell: i => moneyFormat(this.totalDiscount, { locale: this.locale }),
            });

            footer.push({
                title: "Итого со скидкой:",
                cell: i => moneyFormat(this.totalPrice - this.totalDiscount, { locale: this.locale }),
            });
        }

        if (this.prepayment > 0.0) {
            footer.push({
                title: "Предоплата:",
                cell: i => moneyFormat(this.prepayment, { locale: this.locale }),
            });

            footer.push({
                title: "К оплате:",
                cell: i => moneyFormat(this.totalPrice - this.totalDiscount - this.prepayment, { locale: this.locale }),
            });
        }

        return footer;
    }

    private get currency(): Currency | undefined {
        return this.sale.shopRef?.info?.currency;
    }

    private get locale(): Locale | undefined {
        return this.sale.shopRef?.info?.locale;
    }

    private get totalPrice(): number {
        let total = 0.0;
        for (const item of this.items) {
            total += item.price * item.quantity;
        }
        return total;
    }

    private get discountText(): string {
        if (!this.discount) {
            return "";
        }

        switch (this.discount.type) {
            case DiscountType.Fixed:
                return ""; //moneyFormat(this.order.info.discount.value, { locale: this.locale, currency: this.currency });
            case DiscountType.Percent:
                return this.discount.value + "%";
        }

        return "";
    }

    private get totalDiscount(): number {
        if (this.discount?.type === DiscountType.Fixed) {
            return this.discount.value;
        } else if (this.discount?.type === DiscountType.Percent) {
            return (this.totalPrice * this.discount.value) / 100;
        }

        return 0;
    }

    private get prepayment(): number {
        if (!this.sale || !this.sale.payments) {
            return 0.0;
        }

        let sum = 0.0;
        for (const payment of this.sale.payments) {
            if (payment.type === PaymentType.Prepayment) {
                sum += payment.value;
            }
        }
        return sum;
    }

    @Watch("goods")
    private onGoodsChanged(): void {
        this.initValues();
    }

    @Watch("discount")
    private onDiscountChanged(): void {
        this.table.footer = this.getTableFooter();
    }

    private async mounted(): Promise<void> {
        await this.initValues();
    }

    private async initValues(): Promise<void> {
        this.company = await this.$info.getCompany();
        this.accounts = await this.$info.getAccounts();
        this.searchGoodsNew = localStorage.getItem("feature-search-goods-old") !== "1";

        this.items = this.sale.goods.map(
            sg =>
                ({
                    id: sg.id,
                    sku: sg.sku,
                    name: sg.name,
                    description: sg.description,
                    cost: sg.cost,
                    price: sg.price,
                    quantity: sg.quantity,
                    quantityOrig: sg.quantity,
                    warranty: sg.warranty,
                    storeId: sg.store,
                    goodId: sg.good,
                    goodRef: sg.goodRef,
                } as ISaleGoodItem),
        );

        this.table.items = this.items;
        this.table.columns = this.getTableColumns();
        this.table.actions = !this.readonly ? this.getTableActions() : [];
        this.table.footer = this.getTableFooter();
        this.searchUpdateId += 1;
        this.changed = this.hasChanges();
    }

    private goodsChanged(): void {
        this.table.items = this.items;
        this.table.footer = this.getTableFooter();
        this.changed = this.hasChanges();

        //this.$emit("change-goods", this.items);
    }

    private hasChanges(): boolean {
        if (this.items.length !== this.sale.goods.length) {
            return true;
        }

        for (const good of this.sale.goods) {
            const item = this.items.find(i => i.id === good.id);

            if (!item) {
                return true;
            }

            if (good.quantity !== item.quantity) {
                return true;
            }
        }

        return false;
    }

    private async saveChanges(): Promise<void> {
        try {
            const goodsDto = this.getGoodsDto();

            const usecase = this.$alt.system.usecase.createSaleUseCase();
            const sale = await usecase.changeGoods(this.sale.company, this.sale.id, goodsDto);

            this.$emit("sale-changed", sale);
        } catch {
            this.$alt.toast.error("Не удалось изменить данные продажи.");
        }
    }

    private async cancelChanges(): Promise<void> {
        const confirm = await this.$alt.message.confirm(
            "Вы уверены, что хотите отменить все изменения в таблице?",
            "Отмена изменений",
            {
                acceptText: "Подтвердить",
            },
        );

        if (confirm) {
            await this.initValues();
        }
    }

    //

    private async showSaleGoodModal(): Promise<void> {
        const modalSettings = await this.$settings.getSaleGoodModal();

        const context = {
            accounts: this.accounts,
            stores: this.availableStores,
            settings: modalSettings,
            searchGoods: this.searchGoods.bind(this),
        };

        await this.saleGoodModal.show(context);
    }

    private async createNewGood(dto: ISaleGoodItem): Promise<boolean> {
        this.items.push(dto);
        this.goodsChanged();
        this.$settings.setSaleGoodModal({
            accountWidthdrawal: !!dto.accountId,
            accountId: dto.accountId,
            storeAddition: !!dto.storeId,
            storeId: dto.storeId,
        });
        return true;
    }

    private async takeGoodFromStore(dto: ISaleGoodItem, orig: IGood): Promise<boolean> {
        const existed = this.items.find(i => (i.goodId ? i.goodId === dto.goodId : false));

        if (existed) {
            const quantityAdded = existed.quantity - existed.quantityOrig;

            if (quantityAdded + dto.quantity > orig.info.quantity) {
                this.$alt.toast.warning(`Нельзя добавить больше ${orig.info.quantity} шт. товара "${orig.info.name}".`);
                return false;
            }

            existed.quantity += dto.quantity;
            existed.price = dto.price;
        } else {
            this.items.push(dto);
        }

        this.goodsChanged();
        return true;
    }

    private async foundGood(dto: ISaleGoodItem, orig: IGood): Promise<void> {
        if (await this.takeGoodFromStore(dto, orig)) {
            this.searchUpdateId += 1;
            this.$alt.toast.success(`Товар "${orig.info.name}" добавлен.`, undefined, { autoHideDelay: 2000 });
        }
    }

    private async searchGoods(search: string): Promise<IGood[]> {
        try {
            if (this.availableStores.length === 0) {
                return [];
            }

            const companyId = this.company.id;

            const query: ISelectQuery = {
                search,
                limit: 10,
                offset: 0,
                "quantity[gt]": 0,
                "store[in]": this.availableStores.map(store => store.id),
            };
            const result = await this.$alt.system.usecase.createGoodUseCase().select(companyId, query);

            return result.data;
        } catch {
            return [];
        }
    }

    private async confirmDeleteItem(item: ISaleGoodItem): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Удалить товар "${item.name}" (${item.quantity} шт.)?`,
            "Удаление товара",
            { acceptText: "Удалить" },
        );

        if (result) {
            this.deleteGood(item);
        }
    }

    private deleteGood(item: ISaleGoodItem): void {
        const index = this.items.findIndex(g => g === item);
        this.items.splice(index, 1);
        this.goodsChanged();
    }

    private getGoodsDto(): ISaleGoodDto[] {
        const goodsDto: ISaleGoodDto[] = [];

        for (const item of this.items) {
            if (item.id) {
                goodsDto.push(this.getGoodUpdateDto(item));
                continue;
            }

            const dto = item.goodRef ? this.getGoodFromStoreDto(item) : this.getGoodCreateDto(item);
            goodsDto.push(dto);
        }

        return goodsDto;
    }

    private getGoodUpdateDto(item: ISaleGoodItem): ISaleGoodDto {
        const updateDto: ISaleGoodUpdateDto = {
            id: item.id,
            sku: item.sku,
            name: item.name,
            description: item.description,
            cost: item.cost,
            price: item.price,
            quantity: item.quantity,
            warranty: item.warranty,

            store: item.storeId,
            good: item.goodId,
        };

        return {
            update: updateDto,
        };
    }

    private getGoodCreateDto(item: ISaleGoodItem): ISaleGoodDto {
        const createDto: ISaleGoodCreateDto = {
            sku: item.sku,
            name: item.name,
            description: item.description,
            cost: item.cost,
            price: item.price,
            quantity: item.quantity,
            warranty: item.warranty,

            store: item.storeId,
            good: item.goodId,
        };

        let paymentDto: ISaleGoodPaymentDto | undefined;
        if (item.accountId) {
            paymentDto = {
                account: item.accountId,
                description: "Покупка товара",
            };
        }

        return {
            create: createDto,
            payment: paymentDto,
        };
    }

    private getGoodFromStoreDto(item: ISaleGoodItem): ISaleGoodDto {
        if (!item.storeId || !item.goodId) {
            throw new AppException(`Не указан склад в товаре "${item.name}".`);
        }

        const fromStoreDto: ISaleGoodFromStoreDto = {
            price: item.price,
            quantity: item.quantity,
            warranty: item.warranty,

            store: item.storeId,
            good: item.goodId,
        };

        return {
            fromStore: fromStoreDto,
        };
    }
}
