




















import { Vue, Component } from "vue-property-decorator";
import { BAvatar, BTooltip, VBTooltip } from "bootstrap-vue";

@Component({
    name: "CellRendererAvatar",
    components: { BAvatar, BTooltip },
    //directives: { "b-tooltip": VBTooltip },
})
export default class CellRendererAvatar extends Vue {
    private params: any;

    private click() {
        if (this.params.click) {
            this.params.click();
        }
    }
}
