import { Currency, Locale } from "@lib";
import { Localizer } from "@/i18n/localizer";
import { GrammarUtils } from "@/utils/grammar.utils";

export function money(number: number, symbol: boolean = false, locale = Locale.RU, currency = Currency.RUB): string {
    return symbol
        ? `${Localizer.moneyFull(number, locale)} ${Localizer.currencySymbol(currency)}`
        : Localizer.moneyFull(number, locale);
}

export function money2(number: string, symbol: boolean = false, locale = Locale.RU, currency = Currency.RUB): string {
    return money(parseFloat(number), symbol, locale, currency);
}

export function currencyHeader(header: string, currency = Currency.RUB): string {
    return `${header}, ${Localizer.currencySymbol(currency)}`;
}

export function moneyInWords(number: number, currency = Currency.RUB): string {
    return GrammarUtils.moneyInWords(number, currency);
}

interface IMoneyFormat {
    currency?: Currency;
    locale?: Locale;
}

export function moneyFormat(number: number, format: IMoneyFormat): string {
    if (!format.locale) {
        format.locale = Locale.RU;
    }
    if (format.currency) {
        // если валюту не указывать, число будет выведено без значка валюты
        return Localizer.currency(number, format.currency, format.locale);
    }
    return Localizer.moneyFull(number, format.locale);
}
