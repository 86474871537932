import { ApiError } from ".";

export class DataLoadException extends Error {
    public code?: ApiError;

    public constructor(error?: any) {
        const message = DataLoadException.getMessage(error);
        super(message);
        this.code = error.response?.data?.code;
    }

    private static getMessage(error?: any): string {
        if (error.response?.data?.code) {
            return DataLoadException.getMessageByCode(error.response.data);
        }

        if (error.response?.data?.message) {
            return error.response.data.message;
        }

        if (error.message) {
            return error.message;
        }

        if (typeof error === "string") {
            return error;
        }

        return DataLoadException.getMessageByCode(ApiError.UNKNOWN_ERROR);
    }

    private static getMessageByCode(error: any): string {
        // eslint-disable-next-line
        console.warn(`[altsc] Error ${error.code}: ${error.message ?? "Неизвестная ошибка."}`);

        switch (error.code) {
            case ApiError.SUCCESS:
                return "Успех.";

            case ApiError.EMAIL_ALREADY_EXISTS:
                return "Почта уже занята.";
            case ApiError.INVALID_LOGIN_OR_PASSWORD:
                return "Неверный логин или пароль.";
            case ApiError.INVALID_EMAIL:
                return "Неверная почта.";
            case ApiError.INVALID_USER:
                return "Неверный пользователь.";
            case ApiError.INVALID_TOKEN:
                return "Неверный токен.";
            case ApiError.INVALID_OLD_PASSWORD:
                return "Неверный старый пароль.";
            case ApiError.PASSWORDS_NOT_MATCHED:
                return "Пароли не совпадают.";

            case ApiError.SOCIAL_NOT_FOUND:
                return "Соцсеть не подключена.";
            case ApiError.SOCIAL_UNSUPPORTED:
                return "Соцсеть не поддерживается.";
            case ApiError.SOCIAL_ALREADY_EXISTS:
                return "Аккаунт соцсети уже подключён.";

            case ApiError.COMPANY_NOT_FOUND:
                return "Компания не найдена.";
            case ApiError.USER_NOT_FOUND:
                return "Пользователь не найден.";

            case ApiError.HTTP_BADREQUEST:
                return "Некорректный запрос.";
            case ApiError.HTTP_UNAUTHORIZED:
                return "Пользователь не авторизован.";
            case ApiError.HTTP_FORBIDDEN:
                return "Нет доступа.";
            case ApiError.HTTP_NOT_FOUND:
                return "Не найдено.";
            case ApiError.HTTP_INTERNAL:
                return "Внутренняя ошибка.";

            case ApiError.UNKNOWN_ERROR:
            default:
                return error.message ?? "Неизвестная ошибка.";
        }
    }
}
