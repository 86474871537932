import { IField } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Alert,
    Button,
    Control,
    Draggable,
    DraggedEventArgs,
    Icon,
    Label,
    Panel,
} from "@core/components/alt-ui/controls";
import { FieldModal } from "@core/controls/field-modal/field.modal";
import { getFieldTypeText } from "@core/types/fields/field";
import { ObjectUtils } from "@/utils/object.utils";

export interface IFieldSettingsModalContext {
    fieldModal: FieldModal;
}

export class ModalSettingsModal extends Modal<IField[]> {
    private alrInfo!: Alert;
    private drgMain!: Draggable;
    private btnAddField!: Button;
    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private context: IFieldSettingsModalContext;
    private fields: IField[] = [];

    public onSave: ((fields: IField[]) => Promise<boolean>) | null = null;

    public constructor(context: IFieldSettingsModalContext) {
        super("modal-settings", "Настройки формы");
        this.context = context;
        this.initializeControls();
    }

    public show(fields: IField[]): Promise<void> {
        this.fields = ObjectUtils.clone(fields);
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        this.alrInfo = new Alert();
        this.alrInfo.icon = "InfoIcon";
        this.alrInfo.text = "Перетаскивайте поля, чтобы настроить порядок, и добавляйте новые.";
        this.alrInfo.class = "mb-1";

        this.drgMain = new Draggable();
        this.drgMain.id = "modal-settings.draggable";
        this.drgMain.addDraggedHandler(this.moveField.bind(this));
        this.drgMain.addControls(this.fieldsToControls(this.fields));

        this.btnAddField = new Button();
        this.btnAddField.id = "modal-settings.add";
        this.btnAddField.text = "+ Добавить поле";
        this.btnAddField.variant = "outline-success";
        this.btnAddField.class = "w-100";
        this.btnAddField.addClickHandler(this.addField.bind(this));

        this.btnCancel = new Button();
        this.btnCancel.id = "modal-settings.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "modal-settings.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private fieldsToControls(fields: IField[]): Control[] {
        return fields
            .filter(f => !f.hidden)
            .sort((f1, f2) => (f1.sequence !== undefined && f2.sequence !== undefined ? f1.sequence - f2.sequence : 0))
            .map(f => this.fieldToControl(f));
    }

    private fieldToControl(field: IField): Control {
        const title = new Label();
        title.text = field.title ?? "";
        title.class = "uppercase font-semibold";

        const description = new Label();
        description.text = `Тип: ${field.type ? getFieldTypeText(field.type) : ""}`;

        const main = new Panel();
        main.addControls([title, description]);

        // actions

        const edit = new Icon();
        edit.icon = "Edit2Icon";
        edit.class = "cursor-pointer hover:text-primary";
        edit.style = "width: 1rem; height: 1rem;";
        edit.addClickHandler((s, e) => this.editField(field));

        const remove = new Icon();
        remove.icon = "Trash2Icon";
        remove.class = "cursor-pointer hover:text-danger ml-0.75";
        remove.style = "width: 1rem; height: 1rem;";
        remove.addClickHandler((s, e) => this.removeField(field));

        const actions = new Panel();
        actions.class = "flex";
        actions.addControls([edit, remove]);

        //

        const control = new Panel();
        control.id = field.id;
        control.class = "flex justify-content-between items-center";
        control.addControls([main, actions]);

        return control;
    }

    public get controls(): Control[] {
        return [this.alrInfo, this.drgMain, this.btnAddField, this.pnlFooter];
    }

    private moveField(sender: any, e: DraggedEventArgs): void {
        let sequence = 0;
        for (const control of this.drgMain.controls) {
            const field = this.fields.find(f => f.id === control.id);
            if (field) {
                sequence += 10;
                field.sequence = sequence;
            }
        }
    }

    private async addField(sender: any, e: any): Promise<void> {
        const context = {
            fields: this.fields.filter(f => f.hidden) ?? [],
            canCreateCustomField: true,
            canSpecifyMacro: false,
            macros: [],
        };

        const fieldAdded = await this.context.fieldModal.show(context);
        if (!fieldAdded) {
            return;
        }

        const index = this.fields.findIndex(f => f.id === fieldAdded.id);
        if (index >= 0) {
            this.fields[index] = fieldAdded;
        } else {
            this.fields.push(fieldAdded);
        }

        fieldAdded.hidden = false;
        fieldAdded.sequence = this.getMaxFieldSequence(this.fields) + 10;

        this.drgMain.clearControls();
        this.drgMain.addControls(this.fieldsToControls(this.fields));
    }

    private async editField(field: IField): Promise<void> {
        const context = {
            field: field,
            canCreateCustomField: true,
            canSpecifyMacro: false,
            macros: [],
        };

        const fieldEdited = await this.context.fieldModal.show(context);
        if (!fieldEdited) {
            return;
        }

        const index = this.fields.findIndex(f => f.id === field.id);
        if (index >= 0) {
            this.fields[index] = fieldEdited;
        }

        this.drgMain.clearControls();
        this.drgMain.addControls(this.fieldsToControls(this.fields));
    }

    private getMaxFieldSequence(fields: IField[]): number {
        let max = 0;
        for (const field of fields) {
            if (!field.hidden && field.sequence && field.sequence > max) {
                max = field.sequence;
            }
        }
        return max;
    }

    private removeField(field: IField): void {
        field.hidden = true;
        this.drgMain.clearControls();
        this.drgMain.addControls(this.fieldsToControls(this.fields));
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        if (!this.onSave) {
            return;
        }

        const result = await this.onSave(this.fields);
        if (result) {
            this.hide(result);
        }
    }
}
