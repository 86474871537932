import VaAutoButton from "@core/components/va-auto-button/va-auto-button.vue";
import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import { BButton, VBTooltip } from "bootstrap-vue";
import { CompaniesFilterController } from "../companies-filter-controller";
import { ITableFilter } from "@lib";
import FilterModal from "@core/filters/filter-modal.vue";

@Component({
    name: "clients-toolbar",
    components: {
        BButton,
        VaAutoButton,
        FilterModal,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class CompaniesToolbar extends Vue {
    @Prop({ type: Object, required: true })
    public filterController!: CompaniesFilterController;

    @Prop({ type: Array, default: () => [] })
    private filter!: ITableFilter[];

    @Emit("refresh")
    private startRefresh(): void {
        return;
    }

    @Emit("export")
    private startExport(): void {
        return;
    }

    @Emit("show-filter")
    public showFilterModal(): void {
        return;
    }

    private get hasFilter(): boolean {
        return this.filter.length > 0;
    }
}
