import { Control } from "./control";
import { EventHandler } from "..";

export class DateRangeValueChangedEventArgs {
    from!: string;
    to!: string;
    value!: [string, string];
}

export class DateRange extends Control {
    public label = "";
    public placeholder = "";
    public validation: string | null = null;

    public getComponentName(): string {
        return "DateRangeComponent";
    }

    private _from: string = "";
    public get from(): string {
        return this._from;
    }
    public set from(from: string) {
        if (from === this._from) {
            return;
        }

        this._from = from;
        this.notifyValueChangedHandler();
    }

    private _to: string = "";
    public get to(): string {
        return this._to;
    }
    public set to(to: string) {
        if (to === this._to) {
            return;
        }

        this._to = to;
        this.notifyValueChangedHandler();
    }

    public get value(): [string, string] {
        return [this._from, this._to];
    }
    public set value(value: [string, string]) {
        this._from = value[0];
        this._to = value[1];
        this.notifyValueChangedHandler();
    }

    private _valueChangedHandlers = new Set<EventHandler<DateRangeValueChangedEventArgs>>();
    public addValueChangedHandler(handler: EventHandler<DateRangeValueChangedEventArgs>): void {
        this._valueChangedHandlers.add(handler);
    }
    public removeValueChangedHandler(handler: EventHandler<DateRangeValueChangedEventArgs>): void {
        this._valueChangedHandlers.delete(handler);
    }
    private notifyValueChangedHandler(): void {
        const args: DateRangeValueChangedEventArgs = {
            from: this.from,
            to: this.to,
            value: this.value,
        };
        for (const handler of this._valueChangedHandlers) {
            handler(this, args);
        }
    }
}
