















import { Vue, Component, Prop } from "vue-property-decorator";
import { BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import FlatPickr from "vue-flatpickr-component";
import moment from "moment";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { FieldType } from "@lib";

@Component({
    name: "alt-date-time",
    components: { BInputGroup, BInputGroupAppend, FlatPickr },
})
export default class AltDateTime extends Vue {
    @Prop({ type: String })
    private id!: string;

    @Prop({ type: String })
    private placeholder!: string;

    @Prop({ type: Boolean })
    private disabled!: boolean;

    @Prop({ default: null })
    private state!: string | null;

    @Prop({ type: String })
    private value!: string;

    @Prop({ type: String, default: FieldType.DateTime })
    private mode!: FieldType.DateTime | FieldType.Date | FieldType.Time;

    // TODO: locale
    private getConfig() {
        // https://flatpickr.js.org/options/
        return {
            locale: Russian, //i18n.locale === "ru" ? Russian : null,
            enableTime: this.mode === FieldType.DateTime || this.mode === FieldType.Time,
            noCalendar: this.mode !== FieldType.DateTime && this.mode !== FieldType.Date,
            altInput: true,
            minuteIncrement: 1,
            //altFormat: "d.m.Y H:i",
            //dateFormat: "Z",
            //time_24hr: true,
            formatDate: (dt: Date) => moment(dt).format(this.getDisplayFormat()),
            onChange: this.onChange,
        };
    }

    private onChange(dates: Date[]): void {
        if (dates.length === 0) {
            return;
        }

        const value = moment(dates[0]).format(this.getOutputFormat());
        this.$emit("input", value);
    }

    private getDisplayFormat(): string {
        if (this.mode === FieldType.Date) {
            return "L";
        }

        if (this.mode === FieldType.Time) {
            return "LT";
        }

        return "L LT";
    }

    private getOutputFormat(): string {
        return "YYYY-MM-DD HH:mm";
    }

    private get valueCurrent(): string {
        return this.value;
    }

    private set valueCurrent(value: string) {
        this.$emit("input", value);
    }

    private clearValue(): void {
        this.valueCurrent = "";
    }
}
