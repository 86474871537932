import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormCheckbox, BTooltip } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { CheckBox } from "./check-box";
import { SwitchBox } from "./switch-box";

@Component({
    name: "check-box-component",
    components: { BFormCheckbox, ValidationProvider, BTooltip },
})
export class CheckBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: CheckBox | SwitchBox;

    public get validation(): string | null {
        // чтобы проверять, стоит ли галочка, нужно установить правило: rules="required:true"
        if (this.handler.validation?.includes("required") && !this.handler.validation?.includes("required:true")) {
            return this.handler.validation.replace("required", "required:true");
        }

        return this.handler.validation;
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.text}
                        mode="eager"
                        rules={this.validation}
                        vid={this.handler.id ?? undefined}
                        scopedSlots={{
                            default: (validation: any) => this.renderInput(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(validation: any): VNode {
        const items: VNode[] = [];
        const errors = validation.errors;

        items.push(
            <b-form-checkbox
                id={this.handler.id}
                class={this.handler.class}
                style={this.handler.style}
                switch={this.handler instanceof SwitchBox ? this.handler.switch : false}
                disabled={this.handler.disabled}
                value={true}
                unchecked-value={false}
                state={errors.length > 0 ? false : null}
                v-model={this.handler.value}
            >
                {this.handler.text}
            </b-form-checkbox>,
        );

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary ml-0.5"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        return (
            <div class="m-0 mb-1 ml-0.5">
                <div class="flex align-items-center">{items}</div>
                <small class="text-danger">{errors[0]}</small>
            </div>
        );
    }
}
