import { IOrderStage, OrderStageType } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, Select } from "@core/components/alt-ui/controls";

export interface IOrderViewReopenModalContext {
    stages: IOrderStage[];
}

export class OrderViewReopenModal extends Modal<IOrderViewReopenModalContext> {
    private cbStage!: Select<IOrderStage>;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: IOrderViewReopenModalContext | null = null;
    public onReopen: ((stage: IOrderStage) => Promise<boolean>) | null = null;

    public constructor() {
        super("order-view-reopen-modal", "Открытие заявки");
        this.initializeControls();
    }

    public show(context: IOrderViewReopenModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context);
        return super.show();
    }

    protected initializeControls(): void {
        this.cbStage = new Select<IOrderStage>();
        this.cbStage.id = "order-view-reopen.stage";
        this.cbStage.label = "Перевести заявку на этап";
        this.cbStage.items = this.context?.stages ?? [];
        this.cbStage.textField = s => s.name;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "order-view-reopen.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "order-view-reopen.save";
        this.btnSave.text = "Открыть";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(context: IOrderViewReopenModalContext): void {
        const index = this.cbStage.items.findIndex(s => s.type === OrderStageType.InWork) ?? -1;
        this.cbStage.selectedIndex = index >= 0 ? index : 0;
    }

    public get controls(): Control[] {
        return [this.cbStage, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.onReopen || !this.cbStage.selectedItem) {
            // throw new Error("Этап не задан.");
            return;
        }

        const result = await this.onReopen(this.cbStage.selectedItem);
        if (result) {
            this.hide(result);
        }
    }
}
