import { ITableFilter } from "@lib";

export type FilterPair = [string, string];

export enum FilterType {
    Between = "between",
    In = "in",
    Equals = "notIn",
}

export enum FilterTableType {
    Equals = "eq",
    In = "in",
    Gte = "gte",
    Lte = "lte",
}

// TODO: переместить в другую папку - подумать, куда лучше

export abstract class Filter {
    public static convertEqualsBack(key: string, value: string): ITableFilter[] {
        const filter: ITableFilter[] = [];

        if (value) {
            filter.push({
                field: key,
                operator: "eq",
                value: value,
            });
        }

        return filter;
    }

    public static convertInBack(key: string, values: string | string[]): ITableFilter[] {
        const filter: ITableFilter[] = [];

        if (!Array.isArray(values)) {
            values = [values];
        }

        for (const value of values) {
            if (value.length > 0) {
                filter.push({
                    field: key,
                    operator: "in",
                    value: value,
                });
            }
        }

        return filter;
    }

    public static convertBetweenBack(key: string, value: FilterPair): ITableFilter[] {
        const filter: ITableFilter[] = [];

        if (value[0] && value[0].length > 0) {
            filter.push({
                field: key,
                operator: "gte",
                value: value[0],
            });
        }

        if (value[1] && value[1].length > 0) {
            filter.push({
                field: key,
                operator: "lte",
                value: value[1],
            });
        }

        return filter;
    }

    //

    public static convertEquals(key: string, tblfilter: ITableFilter[], $default = ""): string {
        for (const tf of tblfilter) {
            if (tf.field === key && tf.operator === "eq") {
                return tf.value;
            }
        }
        return $default;
    }

    public static convertIn(key: string, tblfilter: ITableFilter[]): string[] {
        const values: string[] = [];
        for (const tf of tblfilter) {
            if (tf.field !== key) {
                continue;
            }

            if (tf.operator === "in" || tf.operator === "eq") {
                values.push(tf.value);
            }
        }
        return values;
    }

    public static convertBetween(key: string, tblfilter: ITableFilter[]): FilterPair {
        const pair: FilterPair = ["", ""];
        for (const tf of tblfilter) {
            if (tf.field === key && tf.operator === "gte") {
                pair[0] = tf.value;
            }
            if (tf.field === key && tf.operator === "lte") {
                pair[1] = tf.value;
            }
        }
        return pair;
    }
}
