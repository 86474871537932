import { FieldType } from "@lib";
import { Control } from "./control";
import { EventHandler } from "..";

export class DateTimeValueChangedEventArgs {
    value!: Date | null;
}

export class DateTime extends Control {
    public name = "";
    public label = "";
    public placeholder = "";
    public help = "";
    public mode: FieldType.DateTime | FieldType.Date | FieldType.Time = FieldType.DateTime;
    public validation: string | null = null;

    public getComponentName(): string {
        return "DateTimeComponent";
    }

    public _value: Date | null = null;
    public get value(): Date | null {
        return this._value;
    }
    public set value(value: Date | null) {
        this._value = value;
        this.notifyValueChangedHandler();
    }

    private _valueChangedHandlers = new Set<EventHandler<DateTimeValueChangedEventArgs>>();
    public addValueChangedHandler(handler: EventHandler<DateTimeValueChangedEventArgs>): void {
        this._valueChangedHandlers.add(handler);
    }
    public removeValueChangedHandler(handler: EventHandler<DateTimeValueChangedEventArgs>): void {
        this._valueChangedHandlers.delete(handler);
    }
    private notifyValueChangedHandler(): void {
        const args: DateTimeValueChangedEventArgs = {
            value: this.value,
        };
        for (const handler of this._valueChangedHandlers) {
            handler(this, args);
        }
    }
}
