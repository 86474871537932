import { Control } from "../controls";
import { Modal } from "../modal/modal";

export abstract class Modal2<T = undefined, TResult = void> extends Modal<T, TResult> {
    protected _visible: boolean = false;
    protected _hideEvent: Function | null = null;
    protected _errorEvent: Function | null = null;

    public constructor(id: string, title = "") {
        super(id, title);
    }

    public abstract get footer(): Control;
}
