import { Control } from "./control";

export class Alert extends Control {
    public text: string = "";
    public variant: string = "primary";
    public icon: string | null = null;

    public getComponentName(): string {
        return "AlertComponent";
    }
}
