import { ITemplateLabel, TemplateLabelType, ITemplateLabelCreateFromTemplateDto, ITemplateLabelUpdateDto } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, CheckBox, Control, Label, Panel, Select, TextBox } from "@core/components/alt-ui/controls";
import { getTemplateLabelTypes, ISelectOption } from "@core/types/common/select-options";
import { getGoodLabelTemplates } from "./settings-templates-labels-defaults";

export class SettingsTemplatesLabelsModal extends Modal<ITemplateLabel> {
    private cbType!: Select<ISelectOption<TemplateLabelType>>;
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private cbTemplate!: Select<ISelectOption>;

    private lblSize!: Label;
    private tbSizeWidth!: TextBox;
    private tbSizeHeight!: TextBox;
    private lblSizeX!: Label;
    private pnlSizeContent!: Panel;
    private pnlSize!: Panel;

    private chbSingle!: CheckBox;
    private chbBorder!: CheckBox;

    private btnEdit!: Button;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private label: ITemplateLabel | null = null;
    public onCreate: ((dto: ITemplateLabelCreateFromTemplateDto) => Promise<ITemplateLabel | null>) | null = null;
    public onUpdate: ((orig: ITemplateLabel, dto: ITemplateLabelUpdateDto) => Promise<ITemplateLabel | null>) | null =
        null;
    public onEditClick: ((label: ITemplateLabel) => Promise<void>) | null = null;

    public constructor() {
        super("labels-modal", "");
        this.initializeControls();
    }

    public show(label?: ITemplateLabel): Promise<void> {
        this.label = label ?? null;
        this.title = label ? "Изменение ценника" : "Новый ценник";
        this.initializeControls();

        if (label) {
            this.populateControls(label);
        }

        return super.show();
    }

    private initializeControls(): void {
        this.tbName = new TextBox();
        this.tbName.id = "labels.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.tbDescription = new TextBox();
        this.tbDescription.id = "labels.description";
        this.tbDescription.label = "Описание";

        this.lblSize = new Label();
        this.lblSize.text = "Размер (ШхВ, мм)";
        this.lblSize.class = "text-sm pl-50";

        this.tbSizeWidth = new TextBox();
        this.tbSizeWidth.id = "labels.width";
        this.tbSizeWidth.placeholder = "Ширина (мм)";
        this.tbSizeWidth.validation = "required|numeric|unsigned";
        this.tbSizeWidth.text = "43";

        this.lblSizeX = new Label();
        this.lblSizeX.text = "x";
        this.lblSizeX.class = "text-middle px-1 pt-0.5";

        this.tbSizeHeight = new TextBox();
        this.tbSizeHeight.id = "labels.height";
        this.tbSizeHeight.placeholder = "Высота (мм)";
        this.tbSizeHeight.validation = "required|numeric|unsigned";
        this.tbSizeHeight.text = "25";

        this.pnlSizeContent = new Panel();
        this.pnlSizeContent.id = "labels.size-content";
        this.pnlSizeContent.class = "flex";
        this.pnlSizeContent.addControls([this.tbSizeWidth, this.lblSizeX, this.tbSizeHeight]);

        this.pnlSize = new Panel();
        this.pnlSize.id = "labels.size";
        this.pnlSize.addControls([this.lblSize, this.pnlSizeContent]);

        this.chbSingle = new CheckBox();
        this.chbSingle.id = "labels.multiple-print";
        this.chbSingle.text = "Печатать по одному на листе";
        this.chbSingle.help = "Для печати на специальном принтере";
        this.chbSingle.value = false;
        this.chbSingle.addValueChangedHandler((s, e) => {
            this.chbBorder.visible = !e.value;
        });

        this.chbBorder = new CheckBox();
        this.chbBorder.id = "labels.border";
        this.chbBorder.text = "Показывать линии разреза";
        this.chbBorder.value = true;

        this.cbTemplate = new Select<ISelectOption>();
        this.cbTemplate.id = "labels.template";
        this.cbTemplate.label = "Готовый шаблон";
        this.cbTemplate.items = getGoodLabelTemplates();
        this.cbTemplate.textField = (opt: ISelectOption) => opt.name;
        this.cbTemplate.selectedIndex = 0;
        this.cbTemplate.visible = true;

        // инициализация после зависимых контролов
        this.cbType = new Select<ISelectOption<TemplateLabelType>>();
        this.cbType.id = "labels.type";
        this.cbType.label = "Тип";
        this.cbType.items = getTemplateLabelTypes();
        this.cbType.textField = (opt: ISelectOption) => opt.name;
        this.cbType.addChangedHandler(this.changeType.bind(this));
        this.cbType.selectedIndex = 0;
        this.cbType.visible = true;

        this.btnEdit = new Button();
        this.btnEdit.id = "labels.edit";
        this.btnEdit.text = "Редактировать шаблон";
        this.btnEdit.class = "w-full mt-0.75";
        this.btnEdit.variant = "outline-primary";
        this.btnEdit.visible = false;
        this.btnEdit.addClickHandler(this.clickEdit.bind(this));

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "labels.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "labels.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(label: ITemplateLabel): void {
        this.cbType.visible = false;
        this.tbName.text = label.name;
        this.tbDescription.text = label.description ?? "";
        this.tbSizeWidth.text = label.width.toString();
        this.tbSizeHeight.text = label.height.toString();
        this.chbSingle.value = label.single ?? false;
        this.chbBorder.value = label.border ?? true;
        this.cbTemplate.visible = false;
        this.btnEdit.visible = true;
    }

    public get controls(): Control[] {
        return [
            this.cbType,
            this.tbName,
            this.tbDescription,
            this.pnlSize,
            this.chbSingle,
            this.chbBorder,
            this.cbTemplate,
            this.btnEdit,
            this.pnlFooter,
        ];
    }

    private changeType(sender: any, e: any): void {
        switch (e.item.id) {
            case TemplateLabelType.GoodLabel:
                this.cbTemplate.items = getGoodLabelTemplates();
                this.cbTemplate.selectedIndex = 0;
                break;
        }
    }

    private async clickEdit(sender: any, e: any): Promise<void> {
        if (this.onEditClick && this.label) {
            this.hide();
            await this.onEditClick(this.label);
        }
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.label ? await this.updateLabel(this.label) : await this.createLabel();

        if (result) {
            this.hide(result);
        }
    }

    private async createLabel(): Promise<ITemplateLabel | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: ITemplateLabelCreateFromTemplateDto = {
            type: this.cbType.selectedItem?.id ?? TemplateLabelType.GoodLabel,
            name: this.tbName.text,
            description: this.tbDescription.text,
            width: parseInt(this.tbSizeWidth.text),
            height: parseInt(this.tbSizeHeight.text),
            single: this.chbSingle.value,
            border: this.chbSingle.value ? false : this.chbBorder.value,
            templateId: this.cbTemplate.selectedItem?.id ?? "blank",
            //offices: [],
        };

        return await this.onCreate(dto);
    }

    private async updateLabel(label: ITemplateLabel): Promise<ITemplateLabel | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: ITemplateLabelUpdateDto = {
            name: this.tbName.text,
            description: this.tbDescription.text,
            width: parseInt(this.tbSizeWidth.text),
            height: parseInt(this.tbSizeHeight.text),
            single: this.chbSingle.value,
            border: this.chbSingle.value ? false : this.chbBorder.value,
            //offices: [],
        };

        return await this.onUpdate(label, dto);
    }
}
