import { IField } from "@lib";
// Важно импортировать именно из этого файла, т.к иначе мы получаем циклическую зависимость
// В компоненте модельного окна в случае, если он импортирует класс Panel отсюда же
import { Panel } from "@core/components/alt-ui/controls/panel";
import { MacroFieldAssociation } from "./field.modal";

export interface IFieldSettingsContext {
    field?: IField;

    /** Возможность задать макросы для поля. */
    canSpecifyMacro?: boolean;

    /** Уже задействованные макросы. */
    macros?: MacroFieldAssociation[];
}

export abstract class FieldSettings extends Panel {
    protected readonly context: IFieldSettingsContext;

    public constructor(context: IFieldSettingsContext) {
        super();
        this.context = context;
        this.initializeControls();

        if (this.context.field) {
            this.populateControls(this.context.field);
        }
    }

    protected abstract initializeControls(): void;

    protected abstract populateControls(field: IField): void;

    public abstract getCreatePayload(): IField | null;

    public abstract getUpdatePayload(): IField | null;

    public validate(): boolean {
        return true;
    }
}
