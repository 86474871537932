import { FieldType } from "@lib";
import { DateTime } from "@core/components/alt-ui/controls";
import { FieldControl } from "../field-control";
import { Formatter } from "@/utils/formatter";
import { Uuid } from "@/utils/uuid";

export class DateTimeFieldControl extends FieldControl<DateTime> {
    protected convert(): DateTime {
        const control = new DateTime();
        control.id = `${this.field.id}_${Uuid.short()}`;
        control.label = this.field.title ?? "";
        control.help = this.field.description ?? "";
        control.validation = this.validationToString(this.field.validation);
        control.value = this.field.default ? new Date(this.field.default) : null;
        control.mode = this.getMode();
        return control;
    }

    public get value(): any {
        return this.control.value;
    }

    public set value(value: any) {
        this.control.value = value ? new Date(value) : null;
    }

    public getValueFormatted(): string {
        return Formatter.datetime(this.value, { format: this.getFormat() });
    }

    private getMode(): FieldType.DateTime | FieldType.Date | FieldType.Time {
        if (this.field.type === FieldType.Date || this.field.type === FieldType.Time) {
            return this.field.type;
        }

        return FieldType.DateTime;
    }

    private getFormat(): string {
        if (this.field.type === FieldType.Date) {
            return "L";
        }

        if (this.field.type === FieldType.Time) {
            return "LT";
        }

        return "L LT";
    }
}
