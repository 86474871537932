import { ValidationObserver } from "vee-validate";
import { Vue, Component, Prop } from "vue-property-decorator";
import { VisibilityChangedEventArgs } from "../components/alt-ui";
import { FilterController } from "./filter-controller";

@Component({
    name: "filter-modal",
    components: {
        ValidationObserver,
        ControlComponent: () => import("../components/alt-ui/controls/control.component"),
    },
})
export default class VaSelect extends Vue {
    @Prop({ type: Object, required: true })
    public controller!: FilterController;

    public get title(): string {
        return this.controller.title.toUpperCase();
    }

    public get validatorId(): string {
        return this.controller.id;
    }

    public onHide(): void {
        this.controller.hide();
    }

    public mounted(): void {
        this.init();
    }

    private init(): void {
        this.controller.addVisibleChangedHandler(this.onModalVisibleChanged.bind(this));
        this.controller.addValidateHandler(this.onModalValidate.bind(this));
    }

    private onModalVisibleChanged(sender: any, e: VisibilityChangedEventArgs): void {
        if (e.visible) {
            this.$bvModal.show(this.controller.id);
        } else {
            this.$bvModal.hide(this.controller.id);
        }
    }

    private async onModalValidate(sender: any, e: any): Promise<void> {
        e.valid = await (this.$refs[this.validatorId] as any).validate();
    }
}
