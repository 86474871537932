import { render, staticRenderFns } from "./plugin.vue?vue&type=template&id=27c02ddd&scoped=true&"
import script from "./plugin.ts?vue&type=script&lang=ts&"
export * from "./plugin.ts?vue&type=script&lang=ts&"
import style0 from "./plugin.scss?vue&type=style&index=0&id=27c02ddd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c02ddd",
  null
  
)

export default component.exports