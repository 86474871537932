import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormSelect, BTooltip } from "bootstrap-vue";
import { ComboBox } from "./combo-box";

@Component({
    name: "combo-box-component",
    components: { BFormSelect, BTooltip },
})
export class ComboBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: ComboBox;

    public get options(): any[] {
        return this.handler.items.map(item => ({
            value: item,
            text: this.handler.textField ? this.handler.textField(item) : "",
        }));
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                //<div class="form-label-group m-0">
                <div class={this.handler.class} style={this.handler.style}>
                    {this.renderParts()}
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderParts(): VNode[] {
        const items: VNode[] = [];

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        items.push(
            <b-form-select
                id={this.handler.id}
                disabled={this.handler.disabled}
                options={this.options}
                v-model={this.handler.selectedItem}
            />,
        );

        return items;
    }
}
