import { IEmployee, IEmployeeUpdateDto, IPermission, IPermissionGrantDto } from "@lib";
import { PermissionCommonSection, PermissionRight, PermissionType } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, CheckBox, Collapse, Control, Label, Panel } from "@core/components/alt-ui/controls";
import { makeEmptyPermissions, EmployeeContext, simplifyPermissions } from "./employee-permissions";
import filters from "./filters";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface PermissionsModalContext extends EmployeeContext {
    employee?: IEmployee;
    permissions?: IPermission;
}

export class PermissionsModal extends Modal<PermissionsModalContext> {
    private chbPermissionsAdmin!: CheckBox;
    private pnlPermissionsCustom!: Panel;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context!: PermissionsModalContext;
    private permissions: IPermissionGrantDto | null = null;
    public onUpdate: ((employee: IEmployee, dto: IEmployeeUpdateDto) => Promise<IEmployee | null>) | null = null;

    public constructor() {
        super("permissions-modal", "Права сотрудника");
        this.initializeControls();
    }

    public show(context: PermissionsModalContext): Promise<void> {
        this.context = context;
        this.permissions = context.permissions ? this.populatePermissions(context.permissions) : null;

        this.initializeControls();
        //if (!this.permissions)
        this.populateControls();
        return super.show();
    }

    protected initializeControls(): void {
        this.chbPermissionsAdmin = new CheckBox();
        this.pnlPermissionsCustom = new Panel();
        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.chbPermissionsAdmin.id = "employees.permissions.admin";
        this.chbPermissionsAdmin.text = "Администратор (все права)";
        this.chbPermissionsAdmin.class = "ml-1.5";
        this.chbPermissionsAdmin.value = false;
        this.chbPermissionsAdmin.addValueChangedHandler(this.changeAdminPermissions.bind(this));

        this.pnlPermissionsCustom.id = "employees.permissions.custom";

        //

        this.btnCancel.id = "employees.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "employees.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(): void {
        this.pnlPermissionsCustom.addControls(this.createPermissionControls());
        this.chbPermissionsAdmin.value = (this.context.permissions as IPermission).admin ?? false;
    }

    public get controls(): Control[] {
        return [this.chbPermissionsAdmin, this.pnlPermissionsCustom, this.pnlFooter];
    }

    private changeAdminPermissions(sender: any, e: any): void {
        if (!this.permissions) {
            return;
        }

        this.permissions.admin = e.value;
        this.pnlPermissionsCustom.visible = !e.value;
    }

    private createPermissionControls(): Control[] {
        const controls: Control[] = [];

        for (const type in this.permissions) {
            switch (type) {
                case PermissionType.Common:
                    controls.push(this.createCollapseCommon(this.permissions));
                    break;
                case PermissionType.Offices:
                case PermissionType.Shops:
                case PermissionType.Accounts:
                case PermissionType.Stores:
                case PermissionType.Employees:
                    for (const branchId in this.permissions[type]) {
                        controls.push(this.createCollapseBranch(this.permissions, type as PermissionType, branchId));
                    }
                    break;
            }
        }

        return controls;
    }

    private populatePermissions(permissions: IPermission): IPermissionGrantDto {
        const result = makeEmptyPermissions(this.context) as any;

        result.admin = permissions.admin ?? false;

        if (permissions.common) {
            for (const sectionName in permissions.common) {
                const section = (permissions.common as any)[sectionName];
                for (const right in section) {
                    result.common[sectionName][right] = section[right];
                }
            }
        }

        for (const branchType in permissions) {
            if (
                branchType !== PermissionType.Offices &&
                branchType !== PermissionType.Shops &&
                branchType !== PermissionType.Stores &&
                branchType !== PermissionType.Accounts &&
                branchType !== PermissionType.Employees
            ) {
                continue;
            }

            for (const branchId in permissions[branchType]) {
                if (!result[branchType][branchId]) {
                    continue;
                }

                const branch = permissions[branchType][branchId];
                for (const right in branch) {
                    result[branchType][branchId][right] = permissions[branchType][branchId][right];
                }
            }
        }

        return result;
    }

    private createCollapseCommon(permissions: IPermissionGrantDto): Collapse {
        const permis = permissions as any;

        const collapse = new Collapse();
        collapse.title = filters.translateType(PermissionType.Common);

        for (const section in permis.common) {
            collapse.addControl(this.createLabel(section));

            for (const right in permis.common[section]) {
                collapse.addControl(
                    this.createCheckBoxCommon(
                        permissions,
                        section as PermissionCommonSection,
                        right as PermissionRight,
                    ),
                );
            }
        }

        return collapse;
    }

    private createCollapseBranch(permissions: IPermissionGrantDto, type: PermissionType, branchId: string): Collapse {
        const permis = permissions as any;

        const collapse = new Collapse();
        collapse.title = `${filters.translateType(type)} "${this.getBranchName(type, branchId)}"`;

        const panel = new Panel();

        for (const right in permis[type][branchId]) {
            if (right === PermissionRight.Admin) {
                collapse.addControl(this.createCheckBoxBranchAdmin(permissions, type, branchId, panel));

                panel.addControl(this.createDivider());
            } else {
                panel.addControl(this.createCheckBoxBranch(permissions, type, branchId, right as PermissionRight));
            }
        }

        collapse.addControl(panel);

        return collapse;
    }

    private createLabel(section: string): Label {
        const label = new Label();
        label.text = filters.translateSection(section);
        label.class = "text-base font-bold mt-1 mb-0.5";
        return label;
    }

    private createDivider(): Label {
        const label = new Label();
        label.text = "";
        label.class = "border-top my-1";
        return label;
    }

    private createCheckBoxCommon(
        permissions: IPermissionGrantDto,
        section: PermissionCommonSection,
        right: PermissionRight,
    ): CheckBox {
        const permis = permissions as any;

        const checkbox = new CheckBox();
        checkbox.text = filters.translateRight(right);
        checkbox.class = "ml-1";
        checkbox.addValueChangedHandler((sender, e): void => {
            permis.common[section][right] = e.value;
        });
        checkbox.value = permis.common[section][right] ?? false;
        return checkbox;
    }

    private createCheckBoxBranchAdmin(
        permissions: IPermissionGrantDto,
        branchType: PermissionType,
        branchId: string,
        panel: Panel,
    ): CheckBox {
        const checkbox = new CheckBox();
        checkbox.text = filters.translateRight(PermissionRight.Admin);
        checkbox.class = "ml-1";
        checkbox.addValueChangedHandler((sender, e): void => {
            permissions[branchType][branchId][PermissionRight.Admin] = e.value;
            panel.visible = !e.value;
        });
        checkbox.value = permissions[branchType][branchId][PermissionRight.Admin] ?? false;
        return checkbox;
    }

    private createCheckBoxBranch(
        permissions: IPermissionGrantDto,
        branchType: PermissionType,
        branchId: string,
        right: PermissionRight,
    ): CheckBox {
        const checkbox = new CheckBox();
        checkbox.text = filters.translateRight(right);
        checkbox.class = "ml-1";
        checkbox.addValueChangedHandler((sender, e): void => {
            permissions[branchType][branchId][right] = e.value;
        });
        checkbox.value = permissions[branchType][branchId][right] ?? false;
        return checkbox;
    }

    private getBranchName(branchType: PermissionType, id: string): string {
        if (branchType === PermissionType.Offices) {
            const office = this.context.offices.find(b => b.id === id);
            return office?.info.name ?? "";
        }

        if (branchType === PermissionType.Shops) {
            const shop = this.context.shops.find(b => b.id === id);
            return shop?.info.name ?? "";
        }

        if (branchType === PermissionType.Stores) {
            const store = this.context.stores.find(b => b.id === id);
            return store?.info.name ?? "";
        }

        if (branchType === PermissionType.Accounts) {
            const account = this.context.accounts.find(b => b.id === id);
            return account?.info.name ?? "";
        }

        if (branchType === PermissionType.Employees) {
            const employee = this.context.employees.find(b => b.user === id);
            return employee?.userRef.info.name ?? "";
        }

        return "";
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context.employee) {
            return;
        }

        const result = await this.updatePermissions(this.context.employee);

        if (result) {
            this.hide(result);
        }
    }

    private async updatePermissions(employee: IEmployee): Promise<IEmployee | null> {
        if (!this.onUpdate || !this.permissions) {
            return null;
        }

        const dto: IEmployeeUpdateDto = {
            permissions: simplifyPermissions(this.permissions),
        };

        return await this.onUpdate(employee, dto);
    }
}
