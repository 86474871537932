import {
    IOrderWork,
    IOrderWorkCreateDto,
    IOrderWorkUpdateDto,
    IEmployee,
    IUser,
    IWork,
    IAccount,
    IOrderWorkPaymentDto,
    Locale,
} from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    CheckBox,
    CheckBoxValueChangedEventArgs,
    ComboBoxChangedEventArgs,
    Control,
    IconPackage,
    Panel,
    Select,
    SuggestBox,
    TextArea,
    TextBox,
    TextChangedEventArgs,
} from "@core/components/alt-ui/controls";
import { WarrantyBox } from "@core/controls/warranty-box";
import { Localizer } from "@/i18n/localizer";
import { DelayedOperation } from "@/utils/delayed-operation";

export interface IWorkModalContext {
    work?: IOrderWork;
    employees: IEmployee[];
    user: IUser;
    accounts: IAccount[];
    readonly: boolean;
    searchWorks: (search: string) => Promise<IWork[]>;
}

export class WorkModal extends Modal<IWorkModalContext> {
    private sbName!: SuggestBox<IWork>;
    private taDescription!: TextArea;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    private tbQuantity!: TextBox;
    private wbWarranty!: WarrantyBox;
    private cbEmployee!: Select<IEmployee>;

    private cbAccount!: Select<IAccount>;
    private chbEnableAccountWithdrawal!: CheckBox;
    private pnlOnlyCreate!: Panel;

    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private context: IWorkModalContext | null = null;

    public onCreate: ((dto: IOrderWorkCreateDto) => Promise<boolean>) | null = null;
    public onUpdate: ((orig: IOrderWork, dto: IOrderWorkUpdateDto) => Promise<boolean>) | null = null;

    public constructor() {
        super("work-modal", "");
        this.initializeControls();
    }

    public show(context: IWorkModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();

        if (context.work) {
            this.title = "Изменение работы";
            this.btnSave.text = "Изменить";
            this.populateControls(context.work);
        } else {
            this.title = "Добавление работы";
            this.btnSave.text = "Добавить";
        }

        return super.show();
    }

    protected initializeControls(): void {
        // this.tbName = new TextBox();
        // this.tbName.id = "work.name";
        // this.tbName.label = "Наименование";
        // this.tbName.validation = "required";

        this.sbName = new SuggestBox<IWork>();
        this.sbName.id = "work.name";
        this.sbName.label = "Наименование";
        this.sbName.validation = "required";
        this.sbName.items = [];
        this.sbName.textField = w => w.name;
        this.sbName.search = this.searchWork.bind(this);
        this.sbName.addChangedHandler(this.selectWork.bind(this));

        this.tbCost = new TextBox();
        this.tbCost.id = "work.cost";
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "required|money|unsigned";
        this.tbCost.text = "0";
        this.tbCost.addTextChangedHandler(this.changeCost.bind(this));

        this.tbPrice = new TextBox();
        this.tbPrice.id = "work.price";
        this.tbPrice.label = "Цена";
        this.tbPrice.validation = "required|money|unsigned";

        this.tbQuantity = new TextBox();
        this.tbQuantity.id = "work.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = `required|numeric|unsigned|min_value:1|max_value:${Number.MAX_VALUE}`;
        this.tbQuantity.text = "1";

        this.taDescription = new TextArea();
        this.taDescription.id = "work.description";
        this.taDescription.label = "Описание";

        this.wbWarranty = new WarrantyBox();
        this.wbWarranty.id = "work.warranty";
        this.wbWarranty.label = "Гарантия";

        this.cbEmployee = new Select<IEmployee>();
        this.cbEmployee.id = "work.employee";
        this.cbEmployee.label = "Исполнитель";
        this.cbEmployee.items = this.context?.employees ?? [];
        this.cbEmployee.textField = e => e.userRef.info.name;
        this.cbEmployee.descriptionField = e => e.position;
        this.cbEmployee.iconPackage = IconPackage.Feater;
        this.cbEmployee.icon = "UserIcon";
        this.cbEmployee.selectedIndex = this.cbEmployee.items.findIndex(e => e.user === this.context?.user.id);

        this.chbEnableAccountWithdrawal = new CheckBox();
        this.chbEnableAccountWithdrawal.id = "work.enable-account-withdrawal";
        this.chbEnableAccountWithdrawal.text = "Вычесть со счёта";
        this.chbEnableAccountWithdrawal.addValueChangedHandler(this.changeEnableAccountWithdrawal.bind(this));
        this.chbEnableAccountWithdrawal.value = false;
        this.chbEnableAccountWithdrawal.visible = false;

        this.cbAccount = new Select<IAccount>();
        this.cbAccount.id = "work.account";
        this.cbAccount.label = "Счёт";
        this.cbAccount.items = this.context?.accounts ?? [];
        this.cbAccount.textField = a => a.info.name;
        this.cbAccount.descriptionField = a => a.info.description;
        this.cbAccount.iconPackage = IconPackage.Alt;
        this.cbAccount.icon = "finance";
        this.cbAccount.selectedIndex = this.cbAccount.items.length > 0 ? 0 : -1;
        this.cbAccount.class = "mb-1";
        this.cbAccount.visible = false;

        this.pnlOnlyCreate = new Panel();
        this.pnlOnlyCreate.addControls([this.chbEnableAccountWithdrawal, this.cbAccount]);

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "work.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "work.save";
        this.btnSave.text = "Добавить";
        this.btnSave.class = "ml-0.75";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(work: IOrderWork): void {
        // TODO: Брать локаль из филиала
        const locale = Locale.RU;

        this.sbName.text = work.name;
        this.taDescription.text = work.description ?? "";
        this.tbCost.text = Localizer.number(work.cost ?? 0, locale);
        this.tbPrice.text = Localizer.number(work.price, locale);
        this.tbQuantity.text = work.quantity.toString();
        this.wbWarranty.value = work.warranty;

        this.cbEmployee.selectedIndex = this.cbEmployee.items.findIndex(e => e.user === work.employee);

        this.pnlOnlyCreate.visible = false;

        if (this.context?.readonly) {
            this.sbName.disabled = true;
            this.tbCost.disabled = true;
            this.tbPrice.disabled = true;
            this.tbQuantity.disabled = true;
            this.taDescription.disabled = true;
            this.wbWarranty.disabled = true;
            this.cbEmployee.disabled = true;
            this.chbEnableAccountWithdrawal.disabled = true;
            this.cbAccount.disabled = true;
            this.btnSave.visible = false;
        }
    }

    public get controls(): Control[] {
        return [
            this.sbName,
            this.tbCost,
            this.tbPrice,
            this.tbQuantity,
            this.taDescription,
            this.wbWarranty,
            this.cbEmployee,
            this.pnlOnlyCreate,
            this.pnlFooter,
        ] as Control[];
    }

    private searchWork(search: string): void {
        if (!this.context) {
            return;
        }

        const searchWorks = this.context.searchWorks;
        search = search.trim();

        // начинать поиск от 2 символов
        if (search.length < 2) {
            this.sbName.items = [];
            return;
        }

        DelayedOperation.invoke("search-work", 600, async () => {
            this.sbName.items = await searchWorks(search);
        });
    }

    private async selectWork(sender: any, e: ComboBoxChangedEventArgs<IWork>): Promise<void> {
        if (!e.item) {
            return;
        }

        const work = e.item;

        // TODO: брать локаль из филиала
        const locale = Locale.RU;
        const cost = work.cost ?? 0.0;
        const price = work.price ?? 0.0;

        this.sbName.text = work.name;
        this.taDescription.text = work.description ?? "";
        this.tbCost.text = Localizer.money(cost, locale);
        this.tbPrice.text = Localizer.money(price, locale);
        this.wbWarranty.value = work.warranty;
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.context?.work ? await this.updateWork(this.context.work) : await this.createWork();

        if (result) {
            this.hide(result);
        }
    }

    private async createWork(): Promise<boolean> {
        if (!this.onCreate) {
            return false;
        }

        if (!this.cbEmployee.selectedItem) {
            return false;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const priceStr = this.tbPrice.text.replace(",", ".");

        const dto: IOrderWorkCreateDto = {
            name: this.sbName.text,
            description: this.taDescription.text,
            cost: parseFloat(costStr),
            price: parseFloat(priceStr),
            quantity: parseInt(this.tbQuantity.text),
            warranty: this.wbWarranty.value,
            employee: this.cbEmployee.selectedItem.user as string,
            payment: this.getAccountPayment(),
        };

        return await this.onCreate(dto);
    }

    private getAccountPayment(): IOrderWorkPaymentDto | undefined {
        let paymentData: IOrderWorkPaymentDto | undefined;
        if (this.chbEnableAccountWithdrawal.value && this.cbAccount.selectedItem) {
            paymentData = {
                account: this.cbAccount.selectedItem.id,
                description: "Расходы на работу",
            };
        }

        return paymentData;
    }

    private async updateWork(orig: IOrderWork): Promise<boolean> {
        if (!this.onUpdate) {
            return false;
        }

        if (!this.cbEmployee.selectedItem) {
            return false;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const priceStr = this.tbPrice.text.replace(",", ".");

        const dto: IOrderWorkUpdateDto = {
            name: this.sbName.text,
            description: this.taDescription.text,
            cost: parseFloat(costStr),
            price: parseFloat(priceStr),
            quantity: parseInt(this.tbQuantity.text),
            warranty: this.wbWarranty.value,
            employee: this.cbEmployee.selectedItem.user as string,
        };

        return await this.onUpdate(orig, dto);
    }

    private changeCost(sender: any, e: TextChangedEventArgs): void {
        const cost = parseFloat(e.text);

        if (isNaN(cost) || cost <= 0) {
            this.chbEnableAccountWithdrawal.visible = false;
            this.cbAccount.visible = false;
            return;
        }

        this.chbEnableAccountWithdrawal.visible = true;
        this.cbAccount.visible = this.chbEnableAccountWithdrawal.value;
    }

    private async changeEnableAccountWithdrawal(sender: any, e: CheckBoxValueChangedEventArgs): Promise<void> {
        this.cbAccount.visible = e.value;
    }
}
