import { FieldType, IField } from "@lib";
import { CheckBoxFieldControl } from "./standart/check-box.field-control";
import { FieldControl, IFieldControlContext } from "./field-control";
import { TextBoxFieldControl } from "./standart/text-box.field-control";
import { PhoneBoxFieldControl } from "./standart/phone-box.field-control";
import { TextAreaFieldControl } from "./standart/text-area.field-control";
import { DateTimeFieldControl } from "./standart/date-time.field-control";
import { SelectFieldControl } from "./standart/select.field-control";
import { CompositeFieldControl } from "./standart/composite.field-control";
import { TagsFieldControl } from "./standart/tags.field-control";
import { AppException } from "@/exceptions";

export abstract class FieldControlFactory {
    public static fromField(field: IField, context?: IFieldControlContext): FieldControl {
        switch (field.type) {
            case FieldType.String:
                return new TextBoxFieldControl(field, context);
            case FieldType.Phone:
                return new PhoneBoxFieldControl(field, context);
            case FieldType.Text:
                return new TextAreaFieldControl(field, context);
            case FieldType.Checkbox:
                return new CheckBoxFieldControl(field, context);
            case FieldType.DateTime:
            case FieldType.Date:
            case FieldType.Time:
                return new DateTimeFieldControl(field, context);
            case FieldType.Select:
                return new SelectFieldControl(field, context);
            case FieldType.Composite:
                return new CompositeFieldControl(field, context);
            case FieldType.Tags:
                return new TagsFieldControl(field, context);
        }

        throw new AppException(`Field type ${field.type} cannot be converted.`);
    }

    public static formatValue(value: any, field: IField, context?: IFieldControlContext): string {
        const fieldControl = FieldControlFactory.fromField(field, context);
        fieldControl.value = value;
        return fieldControl.getValueFormatted();
    }
}
