import { ISettingsTableUseCase, ITable, TableType } from "@lib";
import { ISettingsTableUpdateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class SettingsTableUseCase implements ISettingsTableUseCase {
    public async get(companyId: string, userId: string, type: TableType): Promise<ITable> {
        try {
            const response = await Api.get<ITable>(
                `/companies/${companyId}/employees/${userId}/settings/tables/${type}`,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(
        companyId: string,
        userId: string,
        type: TableType,
        dto: ISettingsTableUpdateDto,
    ): Promise<ITable> {
        try {
            const response = await Api.put<ITable>(
                `/companies/${companyId}/employees/${userId}/settings/tables/${type}`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
