import { Control } from "./control";
import { EventHandler } from "..";

export class FileChangedEventArgs {
    file!: File | null;
}

export class FileUpload extends Control {
    public label = "";
    public placeholder = "";
    public accept = "";
    public browseText = "Выбрать";
    public validation: string | null = null;

    public getComponentName(): string {
        return "FileUploadComponent";
    }

    private _file: File | null = null;
    public get file(): File | null {
        return this._file;
    }
    public set file(file: File | null) {
        if (file === this._file) {
            return;
        }

        this._file = file;
        this.notifyFileChangedHandler();
    }

    private _fileChangedHandlers = new Set<EventHandler<FileChangedEventArgs>>();
    public addFileChangedHandler(handler: EventHandler<FileChangedEventArgs>): void {
        this._fileChangedHandlers.add(handler);
    }
    public removeFileChangedHandler(handler: EventHandler<FileChangedEventArgs>): void {
        this._fileChangedHandlers.delete(handler);
    }
    private notifyFileChangedHandler(): void {
        const args: FileChangedEventArgs = { file: this._file };
        for (const handler of this._fileChangedHandlers) {
            handler(this, args);
        }
    }
}
