import { IOffice } from "@lib";
import parsePhoneNumber from "libphonenumber-js";

export function getTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (office: IOffice) => context.openModalUpdate(office),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (office: IOffice) => context.confirmDelete(office),
                        },
                    ],
                };
            },
        },
        {
            colId: "number",
            field: "number",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,
        },
        {
            colId: "name",
            field: "info.name",
            headerName: "Наименование",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "description",
            field: "info.description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 500,
            hide: true,
        },
        {
            colId: "phone",
            field: "info.contacts.phone",
            headerName: "Телефон",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,

            cellRenderer: function (params: any) {
                const value = params.value as string;

                if (!value) {
                    return "";
                }

                const phoneNumber = parsePhoneNumber(value);

                return phoneNumber?.formatInternational() ?? value;
            },
        },
        {
            colId: "email",
            field: "info.contacts.email",
            headerName: "Почта",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,
        },
        {
            colId: "address",
            field: "info.contacts.address",
            headerName: "Адрес",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
    ];
}
