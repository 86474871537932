import { Control } from "./control";
import { EventHandler } from "..";
import { TextChangedEventArgs } from "./text-box";

export { TextChangedEventArgs } from "./text-box";

export class TextArea extends Control {
    public name = "";
    public label = "";
    public placeholder = "";
    public rows = 2;
    public rowsMax: number | null = 8;
    public help = "";
    public validation: string | null = null;

    public getComponentName(): string {
        return "TextAreaComponent";
    }

    private _text: string = "";
    public get text(): string {
        return this._text;
    }
    public set text(text: string) {
        if (text === this._text) {
            return;
        }

        this._text = text;
        this.notifyTextChangedHandlers();
    }

    private _textChangedHandlers = new Set<EventHandler<TextChangedEventArgs>>();
    public addTextChangedHandler(handler: EventHandler<TextChangedEventArgs>): void {
        this._textChangedHandlers.add(handler);
    }
    public removeTextChangedHandler(handler: EventHandler<TextChangedEventArgs>): void {
        this._textChangedHandlers.delete(handler);
    }
    private notifyTextChangedHandlers(): void {
        const args: TextChangedEventArgs = { text: this._text };
        for (const handler of this._textChangedHandlers) {
            handler(this, args);
        }
    }
}
