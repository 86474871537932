/** Социальные сети и экосистемы. */
export enum Social {
    /** Яндекс. */
    Yandex = "yandex",

    /** ВКонтакте. */
    VK = "vk",

    /** Одноклассники. */
    OK = "ok",

    /** Google. */
    Google = "google",
}
