import { IPluginCardUseCase, IPluginCard } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class PluginCardUseCase implements IPluginCardUseCase {
    public async select(): Promise<IPluginCard[]> {
        try {
            const response = await Api.get<IPluginCard[]>("/plugins");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(id: string): Promise<IPluginCard> {
        throw new Error("Method not implemented.");
    }

    public async getByCode(code: string): Promise<IPluginCard> {
        throw new Error("Method not implemented.");
    }
}
