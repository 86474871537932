import { Vue, Component, Prop } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { IPluginBinding, IPluginCard } from "@lib";

@Component({
    components: { BButton },
})
export default class Plugin extends Vue {
    @Prop({ type: Object, required: true })
    private plugin!: IPluginCard;

    @Prop({ type: Object, default: () => null })
    private binding!: IPluginBinding | null;

    @Prop({ type: Boolean, default: false })
    private canBind!: boolean;

    @Prop({ type: Boolean, default: false })
    private canUnbind!: boolean;

    private bind(): void {
        this.$emit("bind", this.plugin);
    }

    private unbind(): void {
        if (!this.binding) {
            return;
        }
        this.$emit("unbind", this.binding);
    }
}
