import { Control } from "../controls";

export class Composite extends Control {
    public label = "";
    public help = "";
    public elements: Control[] = [];

    public getComponentName(): string {
        return "CompositeComponent";
    }
}
