import { Vue, Component } from "vue-property-decorator";
import { BCard, BCardTitle, BCardText, BLink } from "bootstrap-vue";
import VaLogo from "@/components/common/va-logo/va-logo-full.vue";

@Component({
    components: { BCard, BCardTitle, BLink, BCardText, VaLogo },
})
export default class ConfirmEmail extends Vue {
    private resources = {
        LabelTitleText: "ПОДТВЕРЖДЕНИЕ ПОЧТЫ",
        LabelSubtitle1Text: "Подтверждение почты",
        LinkLoginText: "На главную",
    };

    private token = "";
    private text = "Неверная ссылка.";
    private details = "";

    public async mounted(): Promise<void> {
        this.initParams();

        if (this.token && this.token.length > 0) {
            await this.confirm();
        }
    }

    private async initParams(): Promise<void> {
        if (this.$route.params.id) {
            this.token = this.$route.params.id;
        }
    }

    private async confirm(): Promise<void> {
        try {
            this.$alt.loader.show();

            const service = this.$alt.system.usecase.createAuthUseCase();
            await service.confirmEmail(this.token);
            this.text = "Почта успешно подтверждена.";

            this.$alt.toast.success("Почта успешно подтверждена.");
        } catch (e: any) {
            this.text = "Не удалось подтвердить почту:";
            this.details = e.message;
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
