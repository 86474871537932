export default {
    admin: {
        analytics: {
            title: "Аналитика",
        },

        companies: {
            title: "Компании",
        },

        users: {
            title: "Пользователи",
        },

        tracking: {
            title: "Отслеживание",
        },
    },

    main: {
        dashboard: {
            title: "Главная",
        },

        orders: {
            title: "Заявки",
        },

        sales: {
            title: "Магазин",
        },

        accounts: {
            title: "Финансы",
        },

        stores: {
            title: "Склады",
        },

        clients: {
            title: "Клиенты",
        },

        reports: {
            title: "Отчёты",
        },

        tracking: {
            title: "Отслеживание",
        },

        settings: {
            title: "Настройки",

            profile: {
                title: "Профиль",

                profile: "Профиль",
                security: "Безопасность",
                socials: "Соцсети",
            },

            company: {
                title: "Компания",

                company: "Компания",
                payments: "Оплата",
                plugins: "Интеграции",
                employees: "Сотрудники",
                offices: "Филиалы",
                shops: "Магазины",
                accounts: "Счета",
                stores: "Склады",
            },

            lists: {
                title: "Списки",

                ordertypes: "Типы заявок",
                orderstages: "Этапы заявок",
                salestages: "Этапы продаж",
                producttypes: "Типы продуктов",
                categories: "Категории товаров",
                transactionitems: "Статьи транзакций",
                clientsources: "Источники клиентов",
                works: "Работы",
                suppliers: "Поставщики",
            },

            templates: {
                title: "Шаблоны",

                documents: "Документы",
                sms: "Смс",
                labels: "Ценники",
            },

            help: {
                title: "Справка",
            },
        },

        features: {
            barcodes: {
                typenotspecified: "Необходимо указать тип штрихкода",
                codenotspecified: "Необходимо указать штрихкод",
                apinotspecified: "Системная ошибка",
                invalidsyntax: "Неверный штрихкод",
                alreadyexists: "Товар с таким штрихкодом уже существует",
                unknownerror: "Неизвестная ошибка",
            },
        },
    },

    common: {
        ui: {
            button: {
                login: "Вход",
                back: "Назад",
            },
        },
    },

    errors: {
        error404: "Страница не найдена",
        error500: "Внутренняя ошибка сервера",
        notauthorized: "Требуется авторизация",
        maintenance: "Техническое обслуживание!",
    },
};
