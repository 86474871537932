import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormFile } from "bootstrap-vue";
import { FileUpload } from "./file-upload";

@Component({
    name: "file-upload-component",
    components: { BFormFile },
})
export class FileUploadComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: FileUpload;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                //<div class="form-label-group m-0">
                <div class="m-0">
                    <label class="text-nowrap px-0.5" for={this.handler.id}>
                        {this.handler.label}
                    </label>
                    <b-form-file
                        id={this.handler.id}
                        class={this.handler.class}
                        style={this.handler.style}
                        placeholder={this.handler.placeholder}
                        disabled={this.handler.disabled}
                        drop-placeholder={this.handler.placeholder}
                        browse-text={this.handler.browseText}
                        accept={this.handler.accept}
                        v-model={this.handler.file}
                    />
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}
