/** Статус работы в заявке. */
export enum WorkStatus {
    /** К выполнению. */
    Todo = "todo",

    /** В работе. */
    InWork = "inwork",

    /** Выполнена. */
    Ready = "ready",
}
