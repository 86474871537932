import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import moment from "moment";
import { BButton, BDropdown, BDropdownItem, VBTooltip } from "bootstrap-vue";
import {
    IAccount,
    IGoodCategory,
    ICompany,
    ICompanyFeatureBarcodes,
    IGood,
    IGoodRegistration,
    IGoodRegistrationCreateDto,
    ISelectQuery,
    ISettingsTableUpdateDto,
    IStore,
    ISupplier,
    ITableFilter,
    ITemplateLabel,
    IUser,
    TableType,
} from "@lib";
import { PermissionType, PermissionRight } from "@lib";
import VaAutoButton from "@core/components/va-auto-button";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { Modal2Component } from "@core/components/alt-ui/modal-2";
import { StoresImportContextOut, StoresImportModal } from "../stores-import.modal";
import { StoresExportContextOut, StoresExportModal } from "../stores-export.modal";
import { GoodRegistrationModal } from "@/views/main/good-registrations/good-registration.modal";
import { StoresFilterController } from "../stores-filter-controller";
import FilterModal from "@core/filters/filter-modal.vue";
import { Printer } from "@/@core/usecases/template/printer";
import { GoodPrintContext } from "@core/usecases/template/macro-replacers/good-label.macro-replacer";

@Component({
    name: "stores-toolbar",
    components: {
        BButton,
        BDropdown,
        BDropdownItem,
        VaAutoButton,
        ModalComponent,
        Modal2Component,
        FilterModal,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class StoresToolbar extends Vue {
    @Prop({ type: Array, required: true })
    private stores!: IStore[];

    @Prop({ type: Array, required: true })
    private categories!: IGoodCategory[];

    @Prop({ type: Array, required: true })
    private suppliers!: ISupplier[];

    @Prop({ type: Array, required: true })
    private storesSelected!: IStore[];

    @Prop({ type: Array, default: () => [] })
    private labelTemplates!: ITemplateLabel[];

    @Prop({ type: Array, default: () => [] })
    private filter!: ITableFilter[];

    @Prop({ type: Object, required: true })
    private filterController!: StoresFilterController;

    private BarcodesUseCase = this.$alt.system.usecase.createCompanyFeatureBarcodesUseCase();

    private user!: IUser;
    private company!: ICompany;
    private accounts: IAccount[] = [];
    private barcodesFeature: ICompanyFeatureBarcodes | null = null;

    private goodRegistrationModal: GoodRegistrationModal;

    private storesImportModal: StoresImportModal;
    private storesExportModal: StoresExportModal;

    public constructor() {
        super();

        this.goodRegistrationModal = new GoodRegistrationModal("good-registration-modal.stores");
        this.goodRegistrationModal.onCreate = this.registerGoods.bind(this);

        this.storesImportModal = new StoresImportModal();
        this.storesImportModal.onImport = this.import.bind(this);

        this.storesExportModal = new StoresExportModal();
        this.storesExportModal.onExport = this.export.bind(this);
    }

    private get can(): any {
        const stores = this.storesSelected;
        const secure = this.$secure;
        return {
            get create(): boolean {
                for (const store of stores) {
                    const valid = secure.check(PermissionType.Stores, store.id, PermissionRight.GoodsCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    private get hasFilter(): boolean {
        return this.filter.length > 0 && !!this.filter.find(f => f.field !== "store");
    }

    @Emit("create")
    private startCreate(): void {}

    private async mounted(): Promise<void> {
        this.user = await this.$info.getUser();
        this.company = await this.$info.getCompany();
        this.accounts = await this.$info.getAccounts();

        try {
            this.barcodesFeature = await this.BarcodesUseCase.get(this.company.id);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async showModalGoodRegistration(): Promise<void> {
        const labelTemplatesIds = await this.$settings.getLabelsGoodRegistrationCreate();
        const labelTemplatesSelected = this.labelTemplates.filter(d => labelTemplatesIds.includes(d.id));

        await this.goodRegistrationModal.show({
            companyId: this.company.id,
            userId: this.user.id,
            stores: this.stores,
            store: this.storesSelected[0],
            accounts: this.accounts,
            suppliers: this.suppliers,
            categories: this.categories,
            labelTemplates: this.labelTemplates,
            labelTemplatesSelected: labelTemplatesSelected,
            showBarcode: this.company.features?.barcodes?.enabledForGoods ?? false,
            message: this.$alt.message,
            barcodesFeature: this.barcodesFeature,
            barcodesUseCase: this.BarcodesUseCase,
            searchGoods: this.searchGoods.bind(this),
        });
    }

    private async showModalFilter(): Promise<void> {
        this.$emit("show-filter");
    }

    private async showModalImport(): Promise<void> {
        const context = {
            stores: this.stores,
            storeId: this.storesSelected[0].id,
        };

        await this.storesImportModal.show(context);
    }

    private async showModalExport(): Promise<void> {
        const context = {
            stores: this.stores,
            storeId: this.storesSelected[0].id,
            categories: this.categories,
        };

        await this.storesExportModal.show(context);
    }

    private async saveSelectedLabels(labels: ITemplateLabel[]): Promise<void> {
        try {
            const value = labels.map(d => d.id);
            await this.$settings.setLabelsGoodRegistrationCreate(value);
        } catch {}
    }

    private async registerGoods(
        dto: IGoodRegistrationCreateDto,
        labels: ITemplateLabel[],
    ): Promise<IGoodRegistration | null> {
        try {
            this.$alt.loader.show();
            const service = this.$alt.system.usecase.createGoodRegistrationUseCase();
            const registration = await service.create(this.company.id, dto);
            this.$alt.toast.success("Оприходование успешно создано.");
            this.saveSelectedLabels(labels);
            this.print(labels, registration);
            this.$emit("refresh");
            return registration;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async import(context: StoresImportContextOut): Promise<boolean> {
        try {
            this.$alt.loader.show();

            if (!context.storeId) {
                this.$alt.toast.warning("Склад не выбран.");
                return false;
            }

            if (!context.file) {
                this.$alt.toast.warning("Файл не выбран.");
                return false;
            }

            const tag = `import_${moment().format()}`;
            const dto = { tags: [tag] };
            const service = this.$alt.system.usecase.createGoodUseCase();
            await service.import(this.company.id, context.storeId, context.file, dto);
            this.$alt.toast.success("Данные успешно загружены.");
            this.$emit("refresh");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async export(context: StoresExportContextOut): Promise<boolean> {
        try {
            this.$alt.loader.show();

            const company = await this.$info.getCompany();
            const dto = { ...context.filters };
            const service = this.$alt.system.usecase.createGoodUseCase();
            const file = await service.export(company.id, dto);

            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = `export_${moment().format("YYYYMMDD_HHmmss")}.csv`;
            link.click();
            setTimeout(() => URL.revokeObjectURL(link.href), 0);

            this.$alt.toast.success("Данные успешно выгружены.");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async searchGoods(search: string, storeId: string): Promise<IGood[]> {
        try {
            const query: ISelectQuery = {
                search,
                limit: 10,
                offset: 0,
                //"quantity[gt]": 0,
                //"store[eq]": store.id,
                //"store[in]": [store.id],
            };
            const result = await this.$alt.system.usecase
                .createGoodUseCase()
                .selectForStore(this.company.id, storeId, query);

            return result.data;
        } catch {
            return [];
        }
    }

    private print(labels: ITemplateLabel[], registration: IGoodRegistration): void {
        const goods = registration.goods.map(g => {
            const good = this.$alt.clone(g.goodRef as IGood);
            good.info.quantity = g.quantity;
            good.info.cost = g.cost;
            return good;
        });

        const context: GoodPrintContext = {
            company: this.company,
            stores: this.stores,
            goods: goods,
        };

        Printer.printGoodLabels(labels, context);
    }
}
