import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { IEmployee, IOffice, IOrder, IOrderTask, IOrderTaskCreateDto, IOrderTaskUpdateDto } from "@lib";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { ControlComponent, Panel } from "@core/components/alt-ui/controls";
import { CheckBox, Control, Icon, Label, List } from "@core/components/alt-ui/controls";
import { OrderTaskModal } from "./order-task.modal";

@Component({
    components: {
        BButton,
        ModalComponent,
        ControlComponent,
    },
})
export default class OrderViewTasks extends Vue {
    @Prop({ required: true })
    private order!: IOrder | null;

    @Prop({ type: Array, default: () => [] })
    private tasks!: IOrderTask[];

    @Prop({ type: Array, default: () => [] })
    private employees!: IEmployee[];

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private OrderTaskUseCase = this.$alt.system.usecase.createOrderTaskUseCase();

    private listTasks!: List<IOrderTask>;
    private orderTaskModal!: OrderTaskModal;

    public constructor() {
        super();

        this.listTasks = this.createList();

        this.orderTaskModal = new OrderTaskModal();
        this.orderTaskModal.onCreate = this.createTask.bind(this);
        this.orderTaskModal.onUpdate = this.updateTask.bind(this);
    }

    @Watch("tasks", { immediate: true })
    private onTasksChanged(): void {
        this.listTasks = this.createList();
        this.listTasks.items = this.tasks ?? [];
    }

    private createList(): List<IOrderTask> {
        const list = new List<IOrderTask>();
        list.id = "order-tasks-list";

        list.header = t => {
            const label = new Label();
            label.text = t.title;
            label.class = "text-l font-bold";
            if (t.done) {
                label.class += " strikethrough";
            }
            return label;
        };

        list.content = t => t.description ?? "";

        list.footer = t => {
            if (!t.employee) {
                return null;
            }

            const icon = new Icon();
            icon.icon = "UserIcon";
            icon.class = "h-1 w-1 mr-0.25 text-secondary";

            const label = new Label();
            label.text = this.getEmployeeName(t.employee as string);
            label.class = "text-secondary font-italic";

            const pnl = new Panel();
            pnl.class = "flex w-100 items-center mt-0.5";
            pnl.addControls([icon, label]);

            return pnl;
        };

        if (!this.readonly) {
            list.left = t => this.createCheckBox(t);
        }

        if (!this.readonly) {
            list.tools = (t, i) => {
                const tools: Control[] = [];

                if (i > 0) {
                    const iconUp = new Icon();
                    iconUp.icon = "ArrowUpIcon";
                    iconUp.class = "cursor-pointer hover:text-primary h-1 w-1 mr-0.75";
                    iconUp.addClickHandler(() => this.moveTask(t, i - 1));
                    tools.push(iconUp);
                }

                if (i < this.tasks.length - 1) {
                    const iconDown = new Icon();
                    iconDown.icon = "ArrowDownIcon";
                    iconDown.class = "cursor-pointer hover:text-primary h-1 w-1 mr-0.75";
                    iconDown.addClickHandler(() => this.moveTask(t, i + 1));
                    tools.push(iconDown);
                }

                const iconEdit = new Icon();
                iconEdit.icon = "Edit3Icon";
                iconEdit.class = "cursor-pointer hover:text-primary h-1 w-1 mr-0.75";
                iconEdit.addClickHandler(() => this.showUpdateModal(t));
                tools.push(iconEdit);

                const iconDelete = new Icon();
                iconDelete.icon = "Trash2Icon";
                iconDelete.class = "cursor-pointer hover:text-danger h-1 w-1 mr-0.75";
                iconDelete.addClickHandler(() => this.confirmDeleteTask(t));
                tools.push(iconDelete);

                return tools;
            };
        }

        list.classItem = t =>
            t.done
                ? "flex w-100 align-items-start hover:opacity-75 opacity-25"
                : "flex w-100 align-items-start hover:opacity-75";

        return list;
    }

    private createCheckBox(task: IOrderTask): CheckBox {
        const checkbox = new CheckBox();
        checkbox.id = `chb-${task.id}`;
        checkbox.class = "mr-0.25";
        checkbox.value = task.done ?? false;
        checkbox.addValueChangedHandler((s, e) => {
            this.completeTask(task, e.value);
        });
        return checkbox;
    }

    private getEmployeeName(id: string): string {
        if (!this.employees) {
            return id;
        }

        const employee = this.employees.find(emp => emp.user === id);
        return employee ? employee.userRef.info.name : id;
    }

    private onOrderChanged(order: IOrder): void {
        this.$emit("order-changed", order, this.order);
    }

    public async showCreateModal(): Promise<void> {
        const user = await this.$info.getUser();
        await this.orderTaskModal.show({
            employees: this.employees,
            user: user,
        });
    }

    public async showUpdateModal(task: IOrderTask): Promise<void> {
        const user = await this.$info.getUser();
        await this.orderTaskModal.show({
            task: task,
            employees: this.employees,
            user: user,
        });
    }

    private async confirmDeleteTask(task: IOrderTask): Promise<void> {
        const confirm = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить задачу: "${task.title}"?`,
            "Удаление задачи",
            { acceptText: "Удалить" },
        );

        if (confirm) {
            await this.deleteTask(task);
        }
    }

    private async createTask(dto: IOrderTaskCreateDto): Promise<boolean> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const companyId = this.order.company;
            const officeId = this.order.office;
            const orderId = this.order.id;

            const order = await this.OrderTaskUseCase.create(companyId, officeId, orderId, dto);
            this.onOrderChanged(order);
            return true;
        } catch {
            this.$alt.toast.error("Не удалось добавить задачу.");
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async updateTask(orig: IOrderTask, dto: IOrderTaskUpdateDto): Promise<boolean> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const companyId = this.order.company;
            const officeId = this.order.office;
            const orderId = this.order.id;

            const order = await this.OrderTaskUseCase.update(companyId, officeId, orderId, orig.id, dto);
            this.onOrderChanged(order);
            return true;
        } catch {
            this.$alt.toast.error("Не удалось изменить задачу.");
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async completeTask(task: IOrderTask, done: boolean): Promise<boolean> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const companyId = this.order.company;
            const officeId = this.order.office;
            const orderId = this.order.id;

            const order = await this.OrderTaskUseCase.update(companyId, officeId, orderId, task.id, { done });
            this.onOrderChanged(order);
            return true;
        } catch {
            this.$alt.toast.error("Не удалось изменить задачу.");
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async deleteTask(task: IOrderTask): Promise<void> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const companyId = this.order.company;
            const officeId = this.order.office;
            const orderId = this.order.id;

            const order = await this.OrderTaskUseCase.delete(companyId, officeId, orderId, task.id);
            this.onOrderChanged(order);
        } catch {
            this.$alt.toast.error("Не удалось удалить задачу.");
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async moveTask(task: IOrderTask, index: number): Promise<void> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const companyId = this.order.company;
            const officeId = this.order.office;
            const orderId = this.order.id;

            const order = await this.OrderTaskUseCase.move(companyId, officeId, orderId, task.id, index);
            this.onOrderChanged(order);
        } catch {
            this.$alt.toast.error("Не удалось переместить задачу.");
        } finally {
            this.$alt.loader.hide();
        }
    }
}
