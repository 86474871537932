import {
    IOffice,
    IOrderInfoChangeDto,
    IOrderType,
    IOrderTypeField,
    IOrderTypeFieldGroup,
    OrderTypeFieldGroupType,
} from "@lib";
import { DiscountBoxFieldControl } from "@core/types/field-controls/custom/discount-box.field-control";
import { FieldControl } from "@core/types/field-controls/field-control";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";

export class OrderBlockInfoHandler {
    public controls: FieldControl[] = [];

    private office: IOffice | null = null;
    private orderType: IOrderType | null = null;
    private fieldGroup: IOrderTypeFieldGroup | null = null;

    public get title(): string {
        return this.fieldGroup?.title ?? "Информация";
    }

    public get icon(): string {
        return this.fieldGroup?.icon ?? "info";
    }

    public init(office: IOffice | null, orderType: IOrderType | null): void {
        this.office = office;
        this.orderType = orderType;

        if (!this.office || !this.orderType) {
            return;
        }

        this.fieldGroup = this.orderType.groups.find(field => field.id === OrderTypeFieldGroupType.Info) ?? null;

        this.initControls();
    }

    private initControls(): void {
        this.controls = [];

        const context = {
            locale: this.office?.info?.locale,
        };

        for (const field of this.getFields()) {
            const fieldControl =
                field.id === "discount"
                    ? new DiscountBoxFieldControl(field, context)
                    : FieldControlFactory.fromField(field, context);

            this.controls.push(fieldControl);
        }
    }

    private getFields(): IOrderTypeField[] {
        return (
            this.fieldGroup?.fields
                .filter(f => !f.hidden)
                .sort((a, b) =>
                    a.sequence !== undefined && b.sequence !== undefined ? a.sequence - b.sequence : -1,
                ) ?? []
        );
    }

    public getDto(): { info: IOrderInfoChangeDto; custom: any } {
        const info: IOrderInfoChangeDto = {};
        const custom: any = {};

        for (const fieldControl of this.controls) {
            const value = fieldControl.value;
            this.populateCustomFieldValue(custom, fieldControl.field, value);
            this.populateStandartFieldValue(info, fieldControl.field, value);
        }

        return { info, custom };
    }

    private populateCustomFieldValue(custom: any, field: IOrderTypeField, value: any): void {
        if (!field.custom) {
            // TODO: rename id
            if (field.id === "custom") {
                if (field.details1) {
                    custom[field.details1] = value;
                }
            }

            return;
        }

        // TODO: remove details1
        const id = field.customId ?? field.details1 ?? field.id;
        custom[id] = value;
    }

    private populateStandartFieldValue(info: IOrderInfoChangeDto, field: IOrderTypeField, value: any): void {
        if (field.custom) {
            return;
        }

        switch (field.id) {
            case "name":
                info.name = value;
                return;
            case "description":
                info.description = value;
                return;
            case "createDate":
                info.createDate = value;
                return;
            case "deadline":
                info.deadline = value;
                return;
            case "discount":
                info.discount = value;
                return;
        }
    }

    public clean(): void {
        this.initControls();
    }
}
