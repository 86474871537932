import { ISale, ISaleDeleteDto } from "@lib";
import { Button, CheckBox, Control, Label, Panel } from "@core/components/alt-ui/controls";
import { Modal } from "@core/components/alt-ui/modal";
import { StringUtils } from "@/utils/string.utils";

export enum SaleDeleteModalAnswer {
    Delete = "delete",
    Cancel = "cancel",
}

export interface ISaleDeleteModalResult {
    answer: SaleDeleteModalAnswer;
    data?: ISaleDeleteDto;
}

export class SaleDeleteModal extends Modal<ISale[], ISaleDeleteModalResult> {
    private lblText!: Label;
    private chbReturnToStores!: CheckBox;
    private chbReturnFromAccounts!: CheckBox;

    private btnCancel!: Button;
    private btnDelete!: Button;
    private pnlFooter!: Panel;

    public constructor() {
        super("sale-delete-modal", "Удаление продажи");
        this.initializeControls();
    }

    public show(sales: ISale[]): Promise<ISaleDeleteModalResult> {
        this.initializeControls();
        if (sales.length > 1) {
            const decl = StringUtils.decline(sales.length, ["продажу", "продажи", "продаж"]);
            this.lblText.text = `Вы уверены, что хотите удалить ${sales.length} ${decl}?`;
        }
        return super.show();
    }

    private initializeControls(): void {
        this.lblText = new Label();
        this.lblText.id = "sale-delete.label";
        this.lblText.class = "text-base";
        this.lblText.text = "Вы уверены, что хотите удалить продажу?";

        this.chbReturnToStores = new CheckBox();
        this.chbReturnToStores.id = "sale-delete.return-to-stores";
        this.chbReturnToStores.text = "Вернуть товары на склад";

        this.chbReturnFromAccounts = new CheckBox();
        this.chbReturnFromAccounts.id = "sale-delete.return-from-accounts";
        this.chbReturnFromAccounts.text = "Вернуть средства со счёта";

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "sale-delete.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-warning";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnDelete = new Button();
        this.btnDelete.id = "sale-delete.delete";
        this.btnDelete.text = "Удалить";
        this.btnDelete.variant = "warning";
        this.btnDelete.addClickHandler(this.clickDelete.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnDelete]);
    }

    public get controls(): Control[] {
        return [this.lblText, this.chbReturnToStores, this.chbReturnFromAccounts, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide({ answer: SaleDeleteModalAnswer.Cancel });
    }

    private async clickDelete(sender: any, e: any): Promise<void> {
        const data: ISaleDeleteDto = {
            returnToStores: this.chbReturnToStores.value,
            returnFromAccount: this.chbReturnFromAccounts.value,
        };

        this.hide({
            answer: SaleDeleteModalAnswer.Delete,
            data: data,
        } as ISaleDeleteModalResult);
    }
}
