import { Vue, Component } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ICompany, IStore, IStoreCreateDto, IStoreUpdateDto, IUser } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import VaTable from "@/components/common/va-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { StoreModal } from "./modals/store.modal";
import { getTableColumns } from "./settings-company-stores-defaults";

@Component({
    components: { BButton, VaTable, ModalComponent },
})
export default class SettingsCompanyStores extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private stores: IStore[] = [];

    private StoreUseCase = this.$alt.system.usecase.createStoreUseCase();

    private storeModal: StoreModal;

    public constructor() {
        super();

        this.storeModal = new StoreModal();
        this.storeModal.onCreate = this.create.bind(this);
        this.storeModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Stores, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Stores, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Stores, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectStores();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected async openModalCreate(): Promise<void> {
        await this.storeModal.show();
    }

    protected async openModalUpdate(store: IStore): Promise<void> {
        await this.storeModal.show(store);
    }

    private async confirmDelete(store: IStore): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить склад: "${store.info.name}"?`,
            "Удаление склада",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(store);
        }
    }

    private async selectStores(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.stores = await this.StoreUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить склады:\n${e.message}`);
        }
    }

    private async selectStoresForUser(): Promise<void> {
        const stores = await this.$alt.system.usecase
            .createEmployeeUseCase()
            .selectStores(this.company.id, this.user.id);
        this.$info.setStores(stores);
    }

    private async create(dto: IStoreCreateDto): Promise<IStore | null> {
        try {
            this.$alt.loader.show();
            const st = await this.StoreUseCase.create(this.company.id, dto);
            await this.selectStores();
            this.selectStoresForUser(); // асинхронно
            this.$alt.toast.success("Склад успешно создан.", "Создание");
            return st;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать склад:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(store: IStore, dto: IStoreUpdateDto): Promise<IStore | null> {
        try {
            this.$alt.loader.show();
            const st = await this.StoreUseCase.update(this.company.id, store.id, dto);
            await this.selectStores();
            this.selectStoresForUser(); // асинхронно
            this.$alt.toast.success(`Склад "${store.info.name}" успешно изменён.`, "Изменение");
            return st;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить склад:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(store: IStore): Promise<boolean> {
        try {
            this.$alt.loader.show();
            await this.StoreUseCase.delete(this.company.id, store.id);
            await this.selectStores();
            this.selectStoresForUser(); // асинхронно
            this.$alt.toast.success(`Склад "${store.info.name}" успешно удалён.`, "Удаление");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить склад:\n${e.message}`);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }
}
