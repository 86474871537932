import { Vue, Component, Watch } from "vue-property-decorator";
import {
    IStore,
    IGood,
    ISelectQuery,
    ITable,
    ISettingsTableUpdateDto,
    IGoodCategory,
    IGoodMoveDto,
    IUser,
    ICompany,
    ITemplateLabel,
    TemplateLabelType,
    IGoodMoveManyDto,
    ISupplier,
} from "@lib";
import { IGoodCreateDto, IGoodUpdateDto, IGoodDeleteManyDto } from "@lib";
import { ITableFilter, ITableSort, TableType } from "@lib";
import { PermissionType, PermissionRight } from "@lib";
import VaTable, { TableApi } from "@/components/common/va-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { Modal2Component } from "@core/components/alt-ui/modal-2";
import { Button, Icon } from "@core/components/alt-ui/controls";
import { MultiDropdown } from "@core/controls/multi-dropdown/multi-dropdown";
import { GoodModal, GoodModalContext } from "./good.modal";
import { getDefaultTableColumns, getDefaultTableActions } from "./stores-defaults";
import StoresToolbar from "./stores-toolbar/stores-toolbar.vue";
import StoresCategoryFilter from "./stores-category-filter.vue";
import { GoodMoveModal, GoodMoveModalContext } from "./good-move.modal";
import { GoodPrintModal } from "./good-print.modal";
import { IStoresFilterContext, StoresFilterController } from "./stores-filter-controller";
import { GoodMoveManyModal } from "./good-move-many.modal";

@Component({
    name: "stores",
    components: { VaTable, StoresToolbar, ModalComponent, Modal2Component, StoresCategoryFilter },
})
export default class Stores extends Vue {
    private GoodUseCase = this.$alt.system.usecase.createGoodUseCase();
    private CategoryUseCase = this.$alt.system.usecase.createGoodCategoryUseCase();
    private SupplierUseCase = this.$alt.system.usecase.createSupplierUseCase();
    private SettingsTable = this.$alt.system.usecase.createSettingsTableUseCase();

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private stores: IStore[] = [];
    private categories: IGoodCategory[] = [];
    private suppliers: ISupplier[] = [];
    private selectedStores: IStore[] = [];
    private labelTemplates: ITemplateLabel[] = [];

    private goods: IGood[] = [];
    private goodsTotal: number = 0;

    private table: any = {
        settings: {
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
        },
        page: 1,
        search: "",
        api: new TableApi(),
    };

    private filterController: StoresFilterController;

    private get filterContext(): IStoresFilterContext {
        return {
            categories: this.categories,
            stores: this.stores,

            defaultFilter: {},

            currentFilter: this.filter,
        };
    }

    private goodModal: GoodModal;
    private goodMoveModal: GoodMoveModal;
    private goodPrintModal: GoodPrintModal;
    private goodMoveManyModal: GoodMoveManyModal;

    public constructor() {
        super();

        this.goodModal = new GoodModal();
        this.goodModal.onCreate = this.create.bind(this);
        this.goodModal.onUpdate = this.update.bind(this);
        this.goodModal.onClickMove = this.openModalMove.bind(this);

        this.goodMoveModal = new GoodMoveModal();
        this.goodMoveModal.onConfirm = this.moveGood.bind(this);

        this.goodPrintModal = new GoodPrintModal();

        this.goodMoveManyModal = new GoodMoveManyModal();
        this.goodMoveManyModal.onConfirm = this.moveGoodMany.bind(this);

        this.filterController = new StoresFilterController();
        this.filterController.onSave = this.saveFilter.bind(this);
    }

    private get tableType(): TableType {
        return TableType.Good;
    }

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this);
    }

    private get defaulActions(): any[] {
        return getDefaultTableActions(this);
    }

    private get goodsData(): IGood[] {
        return this.goods ? this.goods : [];
    }

    private get goodsDataTotal(): number {
        return this.goodsTotal ? this.goodsTotal : 0;
    }

    private get can(): any {
        const secure = this.$secure;
        const stores = this.selectedStores;
        return {
            get update(): Function {
                return (good: any): boolean => {
                    return secure.check(PermissionType.Stores, good.store.id, PermissionRight.GoodsUpdate);
                };
            },
            get delete(): Function {
                return (good: any): boolean => {
                    return secure.check(PermissionType.Stores, good.store.id, PermissionRight.GoodsDelete);
                };
            },
            get create(): boolean {
                for (const store of stores) {
                    const valid = secure.check(PermissionType.Stores, store.id, PermissionRight.GoodsCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    private get columns(): any[] {
        // TODO: отрефакторить
        // берём дефолтный набор колонок
        // сначала сортируем их в соответсвии с тем, что лежит в базе
        // затем копируем свойства: ширину и видимость

        const dbColumns = this.table.settings.columns;
        const sorted = this.defaulColumns
            .sort((col1: any, col2: any) => {
                for (let i = 0; i < dbColumns.length; ++i) {
                    if (dbColumns[i].colId === col1.colId) {
                        return -1;
                    }
                    if (dbColumns[i].colId === col2.colId) {
                        return 1;
                    }
                }
                return 0;
            })
            .map((column: any) => {
                const dbColumn = dbColumns.find((dbc: any) => dbc.colId === column.colId);
                if (dbColumn) {
                    column.width = dbColumn.width;
                    column.hide = dbColumn.hide;
                }
                return column;
            });

        return sorted;
    }

    private get limit(): number {
        return this.table.settings.limit ?? 10;
    }

    private get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    private get sort(): ITableSort[] {
        return this.table.settings.sort ?? [];
    }

    private get filter(): ITableFilter[] {
        return this.table.settings.filter ?? [];
    }

    public get categoryHashMap(): Record<string, { name: string; color: string }> {
        return this.categories.reduce<Record<string, { name: string; color: string }>>((a, v) => {
            a[v.id] = {
                name: v.name,
                color: v.color,
            };

            return a;
        }, {});
    }

    private get hasFilter(): boolean {
        return this.filter.length > 0 && !!this.filter.find(f => f.field !== "store");
    }

    private get hasGoods(): boolean {
        return this.goodsData.length > 0 || this.hasFilter || this.table.search.length > 0;
    }

    @Watch("selectedStores", { deep: true, immediate: true })
    private onSelectedStoresUpdate(): void {
        const headerControls = this.$info.ui.getHeaderControls();

        const hdrStores = headerControls.find(c => c.id === "stores.header-stores") as MultiDropdown<IStore>;
        if (hdrStores) {
            if (!this.$alt.compareArrays(hdrStores.selectedItems, this.selectedStores, e => e.id)) {
                hdrStores.selectedItems = this.selectedStores;
            }
        }
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.stores = await this.$info.getStores();
            await this.initData();
            await this.initParams();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        await Promise.all([this.selectTableSettings(), this.selectLabelTemplates()]);

        const filterStores = this.filter.filter(item => item.field === "store");
        this.selectedStores = this.stores.filter(s => !!filterStores.find(f => f.value === s.id));

        this.categories = await this.CategoryUseCase.selectAll(this.company.id);
        this.suppliers = await this.SupplierUseCase.select(this.company.id);

        this.filterController.init(this.filterContext);

        if (!this.selectedStores.length && this.stores[0]) {
            this.filterController.filter.store.set([this.stores[0].id]);

            this.saveFilter(this.filterController.tableFilter);
        }

        this.initHeader();

        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private initHeader(): void {
        const hdrStores = new MultiDropdown<IStore>();
        hdrStores.id = "stores.header-stores";
        hdrStores.items = this.stores;
        hdrStores.selectedItems = this.selectedStores;
        hdrStores.itemId = item => item.id;
        hdrStores.itemName = item => item.info.name;
        hdrStores.iconPackage = "feather";
        hdrStores.icon = "PackageIcon";
        hdrStores.locale = {
            Tooltip: "Выбрать склад",
            ButtonSelectOne: "Выбрать один",
            ButtonSelectMultiple: "Выбрать несколько",
            ButtonSelectAll: "Выбрать все",
            TextNotSelected: "Склад не выбран",
            TextSelectedAll: "Все склады",
            TextSelectedMultiple: "Выбрано:",
            TextSelectedMultipleForms: ["склад", "склада", "складов"],
        };
        hdrStores.addChangedHandler((s, e) => {
            this.filterController.filter.store.set(e.items.map(store => store.id));
            this.saveFilter(this.filterController.tableFilter);
        });

        const hdrRefresh = new Button();
        hdrRefresh.id = "stores.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrStores, hdrRefresh]);
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$route.query.new !== undefined) {
                this.openModalCreate();
                return;
            }

            if (this.$route.query.id) {
                const goodId =
                    this.$route.query.id instanceof Array ? (this.$route.query.id[0] as string) : this.$route.query.id;
                const good = await this.getGood(goodId);
                this.openModalUpdate(good);
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            throw new Error("Товар не найден.");
        }
    }

    public onCategoryFilerSelectAll(): void {
        this.filterController.filter.category.set([]);

        this.saveFilter(this.filterController.tableFilter);
    }

    public onCategoryFilterSelect(categoryValue: string): void {
        this.filterController.filter.category.set([categoryValue]);

        this.saveFilter(this.filterController.tableFilter);
    }

    public showFilter(): void {
        this.filterController.show(this.filterContext);
    }

    private async saveFilter(filter: ITableFilter[]): Promise<boolean> {
        try {
            const dto: ISettingsTableUpdateDto = { filter };
            await this.SettingsTable.update(this.company.id, this.user.id, TableType.Good, dto);

            this.changeFilter(filter);

            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось сохранить настройки фильтра:\n${e.message}`);

            return false;
        }
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectTableSettings(): Promise<void> {
        let settings: ITable | null = null;
        try {
            settings = await this.SettingsTable.get(this.company.id, this.user.id, this.tableType);
        } catch {}
        this.applyTableSettings(settings ?? this.table.settings);
    }

    private applyTableSettings(settings: ITable | null): void {
        if (!settings) {
            throw new Error();
        }

        this.table.settings = settings;

        if (settings.filter && settings.filter.length > 0) {
            return;
        }
    }

    private async selectLabelTemplates(): Promise<void> {
        try {
            this.labelTemplates = await this.$alt.system.usecase
                .createTemplateLabelUseCase()
                .selectByType(this.company.id, TemplateLabelType.GoodLabel);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить ценники:\n${e.message}`);
        }
    }

    private async changeTableColumns(columns: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            // добавить к запросу номер страницы: p=2
            // if (page === 1) super.queryRemove("p");
            // else super.queryAdd("p", page);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private openModalCreate(): void {
        const context: GoodModalContext = {
            company: this.company,
            stores: this.stores,
            selectedStores: this.selectedStores,
            categories: this.categories,
            showBarcode: this.company.features?.barcodes?.enabledForGoods ?? false,
        };
        this.goodModal.show(context);
    }

    private openModalUpdate(good: IGood): void {
        const context: GoodModalContext = {
            company: this.company,
            good: good,
            stores: this.stores,
            selectedStores: this.selectedStores,
            categories: this.categories,
            showBarcode: this.company.features?.barcodes?.enabledForGoods ?? false,
            templates: this.labelTemplates,
            goodPrintModal: this.goodPrintModal,
        };
        this.goodModal.show(context);
    }

    private openModalMove(good: IGood): void {
        const context: GoodMoveModalContext = {
            good: good,
            stores: this.stores,
        };

        this.goodMoveModal.show(context);
    }

    private openModalPrint(template: ITemplateLabel, goods: IGood[]): void {
        const context = { goods, template, stores: this.stores, company: this.company };
        this.goodPrintModal.show(context);
    }

    private openModalMoveMany(goods: IGood[]): void {
        const context = { goods, stores: this.stores };
        this.goodMoveManyModal.show(context);
    }

    private async confirmDelete(good: IGood): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить товар: "${good.info.name}"?`,
            "Удаление товара",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(good);
        }
    }

    private async confirmDeleteMany(goods: IGood[]): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить ${goods.length} товаров?`,
            "Удаление товаров",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.deleteMany(goods);
        }
    }

    private async changeFilter(filter: ITableFilter[], withDataUpdate = true): Promise<void> {
        try {
            this.table.settings.filter = filter;

            const filterStores = this.filter.filter(item => item.field === "store");
            this.selectedStores = this.stores.filter(s => !!filterStores.find(f => f.value === s.id));

            if (withDataUpdate) {
                await this.selectData(this.skip, this.limit, this.sort, this.filter);
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async selectStores(): Promise<void> {
        try {
            this.stores = await this.selectStoresForUser();

            const filterStores = this.filter.filter(item => item.field === "store");
            this.selectedStores = this.stores.filter(s => !!filterStores.find(f => f.value === s.id));
        } catch (e: any) {
            throw new Error(`Не удалось загрузить счета:\n${e.message}`);
        }
    }

    private async selectStoresForUser(): Promise<IStore[]> {
        const stores = await this.$alt.system.usecase
            .createEmployeeUseCase()
            .selectStores(this.company.id, this.user.id);
        this.$info.setStores(stores);
        return stores;
    }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            // обновлять данные складов
            await this.selectStores();

            const filter = this.$alt.system.query.convertTableFilter(tfilter);
            const sort = this.$alt.system.query.convertTableSort(tsort);
            const search = this.table.search.length > 0 ? this.table.search : undefined;
            const query: ISelectQuery = { limit, offset, sort, ...filter, search };
            const result = await this.GoodUseCase.select(this.company.id, query);

            this.goods = result.data;
            this.goodsTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить товары:\n${e.message}`);
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            // начинать поиск от 2 символов
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.goods = [];
            this.goodsTotal = 0;
        }
    }

    private async getGood(id: string): Promise<any> {
        try {
            const good = await this.GoodUseCase.get(this.company.id, id);
            if (!good) {
                throw new Error("Товар не найден.");
            }
            return good;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить данные товара:\n${e.message}`);
        }
    }

    private async create(dto: IGoodCreateDto): Promise<IGood | null> {
        try {
            this.$alt.loader.show();
            const gd = await this.GoodUseCase.create(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Товар успешно создан.");
            return gd;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async moveGood(good: IGood, dto: IGoodMoveDto): Promise<IGood | null> {
        try {
            this.$alt.loader.show();
            const gd = await this.GoodUseCase.move(this.company.id, good.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Товар успешно перемещён.");
            return gd;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async moveGoodMany(dto: IGoodMoveManyDto): Promise<IGood[] | null> {
        try {
            this.$alt.loader.show();
            const goods = await this.GoodUseCase.moveMany(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Товары успешно перемещены.");
            return goods;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(good: IGood, dto: IGoodUpdateDto): Promise<IGood | null> {
        try {
            this.$alt.loader.show();
            const gd = await this.GoodUseCase.update(this.company.id, good.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Товар успешно изменён.");
            return gd;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(good: IGood): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.GoodUseCase.delete(this.company.id, good.id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Товар успешно удалён.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async deleteMany(goods: IGood[]): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: IGoodDeleteManyDto = {
                ids: goods.map(g => g.id),
            };
            await this.GoodUseCase.deleteMany(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Товары успешно удалены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
