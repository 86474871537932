import { Vue, Component, Prop } from "vue-property-decorator";
import { BDropdown, BDropdownItem, BDropdownDivider } from "bootstrap-vue";
import VaTableActionsItem from "./va-table-actions-item.vue";

export interface IActionsOptions {
    minSelected?: number;
    actions?: any[];
}

const DefaultActionsOptions = {
    minSelected: 2,
    actions: [],
};

@Component({
    name: "va-table-actions",
    components: { BDropdown, BDropdownItem, BDropdownDivider, VaTableActionsItem },
})
export default class VaTableActions extends Vue {
    @Prop({ type: Object, default: () => {} })
    private actionsOptions!: IActionsOptions;

    @Prop({ type: Object, required: true })
    private gridOptions!: any;

    private get options(): IActionsOptions {
        return { ...DefaultActionsOptions, ...this.actionsOptions };
    }

    private get selectedItems(): any[] {
        return this.gridOptions.api.getSelectedRows();
    }

    private deselect(): void {
        this.gridOptions.api.deselectAll();
    }
}
