import { IGoodCategory, IStore, ITableFilter, Locale } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, CheckBox, Control, MultiSelect, Panel } from "@core/components/alt-ui/controls";
import { ISelectOption } from "@core/types/common/select-options";
import { NumericRangeControl } from "@/@core/controls/numeric-range.control";
import { Localizer } from "@/i18n/localizer";
import { Filter } from "@/utils/filter";
import { QueryConverter } from "@/utils/plugins/query-converter";

export type StoresExportContextIn = { stores: IStore[]; categories: IGoodCategory[] };
export type StoresExportContextOut = { filters?: Record<string, any> };

export class StoresExportModal extends Modal<StoresExportContextIn, void> {
    private chSetupFilters!: CheckBox;

    private pnFilter!: Panel;
    private msStore!: MultiSelect;
    private msCategory!: MultiSelect;
    private nrCost!: NumericRangeControl;
    private nrPrice!: NumericRangeControl;
    private nrQuantity!: NumericRangeControl;

    private btnExport!: Button;

    public onExport: ((context: StoresExportContextOut) => Promise<boolean>) | null = null;

    private stores: IStore[] = [];
    private categories: IGoodCategory[] = [];
    private storeId: string | null = null;

    public constructor() {
        super("modal-stores-export", "Экспорт товаров");

        this.initializeControls();
    }

    public show(context: StoresExportContextIn): Promise<void> {
        this.stores = context.stores;
        this.categories = context.categories.filter(e => !e.archived);

        this.initializeControls();

        return super.show();
    }

    private initializeControls(): void {
        this.chSetupFilters = new CheckBox();
        this.btnExport = new Button();

        //

        this.chSetupFilters.id = "stores.export.checkbox";
        this.chSetupFilters.text = "Настроить фильтр";
        this.chSetupFilters.class = "mt-0.25";

        this.initializeFilterPanel();

        this.btnExport.id = "stores.export.export";
        this.btnExport.text = "Экспорт";
        this.btnExport.class = "mt-0.25";
        this.btnExport.addClickHandler(this.onClick.bind(this));
    }

    public initializeFilterPanel(): void {
        this.pnFilter = new Panel();

        //

        const storeOptions: ISelectOption[] = this.stores.map(s => ({ id: s.id, name: s.info.name }));
        const storeOptionFirst = storeOptions.length > 0 ? storeOptions[0] : null;
        const storeSelected = this.storeId
            ? storeOptions.find(o => o.id === this.storeId) ?? storeOptionFirst
            : storeOptionFirst;

        this.msStore = new MultiSelect();
        this.msStore.id = "stores.export.filter.store";
        this.msStore.label = "Склад";
        this.msStore.items = storeOptions;
        this.msStore.selectedItems = [storeSelected];
        this.msStore.textField = (opt: ISelectOption) => opt.name;

        this.msCategory = new MultiSelect();
        this.msCategory.id = "stores.filter.category";
        this.msCategory.label = "Категории товара";
        this.msCategory.items = this.categories;
        this.msCategory.textField = (opt: IGoodCategory) => opt.name;
        this.msCategory.descriptionField = (opt: IGoodCategory) => opt.description;

        //

        this.nrCost = new NumericRangeControl({
            id: "stores.export.filter.cost",
            title: "Себестоимость",
            validation: "money|unsigned",
        });

        this.nrPrice = new NumericRangeControl({
            id: "stores.export.filter.price",
            title: "Цена продажи",
            validation: "money|unsigned",
        });

        this.nrQuantity = new NumericRangeControl({
            id: "stores.export.filter.quantity",
            title: "Количество",
            validation: "numeric",
        });

        //

        this.pnFilter.class = "space-y-2";
        this.pnFilter.addControls([this.msStore, this.msCategory, this.nrCost, this.nrPrice, this.nrQuantity]);
    }

    public get controls(): Control[] {
        if (this.chSetupFilters.value) {
            return [this.chSetupFilters, this.pnFilter, this.btnExport];
        }

        return [this.chSetupFilters, this.btnExport];
    }

    private async onClick(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        this.btnExport.disabled = true;

        const result = await this.export();
        if (result) {
            this.btnExport.disabled = false;
            this.hide(result);
        }
    }

    private extractFilterValues(): null | Record<string, any> {
        if (!this.chSetupFilters.value) {
            return null;
        }

        const tableFilter: ITableFilter[] = [];
        const locale = Locale.RU;

        tableFilter.push(
            ...Filter.convertInBack(
                "store",
                this.msStore.selectedItems.map(e => e.id),
            ),
        );
        tableFilter.push(
            ...Filter.convertInBack(
                "category",
                this.msCategory.selectedItems.map(e => e.id),
            ),
        );
        tableFilter.push(
            ...Filter.convertBetweenBack("cost", [
                this.nrCost.value[0] ? Localizer.number(parseFloat(this.nrCost.value[0]), locale) : "",
                this.nrCost.value[1] ? Localizer.number(parseFloat(this.nrCost.value[1]), locale) : "",
            ]),
        );
        tableFilter.push(
            ...Filter.convertBetweenBack("price", [
                this.nrPrice.value[0] ? Localizer.number(parseFloat(this.nrPrice.value[0]), locale) : "",
                this.nrPrice.value[1] ? Localizer.number(parseFloat(this.nrPrice.value[1]), locale) : "",
            ]),
        );
        tableFilter.push(
            ...Filter.convertBetweenBack("quantity", [this.nrQuantity.value[0] ?? "", this.nrQuantity.value[1] ?? ""]),
        );

        const queryConverter = new QueryConverter();

        return queryConverter.convertTableFilter(tableFilter);
    }

    private async export(): Promise<boolean> {
        if (!this.onExport) {
            return false;
        }

        const filters = this.extractFilterValues();

        if (!filters) {
            return await this.onExport({});
        }

        return await this.onExport({ filters });
    }
}
