/** Метка статьи транзакции (для автоматизации). */
export enum TransactionItemTag {
    /** Предоплата заявки/продажи */
    Prepayment = "prepay",

    /** Оплата заявки/продажи */
    Payment = "payment",

    /** Возврат средств */
    Refund = "refund",

    /** Зарплата сотрудникам */
    Salary = "salary",

    /** Штраф сотрудника */
    Fine = "fine",

    /** Расходы на товары/материалы */
    SupplierExpenses = "supplier-expenses",

    /** Входящий перевод */
    TransferIncome = "transfer-income",

    /** Исходящий первод */
    TransferExpense = "transfer-expense",

    /** Прочие доходы */
    OtherIncomes = "other-incomes",

    /** Прочие расходы */
    OtherExpenses = "other-expenses"
}
