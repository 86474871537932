import { Vue, Component } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { BForm, BButton } from "bootstrap-vue";
import { CompanyFeature, ICompany, ICompanyUpdateDto, OrderNumeration, SaleNumeration } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { PhoneBox, Select, SwitchBox, TextArea, TextBox } from "@core/components/alt-ui/controls";
import { getOrderNumerations, getSaleNumerations, ISelectOption } from "@/@core/types/common/select-options";

@Component({
    components: {
        BForm,
        BButton,
        ValidationObserver,
        ControlComponent,
    },
})
export default class SettingsCompanyGeneral extends Vue {
    private company!: ICompany;

    private tbName: TextBox;
    private taDescription: TextArea;

    private tbEmail: TextBox;
    private tbPhone: PhoneBox;
    private tbAddress: TextBox;
    private tbWebsite: TextBox;

    private cbOrderNumeration!: Select<ISelectOption>;
    private cbSaleNumeration!: Select<ISelectOption>;

    private swFeatureGoodBarcodes: SwitchBox;

    private tbReferralLink: TextBox;

    public constructor() {
        super();

        this.tbName = new TextBox();
        this.tbName.id = "company.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.taDescription = new TextArea();
        this.taDescription.id = "company.description";
        this.taDescription.label = "Описание";

        //

        this.tbEmail = new TextBox();
        this.tbEmail.id = "company.email";
        this.tbEmail.label = "Электронная почта";
        this.tbEmail.validation = "required|email";

        this.tbPhone = new PhoneBox();
        this.tbPhone.id = "company.phone";
        this.tbPhone.label = "Телефон";

        this.tbAddress = new TextBox();
        this.tbAddress.id = "company.address";
        this.tbAddress.label = "Адрес";

        this.tbWebsite = new TextBox();
        this.tbWebsite.id = "company.website";
        this.tbWebsite.label = "Сайт";

        this.cbOrderNumeration = new Select<ISelectOption>();
        this.cbOrderNumeration.id = "company.orderNumeration";
        this.cbOrderNumeration.label = "Нумерация заявок";
        this.cbOrderNumeration.items = getOrderNumerations();
        this.cbOrderNumeration.textField = (opt: ISelectOption) => opt.name;
        this.cbOrderNumeration.descriptionField = (opt: ISelectOption) => opt.description;
        this.cbOrderNumeration.selectedIndex = 0;

        this.cbSaleNumeration = new Select<ISelectOption>();
        this.cbSaleNumeration.id = "company.saleNumeration";
        this.cbSaleNumeration.label = "Нумерация продаж";
        this.cbSaleNumeration.items = getSaleNumerations();
        this.cbSaleNumeration.textField = (opt: ISelectOption) => opt.name;
        this.cbSaleNumeration.descriptionField = (opt: ISelectOption) => opt.description;
        this.cbSaleNumeration.selectedIndex = 0;

        //

        this.swFeatureGoodBarcodes = new SwitchBox();
        this.swFeatureGoodBarcodes.id = "company.feature.good-barcodes";
        this.swFeatureGoodBarcodes.text = "Штрихкоды товаров";
        this.swFeatureGoodBarcodes.switch = true;

        //

        this.tbReferralLink = new TextBox();
        this.tbReferralLink.id = "company.referral-link";
        this.tbReferralLink.disabled = true;
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get update(): boolean {
                return secure.isAdmin(); // secure.checkCommon(PermissionCommonSection.Company, PermissionRight.Update);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.company = await this.$info.getCompany();
            this.init();
            await this.getPartner();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private init(): void {
        this.tbName.text = this.company.info.name;
        this.taDescription.text = this.company.info.description;

        this.tbEmail.text = this.company.info.contacts.email ?? "";
        this.tbPhone.text = this.company.info.contacts.phone ?? "";
        this.tbAddress.text = this.company.info.contacts.address ?? "";
        this.tbWebsite.text = this.company.info.contacts.website ?? "";

        const orderNumerationIndex = this.cbOrderNumeration.items.findIndex(
            i => i.id === this.company.features?.offices?.orderNumeration,
        );
        this.cbOrderNumeration.selectedIndex = orderNumerationIndex >= 0 ? orderNumerationIndex : 0;
        const saleNumerationIndex = this.cbSaleNumeration.items.findIndex(
            i => i.id === this.company.features?.shops?.saleNumeration,
        );
        this.cbSaleNumeration.selectedIndex = saleNumerationIndex >= 0 ? saleNumerationIndex : 0;

        this.swFeatureGoodBarcodes.value = this.company.features?.barcodes?.enabledForGoods ?? false;
    }

    private async save(): Promise<void> {
        try {
            const result = await (this.$refs["formValidator"] as any).validate();
            if (!result) {
                this.$alt.toast.error("Не все поля верно заполнены.");
                return;
            }

            this.$alt.loader.show();

            const dtoInfo: ICompanyUpdateDto = {
                name: this.tbName.text,
                description: this.taDescription.text,
                contacts: {
                    email: this.tbEmail.text,
                    phone: this.tbPhone.text,
                    address: this.tbAddress.text,
                    website: this.tbWebsite.text,
                },
                features: {
                    [CompanyFeature.Shops]: {
                        saleNumeration: this.cbSaleNumeration.selectedItem?.id as SaleNumeration,
                    },
                    [CompanyFeature.Offices]: {
                        orderNumeration: this.cbOrderNumeration.selectedItem?.id as OrderNumeration,
                    },
                    [CompanyFeature.Barcodes]: {
                        enabledForGoods: this.swFeatureGoodBarcodes.value,
                    },
                },
            };

            const companyUseCase = this.$alt.system.usecase.createCompanyUseCase();
            const company = await companyUseCase.update(this.company.id, dtoInfo);

            this.$info.setCompany(company);
            this.company = company;
            this.init();
            this.$alt.toast.success("Изменения сохранены.", "Сохранение");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось сохранить изменения:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private reset(): void {
        this.init();
        this.$alt.toast.success("Изменения сброшены.", "Сброс");
    }

    private async generateReferralLink(): Promise<void> {
        const partner = await this.$alt.system.usecase.createPartnerUseCase().create(this.company.id);
        if (partner) {
            this.tbReferralLink.text = this.getReferralLink(partner.partnerId);
        }
    }

    private async copyReferralLink(): Promise<void> {
        this.$copyText(this.tbReferralLink.text);
        this.$alt.toast.success("Ссылка скопирована.");
    }

    private async getPartner(): Promise<void> {
        try {
            const partner = await this.$alt.system.usecase.createPartnerUseCase().getByCompany(this.company.id);
            const partnerId = partner?.partnerId;
            if (partnerId) {
                this.tbReferralLink.text = this.getReferralLink(partnerId);
            }
        } catch (e: any) {
            throw new Error(`Не удалось загрузить данные:\n${e.message}`);
        }
    }

    private getReferralLink(partnerId: string): string {
        const register = this.$router.resolve({ name: "registration", query: { partner: partnerId } }).href;
        return `${window.location.origin}${register}`;
    }
}
