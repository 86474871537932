import {
    IGoodInventory,
    IGoodInventoryCreateDto,
    IGoodInventoryUpdateDto,
    IGoodInventoryUseCase,
    ISelectQuery,
    ISelectedData,
} from "@lib";
import { Api } from "../axios";
import { DataLoadException } from "@/exceptions";

export class GoodInventoryUseCase implements IGoodInventoryUseCase {
    public async select(companyId: string, query?: ISelectQuery): Promise<ISelectedData<IGoodInventory>> {
        try {
            const response = await Api.get<ISelectedData<IGoodInventory>>(`/companies/${companyId}/good-inventories`, {
                params: query,
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IGoodInventory> {
        try {
            const response = await Api.get<IGoodInventory>(`/companies/${companyId}/good-inventories/${id}`);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IGoodInventoryCreateDto): Promise<IGoodInventory> {
        try {
            const response = await Api.post<IGoodInventory>(`/companies/${companyId}/good-inventories/`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async resume(companyId: string, id: string): Promise<IGoodInventory> {
        try {
            const response = await Api.post<IGoodInventory>(`/companies/${companyId}/good-inventories/${id}/resume`);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IGoodInventoryUpdateDto): Promise<IGoodInventory> {
        try {
            const response = await Api.put<IGoodInventory>(`/companies/${companyId}/good-inventories/${id}`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/good-inventories/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
