












import { Vue, Component } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";

@Component({
    components: { BButton },
})
export default class Maintenance extends Vue {
    //
}
