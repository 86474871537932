import { IGoodCategory, IStore } from "@lib";

export function getTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.create && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (category: IGoodCategory) => context.openModalUpdate(category),
                        },
                        {
                            id: "copy",
                            description: "Скопировать",
                            icon: "CopyIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.create,
                            click: (category: IGoodCategory) => context.confirmCopy(category),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (category: IGoodCategory) => context.confirmDelete(category),
                        },
                    ],
                };
            },
        },
        {
            colId: "sequence",
            field: "sequence",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,
        },
        {
            colId: "stores",
            field: "stores",
            headerName: "Склад",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,

            cellRendererFramework: "CellRendererChips",
            cellRendererParams: (params: any) => {
                const stores = params.value as IStore[];

                if (!stores.length) {
                    return {
                        chips: [
                            {
                                text: "Не указан",
                            },
                        ],
                    };
                }

                return {
                    chips: stores.map(store => ({ text: store.info.name })),
                };
            },
        },
        {
            colId: "name",
            field: "name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,
        },
        {
            colId: "description",
            field: "description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 400,
            hide: false,
        },
        {
            colId: "color",
            field: "color",
            headerName: "Цвет",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                if (!params.value || params.value.length === 0) {
                    return { color: "#82868b", name: "#82868b" };
                }

                return {
                    color: params.value,
                    name: params.value,
                };
            },
        },
    ];
}
