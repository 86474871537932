import { EventHandler } from "@core/components/alt-ui";
import { BarcodeControl } from "@core/controls/barcode/barcode-control";
import { WarrantyControl } from "@core/controls/warranty.control";
import { Localizer } from "@/i18n/localizer";
import { DelayedOperation } from "@/utils/delayed-operation";
import {
    Button,
    ComboBoxChangedEventArgs,
    Control,
    IconPackage,
    Label,
    MultiSelect,
    Panel,
    Select,
    TextArea,
    TextBox,
    TextChangedEventArgs,
} from "@core/components/alt-ui/controls";
import { Modal } from "@core/components/alt-ui/modal";
import { IBarcode, BarcodeType, IGoodCategory, IGood, IGoodCreateDto, IGoodUpdateDto, IStore, Locale } from "@lib";

export interface IGoodRegistrationItemModalContext {
    companyId: string;
    good?: IGood; // товар для редактирования
    stores: IStore[];
    selectedStore: IStore;
    categories: IGoodCategory[];
    showBarcode: boolean;
    selectedType?: BarcodeType;
    searchGoods: (search: string, storeId: string) => Promise<IGood[]>;
    generateBarcode: () => Promise<IBarcode | null>;
    updateBarcodeType: (type: BarcodeType) => Promise<void>;
}

export class GoodRegistrationItemModal extends Modal<IGoodRegistrationItemModalContext> {
    private cbGoodSearch!: Select<IGood>;

    private bcBarcode!: BarcodeControl;
    private tbSku!: TextBox;
    private tbName!: TextBox;
    private taDescription!: TextArea;
    private msCategory!: MultiSelect<IGoodCategory>;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    private tbQuantity!: TextBox;
    private lbQuantity!: Label;
    private wcWarranty!: WarrantyControl;

    private pnlGetInfo!: Panel;
    private pnlCreateInfo!: Panel;

    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private context: IGoodRegistrationItemModalContext | null = null;
    private categories!: IGoodCategory[];

    public onAdd: ((dto: IGoodCreateDto) => Promise<boolean>) | null = null;
    public onCreate: ((dto: IGoodCreateDto) => Promise<boolean>) | null = null;
    public onUpdate: ((good: IGood, dto: IGoodUpdateDto) => Promise<boolean>) | null = null;

    private changeQuantityBinded: EventHandler<TextChangedEventArgs> = this.changeQuantity.bind(this);
    private changeCostBinded: EventHandler<TextChangedEventArgs> = this.changeCost.bind(this);
    private changePriceBinded: EventHandler<TextChangedEventArgs> = this.changePrice.bind(this);

    public constructor() {
        super("good-registration-item-modal", "Добавление товара");
        this.initializeControls();
    }

    public show(context: IGoodRegistrationItemModalContext): Promise<void> {
        this.context = context;
        this.categories = this.context.categories.filter(e => !e.archived);

        this.initializeControls();

        if (context.good) {
            this.title = "Изменение товара";
            this.btnSave.text = "Изменить";
            this.populateControls(context.good);
        } else {
            this.title = "Новый товар";
            this.btnSave.text = "Добавить";
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.cbGoodSearch = new Select<IGood>();
        this.cbGoodSearch.id = "good.good";
        this.cbGoodSearch.label = "Искать товар";
        this.cbGoodSearch.items = [];
        this.cbGoodSearch.iconPackage = IconPackage.Feater;
        this.cbGoodSearch.icon = "SearchIcon";
        this.cbGoodSearch.selectedIndex = this.cbGoodSearch.items.length > 0 ? 0 : -1;
        this.cbGoodSearch.clearable = true;
        this.cbGoodSearch.textField = e => e.info.name;
        this.cbGoodSearch.descriptionField = this.goodDescriptionField.bind(this);
        this.cbGoodSearch.search = this.searchGood.bind(this);
        this.cbGoodSearch.addChangedHandler(this.selectGood.bind(this));

        this.bcBarcode = new BarcodeControl({
            companyId: this.context?.companyId ?? "",
            selectedType: this.context?.selectedType,
            onChangeType: this.context?.updateBarcodeType,
        });
        if (this.context?.showBarcode) {
            this.generateBarcode();
        }
        this.bcBarcode.visible = this.context?.showBarcode ?? false;

        this.tbSku = new TextBox();
        this.tbSku.id = "good.sku";
        this.tbSku.label = "Артикул";
        this.tbSku.text = "";

        this.tbName = new TextBox();
        this.tbName.id = "good.name";
        this.tbName.label = "Наименование";
        this.tbName.validation = "required";
        this.tbName.text = "";

        this.taDescription = new TextArea();
        this.taDescription.id = "good.description";
        this.taDescription.label = "Описание";
        this.taDescription.text = "";

        this.msCategory = new MultiSelect<IGoodCategory>();
        this.msCategory.id = "good.category";
        this.msCategory.label = "Категории";
        this.msCategory.items = this.getAllowedCategories();
        this.msCategory.textField = e => e.name;
        this.msCategory.descriptionField = e => e.description;

        this.tbCost = new TextBox();
        this.tbCost.id = "good.cost";
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "required|money|unsigned";
        this.tbCost.text = "";

        this.tbPrice = new TextBox();
        this.tbPrice.id = "good.price";
        this.tbPrice.label = "Цена продажи";
        this.tbPrice.validation = "required|money|unsigned";
        this.tbPrice.text = "";

        this.tbQuantity = new TextBox();
        this.tbQuantity.id = "good.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = "required|numeric|unsigned";
        this.tbQuantity.text = "";

        this.lbQuantity = new Label();
        this.lbQuantity.class = "text-sm text-primary mb-0.75";

        this.wcWarranty = new WarrantyControl();
        this.wcWarranty.id = "good.warranty";
        this.wcWarranty.label = "Гарантия";

        //

        this.pnlCreateInfo = new Panel();
        this.pnlCreateInfo.addControls([
            this.bcBarcode,
            this.tbSku,
            this.tbName,
            this.taDescription,
            this.msCategory,
            this.tbCost,
            this.tbPrice,
            this.tbQuantity,
            this.wcWarranty,
        ]);

        this.pnlGetInfo = new Panel();
        this.pnlGetInfo.addControls([this.tbQuantity, this.lbQuantity, this.tbCost, this.tbPrice]);
        this.pnlGetInfo.visible = false;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "good-get.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "good-get.save";
        this.btnSave.text = "Добавить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(good: IGood): void {
        const locale = good.storeRef?.info.locale ?? Locale.RU;

        // В context.categories лежат все категории, в т.ч и удалённые
        // Мы ищем в них потому что у пользователя мог остаться сохраненный фильтр
        // А сама категория была архивирован
        const categories = good.info.categories
            ?.map(categoryId => this.context?.categories.find(e => e.id === categoryId) ?? null)
            .filter(e => e) as IGoodCategory[];

        this.bcBarcode.barcodes = good.info.barcodes ?? [];
        this.tbSku.text = good.info.sku ?? "";
        this.tbName.text = good.info.name;
        this.taDescription.text = good.info.description ?? "";
        this.msCategory.selectedItems = categories;
        this.tbCost.text = Localizer.number(good.info.cost, locale);
        this.tbPrice.text = Localizer.number(good.info.price, locale);
        this.tbQuantity.text = good.info.quantity.toString();
        this.wcWarranty.value = good.info.warranty;
    }

    public get controls(): Control[] {
        return [this.cbGoodSearch, this.pnlCreateInfo, this.pnlGetInfo, this.pnlFooter];
    }

    private async generateBarcode(): Promise<void> {
        if (!this.context) {
            return;
        }

        const barcode = await this.context.generateBarcode();
        if (barcode) {
            this.bcBarcode.barcodes = [barcode];
        }
    }

    private searchGood(search: string): void {
        if (!this.context) {
            return;
        }

        const searchGoods = this.context.searchGoods;
        const storeId = this.context.selectedStore.id;
        search = search.trim();

        // начинать поиск от 2 символов
        if (search.length < 2) {
            this.cbGoodSearch.items = [];
            return;
        }

        DelayedOperation.invoke("search-goods", 700, async () => {
            this.cbGoodSearch.items = await searchGoods(search, storeId);
        });
    }

    private selectGood(sender: any, e: ComboBoxChangedEventArgs<IGood>): void {
        if (e.item) {
            this.showGetPanel(e.item);
        } else {
            this.showCreatePanel();
        }
    }

    private showCreatePanel(): void {
        this.tbQuantity.removeTextChangedHandler(this.changeQuantityBinded);
        this.tbCost.removeTextChangedHandler(this.changeCostBinded);
        this.tbPrice.removeTextChangedHandler(this.changePriceBinded);

        this.pnlCreateInfo.visible = true;
        this.pnlGetInfo.visible = false;

        this.tbCost.text = "";
        this.tbCost.class = "";
        this.tbPrice.text = "";
        this.tbPrice.class = "";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = "required|numeric|unsigned";
        this.tbQuantity.text = "";
    }

    private showGetPanel(good: IGood): void {
        this.tbQuantity.addTextChangedHandler(this.changeQuantityBinded);
        this.tbCost.addTextChangedHandler(this.changeCostBinded);
        this.tbPrice.addTextChangedHandler(this.changePriceBinded);

        this.pnlCreateInfo.visible = false;
        this.pnlGetInfo.visible = true;

        const locale = good.storeRef?.info?.locale ?? Locale.RU;
        const cost = good.info.cost ?? 0.0;
        const price = good.info.price ?? 0.0;
        const quantity = good.info.quantity ?? 0;

        this.tbCost.text = Localizer.money(cost, locale);
        this.tbCost.class = "mb-0.75";
        this.tbPrice.text = Localizer.money(price, locale);
        this.tbPrice.class = "mb-0.75";
        this.tbQuantity.label = `Количество (макс: ${quantity})`;
        this.tbQuantity.validation = "required|numeric|unsigned|min_value:1";
        this.tbQuantity.text = "1";
    }

    private getAllowedCategories(): IGoodCategory[] {
        if (!this.context || !this.categories) {
            return [];
        }

        const storeId = this.context.selectedStore.id;

        return this.categories.filter(
            c => (c.stores as IStore[]).some(store => store.id === storeId) || !c.stores.length,
        );
    }

    private getGoodStore(good: IGood): IStore | undefined {
        return typeof good.store === "string" ? this.context?.stores.find(s => s.id === good.store) : good.store;
    }

    private goodDescriptionField(good: IGood): string {
        const store = this.getGoodStore(good);
        if (!store) {
            return `${good.info.quantity} шт.`;
        }

        return `${store.info.name} (${good.info.quantity} шт.)`;
    }

    private async changeQuantity(sender: any, e: TextChangedEventArgs): Promise<void> {
        let origQuantity = this.cbGoodSearch.selectedItem?.info.quantity;
        if (origQuantity === undefined) {
            origQuantity = 0;
        }

        let newQuantity = parseInt(e.text);
        if (isNaN(newQuantity)) {
            newQuantity = 0;
        }

        this.tbQuantity.label = `Количество (на складе: ${origQuantity})`;
        this.lbQuantity.text = `Будет на складе: ${origQuantity + newQuantity}`;
    }

    private async changeCost(sender: any, e: TextChangedEventArgs): Promise<void> {
        const cost = this.cbGoodSearch.selectedItem?.info.cost;
        if (!cost) {
            return;
        }

        if (cost === parseFloat(e.text)) {
            this.tbCost.label = "Себестоимость";
            return;
        }

        this.tbCost.label = `Себестоимость (исходная: ${cost})`;
    }

    private async changePrice(sender: any, e: TextChangedEventArgs): Promise<void> {
        const price = this.cbGoodSearch.selectedItem?.info.price;
        if (!price) {
            return;
        }

        if (price === parseFloat(e.text)) {
            this.tbPrice.label = "Цена продажи";
            return;
        }

        this.tbPrice.label = `Цена продажи (исходная: ${price})`;
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = await this.getSaveResult();
        if (result) {
            this.hide(result);
        }
    }

    private async getSaveResult(): Promise<boolean> {
        if (this.context?.good) {
            return await this.updateGood(this.context.good);
        }

        if (this.cbGoodSearch.selectedItem) {
            return await this.getGood();
        }

        return await this.createGood();
    }

    private async getGood(): Promise<boolean> {
        if (!this.onAdd || !this.context || !this.cbGoodSearch.selectedItem) {
            return false;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const cost = parseFloat(costStr);

        const priceStr = this.tbPrice.text.replace(",", ".");
        const price = parseFloat(priceStr);

        const quantity = parseInt(this.tbQuantity.text);

        const dto: IGoodCreateDto = {
            store: this.context.selectedStore.id,
            good: this.cbGoodSearch.selectedItem.id,
            info: {
                ...this.cbGoodSearch.selectedItem.info,
                cost,
                price,
                quantity,
                // warranty: this.wbWarranty.value,
            },
        };

        return await this.onAdd(dto);
    }

    private async createGood(): Promise<boolean> {
        if (!this.onCreate || !this.context) {
            return false;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const cost = parseFloat(costStr);

        const priceStr = this.tbPrice.text.replace(",", ".");
        const price = parseFloat(priceStr);

        const dto: IGoodCreateDto = {
            store: this.context.selectedStore.id,
            info: {
                sku: this.tbSku.text,
                name: this.tbName.text,
                description: this.taDescription.text,
                categories: this.msCategory.selectedItems.map(e => e.id),
                barcodes: this.bcBarcode.barcodes,
                cost: cost,
                price: price,
                quantity: parseInt(this.tbQuantity.text),
                warranty: this.wcWarranty.value,
            },
        };

        return await this.onCreate(dto);
    }

    private async updateGood(good: IGood): Promise<boolean> {
        if (!this.onUpdate) {
            return false;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const cost = parseFloat(costStr);

        const priceStr = this.tbPrice.text.replace(",", ".");
        const price = parseFloat(priceStr);

        const dto: IGoodUpdateDto = {
            info: {
                sku: this.tbSku.text,
                name: this.tbName.text,
                description: this.taDescription.text,
                categories: this.msCategory.selectedItems.map(e => e.id),
                barcodes: this.bcBarcode.barcodes,
                cost: cost,
                price: price,
                quantity: parseInt(this.tbQuantity.text),
                warranty: this.wcWarranty.value,
            },
        };

        return await this.onUpdate(good, dto);
    }
}
