

































import { Vue, Component, Prop } from "vue-property-decorator";
import { OrderClientSearchControl } from "./order-client-search-control";
import { Select } from "@/@core/components/alt-ui/controls";
import { CheckBoxComponent } from "@core/components/alt-ui/controls/check-box.component";
import { BButton } from "bootstrap-vue";
import { IClient } from "@lib";

@Component({
    components: {
        BButton,
        CheckBoxComponent,
        ControlComponent: () => import("../../components/alt-ui/controls/control.component"),
    },
})
export default class OrderClientSearchComponent extends Vue {
    @Prop({ type: Object })
    private handler!: OrderClientSearchControl;

    public handleClickSelect() {
        this.handler.client = this.handler.cbClient?.selectedItem || null;
        this.handler.notifyChangedHandler();
    }

    public handleClickDeselect() {
        this.handler.client = null;
        this.handler.notifyChangedHandler();
    }
}
