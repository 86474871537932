import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import VueSelect from "vue-select";
import { IOrder, IOrderStage } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { TextArea } from "@core/components/alt-ui/controls";

@Component({
    name: "order-view-stage",
    components: {
        BButton,
        VueSelect,
        ControlComponent,
    },
})
export default class OrderViewStage extends Vue {
    @Prop({ required: true })
    private order!: IOrder | null;

    @Prop({ type: Array, required: true })
    private stages!: IOrderStage[];

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private stage: IOrderStage | { id: "" } = { id: "" };
    private taComment = new TextArea();

    private get isChanged(): boolean {
        if (this.order && this.order.stage) {
            return this.order.stage !== this.stage.id;
        }
        return false;
    }

    @Watch("order", { immediate: true, deep: true })
    private onOrderChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        if (this.order && this.order.stage) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const stage = this.stages.find(st => st.id === this.order!.stage);
            this.stage = stage ?? { id: "" };
        } else {
            this.stage = this.stages.length > 0 ? this.stages[0] : { id: "" };
        }

        this.taComment = new TextArea();
        this.taComment.id = "order-view-stage.comment";
        this.taComment.placeholder = "Комментарий к изменению этапа";
    }

    private async applyChangeStage(): Promise<void> {
        try {
            if (this.stage.id === "") {
                throw new Error("Этап не задан.");
            }

            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            const companyId = this.order.company as string;
            const orderId = this.order.id;
            const stageId = this.stage.id;
            const comment = this.taComment.text;

            const usecase = this.$alt.system.usecase.createOrderUseCase();
            const order = await usecase.changeStage(companyId, orderId, stageId, comment);

            this.$emit("order-changed", order);
        } catch {
            this.$alt.toast.error("Не удалось изменить этап.");
        }
    }

    private cancelChangeStage(): void {
        this.initValues();
    }
}
