import { Vue, Component } from "vue-property-decorator";
import { BOverlay } from "bootstrap-vue";

@Component({
    components: { BOverlay },
})
export default class FullPage extends Vue {
    private loader: boolean = false;

    public created(): void {
        this.$alt.loader.onChanged = this.changeLoader;
    }

    private changeLoader(value: boolean): void {
        this.loader = value;
    }
}
