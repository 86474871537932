import { IGoodCategory, IGoodCategoryCreateDto, IGoodCategoryUpdateDto, IStore } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, ColorBox, Control, MultiSelect, Panel, Select, TextBox } from "@core/components/alt-ui/controls";

export interface ICategoriesModalContext {
    stores: IStore[];
    category?: IGoodCategory;
}

export class CategoriesModal extends Modal<ICategoriesModalContext> {
    private cbStore!: MultiSelect<IStore>;
    private tbSequence!: TextBox;
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private clColor!: ColorBox;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: ICategoriesModalContext | null = null;
    private category: IGoodCategory | null = null;

    public onCreate: ((dto: IGoodCategoryCreateDto) => Promise<IGoodCategory | null>) | null = null;
    public onUpdate:
        | ((productType: IGoodCategory, dto: IGoodCategoryUpdateDto) => Promise<IGoodCategory | null>)
        | null = null;

    public constructor() {
        super("categories-modal", "");
        this.initializeControls();
    }

    public show(context: ICategoriesModalContext): Promise<void> {
        this.context = context;
        this.category = this.context.category ?? null;
        this.title = this.category ? "Изменение категории" : "Новая категория";
        this.initializeControls();

        if (this.category) {
            this.populateControls(this.category);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbSequence = new TextBox();
        this.cbStore = new MultiSelect<IStore>();
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.clColor = new ColorBox();

        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbSequence.id = "category.sequence";
        this.tbSequence.label = "Порядок";
        this.tbSequence.validation = "required|numeric|min_value:1|max_value:999";
        this.tbSequence.visible = false;

        this.cbStore.id = "good.store";
        this.cbStore.label = "Склад";
        this.cbStore.help = "Для каких складов категория доступна. Если пусто - для всех";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = ac => ac.info.name;
        this.cbStore.descriptionField = ac => ac.info.description;
        this.cbStore.selectedItems = [];

        this.tbName.id = "category.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.tbDescription.id = "category.description";
        this.tbDescription.label = "Описание";

        this.clColor.id = "category.color";
        this.clColor.label = "Цвет";
        this.clColor.value = "#82868b";

        //

        this.btnCancel.id = "category.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "category.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(category: IGoodCategory): void {
        this.cbStore.selectedItems =
            this.context?.stores.filter(e => (category.stores as IStore[]).map(e => e.id).includes(e.id)) ?? [];

        this.tbSequence.text = category.sequence.toString();
        this.tbSequence.visible = true;

        this.tbName.text = category.name;

        this.tbDescription.text = category.description ?? "";

        this.clColor.value = category.color;
    }

    public get controls(): Control[] {
        return [this.tbSequence, this.cbStore, this.tbName, this.tbDescription, this.clColor, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const result = this.category ? await this.update(this.category) : await this.create();

        if (result) {
            this.hide(result);
        }
    }

    private async create(): Promise<IGoodCategory | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: IGoodCategoryCreateDto = {
            stores: this.cbStore.selectedItems.map(e => e.id),
            name: this.tbName.text,
            description: this.tbDescription.text,
            color: this.clColor.value,
        };

        return await this.onCreate(dto);
    }

    private async update(productType: IGoodCategory): Promise<IGoodCategory | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IGoodCategoryUpdateDto = {
            stores: this.cbStore.selectedItems.map(e => e.id),
            sequence: parseInt(this.tbSequence.text),
            name: this.tbName.text,
            description: this.tbDescription.text,
            color: this.clColor.value,
        };

        return await this.onUpdate(productType, dto);
    }
}
