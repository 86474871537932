import { Modal } from "@core/components/alt-ui/modal";
import { Button, Collapse, Control, FileUpload, Html } from "@core/components/alt-ui/controls";

export type ClientsImportContextOut = { file?: any };

export class ClientsImportModal extends Modal {
    private fuFile!: FileUpload;
    private htmlHelp!: Html;
    private clHelp!: Collapse;
    private btnImport!: Button;

    public onImport: ((context: ClientsImportContextOut) => Promise<boolean>) | null = null;

    public constructor() {
        super("modal-clients-import", "Импорт клиентов");
        this.initializeControls();
    }

    public show(): Promise<void> {
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        this.fuFile = new FileUpload();
        this.htmlHelp = new Html();
        this.clHelp = new Collapse();
        this.btnImport = new Button();

        //

        this.fuFile.id = "clients.import.file";
        this.fuFile.label = "Укажите CSV-файл:";
        this.fuFile.placeholder = "Выберите или перетащите файл";
        this.fuFile.accept = ".csv";

        const linkExample = require("@/assets/documents/import-clients.csv");
        this.htmlHelp.html = `<small>Для импорта принимаются файлы формата CSV:<br/>
            <br/>
            - Информация по каждому клиенту на новой строке.<br/>
            - Первая строка - заголовки столбцов.<br/>
            - Кодировка файла - UTF-8.<br/>
            - Файл не должен превышать 5 МБ.<br/>
            <br/>
            <a href="${linkExample}" download="import-clients.csv" class="hover:underline">Скачать пример CSV-файла</a></small>`;

        this.clHelp.id = "clients.import.help";
        this.clHelp.title = "Справка";
        this.clHelp.opened = true;
        this.clHelp.addControl(this.htmlHelp);

        this.btnImport.id = "clients.import.import";
        this.btnImport.text = "Импорт";
        this.btnImport.class = "mt-0.25";
        this.btnImport.addClickHandler(this.clickBtnImport.bind(this));
    }

    public get controls(): Control[] {
        return [this.fuFile, this.clHelp, this.btnImport];
    }

    private async clickBtnImport(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = await this.import();
        if (result) {
            this.hide(result);
        }
    }

    private async import(): Promise<boolean> {
        const fileControl = document.getElementById("clients.import.file") as any;
        const context: ClientsImportContextOut = {
            file: fileControl?.files?.length ? fileControl.files[0] : undefined,
        };

        if (!this.onImport) {
            return false;
        }

        return await this.onImport(context);
    }
}
