import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Html } from "./html";

@Component({
    name: "html-component",
})
export class HtmlComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Html;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div
                    id={this.handler.id}
                    class={this.handler.class}
                    style={this.handler.style}
                    domPropsInnerHTML={this.handler.html}
                />
            );
        } else {
            return <div></div>;
        }
    }
}
