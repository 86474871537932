import { IStore, IGood, IGoodCategory } from "@lib";
import { Localizer } from "@/i18n/localizer";

export function getDefaultTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: false,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: (good: IGood) => context.can.update(good),
                            click: (good: IGood) => context.openModalUpdate(good),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: (good: IGood) => context.can.delete(good),
                            click: (good: IGood) => context.confirmDelete(good),
                        },
                    ],
                };
            },
        },
        // {
        //     colId: "checkboxes",
        //     headerName: "#",
        //     width: 50,
        //     hide: false,
        //     filter: false,
        //     checkboxSelection: true,
        //     headerCheckboxSelectionFilteredOnly: true,
        //     headerCheckboxSelection: true
        // },
        {
            colId: "store",
            field: "info.store",
            headerName: "Склад",
            sortable: true,
            filter: true,
            width: 175,
            hide: true,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                return {
                    name: params.data.storeRef?.info.name,
                };
            },
        },
        {
            colId: "categories",
            field: "info.categories",
            headerName: "Категории",
            sortable: false,
            filter: true,
            width: 175,
            hide: false,

            cellRendererFramework: "CellRendererChips",
            cellRendererParams: (params: { value: string[] }) => {
                if (!params.value.length) {
                    return {
                        chips: [
                            {
                                text: "Без категории",
                                color: "#82868b",
                            },
                        ],
                    };
                }

                return {
                    chips: params.value
                        .map(e => {
                            const category = context.categoryHashMap[e] ?? null;

                            if (!category) {
                                return null;
                            }

                            return {
                                text: category.name,
                                color: category.color || "#82868b",
                            };
                        })
                        .filter(e => e),
                };
            },
        },
        {
            colId: "sku",
            field: "info.sku",
            headerName: "Артикул",
            sortable: true,
            filter: true,
            width: 150,
            hide: false,
        },
        {
            colId: "name",
            field: "info.name",
            headerName: "Наименование",
            sortable: true,
            filter: true,
            width: 500,
            hide: false,
        },
        {
            colId: "description",
            field: "info.description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 500,
            hide: true,
        },
        {
            colId: "cost",
            field: "info.cost",
            headerName: "Себестоимость",
            sortable: true,
            filter: true,
            width: 185,
            hide: false,

            cellRenderer: function (params: any) {
                const currency = params.data?.storeRef?.info?.currency;
                const locale = params.data?.storeRef?.info?.locale;
                return Localizer.currency(params.value, currency, locale);
            },
        },
        {
            colId: "price",
            field: "info.price",
            headerName: "Цена продажи",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,

            cellRenderer: function (params: any) {
                const currency = params.data?.storeRef?.info?.currency;
                const locale = params.data?.storeRef?.info?.locale;
                return Localizer.currency(params.value, currency, locale);
            },
        },
        {
            colId: "quantity",
            field: "info.quantity",
            headerName: "Количество",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,
        },
        {
            colId: "tags",
            field: "tags",
            headerName: "Метки",
            filter: true,
            width: 150,
            hide: false,

            cellRendererFramework: "CellRendererTags",
            cellRendererParams: (params: any) => {
                const tags = params.value;
                if (!tags) {
                    return;
                }

                return {
                    tags,
                    handler: (t: string) => context.table.api.search(t),
                };
            },
        },
    ];
}

//

export function getDefaultTableActions(context: any): any[] {
    const actions: any[] = [];

    for (const template of context.labelTemplates) {
        actions.push({
            name: `Печатать ${template.name}`,
            icon: "PrinterIcon",
            click: (goods: IGood[]) => context.openModalPrint(template, goods),
        });
    }

    if (context.labelTemplates.length > 0) {
        actions.push({
            type: "divider",
        });
    }

    if (context.stores.length > 1) {
        actions.push(
            {
                name: "Переместить",
                icon: "ShareIcon",
                click: (goods: IGood[]) => context.openModalMoveMany(goods),
            },
            {
                type: "divider",
            },
        );
    }

    actions.push({
        name: "Удалить всё",
        icon: "Trash2Icon",
        click: (goods: IGood[]) => context.confirmDeleteMany(goods),
    });

    return actions;
}
