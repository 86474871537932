import { Control } from "./control";
import { Icon } from "./icon";
import { EventHandler } from "..";

export type ListFieldReceiver<T> = (item: T, index: number) => string | Control | null;
export type ListFieldArrayReceiver<T> = (item: T, index: number) => Control[];
export type ListStringReceiver<T> = (item: T, index: number) => string;

export class ListItemClickEventArgs<T = any> {
    item!: T | null;
    index!: number;
}

export class List<T = any> extends Control {
    public items: T[] = [];

    public left: ListFieldReceiver<T> | null = null;
    public right: ListFieldReceiver<T> | null = null;
    public header: ListFieldReceiver<T> | null = null;
    public tools: ListFieldArrayReceiver<T> | null = null;
    public content: ListFieldReceiver<T> | null = null;
    public footer: ListFieldReceiver<T> | null = null;
    public footerRight: ListFieldReceiver<T> | null = null;

    public classItem: ListStringReceiver<T> | null = null;
    public styleItem: ListStringReceiver<T> | null = null;

    public classLeft: ListStringReceiver<T> | null = null;
    public styleLeft: ListStringReceiver<T> | null = null;

    public classRight: ListStringReceiver<T> | null = null;
    public styleRight: ListStringReceiver<T> | null = null;

    public classHeader: ListStringReceiver<T> | null = null;
    public styleHeader: ListStringReceiver<T> | null = null;

    public classContent: ListStringReceiver<T> | null = null;
    public styleContent: ListStringReceiver<T> | null = null;

    public getComponentName(): string {
        return "ListComponent";
    }

    public clickItem(item: T): void {
        const index = this.items.findIndex(i => i === item);
        if (index >= 0) {
            this.notifyClickHandlers(item, index);
        }
    }

    private _ckickHandlers = new Set<EventHandler<ListItemClickEventArgs<T>>>();
    public addClickHandler(handler: EventHandler<ListItemClickEventArgs<T>>): void {
        this._ckickHandlers.add(handler);
    }
    public removeClickHandler(handler: EventHandler<ListItemClickEventArgs<T>>): void {
        this._ckickHandlers.delete(handler);
    }
    private notifyClickHandlers(item: T, index: number): void {
        const args: ListItemClickEventArgs<T> = { item, index };
        for (const handler of this._ckickHandlers) {
            handler(this, args);
        }
    }
}
