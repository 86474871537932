/** Тип гарантии. */
export enum WarrantyType {
    /** Месяцы. */
    Month = "months",

    /** Недели. */
    Week = "weeks",

    /** Дни. */
    Day = "days",

    /** Годы. */
    Year = "years",

    /** Бесконечная. */
    Inf = "inf",

    /** Другое. */
    Other = "other",
}
