import { IShop, ISale, ISalary, DiscountType, Locale } from "@lib";
import { ISalaryTableItem, SalaryTableItemType, TableSalaryConverterResult } from ".";
import { moneyFormat } from "@/filters/money";

export default class SalaryTableSaleConverter {
    private sales: ISale[];
    private userId: string;
    private percentSale: number;

    public constructor(sales: ISale[], userId: string, percentSale: number) {
        this.sales = sales;
        this.userId = userId;
        this.percentSale = percentSale;
    }

    public convert(): ISalaryTableItem[] {
        const items: ISalaryTableItem[] = [];
        for (const sale of this.sales) {
            const salaryLink = this.getSalarySale(sale);
            const paid = salaryLink ? salaryLink.paid : false;

            const item: ISalaryTableItem = {
                type: SalaryTableItemType.Sale,
                number: salaryLink?.number,
                name: `Продажа №${sale.number}`,
                profit: 0,
                amount: 0,
                meta: {
                    sale: sale,
                    employee: this.userId,
                    salary: salaryLink,
                    locale: sale.shopRef?.info.locale ?? Locale.RU,
                    percentSale: this.percentSale,
                },
                paid: paid,
                _rowVariant: paid ? "success" : undefined,
            };
            items.push(item);

            let saleProfit = 0;
            let saleAmount = 0;

            const result = this.convertGoods(sale, item);
            items.push(...result.items);
            saleProfit += result.sumProfit;
            saleAmount += result.sumAmount;

            item.profit = saleProfit;
            item.amount = saleAmount;
        }
        return items;
    }

    private convertGoods(sale: ISale, parent: ISalaryTableItem): TableSalaryConverterResult {
        let sumProfit = 0;
        let sumAmount = 0;
        const locale = sale.shopRef?.info.locale ?? Locale.RU;
        const items: ISalaryTableItem[] = [];

        for (const good of sale.goods) {
            const expenses = good.cost * good.quantity;
            let incomes = good.price * good.quantity;
            let incomesHelp = `= ${moneyFormat(good.price, { locale })} * ${good.quantity}`;
            let discountValue = 0;
            let discountStr = "";
            let discountHelp: string | undefined;

            if (sale.info?.discount) {
                if (sale.info.discount.type === DiscountType.Percent) {
                    discountValue = (sale.info.discount.value * incomes) / 100;
                    discountStr = `${sale.info.discount.value}%`;
                    discountHelp = `= ${moneyFormat(discountValue, { locale })}`;
                    incomesHelp += ` - ${discountStr}`;
                } else if (sale.info.discount.type === DiscountType.Fixed) {
                    discountValue = sale.info.discount.value;
                    discountStr += moneyFormat(sale.info.discount.value, { locale });
                    incomesHelp += ` - ${discountStr}`;
                }
            }

            incomes = incomes - discountValue;
            const profit = incomes - expenses;
            const amount = (profit * this.percentSale) / 100;
            const amountHelp = `= ${moneyFormat(profit, { locale })} * ${this.percentSale}%`;
            sumProfit += profit;
            sumAmount += amount;

            items.push({
                type: SalaryTableItemType.Good,
                name: good.name,
                quantity: good.quantity,
                expenses: expenses,
                expensesHelp: `= ${moneyFormat(good.cost, { locale })} * ${good.quantity}`,
                incomes: incomes,
                incomesHelp: incomesHelp,
                discount: discountStr,
                discountHelp: discountHelp,
                profit: profit,
                amount: amount,
                amountHelp: amountHelp,
                meta: {
                    sale: sale,
                    good: good,
                    employee: this.userId,
                    locale: locale,
                    percentMaterial: this.percentSale,
                },
                parent: parent,
                paid: false,
                _rowVariant: parent._rowVariant,
            });
        }
        return { items, sumProfit, sumAmount };
    }

    private getSalarySale(sale: ISale): ISalary | undefined {
        if (!(sale as any).salaries) {
            return undefined;
        }
        for (const salary of (sale as any).salaries as ISalary[]) {
            for (const subject of salary.subject) {
                if (subject.sale) {
                    return salary;
                }
            }
        }
        return undefined;
    }

    public static convert(sales: ISale[], userId: string, percentSale: number): ISalaryTableItem[] {
        return new SalaryTableSaleConverter(sales, userId, percentSale).convert();
    }
}
