/** Тарифный план. */
export enum CompanyPricingPlan {
    /** Первые 10 заявок/продаж бесплатно. */
    Free10 = "free10",

    /** Есть доступ, пока баланс положительный. */
    Standart = "standart",

    /** Для тех, кто перешёл с SC24. */
    SC24 = "sc24",
}
