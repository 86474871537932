import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BSidebar, BButton, VBTooltip } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ValidationObserver } from "vee-validate";
import { IOrder, IProductType, IEmployee, IDocument, IUser, ICompany, IOrderProductChangeDto } from "@lib";
import { IOffice, IAccount, IStore, IClient, IClientSource } from "@lib";
import { IOrderStage, IOrderType, IOrderCreateDto } from "@lib";
import VaCheckboxSelect from "@/components/common/va-checkbox-select";
import { Printer } from "@/@core/usecases/template/printer";
import { OrderPrintContext } from "@core/usecases/template/macro-replacers/order-document.macro-replacer";
import OrderCreateHeader from "./order-create-header.vue";
import OrderCreateInfo from "./order-create-info.vue";
import OrderCreateClient from "./order-create-client.vue";
import OrderCreateProduct from "./order-create-product.vue";
import OrderCreatePayment from "./order-create-payment.vue";
import OrderCreateController from "./orderCreateController";
import { OrderBlockClientHandler } from "./order-block-client-handler";
import { OrderBlockProductHandler } from "./order-block-product-handler";
import { OrderBlockInfoHandler } from "./order-block-info-handler";
import { OrderBlockPaymentHandler } from "./order-block-payment-handler";
import { StringUtils } from "@/utils/string.utils";
import { AppException } from "@/exceptions";

@Component({
    components: {
        BSidebar,
        BButton,
        ValidationObserver,
        VuePerfectScrollbar,
        OrderCreateHeader,
        OrderCreateInfo,
        OrderCreateClient,
        OrderCreateProduct,
        OrderCreatePayment,
        VaCheckboxSelect,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class OrderCreateForm extends Vue {
    @Prop({ type: Object, required: true })
    private value!: OrderCreateController;

    @Prop({ type: Array, default: () => [] })
    private offices!: IOffice[];

    @Prop({ type: Object, default: () => null })
    private office!: IOffice | null;

    @Prop({ type: Array, default: () => [] })
    private stores!: IStore[];

    @Prop({ type: Array, default: () => [] })
    private accounts!: IAccount[];

    @Prop({ type: Array, default: () => [] })
    private documents!: IDocument[];

    @Prop({ type: Array, default: () => [] })
    private employees!: IEmployee[];

    @Prop({ type: Array, required: true })
    private orderTypes!: IOrderType[];

    @Prop({ type: Array, required: true })
    private orderStages!: IOrderStage[];

    @Prop({ type: Array, required: true })
    private clientSources!: IClientSource[];

    @Prop({ type: Array, required: true })
    private productTypes!: IProductType[];

    @Prop({ type: Boolean, default: false })
    private canEditOrderType!: boolean;

    private controller = this.value;

    private user!: IUser;
    private company!: ICompany;
    private ClientUseCase = this.$alt.system.usecase.createClientUseCase();

    private orderHeader = {
        office: null as IOffice | null,
        type: null as IOrderType | null,
        stage: null as IOrderStage | null,
        manager: undefined as string | undefined,
    };

    private orderBlockClientHandler = new OrderBlockClientHandler();
    private orderBlockProductHandler = new OrderBlockProductHandler();
    private orderBlockInfoHandler = new OrderBlockInfoHandler();
    private orderBlockPaymentHandler = new OrderBlockPaymentHandler();

    private documentsSelected: IDocument[] = [];

    private orderType: IOrderType | null = null;

    private get printText(): string {
        if (this.documentsSelected.length === 0) {
            return "не печатать документы";
        }

        const form = StringUtils.decline(this.documentsSelected.length, ["документ", "документа", "документов"]);
        return `напечать ${this.documentsSelected.length} ${form}`;
    }

    public created(): void {
        this.orderBlockClientHandler.searchClients = this.searchClients.bind(this);
    }

    public async mounted(): Promise<void> {
        await this.initValues();
    }

    @Watch("controller.show")
    private onDataChanged(): void {
        this.initValues();
        (this.$refs["formValidator"] as any).reset();
    }

    private async initValues(): Promise<void> {
        this.user = await this.$info.getUser();
        this.company = await this.$info.getCompany();

        const documents = await this.$settings.getDocumentsOrderCreate();
        this.documentsSelected = this.documents.filter(d => documents.includes(d.id));

        this.orderBlockClientHandler.clean();
        this.orderBlockProductHandler.clean();
        this.orderBlockInfoHandler.clean();
        this.orderBlockPaymentHandler.clean();
    }

    private async save(): Promise<void> {
        const valid = await (this.$refs["formValidator"] as any).validate();
        if (!valid) {
            return;
        }

        const productDto = this.orderBlockProductHandler.getDto();
        const orderDto = this.getOrderDto(productDto);
        const clientDto = this.orderBlockClientHandler.getDto();
        const paymentDto = this.orderBlockPaymentHandler.getDto();

        const order = await this.controller.save(orderDto, clientDto, paymentDto);
        if (order) {
            this.print(order);
            this.controller.close();
            this.initValues();
        }
    }

    private getOrderDto(productDto: IOrderProductChangeDto): IOrderCreateDto {
        if (!this.orderHeader.office) {
            throw new AppException("Филиал не выбран.");
        }

        if (!this.orderHeader.type) {
            throw new AppException("Тип заявки не выбран.");
        }

        if (!this.orderHeader.stage) {
            throw new AppException("Этап не выбран.");
        }

        if (!this.orderHeader.manager) {
            throw new AppException("Ответственный не выбран.");
        }

        const dto = this.orderBlockInfoHandler.getDto();
        const clientExisted = this.orderBlockClientHandler.getExistedClient();

        const officeId = this.orderHeader.office.id;
        const orderDto: IOrderCreateDto = {
            office: officeId,
            info: dto.info,
            custom: dto.custom,
            type: this.orderHeader.type.id,
            stage: this.orderHeader.stage.id,
            client: clientExisted?.id,
            manager: this.orderHeader.manager,
            products: [productDto],
        };

        return orderDto;
    }

    private cancel(): void {
        this.controller.close();
        this.initValues();
    }

    private async searchClients(search: string): Promise<IClient[]> {
        try {
            const result = await this.ClientUseCase.select(this.company.id, { search });
            return result.data;
        } catch {
            return [];
        }
    }

    private changeOffice(office: IOffice): void {
        this.orderHeader.office = office;
    }

    private changeOrderType(type: IOrderType): void {
        this.orderHeader.type = type;
        this.orderType = type;
    }

    private changeOrderStage(stage: IOrderStage): void {
        this.orderHeader.stage = stage;
    }

    private changeManager(manager: IEmployee): void {
        this.orderHeader.manager = manager?.user as string;
    }

    private async changeDocuments(): Promise<void> {
        try {
            const value = this.documentsSelected.map(d => d.id);
            await this.$settings.setDocumentsOrderCreate(value);
        } catch {}
    }

    private print(order: IOrder): void {
        const orderType = this.orderTypes.find(t => t.id === order.type);

        if (!orderType) {
            return;
        }

        const context: OrderPrintContext = {
            company: this.company,
            office: this.orderHeader.office as IOffice,
            order: order,
            orderType: orderType,
            productTypes: this.productTypes,
        };

        Printer.printOrderDocuments(this.documentsSelected, context);
    }

    private relocateToOrderType(): void {
        this.$router.push({ name: "settings-lists", query: { id: this.orderType?.id } }).catch(() => {});
    }
}
