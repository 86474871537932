import { Vue, Component } from "vue-property-decorator";

@Component
export default class SettingsHelp extends Vue {
    private readonly DocsUrl = process.env.VUE_APP_DOCS_BASEURL;

    private get version(): string {
        return process.env.APP_VERSION;
    }
}
