





















import { Vue, Component, Prop } from "vue-property-decorator";
import { FieldType, IOrderTypeField } from "@lib";
import { getFieldTypeText } from "@core/types/fields/field";

@Component
export default class OrderTypeGroupField extends Vue {
    @Prop({ required: true })
    private field!: IOrderTypeField;

    public getFieldTypeText(type: FieldType): string {
        return getFieldTypeText(type);
    }
}
