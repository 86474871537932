// import { Vue } from "vue-property-decorator";
// import { VNode } from "vue";
// import ControlComponent from "./control.component";

import { EventHandler, VisibilityChangedEventArgs } from "..";

// export abstract class IControlComponent extends Vue {
//     public static draw(handler: Control): VNode {
//         return Vue.prototype.$createElement("div");
//     }
// }

export abstract class Control {
    public id: string | null = null;
    public class: string = "";
    public style: string = "";

    public abstract getComponentName(): string;

    protected _disabled = false;
    public get disabled(): boolean {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this._disabled = value;
    }

    protected _visible = true;
    public get visible(): boolean {
        return this._visible;
    }
    public set visible(value: boolean) {
        this._visible = value;
        this.notifyVisibleChangedHandlers();
    }

    // public get component(): typeof IControlComponent {
    //     return ControlComponent;
    // }

    // public render(): VNode {
    //     return this.component.draw(this);
    //     return Vue.prototype.$createElement("div");
    // }

    private _visibleChangedHandlers = new Set<EventHandler<VisibilityChangedEventArgs>>();
    public addVisibleChangedHandler(handler: EventHandler<VisibilityChangedEventArgs>): void {
        this._visibleChangedHandlers.add(handler);
    }
    public removeVisibleChangedHandler(handler: EventHandler<VisibilityChangedEventArgs>): void {
        this._visibleChangedHandlers.delete(handler);
    }
    private notifyVisibleChangedHandlers(): void {
        const args: VisibilityChangedEventArgs = { visible: this._visible };
        for (const handler of this._visibleChangedHandlers) {
            handler(this, args);
        }
    }
}
