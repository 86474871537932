import { IOrder, IOrderCreateDto, IClientCreateDto, IOrderCreatePaymentDto } from "@lib";

export default class OrderCreateController {
    public show: boolean;
    public data: any;

    private create: Function;
    private clients: Function;

    public onOpening: Function | null;
    public onClosed: Function | null;

    public constructor(createFunc: Function, clientsFunc: Function) {
        this.show = false;
        this.data = {};

        this.create = createFunc;
        this.clients = clientsFunc;

        this.onOpening = null;
        this.onClosed = null;
    }

    public async openCreate(order: IOrder | {} = {}): Promise<void> {
        if (this.onOpening) {
            await this.onOpening();
        }

        this.data = order;
        this.show = true;
    }

    public async openUpdate(order: IOrder): Promise<void> {
        if (this.onOpening) {
            await this.onOpening();
        }

        this.data = order;
        this.show = true;
    }

    public async close(): Promise<void> {
        this.show = false;

        if (this.onClosed) {
            await this.onClosed();
        }
    }

    public async save(
        order: IOrderCreateDto,
        client?: IClientCreateDto,
        payment?: IOrderCreatePaymentDto,
    ): Promise<IOrder | undefined> {
        return await this.create(order, client, payment);
    }

    public async searchClients(search: string): Promise<any[]> {
        return await this.clients(search);
    }
}
