import { Control } from "./control";
import { EventHandler } from "..";

type TextFieldReceiver<T> = (item: T) => any;
type DisabledFieldReceiver<T> = (item: T) => boolean;

export class CheckBoxGroupChangedEventArgs<T = any> {
    items!: T[];
}

export class CheckBoxGroup<T = any> extends Control {
    public items: T[] = [];
    public label = "";

    public textField: TextFieldReceiver<T> = (item: T) => item;
    public disabledField: DisabledFieldReceiver<T> = (item: T) => false;

    public getComponentName(): string {
        return "CheckBoxGroupComponent";
    }

    private _selectedItems: T[] = [];
    public get selectedItems(): T[] {
        return this._selectedItems;
    }
    public set selectedItems(items: T[]) {
        if (items === this._selectedItems) {
            return;
        }

        this._selectedItems = items;
        this.notifyChangedHandlers();
    }

    private _changedHandlers = new Set<EventHandler<CheckBoxGroupChangedEventArgs<T>>>();
    public addChangedHandler(handler: EventHandler<CheckBoxGroupChangedEventArgs<T>>): void {
        this._changedHandlers.add(handler);
    }
    public removeChangedHandler(handler: EventHandler<CheckBoxGroupChangedEventArgs<T>>): void {
        this._changedHandlers.delete(handler);
    }
    private notifyChangedHandlers(): void {
        const args: CheckBoxGroupChangedEventArgs<T> = { items: this._selectedItems };
        for (const handler of this._changedHandlers) {
            handler(this, args);
        }
    }
}
