import { Control } from "../controls";

export class Panel extends Control {
    private _controls: Control[] = [];

    public get controls(): Control[] {
        return this._controls;
    }

    public getComponentName(): string {
        return "PanelComponent";
    }

    public clearControls(): void {
        this._controls = [];
    }

    public addControl(control: Control): void {
        this._controls.push(control);
    }

    public addControls(controls: Control[]): void {
        this._controls.push(...controls);
    }
}
