import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { BButton, BOverlay } from "bootstrap-vue";
import BackToTop from "vue-backtotop";
import HeaderNavbar from "@/layouts/components/header-navbar/index.vue";
//import Footer from "@/layouts/components/footer/index.vue";
import Sidebar from "@/layouts/components/sidebar/VerticalNavMenu.vue";
import { IMenuItem } from "../components/sidebar/menu-item";
import themeConfig from "@/../themeConfig";

@Component({
    components: {
        BButton,
        BOverlay,
        BackToTop,
        HeaderNavbar,
        //Footer,
        Sidebar,
    },
})
export default class Main extends Vue {
    @Prop({ type: Array, required: true })
    private menuItems!: IMenuItem[];

    private title = themeConfig.app.name;
    private routerTransition = "zoom-fade"; // zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none
    private routeTitle!: string;
    private loader: boolean = false;
    private classLayout = "main-vertical";
    private classHeaderNavbar = "navbar-sticky"; // navbar-hidden / navbar-sticky / navbar-static / navbar-floating
    private classFooter = "footer-hidden"; // footer-hidden / footer-sticky / footer-static

    private get noScroll(): boolean {
        return this.$route.meta?.noscroll;
    }

    @Watch("$route")
    private onRouteChanged(): void {
        this.routeTitle = this.$route.meta?.pageTitle ?? this.title;
    }

    public created(): void {
        this.$alt.loader.onChanged = this.changeLoader;
        this.routeTitle = this.$route.meta?.pageTitle ?? this.title;
    }

    private changeLoader(value: boolean): void {
        this.loader = value;
    }

    private changeRouteTitle(title: string): void {
        this.routeTitle = title;
    }
}
