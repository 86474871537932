export function capitalize(value: any): string {
    if (!value) {
        return "";
    }

    value = value.toString();
    const arr = value.split(" ");
    const capitalized_array: string[] = [];
    arr.forEach((word: string) => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
        capitalized_array.push(capitalized);
    });
    return capitalized_array.join(" ");
}

export function uppercase(value: any): string {
    return value.toString().toUpperCase();
}

export function title(value: any, replacer: string = "_"): string {
    if (!value) {
        return "";
    }
    value = value.toString();

    const arr = value.split(replacer);
    const capitalized_array: string[] = [];
    arr.forEach((word: string) => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
        capitalized_array.push(capitalized);
    });
    return capitalized_array.join(" ");
}

export function truncate(value: any, limit: number): string {
    return value.toString().substring(0, limit);
}

export function tailing(value: any, tail: string): string {
    return value.toString() + tail;
}

export function csv(value: string[]): string {
    return value.join(", ");
}

export function filterTags(value: string): string {
    return value.replace(/<\/?[^>]+(>|$)/g, "");
}
