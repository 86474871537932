import { IDocumentUseCase, IDocument, DocumentType } from "@lib";
import { IDocumentCreateDto, IDocumentCreateFromTemplateDto } from "@lib";
import { IDocumentUpdateDto, IDocumentUpdateTemplateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class DocumentUseCase implements IDocumentUseCase {
    public async select(companyId: string): Promise<IDocument[]> {
        try {
            const response = await Api.get<IDocument[]>(`/companies/${companyId}/templates/documents`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectByType(companyId: string, type: DocumentType): Promise<IDocument[]> {
        try {
            const params = { type };
            const response = await Api.get<IDocument[]>(`/companies/${companyId}/templates/documents`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IDocument> {
        try {
            const response = await Api.get<IDocument>(`/companies/${companyId}/templates/documents/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IDocumentCreateDto): Promise<IDocument> {
        try {
            const response = await Api.post<IDocument>(`/companies/${companyId}/templates/documents`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: IDocumentCreateDto[]): Promise<IDocument[]> {
        try {
            const response = await Api.post<IDocument[]>(`/companies/${companyId}/templates/documents/many`, dtos);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createFromTemplate(companyId: string, dto: IDocumentCreateFromTemplateDto): Promise<IDocument> {
        try {
            const response = await Api.post<IDocument>(`/companies/${companyId}/templates/documents/template`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createManyFromTemplate(
        companyId: string,
        dtos: IDocumentCreateFromTemplateDto[],
    ): Promise<IDocument[]> {
        try {
            const response = await Api.post<IDocument[]>(
                `/companies/${companyId}/templates/documents/many/template`,
                dtos,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IDocumentUpdateDto): Promise<IDocument> {
        try {
            const response = await Api.put<IDocument>(`/companies/${companyId}/templates/documents/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async updateTemplate(companyId: string, id: string, dto: IDocumentUpdateTemplateDto): Promise<IDocument> {
        try {
            const response = await Api.patch<IDocument>(
                `/companies/${companyId}/templates/documents/${id}/template`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/templates/documents/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
