import { SettingsUserUseCase } from "@core/usecases/settings/settings-user.usecase";

export enum SettingsUserKey {
    CommonSidebar = "common.sidebar",
    OrderCreateDocuments = "order-create.documents",
    OrderCompleteDocuments = "order-complete.documents",
    OrderStageSendSmsStatus = "order-stage.send-sms-status",
    SaleCreateDocuments = "sale-create.documents",
    SaleGoodModalSettings = "sale-good-modal",
    OrderMaterialModalSettings = "order-material-modal",
    GoodRegistrationCreateLabels = "good-registration-create.labels",
    GoodRegistrationCreateDeductFromAccount = "good-registration-create.deduct-from-account",
    GoodRegistrationCreateAccount = "good-registration-create.account",
}

export type SaleGoodModalSettings = {
    accountWidthdrawal?: boolean;
    accountId?: string;
    storeAddition?: boolean;
    storeId?: string;
};

export type OrderMaterialModalSettings = {
    accountWidthdrawal?: boolean;
    accountId?: string;
    storeAddition?: boolean;
    storeId?: string;
};

export class SettingsPlugin {
    private readonly SettingsKey = "webui";
    private _settings: any | null = null;
    private _userId: string | null = null;

    public setUserInfo(userId: string): void {
        this._userId = userId;
    }

    //

    public async getSidebarOpened(): Promise<boolean> {
        const sidebar = await this.get(SettingsUserKey.CommonSidebar);
        return sidebar ? sidebar !== "collapsed" : true;
    }
    public async setSidebarOpened(value: boolean): Promise<void> {
        await this.set(SettingsUserKey.CommonSidebar, value ? "opened" : "collapsed");
    }

    public async getDocumentsOrderCreate(): Promise<string[]> {
        const documents = await this.get(SettingsUserKey.OrderCreateDocuments);
        return documents ? (documents as string[]) : [];
    }
    public async setDocumentsOrderCreate(value: string[]): Promise<void> {
        await this.set(SettingsUserKey.OrderCreateDocuments, value);
    }

    public async getDocumentsOrderComplete(): Promise<string[]> {
        const documents = await this.get(SettingsUserKey.OrderCompleteDocuments);
        return documents ? (documents as string[]) : [];
    }
    public async setDocumentsOrderComplete(value: string[]): Promise<void> {
        await this.set(SettingsUserKey.OrderCompleteDocuments, value);
    }
    public async getOrderStageSendSmsStatus(): Promise<boolean> {
        return (await this.get(SettingsUserKey.OrderStageSendSmsStatus)) ?? false;
    }
    public async setOrderStageSendSmsStatus(value: boolean): Promise<void> {
        await this.set(SettingsUserKey.OrderStageSendSmsStatus, value);
    }

    public async getDocumentsSaleCreate(): Promise<string[]> {
        const documents = await this.get(SettingsUserKey.SaleCreateDocuments);
        return documents ? (documents as string[]) : [];
    }
    public async setDocumentsSaleCreate(value: string[]): Promise<void> {
        await this.set(SettingsUserKey.SaleCreateDocuments, value);
    }

    public async getSaleGoodModal(): Promise<SaleGoodModalSettings | undefined> {
        return await this.get(SettingsUserKey.SaleGoodModalSettings);
    }
    public async setSaleGoodModal(settings: SaleGoodModalSettings): Promise<void> {
        await this.set(SettingsUserKey.SaleGoodModalSettings, settings);
    }

    public async getOrderMaterialModal(): Promise<OrderMaterialModalSettings | undefined> {
        return await this.get(SettingsUserKey.OrderMaterialModalSettings);
    }
    public async setOrderMaterialModal(settings: OrderMaterialModalSettings): Promise<void> {
        await this.set(SettingsUserKey.OrderMaterialModalSettings, settings);
    }

    public async getLabelsGoodRegistrationCreate(): Promise<string[]> {
        const documents = await this.get(SettingsUserKey.GoodRegistrationCreateLabels);
        return documents ? (documents as string[]) : [];
    }
    public async setLabelsGoodRegistrationCreate(value: string[]): Promise<void> {
        await this.set(SettingsUserKey.GoodRegistrationCreateLabels, value);
    }
    public async getDeductFromAccountGoodRegistrationCreate(): Promise<boolean | undefined> {
        return await this.get(SettingsUserKey.GoodRegistrationCreateDeductFromAccount);
    }
    public async setDeductFromAccountGoodRegistrationCreate(value: boolean): Promise<void> {
        await this.set(SettingsUserKey.GoodRegistrationCreateDeductFromAccount, value);
    }
    public async getAccountGoodRegistrationCreate(): Promise<string | undefined> {
        return await this.get(SettingsUserKey.GoodRegistrationCreateAccount);
    }
    public async setAccountGoodRegistrationCreate(value: string): Promise<void> {
        await this.set(SettingsUserKey.GoodRegistrationCreateAccount, value);
    }

    //

    public async get(key: string): Promise<any> {
        if (!this._settings) {
            this._settings = await this.loadSettings();

            if (!this._settings) {
                return null;
            }
        }

        let subobj = this._settings;
        const subkeys = key.split(".");
        for (const subkey of subkeys) {
            subobj = subobj[subkey];
            if (!subobj) {
                return subobj;
            }
        }
        return subobj;
    }

    private async loadSettings(): Promise<any> {
        if (!this._userId) {
            return null;
        }

        const settings = await new SettingsUserUseCase().select(this._userId, this.SettingsKey);
        return settings && settings[this.SettingsKey] ? settings[this.SettingsKey] : null;
    }

    public async set(key: SettingsUserKey, value: any): Promise<void> {
        if (!this._userId) {
            return;
        }

        const settings = await new SettingsUserUseCase().set(this._userId, this.SettingsKey, key, value);
        this._settings = settings && settings[this.SettingsKey] ? settings[this.SettingsKey] : null;

        this.notifySettingsChanged();
    }

    //

    private listenersSettingsChanged = new Set<Function>();
    public set onSettingsChanged(func: Function) {
        this.listenersSettingsChanged.add(func);
    }

    private notifySettingsChanged(): void {
        for (const listener of this.listenersSettingsChanged) {
            listener(this._settings);
        }
    }
}
