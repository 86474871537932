import { IStore, IOrderWork, IGood, IOrderMaterialGetDto, Locale } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    ComboBoxChangedEventArgs,
    Control,
    IconPackage,
    Panel,
    SearchLoadingHandler,
    Select,
    TextBox,
} from "@core/components/alt-ui/controls";
import { WarrantyBox } from "@core/controls/warranty-box";
import { Localizer } from "@/i18n/localizer";
import { DelayedOperation } from "@/utils/delayed-operation";

type OrderWorkRef = { text: string; ref?: IOrderWork };

export interface IMaterialGetModalContext {
    stores: IStore[];
    works: IOrderWork[];
    searchGoods: (store: IStore, search: string) => Promise<IGood[]>;
}

export class MaterialGetModal extends Modal<IMaterialGetModalContext> {
    private cbStore!: Select<IStore>;
    private cbGood!: Select<IGood>;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    private tbQuantity!: TextBox;
    private wbWarranty!: WarrantyBox;
    private cbWork!: Select<OrderWorkRef>;

    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private context: IMaterialGetModalContext | null = null;

    public onGet: ((dto: IOrderMaterialGetDto) => Promise<boolean>) | null = null;

    public constructor() {
        super("material-get-modal", "Добавление материала со склада");
        this.initializeControls();
    }

    public show(context: IMaterialGetModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    protected initializeControls(): void {
        this.cbStore = new Select<IStore>();
        this.cbStore.id = "material-get.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = s => s.info.name;
        this.cbStore.descriptionField = s => s.info.description;
        this.cbStore.iconPackage = IconPackage.Feater;
        this.cbStore.icon = "PackageIcon";
        this.cbStore.selectedIndex = this.cbStore.items.length > 0 ? 0 : -1;

        this.cbGood = new Select<IGood>();
        this.cbGood.id = "material-get.good";
        this.cbGood.label = "Искать материал";
        this.cbGood.items = [];
        this.cbGood.textField = e => e.info.name;
        this.cbGood.search = this.searchMaterial.bind(this);
        this.cbGood.addChangedHandler(this.selectMaterial.bind(this));

        this.tbCost = new TextBox();
        this.tbCost.id = "material-get.cost";
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "required|money|unsigned";
        this.tbCost.disabled = true;

        this.tbPrice = new TextBox();
        this.tbPrice.id = "material-get.price";
        this.tbPrice.label = "Цена продажи";
        this.tbPrice.validation = "required|money|unsigned";

        this.tbQuantity = new TextBox();
        this.tbQuantity.id = "material-get.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = `required|numeric|unsigned|min_value:1|max_value:1`;
        this.tbQuantity.text = "1";

        this.wbWarranty = new WarrantyBox();
        this.wbWarranty.id = "material-get.warranty";
        this.wbWarranty.label = "Гарантия";

        const works: OrderWorkRef[] = this.context?.works.map(w => ({ text: w.name, ref: w })) ?? [];
        works.unshift({ text: "-- Не привязано --", ref: undefined });

        this.cbWork = new Select<OrderWorkRef>();
        this.cbWork.id = "material-get.work";
        this.cbWork.label = "Привязать к работе";
        this.cbWork.items = works;
        this.cbWork.textField = w => w.text;
        this.cbWork.iconPackage = IconPackage.Feater;
        this.cbWork.icon = "ToolIcon";
        this.cbWork.selectedIndex = 0;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "material-get.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "material-get.save";
        this.btnSave.text = "Добавить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    public get controls(): Control[] {
        return [
            this.cbStore,
            this.cbGood,
            this.tbCost,
            this.tbPrice,
            this.tbQuantity,
            this.wbWarranty,
            this.cbWork,
            this.pnlFooter,
        ] as Control[];
    }

    private searchMaterial(search: string): void {
        if (!this.context || !this.cbStore.selectedItem) {
            return;
        }

        const searchGoods = this.context.searchGoods;
        const store = this.cbStore.selectedItem;
        search = search.trim();

        // начинать поиск от 2 символов
        if (search.length < 2) {
            this.cbGood.items = [];
            return;
        }

        DelayedOperation.invoke("search-material", 700, async () => {
            this.cbGood.items = await searchGoods(store, search);
        });
    }

    private async selectMaterial(sender: any, e: ComboBoxChangedEventArgs<IGood>): Promise<void> {
        if (!e.item) {
            return;
        }

        const material = e.item;
        const locale = this.cbStore.selectedItem?.info?.locale ?? Locale.RU;
        const cost = material.info.cost ?? 0.0;
        const price = material.info.price ?? 0.0;
        const quantity = material.info.quantity ?? 0;

        this.tbCost.text = Localizer.money(cost, locale);
        this.tbPrice.text = Localizer.money(price, locale);
        this.tbQuantity.label = `Количество (макс: ${quantity})`;
        this.tbQuantity.validation = `required|numeric|unsigned|min_value:1|max_value:${quantity}`;
        this.tbQuantity.text = "1";
        this.wbWarranty.value = material.info.warranty;
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.onGet) {
            return;
        }

        if (!this.cbStore.selectedItem || !this.cbGood.selectedItem) {
            return;
        }

        const priceStr = this.tbPrice.text.replace(",", ".");

        const dto: IOrderMaterialGetDto = {
            price: parseFloat(priceStr),
            quantity: parseInt(this.tbQuantity.text),
            warranty: this.wbWarranty.value,
            work: this.cbWork.selectedItem?.ref?.id,
            store: this.cbStore.selectedItem.id,
            good: this.cbGood.selectedItem.id,
        };

        const result = await this.onGet(dto);
        if (result) {
            this.hide(result);
        }
    }
}
