import { ISalary, Locale } from "@lib";
import { ISalaryTableItem, SalaryTableItemType } from ".";

export default class SalaryTableReservedConverter {
    private salaries: ISalary[];
    private userId: string;

    public constructor(salaries: ISalary[], userId: string) {
        this.salaries = salaries;
        this.userId = userId;
    }

    public convert(): ISalaryTableItem[] {
        const items: ISalaryTableItem[] = [];
        for (const salary of this.salaries) {
            const item: ISalaryTableItem = {
                type: SalaryTableItemType.Reserved,
                number: salary.number,
                name: this.getSalaryName(salary),
                profit: salary.amount,
                amount: salary.amount,
                meta: {
                    employee: this.userId,
                    salary: salary,
                    //salary: salaryLink,
                    locale: Locale.RU, // salary.shopRef?.info.locale,
                },
                paid: salary.paid,
                _rowVariant: salary.paid ? "success" : undefined,
            };
            items.push(item);
        }
        return items;
    }

    private getSalaryName(salary: ISalary): string {
        //const prefix = salary.amount < 0 ? "Штраф" : "Премия";
        //let name = `${prefix}: ${salary.comment}` ?? "";
        const name = salary.comment ?? "";
        /*for (const subject of salary.subject) {
            if (subject.order && !subject.work && !subject.material) {
                if (name.length > 0) name += ",\n";
                name += `Заявка №${ (subject.order as IOrder).number }`;
            }
            if (subject.order && subject.work) {
                if (name.length > 0) name += ",\n";
                name += `Заявка №${ (subject.order as IOrder).number }`;
                //<small>- {{ getOrderWorkName(subject.order, subject.work) }}</small>
            }
            if (subject.order && subject.material) {
                if (name.length > 0) name += ",\n";
                name += `Заявка №${ (subject.order as IOrder).number }`;
                //<small>- {{ getOrderMaterialName(subject.order, subject.material) }}</small>
            }
            if (subject.sale) {
                if (name.length > 0) name += ",\n";
                name += `Продажа №${ (subject.sale as ISale).number }`;
            }
        }*/
        return name.length > 0 ? name : "Выплата";
    }

    public static convert(salary: ISalary[], userId: string): ISalaryTableItem[] {
        return new SalaryTableReservedConverter(salary, userId).convert();
    }
}
