import { Control } from "./control";

export class Grid extends Control {
    private readonly grid: (Control | null)[][] = [];

    public readonly rowCount: number;
    public readonly columnCount: number;

    public constructor(rowCount: number, columnCount: number) {
        super();
        this.rowCount = rowCount;
        this.columnCount = columnCount;

        this.grid = [];
        for (let r = 0; r < this.rowCount; ++r) {
            this.grid[r] = [];

            for (let c = 0; c < this.columnCount; ++c) {
                this.grid[r][c] = null;
            }
        }
    }

    public getComponentName(): string {
        return "GridComponent";
    }

    public addControl(control: Control, row: number, col: number): void {
        this.grid[row][col] = control;
    }

    public getControl(row: number, col: number): Control | null {
        return this.grid[row][col];
    }
}
