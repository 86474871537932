import Vue, { VNode } from "vue";
import { IClient, IClientCreateDto, IClientSource, IClientUpdateDto, IField } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel } from "@core/components/alt-ui/controls";
import { ModalSettingsModal } from "@/@core/controls/modal-settings/modal-settings.modal";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { FieldControl } from "@/@core/types/field-controls/field-control";
import { DefaultFieldDataExtractor, DefaultFieldDataSetup } from "./clients-defaults";

export interface IClientModalContext {
    vue: Vue;
    client?: IClient;
    sources: IClientSource[];
    fields: IField[];
    defaultFields: IField[];
    settingsModal: ModalSettingsModal;
}

export class ClientModal extends Modal<IClientModalContext> {
    private pnlMain!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private fieldControls: FieldControl[] = [];
    private context: IClientModalContext | null = null;

    public onModalShow: (() => void) | null = null;
    public onModalHide: (() => void) | null = null;

    public onCreate: ((dto: IClientCreateDto) => Promise<IClient | null>) | null = null;
    public onUpdate: ((orig: IClient, dto: IClientUpdateDto) => Promise<IClient | null>) | null = null;

    public constructor() {
        super("client-modal", "");
        this.addVisibleChangedHandler(this.changeModalVisible.bind(this));
        this.initializeControls();
    }

    public show(context: IClientModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();

        if (context.client) {
            this.title = "Изменение клиента";
            const iconNode = this.createIconNode();
            this.titleVNode = this.context.vue.$createElement("div", { class: "flex items-center" }, [
                this.title.toUpperCase(),
                iconNode,
            ]);
            this.populateControls(context.client);
        } else {
            this.title = "Новый клиент";
            const iconNode = this.createIconNode();
            this.titleVNode = this.context.vue.$createElement("div", { class: "flex items-center" }, [
                this.title.toUpperCase(),
                iconNode,
            ]);
        }

        return super.show();
    }

    private createIconNode(): VNode | null {
        if (!this.context) {
            return null;
        }

        const h = this.context.vue.$createElement;
        return h("feather-icon", {
            props: {
                icon: "SettingsIcon",
            },
            class: {
                "cursor-pointer": true,
                "hover:text-primary": true,
                "ml-0.5": true,
            },
            style: {
                width: "1rem",
                height: "1rem",
            },
            on: {
                click: () => this.showSettingsModal(null, null),
            },
        });
    }

    private initializeControls(): void {
        this.fieldControls = this.fieldsToFieldControls(this.context?.fields ?? []);

        this.pnlMain = new Panel();
        this.pnlMain.id = "client.controls";
        this.pnlMain.addControls(
            this.fieldControls.map(fc => {
                fc.control.class = "mb-0.75";
                return fc.control;
            }),
        );

        this.btnCancel = new Button();
        this.btnCancel.id = "client.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "client.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private fieldsToFieldControls(fields: IField[]): FieldControl[] {
        if (!this.context) {
            return [];
        }

        const fieldControls: FieldControl[] = [];

        const fieldControlContext = {
            clientSources: this.context.sources,
        };

        for (const field of this.filterFields(fields)) {
            if (field.custom) {
                const fieldControl = FieldControlFactory.fromField(field, fieldControlContext);
                fieldControls.push(fieldControl);
            } else {
                const sfield = this.context.defaultFields.find(df => df.standart === field.standart);
                if (!sfield) {
                    continue;
                }

                const fieldControl = FieldControlFactory.fromField(sfield, fieldControlContext);
                fieldControls.push(fieldControl);
            }
        }

        return fieldControls;
    }

    public filterFields(fields: IField[]): IField[] {
        return fields
            .filter(f => !f.hidden)
            .sort((a, b) => (a.sequence !== undefined && b.sequence !== undefined ? a.sequence - b.sequence : 0));
    }

    private populateControls(client: IClient): void {
        for (const fieldControl of this.fieldControls) {
            if (fieldControl.field.custom) {
                if (!client.custom) {
                    continue;
                }

                const customId = fieldControl.field.customId ?? fieldControl.id;
                const value = client.custom[customId];
                if (!value) {
                    continue;
                }

                fieldControl.value = value;
            } else {
                const id = fieldControl.field.standart ?? fieldControl.id;
                if (!DefaultFieldDataExtractor[id]) {
                    continue;
                }

                fieldControl.value = DefaultFieldDataExtractor[id](client);
            }
        }
    }

    public get controls(): Control[] {
        return [this.pnlMain, this.pnlFooter];
    }

    private async changeModalVisible(sender: any, e: any): Promise<void> {
        if (e.visible) {
            if (this.onModalShow) {
                this.onModalShow();
            }
        } else {
            if (this.onModalHide) {
                this.onModalHide();
            }
        }
    }

    private async showSettingsModal(sender: any, e: any): Promise<void> {
        if (!this.context) {
            return;
        }

        this.context.settingsModal.show(this.context.fields);
        this.hide();
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.context?.client ? await this.updateClient(this.context.client) : await this.createClient();

        if (result) {
            this.hide(result);
        }
    }

    private async createClient(): Promise<IClient | null> {
        if (!this.context || !this.onCreate) {
            return null;
        }

        const dto: IClientCreateDto = { name: "" };

        for (const fieldControl of this.fieldControls) {
            if (fieldControl.field.custom) {
                if (!dto.custom) {
                    dto.custom = {};
                }

                const customId = fieldControl.field.customId ?? fieldControl.id;
                dto.custom[customId] = fieldControl.value;
            } else {
                const id = fieldControl.field.standart ?? fieldControl.id;
                if (!DefaultFieldDataSetup[id]) {
                    continue;
                }

                DefaultFieldDataSetup[id](dto, fieldControl.value);
            }
        }

        return await this.onCreate(dto);
    }

    private async updateClient(orig: IClient): Promise<IClient | null> {
        if (!this.context || !this.onUpdate) {
            return null;
        }

        const dto: IClientUpdateDto = {};

        for (const fieldControl of this.fieldControls) {
            if (fieldControl.field.custom) {
                if (!dto.custom) {
                    dto.custom = {};
                }

                const customId = fieldControl.field.customId ?? fieldControl.id;
                dto.custom[customId] = fieldControl.value;
            } else {
                const id = fieldControl.field.standart ?? fieldControl.id;
                if (!DefaultFieldDataSetup[id]) {
                    continue;
                }

                DefaultFieldDataSetup[id](dto, fieldControl.value);
            }
        }

        return await this.onUpdate(orig, dto);
    }
}
