import Vue, { VNode } from "vue";

export class AltToast {
    private _context: Vue | undefined;
    private readonly place = "b-toaster-bottom-left";
    private readonly solid = true;

    public set context(context: Vue) {
        this._context = context;
    }

    private show(text: string | VNode[], title?: string | VNode[], option?: any): void {
        this._context?.$bvToast.toast(text, {
            title,
            toaster: this.place,
            solid: this.solid,
            variant: option?.color ?? "primary",
            autoHideDelay: option?.autoHideDelay,
        });
    }

    public success(text: string | VNode[], title?: string | VNode[], option?: any): void {
        this.show(text, title, { color: "success", ...option });
    }

    public info(text: string | VNode[], title?: string | VNode[], option?: any): void {
        this.show(text, title, { color: "primary", ...option });
    }

    public error(text: string | VNode[], title: string | VNode[] = "Ошибка", option?: any): void {
        this.show(text, title, { color: "danger", ...option });
    }

    public warning(text: string | VNode[], title?: string | VNode[], option?: any): void {
        this.show(text, title, { color: "warning", ...option });
    }
}
