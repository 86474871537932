import { IField } from "@lib";
import { Select } from "@core/components/alt-ui/controls";
import { ISelectOption } from "../../common/select-options";
import { FieldControl } from "../field-control";
import { Uuid } from "@/utils/uuid";

export class SelectFieldControl extends FieldControl<Select<ISelectOption>> {
    protected convert(): Select<ISelectOption> {
        const control = new Select<ISelectOption>();
        control.id = `${this.field.id}_${Uuid.short()}`;
        control.label = this.field.title ?? "";
        control.help = this.field.description ?? "";
        control.items = this.convertSelectOptions(this.field);
        control.textField = item => item.name;
        control.descriptionField = item => item.description;
        // control.addChangedHandler((s, e) => {
        //     console.log(e.item);
        //     console.log(control.selectedItem);
        // });
        control.selectedItem = this.convertDefault(this.field.default, control.items);
        return control;
    }

    private convertSelectOptions(field: IField): ISelectOption[] {
        if (field.items && field.items.length > 0) {
            return field.items;
        }

        switch (field.details1) {
            case "select.standart":
                return this.convertSelectOptionsStandart(field);
            case "select.fixed":
                return this.convertSelectOptionsFixed(field);
        }

        return [];
    }

    private convertSelectOptionsStandart(field: IField): ISelectOption[] {
        switch (field.details2) {
            case "$(компания.списки.источники)":
            case "$(company.lists.sources)":
                return this.context?.clientSources?.map(source => ({ id: source.id, name: source.name })) ?? [];

            case "$(компания.списки.типыпродуктов)":
            case "$(company.lists.producttypes)":
                return this.context?.productTypes?.map(type => ({ id: type.id, name: type.name })) ?? [];

            case "$(компания.счета)":
            case "$(company.accounts)":
                return this.context?.accounts?.map(account => ({ id: account.id, name: account.info.name })) ?? [];

            // case "$(компания.сотрудники)":
            // case "$(company.employees)":
            //     return this.context?.employees?.map(emp => ({ id: emp.id, name: emp.userRef.info.name })) ?? [];
        }

        return [];
    }

    private convertSelectOptionsFixed(field: IField): ISelectOption[] {
        try {
            if (!field.details2) {
                return [];
            }

            return JSON.parse(field.details2);
        } catch {
            return [];
        }
    }

    private convertDefault(defvalue: string | undefined, options: ISelectOption[]): ISelectOption | null {
        if (!defvalue || defvalue === "$(первый)" || defvalue === "$(first)") {
            return options.length > 0 ? options[0] : null;
        } else if (defvalue === "$(ничего)" || defvalue === "$(nothing)") {
            return null;
        }
        // } else if (defvalue === "$(me.id)") {
        //     return options.find(o => o.id === this.context?.user?.id) ?? null;
        // }

        return options.find(o => o.id === defvalue) ?? null;
    }

    public get value(): any {
        return this.control.selectedItem?.id ?? null;
    }

    public set value(value: any) {
        this.control.selectedItem = this.control.items.find(i => i.id === value) ?? null;
    }

    public getValueFormatted(): string {
        if (!this.value) {
            return "";
        }

        if (this.field.details1 === "select.standart") {
            return this.formatSelectStandart(this.value, this.field.details2 ?? "");
        }

        return this.control.selectedItem?.name ?? "";
    }

    private formatSelectStandart(value: string, details2: string): string {
        switch (details2) {
            case "$(компания.списки.источники)":
            case "$(company.lists.sources)":
                return this.context?.clientSources?.find(type => type.id === value)?.name ?? "";

            case "$(компания.списки.типыпродуктов)":
            case "$(company.lists.producttypes)":
                return this.context?.productTypes?.find(type => type.id === value)?.name ?? "";

            case "$(компания.счета)":
            case "$(company.accounts)":
                return this.context?.accounts?.find(account => account.id === value)?.info.name ?? "";

            // case "$(компания.сотрудники)":
            // case "$(company.employees)":
            //     return this.context?.employees?.find(emp => emp.id === value)?.userRef.info.name ?? "";
        }

        return "";
    }
}
