import { Vue, Component, Prop } from "vue-property-decorator";
import { BDropdown, BDropdownItem } from "bootstrap-vue";

@Component({
    name: "va-table-limits",
    components: { BDropdown, BDropdownItem },
})
export default class VaTableLimits extends Vue {
    @Prop({ type: Array, default: () => [] })
    private limits!: string[];

    @Prop({ type: Number, required: true })
    private offset!: number;

    @Prop({ type: Number, required: true })
    private limit!: number;

    @Prop({ type: Number, required: true })
    private total!: number;

    private changeLimit(limit: number): void {
        this.$emit("change-limit", limit);
    }
}
