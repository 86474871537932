






import { Vue, Component, Prop } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";

@Component({
    name: "alt-button",
    components: { BButton },
})
export default class AltButton extends Vue {
    @Prop({ type: String })
    private id!: string;

    @Prop({ type: String, default: "primary" })
    private variant!: string;

    @Prop({ type: Boolean })
    private disabled!: boolean;

    public click(event: any) {
        this.$emit("click", event);
    }
}
