





import { Vue, Component } from "vue-property-decorator";

@Component({ name: "CellRendererMoney" })
export default class CellRendererMoney extends Vue {
    private params: any;

    private get customValue(): number {
        return this.params.modify ? this.params.modify(this.params.value) : this.params.value;
    }

    private get currency(): number {
        return this.params.currency;
    }

    private get locale(): number {
        return this.params.locale;
    }

    private get customClass(): string {
        return this.params.class ?? "";
    }
}
