import moment from "moment";
import { Locale } from "@lib";
import { datetime } from "@/filters/datetime";
import { moneyFormat } from "@/filters/money";
import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";
import { FilterPair } from "@/utils/filter";

export class BaseTableReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;

        const from = dates[0];
        const to = dates[1];

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.base(options.company, {
            timezone: new Date().getTimezoneOffset().toString(),
            from,
            to,
        });

        const classHeaderNum = "va-td va-td-num text-center";
        const classHeaderName = "va-td text-center";
        const classHeaderMoney = "va-td va-td-money text-center";

        const classCellNum = "va-td va-td-num text-center";
        const classCellName = "va-td";
        const classCellMoney = "va-td va-td-money text-center";

        const header = [
            {
                cells: [
                    {
                        value: "#",
                        class: classHeaderNum,
                    },
                    {
                        value: "Показатель",
                        class: classHeaderName,
                    },
                    ...report.dates.map(dt => {
                        return {
                            value: this.dtFormat(dt),
                            class: classHeaderMoney,
                        };
                    }),
                ],
            },
        ];

        const locale = Locale.RU;
        const body = [
            {
                cells: [
                    {
                        value: "1",
                        class: classCellNum,
                    },
                    {
                        value: "Выручка",
                        class: classCellName,
                    },
                    ...report.revenue.map(v => {
                        return {
                            value: moneyFormat(v, { locale }),
                            class: classCellMoney,
                        };
                    }),
                ],
            },
            {
                cells: [
                    {
                        value: "2",
                        class: classCellNum,
                    },
                    {
                        value: "Расходы",
                        class: classCellName,
                    },
                    ...report.expenses.map(v => {
                        return {
                            value: moneyFormat(v, { locale }),
                            class: classCellMoney,
                        };
                    }),
                ],
            },
            {
                cells: [
                    {
                        value: "3",
                        class: classCellNum,
                    },
                    {
                        value: "Прибыль",
                        class: classCellName,
                    },
                    ...report.profit.map(v => {
                        return {
                            value: moneyFormat(v, { locale }),
                            class: classCellMoney,
                        };
                    }),
                ],
            },
        ];

        const subtitle = from === to ? datetime(from, "LL") : `${datetime(from, "LL")} - ${datetime(to, "LL")}`;

        return {
            title: "Отчёт по основным показателям",
            subtitle: subtitle,
            items: [
                {
                    kind: ReportKind.Table,
                    table: {
                        header,
                        body,
                    },
                },
            ],
        };
    }

    private dtFormat(date: number | string | Date): string {
        return moment(date).format("MMM 'YY");
    }
}
