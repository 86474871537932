









import { Vue, Component, Prop } from "vue-property-decorator";
import { BDropdownItem, BDropdownDivider } from "bootstrap-vue";

@Component({
    name: "va-table-actions-item",
    components: { BDropdownItem, BDropdownDivider },
})
export default class VaTableActionsItem extends Vue {
    @Prop({ type: Object, required: true })
    private action!: any;

    @Prop({ type: Object, required: true })
    private gridOptions!: any;

    private get selectedItems(): any[] {
        return this.gridOptions.api.getSelectedRows();
    }
}
