import { IStore, IGood, IWarranty, IGoodCreateDto, Locale } from "@lib";
import {
    Button,
    ComboBoxChangedEventArgs,
    Control,
    Label,
    Panel,
    Select,
    TextBox,
    TextChangedEventArgs,
} from "@core/components/alt-ui/controls";
import { WarrantyBox } from "@core/controls/warranty-box";
import { Modal } from "@core/components/alt-ui/modal";
import { Warranty } from "@core/types/common/warranty";
import { DelayedOperation } from "@/utils/delayed-operation";
import { Localizer } from "@/i18n/localizer";
import { ValueChangedEventArgs } from "@core/components/alt-ui";

export interface IGoodRegistrationGetItemModalContext {
    store?: IStore;
    searchGoods: (search: string, storeId: string) => Promise<IGood[]>;
}

export class GoodRegistrationGetItemModal extends Modal<IGoodRegistrationGetItemModalContext> {
    private cbGood!: Select<IGood>;

    private tbQuantity!: TextBox;
    private lbQuantity!: Label;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    // private wbWarranty!: WarrantyBox;
    private pnlInfo!: Panel;

    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private context: IGoodRegistrationGetItemModalContext | null = null;
    private originalItem: IGood | null = null;

    public onAdd: ((dto: any) => Promise<boolean>) | null = null;

    public constructor() {
        super("good-registration-get-item-modal", "Добавление товара со склада");
        this.initializeControls();
    }

    public show(context: IGoodRegistrationGetItemModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    protected initializeControls(): void {
        this.cbGood = new Select<IGood>();
        this.cbGood.id = "good-get.good";
        this.cbGood.label = "Искать товар";
        this.cbGood.items = [];
        this.cbGood.textField = e => e.info.name;
        this.cbGood.descriptionField = e => e.info.description;
        this.cbGood.search = this.searchGood.bind(this);
        this.cbGood.addChangedHandler(this.selectGood.bind(this));

        this.tbQuantity = new TextBox();
        this.tbQuantity.id = "good-get.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = "required|numeric|unsigned|min_value:1";
        this.tbQuantity.addTextChangedHandler(this.changeQuantity.bind(this));

        this.lbQuantity = new Label();
        this.lbQuantity.class = "text-sm text-primary mb-0.75";

        this.tbCost = new TextBox();
        this.tbCost.id = "good-get.cost";
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "required|money|unsigned";
        this.tbCost.class = "mb-0.75";
        this.tbCost.addTextChangedHandler(this.changeCost.bind(this));

        this.tbPrice = new TextBox();
        this.tbPrice.id = "good-get.price";
        this.tbPrice.label = "Цена продажи";
        this.tbPrice.validation = "required|money|unsigned";
        this.tbPrice.class = "mb-0.75";
        this.tbPrice.addTextChangedHandler(this.changePrice.bind(this));

        // this.wbWarranty = new WarrantyBox();
        // this.wbWarranty.id = "good-get.warranty";
        // this.wbWarranty.label = "Гарантия";
        // this.wbWarranty.class = "mb-0.75";
        // this.wbWarranty.addValueChangedHandler(this.changeWarranty.bind(this));

        this.pnlInfo = new Panel();
        // this.pnlInfo.addControls([this.tbQuantity, this.lbQuantity, this.tbCost, this.tbPrice, this.wbWarranty]);
        this.pnlInfo.addControls([this.tbQuantity, this.lbQuantity, this.tbCost, this.tbPrice]);
        this.pnlInfo.visible = false;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "good-get.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "good-get.save";
        this.btnSave.text = "Добавить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    public get controls(): Control[] {
        return [this.cbGood, this.pnlInfo, this.pnlFooter];
    }

    private searchGood(search: string): void {
        if (!this.context) {
            return;
        }

        const searchGoods = this.context.searchGoods;
        const storeId = this.context.store?.id ?? "";
        search = search.trim();

        // начинать поиск от 2 символов
        if (search.length < 2) {
            this.cbGood.items = [];
            return;
        }

        DelayedOperation.invoke("search-goods", 700, async () => {
            this.cbGood.items = await searchGoods(search, storeId);
        });
    }

    private async selectGood(sender: any, e: ComboBoxChangedEventArgs<IGood>): Promise<void> {
        if (!e.item) {
            this.pnlInfo.visible = false;
            return;
        }

        this.originalItem = e.item;

        const good = e.item;
        const locale = good.storeRef?.info?.locale ?? Locale.RU;
        const cost = good.info.cost ?? 0.0;
        const price = good.info.price ?? 0.0;

        this.tbQuantity.text = "1";
        this.tbCost.text = Localizer.money(cost, locale);
        this.tbPrice.text = Localizer.money(price, locale);
        // this.wbWarranty.value = good.info.warranty;

        this.pnlInfo.visible = true;
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.onAdd) {
            return;
        }

        if (!this.cbGood.selectedItem || !this.context?.store?.id) {
            return;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const cost = parseFloat(costStr);

        const priceStr = this.tbPrice.text.replace(",", ".");
        const price = parseFloat(priceStr);

        const quantity = parseInt(this.tbQuantity.text);

        const dto: IGoodCreateDto = {
            store: this.context.store.id,
            good: this.cbGood.selectedItem.id,
            info: {
                ...this.cbGood.selectedItem.info,
                cost,
                price,
                quantity,
                // warranty: this.wbWarranty.value,
            },
        };

        const result = await this.onAdd(dto);
        if (result) {
            this.hide(result);
        }
    }

    private async changeQuantity(sender: any, e: TextChangedEventArgs): Promise<void> {
        let origQuantity = this.originalItem?.info.quantity;
        if (origQuantity === undefined) {
            origQuantity = 0;
        }

        let newQuantity = parseInt(e.text);
        if (isNaN(newQuantity)) {
            newQuantity = 0;
        }

        this.tbQuantity.label = `Количество (на складе: ${origQuantity})`;
        this.lbQuantity.text = `Будет на складе: ${origQuantity + newQuantity}`;
    }

    private async changeCost(sender: any, e: TextChangedEventArgs): Promise<void> {
        const cost = this.originalItem?.info.cost;
        if (!cost) {
            return;
        }

        if (cost === parseFloat(e.text)) {
            this.tbCost.label = "Себестоимость";
            return;
        }

        this.tbCost.label = `Себестоимость (исходная: ${cost})`;
    }

    private async changePrice(sender: any, e: TextChangedEventArgs): Promise<void> {
        const price = this.originalItem?.info.price;
        if (!price) {
            return;
        }

        if (price === parseFloat(e.text)) {
            this.tbPrice.label = "Цена продажи";
            return;
        }

        this.tbPrice.label = `Цена продажи (исходная: ${price})`;
    }

    // private async changeWarranty(sender: any, e: ValueChangedEventArgs<IWarranty>): Promise<void> {
    //     const warranty = this.originalItem?.info.warranty;
    //     const item = e.value;

    //     if (warranty?.type === item.type && warranty.value === item.value) {
    //         this.wbWarranty.label = "Гарантия";
    //         return;
    //     }

    //     let src = Warranty.toString(warranty);
    //     if (src.length === 0) {
    //         src = "нет";
    //     }
    //     this.wbWarranty.label = `Гарантия (исходная: ${src})`;
    // }
}
