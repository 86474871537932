import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { IShop } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { SaleBlockInfoHandler } from "./sale-block-info-handler";

@Component({
    components: { ControlComponent },
})
export default class SaleCreateInfo extends Vue {
    @Prop({ required: true })
    private handler!: SaleBlockInfoHandler;

    @Prop({ default: () => null })
    private shop!: IShop | null;

    @Watch("shop", { immediate: true, deep: true })
    private onShopChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        this.handler.init(this.shop);
    }
}
