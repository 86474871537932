import { IAccount, IEmployee, ISalary, Locale } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Html, Panel, Select, TextBox } from "@core/components/alt-ui/controls";
import { ISalaryTableItem, SalaryTableItemType } from "./salary-table";
import { Formatter } from "@/utils/formatter";

export interface ISalaryPayModalContext {
    item: ISalaryTableItem;
    accounts: IAccount[];
    employee?: IEmployee;
}

export class SalaryPayModal extends Modal<ISalaryPayModalContext> {
    private cbAccount!: Select<IAccount>;
    private htSummary!: Html;
    private tbAmount!: TextBox;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: ISalaryPayModalContext | null = null;

    public onPay: ((account: IAccount, item: ISalaryTableItem) => Promise<ISalary | null>) | null = null;

    public constructor() {
        super("salary-pay-modal", "Выплата");
        this.initializeControls();
    }

    public show(context: ISalaryPayModalContext): Promise<void> {
        this.context = context;
        this.title = this.getTitle(this.context.item);
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        const accounts = this.context?.accounts ?? [];
        const item = this.context?.item ?? null;
        const amount = item?.amount ?? 0;
        const locale = item?.meta.locale as Locale | undefined;
        const employee = this.context?.employee ?? null;

        let accountIndex = employee?.defaultAccount ? accounts.findIndex(a => a.id === employee.defaultAccount.id) : -1;
        if (accountIndex < 0) {
            accountIndex = accounts.length > 0 ? 0 : -1;
        }

        this.cbAccount = new Select<IAccount>();
        this.cbAccount.id = "salary-pay.account";
        this.cbAccount.label = "Счёт";
        this.cbAccount.items = accounts;
        this.cbAccount.textField = a => {
            const balance = Formatter.money(a.balance, { locale: a.info.locale, currency: a.info.currency });
            const insufficient = a.balance < amount;
            return insufficient ? `${a.info.name} (${balance} - не хватает средств!)` : `${a.info.name} (${balance})`;
        };
        this.cbAccount.descriptionField = a => a.info.description;
        this.cbAccount.selectedIndex = accountIndex;

        this.htSummary = new Html();
        this.htSummary.id = "salary-pay.summary";
        this.htSummary.html = item ? this.getSummary(item) : "";

        this.tbAmount = new TextBox();
        this.tbAmount.id = "salary-pay.amount";
        this.tbAmount.label = "К оплате";
        this.tbAmount.text = Formatter.money(amount, { locale });
        this.tbAmount.disabled = true;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "salary-pay.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "salary-pay.save";
        this.btnSave.text = "Выплатить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private getTitle(item: ISalaryTableItem): string {
        if (item.meta.order && !item.meta.work && !item.meta.material) {
            return "Выплата за заявку";
        } else if (item.meta.order && item.meta.work) {
            return "Выплата за работу";
        } else if (item.meta.order && item.meta.material) {
            return "Выплата за материал";
        } else if (item.meta.sale) {
            return "Выплата за продажу";
        }

        return "Выплата";
    }

    private getSummary(item: ISalaryTableItem): string {
        const profitStr = Formatter.money(item.profit ?? 0, { locale: item.meta.locale as Locale });

        let summary = '<div class="ml-2 mt-1 mb-1">';
        if (item.type === SalaryTableItemType.Reserved) {
            summary += `${item.name}<br/>`;
        }
        if (item.meta.order) {
            summary += `Заявка №${item.meta.order.number}<br/>`;
        }
        if (item.meta.work) {
            summary += `Работа: ${item.meta.work.name}<br/>`;
        }
        if (item.meta.material) {
            summary += `Материал: ${item.meta.material.name}<br/>`;
        }
        if (item.meta.percentWork) {
            summary += `Процент за работы: ${item.meta.percentWork}%<br/>`;
        }
        if (item.meta.percentMaterial) {
            summary += `Процент за материалы: ${item.meta.percentMaterial}%<br/>`;
        }
        if (item.meta.sale) {
            summary += `Продажа №${item.meta.sale.number}<br/>`;
        }
        if (item.meta.percentSale) {
            summary += `Процент за продажи: ${item.meta.percentSale}%<br/>`;
        }
        summary += `Стоимость: ${profitStr}<br/>`;
        summary += "</div>";
        return summary;
    }

    public get controls(): Control[] {
        return [this.cbAccount, this.htSummary, this.tbAmount, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.onPay || !this.cbAccount.selectedItem) {
            return;
        }

        const result = await this.onPay(this.cbAccount.selectedItem, this.context.item);

        if (result) {
            this.hide(result);
        }
    }
}
