import { FieldType, IField, IValidation } from "@lib";
import { CheckBox, Control, Label, TextBox, TextChangedEventArgs } from "@core/components/alt-ui/controls";
import FieldSettingsItems from "./field-settings-items";

export default class FieldSettingsItemsTextBox extends FieldSettingsItems {
    private tbTitle!: TextBox;
    private tbDescription!: TextBox;
    private tbDefault!: TextBox;
    private chbRequired!: CheckBox;
    private tbMacros!: TextBox;
    private lbError!: Label;

    protected initializeControls(): void {
        this.tbTitle = new TextBox();
        this.tbTitle.id = `${this.id}.title`;
        this.tbTitle.label = "Название";
        this.tbTitle.validation = "required";
        this.tbTitle.class = "mb-0.75";

        this.tbDescription = new TextBox();
        this.tbDescription.id = `${this.id}.description`;
        this.tbDescription.label = "Подсказка";
        this.tbDescription.class = "mb-0.75";

        this.tbDefault = new TextBox();
        this.tbDefault.id = `${this.id}.default`;
        this.tbDefault.label = "Значение по умолчанию";
        this.tbDefault.class = "mb-0.75";

        this.chbRequired = new CheckBox();
        this.chbRequired.id = `${this.id}.required`;
        this.chbRequired.text = "Обязательно для заполнения";
        this.chbRequired.value = false;
        this.chbRequired.class = "mb-0.75";

        this.tbMacros = new TextBox();
        this.tbMacros.id = `${this.id}.macros`;
        this.tbMacros.label = "Макрос для документов";
        this.tbMacros.validation = "macro";
        this.tbMacros.addTextChangedHandler(this.changeMacro.bind(this));
        this.tbMacros.visible = !!this.context.canSpecifyMacro;

        this.lbError = new Label();
        this.lbError.class = "text-danger text-sm mb-0.75";
        this.lbError.visible = false;
    }

    public populateControls(field: IField): void {
        this.tbTitle.text = field.title ?? "";
        this.tbDescription.text = field.description ?? "";
        this.tbDefault.text = field.default ?? "";
        this.chbRequired.value = field.validation?.required ?? false;

        if (field.custom) {
            this.tbMacros.text = field.macros && field.macros.length > 0 ? field.macros[0] : "";
        }

        if (field.type === FieldType.Phone) {
            this.tbDefault.visible = false;
        }
    }

    public get controls(): Control[] {
        return [this.tbTitle, this.tbDescription, this.tbDefault, this.chbRequired, this.tbMacros, this.lbError];
    }

    public getField(): IField {
        return {
            title: this.tbTitle.text,
            description: this.tbDescription.text.length > 0 ? this.tbDescription.text : undefined,
            default: this.tbDefault.text.length > 0 ? this.tbDefault.text : undefined,
            validation: this.getValidation(),
            macros: this.tbMacros.text.length > 0 ? [this.tbMacros.text.trim().toLowerCase()] : undefined,
        } as IField;
    }

    private getValidation(): IValidation | undefined {
        if (this.chbRequired.value) {
            return {
                required: this.chbRequired.value,
            };
        }

        return undefined;
    }

    public changeMacro(sender: any, e: TextChangedEventArgs): void {
        this.validate();
    }

    public validate(): boolean {
        this.lbError.visible = false;

        const macro = this.tbMacros.text.trim().toLocaleLowerCase();

        if (!this.validateMacros(macro)) {
            this.lbError.text = "Макрос уже занят";
            this.lbError.visible = true;
            return false;
        }

        return true;
    }
}
