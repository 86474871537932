import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Label } from "@core/components/alt-ui/controls";

export type WorksExportContextOut = { filters?: Record<string, any> };

export class WorksExportModal extends Modal {
    private lbAlert!: Label;

    private btnExport!: Button;

    public onExport: ((context: WorksExportContextOut) => Promise<boolean>) | null = null;

    public constructor() {
        super("modal-works-export", "Экспорт работ");

        this.initializeControls();
    }

    public show(): Promise<void> {
        this.initializeControls();

        return super.show();
    }

    private initializeControls(): void {
        this.lbAlert = new Label();
        this.btnExport = new Button();

        //

        this.lbAlert.id = "works.export.label";
        this.lbAlert.text = "В файл будут выгружены все работы";
        this.lbAlert.class = "mt-0.25";

        this.btnExport.id = "works.export.btn";
        this.btnExport.text = "Экспорт";
        this.btnExport.class = "mt-0.25";
        this.btnExport.addClickHandler(this.onClick.bind(this));
    }

    public get controls(): Control[] {
        return [this.lbAlert, this.btnExport];
    }

    private async onClick(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        this.btnExport.disabled = true;

        const result = await this.export();
        if (result) {
            this.btnExport.disabled = false;
            this.hide(result);
        }
    }

    private async export(): Promise<boolean> {
        if (!this.onExport) {
            return false;
        }

        return await this.onExport({});
    }
}
