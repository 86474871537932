import { IWarranty, WarrantyType } from "@lib";
import { ISelectOption } from "./select-options";
import { DateTimeUtils } from "@/utils/datetime.utils";
import * as filters from "@/filters";

export abstract class Warranty {
    public static getTypes(): WarrantyType[] {
        return [
            WarrantyType.Month,
            WarrantyType.Week,
            WarrantyType.Day,
            WarrantyType.Year,
            WarrantyType.Inf,
            WarrantyType.Other,
        ];
    }

    public static getTypeText(type: WarrantyType): string {
        switch (type) {
            case WarrantyType.Month:
                return "мес.";
            case WarrantyType.Week:
                return "нед.";
            case WarrantyType.Day:
                return "дн.";
            case WarrantyType.Year:
                return "г.";
            case WarrantyType.Inf:
                return "бессрочная";
            case WarrantyType.Other:
                return "";
        }
    }

    public static getOptions(): ISelectOption<WarrantyType>[] {
        return Warranty.getTypes().map(t => ({
            id: t,
            name: Warranty.getTypeText(t),
        }));
    }

    public static toString(warranty: IWarranty | undefined): string {
        if (!warranty) {
            return "";
        }

        const options = Warranty.getOptions();
        const option = options.find(o => o.id === warranty.type);

        if (!option) {
            return "";
        }

        if (warranty.type === WarrantyType.Inf) {
            return option.name;
        }

        if (!warranty.value || warranty.value.length === 0) {
            return "";
        }

        return `${warranty.value} ${option.name}`;
    }

    /** Получить дату, до которой действует гарантия. */
    public static toDate(warranty: IWarranty | undefined): Date | "infinity" | null {
        if (!warranty) {
            return null;
        }

        switch (warranty.type) {
            case WarrantyType.Month: {
                const val = parseInt(warranty.value);

                if (isNaN(val)) {
                    return null;
                }

                return DateTimeUtils.addMonths(val);
            }
            case WarrantyType.Week: {
                const val = parseInt(warranty.value);

                if (isNaN(val) || val < 0) {
                    return null;
                }

                return DateTimeUtils.addWeeks(val);
            }
            case WarrantyType.Day: {
                const val = parseInt(warranty.value);

                if (isNaN(val) || val < 0) {
                    return null;
                }

                return DateTimeUtils.addDays(val);
            }
            case WarrantyType.Year: {
                const val = parseInt(warranty.value);

                if (isNaN(val) || val < 0) {
                    return null;
                }

                return DateTimeUtils.addYears(val);
            }
            case WarrantyType.Inf:
                return "infinity";
            case WarrantyType.Other:
                return null;
        }

        return null;
    }

    /** Получить максимальную дату, до которой действует гарантия. */
    public static toMaxDate(warranties: (IWarranty | undefined)[]): Date | "infinity" | null {
        let exists = false;
        let max = new Date();

        for (const warranty of warranties) {
            if (!warranty) {
                continue;
            }

            const curr = Warranty.toDate(warranty);

            if (!curr) {
                continue;
            }

            if (curr === "infinity") {
                return "infinity";
            }

            if (curr > max) {
                max = curr;
                exists = true;
            }
        }

        return exists ? max : null;
    }

    /** Получить максимальную дату, до которой действует гарантия, в виде строки. */
    public static toMaxDateString(warranties: (IWarranty | undefined)[]): string {
        const dt = Warranty.toMaxDate(warranties);

        if (!dt) {
            return "-";
        }

        if (dt === "infinity") {
            return "бессрочно";
        }

        return filters.datetime.datetime(dt, "L");
    }
}
