import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { IClientSource, IOffice, IOrderType } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import VaIcon from "@core/components/va-icon/va-icon.vue";
import { OrderBlockClientHandler } from "./order-block-client-handler";

@Component({
    components: { BButton, ControlComponent, VaIcon },
})
export default class OrderCreateClient extends Vue {
    @Prop({ required: true })
    private handler!: OrderBlockClientHandler;

    @Prop({ default: () => null })
    private office!: IOffice | null;

    @Prop({ default: () => null })
    private orderType!: IOrderType | null;

    @Prop({ default: () => [] })
    private clientSources!: IClientSource[];

    @Watch("orderType", { immediate: true, deep: true })
    private onOrderTypeChanged(): void {
        this.initValues();
    }

    @Watch("office", { immediate: true, deep: true })
    private onOfficeChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        this.handler.init(this.office, this.orderType, this.clientSources);
    }

    public deselectClient(): void {
        this.handler.setClient(null);
    }
}
