import { IOffice, IUser } from "@lib";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { IOrderType, IOrderStage, IEmployee } from "@lib";
import VueSelect from "vue-select";
import VaSelect from "@core/components/va-select";

@Component({
    components: {
        VueSelect,
        VaSelect,
    },
})
export default class OrderCreateHeader extends Vue {
    @Prop({ type: Boolean, required: true })
    private show!: boolean;

    @Prop({ type: Array, required: true })
    private offices!: IOffice[];

    @Prop({ type: Object, default: () => null })
    private office!: IOffice | null;

    @Prop({ type: Array, required: true })
    private orderStages!: IOrderStage[];

    @Prop({ type: Array, required: true })
    private orderTypes!: IOrderType[];

    @Prop({ type: Array, required: true })
    private employees!: IEmployee[];

    private user!: IUser;
    private officeCurrent: IOffice | null = null;
    private orderType: IOrderType | null = null;
    private orderStage: IOrderStage | null = null;
    private manager: IEmployee | null = null;

    @Watch("show")
    private onShowChanged(show: boolean): void {
        if (show) {
            this.initValues();
        }
    }

    private async initValues(): Promise<void> {
        this.user = await this.$info.getUser();
        this.officeCurrent = null;
        this.orderType = null;
        this.orderStage = null;

        const officeCurrent = this.office ?? (this.offices?.length > 0 ? this.offices[0] : null);
        this.chageOffice(officeCurrent);
    }

    private chageOffice(office: IOffice | null): void {
        this.officeCurrent = office;

        const orderType = this.getOrderTypeFirst();
        this.changeType(orderType);

        const orderStage = this.getOrderStageFirst();
        this.changeStage(orderStage);

        const manager = this.getManagerFirst();
        this.changeManager(manager);

        this.$emit("change-office", this.officeCurrent);
    }

    private getOrderTypeFirst(): IOrderType | null {
        if (!this.officeCurrent) {
            return null;
        }

        const officeId = this.officeCurrent.id;
        const types = this.orderTypes.filter(t => {
            if (!t.offices || t.offices.length === 0) {
                return true;
            }
            return (t.offices as string[]).includes(officeId);
        });

        return types.length > 0 ? types[0] : null;
    }

    private getOrderStageFirst(): IOrderStage | null {
        if (!this.officeCurrent) {
            return null;
        }

        const officeId = this.officeCurrent.id as string;
        const stages = this.orderStages.filter(s => {
            if (!s.offices || s.offices.length === 0) {
                return true;
            }
            return (s.offices as string[]).includes(officeId);
        });

        return stages.length > 0 ? stages[0] : null;
    }

    private getManagerFirst(): IEmployee | null {
        return this.employees.find(emp => emp.user === this.user.id) ?? null;
    }

    private changeType(type: IOrderType | null): void {
        if (this.orderType === type) {
            return;
        }

        this.orderType = type;
        this.$emit("change-order-type", this.orderType);
    }

    private changeStage(stage: IOrderStage | null): void {
        if (this.orderStage === stage) {
            return;
        }

        this.orderStage = stage;
        this.$emit("change-order-stage", this.orderStage);
    }

    private changeManager(manager: IEmployee | null): void {
        if (this.manager === manager) {
            return;
        }

        this.manager = manager;
        this.$emit("change-order-manager", this.manager);
    }
}
