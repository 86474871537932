
/** Тип поля. */
export enum FieldType {
    /** Текстовое поле. */
    String = "string",

    /** Номер телефона. */
    Phone = "phone",
    
    /** Пароль. */
    Password = "password",

    /** Многострочная текстовая область. */
    Text = "text",

    /** Число. */
    Number = "number",

    /** Дата. */
    Date = "date",

    /** Время. */
    Time = "time",

    /** Дата и время. */
    DateTime = "datetime",

    /** Цвет. */
    Color = "color",

    /** Флажок (галочка). */
    Checkbox = "checkbox",

    /** Выпадающий список. */
    Select = "select",

    /** Выпадающий список с множественным выбором. */
    MultiSelect = "multiselect",

    /** Составное поле (состоит из других полей). */
    Composite = "composite",

    /** Метки. */
    Tags = "tags",

    /** Кнопка. */
    Button = "button",
}
