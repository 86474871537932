import axios, { AxiosInstance } from "axios";
import routerUser from "@/router/router";
import routerAdmin from "@/router/router-admin";

const router = process.env.VUE_APP_ADMIN_MODE ? routerAdmin : routerUser;
const TokenType = "Bearer";

export const ApiBaseUrl = process.env.VUE_APP_API_BASEURL;

function makeAxios(): AxiosInstance {
    const api = axios.create({
        withCredentials: true, // чтобы передавать куки
        baseURL: ApiBaseUrl,

        // headers
    });

    addRequestInterceptor(api);
    addResponseInterceptor(api);
    return api;
}

function makeAxiosForAuth(): AxiosInstance {
    const api = axios.create({
        withCredentials: true, // чтобы передавать куки
        baseURL: ApiBaseUrl,
    });

    addResponseInterceptorAuth(api);
    return api;
}

function makeAxiosExternal(): AxiosInstance {
    return axios.create({
        // headers
    });
}

function addRequestInterceptor(api: AxiosInstance): void {
    api.interceptors.request.use(
        config => {
            const accessToken = router.app.$alt.system.token.getCompanyToken();
            if (accessToken) {
                config.headers.Authorization = `${TokenType} ${accessToken}`;
                return config;
            }

            const userOnlyToken = router.app.$alt.system.token.getUserOnlyToken();
            if (userOnlyToken) {
                config.headers.Authorization = `${TokenType} ${userOnlyToken}`;
                return config;
            }

            const adminToken = router.app.$alt.system.token.getAdminToken();
            if (adminToken) {
                config.headers.Authorization = `${TokenType} ${adminToken}`;
                return config;
            }

            return config;
        },
        error => Promise.reject(error),
    );
}

function addResponseInterceptor(api: AxiosInstance): void {
    api.interceptors.response.use(
        response => response,
        async error => {
            const response = error.response;
            const originalRequest = error.config;

            if (response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true; // повторить refresh только 1 раз

                if (router.app.$alt.system.token.hasCompanyToken()) {
                    const result = await router.app.$alt.system.usecase.createAuthUseCase().refresh();
                    router.app.$alt.system.token.setCompanyToken(result.accessToken);
                } else if (router.app.$alt.system.token.hasUserOnlyToken()) {
                    const result = await router.app.$alt.system.usecase.createUserOnlyUseCase().refresh();
                    router.app.$alt.system.token.setUserOnlyToken(result.accessToken);
                } else if (router.app.$alt.system.token.hasAdminToken()) {
                    const result = await router.app.$alt.system.usecase.createAdminAuthUseCase().refresh();
                    router.app.$alt.system.token.setAdminToken(result.accessToken);
                }

                return api.request(originalRequest);
            } else if (response?.status === 401) {
                router.app.$alt.system.token.cleanData();
                await router.push({ name: "login" }).catch(() => {});
            }
            return Promise.reject(error);
        },
    );
}

function addResponseInterceptorAuth(api: AxiosInstance): void {
    api.interceptors.response.use(
        response => response,
        async error => {
            if (error.response?.status === 401) {
                router.app.$alt.system.token.cleanData();
                await router.push({ name: "login" }).catch(() => {});
            }
            return Promise.reject(error);
        },
    );
}

/** Для обычных вызовов API. */
export const Api = makeAxios();

/** Для вызовов API, связанных с авторизацией. */
export const ApiAuth = makeAxiosForAuth();

/** Для вызовов API, связанных с авторизацией. */
export const External = makeAxiosExternal();
