import { Control, DateRange, MultiSelect } from "@/@core/components/alt-ui/controls";
import { Footer } from "@/@core/controls/footer.control";
import { FilterController } from "@/@core/filters/filter-controller";
import { FilterType, FilterPair } from "@/utils/filter";
import { IEmployee, IOffice, IOrderStage, ITableFilter, IUser } from "@lib";

export type OrdersFilter = Partial<{
    office: string[];
    createdAt: FilterPair;
    doneAt: FilterPair;
    stage: string[];
    manager: string[];
}>;

export interface IOrdersFilterContext {
    offices: IOffice[];
    stages: IOrderStage[];
    employees: IEmployee[];
    defaultFilter: OrdersFilter;
    currentFilter: ITableFilter[];
}

export class OrdersFilterController extends FilterController<IOrdersFilterContext, void> {
    public id: string = "orders-filter";
    public title: string = "Фильтр";

    public filter = {
        office: {
            control: new MultiSelect<IOffice>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.office.control.selectedItems.map(office => office.id);
            },
            set: (ids: string[]): void => {
                this.filter.office.control.selectedItems = this.findItemsByIds(this.context.offices, ids);
            },
        },
        createdAt: {
            control: new DateRange(),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.createdAt.control.value;
            },
            set: (date: FilterPair): void => {
                this.filter.createdAt.control.value = date;
            },
        },
        doneAt: {
            control: new DateRange(),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.doneAt.control.value;
            },
            set: (date: FilterPair): void => {
                this.filter.doneAt.control.value = date;
            },
        },
        stage: {
            control: new MultiSelect<IOrderStage>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.stage.control.selectedItems.map(stage => stage.id);
            },
            set: (ids: string[]): void => {
                this.filter.stage.control.selectedItems = this.findItemsByIds(this.context.stages, ids);
            },
        },
        manager: {
            control: new MultiSelect<IUser>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.manager.control.selectedItems.map(e => e.id);
            },
            set: (ids: string[]): void => {
                this.filter.manager.control.selectedItems = this.findItemsByIds(
                    this.context.employees.map(e => e.userRef),
                    ids,
                );
            },
        },
    };

    private ftFooter!: Footer;

    private context!: IOrdersFilterContext;

    public onSave: ((filter: ITableFilter[]) => Promise<boolean>) | null = null;

    constructor() {
        super();

        this.initControls();
        this.initFooter();
    }

    public init(context: IOrdersFilterContext): void {
        this.context = context;
        this.initControls();
        this.initFooter();
        this.populateFilters();
    }

    public show(context: IOrdersFilterContext): Promise<void> {
        this.context = context;
        this.initControls();
        this.initFooter();
        this.populateFilters();

        return super.show(context);
    }

    // Init

    private initControls(): void {
        this.filter.office.control = new MultiSelect<IOffice>();
        this.filter.office.control.id = this.getControlId("office");
        this.filter.office.control.label = "Филиал";
        this.filter.office.control.items = this.context?.offices ?? [];
        this.filter.office.control.textField = (office: IOffice) => office.info.name;

        this.filter.createdAt.control = new DateRange();
        this.filter.createdAt.control.id = this.getControlId("createdAt");
        this.filter.createdAt.control.label = "Дата создания";

        this.filter.doneAt.control = new DateRange();
        this.filter.doneAt.control.id = this.getControlId("doneAt");
        this.filter.doneAt.control.label = "Дата завершения";

        this.filter.stage.control = new MultiSelect<IOrderStage>();
        this.filter.stage.control.id = this.getControlId("stage");
        this.filter.stage.control.label = "Этап";
        this.filter.stage.control.items = this.context?.stages ?? [];
        this.filter.stage.control.textField = (stage: IOrderStage) => stage.name;

        this.filter.manager.control = new MultiSelect<IUser>();
        this.filter.manager.control.id = this.getControlId("manager");
        this.filter.manager.control.label = "Сотрудник";
        this.filter.manager.control.items = this.context?.employees.map(e => e.userRef) ?? [];
        this.filter.manager.control.textField = e => e.info.name;
    }

    private initFooter(): void {
        this.ftFooter = new Footer({
            okText: "Сохранить",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateFilters(): void {
        if (!this.context.currentFilter) {
            return;
        }

        const filters = this.getLocalFilter(this.context.currentFilter);

        Object.entries(filters).forEach(([k, filter]) => {
            const key = k as keyof OrdersFilter;

            if (!this.filter[key]) {
                return;
            }

            this.filter[key].set(filter as any);
        });
    }

    public get controls(): Control[] {
        return Object.entries(this.filter).map(([_, filter]) => filter.control);
    }

    public get footer(): Control {
        return this.ftFooter;
    }

    // /Init

    public get tableFilter(): ITableFilter[] {
        return this.getTableFilter(this.filter);
    }

    public async clickSave(): Promise<void> {
        if (!this.onSave) {
            return;
        }

        const result = await this.onSave(this.tableFilter);

        if (result) {
            this.hide();
        }
    }

    public clickCancel(): void {
        this.hide();
    }
}
