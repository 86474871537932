import { IAccountUseCase, IAccount, ITransferCreateDto, ITransaction } from "@lib";
import { IAccountCreateDto, IAccountUpdateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class AccountUseCase implements IAccountUseCase {
    public async select(companyId: string): Promise<IAccount[]> {
        try {
            const response = await Api.get<IAccount[]>(`/companies/${companyId}/accounts`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IAccount> {
        try {
            const response = await Api.get<IAccount>(`/companies/${companyId}/accounts/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IAccountCreateDto): Promise<IAccount> {
        try {
            const response = await Api.post<IAccount>(`/companies/${companyId}/accounts/`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async transfer(companyId: string, dto: ITransferCreateDto): Promise<ITransaction[] | null> {
        try {
            const response = await Api.put<ITransaction[] | null>(`/companies/${companyId}/accounts/transfer`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IAccountUpdateDto): Promise<IAccount> {
        try {
            const response = await Api.put<IAccount>(`/companies/${companyId}/accounts/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/accounts/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
