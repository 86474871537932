/** Тип группы полей для типа заявки. */
export enum OrderTypeFieldGroupType {
    /** Описание. */
    Info = "info",

    /** Клиент. */
    Client = "client",

    /** Продукт (устройство). */
    Product = "product",

    /** Оплата. */
    Payment = "payment",
}
