















































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "va-icon" })
export default class VaIcon extends Vue {
    @Prop({ type: String })
    private icon!: string;

    private click(): void {
        this.$emit("click");
    }
}
