import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import VueSelect from "vue-select";
import { ValidationObserver } from "vee-validate";
import { IOrder, IClient, IClientSource, IClientUpdateDto, FieldType, IReadQuery } from "@lib";
import { IOrderType, IOrderTypeField, IOrderTypeFieldGroup, OrderTypeFieldGroupType } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import VaIcon from "@core/components/va-icon/va-icon.vue";
import { FieldControl } from "@core/types/field-controls/field-control";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { Formatter } from "@/utils/formatter";
import { DelayedOperation } from "@/utils/delayed-operation";
import { AppException } from "@/exceptions";

@Component({
    components: {
        BButton,
        VueSelect,
        ValidationObserver,
        ControlComponent,
        VaIcon,
    },
})
export default class OrderViewClientExisting extends Vue {
    @Prop({ required: true })
    private order!: IOrder;

    @Prop({ required: true })
    private orderType!: IOrderType;

    @Prop({ type: Array, required: true })
    private clientSources!: IClientSource[];

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private visible = true;
    private editMode = false;
    private clientCurrent: IClient | null = null;
    private fieldGroup: IOrderTypeFieldGroup | null = null;
    private controls: FieldControl[] = [];

    private ClientUseCase = this.$alt.system.usecase.createClientUseCase();
    private clients: IClient[] = [];
    private search = "";

    private get title(): string {
        return this.fieldGroup?.title ?? "Клиент";
    }

    private get icon(): string {
        return this.fieldGroup?.icon ?? "user";
    }

    @Watch("order", { immediate: true, deep: true })
    private onOrderChanged(): void {
        this.initValues();
    }

    @Watch("orderType", { immediate: true, deep: true })
    private onOrderTypeChanged(): void {
        this.initValues();
    }

    private async initValues(): Promise<void> {
        if (!this.order || !this.orderType) {
            return;
        }

        this.editMode = false;
        this.clientCurrent = this.order.clientRef ? this.$alt.clone(this.order.clientRef) : null;
        this.fieldGroup = this.orderType.groups.find(field => field.id === OrderTypeFieldGroupType.Client) ?? null;
        this.visible = this.getFields().length > 0;

        this.initControls();
    }

    private initControls(): void {
        this.controls = [];

        const context = {
            locale: this.order.officeRef?.info?.locale,
            clientSources: this.clientSources,
        };

        for (const field of this.getFields()) {
            // TODO: у старых полей телефона был тип string
            if (field.id === "client.phone") {
                field.type = FieldType.Phone;
            }

            const fieldControl = FieldControlFactory.fromField(field, context);
            fieldControl.value = this.clientCurrent ? this.getFieldValue(this.clientCurrent, field) : null;
            this.controls.push(fieldControl);
        }
    }

    public getFields(): IOrderTypeField[] {
        return (
            this.fieldGroup?.fields
                .filter(f => !f.hidden)
                .sort((a, b) =>
                    a.sequence !== undefined && b.sequence !== undefined ? a.sequence - b.sequence : -1,
                ) ?? []
        );
    }

    public getFieldValue(client: IClient, field: IOrderTypeField): any {
        if (!client) {
            return "";
        }

        if (field.custom) {
            // TODO: remove details1
            const id = field.customId ?? field.details1 ?? field.id;
            return client.custom ? client.custom[id] : "";
        }

        return this.getStandartFieldValue(client, field);
    }

    private getStandartFieldValue(client: IClient, field: IOrderTypeField): any {
        switch (field.id) {
            case "client.type":
                return client.info?.type;
            case "client.name":
                return client.info?.name;
            case "client.description":
                return client.info?.description;
            case "client.taxId":
                return client.info?.taxId;
            case "client.email":
                return client.info?.contacts?.email;
            case "client.phone": {
                return client.info?.contacts?.phone;
            }
            case "client.source":
                return client.info?.source;
            case "client.comment":
                return client.comment;
        }

        return "";
    }

    private createDto(): IClientUpdateDto {
        if (!this.clientCurrent) {
            throw new AppException("Клиент не задан.");
        }

        const client = this.$alt.clone(this.clientCurrent);

        for (const fieldControl of this.controls) {
            const value = fieldControl.value;
            this.populateCustomFieldValue(client, fieldControl.field, value);
            this.populateStandartFieldValue(client, fieldControl.field, value);
        }

        return {
            ...client.info,
            comment: client.comment,
            custom: client.custom,
        };
    }

    private populateCustomFieldValue(client: IClient, field: IOrderTypeField, value: any): void {
        if (field.custom) {
            if (!client.custom) {
                client.custom = {};
            }

            // TODO: remove details1
            const id = field.customId ?? field.details1 ?? field.id;
            client.custom[id] = value;
            return;
        }
    }

    private populateStandartFieldValue(client: IClient, field: IOrderTypeField, value: any): void {
        if (!client.info) {
            client.info = {} as any;
        }

        switch (field.id) {
            case "client.type":
                client.info.type = value;
                return;
            case "client.name":
                client.info.name = value;
                return;
            case "client.description":
                client.info.description = value;
                return;
            case "client.taxId":
                client.info.taxId = value;
                return;
            case "client.email":
                client.info.contacts.email = value;
                return;
            case "client.phone":
                client.info.contacts.phone = value;
                return;
            case "client.source":
                client.info.source = value;
                return;
            case "client.comment":
                client.comment = value;
                return;
        }
    }

    private startEditClient(): void {
        this.initControls();
        this.editMode = true;
    }

    private async applyEditClient(): Promise<void> {
        try {
            if (!this.order) {
                throw new AppException("Заявка не задана.");
            }

            if (!this.clientCurrent) {
                throw new AppException("Клиент не задан.");
            }

            const companyId = this.order.company as string;
            const orderId = this.order.id;
            const clientId = this.clientCurrent.id;

            const dto = this.createDto();
            await this.ClientUseCase.update(companyId, clientId, dto);

            const usecase = this.$alt.system.usecase.createOrderUseCase();

            // если клиент не менялся, надо заново загрузить заявку
            // в противном случае - поменять клиента

            const order =
                this.order.clientRef?.id === this.clientCurrent.id
                    ? await usecase.get(companyId, orderId)
                    : await usecase.changeClient(companyId, orderId, clientId);

            this.editMode = false;
            this.$emit("order-changed", order);
        } catch {
            this.$alt.toast.error("Не удалось изменить клиента.");
        }
    }

    private cancelEditClient(): void {
        this.initValues();
    }

    private changeClient(client: IClient): void {
        this.clientCurrent = client;
        this.initControls();
    }

    private formatPhone(value: string): string {
        return Formatter.phone(value);
    }

    private searchClient(search: string, loading: Function): void {
        this.search = search;

        // начинать поиск от 2 символов
        if (search.trim().length > 1) {
            DelayedOperation.invoke("search", 750, async () => {
                if (this.search.length === 0) {
                    return;
                }

                loading(true);
                this.clients = await this.searchClients(this.search);
                loading(false);
            });
        }
    }

    private async searchClients(search: string): Promise<IClient[]> {
        try {
            if (!this.order) {
                return [];
            }

            const companyId = this.order.company as string;
            const limit: number = 10;
            const query: IReadQuery = { search, limit, offset: 0 };
            const result = await this.ClientUseCase.select(companyId, query);
            return result.data;
        } catch (e: any) {
            return [];
        }
    }
}
