import { ICompanyPaymentUseCase, ICompanyPayment } from "@lib";
import { ISelectedData, ISelectQuery } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class CompanyPaymentUseCase implements ICompanyPaymentUseCase {
    public async select(companyId: string, query?: ISelectQuery): Promise<ISelectedData<ICompanyPayment>> {
        try {
            const params = query;
            const response = await Api.get<ISelectedData<ICompanyPayment>>(`/companies/${companyId}/payments`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
