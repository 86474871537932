import { IWarranty, WarrantyType } from "@lib";
import { ComboBox, Label, Panel, TextBox } from "../components/alt-ui/controls";
import { Warranty } from "../types/common/warranty";

export class WarrantyControl extends Panel {
    public getComponentName(): string {
        return "PanelComponent";
    }

    private _label = "";
    public get label(): string {
        return this._label;
    }
    public set label(label: string) {
        this._label = label;
        this.lbLabel.text = label;
    }

    public get value(): IWarranty {
        const type = this.cbType.selectedItem ?? WarrantyType.Month;
        return {
            value: type === WarrantyType.Inf ? "" : this.tbValue.text,
            type: type,
        };
    }
    public set value(warranty: IWarranty | undefined) {
        if (warranty) {
            this.tbValue.text = warranty.value;
            this.cbType.selectedIndex = this.cbType.items.findIndex(i => i === warranty.type);
        } else {
            this.tbValue.text = "";
            this.cbType.selectedIndex = 0;
        }
    }

    private lbLabel: Label;
    private pnPanel: Panel;
    private tbValue: TextBox;
    private cbType: ComboBox<WarrantyType>;

    public constructor() {
        super();

        this.lbLabel = new Label();
        this.lbLabel.id = "warranty.label";
        this.lbLabel.text = this.label;
        this.lbLabel.class = "text-sm ml-0.5";

        this.tbValue = new TextBox();
        this.tbValue.id = "warranty.value";
        this.tbValue.class = "w-66% mr-0.5";

        this.cbType = new ComboBox<WarrantyType>();
        this.cbType.id = "warranty.type";
        this.cbType.items = Warranty.getTypes();
        this.cbType.textField = Warranty.getTypeText;
        this.cbType.selectedItem = WarrantyType.Month;
        this.cbType.class = "w-33%";
        this.cbType.addChangedHandler(this.onTypeChanged.bind(this));

        this.pnPanel = new Panel();
        this.pnPanel.id = "warranty.controls";
        this.pnPanel.class = "flex";
        this.pnPanel.addControl(this.tbValue);
        this.pnPanel.addControl(this.cbType);

        this.addControl(this.lbLabel);
        this.addControl(this.pnPanel);
    }

    private onTypeChanged(sender: any, e: any): void {
        if (e.item.id === WarrantyType.Inf) {
            this.tbValue.disabled = true;
            this.tbValue.text = "";
        } else {
            this.tbValue.disabled = false;
        }
    }
}
