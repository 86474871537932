import { IAdminCompanyUseCase, IAdminCompanyInfo, ICompanyPayment } from "@lib";
import { ISelectedData, ISelectQuery } from "@lib";
import { ICompanyPaymentCreateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class AdminCompanyUseCase implements IAdminCompanyUseCase {
    public async select(options?: ISelectQuery): Promise<ISelectedData<IAdminCompanyInfo>> {
        try {
            const params = options;
            const response = await Api.get<ISelectedData<IAdminCompanyInfo>>("/admin/companies", { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(id: string): Promise<void> {
        try {
            await Api.delete<void>(`/admin/companies/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async payment(companyId: string, dto: ICompanyPaymentCreateDto): Promise<ICompanyPayment> {
        try {
            const response = await Api.post<ICompanyPayment>(`/admin/companies/${companyId}/payments`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async export(query?: ISelectQuery): Promise<Blob> {
        try {
            const params = query;
            const response = await Api.get<Blob>(`/admin/companies/actions/export`, {
                params,
                responseType: "blob",
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
