

















import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BNavbar } from "bootstrap-vue";
import { Control } from "@core/components/alt-ui/controls";
import NotificationDropdown from "./notification-dropdown.vue";
import ProfileDropdown from "./profile-dropdown.vue";

@Component({
    name: "header-navbar",
    components: {
        BNavbar,
        //NotificationDropdown,
        ProfileDropdown,
        ControlComponent: () => import("@core/components/alt-ui/controls/control.component"),
    },
})
export default class HeaderNavbar extends Vue {
    private color = "#fff";
    private controls: Control[] = [];

    public mounted(): void {
        this.$info.ui.addHeaderControlsChangedHandler(() => {
            this.controls = this.$info.ui.getHeaderControls();
        });
    }

    private showSidebar(): void {
        this.$info.ui.openSidebar();
    }
}
