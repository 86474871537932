import moment from "moment";
import {
    Currency,
    DiscountType,
    DocumentType,
    LegalType,
    Locale,
    OrderNumeration,
    OrderStageType,
    SaleNumeration,
    SaleStageType,
    SmsType,
    TemplateLabelType,
    TransactionItemTag,
    TransactionType,
    WarrantyType,
} from "@lib";
import { Localizer } from "@/i18n/localizer";
import { DateTimeUtils } from "@/utils/datetime.utils";

export interface ISelectOption<T = string> {
    id: T;
    name: string;
    description?: string;
}

export function getClientTypes(): ISelectOption[] {
    return [
        { id: LegalType.Natural, name: "Физическое лицо" },
        { id: LegalType.Juridical, name: "Юридическое лицо" },
    ];
}

export function getDiscountTypes(): ISelectOption[] {
    return [
        { id: DiscountType.Percent, name: "%" },
        { id: DiscountType.Fixed, name: "фикс." },
    ];
}

export function getDocumentTypes(): ISelectOption[] {
    return [
        { id: DocumentType.OfficeGeneral, name: "Документ филиала" },
        { id: DocumentType.ShopDocument, name: "Документ магазина" },
    ];
}

export function getSmsTypes(): any[] {
    return [{ id: SmsType.SmsGeneral, name: "СМС" }];
}

export function getTemplateLabelTypes(): ISelectOption<TemplateLabelType>[] {
    return [{ id: TemplateLabelType.GoodLabel, name: "Ценник товара" }];
}

export function getCurrencies(): ISelectOption[] {
    return [
        { id: Currency.RUB, name: "Рубль (РФ)" },
        { id: Currency.BYN, name: "Рубль (РБ)" },
        { id: Currency.UAH, name: "Гривна (Украина)" },
        { id: Currency.KZT, name: "Тенге (Казахстан)" },
        { id: Currency.GEL, name: "Лари (Грузия)" },
        { id: Currency.AMD, name: "Драм (Армения)" },
        { id: Currency.AZN, name: "Манат (Азербайджан)" },
        { id: Currency.KGS, name: "Сом (Киргизия)" },
        { id: Currency.TJS, name: "Сомони (Таджикистан)" },
        { id: Currency.TMT, name: "Манат (Туркменистан)" },
        { id: Currency.UZS, name: "Сум (Узбекистан)" },
        { id: Currency.MDL, name: "Лей (Молдавия)" },
        { id: Currency.USD, name: "Доллар (США)" },
        { id: Currency.EUR, name: "Евро (ЕС)" },
    ];
}

export function getLocales(): ISelectOption[] {
    return [
        {
            id: Locale.RU,
            name: `Русский (${Locale.RU})`,
            description: `Пример: ${Localizer.currency(12345.67, Currency.RUB, Locale.RU)}`,
        },
        {
            id: Locale.EN,
            name: `Английский (${Locale.EN})`,
            description: `Пример: ${Localizer.currency(12345.67, Currency.USD, Locale.EN)}`,
        },
    ];
}

export function getOrderNumerations(): ISelectOption[] {
    return [
        {
            id: OrderNumeration.Office,
            name: "По филиалам",
            description: "В каждом филиале своя нумерация заявок",
        },
        {
            id: OrderNumeration.Company,
            name: "По компании",
            description: "Единая нумерация заявок для всех филиалов компании",
        },
    ];
}

export function getSaleNumerations(): ISelectOption[] {
    return [
        {
            id: SaleNumeration.Shop,
            name: "По магазинам",
            description: "В каждом магазине своя нумерация продаж",
        },
        {
            id: SaleNumeration.Company,
            name: "По компании",
            description: "Единая нумерация продаж для всех магазинов компании",
        },
    ];
}

export function getOrderStageTypes(): ISelectOption<string | undefined>[] {
    return [
        { id: undefined, name: "<не указан>" },
        { id: OrderStageType.New, name: "ТОЛЬКО ЧТО СОЗДАННЫЕ ЗАЯВКИ" },
        { id: OrderStageType.InWork, name: "ЗАЯВКИ В РАБОТЕ" },
        { id: OrderStageType.Deferred, name: "ОТЛОЖЕННЫЕ ЗАЯВКИ" },
        { id: OrderStageType.Ready, name: "ГОТОВЫЕ ЗАЯВКИ" },
        { id: OrderStageType.Closed, name: "ЗАКРЫТЫЕ ЗАЯВКИ" },
        { id: OrderStageType.Canceled, name: "ОТМЕНЁННЫЕ ЗАЯВКИ" },
    ];
}

export function getSaleStageTypes(): ISelectOption<SaleStageType | null>[] {
    return [
        { id: null, name: "<не указан>" },
        { id: SaleStageType.New, name: "ТОЛЬКО ЧТО СОЗДАННЫЕ ПРОДАЖИ" },
        { id: SaleStageType.Processing, name: "ПРОДАЖИ В ОБРАБОТКЕ" },
        { id: SaleStageType.Delivery, name: "ПРОЖАДИ В ДОСТАВКЕ" },
        { id: SaleStageType.Ready, name: "ГОТОВЫЕ К ВЫДАЧЕ ПРОДАЖИ" },
        { id: SaleStageType.Closed, name: "ЗАКРЫТЫЕ ПРОДАЖИ" },
        { id: SaleStageType.Return, name: "ВОЗВРАЩЁННЫЕ ПРОДАЖИ" },
        { id: SaleStageType.Canceled, name: "ОТМЕНЁННЫЕ ПРОДАЖИ" },
    ];
}

export function getTransactionType(): ISelectOption<TransactionType>[] {
    return [
        { id: TransactionType.Income, name: "Доход" },
        { id: TransactionType.Expense, name: "Расход" },
    ];
}

export function getTransactionItemTag(): ISelectOption<TransactionItemTag>[] {
    return [
        { id: TransactionItemTag.Prepayment, name: "Предоплата заявки/продажи" },
        { id: TransactionItemTag.Payment, name: "Оплата заявки/продажи" },
        { id: TransactionItemTag.Refund, name: "Возврат средств" },
        { id: TransactionItemTag.Salary, name: "Зарплата сотрудника" },
        { id: TransactionItemTag.Fine, name: "Штраф сотрудника" },
        { id: TransactionItemTag.SupplierExpenses, name: "Расходы на товары/материалы" },
        { id: TransactionItemTag.TransferIncome, name: "Входящий перевод" },
        { id: TransactionItemTag.TransferExpense, name: "Исходящий перевод" },
        { id: TransactionItemTag.OtherIncomes, name: "Прочие доходы" },
        { id: TransactionItemTag.OtherExpenses, name: "Прочие расходы" },
    ];
}

//

export enum Period {
    Today = "today",
    Yesterday = "yesterday",
    Week = "week",
    Month = "month",
    Custom = "custom",
}

export type PeriodArray = [string, string];

export function getPeriods(): ISelectOption<Period>[] {
    return [
        { id: Period.Today, name: "Сегодня" },
        { id: Period.Yesterday, name: "Вчера" },
        { id: Period.Week, name: "Неделя" },
        { id: Period.Month, name: "Месяц" },
        { id: Period.Custom, name: "Выбрать даты" },
    ];
}

export function getPeriodDates(period: Period): PeriodArray | null {
    let from: string = "";
    let to: string = "";

    switch (period) {
        case Period.Today:
            from = moment().format("YYYY-MM-DD");
            return [from, from];
        case Period.Yesterday:
            from = moment(DateTimeUtils.yesterday()).format("YYYY-MM-DD");
            return [from, from];
        case Period.Week:
            from = moment(DateTimeUtils.weekAgo(+1)).format("YYYY-MM-DD");
            to = moment().format("YYYY-MM-DD");
            return [from, to];
        case Period.Month:
            from = moment(DateTimeUtils.monthAgo(+1)).format("YYYY-MM-DD");
            to = moment().format("YYYY-MM-DD");
            return [from, to];
    }

    return null;
}
