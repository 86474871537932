import { IOrderTask, IOrderTaskCreateDto, IOrderTaskUpdateDto, IEmployee, IUser } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, IconPackage, Panel, Select, TextArea, TextBox } from "@core/components/alt-ui/controls";

export interface IOrderTaskModalContext {
    task?: IOrderTask;
    employees: IEmployee[];
    user: IUser;
}

type EmployeeWrp = {
    employee: IEmployee | null;
};

export class OrderTaskModal extends Modal<IOrderTaskModalContext> {
    private tbTitle!: TextBox;
    private taDescription!: TextArea;
    private cbEmployee!: Select<EmployeeWrp>;

    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private context: IOrderTaskModalContext | null = null;

    public onCreate: ((dto: IOrderTaskCreateDto) => Promise<boolean>) | null = null;
    public onUpdate: ((orig: IOrderTask, dto: IOrderTaskUpdateDto) => Promise<boolean>) | null = null;

    public constructor() {
        super("order-task-modal", "");
        this.initializeControls();
    }

    public show(context: IOrderTaskModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();

        if (context.task) {
            this.title = "Изменение задачи";
            this.btnSave.text = "Изменить";
            this.populateControls(context.task);
        } else {
            this.title = "Добавление задачи";
            this.btnSave.text = "Добавить";
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbTitle = new TextBox();
        this.tbTitle.id = "task.name";
        this.tbTitle.label = "Название";
        this.tbTitle.validation = "required";

        this.taDescription = new TextArea();
        this.taDescription.id = "task.description";
        this.taDescription.label = "Описание";

        this.cbEmployee = new Select<EmployeeWrp>();
        this.cbEmployee.id = "task.employee";
        this.cbEmployee.label = "Исполнитель";
        this.cbEmployee.items = this.makeEmployeeSelectItems(this.context?.employees ?? []);
        this.cbEmployee.textField = e => e.employee?.userRef.info.name ?? "<Не указан>";
        this.cbEmployee.descriptionField = e => e.employee?.position;
        this.cbEmployee.iconPackage = IconPackage.Feater;
        this.cbEmployee.icon = "UserIcon";
        this.cbEmployee.selectedIndex = this.cbEmployee.items.findIndex(
            e => e.employee?.user === this.context?.user.id,
        );

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "task.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "task.save";
        this.btnSave.text = "Добавить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(task: IOrderTask): void {
        this.tbTitle.text = task.title;
        this.taDescription.text = task.description ?? "";

        this.cbEmployee.selectedIndex = this.cbEmployee.items.findIndex(e => {
            const userId = e.employee?.user ?? null;
            return userId === task.employee;
        });
    }

    private makeEmployeeSelectItems(employees: IEmployee[]): EmployeeWrp[] {
        const items: EmployeeWrp[] = [];

        items.push({
            employee: null,
        });

        items.push(
            ...employees.map(e => ({
                employee: e,
            })),
        );

        return items;
    }

    public get controls(): Control[] {
        return [this.tbTitle, this.taDescription, this.cbEmployee, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.context?.task ? await this.updateTask(this.context.task) : await this.createTask();

        if (result) {
            this.hide(result);
        }
    }

    private async createTask(): Promise<boolean> {
        if (!this.onCreate) {
            return false;
        }

        if (!this.cbEmployee.selectedItem) {
            return false;
        }

        const dto: IOrderTaskCreateDto = {
            title: this.tbTitle.text,
            description: this.taDescription.text,
            employee: (this.cbEmployee.selectedItem.employee?.user as string) ?? null,
        };

        return await this.onCreate(dto);
    }

    private async updateTask(orig: IOrderTask): Promise<boolean> {
        if (!this.onUpdate) {
            return false;
        }

        if (!this.cbEmployee.selectedItem) {
            return false;
        }

        const dto: IOrderTaskUpdateDto = {
            title: this.tbTitle.text,
            description: this.taDescription.text,
            employee: (this.cbEmployee.selectedItem.employee?.user as string) ?? null,
        };

        return await this.onUpdate(orig, dto);
    }
}
