const Colors = {
    // цвет самой линии
    line: ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#00FFFF", "#FF00FF"],

    // цвет линии кружочка
    markerStroke: ["#00FF00", "#0000FF", "#FFFF00", "#00FFFF", "#FF00FF", "#FF0000"],

    // цвет внутри кружочка
    markerFill: ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#00FFFF", "#FF00FF"],
};

type Formatter = (x: any) => string;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getChartOptions(categories: string[], formatter: Formatter = x => x) {
    return {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        markers: {
            strokeWidth: 5,
            strokeOpacity: 1,
            strokeColors: Colors.markerStroke,
            colors: Colors.markerFill,
        },
        colors: Colors.line,
        // https://apexcharts.com/docs/options/plotoptions/bar/
        plotOptions: {
            bar: {
                columnWidth: "75%",
                distributed: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        // tooltip: {
        //     custom(data: any) {
        //         return `${"<div class=\"px-1 py-50\"><span>"}${
        //             data.series[data.seriesIndex][data.dataPointIndex]
        //         }%</span></div>`;
        //     },
        // },
        xaxis: {
            categories,
            labels: {
                style: {
                    fontSize: "1.25rem",
                },
            },
        },
        yaxis: {
            // opposite: isRtl,
            labels: {
                style: {
                    fontSize: "1.25rem",
                },
                formatter: formatter,
            },
        },
        legend: {
            fontSize: "16px", //"1.25rem",
        },
    };
}
