import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";

@Component({
    name: "reports-view-chart",
    components: { VueApexCharts },
})
export default class ReportsViewChart extends Vue {
    @Prop({ required: true })
    private chart!: any;
}
