/** Тип оплаты плагина. */
export enum PluginPriceType {
    /** Бесплатно. */
    Free = "free",

    /** Ежемесячно. */
    Monthly = "monthly",

    /** Кажое использование (может варьироваться). */
    Usage = "usage",
}
