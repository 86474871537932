import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import App from "./App.vue";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Material Icons
import "material-icons/iconfont/material-icons.css";

// собственные плагины
import { AltPluginSetInstaller } from "./utils/plugins";
Vue.use(AltPluginSetInstaller);

// Theme Configurations
import "../themeConfig";

// Globally Registered Components
import "./globalComponents";

// old Styles: SCSS
import "./assets/scss/main.scss";

// import core styles
import "@core/scss/core.scss";

// import assets styles
import "@/assets/scss/style.scss";

// Vue Router
import router from "./router/router-admin";

// i18n
import i18n from "./i18n/i18n";

// Vuexy Admin Filters
import "./filters/vue";

// VeeValidate
import VeeValidate from "vee-validate";
import configurate from "@/utils/validation";
Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
configurate(VeeValidate, i18n.locale);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer as any);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
import "./assets/css/iconfont.css";

Vue.config.productionTip = false;

new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount("#app");
