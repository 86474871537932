import {
    IStore,
    IOrderMaterial,
    IOrderWork,
    IAccount,
    IOrderMaterialCreateDto,
    IOrderMaterialPaymentDto,
    IOrderMaterialUpdateDto,
    Locale,
} from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    CheckBox,
    CheckBoxValueChangedEventArgs,
    Control,
    IconPackage,
    Panel,
    Select,
    TextArea,
    TextBox,
} from "@core/components/alt-ui/controls";
import { WarrantyBox } from "@core/controls/warranty-box";
import { Localizer } from "@/i18n/localizer";
import { OrderMaterialModalSettings } from "@/utils/plugins/settings.plugin";

type OrderWorkRef = { text: string; ref?: IOrderWork };

export interface IMaterialModalContext {
    material?: IOrderMaterial;
    accounts: IAccount[];
    stores: IStore[];
    works: IOrderWork[];
    readonly: boolean;
    settings?: OrderMaterialModalSettings;
}

export class MaterialModal extends Modal<IMaterialModalContext> {
    private tbSku!: TextBox;
    private tbName!: TextBox;
    private taDescription!: TextArea;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    private tbQuantity!: TextBox;
    private wbWarranty!: WarrantyBox;
    private cbWork!: Select<OrderWorkRef>;

    private chbEnableAccountWithdrawal!: CheckBox;
    private cbAccount!: Select<IAccount>;
    private chbEnableStoreAddition!: CheckBox;
    private cbStore!: Select<IStore>;
    private pnlOnlyCreate!: Panel;

    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private context: IMaterialModalContext | null = null;

    public onCreate: ((dto: IOrderMaterialCreateDto) => Promise<boolean>) | null = null;
    public onUpdate: ((orig: IOrderMaterial, dto: IOrderMaterialUpdateDto) => Promise<boolean>) | null = null;

    public constructor() {
        super("material-modal", "");
        this.initializeControls();
    }

    public show(context: IMaterialModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();

        if (context.material) {
            this.title = "Изменение материала";
            this.btnSave.text = "Изменить";
            this.populateControls(context.material);
        } else {
            this.title = "Новый материал";
            this.btnSave.text = "Добавить";
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbSku = new TextBox();
        this.tbSku.id = "material.sku";
        this.tbSku.label = "Артикул";

        this.tbName = new TextBox();
        this.tbName.id = "material.name";
        this.tbName.label = "Наименование";
        this.tbName.validation = "required";

        this.taDescription = new TextArea();
        this.taDescription.id = "material.description";
        this.taDescription.label = "Описание";

        this.tbCost = new TextBox();
        this.tbCost.id = "material.cost";
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "required|money|unsigned";

        this.tbPrice = new TextBox();
        this.tbPrice.id = "material.price";
        this.tbPrice.label = "Цена продажи";
        this.tbPrice.validation = "required|money|unsigned";

        this.tbQuantity = new TextBox();
        this.tbQuantity.id = "material.quantity";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = `required|numeric|unsigned|min_value:1|max_value:${Number.MAX_VALUE}`;
        this.tbQuantity.text = "1";

        this.wbWarranty = new WarrantyBox();
        this.wbWarranty.id = "material.warranty";
        this.wbWarranty.label = "Гарантия";

        const works: OrderWorkRef[] = this.context?.works.map(w => ({ text: w.name, ref: w })) ?? [];
        works.unshift({ text: "-- Не привязано --", ref: undefined });

        this.cbWork = new Select<OrderWorkRef>();
        this.cbWork.id = "material.work";
        this.cbWork.label = "Привязать к работе";
        this.cbWork.items = works;
        this.cbWork.textField = w => w.text;
        this.cbWork.iconPackage = IconPackage.Feater;
        this.cbWork.icon = "ToolIcon";
        this.cbWork.selectedIndex = 0;
        this.cbWork.class = "mb-1";

        //

        const selectedAccountId = this.context?.settings?.accountId;
        const selectedAccountIndex = this.context?.accounts?.findIndex(a => a.id === selectedAccountId) ?? -1;
        const selectedAccountIndexDefault = (this.context?.accounts ?? []).length > 0 ? 0 : -1;

        this.cbAccount = new Select<IAccount>();
        this.cbAccount.id = "material.account";
        this.cbAccount.label = "Счёт";
        this.cbAccount.items = this.context?.accounts ?? [];
        this.cbAccount.textField = a => a.info.name;
        this.cbAccount.descriptionField = a => a.info.description;
        this.cbAccount.iconPackage = IconPackage.Alt;
        this.cbAccount.icon = "finance";
        this.cbAccount.selectedIndex = selectedAccountIndex >= 0 ? selectedAccountIndex : selectedAccountIndexDefault;
        this.cbAccount.class = "mb-1";

        const accountWidthdrawalChecked =
            this.context?.settings?.accountWidthdrawal !== undefined ? this.context.settings.accountWidthdrawal : true;

        this.chbEnableAccountWithdrawal = new CheckBox();
        this.chbEnableAccountWithdrawal.id = "material.enable-account-withdrawal";
        this.chbEnableAccountWithdrawal.text = "Вычесть со счёта";
        this.chbEnableAccountWithdrawal.addValueChangedHandler(this.changeEnableAccountWithdrawal.bind(this));
        this.chbEnableAccountWithdrawal.value = accountWidthdrawalChecked;

        const storeAdditionChecked =
            this.context?.settings?.storeAddition !== undefined ? this.context.settings.storeAddition : false;

        const selectedStoreId = this.context?.settings?.storeId;
        const selectedStoreIndex = this.context?.stores?.findIndex(a => a.id === selectedStoreId) ?? -1;
        const selectedStoreIndexDefault = (this.context?.stores ?? []).length > 0 ? 0 : -1;

        this.cbStore = new Select<IStore>();
        this.cbStore.id = "material.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = s => s.info.name;
        this.cbStore.descriptionField = s => s.info.description;
        this.cbStore.iconPackage = IconPackage.Feater;
        this.cbStore.icon = "PackageIcon";
        this.cbStore.selectedIndex = selectedStoreIndex >= 0 ? selectedStoreIndex : selectedStoreIndexDefault;
        this.cbStore.visible = storeAdditionChecked;

        this.chbEnableStoreAddition = new CheckBox();
        this.chbEnableStoreAddition.id = "material.enable-store-addition";
        this.chbEnableStoreAddition.text = "Добавить на склад";
        this.chbEnableStoreAddition.addValueChangedHandler(this.changeEnableStoreAddition.bind(this));
        this.chbEnableStoreAddition.value = storeAdditionChecked;

        this.pnlOnlyCreate = new Panel();
        this.pnlOnlyCreate.addControls([
            this.chbEnableAccountWithdrawal,
            this.cbAccount,
            this.chbEnableStoreAddition,
            this.cbStore,
        ]);

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "material.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "material.save";
        this.btnSave.text = "Добавить";
        this.btnSave.class = "ml-0.75";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(material: IOrderMaterial): void {
        const locale = material.storeRef?.info.locale ?? Locale.RU;

        this.tbSku.text = material.sku ?? "";
        this.tbName.text = material.name;
        this.taDescription.text = material.description ?? "";
        this.tbCost.text = Localizer.number(material.cost, locale);
        this.tbPrice.text = Localizer.number(material.price, locale);
        this.tbQuantity.text = material.quantity.toString();
        this.wbWarranty.value = material.warranty;

        if (material.work) {
            const index = this.cbWork.items.findIndex(i => i.ref?.id === material.work);
            this.cbWork.selectedIndex = index;
        }

        this.pnlOnlyCreate.visible = false;

        if (this.context?.readonly) {
            this.tbSku.disabled = true;
            this.tbName.disabled = true;
            this.taDescription.disabled = true;
            this.tbCost.disabled = true;
            this.tbPrice.disabled = true;
            this.tbQuantity.disabled = true;
            this.wbWarranty.disabled = true;
            this.cbWork.disabled = true;
            this.chbEnableAccountWithdrawal.disabled = true;
            this.cbAccount.disabled = true;
            this.chbEnableStoreAddition.disabled = true;
            this.cbStore.disabled = true;
            this.btnSave.visible = false;
        }
    }

    public get controls(): Control[] {
        return [
            this.tbSku,
            this.tbName,
            this.taDescription,
            this.tbCost,
            this.tbPrice,
            this.tbQuantity,
            this.wbWarranty,
            this.cbWork,
            this.pnlOnlyCreate,
            this.pnlFooter,
        ] as Control[];
    }

    private async changeEnableAccountWithdrawal(sender: any, e: CheckBoxValueChangedEventArgs): Promise<void> {
        this.cbAccount.visible = e.value;
    }

    private async changeEnableStoreAddition(sender: any, e: CheckBoxValueChangedEventArgs): Promise<void> {
        this.cbStore.visible = e.value;
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.context?.material
            ? await this.updateMaterial(this.context.material)
            : await this.createMaterial();

        if (result) {
            this.hide(result);
        }
    }

    private async createMaterial(): Promise<boolean> {
        if (!this.onCreate) {
            return false;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const priceStr = this.tbPrice.text.replace(",", ".");

        const dto: IOrderMaterialCreateDto = {
            sku: this.tbSku.text,
            name: this.tbName.text,
            description: this.taDescription.text,
            cost: parseFloat(costStr),
            price: parseFloat(priceStr),
            quantity: parseInt(this.tbQuantity.text),
            warranty: this.wbWarranty.value,
            work: this.cbWork.selectedItem?.ref?.id,
            store: this.getAdditionStoreId(),
            payment: this.getAccountPayment(),
        };

        return await this.onCreate(dto);
    }

    private getAccountPayment(): IOrderMaterialPaymentDto | undefined {
        let paymentData: IOrderMaterialPaymentDto | undefined;
        if (this.chbEnableAccountWithdrawal.value && this.cbAccount.selectedItem) {
            paymentData = {
                account: this.cbAccount.selectedItem.id,
                description: `Покупка материалов (${this.tbQuantity.text} шт.)`,
            };
        }

        return paymentData;
    }

    private getAdditionStoreId(): string | undefined {
        let storeId: string | undefined;
        if (this.chbEnableStoreAddition.value && this.cbStore.selectedItem) {
            storeId = this.cbStore.selectedItem.id;
        }
        return storeId;
    }

    private async updateMaterial(orig: IOrderMaterial): Promise<boolean> {
        if (!this.onUpdate) {
            return false;
        }

        const costStr = this.tbCost.text.replace(",", ".");
        const priceStr = this.tbPrice.text.replace(",", ".");

        const dto: IOrderMaterialUpdateDto = {
            sku: this.tbSku.text,
            name: this.tbName.text,
            description: this.taDescription.text,
            cost: parseFloat(costStr),
            price: parseFloat(priceStr),
            quantity: parseInt(this.tbQuantity.text),
            warranty: this.wbWarranty.value,
            work: this.cbWork.selectedItem?.ref?.id,
        };

        return await this.onUpdate(orig, dto);
    }
}
