














































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BBadge } from "bootstrap-vue";
import VNavMenuItem from "./VerticalNavMenuItem.vue";

@Component({
    name: "v-nav-menu-group",
    components: { BBadge, VNavMenuItem },
})
export default class VerticalNavMenuGroup extends Vue {
    @Prop({ type: Boolean, default: false })
    private openHover!: boolean;

    @Prop({ type: Boolean, default: false })
    private open!: boolean;

    @Prop({ type: Object })
    private group!: object;

    @Prop({ type: Number })
    private groupIndex!: number;

    private maxHeight: string = "0px";
    private openItems: boolean = false;

    private get context(): Vue {
        return this;
    }

    private get styleItems(): any {
        return { maxHeight: this.maxHeight, margin: 0 };
    }

    private get itemIcon(): any {
        return (index: string) => {
            if (!((index.match(/\./g) || []).length > 1)) {
                return "CircleIcon";
            }
        };
    }

    private get isGroupActive(): any {
        return (item: any) => {
            const path = this.$route.fullPath;
            let open = false;
            const routeParent = this.$route.meta ? this.$route.meta.parent : undefined;

            const func = (item: any) => {
                if (item.submenu) {
                    item.submenu.forEach((item: any) => {
                        if (item.route === this.$route.meta?.active) {
                            open = true;
                        } else if (item.submenu) {
                            func(item);
                        }
                    });
                }
            };

            func(item);
            return open;
        };
    }

    @Watch("$route")
    private onRouteChanged(): void {
        const scrollHeight = (this as any).scrollHeight;

        // Collapse Group
        if (this.openItems && !this.open) {
            this.maxHeight = `${scrollHeight}px`;
            setTimeout(() => {
                this.maxHeight = `${0}px`;
            }, 50);
            // Expand Group
        } else if (this.open) {
            this.maxHeight = `${scrollHeight}px`;
            setTimeout(() => {
                this.maxHeight = "none";
            }, 300);
        }
    }

    @Watch("maxHeight")
    private onMaxHeightChanged(): void {
        this.openItems = this.maxHeight !== "0px";
    }

    private clickGroup(): void {
        if (!this.openHover) {
            const thisScrollHeight = (this.$refs.items as any).scrollHeight;

            if (this.maxHeight === "0px") {
                this.maxHeight = `${thisScrollHeight}px`;
                setTimeout(() => {
                    this.maxHeight = "none";
                }, 300);
            } else {
                this.maxHeight = `${thisScrollHeight}px`;
                setTimeout(() => {
                    this.maxHeight = `${0}px`;
                }, 50);
            }

            this.$parent.$children.map((child: any) => {
                if (child.isGroupActive) {
                    if (child !== this && !child.open && child.maxHeight !== "0px") {
                        setTimeout(() => {
                            child.maxHeight = `${0}px`;
                        }, 50);
                    }
                }
            });
        }
    }

    private mouseover(): void {
        if (this.openHover) {
            const scrollHeight = (this.$refs.items as any).scrollHeight;
            this.maxHeight = `${scrollHeight}px`;
        }
    }

    private mouseout(): void {
        if (this.openHover) {
            const scrollHeight = 0;
            this.maxHeight = `${scrollHeight}px`;
        }
    }

    public mounted(): void {
        this.openItems = this.open;
        if (this.open) {
            this.maxHeight = "none";
        }
    }
}
