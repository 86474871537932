import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormSpinbutton, BTooltip } from "bootstrap-vue";
import { NumberBox } from "./number-box";

@Component({
    name: "number-box-component",
    components: { BFormSpinbutton, BTooltip },
})
export class NumberBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: NumberBox;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    {this.renderInput()}
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(): VNode[] {
        const items: VNode[] = [];

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        items.push(
            <b-form-spinbutton
                id={this.handler.id}
                placeholder={this.handler.placeholder}
                disabled={this.handler.disabled}
                min={this.handler.min}
                max={this.handler.max}
                step={this.handler.step}
                size={this.handler.size}
                v-model={this.handler.value}
            />,
        );

        return items;
    }
}
