import { IClient, IPluginBinding, IPluginCard, IPluginNotificationSendDto } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Label, Panel, PhoneBox, Select, TextArea } from "@core/components/alt-ui/controls";

export interface IOrderViewNotificationModalContext {
    plugins: IPluginBinding[];
    client?: IClient;
    message: string;
}

export class OrderViewNotificationModal extends Modal<IOrderViewNotificationModalContext> {
    private tbPhone!: PhoneBox;
    private taMessage!: TextArea;
    private lbLength!: Label;
    private cbPlugin!: Select<IPluginBinding>;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: IOrderViewNotificationModalContext | null = null;
    public onSend: ((bindingId: string, dto: IPluginNotificationSendDto) => Promise<boolean>) | null = null;

    public constructor() {
        super("order-view-notification-modal", "Отправка уведомлений");
        this.initializeControls();
    }

    public show(context: IOrderViewNotificationModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context);
        return super.show();
    }

    protected initializeControls(): void {
        this.tbPhone = new PhoneBox();
        this.tbPhone.id = "order-view-notification.phone";
        this.tbPhone.label = "Телефон";
        this.tbPhone.validation = "required";

        this.lbLength = new Label();
        this.lbLength.text = "";
        this.lbLength.class = "text-sm text-right -mt-0.5";

        this.taMessage = new TextArea();
        this.taMessage.id = "order-view-notification.message";
        this.taMessage.label = "Сообщение";
        this.taMessage.validation = "required";
        this.taMessage.addTextChangedHandler(this.changeMessage.bind(this));

        this.cbPlugin = new Select<IPluginBinding>();
        this.cbPlugin.id = "order-view-notification.plugin";
        this.cbPlugin.label = "Сервис уведомлений";
        this.cbPlugin.help = 'Выбрать и настроить можно в меню "Настройки компании" - Интеграции.';
        this.cbPlugin.items = this.context?.plugins ?? [];
        this.cbPlugin.textField = p => p.pluginRef?.name ?? "";
        this.cbPlugin.descriptionField = p => p.pluginRef?.description ?? "";
        this.cbPlugin.selectedIndex = this.cbPlugin.items.length > 0 ? 0 : -1;
        this.cbPlugin.visible = this.cbPlugin.items.length > 1;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "order-view-notification.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "order-view-notification.send";
        this.btnSave.text = "Отправить";
        this.btnSave.addClickHandler(this.clickSend.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(context: IOrderViewNotificationModalContext): void {
        this.tbPhone.text = context.client?.info?.contacts?.phone ?? "";
        this.taMessage.text = context.message;
    }

    public get controls(): Control[] {
        return [this.tbPhone, this.taMessage, this.lbLength, this.cbPlugin, this.pnlFooter];
    }

    private async changeMessage(sender: any, e: any): Promise<void> {
        this.lbLength.text = `Символов: ${this.taMessage.text.length}`;
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSend(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.cbPlugin.selectedItem || !this.onSend) {
            return;
        }

        const bindingId = this.cbPlugin.selectedItem.id;
        const phone = this.tbPhone.text.trim().replace(" ", "");

        const dto: IPluginNotificationSendDto = {
            to: phone,
            message: this.taMessage.text,
        };

        const result = await this.onSend(bindingId, dto);
        if (result) {
            this.hide(result);
        }
    }
}
