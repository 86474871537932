/** Тип (специализация) компании. */
export enum CompanyType {
    /** Ремонт электроники. */
    RepairDevices = "repair-devices",

    /** Ремонт авто. */
    RepairAuto = "repair-auto",

    /** Швейная мастерская. */
    Tailoring = "tailoring",

    /** Химчистка. */
    DryCleaning = "dry-cleaning",
}
