import Vue from "vue";
import * as filters from ".";
import { Formatter } from "@/utils/formatter";

Vue.filter("capitalize", filters.strings.capitalize);
Vue.filter("uppercase", filters.strings.uppercase);
Vue.filter("title", filters.strings.title);
Vue.filter("truncate", filters.strings.truncate);
Vue.filter("tailing", filters.strings.tailing);
Vue.filter("csv", filters.strings.csv);
Vue.filter("filter_tags", filters.strings.filterTags);

Vue.filter("time", filters.datetime.time);
Vue.filter("date", filters.datetime.date);
Vue.filter("month", filters.datetime.month);
Vue.filter("datetime", filters.datetime.datetime);
Vue.filter("fromNow", filters.datetime.fromNow);

Vue.filter("k_format", filters.numbers.kFormat);

Vue.filter("money", filters.money.money);
Vue.filter("moneyFormat", filters.money.moneyFormat);
Vue.filter("currencyHeader", filters.money.currencyHeader);

Vue.filter("phone", (value: any): string => Formatter.phone(value));
