import { FieldType, IField, IValidation } from "@lib";
import { CheckBox, TextBox } from "@core/components/alt-ui/controls";
import { FieldSettings } from "./field-settings";

export class FieldSettingsStandart extends FieldSettings {
    private tbTitle!: TextBox;
    private tbDescription!: TextBox;
    private tbDefault!: TextBox;
    private chbRequired!: CheckBox;

    protected initializeControls(): void {
        this.tbTitle = new TextBox();
        this.tbTitle.id = "title";
        this.tbTitle.label = "Название";
        this.tbTitle.validation = "required";
        this.tbTitle.class = "mb-0.75";

        this.tbDescription = new TextBox();
        this.tbDescription.id = "description";
        this.tbDescription.label = "Подсказка";
        this.tbDescription.class = "mb-0.75";

        this.tbDefault = new TextBox();
        this.tbDefault.id = "default";
        this.tbDefault.label = "Значение по умолчанию";
        this.tbDefault.class = "mb-0.75";

        this.chbRequired = new CheckBox();
        this.chbRequired.id = "required";
        this.chbRequired.text = "Обязательно для заполнения";
        this.chbRequired.value = false;
        this.chbRequired.class = "mt-1 mb-0.75";

        this.addControls([this.tbTitle, this.tbDescription, this.tbDefault, this.chbRequired]);
    }

    public populateControls(field: IField): void {
        this.tbTitle.text = field.title ?? "";
        this.tbDescription.text = field.description ?? "";
        this.tbDefault.text = field.default ?? "";
        this.chbRequired.value = field.validation?.required ?? false;

        if (field.type === FieldType.Phone) {
            this.tbDefault.visible = false;
        }
    }

    public getCreatePayload(): IField | null {
        return null;
    }

    public getUpdatePayload(): IField | null {
        if (!this.context.field) {
            return null;
        }

        return {
            ...this.context.field,
            title: this.tbTitle.text,
            description: this.tbDescription.text.length > 0 ? this.tbDescription.text : undefined,
            default: this.tbDefault.text.length > 0 ? this.tbDefault.text : undefined,
            validation: this.getValidation(),
        } as IField;
    }

    private getValidation(): IValidation | undefined {
        if (this.chbRequired.value) {
            return {
                required: this.chbRequired.value,
            };
        }

        return undefined;
    }
}
