import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, TextArea, TextBox } from "@core/components/alt-ui/controls";

export interface ICompanyCreateModalData {
    name: string;
    description?: string;
}

export enum CompanyCreateModalAnswer {
    Create = "create",
    Cancel = "cancel",
}

export interface ICompanyCreateModalModalResult {
    answer: CompanyCreateModalAnswer;
    data?: ICompanyCreateModalData;
}

export class CompanyCreateModal extends Modal<undefined, ICompanyCreateModalModalResult> {
    private tbName!: TextBox;
    private taDescription!: TextArea;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    public constructor() {
        super("company-create-modal", "Создание новой компании");
        this.initializeControls();
    }

    public show(): Promise<ICompanyCreateModalModalResult> {
        this.initializeControls();
        return super.show();
    }

    protected initializeControls(): void {
        this.tbName = new TextBox();
        this.tbName.id = "company-create.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.taDescription = new TextArea();
        this.taDescription.id = "company-create.description";
        this.taDescription.label = "Описание";

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "company-create.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "company-create.save";
        this.btnSave.text = "Создать";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    public get controls(): Control[] {
        return [this.tbName, this.taDescription, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide({ answer: CompanyCreateModalAnswer.Cancel });
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const data: ICompanyCreateModalData = {
            name: this.tbName.text,
            description: this.taDescription.text.length > 0 ? this.taDescription.text : undefined,
        };

        this.hide({
            answer: CompanyCreateModalAnswer.Create,
            data: data,
        } as ICompanyCreateModalModalResult);
    }
}
