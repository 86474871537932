








import { Component, Prop, Vue } from "vue-property-decorator";
import { Label, List } from "../controls";
import { ListComponent } from "../controls/list.component";
import { TableList } from "./table-list";

@Component({
    name: "table-list-component",
    components: {
        ListComponent,
        TableComponent: () => import("../table/table.component.vue"),
    },
})
export default class TableListComponent extends Vue {
    @Prop({ type: Object })
    private handler!: TableList;

    private get listFooter(): List {
        const footer = new List();
        footer.class = "my-0.5";
        footer.classItem = c => "border-0 text-right";
        footer.classContent = c => "mr-0.5";
        footer.classRight = c => "font-bold";
        footer.content = c => (typeof c.title === "string" ? c.title : c.title(this.handler.items));
        footer.right = c => c.cell(this.handler.items);
        footer.items = this.handler.footer;
        return footer;
    }
}
