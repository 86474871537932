import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BTooltip } from "bootstrap-vue";
import { ValidationProvider, Validator } from "vee-validate";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import AltInput from "@core/components/alt-controls/alt-input.vue";
import validators from "@/views/main/settings/settings-company/modals/validators";
import { PhoneBox } from "./phone-box";

const PhoneRuleName = "phone-number";
Validator.extend(PhoneRuleName, validators.validatePhoneNumber);

@Component({
    name: "phone-box-component",
    components: { AltInput, ValidationProvider, BTooltip },
})
export class PhoneBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: PhoneBox;

    public get text(): string {
        return this.phoneFormat(this.handler.text);
    }

    public set text(text: string) {
        this.handler.text = text.replace(/\s/g, "");
    }

    // TODO: вынести в utils???
    private phoneFormat(value: string): string {
        const formatter = new AsYouType({
            defaultCountry: "RU",
        });

        const defaultPrefix = "+";

        const possibleNeedPrefix = value.length === 1;
        const shouldAddPrefix = possibleNeedPrefix && !value.startsWith(defaultPrefix) && !value.startsWith("8");

        if (shouldAddPrefix) {
            return formatter.input(defaultPrefix + value);
        }

        const phoneNumber = parsePhoneNumber(value, {
            defaultCountry: "RU",
            extract: false,
        });

        if (!phoneNumber) {
            return value;
        }

        return formatter.input(value);
    }

    public get validation(): string | null {
        return this.handler.validation ? `${this.handler.validation}|${PhoneRuleName}` : PhoneRuleName;
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderInput(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        items.push(
            <alt-input
                id={this.handler.id}
                type={this.handler.type}
                placeholder={this.handler.placeholder}
                disabled={this.handler.disabled}
                autocomplete="off"
                state={errors.length > 0 ? false : null}
                v-model={this.text}
            />,
        );

        items.push(<small class="text-danger">{errors[0]}</small>);
        return items;
    }
}
