export const rules = {
    money: {
        getMessage: (_: string, __: string[]): string => {
            // if (args.length > 0 && args[0] === "unsigned") {
            //     return "Поле должно быть числом без знака";
            // }

            return "Поле должно иметь денежный формат";
        },

        validate: (value: string, __: string[]): boolean => {
            // if (args.length > 0 && args[0] === "unsigned") {
            //     return /^[0-9]+((\.|,)[0-9]{1,2})?$/.test(value);
            // }

            return /^(-|\+)?[0-9]+((\.|,)[0-9]{1,2})?$/.test(value);
        },
    },

    unsigned: {
        getMessage: (): string => {
            return "Поле должно быть числом без знака";
        },

        validate: (value: string): boolean => {
            return /^[0-9]+((\.|,)[0-9]{1,2})?$/.test(value);
        },
    },

    macro: {
        getMessage: (_: string, __: string[]): string => {
            return "Поле может включать буквы, цифры, точки и подчёркивания";
        },

        validate: (value: string, __: string[]): boolean => {
            return /^[\p{L}\p{N}._]*$/giu.test(value);
        },
    },
};

export const messages = {
    ru: (locale: any) => {
        locale.messages.required = "Поле обязательно для заполнения";
        locale.messages.confirmed = "Поле не совпадает с исходным";
        locale.messages.email = "Поле должно быть действительным электронным адресом";
        locale.messages.money = "Поле должно иметь денежный формат";
        locale.messages.numeric = "Поле должно быть числом";
        locale.messages.unsigned = "Поле должно быть числом без знака";
        locale.messages.macro = "Поле может включать буквы, цифры, точки и подчёркивания";
        locale.messages.min_value = (field: any, e: any[]) => `Поле должно быть ${e[0]} или больше`;
        locale.messages.max_value = (field: any, e: any[]) => `Поле должно быть ${e[0]} или меньше`;
    },

    en: (locale: any) => {
        locale.messages.required = "The field is required";
        locale.messages.confirmed = "The field does not match the original";
        locale.messages.email = "The field must be in email format";
        locale.messages.money = "The field must be in money format";
        locale.messages.numeric = "The field may only contain numeric characters";
        locale.messages.unsigned = "The field must be unsigned number";
        locale.messages.macro = "The field may only contain characters, digits, dots and underscores";
        locale.messages.min_value = (field: any, e: any[]) => `The field must be ${e[0]} or more`;
        locale.messages.max_value = (field: any, e: any[]) => `The field must be ${e[0]} or less`;
    },
};

// VeeValidate locales
import ru from "vee-validate/dist/locale/ru.js";
import en from "vee-validate/dist/locale/en.js";

export default function configurate(VeeValidate: any, locale: string): void {
    VeeValidate.Validator.extend("money", rules.money);
    VeeValidate.Validator.extend("unsigned", rules.unsigned);
    VeeValidate.Validator.extend("macro", rules.macro);

    messages.ru(ru);
    messages.en(en);

    //VeeValidate.Validator.localize("ru", ru);
    VeeValidate.Validator.localize({ ru, en });
    VeeValidate.Validator.localize(locale);
}
