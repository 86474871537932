import { IWorkUseCase, IWork, ISelectQuery, IWorkImportDto, ISelectedData } from "@lib";
import { IWorkCreateDto, IWorkUpdateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class WorkUseCase implements IWorkUseCase {
    public async select(companyId: string, query?: ISelectQuery): Promise<ISelectedData<IWork>> {
        try {
            const params = query;
            const response = await Api.get<ISelectedData<IWork>>(`/companies/${companyId}/lists/works`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    // public async selectForOffice(companyId: string, officeId: string): Promise<IWork[]> {
    //     try {
    //         const response = await Api.get<IWork[]>(`/companies/${companyId}/offices/${officeId}/works`);
    //         return response.data;
    //     } catch (e: any) {
    //         throw new DataLoadException(e);
    //     }
    // }

    public async get(companyId: string, id: string): Promise<IWork> {
        try {
            const response = await Api.get<IWork>(`/companies/${companyId}/lists/works/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IWorkCreateDto): Promise<IWork> {
        try {
            const response = await Api.post<IWork>(`/companies/${companyId}/lists/works`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: IWorkCreateDto[]): Promise<IWork[]> {
        try {
            const response = await Api.post<IWork[]>(`/companies/${companyId}/lists/works/many`, dtos);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IWorkUpdateDto): Promise<IWork> {
        try {
            const response = await Api.put<IWork>(`/companies/${companyId}/lists/works/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/lists/works/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async import(companyId: string, file: File, dto: IWorkImportDto): Promise<IWork[]> {
        try {
            const data = new FormData();
            data.append("file", file);
            for (const key in dto) {
                data.append(key, (dto as any)[key]);
            }

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            const response = await Api.post<IWork[]>(`/companies/${companyId}/lists/works/import`, data, config);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async export(companyId: string, query?: ISelectQuery): Promise<Blob> {
        try {
            const params = query;
            const response = await Api.get<Blob>(`/companies/${companyId}/lists/works/export`, {
                params,
                responseType: "blob",
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
