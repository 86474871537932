import {
    IUserOnlyUseCase,
    ICompany,
    IAuthLoginResultDto,
    ICompanyCreateDto,
    IAuthRefreshResultDto,
    IAuthLoadInfoResultDto,
} from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api, ApiAuth } from "../axios";

export class UserOnlyUseCase implements IUserOnlyUseCase {
    public async companies(): Promise<ICompany[]> {
        try {
            const response = await Api.get<ICompany[]>("/user-only/companies");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createCompany(dto: ICompanyCreateDto): Promise<ICompany> {
        try {
            const response = await Api.post<ICompany>("/user-only/companies", dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async leaveCompany(companyId: string): Promise<void> {
        try {
            await Api.delete<void>(`/user-only/companies/${companyId}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async loadInfo(): Promise<IAuthLoadInfoResultDto> {
        try {
            const response = await Api.get<IAuthLoadInfoResultDto>("/user-only/info");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async login(companyId: string): Promise<IAuthLoginResultDto> {
        try {
            const response = await Api.post<IAuthLoginResultDto>("/user-only/login", { companyId });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async logout(): Promise<void> {
        try {
            await ApiAuth.post<void>("/user-only/logout");
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async refresh(): Promise<IAuthRefreshResultDto> {
        try {
            const response = await ApiAuth.post<IAuthRefreshResultDto>("/user-only/refresh");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
