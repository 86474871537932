import { Control, MultiSelectChangedEventArgs, TextFieldReceiver } from "@core/components/alt-ui/controls";
import { EventHandler } from "@core/components/alt-ui";

export class MultiDropdown<T = any> extends Control {
    public items: T[] = [];
    public iconPackage: "alt" | "feather" = "alt";
    public icon: string = "";
    public locale: any = {};
    public toolbar = true;

    public itemId: TextFieldReceiver<T> = (item: T) => item;
    /** Название элемента для списка. */
    public itemName: TextFieldReceiver<T> = (item: T) => item;
    public itemDescription: TextFieldReceiver<T> | null = null;
    /** Особое название элемента для заголовка. */
    public itemTitle: TextFieldReceiver<T> | null = null;

    public getComponentName(): string {
        return "MultiDropdownComponent";
    }

    private _selectedItems: T[] = [];
    public get selectedItems(): T[] {
        return this._selectedItems;
    }
    public set selectedItems(items: T[]) {
        if (items === this._selectedItems) {
            return;
        }

        this._selectedItems = items;
        this.notifyChangedHandler();
    }

    private _changedHandlers = new Set<EventHandler<MultiSelectChangedEventArgs<T>>>();
    public addChangedHandler(handler: EventHandler<MultiSelectChangedEventArgs<T>>): void {
        this._changedHandlers.add(handler);
    }
    public removeChangedHandler(handler: EventHandler<MultiSelectChangedEventArgs<T>>): void {
        this._changedHandlers.delete(handler);
    }
    private notifyChangedHandler(): void {
        const args: MultiSelectChangedEventArgs<T> = {
            items: this._selectedItems,
        };
        for (const handler of this._changedHandlers) {
            handler(this, args);
        }
    }
}
