import { IDiscount, DiscountType, Locale, Currency } from "@lib";
import { getDiscountTypes as getDiscountTypesOptions } from "./select-options";
import { moneyFormat } from "@/filters/money";
import { Localizer } from "@/i18n/localizer";

export type DiscountArray = [string, DiscountType];

export function getEmptyDiscountArray(): DiscountArray {
    return ["0", DiscountType.Percent];
}

export function arrayToDiscount(array: DiscountArray): IDiscount | undefined {
    if (array[0].length === 0) {
        return undefined;
    }

    const tmp = array[0].trim().replace(",", ".");
    const value = parseFloat(tmp);

    if (!value) {
        return undefined;
    }

    return { value, type: array[1] };
}

export function discountToArray(discount: IDiscount | null, locale: Locale = Locale.RU): DiscountArray {
    if (!discount) {
        return getEmptyDiscountArray();
    }

    const value = Localizer.number(discount.value, locale);
    return [value, discount.type];
}

export function discountToString(discount: IDiscount | undefined, locale: Locale = Locale.RU): string {
    if (!discount) {
        return "";
    }

    const options = getDiscountTypesOptions();
    const option = options.find(o => o.id === discount.type);

    if (!option) {
        return "";
    }

    if (discount.type === DiscountType.Percent) {
        return `${discount.value}%`;
    }

    return moneyFormat(discount.value, { locale });
}

//

export function getDiscountTypes(): DiscountType[] {
    return [DiscountType.Percent, DiscountType.Fixed];
}

export function getDiscountTypeText(type: DiscountType, options?: { currency: Currency }): string {
    switch (type) {
        case DiscountType.Percent:
            return "%";
        case DiscountType.Fixed:
            return options ? Localizer.currencySymbol(options.currency) : "фикс.";
    }
}
