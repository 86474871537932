import { FieldFormat } from "@lib";
import { AutoSuggest } from "@core/components/alt-ui/controls";
import { FieldControl } from "../field-control";
import { Formatter } from "@/utils/formatter";
import { Uuid } from "@/utils/uuid";

export class AutoSuggestFieldControl<T = any> extends FieldControl<AutoSuggest<T>> {
    protected convert(): AutoSuggest<T> {
        const control = new AutoSuggest<T>();
        control.id = `${this.field.id}_${Uuid.short()}`;
        control.label = this.field.title ?? "";
        control.help = this.field.description ?? "";
        control.format = this.field.format ?? null;
        control.validation = this.validationToString(this.field.validation);
        control.text = this.field.default ?? "";
        return control;
    }

    public get value(): any {
        return this.control.text;
    }

    public set value(value: any) {
        this.control.text = value ? String(value) : "";
    }

    public getValueFormatted(): string {
        if (!this.field.format) {
            return this.value;
        }

        switch (this.field.format) {
            case FieldFormat.Number:
                return Formatter.number(this.value, { locale: this.context?.locale });
            case FieldFormat.Money:
                return Formatter.money(this.value, { locale: this.context?.locale });
            case FieldFormat.Phone:
                return Formatter.phone(this.value);
        }

        return this.value;
    }
}
