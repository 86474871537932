/** Тип документа. */
export enum DocumentType {
    /** Документ филиала. */
    OfficeGeneral = "office-general",

    /** Документ магазина. */
    ShopDocument = "shop-general",
}

// TODO: переименовать в TemplateDocumentType
