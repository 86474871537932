import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { BButton, VBTooltip } from "bootstrap-vue";
import { IOffice, IOrderStage } from "@lib";
import { ITableFilter } from "@lib";
import { PermissionType, PermissionRight } from "@lib";
import VaIcon from "@core/components/va-icon";
import VaAutoButton from "@core/components/va-auto-button";
import { OrdersFilterController } from "../orders-filter-controller";
import FilterModal from "@core/filters/filter-modal.vue";

@Component({
    name: "orders-toolbar",
    components: {
        BButton,
        VaIcon,
        VaAutoButton,
        FilterModal,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class OrdersToolbar extends Vue {
    @Prop({ type: Array, required: true })
    private offices!: IOffice[];

    @Prop({ type: Array, required: true })
    private officesSelected!: IOffice[];

    @Prop({ type: Array, required: true })
    private stages!: IOrderStage[];

    @Prop({ type: Array, default: () => [] })
    private filter!: ITableFilter[];

    @Prop({ type: Object, required: true })
    public filterController!: OrdersFilterController;

    @Emit("create")
    private startCreate(): void {
        return;
    }

    @Emit("change-view")
    private changeView(view: string): string {
        return view;
    }

    private get can(): any {
        const offices = this.officesSelected;
        const secure = this.$secure;
        return {
            get create(): boolean {
                for (const office of offices) {
                    const valid = secure.check(PermissionType.Offices, office.id, PermissionRight.OrdersCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    public get hasFilter(): boolean {
        return this.filter.length > 0 && !!this.filter.find(f => f.field !== "office");
    }

    public showFilterModal(): void {
        this.$emit("show-filter");
    }

    public onOfficeChange(offices: IOffice[]): void {
        this.filterController.filter.office.set(offices.map(office => office.id));

        this.$emit("change-filter", this.filterController.tableFilter);
    }
}
