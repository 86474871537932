import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BTooltip } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import AltTextArea from "@core/components/alt-controls/alt-text-area.vue";
import { TextArea } from "./text-area";

@Component({
    name: "text-area-component",
    components: { AltTextArea, ValidationProvider, BTooltip },
})
export class TextAreaComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: TextArea;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.handler.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderTextArea(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderTextArea(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        items.push(
            <alt-text-area
                id={this.handler.id}
                placeholder={this.handler.placeholder}
                disabled={this.handler.disabled}
                rows={this.handler.rows}
                rows-max={this.handler.rowsMax}
                state={errors.length > 0 ? false : null}
                v-model={this.handler.text}
            />,
        );

        items.push(<small class="text-danger">{errors[0]}</small>);
        return items;
    }
}
