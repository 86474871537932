

















import { Vue, Component } from "vue-property-decorator";
import { BBadge } from "bootstrap-vue";

@Component({ name: "CellRendererChip", components: { BBadge } })
export default class CellRendererChip extends Vue {
    private params: any;

    private click() {
        if (this.params.click) {
            this.params.click();
        }
    }
}
