import Vue from "vue";
//import VxTooltip from "./components/vuexy/vx-tooltip/VxTooltip.vue";
import VxCard from "./components/vuexy/vx-card/VxCard.vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";

//Vue.component(VxTooltip.name, VxTooltip);
Vue.component(VxCard.name, VxCard);
Vue.component(FeatherIcon.name, FeatherIcon);

// v-select component
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
(vSelect.props as any).components.default = () => ({
    Deselect: {
        render: (createElement: Function) =>
            createElement("feather-icon", {
                props: {
                    icon: "XIcon",
                    class: "w-1 h-1 mt-0.25",
                },
            }),
    },
    OpenIndicator: {
        render: (createElement: Function) =>
            createElement("feather-icon", {
                props: {
                    icon: "ChevronDownIcon",
                    class: "w-1.25 h-1.25",
                },
            }),
    },
});

Vue.component(vSelect.name);
