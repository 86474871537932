






import { Vue, Component, Emit } from "vue-property-decorator";
import { PermissionCommonSection, PermissionRight } from "@lib";
import VaAutoButton from "@core/components/va-auto-button";
import { ModalComponent } from "@core/components/alt-ui/modal";

@Component({
    name: "good-inventories-toolbar",
    components: { VaAutoButton, ModalComponent },
})
export default class GoodInventoriesToolbar extends Vue {
    @Emit("create")
    private startCreate(): void {
        return;
    }

    public constructor() {
        super();
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                // TODO: добавить проверку прав
                return true;
            },
        };
    }
}
