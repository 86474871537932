import { ISalary, IOrder, IOrderMaterial, IOrderWork, ITransaction, IAccount } from "@lib";
import { ISale, ISaleGood } from "@lib";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface TableSalaryConverterResult {
    items: any[];
    sumProfit: number;
    sumAmount: number;
}

export enum SalaryTableItemType {
    Order = "order",
    Work = "work",
    Material = "material",
    Sale = "sale",
    Good = "good",
    Reserved = "reserved",

    Paid = "paid",
}

export interface ISalaryTableItem {
    type: SalaryTableItemType;
    number?: number;
    name: string;
    quantity?: number;
    expenses?: number;
    expensesHelp?: string;
    incomes?: number;
    incomesHelp?: string;
    discount?: string;
    discountHelp?: string;
    profit?: number;
    amount: number;
    amountHelp?: string;
    meta: {
        order?: IOrder;
        work?: IOrderWork;
        material?: IOrderMaterial;
        sale?: ISale;
        good?: ISaleGood;
        salary?: ISalary;
        employee: string;
        locale: string;
        percentWork?: number;
        percentMaterial?: number;
        percentSale?: number;
    };
    parent?: ISalaryTableItem;
    paid: boolean;
    transaction?: ITransaction;
    account?: string;

    //  https://bootstrap-vue.org/docs/components/table#items-record-data
    _rowVariant?: string;
}

export { default as SalaryTableOrderConverter } from "./salary-table-order-converter";
export { default as SalaryTableSaleConverter } from "./salary-table-sale-converter";
export { default as SalaryTablePaidConverter } from "./salary-table-paid-converter";
export { default as SalaryTableReservedConverter } from "./salary-table-reserved-converter";
