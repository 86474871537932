import { IAccount, PaymentType, ISalePaymentCreateDto, ISale } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Control, Select, TextArea, TextBox } from "@core/components/alt-ui/controls";
import { ISelectOption } from "@/@core/types/common/select-options";
import { Footer } from "@/@core/controls/footer.control";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface SalePaymentModalContext {
    accounts: IAccount[];
    userId: string;
}

export const paymentTypes: ISelectOption<PaymentType>[] = [
    {
        id: PaymentType.Prepayment,
        name: "Предоплата",
    },
    {
        id: PaymentType.Payment,
        name: "Оплата заявки",
    },
];

export class SalePaymentModal extends Modal<SalePaymentModalContext> {
    private сbAccount!: Select<IAccount>;
    private cbType!: Select<ISelectOption<PaymentType>>;
    private tbValue!: TextBox;
    private taDescription!: TextArea;

    private ftFooter!: Footer;

    private context!: SalePaymentModalContext;
    public onCreate: ((dto: ISalePaymentCreateDto) => Promise<ISale | null>) | null = null;

    public constructor(id: string = "sale-payment-modal") {
        // TODO: окно встречается 2 раза, из-за этого есть проблемы с заполнением полей
        super(id, "");
        this.initializeControls();
    }

    public show(context: SalePaymentModalContext): Promise<void> {
        this.context = context;
        this.title = "Добавление оплаты";

        this.initializeControls();

        return super.show();
    }

    protected initializeControls(): void {
        this.сbAccount = new Select<IAccount>();
        this.cbType = new Select<ISelectOption<PaymentType>>();
        this.tbValue = new TextBox();
        this.taDescription = new TextArea();

        this.ftFooter = new Footer({
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });

        //

        this.сbAccount.id = "sale-payment.account";
        this.сbAccount.label = "Счёт";
        this.сbAccount.items = this.context?.accounts ?? [];
        this.сbAccount.textField = ac => ac.info.name;
        this.сbAccount.descriptionField = ac => ac.info.description;
        this.сbAccount.selectedIndex = this.сbAccount.items.length > 0 ? 0 : -1;

        this.cbType.id = "sale-payment.type";
        this.cbType.label = "Статья";
        this.cbType.items = paymentTypes;
        this.cbType.textField = item => item.name;
        this.cbType.selectedIndex = 0;
        this.cbType.addChangedHandler(() => {
            if (this.cbType.selectedItem) {
                this.taDescription.text = this.cbType.selectedItem.name;
            }
        });

        this.tbValue.id = "sale-payment.value";
        this.tbValue.label = "Сумма";
        this.tbValue.validation = "required|money";
        this.tbValue.text = "";
        //this.tbValue.help = "Положительное значение для прихода, отрицательное для расхода";

        this.taDescription.id = "sale-payment.description";
        this.taDescription.label = "Описание";
        this.taDescription.validation = "required";
        this.taDescription.text = "Предоплата";
    }

    public get controls(): Control[] {
        return [this.сbAccount, this.cbType, this.tbValue, this.taDescription, this.ftFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = await this.createPayment();

        if (result) {
            this.hide(result);
        }
    }

    private async createPayment(): Promise<ISale | null> {
        if (!this.onCreate) {
            return null;
        }

        if (!this.сbAccount.selectedItem) {
            return null;
        }

        if (!this.cbType.selectedItem) {
            return null;
        }

        const account = this.сbAccount.selectedItem;

        const moneyStr = this.tbValue.text.replace(",", ".");
        const money = parseFloat(moneyStr);

        const dto: ISalePaymentCreateDto = {
            type: this.cbType.selectedItem.id,
            accountId: account.id,
            amount: money,
            description: this.taDescription.text,
            userId: this.context.userId,
        };

        return await this.onCreate(dto);
    }
}
