import {
    IGoodUseCase,
    IGood,
    ISelectedData,
    ISelectQuery,
    IGoodCreateDto,
    IGoodUpdateDto,
    IGoodMoveDto,
    IGoodDeleteManyDto,
    IGoodImportDto,
    IGoodMoveManyDto,
} from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class GoodUseCase implements IGoodUseCase {
    public async select(companyId: string, query?: ISelectQuery): Promise<ISelectedData<IGood>> {
        try {
            const config = { params: query };
            const response = await Api.get<ISelectedData<IGood>>(`/companies/${companyId}/goods`, config);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectForStore(
        companyId: string,
        storeId: string,
        query?: ISelectQuery,
    ): Promise<ISelectedData<IGood>> {
        try {
            const config = { params: query };
            const response = await Api.get<ISelectedData<IGood>>(
                `/companies/${companyId}/goods/for/${storeId}`,
                config,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectForAvailableStores(companyId: string, query?: ISelectQuery): Promise<ISelectedData<IGood>> {
        try {
            const config = { params: query };
            const response = await Api.get<ISelectedData<IGood>>(`/companies/${companyId}/goods/for/available`, config);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IGood> {
        try {
            const response = await Api.get<IGood>(`/companies/${companyId}/goods/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IGoodCreateDto): Promise<IGood> {
        try {
            const response = await Api.post<IGood>(`/companies/${companyId}/goods`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IGoodUpdateDto): Promise<IGood> {
        try {
            const response = await Api.put<IGood>(`/companies/${companyId}/goods/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async move(companyId: string, id: string, dto: IGoodMoveDto): Promise<IGood> {
        try {
            const response = await Api.put<IGood>(`/companies/${companyId}/goods/${id}/move`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async moveMany(companyId: string, dto: IGoodMoveManyDto): Promise<IGood[]> {
        try {
            const response = await Api.post<IGood[]>(`/companies/${companyId}/goods/actions/move`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/goods/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async deleteMany(companyId: string, dto: IGoodDeleteManyDto): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/goods/actions/delete`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async import(companyId: string, storeId: string, file: File, dto: IGoodImportDto): Promise<IGood[]> {
        try {
            const data = new FormData();
            data.append("file", file);
            for (const key in dto) {
                data.append(key, (dto as any)[key]);
            }

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            const response = await Api.post<IGood[]>(
                `/companies/${companyId}/goods/actions/import/${storeId}`,
                data,
                config,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async export(companyId: string, query?: ISelectQuery): Promise<Blob> {
        try {
            const params = query;
            const response = await Api.get<Blob>(`/companies/${companyId}/goods/actions/export`, {
                params,
                responseType: "blob",
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
