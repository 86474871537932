import parsePhoneNumber from "libphonenumber-js";
import moment from "moment";
import { Currency, Locale } from "@lib";
import { Gender } from "@core/types/common/gender";
import { GrammarUtils } from "./grammar.utils";

export interface INumberFormatter {
    format(value: any, options?: NumberFormatOptions): string;
    inWords(value: any, options?: NumberInWordsOptions): string;
}

export interface IMoneyFormatter {
    format(value: any, options?: MoneyFormatOptions): string;
    inWords(value: any, options?: MoneyInWordsOptions): string;
    symbol(currency: Currency): string;
}

export interface IDateTimeFormatter {
    format(value: any, options?: DateTimeFormatOptions): string;
    fromNow(value: any, options?: TimeFromNowFormatOptions): string;
}

export interface IStringFormatter {
    phone(value: any): string;
    boolean(value: any): string;
}

export interface IFormatter {
    get number(): INumberFormatter;
    get money(): IMoneyFormatter;
    get dateTime(): IDateTimeFormatter;
    get string(): IStringFormatter;
}

export abstract class Formatter {
    private static Defaults = {
        Locale: Locale.RU,
        DateTimeFormat: "LLL",
    };

    public static number(value: any, options?: NumberFormatOptions): string {
        if (value === undefined || value === null) {
            return "";
        }

        const num = parseFloat(value);
        if (isNaN(num)) {
            return value;
        }

        const locale = options?.locale ?? Formatter.Defaults.Locale;
        const useGrouping = options?.grouping ?? false;
        const minimumFractionDigits = options?.minFraction;

        return new Intl.NumberFormat(locale, { useGrouping, minimumFractionDigits }).format(num);
    }

    public static numberInWords(value: any, options?: NumberInWordsOptions): string {
        if (value === undefined || value === null) {
            return "";
        }

        const num = parseFloat(value);
        if (isNaN(num)) {
            return value;
        }

        const gender = options?.gender;

        return GrammarUtils.numberInWords(num, gender);
    }

    public static currencySymbol(currency: Currency): string {
        const parts = new Intl.NumberFormat(undefined, { style: "currency", currency })
            .formatToParts(0)
            .find(v => v.type === "currency");

        return parts?.value ?? "";
    }

    public static money(value: any, options?: MoneyFormatOptions): string {
        if (value === undefined || value === null) {
            return "";
        }

        const num = parseFloat(value);
        if (isNaN(num)) {
            return value;
        }

        const locale = options?.locale ?? Formatter.Defaults.Locale;
        const useGrouping = options?.grouping ?? true;
        const currency = options?.currency;
        const minimumFractionDigits = options?.showNullFraction ? 2 : undefined;
        const maximumFractionDigits = 2;

        return new Intl.NumberFormat(locale, {
            useGrouping,
            style: currency ? "currency" : undefined,
            currency,
            minimumFractionDigits,
            maximumFractionDigits,
        }).format(num);
    }

    public static moneyInWords(value: any, options?: MoneyInWordsOptions): string {
        if (value === undefined || value === null) {
            return "";
        }

        const num = parseFloat(value);
        if (isNaN(num)) {
            return value;
        }

        const currency = options?.currency ?? Currency.RUB;
        const showFraction = options?.withFraction ?? true;

        return GrammarUtils.moneyInWords(num, currency, showFraction);
    }

    public static phone(value: any): string {
        if (value === undefined || value === null) {
            return "";
        }

        const phoneNumber = parsePhoneNumber(value);
        return phoneNumber?.formatInternational() ?? value;
    }

    public static datetime(value: any, options?: DateTimeFormatOptions): string {
        if (value === undefined || value === null) {
            return "";
        }

        const locale = options?.locale ?? Formatter.Defaults.Locale;
        const format = options?.format ?? Formatter.Defaults.DateTimeFormat;

        return moment(value).locale(locale).format(format);
    }

    /** Выводить в формате от текущего времени (например, 2 дня назад). */
    public static timeFromNow(value: any, options?: TimeFromNowFormatOptions): string {
        if (value === undefined || value === null) {
            return "";
        }

        const locale = options?.locale ?? Formatter.Defaults.Locale;
        return moment(value).locale(locale).fromNow(!options?.suffix);
    }

    public static boolean(value: any): string {
        // TODO: брать да/нет из ресурсов
        return value === true || value === "true" ? "Да" : "Нет";
    }
}

export type NumberFormatOptions = {
    /** Языковый стандарт (по умолчанию `ru-RU`). */
    locale?: Locale;

    /** Разделять тысячи (по умолчанию `не разделять`). */
    grouping?: boolean;

    /** Минимальное число знаков после запятой (по умолчанию `все`). */
    minFraction?: number;
};

export type NumberInWordsOptions = {
    /** Язык (по умолчанию `русский`). */
    // locale?: Locale;

    /** Род (по умолчанию `мужской`). */
    gender?: Gender;
};

export type MoneyFormatOptions = {
    /** Языковый стандарт (по умолчанию `ru-RU`). */
    locale?: Locale;

    /** Добавлять значок валюты (по умолчанию `не добавлять`). */
    currency?: Currency;

    /** Разделять тысячи (по умолчанию `true`). */
    grouping?: boolean;

    /** Добавлять ли копейки, если они равны `0` (по умолчанию `добавлять`). */
    showNullFraction?: boolean;
};

export type MoneyInWordsOptions = {
    /** Язык (по умолчанию `русский`). */
    // locale?: Locale;

    /** Валюта (по умолчанию `RUB`). */
    currency?: Currency;

    /** Добавлять копейки (по умолчанию `добавлять`). */
    withFraction?: boolean;
};

export type DateTimeFormatOptions = {
    /** Языковый стандарт (по умолчанию `ru-RU`). */
    locale?: Locale;

    /**
     * Формат даты и времени (по умолчанию `LLL`).
     * Возможные варианты: https://momentjs.com/docs/#/displaying/format/
     */
    format?: string;
};

export type TimeFromNowFormatOptions = {
    /** Языковый стандарт (по умолчанию `ru-RU`). */
    locale?: Locale;

    /**
     * Добавлять суффикс `назад` в конце (например, `5 минут назад`) (по умолчанию добавлять).
     */
    suffix?: boolean;
};
