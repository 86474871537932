<template>
    <div>
        <!-- Панель инструментов -->
        <div class="d-flex mb-0.75">
            <!-- Слот -->
            <slot name="toolbar" />

            <div class="d-flex ml-auto" v-if="!hideHeader">
                <b-form-input class="sc24-search" placeholder="Поиск" v-model="search" @input="changeSearch" />

                <b-dropdown class="sc24-columns ml-0.25" variant="outline-secondary" right>
                    <template #button-content>
                        <feather-icon icon="GridIcon" />
                    </template>
                    <b-dropdown-form>
                        <b-form-checkbox
                            v-for="column of columnsCurrent"
                            :key="column.key"
                            class="mb-0.25"
                            v-model="column.visible"
                            @input="changeColumnVisible(column, $event)"
                        >
                            {{ column.label }}
                        </b-form-checkbox>
                    </b-dropdown-form>
                </b-dropdown>
            </div>
        </div>

        <!-- Таблица -->
        <div class="shadow bg-white">
            <b-table
                hover
                :fields="columnsVisible"
                :items="items"
                responsive="sm"
                thead-tr-class="sc24-table-tr"
                tbody-tr-class="sc24-table-tr"
                show-empty
                empty-text="Нет данных для отображения."
                no-local-sorting
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                @sort-changed="changeSort"
            >
                <template #head()="data">
                    <slot
                        :name="`head(${data.column})`"
                        :column="data.column"
                        :field="data.field"
                        :label="data.label"
                        :selectAllRows="data.selectAllRows"
                        :clearSelected="data.clearSelected"
                    >
                        {{ data.label }}
                    </slot>
                </template>

                <template #cell()="data">
                    <slot
                        :name="`cell(${data.field.key})`"
                        :item="data.item"
                        :index="data.index"
                        :field="data.field"
                        :unformatted="data.unformatted"
                        :value="data.value"
                        :detailsShowing="data.detailsShowing"
                        :rowSelected="data.rowSelected"
                    >
                        {{ data.value }}
                    </slot>
                </template>
            </b-table>
        </div>

        <!-- Подвал -->
        <div class="d-flex" v-if="!hideFooter">
            <div class="d-flex sc24-footer-limit mr-auto">
                <span class="align-self-center text-nowrap">
                    Записи с {{ firstItem }} по {{ lastItem }} из {{ total }}
                </span>
                <b-form-select
                    class="sc24-footer-limit-select mx-1"
                    :options="limitOptions"
                    v-model="limit"
                    @input="changeLimit"
                />
                <span class="align-self-center text-nowrap">записей на страницу</span>
            </div>

            <b-pagination
                class="sc24-footer-pagination shadow ml-auto"
                size="lg"
                v-model="page"
                @input="changePage"
                :per-page="limit"
                :total-rows="total"
            />
        </div>
    </div>
</template>

<script>
import { BFormInput, BFormSelect, BFormCheckbox, BDropdown, BDropdownForm, BTable, BPagination } from "bootstrap-vue";

export default {
    components: {
        BFormInput,
        BFormSelect,
        BFormCheckbox,
        BDropdown,
        BDropdownForm,
        BTable,
        BPagination,
    },
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => [],
        },
        total: {
            type: Number,
            default: 0,
        },
        sort: {
            type: Object,
            required: false,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            columnsCurrent: [],
            columnsVisible: [],
            page: 1,
            limit: 25,
            search: "",
        };
    },
    computed: {
        limitOptions() {
            const options = [10, 25];

            if (this.total > 25) {
                options.push(50);
            }
            if (this.total > 50) {
                options.push(100);
            }
            if (this.total > 100) {
                options.push(200);
            }
            if (this.total > 200) {
                options.push(500);
            }

            return options;
        },
        skip() {
            return this.limit * (this.page - 1);
        },
        firstItem() {
            return this.skip + 1;
        },
        lastItem() {
            return Math.min(this.skip + this.limit, this.total);
        },
        sortBy() {
            return this.sort?.column ?? "";
        },
        sortDesc() {
            return this.sort?.direction === "desc" ?? false;
        },
    },
    mounted() {
        this.columnsCurrent = this.columns.map(c => {
            c.visible = c.visible === undefined || c.visible;
            return c;
        });
        this.columnsVisible = this.getVisibleColumns();
    },
    methods: {
        getVisibleColumns() {
            return this.columnsCurrent.filter(c => c.visible);
        },
        changeColumnVisible(column, visible) {
            column.visible = visible;
            this.columnsVisible = this.getVisibleColumns();
            this.$emit("change-columns", this.columnsCurrent);
        },
        changeLimit() {
            this.$emit("change-limit", this.limit);
        },
        changePage() {
            this.$emit("change-page", this.page);
        },
        changeSearch() {
            this.$emit("search", this.search);
        },
        changeSort(ctx) {
            this.$emit("sort", ctx.sortBy, ctx.sortDesc ? "desc" : "asc");
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.sc24-table-tr {
    th,
    td {
        cursor: default;
        padding: 1rem;
    }

    th {
        background-color: white !important;
        color: $primary;
        //font-weight: initial;
        text-transform: initial !important;
    }
}

.sc24-footer-limit {
    height: fit-content;
}

.sc24-footer-limit-select,
.sc24-footer-pagination,
.sc24-search,
.sc24-columns {
    height: 2.75rem;
}
</style>
