import { Control } from "./control";
import { EventHandler } from "..";

export class NumberChangedEventArgs {
    value!: number | null;
}

export class NumberBox extends Control {
    public name = "";
    public label = "";
    public placeholder = "";
    public help = "";
    public min = 1;
    public max = 100;
    public step = 1;
    public size = "md";

    public getComponentName(): string {
        return "NumberBoxComponent";
    }

    private _value: number | null = 1;
    public get value(): number | null {
        return this._value;
    }
    public set value(value: number | null) {
        if (value === this._value) {
            return;
        }

        this._value = value;
        this.notifyValueChangedHandlers();
    }

    private _valueChangedHandlers = new Set<EventHandler<NumberChangedEventArgs>>();
    public addValueChangedHandler(handler: EventHandler<NumberChangedEventArgs>): void {
        this._valueChangedHandlers.add(handler);
    }
    public removeValueChangedHandler(handler: EventHandler<NumberChangedEventArgs>): void {
        this._valueChangedHandlers.delete(handler);
    }
    private notifyValueChangedHandlers(): void {
        const args: NumberChangedEventArgs = { value: this._value };
        for (const handler of this._valueChangedHandlers) {
            handler(this, args);
        }
    }
}
