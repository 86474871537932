import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { BOverlay } from "bootstrap-vue";
//import Prism from "vue-prism-component";
import _color from "@/assets/utils/color.js";

@Component({
    name: "va-card",
    components: {
        BOverlay,
        //Prism
    },
})
export default class VaCard extends Vue {
    @Prop(String)
    private title!: string;

    @Prop(String)
    private subtitle!: string;

    @Prop({ type: Boolean, default: false })
    private actionButtons!: boolean;

    @Prop({ type: String, default: "success" })
    private actionButtonsColor!: string;

    @Prop({ type: Boolean, default: false })
    private codeToggler!: boolean;

    @Prop({ type: Boolean, default: false })
    private noShadow!: boolean;

    @Prop({ type: Boolean, default: false })
    private noRadius!: boolean;

    @Prop({ type: Boolean, default: false })
    private cardBorder!: boolean;

    @Prop({ default: "markup", type: String })
    private codeLanguage!: string;

    @Prop({ type: Boolean, default: false })
    private collapseAction!: boolean;

    @Prop({ type: Boolean, default: false })
    private refreshContentAction!: boolean;

    @Prop({ type: Boolean, default: false })
    private removeCardAction!: boolean;

    @Prop({ default: "", type: String })
    private headerBackground!: string;

    // @Prop({ default: "", type: String })
    // private bodyBackground!: string;

    // @Prop({ default: "", type: String })
    // private headerbackground!: string;

    @Prop({ default: "", type: String })
    private cardBackground!: string;

    @Prop({ default: "", type: String })
    private contentColor!: string;

    @Prop({ default: "", type: String })
    private titleColor!: string;

    @Prop({ default: "#b8c2cc", type: String })
    private subtitleColor!: string;

    private isContentCollapsed: boolean = false;
    private showCode: boolean = false;
    private maxHeight: any = null;
    private cardMaxHeight: any = null;
    private codeContainerMaxHeight: string = "0px";
    private tempHidden: boolean = false;
    private loader: boolean = false;

    private get hasAction(): any {
        return (
            this.$slots.actions ||
            this.actionButtons ||
            this.collapseAction ||
            this.refreshContentAction ||
            this.removeCardAction ||
            this.codeToggler
        );
    }

    private get hasHeader(): boolean {
        return this.hasAction || this.title || this.subtitle;
    }

    private get StyleItems(): any {
        return { maxHeight: this.maxHeight };
    }

    private get cardStyles(): any {
        const obj: any = { maxHeight: this.cardMaxHeight };
        if (!_color.isColor(this.cardBackground)) {
            obj.background = _color.getColor(this.cardBackground);
        }
        if (!_color.isColor(this.contentColor)) {
            obj.color = _color.getColor(this.contentColor);
        }
        return obj;
    }

    private get codeContainerStyles(): any {
        return { maxHeight: this.codeContainerMaxHeight };
    }

    private get cardClasses(): string {
        let str = "";

        // Add bg class
        if (_color.isColor(this.cardBackground)) {
            str += ` bg-${this.cardBackground}`;
        }

        // add content color
        if (_color.isColor(this.contentColor)) {
            str += ` text-${this.contentColor}`;
        }

        return str.trim();
    }

    private get titleStyles(): any {
        return {
            color: _color.getColor(this.titleColor),
        };
    }

    private get titleClasses(): string {
        let str = "text-sm uppercase";

        // add content color
        if (_color.isColor(this.titleColor)) {
            str += ` text-${this.titleColor}`;
        }

        return str.trim();
    }

    private get subtitleStyles(): any {
        const obj: any = {};
        if (!_color.isColor(this.subtitleColor)) {
            obj.color = _color.getColor(this.subtitleColor);
        }

        return obj;
    }

    private get subtitleClasses(): string {
        let str = "";

        // add content color
        if (_color.isColor(this.subtitleColor)) {
            str += ` text-${this.subtitleColor}`;
        }

        return str.trim();
    }

    private toggleContent(): void {
        (this.$refs.content as any).style.overflow = "hidden";
        const scrollHeight = (this.$refs.content as any).scrollHeight;
        if (this.maxHeight === "1.5rem") {
            this.maxHeight = `${scrollHeight}px`;
            setTimeout(() => {
                this.maxHeight = "none";
                (this.$refs.content as any).style.overflow = null;
            }, 300);
        } else {
            this.maxHeight = `${scrollHeight}px`;
            setTimeout(() => {
                this.maxHeight = "1.5rem";
                (this.$refs.content as any).style.overflow = null;
            }, 50);
        }
        this.isContentCollapsed = !this.isContentCollapsed;
        this.$emit("toggleCollapse", this.isContentCollapsed);
    }

    private refreshcard(): void {
        this.loader = true;
        this.tempHidden = true;
        this.$emit("refresh", this);
    }

    private removeRefreshAnimation(time = 100): void {
        setTimeout(() => {
            this.loader = false;
            this.tempHidden = false;
        }, time);
    }

    private removeCard(): void {
        const scrollHeight = (this.$refs.card as any).scrollHeight;
        this.cardMaxHeight = `${scrollHeight}px`;
        (this.$el as any).style.overflow = "hidden";
        setTimeout(() => {
            this.cardMaxHeight = "0px";
        }, 50);
        this.$emit("remove");
    }

    private toggleCode(): void {
        this.tempHidden = true;
        this.showCode = !this.showCode;
        const scrollHeight = (this.$refs.codeContainer as any).scrollHeight;
        if (this.codeContainerMaxHeight === "0px") {
            this.codeContainerMaxHeight = `${scrollHeight}px`;
            setTimeout(() => {
                this.codeContainerMaxHeight = "none";
                this.tempHidden = false;
            }, 300);
        } else {
            this.codeContainerMaxHeight = `${scrollHeight}px`;
            setTimeout(() => {
                this.codeContainerMaxHeight = "0px";
                this.tempHidden = false;
            }, 150);
        }
    }
}
