import { IStore } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Collapse, ComboBox, Control, FileUpload, Html } from "@core/components/alt-ui/controls";
import { ISelectOption } from "@core/types/common/select-options";

export type StoresImportContextIn = { stores: IStore[]; storeId: string };
export type StoresImportContextOut = { storeId?: string; file?: any };

export class StoresImportModal extends Modal<StoresImportContextIn, void> {
    private cbStore!: ComboBox<ISelectOption>;
    private fuFile!: FileUpload;
    private htmlHelp!: Html;
    private clHelp!: Collapse;
    private btnImport!: Button;

    public onImport: ((context: StoresImportContextOut) => Promise<boolean>) | null = null;

    private stores: IStore[] = [];
    private storeId: string | null = null;

    public constructor() {
        super("modal-stores-import", "Импорт товаров");
        this.initializeControls();
    }

    public show(context: StoresImportContextIn): Promise<void> {
        this.stores = context.stores;
        this.storeId = context.storeId;
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        this.cbStore = new ComboBox<ISelectOption>();
        this.fuFile = new FileUpload();
        this.htmlHelp = new Html();
        this.clHelp = new Collapse();
        this.btnImport = new Button();

        //

        const storeOptions: ISelectOption[] = this.stores.map(s => ({ id: s.id, name: s.info.name }));
        const storeOptionFirst = storeOptions.length > 0 ? storeOptions[0] : null;
        const storeSelected = this.storeId
            ? storeOptions.find(o => o.id === this.storeId) ?? storeOptionFirst
            : storeOptionFirst;

        this.cbStore.id = "stores.import.store";
        this.cbStore.label = "Склад:";
        this.cbStore.items = storeOptions;
        this.cbStore.textField = (opt: ISelectOption) => opt.name;
        this.cbStore.selectedItem = storeSelected;

        this.fuFile.id = "stores.import.file";
        this.fuFile.label = "Укажите CSV-файл:";
        this.fuFile.placeholder = "Выберите или перетащите файл";
        this.fuFile.accept = ".csv";

        const linkExample = require("@/assets/documents/import-goods.csv");
        this.htmlHelp.html = `<small>Для импорта принимаются файлы формата CSV:<br/>
            <br/>
            - Информация по каждому товару на новой строке.<br/>
            - Первая строка - заголовки столбцов.<br/>
            - Кодировка файла - UTF-8.<br/>
            - Файл не должен превышать 5 МБ.<br/>
            <br/>
            <a href="${linkExample}" download="import-goods.csv" class="hover:underline">Скачать пример CSV-файла</a></small>`;

        this.clHelp.id = "stores.import.help";
        this.clHelp.title = "Справка";
        this.clHelp.opened = true;
        this.clHelp.addControl(this.htmlHelp);

        this.btnImport.id = "stores.import.import";
        this.btnImport.text = "Импорт";
        this.btnImport.class = "mt-0.25";
        this.btnImport.addClickHandler(this.clickBtnImport.bind(this));
    }

    public get controls(): Control[] {
        return [this.cbStore, this.fuFile, this.clHelp, this.btnImport];
    }

    private async clickBtnImport(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = await this.import();
        if (result) {
            this.hide(result);
        }
    }

    private async import(): Promise<boolean> {
        const fileControl = document.getElementById("stores.import.file") as any;
        const context: StoresImportContextOut = {
            storeId: this.cbStore.selectedItem?.id,
            file: fileControl?.files?.length ? fileControl.files[0] : undefined,
        };

        if (!this.onImport) {
            return false;
        }

        return await this.onImport(context);
    }
}
