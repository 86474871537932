import { ITransactionItemUseCase, ITransactionItem } from "@lib";
import { ITransactionItemCreateDto, ITransactionItemUpdateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class TransactionItemUseCase implements ITransactionItemUseCase {
    public async select(companyId: string): Promise<ITransactionItem[]> {
        try {
            const response = await Api.get<ITransactionItem[]>(`/companies/${companyId}/lists/transactionitems`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    // public async selectForAccount(companyId: string, accountId: string): Promise<ITransactionItem[]> {
    //     try {
    //         const response = await Api.get<ITransactionItem[]>(
    //             `/companies/${companyId}/accounts/${accountId}/transactionitems`,
    //         );
    //         return response.data;
    //     } catch (e: any) {
    //         throw new DataLoadException(e);
    //     }
    // }

    public async get(companyId: string, id: string): Promise<ITransactionItem> {
        try {
            const response = await Api.get<ITransactionItem>(`/companies/${companyId}/lists/transactionitems/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ITransactionItemCreateDto): Promise<ITransactionItem> {
        try {
            const response = await Api.post<ITransactionItem>(`/companies/${companyId}/lists/transactionitems`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: ITransactionItemCreateDto[]): Promise<ITransactionItem[]> {
        try {
            const response = await Api.post<ITransactionItem[]>(
                `/companies/${companyId}/lists/transactionitems/many`,
                dtos,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: ITransactionItemUpdateDto): Promise<ITransactionItem> {
        try {
            const response = await Api.put<ITransactionItem>(
                `/companies/${companyId}/lists/transactionitems/${id}`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/lists/transactionitems/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
