import { TextArea } from "@core/components/alt-ui/controls";
import { FieldControl } from "../field-control";
import { Uuid } from "@/utils/uuid";

export class TextAreaFieldControl extends FieldControl<TextArea> {
    protected convert(): TextArea {
        const control = new TextArea();
        control.id = `${this.field.id}_${Uuid.short()}`;
        control.label = this.field.title ?? "";
        control.help = this.field.description ?? "";
        control.validation = this.validationToString(this.field.validation);
        control.text = this.field.default ?? "";
        return control;
    }

    public get value(): any {
        return this.control.text;
    }

    public set value(value: any) {
        this.control.text = value ? String(value) : "";
    }
}
