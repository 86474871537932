import { IProductTypeUseCase, IProductType } from "@lib";
import { IProductTypeCreateDto, IProductTypeUpdateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class ProductTypeUseCase implements IProductTypeUseCase {
    public async select(companyId: string): Promise<IProductType[]> {
        try {
            const response = await Api.get<IProductType[]>(`/companies/${companyId}/lists/producttypes`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IProductType> {
        try {
            const response = await Api.get<IProductType>(`/companies/${companyId}/lists/producttypes/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IProductTypeCreateDto): Promise<IProductType> {
        try {
            const response = await Api.post<IProductType>(`/companies/${companyId}/lists/producttypes`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: IProductTypeCreateDto[]): Promise<IProductType[]> {
        try {
            const response = await Api.post<IProductType[]>(`/companies/${companyId}/lists/producttypes/many`, dtos);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IProductTypeUpdateDto): Promise<IProductType> {
        try {
            const response = await Api.put<IProductType>(`/companies/${companyId}/lists/producttypes/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/lists/producttypes/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
