import { Vue, Component } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { BForm, BButton } from "bootstrap-vue";
import { IUser } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { PasswordBox } from "@core/components/alt-ui/controls";

@Component({
    components: {
        BForm,
        BButton,
        ValidationObserver,
        ControlComponent,
    },
})
export default class SettingsProfilePassword extends Vue {
    private user!: IUser;

    private passwordExists = true;
    private tbOldPassword: PasswordBox;
    private tbNewPassword: PasswordBox;
    private tbConfirmPassword: PasswordBox;

    public constructor() {
        super();

        this.tbOldPassword = new PasswordBox();
        this.tbOldPassword.id = "password.old-password";
        this.tbOldPassword.label = "Старый пароль";
        this.tbOldPassword.validation = "required|min:6|max:40";

        this.tbNewPassword = new PasswordBox();
        this.tbNewPassword.id = "password.new-password";
        this.tbNewPassword.label = "Новый пароль";
        this.tbNewPassword.validation = "required|min:6|max:40";

        this.tbConfirmPassword = new PasswordBox();
        this.tbConfirmPassword.id = "password.confirm-password";
        this.tbConfirmPassword.label = "Повтор пароля";
        this.tbConfirmPassword.validation = "required|min:6|max:40|confirmed:password.new-password";
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            await this.resetForm();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async save(): Promise<void> {
        try {
            const result = await (this.$refs["formValidator"] as any).validate();
            if (!result) {
                this.$alt.toast.error("Не все поля верно заполнены.");
                return;
            }

            this.$alt.loader.show();

            if (this.tbNewPassword.text !== this.tbConfirmPassword.text) {
                throw new Error("Пароли не совпадают.");
            }

            const usecase = this.$alt.system.usecase.createUserUseCase();
            if (this.passwordExists) {
                await usecase.changePassword(this.user.id, this.tbOldPassword.text, this.tbNewPassword.text);
            } else {
                await usecase.setPassword(this.user.id, this.tbNewPassword.text);
            }

            await this.resetForm();
            this.$alt.toast.success("Пароль изменён.", "Изменение пароля");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить пароль:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async resetForm(): Promise<void> {
        this.tbOldPassword.text = "";
        this.tbNewPassword.text = "";
        this.tbConfirmPassword.text = "";
        (this.$refs["formValidator"] as any).reset();

        const usecase = this.$alt.system.usecase.createAuthSocialsUseCase();
        const socials = await usecase.loadSocialsInfo();
        this.passwordExists = socials.password ?? false;

        this.tbOldPassword.visible = this.passwordExists;
    }
}
