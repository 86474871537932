import { Vue, Component } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ICompany, ITransactionItem, IUser } from "@lib";
import { ITransactionItemCreateDto, ITransactionItemUpdateDto } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import VaTable from "@/components/common/va-table";
import { getTableColumns } from "./settings-lists-transactionitems-defaults";
import { TransactionItemsModal } from "./modals/transactionitems.modal";
import { ModalComponent } from "@core/components/alt-ui/modal";

@Component({
    components: { BButton, VaTable, ModalComponent },
})
export default class SettingsListsTransactionItems extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private transactionItems: ITransactionItem[] = [];

    private TransactionItemUseCase = this.$alt.system.usecase.createTransactionItemUseCase();

    private transactionItemsModal: TransactionItemsModal;

    public constructor() {
        super();

        this.transactionItemsModal = new TransactionItemsModal();
        this.transactionItemsModal.onCreate = this.create.bind(this);
        this.transactionItemsModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectTransactionItems();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected openModalCreate(): void {
        this.transactionItemsModal.show();
    }

    protected openModalUpdate(transactionItem: ITransactionItem): void {
        this.transactionItemsModal.show(transactionItem);
    }

    private async confirmCopy(item: ITransactionItem): Promise<void> {
        const result = await this.$alt.message.confirm(`Скопировать статью "${item.name}"?`, "Копирование статьи", {
            acceptText: "Скопировать",
            color: "primary",
        });

        if (result) {
            await this.copy(item);
        }
    }

    private async confirmDelete(item: ITransactionItem): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить статью: "${item.name}"?`,
            "Удаление статьи",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(item);
        }
    }

    private async selectTransactionItems(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.transactionItems = await this.TransactionItemUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить статью:\n${e.message}`);
        }
    }

    private async create(dto: ITransactionItemCreateDto): Promise<ITransactionItem | null> {
        try {
            this.$alt.loader.show();

            const response = await this.TransactionItemUseCase.create(this.company.id, dto);
            await this.selectTransactionItems();

            this.$alt.toast.success(`Статья "${dto.name}" успешно создана.`, "Создание");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать статью:\n${e.message}`);

            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(
        transactionItem: ITransactionItem,
        dto: ITransactionItemUpdateDto,
    ): Promise<ITransactionItem | null> {
        try {
            this.$alt.loader.show();

            const response = await this.TransactionItemUseCase.update(this.company.id, transactionItem.id, dto);
            await this.selectTransactionItems();

            this.$alt.toast.success(`Статья "${transactionItem.name}" успешно изменена.`, "Изменение");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить статью:\n${e.message}`);

            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(item: ITransactionItem): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: ITransactionItemCreateDto = {
                accounts: item.accounts as string[],
                //sequence: undefined,
                type: item.type,
                name: item.name + " - копия",
                description: item.description,
                tags: item.tags,
            };
            await this.TransactionItemUseCase.create(this.company.id, dto);
            await this.selectTransactionItems();
            this.$alt.toast.success(`Статья "${item.name}" успешно скопирована.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать статью:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(item: ITransactionItem): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.TransactionItemUseCase.archive(this.company.id, item.id);
            await this.selectTransactionItems();
            this.$alt.toast.success(`Статья "${item.name}" успешно удалена.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить статью:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
