import {
    IOffice,
    IOrder,
    IClient,
    IUser,
    LegalType,
    IOrderType,
    ISale,
    IShop,
    WarrantyType,
    IGood,
    IStore,
    BarcodeType,
    ICompany,
    CompanyFeature,
    CompanyPricingPlan,
    IProductType,
    FieldType,
    FieldFormat,
    IOrderTypeFieldGroup,
    IDiscount,
    DiscountType,
    Currency,
    Locale,
    PaymentType,
    SaleStageType,
} from "@lib";

export function createDemoOrder(): IOrder {
    const office = createDemoOffice();

    const client: IClient = {
        id: "618e48d930d18f154ca539d9",
        company: "614c4ebff6f3ac0b74b82b8c",
        number: 1467,
        info: {
            type: LegalType.Natural,
            name: "Иванов Иван",
            contacts: {
                email: "ivan.client@altsc.ru",
                phone: "+79997771467",
            },
        },
        createdAt: new Date(),
        updatedAt: new Date(),
    };

    const manager: IUser = {
        id: "614c4ebff6f3ac0b74b82b8b",
        info: {
            name: "Серёга",
            officialName: "Сергеев Сергей",
            contacts: {
                email: "sergey.service@altsc.ru",
                phone: "+79997770001",
            },
        },
        createdAt: new Date(),
        updatedAt: new Date(),
    };

    return {
        id: "618e48d930d18f154ca539da",
        company: "614c4ebff6f3ac0b74b82b8c",
        office: office,
        number: 1523,
        trackingCode: "YTEN296722",
        info: {
            //name: "",
            //description: "",
            priority: 3,
            createDate: new Date(),
            deadline: new Date(),
            discount: {
                type: DiscountType.Percent,
                value: 10,
            } as IDiscount,
        },
        custom: { costapprox: "3000" },
        type: "614c4ec0f6f3ac0b74b82b93",
        stage: "614c4ec0f6f3ac0b74b82b99",
        client: client,
        manager: manager,
        products: [
            {
                id: "618e48d930d18f154ca539db",
                info: {
                    type: "614c4ec0f6f3ac0b74b82b9b",
                    name: "iPhone X",
                    description: "qweqwe",
                    serial: "",
                    suite: "",
                    appearance: "Царапины, потёртости",
                    parameters: { codeblock: "1234" },
                },
            },
        ],
        works: [
            {
                id: "618e48e830d18f154ca539e0",
                name: "Диагностика",
                description: "",
                price: 500,
                quantity: 1,
                employee: "614c4ebff6f3ac0b74b82b8b",
            },
            {
                id: "618e490e30d18f154ca539e5",
                name: "Замена экрана",
                description: "",
                price: 500,
                quantity: 1,
                warranty: { value: "30", type: WarrantyType.Day },
                employee: "614c4ebff6f3ac0b74b82b8b",
            },
        ],
        materials: [
            {
                id: "618e490630d18f154ca539e2",
                sku: "",
                name: "Экран iPhone X",
                description: "",
                cost: 1000,
                price: 2000,
                quantity: 1,
                warranty: { value: 12, type: WarrantyType.Month },
                work: "618e490e30d18f154ca539e5",
            },
        ],
        payments: [
            {
                id: "618e48da30d18f154ca539de",
                type: PaymentType.Prepayment,
                account: "614c4ec0f6f3ac0b74b82b90",
                value: 1000,
                description: "Предоплата",
                user: "614c4ebff6f3ac0b74b82b8b",
                updatedAt: "2021-11-12T10:58:34.224Z",
                createdAt: "2021-11-12T10:58:34.224Z",
            },
            {
                id: "618e492430d18f154ca539ea",
                type: PaymentType.Payment,
                account: "614c4ec0f6f3ac0b74b82b90",
                value: 1700,
                description: "Оплата заявки",
                user: "614c4ebff6f3ac0b74b82b8b",
                updatedAt: "2021-11-12T10:59:48.228Z",
                createdAt: "2021-11-12T10:59:48.228Z",
            },
        ],
        timeline: [],
        tags: ["order"],
        done: true,
        doneAt: "2021-11-12T10:59:48.192Z",
        createdAt: "2021-11-12T10:58:34.004Z",
        updatedAt: "2021-11-12T10:59:48.262Z",
    } as any;

    // "timeline":[
    //     {"id":{"$oid":"618e48da30d18f154ca539dc"},"type":"create","author":{"$oid":"614c4ebff6f3ac0b74b82b8b"},"datetime":{"$date":"2021-11-12T10:58:34.041Z"}},
    //     {"id":{"$oid":"618e48da30d18f154ca539df"},"type":"payment","text":"500","author":{"$oid":"614c4ebff6f3ac0b74b82b8b"},"datetime":{"$date":"2021-11-12T10:58:34.230Z"}},
    //     {"id":{"$oid":"618e48e830d18f154ca539e1"},"type":"work-create","text":"Diagnostic","author":{"$oid":"614c4ebff6f3ac0b74b82b8b"},"datetime":{"$date":"2021-11-12T10:58:48.280Z"}},
    //     {"id":{"$oid":"618e490630d18f154ca539e4"},"type":"material-create","text":"iPhone X detail","author":{"$oid":"614c4ebff6f3ac0b74b82b8b"},"datetime":{"$date":"2021-11-12T10:59:18.443Z"}},
    //     {"id":{"$oid":"618e490e30d18f154ca539e6"},"type":"work-create","text":"Repair","author":{"$oid":"614c4ebff6f3ac0b74b82b8b"},"datetime":{"$date":"2021-11-12T10:59:26.270Z"}},
    //     {"id":{"$oid":"618e491930d18f154ca539e8"},"type":"material-update","text":"iPhone X detail","author":{"$oid":"614c4ebff6f3ac0b74b82b8b"},"datetime":{"$date":"2021-11-12T10:59:37.200Z"}},
    //     {"id":{"$oid":"618e492430d18f154ca539eb"},"type":"payment","text":"1500","author":{"$oid":"614c4ebff6f3ac0b74b82b8b"},"datetime":{"$date":"2021-11-12T10:59:48.233Z"}},
    //     {"id":{"$oid":"618e492430d18f154ca539ec"},"type":"stage","text":"614c4ec0f6f3ac0b74b82b99","author":{"$oid":"614c4ebff6f3ac0b74b82b8b"},"datetime":{"$date":"2021-11-12T10:59:48.248Z"}},
    //     {"id":{"$oid":"618e492430d18f154ca539ed"},"type":"done","author":{"$oid":"614c4ebff6f3ac0b74b82b8b"},"datetime":{"$date":"2021-11-12T10:59:48.262Z"}}
    // ],
}

export function createDemoOffice(): IOffice {
    return {
        id: "614c4ec0f6f3ac0b74b82b8e",
        company: "614c4ebff6f3ac0b74b82b8c",
        number: 1,
        info: {
            name: "Мой сервис",
            contacts: {
                email: "my.service@altsc.ru",
                phone: "+79991112233",
                address: "Екатерининский пр. 111, ТЦ Пиксель, 2 этаж 10 секция",
            },
            currency: Currency.RUB,
            locale: Locale.RU,
        },
        createdAt: new Date(),
        updatedAt: new Date(),
    } as IOffice;
}

export function createDemoOrderType(): IOrderType {
    return {
        id: "614c4ec0f6f3ac0b74b82b93",
        offices: [],
        company: "614c4ebff6f3ac0b74b82b8c",
        sequence: 1,
        name: "Ремонт",
        description: "Ремонт цифровой техники",
        groups: getTemplateDeviceRepair(),
        createdAt: "2021-09-23T09:54:08.208Z",
        updatedAt: "2021-09-23T09:54:08.208Z",
    } as any;
}

/** TODO: упростить для демо. Ремонт электроники. */
function getTemplateDeviceRepair(): IOrderTypeFieldGroup[] {
    return [
        {
            id: "client",
            sequence: 10,
            title: "Клиент",
            fields: [
                {
                    id: "client.name",
                    sequence: 20,
                    standart: "name",
                    type: FieldType.String,
                    title: "Имя",
                    validation: { required: true },
                },
                {
                    id: "client.phone",
                    sequence: 60,
                    standart: "phone",
                    type: FieldType.Phone,
                    title: "Телефон",
                },
                {
                    id: "client.comment",
                    sequence: 70,
                    standart: "comment",
                    type: FieldType.Text,
                    title: "Комментарий",
                },
            ],
        },
        {
            id: "product",
            sequence: 20,
            title: "Устройство",
            fields: [
                {
                    id: "product.type",
                    sequence: 10,
                    standart: "type",
                    type: FieldType.Select,
                    title: "Тип",
                    details1: "select.standart",
                    details2: "$(компания.списки.типыпродуктов)",
                    default: "$(первый)",
                    //macros
                },
                {
                    id: "product.name",
                    sequence: 20,
                    standart: "name",
                    type: FieldType.String,
                    title: "Название",
                    validation: { required: true },
                },
                {
                    id: "product.description",
                    sequence: 30,
                    standart: "description",
                    type: FieldType.Text,
                    title: "Неисправность",
                    validation: { required: true },
                },
                {
                    id: "product.serial",
                    sequence: 60,
                    standart: "serial",
                    type: FieldType.String,
                    title: "Серийный номер",
                },
                {
                    id: "product.suite",
                    sequence: 70,
                    standart: "suite",
                    type: FieldType.Text,
                    title: "Комплектация",
                },
                {
                    id: "product.appearance",
                    sequence: 80,
                    standart: "appearance",
                    type: FieldType.Text,
                    title: "Внешний вид",
                    default: "Царапины, потёртости",
                },
                {
                    id: "product.codeblock",
                    sequence: 90,
                    custom: true,
                    customId: "codeblock",
                    type: FieldType.String,
                    title: "Код разблокировки",
                },
                // {
                //     // TODO: test
                //     id: "product.color",
                //     sequence: 95,
                //     custom: true,
                //     customId: "color",
                //     type: FieldType.String,
                //     title: "Цвет",
                // }
            ],
        },
        {
            id: "info",
            sequence: 30,
            title: "Прочее",
            fields: [
                {
                    id: "costapprox",
                    sequence: 10,
                    custom: true,
                    customId: "costapprox",
                    type: FieldType.String,
                    title: "Примерная цена",
                    default: "0",
                    validation: {
                        money: true,
                        unsigned: true,
                    },
                    macros: ["заявка.примернаяцена", "order.costapprox"],
                    format: FieldFormat.Money,
                },
            ],
        },
        {
            id: "payment",
            sequence: 40,
            title: "Оплата",
            fields: [
                {
                    id: "payment.value",
                    sequence: 10,
                    standart: "value",
                    type: FieldType.String,
                    title: "Предоплата",
                    default: "0",
                    validation: {
                        money: true,
                        unsigned: true,
                    },
                },
                {
                    id: "payment.account",
                    sequence: 20,
                    standart: "account",
                    type: FieldType.Select,
                    title: "Счёт",
                    details1: "select.standart",
                    details2: "$(компания.счета)",
                    default: "$(первый)",
                },
            ],
        },
    ];
}

export function createProductType(): IProductType {
    return {
        id: "614c4ec0f6f3ac0b74b82b9b",
        company: "614c4ebff6f3ac0b74b82b8c",
        sequence: 1,
        name: "Телефон",
        description: "Телефоны, смартфоны, КПК",
        createdAt: "2021-09-23T09:54:08.208Z",
        updatedAt: "2021-09-23T09:54:08.208Z",
    } as any;
}

export function createDemoSale(): ISale {
    const shop = createDemoShop();

    return {
        id: "6156f6bd521d7a29ec96abce",
        company: "614c4ebff6f3ac0b74b82b8c",
        shop: shop,
        number: 1012,
        info: {
            createDate: "2021-10-01T11:53:33.641Z",
            discount: {
                type: DiscountType.Fixed,
                value: 100,
            },
        },
        stage: SaleStageType.Paid,
        seller: "614c4ebff6f3ac0b74b82b8b",
        goods: [
            {
                id: "6156f6bd521d7a29ec96abcf",
                sku: "",
                name: "Задняя панель Nokia (синяя)",
                cost: 100,
                price: 200,
                quantity: 1,
                warranty: { value: 1, type: WarrantyType.Year },
                store: "614c4ec0f6f3ac0b74b82b91",
                good: "6156f68b521d7a29ec96abcd",
            },
            {
                id: "6156f6bd521d7a29ec96abcf",
                sku: "",
                name: "Защитное стекло Nokia",
                cost: 200,
                price: 400,
                quantity: 2,
                warranty: { value: 30, type: WarrantyType.Day },
                store: "614c4ec0f6f3ac0b74b82b91",
                good: "6156f68b521d7a29ec96abcd",
            },
        ],
        payments: [
            {
                id: "6156f6bd521d7a29ec96abd0",
                type: PaymentType.Payment,
                account: "614c4ec0f6f3ac0b74b82b90",
                value: 900,
                description: "Оплата в магазине",
                user: "614c4ebff6f3ac0b74b82b8b",
                createdAt: "2021-10-01T11:53:33.703Z",
                updatedAt: "2021-10-01T11:53:33.703Z",
            },
        ],
        done: true,
        doneAt: "2021-10-01T11:53:33.697Z",
        createdAt: "2021-10-01T11:53:33.703Z",
        updatedAt: "2021-10-01T11:53:33.703Z",
    } as any;
}

export function createDemoShop(): IShop {
    return {
        id: "614c4ec0f6f3ac0b74b82b8f",
        company: "614c4ebff6f3ac0b74b82b8c",
        number: 1,
        info: {
            name: "Мой магазин",
            contacts: {
                email: "my.shop@altsc.ru",
                phone: "+79992223344",
                address: "Екатерининский пр. 112, ТЦ Пиксель, 2 этаж 10 секция",
            },
            currency: Currency.RUB,
            locale: Locale.RU,
        },
        createdAt: new Date(),
        updatedAt: new Date(),
    } as IShop;
}

export function createDemoGoods(): IGood[] {
    const store = createDemoStore();

    return [
        {
            id: "6156f68b521d7a29ec96abcd",
            company: "614c4ebff6f3ac0b74b82b8c",
            info: {
                sku: "NKPNL-1111BLUE",
                name: "Задняя панель Nokia (синяя)",
                cost: 100,
                price: 200,
                quantity: 3,
                warranty: { value: "1", type: WarrantyType.Year },
                barcodes: [
                    {
                        type: BarcodeType.EAN13,
                        code: "2100000000050",
                    },
                ],
            },
            store: store.id,
            storeRef: store,
            createdAt: new Date(),
            updatedAt: new Date(),
        },
        {
            id: "6156f68b521d7a29ec96abce",
            company: "614c4ebff6f3ac0b74b82b8c",
            info: {
                sku: "NKPNL-1111BLACK",
                name: "Задняя панель Nokia (чёрная)",
                cost: 100,
                price: 200,
                quantity: 2,
                warranty: { value: "1", type: WarrantyType.Year },
                barcodes: [
                    {
                        type: BarcodeType.EAN13,
                        code: "2100000000067",
                    },
                ],
            },
            store: store.id,
            storeRef: store,
            createdAt: new Date(),
            updatedAt: new Date(),
        },
        {
            id: "6156f68b521d7a29ec96abcf",
            company: "614c4ebff6f3ac0b74b82b8c",
            info: {
                sku: "NKGLS-2222",
                name: "Защитное стекло Nokia",
                cost: 200,
                price: 400,
                quantity: 4,
                warranty: { value: "30", type: WarrantyType.Day },
                barcodes: [
                    {
                        type: BarcodeType.EAN13,
                        code: "2100000000081",
                    },
                ],
            },
            store: store.id,
            storeRef: store,
            createdAt: new Date(),
            updatedAt: new Date(),
        },
    ];
}

export function createDemoStore(): IStore {
    return {
        id: "614c4ec0f6f3ac0b74b82b91",
        company: "614c4ebff6f3ac0b74b82b8c",
        number: 1,
        info: {
            name: "Мой склад",
            currency: Currency.RUB,
            locale: Locale.RU,
        },
        createdAt: new Date(),
        updatedAt: new Date(),
    } as IStore;
}

export function createDemoCompany(): ICompany {
    return {
        id: "614c4ebff6f3ac0b74b82b8c",
        info: {
            name: "Моя компания",
            description: "Описание моей компании",
            contacts: {
                email: "my.shop@altsc.ru",
                phone: "+79992223344",
                street: "Екатерининский пр., д. 110",
                city: "Санкт-Петербург",
                country: "Россия",
                zip: "195012",
                address: "Екатерининский пр. 110, ТЦ Пиксель, 2 этаж 10 секция",
            },
        },
        features: {
            [CompanyFeature.Barcodes]: { enabledForGoods: true },
        },
        owners: [],
        balance: 10000,
        pricing: { date: new Date(), plan: CompanyPricingPlan.Free10 },
        createdAt: new Date(),
        updatedAt: new Date(),
    };
}
