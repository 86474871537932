/** Тип этапа заявки. */
export enum OrderStageType {
    /** Новая. */
    New = "new",

    /** В работе. */
    InWork = "inwork",

    /** Отложена. */
    Deferred = "deferred",

    /** Готова. */
    Ready = "ready",

    /** Закрыта. */
    Closed = "closed",

    /** Отменена. */
    Canceled = "canceled",
}
