import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormCheckbox } from "bootstrap-vue";
import { CheckBoxGroup } from "./check-box-group";

@Component({
    name: "check-box-group-component",
    components: { BFormCheckbox },
})
export class CheckBoxGroupComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: CheckBoxGroup;

    public get options(): any[] {
        return this.handler.items.map(item => ({
            value: item,
            text: this.handler.textField ? this.handler.textField(item) : "",
            disabled: this.handler.disabledField ? this.handler.disabledField(item) : false,
            //style: undefined,
            //class: undefined,
        }));
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class="m-0" id={this.handler.id}>
                    {this.renderLabel()}
                    {this.renderCheckBoxes()}
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderLabel(): VNode {
        return this.handler.label.length > 0 ? <label class="mb-0.25">{this.handler.label}</label> : <div />;
    }

    private renderCheckBoxes(): VNode[] {
        const checkboxes: VNode[] = [];
        for (const option of this.options) {
            checkboxes.push(
                <b-form-checkbox
                    class={`ml-0.5 mb-50 ${option.class}`}
                    style={option.style}
                    disabled={option.disabled}
                    value={option.value}
                    v-model={this.handler.selectedItems}
                >
                    {option.text}
                </b-form-checkbox>,
            );
        }
        return checkboxes;
    }
}
