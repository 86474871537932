import { Vue, Component } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BButton, BForm, BFormInput, BFormGroup, BCard, BLink } from "bootstrap-vue";
import { BCardTitle, BCardText, BFormCheckbox, BTooltip } from "bootstrap-vue";
import { BInputGroup, BInputGroupPrepend, BInputGroupAppend } from "bootstrap-vue";
import { CompanyType, IAuthRegistrationDto, IAuthRegistrationResultDto, Social } from "@lib";
import VaIcon from "@core/components/va-icon/va-icon.vue";
import VaLogo from "@/components/common/va-logo/va-logo-full.vue";
import { SocialAction } from "../socials";

@Component({
    components: {
        BCard,
        BLink,
        BCardTitle,
        BCardText,
        BForm,
        BButton,
        BFormCheckbox,
        BFormInput,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        BTooltip,
        ValidationProvider,
        ValidationObserver,
        VaIcon,
        VaLogo,
    },
})
export default class Registration extends Vue {
    private resources = {
        LabelTitleText: "ДАННЫЕ ДЛЯ ВХОДА",
        LabelSubtitle1Text: "Приветствуем!",
        LabelSubtitle2Text: "Пожалуйста, введите данные для входа:",
        TextBoxNameLabel: "Имя",
        TextBoxEmailLabel: "Почта",
        TextBoxPasswordLabel: "Пароль",
        LinkLoginText: "Уже есть аккаунт",
        CheckBoxTermsText1: "Принимаю",
        CheckBoxTermsText2: "пользовательское соглашение",
        CheckBoxTermsText3: "и даю согласие на",
        CheckBoxTermsText4: "обработку персональных данных",
        ButtonText: "Войти",
        ValidatorId: "registrationForm",
    };

    private username = "";
    private email = "";
    private password = "";
    private termsAccepted = true;
    private passwordVisible: boolean = false;

    private linkEula = require("@/assets/pdf/eula.pdf");
    private linkLegal = require("@/assets/pdf/legal.pdf");

    public mounted(): void {
        const companyType = this.getCompanyType();
        this.$alt.setToLocalStorage("companyType", companyType);

        const partnerId = this.getPartnerId();
        this.$alt.setToLocalStorage("partner", partnerId);

        const meta = this.getMetaData();
        this.$alt.setToLocalStorage("meta", meta ? JSON.stringify(meta) : undefined);
    }

    private togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    private async register(): Promise<void> {
        try {
            const valid = await (this.$refs[this.resources.ValidatorId] as any).validate();
            if (!valid) {
                return;
            }

            this.$alt.loader.show();

            const companyType = this.getCompanyType();
            const partnerId = this.getPartnerId();
            const meta = this.getMetaData();

            const dto: IAuthRegistrationDto = {
                username: this.username,
                email: this.email,
                password: this.password,
                companyType: companyType,
                partnerId: partnerId,
                meta: meta,
            };

            const usecase = this.$alt.system.usecase.createAuthUseCase();
            const result = await usecase.register(dto);
            this.applyCompanyData(result);
            this.createYandexGoal();

            await this.$router.push({ name: "orders", query: { welcome: "" } });
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private getCompanyType(): CompanyType | undefined {
        if (this.$route.query.type) {
            if (typeof this.$route.query.type === "string") {
                return this.$route.query.type as CompanyType;
            }
        }
        return undefined;
    }

    private getPartnerId(): string | undefined {
        if (this.$route.query.partner) {
            if (typeof this.$route.query.partner === "string") {
                return this.$route.query.partner;
            }
        }
        return undefined;
    }

    private getMetaData(): any | undefined {
        if (this.$route.query.utm_source) {
            return {
                utm: {
                    utm_source: this.$route.query.utm_source,
                    utm_medium: this.$route.query.utm_medium,
                    utm_campaign: this.$route.query.utm_campaign,
                    utm_content: this.$route.query.utm_content,
                    utm_term: this.$route.query.utm_term,
                },
            };
        }
        return undefined;
    }

    private async applyCompanyData(result: IAuthRegistrationResultDto): Promise<void> {
        this.$alt.removeFromLocalStorage("companyType");
        this.$alt.removeFromLocalStorage("partner");
        this.$alt.removeFromLocalStorage("meta");

        this.$alt.system.token.cleanData();
        this.$alt.system.token.setCompanyToken(result.accessToken);

        this.$info.setAuthInfo(result.data);
        this.$settings.setUserInfo(result.data.user.user.id);
        if (result.data.company) {
            this.$secure.grant(result.data.company.permissions);
        }
    }

    private createYandexGoal(): void {
        try {
            (window as any)["ym"](71328052, "reachGoal", "REGISTERED");
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.log(`Yandex Metrik Error: ${e.message}`);
        }
    }

    public async authorizeWithYandex(): Promise<void> {
        return this.authorizeWithSocial(Social.Yandex);
    }

    public async authorizeWithVK(): Promise<void> {
        return this.authorizeWithSocial(Social.VK);
    }

    public async authorizeWithOK(): Promise<void> {
        return this.authorizeWithSocial(Social.OK);
    }

    public async authorizeWithGoogle(): Promise<void> {
        return this.authorizeWithSocial(Social.Google);
    }

    private async authorizeWithSocial(social: Social): Promise<void> {
        try {
            this.$alt.loader.show();

            const socialUri = this.$router.resolve({
                name: "socials",
                params: { action: SocialAction.Registration, social },
            }).href;
            const redirectUri = `${window.location.origin}${socialUri}`;

            const usecase = this.$alt.system.usecase.createAuthSocialsUseCase();
            const uri = await usecase.getAuthorizationUri(social, redirectUri);

            if (uri && uri.length > 0) {
                window.location = uri as any;
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
