import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BBadge } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { ISelectQuery, IGood, IStore } from "@lib";
import { ISaleGoodItem } from "./sale-create-goods";
import { DelayedOperation } from "@/utils/delayed-operation";

@Component({
    name: "sale-search-goods",
    components: {
        BBadge,
        VueAutosuggest,
    },
})
export default class SaleSearchGoods extends Vue {
    @Prop({ type: Number, required: true })
    private id!: number;

    @Prop({ type: Array, default: () => [] })
    private availableStores!: IStore[];

    private search: string = "";
    private searchGoodModal = {
        goods: [] as IGood[],
        total: 0,
    };

    @Watch("id")
    private onIdChanged(): void {
        this.initValues();
    }

    public mounted(): void {
        this.initValues();
    }

    private initValues(): void {
        this.search = "";
        (document.getElementById("searchInput") as any)?.focus();
    }

    private getSuggestionValue(suggestion: { item: any }): string {
        return this.search;
    }

    private chooseGoodAndHide(suggestion: { item: any }): void {
        if (!suggestion) {
            return;
        }

        this.chooseGood(suggestion.item);

        this.searchGoodModal.goods = [];
        this.searchGoodModal.total = 0;
    }

    private async changeSearch(): Promise<void> {
        DelayedOperation.invoke("search-good", 700, () => this.searchGoods(this.search));
    }

    private async searchGoods(search: string): Promise<void> {
        try {
            if (this.search.length < 2 || this.availableStores.length === 0) {
                this.searchGoodModal.goods = [];
                this.searchGoodModal.total = 0;
                return;
            }

            const company = await this.$info.getCompany();
            const tfilter = [{ field: "quantity", operator: "gt", value: "0" }];
            const filter = this.$alt.system.query.convertTableFilter(tfilter);
            filter["store[in]"] = this.availableStores.map(store => store.id);
            const options: ISelectQuery = { ...filter, search, limit: 20 };
            const result = await this.$alt.system.usecase.createGoodUseCase().select(company.id, options);

            const goods = result.data;

            if (goods.length === 1) {
                this.chooseGood(goods[0]);
                this.searchGoodModal.goods = [];
                this.searchGoodModal.total = 0;
            }

            if (goods.length > 1) {
                this.searchGoodModal.goods = goods;
                this.searchGoodModal.total = result.total;
            }

            if (goods.length === 0) {
                this.$alt.toast.warning("Ничего не найдено.");
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private chooseGood(orig: IGood): void {
        const dto: ISaleGoodItem = {
            sku: orig.info.sku,
            name: orig.info.name,
            description: orig.info.description,
            cost: orig.info.cost,
            price: orig.info.price,
            quantity: 1,
            warranty: orig.info.warranty,

            storeId: orig.store,
            goodId: orig.id,
            goodRef: orig,
        };

        this.$emit("found", dto, orig);
    }
}
