/** Генератор гуидов. */
export abstract class Uuid {
    public static new(): string {
        // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    public static short(): string {
        // https://stackoverflow.com/questions/6248666/how-to-generate-short-uid-like-ax4j9z-in-js
        const first = (Math.random() * 46656) | 0;
        const second = (Math.random() * 46656) | 0;
        const firstPart = ("000" + first.toString(36)).slice(-3);
        const secondPart = ("000" + second.toString(36)).slice(-3);
        return firstPart + secondPart;
    }
}
