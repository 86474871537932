/** Языковой стандарт. */
export enum Locale {
    /** Русский. */
    RU = "ru-RU",

    /** Английский. */
    EN = "en-US",
}

// TODO: обобщить до "ru", "en", чтобы не привязываться к стране
