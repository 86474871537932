import { ITrackResultDto, OrderStageType, SaleStageType } from "@lib";
import { BButton, BFormInput, BInputGroup, BInputGroupPrepend, BCard, BCardTitle, BBadge } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";
import VaLogo from "@/components/common/va-logo/va-logo-full.vue";
import { ApiError } from "@/exceptions";

@Component({
    name: "tracking",
    components: {
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BButton,
        BCard,
        BCardTitle,
        BBadge,
        ValidationProvider,
        ValidationObserver,
        VaLogo,
    },
})
export default class Tracking extends Vue {
    private TrackingUseCase = this.$alt.system.usecase.createTrackingUseCase();

    private resources = {
        TitleText: "Поиск по трек-номеру",
        LabelTrackingCode: "Трек-номер",
        ButtonStart: "Искать",
        ButtonBack: "Назад",
        ValidatorId: "trackingCodeValidator",
    };

    private code: string = "";
    private trackError: string | null = null;
    private trackResult: ITrackResultDto | null = null;

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();

            this.code = this.$router.currentRoute.params.code;
            if (!this.code) {
                return;
            }

            await this.start();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async start(): Promise<boolean> {
        try {
            if (this.code.length === 0) {
                return false;
            }

            this.trackResult = await this.TrackingUseCase.track(this.code);
            return true;
        } catch (e: any) {
            if (e.code === ApiError.TRACKING_CODE_NOT_FOUND) {
                this.trackError = "Трек-номер не найден.";
            } else {
                this.trackError = e.message;
            }
            return false;
        }
    }

    private back(): void {
        this.code = "";
        this.trackResult = null;
        this.trackError = null;
        this.$router.replace({ name: "tracking" });
    }

    private async onClickStart(): Promise<void> {
        try {
            const valid = await (this.$refs[this.resources.ValidatorId] as any).validate();
            if (!valid) {
                return;
            }

            this.$alt.loader.show();

            await this.start();
            this.$router.push({ name: "tracking", params: { code: this.code } });
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private getStageColor(trackResult: ITrackResultDto): string | undefined {
        if (trackResult.type === "order") {
            return this.getOrderStageColor(trackResult.info.stage as OrderStageType);
        }

        if (trackResult.type === "sale") {
            return this.getSaleStageColor(trackResult.info.stage as SaleStageType);
        }

        return "danger";
    }

    private getOrderStageColor(stage: OrderStageType): string {
        switch (stage) {
            case OrderStageType.New:
                return "primary";
            case OrderStageType.InWork:
            case OrderStageType.Deferred:
                return "warning";
            case OrderStageType.Ready:
                return "success";
            case OrderStageType.Closed:
            case OrderStageType.Canceled:
                return "secondary";
            default:
                return "danger";
        }
    }

    private getSaleStageColor(stage: SaleStageType): string {
        switch (stage) {
            case SaleStageType.New:
            case SaleStageType.Open:
                return "primary";
            case SaleStageType.Processing:
            case SaleStageType.Delivery:
                return "warning";
            case SaleStageType.Ready:
                return "success";
            case SaleStageType.Paid:
            case SaleStageType.Closed:
            case SaleStageType.Canceled:
            case SaleStageType.Return:
                return "secondary";
        }
    }

    private getStageText(trackResult: ITrackResultDto): string | undefined {
        if (trackResult.type === "order") {
            return this.getOrderStageText(trackResult.info.stage as OrderStageType);
        }

        if (trackResult.type === "sale") {
            return this.getSaleStageText(trackResult.info.stage as SaleStageType);
        }

        return "Неизвестно";
    }

    private getOrderStageText(stage: OrderStageType): string {
        switch (stage) {
            case OrderStageType.New:
                return "Создано";
            case OrderStageType.InWork:
            case OrderStageType.Deferred:
                return "В работе";
            case OrderStageType.Ready:
                return "Готово";
            case OrderStageType.Closed:
                return "Закрыто";
            case OrderStageType.Canceled:
                return "Отменено";
            default:
                return "Неизвестно";
        }
    }

    private getSaleStageText(stage: SaleStageType): string {
        switch (stage) {
            case SaleStageType.New:
            case SaleStageType.Open:
                return "Создано";
            case SaleStageType.Processing:
                return "В сборке";
            case SaleStageType.Delivery:
                return "В доставке";
            case SaleStageType.Ready:
                return "Готово к выдаче";
            case SaleStageType.Paid:
            case SaleStageType.Closed:
                return "Закрыто";
            case SaleStageType.Canceled:
                return "Отменено";
            case SaleStageType.Return:
                return "Возвращено";
            default:
                return "Неизвестно";
        }
    }
}
