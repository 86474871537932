export class TokenService {
    // токен пользователя в компании

    private readonly StorageAccessTokenKeyName = "accessToken";

    public hasCompanyToken(): boolean {
        return !!localStorage.getItem(this.StorageAccessTokenKeyName);
    }

    public getCompanyToken(): string | null {
        return localStorage.getItem(this.StorageAccessTokenKeyName);
    }

    public setCompanyToken(token: string): void {
        localStorage.setItem(this.StorageAccessTokenKeyName, token);
    }

    public removeCompanyToken(): void {
        localStorage.removeItem(this.StorageAccessTokenKeyName);
    }

    // токен пользователя без компании

    private readonly StorageUserOnlyTokenKeyName = "userToken";

    public hasUserOnlyToken(): boolean {
        return !!localStorage.getItem(this.StorageUserOnlyTokenKeyName);
    }

    public getUserOnlyToken(): string | null {
        return localStorage.getItem(this.StorageUserOnlyTokenKeyName);
    }

    public setUserOnlyToken(token: string): void {
        localStorage.setItem(this.StorageUserOnlyTokenKeyName, token);
    }

    public removeUserOnlyToken(): void {
        localStorage.removeItem(this.StorageUserOnlyTokenKeyName);
    }

    // токен администратора

    private readonly StorageAdminTokenKeyName = "adminToken";

    public hasAdminToken(): boolean {
        return !!localStorage.getItem(this.StorageAdminTokenKeyName);
    }

    public getAdminToken(): string | null {
        return localStorage.getItem(this.StorageAdminTokenKeyName);
    }

    public setAdminToken(token: string): void {
        localStorage.setItem(this.StorageAdminTokenKeyName, token);
    }

    public removeAdminToken(): void {
        localStorage.removeItem(this.StorageAdminTokenKeyName);
    }

    //

    public hasAnyToken(): boolean {
        return this.hasCompanyToken() || this.hasUserOnlyToken();
    }

    public cleanData(): void {
        this.removeCompanyToken();
        this.removeUserOnlyToken();
        this.removeAdminToken();
    }
}
