/** Тип таблицы. */
export enum TableType {
    Order = "order",
    Sale = "sale",
    Client = "client",
    GoodRegistration = "good-registration",
    GoodWriteOff = "good-write-off",
    GoodInventory = "good-inventory",
    Good = "good",
    Transaction = "transaction",
    Salary = "salary",
    Report = "report",
    Work = "work",
}
