import { render, staticRenderFns } from "./va-table.vue?vue&type=template&id=0c162ea7&scoped=true&"
import script from "./va-table.ts?vue&type=script&lang=ts&"
export * from "./va-table.ts?vue&type=script&lang=ts&"
import style0 from "./va-table.scss?vue&type=style&index=0&id=0c162ea7&lang=scss&scoped=true&"
import style1 from "./va-table.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c162ea7",
  null
  
)

export default component.exports