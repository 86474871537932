import Vue from "vue";
import Router from "vue-router";
import { MenuItemsAdmin } from "../layouts/components/sidebar/menu-items-admin";

Vue.use(Router);

enum AuthType {
    Public = "public",
    Unauth = "unauth",
    Admin = "admin",
}

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        // =============================================================================
        // PUBLIC PAGES
        // =============================================================================
        {
            path: "",
            component: () => import("@/layouts/full-page/index.vue"),
            children: [
                {
                    path: "/",
                    name: "landing",
                    component: () => import("@/views/community/landing.vue"),
                    meta: {
                        auth: AuthType.Public,
                    },

                    // TODO: с лендинга перекидывать на страницу логина
                    redirect: { name: "login" },
                },
            ],
        },

        // =============================================================================
        // ADMIN PAGES
        // =============================================================================
        {
            path: "",
            component: () => import("@/layouts/main/index.vue"),
            props: { menuItems: MenuItemsAdmin },
            children: [
                // {
                //     path: "/home",
                //     name: "home",
                //     component: () => import("@/views/admin/analytics/analytics.vue"),
                //     meta: {
                //         title: "Аналитика"
                //     },

                //     // TODO: пока нет главной страницы, переходить на страницу заявок
                //     redirect: { name: "analytics" }
                // },
                {
                    path: "/analytics",
                    name: "analytics",
                    component: () => import("@/views/admin/analytics/index.vue"),
                    meta: {
                        title: "Аналитика",
                        auth: AuthType.Admin,
                    },
                },
                {
                    path: "/companies/:id?",
                    name: "companies",
                    component: () => import("@/views/admin/companies/companies.vue"),
                    meta: {
                        title: "Компании",
                        auth: AuthType.Admin,
                    },
                },
                {
                    path: "/users/:id?",
                    name: "users",
                    component: () => import("@/views/admin/users/users.vue"),
                    meta: {
                        title: "Пользователи",
                        auth: AuthType.Admin,
                    },
                },
            ],
        },

        // =============================================================================
        // UNAUTH PAGES
        // =============================================================================
        {
            path: "",
            component: () => import("@/layouts/full-page/index.vue"),
            children: [
                {
                    path: "/login",
                    name: "login",
                    component: () => import("@/views/admin/auth/login.vue"),
                    meta: {
                        title: "Вход",
                        auth: AuthType.Unauth,
                    },
                },

                // =============================================================================
                // ERROR PAGES etc
                // =============================================================================
                {
                    path: "/error-401",
                    name: "page-error-401",
                    component: () => import("@/views/errors/error-401.vue"),
                    meta: {
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/error-404",
                    name: "page-error-404",
                    component: () => import("@/views/errors/error-404.vue"),
                    meta: {
                        title: "404",
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/error-500",
                    name: "page-error-500",
                    component: () => import("@/views/errors/error-500.vue"),
                    meta: {
                        title: "500",
                        auth: AuthType.Public,
                    },
                },
            ],
        },

        // =============================================================================
        // Redirect to 404 page, if no match found
        // =============================================================================
        {
            path: "*",
            redirect: "/error-404",
        },
    ],
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

router.beforeEach(async (to: any, from: any, next: any) => {
    if (to.meta?.auth === AuthType.Admin && !router.app.$alt.system.token.hasAdminToken()) {
        await router.push({ name: "login" }).catch(() => {});
        // TODO: russian text
        router.app.$alt.toast.error("Доступ запрещён.");
        return;
    }

    if (to.meta?.auth === AuthType.Unauth && router.app.$alt.system.token.hasAdminToken()) {
        await router.push({ name: "analytics" }).catch(() => {});
        return;
    }

    if (to.meta?.title) {
        document.title = `${to.meta.title} | Alt SC`;
    } else {
        document.title = "Alt SC";
    }

    return next();
});

export default router;
