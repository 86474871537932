import { ITableFilter } from "@lib";
import { Filter } from "@/utils/filter";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface GoodInventoriesFilter {
    store?: string[];
}

export function getInitialData(filterByDefault: GoodInventoriesFilter = {}): GoodInventoriesFilter {
    return {
        store: filterByDefault.store,
    };
}

export function convertFilterBack(filter: GoodInventoriesFilter): ITableFilter[] {
    const tblfilter: ITableFilter[] = [];

    if (filter.store) {
        const tf = Filter.convertInBack("store", filter.store);
        tblfilter.push(...tf);
    }

    return tblfilter;
}

export function updateFilterBack(tfilter: ITableFilter[], filter: GoodInventoriesFilter): ITableFilter[] {
    if (filter.store) {
        tfilter = tfilter.filter(f => f.field !== "store");
        const tf = Filter.convertInBack("store", filter.store);
        tfilter.push(...tf);
    }

    return tfilter;
}

export function convertFilter(
    tblfilter: ITableFilter[],
    filterByDefault: GoodInventoriesFilter = {},
): GoodInventoriesFilter {
    const filter: GoodInventoriesFilter = getInitialData(filterByDefault);
    if (tblfilter.length > 0) {
        filter.store = Filter.convertIn("store", tblfilter);
    }
    return filter;
}
