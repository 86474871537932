import moment from "moment";
import { Vue, Component } from "vue-property-decorator";
import { BRow, BCol, BCard } from "bootstrap-vue";
import { IAdminActiveCompanyInfo, IAdminConversion, Locale } from "@lib";
import StatisticCardHorizontal from "@/components/vuexy/StatisticCardHorizontal.vue";
import { Localizer } from "@/i18n/localizer";

@Component({
    name: "analytics",
    components: { BRow, BCol, BCard, StatisticCardHorizontal },
})
export default class Analytics extends Vue {
    private readonly MONEY_PER_POINT = 510;

    public activeCompanies: IAdminActiveCompanyInfo[] = [];
    public totalPoints = 0;
    public totalIncomes = 0;
    public conversions: IAdminConversion[] = [];
    public total: IAdminConversion = {
        period: new Date(),
        countRegistrations: 0,
        countCreateOrder: 0,
        countPaid: 0,
        countUtmYandex: 0,
    };

    public get totalIncomesFormated(): string {
        // TODO: брать локаль откуда-то
        const locale = Locale.RU;
        return Localizer.moneyFull(this.totalPoints * this.MONEY_PER_POINT, locale);
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.init();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async init(): Promise<void> {
        await this.getFlatAnalytics();
    }

    private async getFlatAnalytics(): Promise<void> {
        try {
            const testCompaniesStr: string = process.env.VUE_APP_TEST_COMPANIES ?? "";
            const testCompanies = testCompaniesStr.split(",");

            const service = this.$alt.system.usecase.createAdminAnalyticsUseCase();
            const response = await service.getFlatAnalytics();

            this.activeCompanies = response.activeCompanies.filter(c => !testCompanies.includes(c.id));
            this.totalPoints = this.activeCompanies.reduce((sum, company) => (sum += company.countPoints), 0);
            this.totalIncomes = this.totalPoints * this.MONEY_PER_POINT;
            this.conversions = response.conversions;

            this.total = {
                period: new Date(),
                countRegistrations: response.conversions.reduce((sum, value) => sum + value.countRegistrations, 0),
                countCreateOrder: response.conversions.reduce((sum, value) => sum + value.countCreateOrder, 0),
                countPaid: response.conversions.reduce((sum, value) => sum + value.countPaid, 0),
                countUtmYandex: response.conversions.reduce((sum, value) => sum + value.countUtmYandex, 0),
            };
        } catch (e: any) {
            throw new Error(`Не удалось загрузить аналитику:\n${e.message}`);
        }
    }

    private dtFormat(date: number | string | Date): string {
        return moment(date).format("MMM 'YY");
    }

    public getConversion(from: number, to: number): string {
        const res = from !== 0 ? (to / from) * 100 : 0;
        return `${res.toFixed(2)}%`;
    }
}
