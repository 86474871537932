import { IGoodRegistrationCreateDto, IGood, ITemplateLabel, ICompany, IStore } from "@lib";
import { Control, TextBox } from "@core/components/alt-ui/controls";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { Footer } from "@core/controls/footer.control";
import { Table } from "@core/components/alt-ui/table";
import { Printer } from "@/@core/usecases/template/printer";
import { GoodPrintContext } from "@core/usecases/template/macro-replacers/good-label.macro-replacer";
import { ObjectUtils } from "@/utils/object.utils";

export interface IGoodPrintModalContext {
    company: ICompany;
    stores: IStore[];
    goods: IGood[];
    template: ITemplateLabel;
}

export class GoodPrintModal extends Modal2<IGoodPrintModalContext> {
    private tblGoodsTable!: Table<IGood>;
    private ftFooter!: Footer;

    private context!: IGoodPrintModalContext;
    public onCreate: ((dto: IGoodRegistrationCreateDto) => Promise<any | null>) | null = null;

    public constructor() {
        super("good-print-modal", "Печатать");
        this.initializeControls();
    }

    public get footer(): Footer {
        return this.ftFooter;
    }

    public show(context: IGoodPrintModalContext): Promise<void> {
        this.context = context;
        this.title = `Печатать ${context.template.name}`;
        this.initializeControls();
        this.populateControls(this.context.goods);
        return super.show();
    }

    protected initializeControls(): void {
        this.tblGoodsTable = new Table<IGood>();
        this.tblGoodsTable.id = "good-print.goods";
        this.tblGoodsTable.columns = [
            {
                title: "Наименование",
                classHeader: "text-left text-xs",
                classCell: "text-left",
                cell: item => item.info.name,
            },
            {
                title: "Количество",
                classHeader: "text-xs",
                classCell: "text-xs",
                cell: item => {
                    const tb = new TextBox();
                    tb.id = `good-print.good-${item.id}`;
                    tb.validation = "required|numeric|unsigned";
                    tb.text = item.info.quantity.toString();
                    tb.addTextChangedHandler((s, e) => {
                        const quantity = parseInt(e.text);
                        item.info.quantity = isNaN(quantity) ? 0 : quantity;
                    });
                    return tb;
                },
            },
        ];

        //

        this.ftFooter = new Footer({
            okText: "Печатать",
            okHandler: this.clickPrint.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(goods: IGood[]): void {
        this.tblGoodsTable.items = ObjectUtils.clone(goods);
    }

    public get controls(): Control[] {
        return [this.tblGoodsTable];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickPrint(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.tblGoodsTable.items.length) {
            return;
        }

        const context: GoodPrintContext = {
            company: this.context.company,
            stores: this.context.stores,
            goods: this.tblGoodsTable.items,
        };

        Printer.printGoodLabel(this.context.template, context);

        this.hide();
    }
}
