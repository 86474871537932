import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BSidebar, BButton } from "bootstrap-vue";
import { Editor } from "tinymce";
import { TinymceEditor } from "@core/tinymce";
import { Control } from "@core/components/alt-ui/controls";
import { autocompleterSpec, MacroAutocomplete } from "./document-template-macros-autocompleter";

export class DocumentTemplateEditor extends Control {
    public text = "";
    public previewWidth = 210; // mm
    public preview: ((template: string) => void) | null = null;
    public getPreviewHtml: ((template: string) => string) | null = null;
    public macroAutocompletes: MacroAutocomplete[] = [];
    public macrosDocsLink = `${process.env.VUE_APP_DOCS_BASEURL}guide/features/documents/`;

    public template: string = "";

    public getComponentName(): string {
        return "DocumentTemplateEditorComponent";
    }
}

@Component({
    components: {
        BSidebar,
        BButton,
        TinymceEditor,
    },
})
export default class DocumentTemplateEditorComponent extends Vue {
    @Prop({ type: Object })
    private handler!: DocumentTemplateEditor;

    private template = "";

    private editorUpdateMarker = 0;

    private init = {
        height: "100%",
        menubar: false,
        resize: false,
        skin: false,

        // без отступов, чтобы шрифты отображались в списке ровно
        font_formats: `Arial=arial,helvetica,sans-serif;\
            Comic Sans MS=comic sans ms,sans-serif;\
            Consolas=Consolas, 'Courier New', monospace;\
            Courier New=courier new,courier;\
            Georgia=georgia,palatino;\
            Helvetica=helvetica;\
            Impact=impact,chicago;\
            Inter='Inter', sans-serif;\
            Monotype Corsiva=monotype corsiva;\
            Open Sans='Open Sans', sans-serif;\
            Roboto='Roboto', sans-serif;\
            Tahoma=tahoma,arial,helvetica,sans-serif;\
            Times New Roman=times new roman,times;\
            Trebuchet MS=trebuchet ms,geneva;\
            Verdana=verdana,geneva;`,

        plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks fullscreen",
            "insertdatetime media table contextmenu paste",
            "textcolor fullpage pagebreak code",
        ],

        toolbar:
            "undo redo | altPreview | fontselect | fontsizeselect | forecolor backcolor | \
            bold italic underline strikethrough | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist | table image | pagebreak code | altMacros",

        content_css: false,
        content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Inter&family=Open+Sans&display=swap');\
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');\
            @import url('https://fonts.googleapis.com/css2?&family=Roboto&display=swap');\
            p { padding: 0; margin: 0px; line-height: 1; } \
            ul, ol { line-height: 1; } \
            li { padding: 0; margin: 0px; line-height: 1; } \
            .mce-item-table, .mce-item-table td, .mce-item-table th, .mce-item-table caption { border: 1px dashed #BBB; } \
            table { word-break: break-all } \
            td[data-mce-selected],th[data-mce-selected]{background-color:#2276d2 !important} \
            .ephox-snooker-resizer-bar{background-color:#2276d2;opacity:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none} \
            .ephox-snooker-resizer-cols{cursor:col-resize} \
            .ephox-snooker-resizer-rows{cursor:row-resize} \
            .ephox-snooker-resizer-bar.ephox-snooker-resizer-bar-dragging{opacity:.2}",

        language: "ru",

        setup: (editor: Editor) => {
            editor.ui.registry.addButton("altMacros", {
                text: "Макросы",
                onAction: () => {
                    window.open(this.handler.macrosDocsLink, "_blank")?.focus();
                },
            });

            editor.ui.registry.addButton("altPreview", {
                text: "Просмотр",
                onAction: this.preview,
            });

            editor.ui.registry.addAutocompleter(
                "macrosAutocompleter",
                autocompleterSpec(editor, "%", this.handler.macroAutocompletes),
            );
        },
    };

    private get visible(): boolean {
        return this.handler.visible;
    }

    @Watch("template")
    private onTemplateChange(value: string): void {
        this.handler.template = value;

        this.updatePreview();
    }

    @Watch("visible", { deep: true })
    private onVisibleChange(value: boolean): void {
        this.template = this.handler.text;
        this.editorUpdateMarker += 1;
        this.updatePreview();
    }

    private updatePreview(): void {
        if (this.handler.getPreviewHtml) {
            let html = this.handler.getPreviewHtml(this.template);
            html = html.replaceAll("page-break-before: always", "page-break-before: always; margin: 1rem 2rem;");

            this.$nextTick(() => {
                const preview = this.$refs.preview as HTMLIFrameElement;

                if (!preview.contentDocument) {
                    return;
                }

                preview.contentDocument.write(html);
                preview.contentDocument.close();
            });
        }
    }

    private preview(): void {
        if (this.handler.preview) {
            this.handler.preview(this.template);
        }
    }

    // Нужно т.к бутстрап перезаписывает фокус ивенты, и получается так что на инпуты внутри модалок tinymce нельзя кликнуть
    private tinymceFocusListener(event: Event): void {
        if ((event.target as any)?.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
            event.stopImmediatePropagation();
        }
    }

    public mounted(): void {
        document.addEventListener("focusin", this.tinymceFocusListener);
    }

    public beforeUnmount(): void {
        document.removeEventListener("focusin", this.tinymceFocusListener);
    }
}
