import { IPluginBinding, IPluginCard } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel } from "@core/components/alt-ui/controls";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { FieldControl } from "@core/types/field-controls/field-control";

export interface IPluginConfigurationModalContext {
    binding: IPluginBinding;
    plugin: IPluginCard;
}

export class PluginConfigurationModal extends Modal<IPluginConfigurationModalContext, IPluginBinding> {
    private pnlMain!: Panel;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: IPluginConfigurationModalContext | null = null;
    private fieldControls: FieldControl[] = [];

    public onSave: ((orig: IPluginBinding, dto: any) => Promise<IPluginBinding | null>) | null = null;

    public constructor() {
        super("plugin-config-modal", "Настройка интеграции");
        this.initializeControls();
    }

    public show(context: IPluginConfigurationModalContext): Promise<IPluginBinding> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context.binding);
        return super.show();
    }

    protected initializeControls(): void {
        this.fieldControls = [];
        this.pnlMain = new Panel();

        if (this.context?.plugin?.configuration) {
            for (const field of this.context.plugin.configuration) {
                const fieldControl = FieldControlFactory.fromField(field);
                this.fieldControls.push(fieldControl);

                fieldControl.control.class = "mb-0.75";
                this.pnlMain.addControl(fieldControl.control);
            }
        }

        this.btnCancel = new Button();
        this.btnCancel.id = "plugin-config.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "plugin-config.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(binding: IPluginBinding): void {
        for (const fieldControl of this.fieldControls) {
            const alias = fieldControl.field.alias as string;
            fieldControl.value = binding.configuration?.[alias] ?? "";
        }
    }

    public get controls(): Control[] {
        return [this.pnlMain, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.onSave) {
            return;
        }

        const dto: any = {};

        for (const fieldControl of this.fieldControls) {
            const alias = fieldControl.field.alias as string;
            dto[alias] = fieldControl.value;
        }

        const result = await this.onSave(this.context.binding, dto);
        if (result) {
            this.hide(result);
        }
    }
}
