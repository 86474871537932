import parsePhoneNumber from "libphonenumber-js";
import { IClient, IClientSource, FieldType, LegalType, IField, IClientCreateDto } from "@lib";
import { getClientTypes } from "@core/types/common/select-options";

export function getDefaultTableColumns(context: any): any[] {
    const sources = context.sources as IClientSource[];

    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 70 + (context.can.update ? 25 : 0) + (context.can.delete ? 25 : 0),
            hide: false,
            headerClass: "text-transparent",
            headerCheckboxSelection: true,
            checkboxSelection: true,

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (client: IClient) => context.showUpdateModal(client),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (client: IClient) => context.confirmDelete(client),
                        },
                    ],
                };
            },
        },
        // {
        //     colId: "checkboxes",
        //     headerName: "#",
        //     width: 50,
        //     hide: false,
        //     filter: false,
        //     checkboxSelection: true,
        //     headerCheckboxSelectionFilteredOnly: true,
        //     headerCheckboxSelection: true
        // },
        {
            colId: "name",
            field: "info.name",
            headerName: "Имя",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "description",
            field: "info.description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 500,
            hide: true,
        },
        {
            colId: "type",
            field: "info.type",
            headerName: "Тип",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,

            cellRenderer: function (params: any) {
                const type = getClientTypes().find(type => type.id === params.value);
                return type ? type.name : "-";
            },
            // export: function(params: any) {
            //     return this.cellRenderer(params);
            // },
        },
        {
            colId: "phone",
            field: "info.contacts.phone",
            headerName: "Телефон",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,

            cellRenderer: function (params: any) {
                const clientPhone = params.value as string;

                if (!clientPhone) {
                    return "";
                }

                const phoneNumber = parsePhoneNumber(clientPhone);

                return phoneNumber?.formatInternational() ?? clientPhone;
            },
        },
        {
            colId: "email",
            field: "info.contacts.email",
            headerName: "Почта",
            sortable: true,
            filter: true,
            width: 200,
            hide: true,
        },
        {
            colId: "source",
            field: "info.source",
            headerName: "Источник",
            sortable: true,
            filter: true,
            width: 160,
            hide: false,

            // cellRenderer: function(params: any) {
            //     console.log(params);
            //     const stage = stages.find(stage => stage.id === params.value);
            //     return stage ? stage.name : "-";
            // },
            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                const source = sources.find(source => source.id === params.value);
                return { name: source ? source.name : "Не указан" };
            },
            // export: function(params: any) {
            //     return this.cellRendererParams(params).name;
            // },
        },
        {
            colId: "tags",
            field: "tags",
            headerName: "Метки",
            filter: true,
            width: 150,
            hide: false,

            cellRendererFramework: "CellRendererTags",
            cellRendererParams: (params: any) => {
                const tags = params.value;
                if (!tags) {
                    return;
                }

                return {
                    tags,
                    handler: (t: string) => context.table.api.search(t),
                };
            },
        },
    ];
}

//

export function getDefaultTableActions(context: any): any[] {
    return [
        {
            name: "Удалить всё",
            icon: "Trash2Icon",
            click: (clients: IClient[]) => context.confirmDeleteMany(clients),
        },
    ];
}

//

export function getDefaultFields(): IField[] {
    return [
        {
            id: "type",
            sequence: 10,
            standart: "type",
            type: FieldType.Select,
            title: "Тип",
            details1: "select.fixed",
            details2: JSON.stringify(getClientTypes()),
            default: LegalType.Natural,
            hidden: true,
        },
        {
            id: "name",
            sequence: 20,
            standart: "name",
            type: FieldType.String,
            title: "Имя",
            validation: {
                required: true,
            },
        },
        {
            id: "description",
            sequence: 30,
            standart: "description",
            type: FieldType.Text,
            title: "Описание",
            hidden: true,
        },
        {
            id: "taxId",
            sequence: 40,
            standart: "taxId",
            type: FieldType.String,
            title: "ИНН",
            hidden: true,
        },
        {
            id: "email",
            sequence: 50,
            standart: "email",
            type: FieldType.String,
            title: "Почта",
            validation: {
                email: true,
            },
            hidden: true,
        },
        {
            id: "phone",
            sequence: 60,
            standart: "phone",
            type: FieldType.Phone,
            title: "Телефон",
        },
        {
            id: "source",
            sequence: 70,
            standart: "source",
            type: FieldType.Select,
            title: "Источник",
            details1: "select.standart",
            details2: "$(компания.списки.источники)",
            default: "$(первый)",
        },
        {
            id: "comment",
            sequence: 80,
            standart: "comment",
            type: FieldType.Text,
            title: "Комментарий",
        },
        {
            id: "tags",
            sequence: 90,
            standart: "tags",
            type: FieldType.Tags,
            title: "Метки",
            details1: "Введите новую метку",
        },
    ];
}

export const DefaultFieldDataExtractor: any = {
    type: (client: IClient) => client.info?.type ?? LegalType.Natural,
    name: (client: IClient) => client.info?.name ?? "",
    description: (client: IClient) => client.info?.description ?? "",
    taxId: (client: IClient) => client.info?.taxId ?? "",
    email: (client: IClient) => client.info?.contacts?.email ?? "",
    phone: (client: IClient) => client.info?.contacts?.phone ?? "",
    source: (client: IClient) => client.info?.source ?? "",
    comment: (client: IClient) => client.comment ?? "",
    tags: (client: IClient) => client.tags ?? [],
};

export const DefaultFieldDataSetup: any = {
    type: (dto: IClientCreateDto, value: string) => (dto.type = value as LegalType),
    name: (dto: IClientCreateDto, value: string) => (dto.name = value),
    description: (dto: IClientCreateDto, value: string) => (dto.description = value),
    taxId: (dto: IClientCreateDto, value: string) => (dto.taxId = value),
    email: (dto: IClientCreateDto, value: string) => {
        if (!dto.contacts) {
            dto.contacts = {};
        }
        dto.contacts.email = value;
    },
    phone: (dto: IClientCreateDto, value: string) => {
        if (!dto.contacts) {
            dto.contacts = {};
        }
        dto.contacts.phone = value;
    },
    source: (dto: IClientCreateDto, value: string) => (dto.source = value),
    comment: (dto: IClientCreateDto, value: string) => (dto.comment = value),
    tags: (dto: IClientCreateDto, value: string[]) => (dto.tags = value),
};
