import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormGroup, BFormTags, BTooltip } from "bootstrap-vue";
import { Tags } from "./tags";

@Component({
    name: "tags-component",
    components: { BFormGroup, BFormTags, BTooltip },
})
export class TagsComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Tags;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <b-form-group class="w-full mb-0">
                    <div class="flex">{this.renderLabel()}</div>
                    <b-form-tags
                        input-id={this.handler.id}
                        class={this.handler.class}
                        style={this.handler.style}
                        placeholder={this.handler.placeholder}
                        disabled={this.handler.disabled}
                        add-button-text="+"
                        v-model={this.handler.tags}
                    />
                </b-form-group>
            );
        } else {
            return <div />;
        }
    }

    private renderLabel(): VNode[] {
        const items: VNode[] = [];

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        return items;
    }
}
