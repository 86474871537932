import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Panel } from "./panel";

@Component({
    name: "panel-component",
    components: {
        ControlComponent: () => import("../controls/control.component"),
    },
})
export class PanelComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Panel;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    {this.renderControls()}
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderControls(): VNode[] {
        const controls: VNode[] = [];
        for (const control of this.handler.controls) {
            controls.push(<control-component handler={control} />);
        }
        return controls;
    }
}
