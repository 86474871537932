import { PhoneBox } from "@core/components/alt-ui/controls";
import { FieldControl } from "../field-control";
import { Formatter } from "@/utils/formatter";
import { Uuid } from "@/utils/uuid";

export class PhoneBoxFieldControl extends FieldControl<PhoneBox> {
    protected convert(): PhoneBox {
        const control = new PhoneBox();
        control.id = `${this.field.id}_${Uuid.short()}`;
        control.label = this.field.title ?? "";
        control.help = this.field.description ?? "";
        control.validation = this.validationToString(this.field.validation);
        control.text = this.field.default ?? "";
        return control;
    }

    public get value(): any {
        return this.control.text;
    }

    public set value(value: any) {
        // TODO: удалить лишние символы - оставить только числа и +
        this.control.text = value ? String(value) : "";
    }

    public getValueFormatted(): string {
        return Formatter.phone(this.value);
    }
}
