import { Vue, Component } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { BForm, BAlert, BButton } from "bootstrap-vue";
import { IUser, IUserUpdateDto } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { PhoneBox, TextArea, TextBox } from "@core/components/alt-ui/controls";

@Component({
    components: {
        BForm,
        BAlert,
        BButton,
        ValidationObserver,
        ControlComponent,
    },
})
export default class SettingsProfileGeneral extends Vue {
    private user!: IUser;

    private tbName: TextBox;
    private tbOfficialName: TextBox;
    private taAbout: TextArea;

    private tbEmail: TextBox;
    private tbPhone: PhoneBox;
    private tbAddress: TextBox;
    private tbWebsite: TextBox;

    private emailСonfirmed = false;

    public constructor() {
        super();

        this.tbName = new TextBox();
        this.tbName.id = "profile.name";
        this.tbName.label = "Имя пользователя (на сайте)";
        this.tbName.validation = "required|max:40";

        this.tbOfficialName = new TextBox();
        this.tbOfficialName.id = "profile.official-name";
        this.tbOfficialName.label = "Официальное имя (для документов)";

        this.taAbout = new TextArea();
        this.taAbout.id = "profile.about";
        this.taAbout.label = "О себе";

        //

        this.tbEmail = new TextBox();
        this.tbEmail.id = "profile.email";
        this.tbEmail.label = "Электронная почта";
        this.tbEmail.validation = "required|email|max:40";

        this.tbPhone = new PhoneBox();
        this.tbPhone.id = "profile.phone";
        this.tbPhone.label = "Телефон";

        this.tbAddress = new TextBox();
        this.tbAddress.id = "profile.address";
        this.tbAddress.label = "Адрес";

        this.tbWebsite = new TextBox();
        this.tbWebsite.id = "profile.website";
        this.tbWebsite.label = "Сайт";
    }

    private get emailChanged(): boolean {
        return this.tbEmail.text !== this.user?.info.contacts.email;
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.emailСonfirmed = this.user.info.emailСonfirmed ?? false;
            this.init();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private init(): void {
        // this.updateLocale(this.language ? this.language : "ru");

        this.tbName.text = this.user.info.name;
        this.tbOfficialName.text = this.user.info.officialName ?? "";
        this.taAbout.text = this.user.info.about ?? "";

        this.tbEmail.text = this.user.info.contacts.email ?? "";
        this.tbPhone.text = this.user.info.contacts.phone ?? "";
        this.tbAddress.text = this.user.info.contacts.address ?? "";
        this.tbWebsite.text = this.user.info.contacts.website ?? "";
    }

    private get i18n_locale_img(): any {
        return require(`@/assets/images/flags/${this.$i18n.locale}.png`);
    }

    private get getCurrentLocaleData(): any {
        const locale = this.$i18n.locale;
        if (locale === "en") {
            return { flag: "us", lang: "English" };
        }
        //else if (locale == "ru") return { flag: "ru", lang: "Русский" }
        return { flag: "ru", lang: "Русский" };
    }

    // private updateLocale(locale: any): void {
    //     this.$i18n.locale = locale;
    //     this.language = locale;
    // }

    private theme = "default";

    private get showThemeButtons(): boolean {
        return this.$alt.isFeatureEnabled("feature-themes");
    }

    private changeTheme(theme: string): void {
        const root = document.querySelector(":root");
        if (!root) {
            return;
        }

        this.theme = theme;

        (root as any).setAttribute("data-theme", this.theme);
    }

    private async save(): Promise<void> {
        try {
            const result = await (this.$refs["formValidator"] as any).validate();
            if (!result) {
                this.$alt.toast.error("Не все поля верно заполнены.");
                return;
            }

            this.$alt.loader.show();

            const dto: IUserUpdateDto = {
                name: this.tbName.text,
                officialName: this.tbOfficialName.text,
                about: this.taAbout.text,
                emailСonfirmed: this.user.info.emailСonfirmed,
                contacts: {
                    email: this.tbEmail.text,
                    phone: this.tbPhone.text,
                    address: this.tbAddress.text,
                    website: this.tbWebsite.text,
                },
            };

            const user = await this.$alt.system.usecase.createUserUseCase().update(this.user.id, dto);
            if (!user) {
                throw new Error("Пользователь не найден.");
            }

            this.$info.setUser(user);
            this.user = user;
            this.init();
            this.$alt.toast.success("Изменения сохранены.", "Сохранение");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось сохранить изменения:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async sendConfirmationEmail(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.$alt.system.usecase.createAuthUseCase().sendConfirmationEmail();
            this.$alt.toast.success("Письмо отправлено.", "Подтверждение почты");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось отправить письмо:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private reset(): void {
        this.init();
        this.$alt.toast.success("Изменения сброшены.", "Сброс");
    }
}
