export enum ApiError {
    SUCCESS = 0,

    EMAIL_ALREADY_EXISTS = 1,
    INVALID_LOGIN_OR_PASSWORD = 2,
    INVALID_EMAIL = 3,
    INVALID_USER = 4,
    INVALID_TOKEN = 5,
    INVALID_OLD_PASSWORD = 6,
    PASSWORDS_NOT_MATCHED = 7,

    SOCIAL_NOT_FOUND = 10,
    SOCIAL_UNSUPPORTED = 11,
    SOCIAL_ALREADY_EXISTS = 12,

    USER_NOT_FOUND = 20,
    USER_UNABLE_CREATE = 21,
    USER_UNABLE_UPDATE = 22,

    EMPLOYEE_NOT_FOUND = 25,
    EMPLOYEE_UNABLE_CREATE = 26,
    EMPLOYEE_UNABLE_UPDATE = 27,
    EMPLOYEE_UNABLE_DELETE = 28,

    COMPANY_NOT_FOUND = 30,
    COMPANY_UNABLE_CREATE = 31,
    COMPANY_UNABLE_UPDATE = 32,
    COMPANY_PAYMENT_UNABLE_CREATE = 36,
    COMPANY_PAYMENT_FUNDS = 37,
    COMPANY_PRICING_UNABLE_UPDATE = 38,

    PERMISSIONS_UNABLE_GRANT = 41,

    CLIENT_NOT_FOUND = 50,
    CLIENT_UNABLE_CREATE = 51,
    CLIENT_UNABLE_IMPORT = 59,

    OFFICE_NOT_FOUND = 60,
    OFFICE_UNABLE_CREATE = 61,
    OFFICE_UNABLE_UPDATE = 62,
    ORDER_NOT_FOUND = 65,
    ORDER_UNABLE_CREATE = 66,
    ORDER_UNABLE_UPDATE = 67,

    SHOP_NOT_FOUND = 70,
    SHOP_UNABLE_CREATE = 71,
    SHOP_UNABLE_UPDATE = 72,
    SALE_NOT_FOUND = 75,
    SALE_UNABLE_CREATE = 76,

    ACCOUNT_NOT_FOUND = 80,
    ACCOUNT_UNABLE_CREATE = 81,
    ACCOUNT_UNABLE_UPDATE = 82,
    TRANSACTION_NOT_FOUND = 85,
    TRANSACTION_UNABLE_CREATE = 86,

    // store - 16xxx

    STORE_NOT_FOUND = 16000,
    STORE_UNABLE_CREATE = 16001,
    STORE_UNABLE_UPDATE = 16002,
    STORE_UNABLE_DELETE = 16003,

    GOOD_NOT_FOUND = 16100,
    GOOD_UNABLE_CREATE = 16101,
    GOOD_UNABLE_UPDATE = 16102,
    GOOD_UNABLE_DELETE = 16103,
    GOOD_UNABLE_IMPORT = 16104,

    GOOD_CATEGORY_NOT_FOUND = 16300,
    GOOD_CATEGORY_UNABLE_CREATE = 16301,
    GOOD_CATEGORY_UNABLE_UPDATE = 16302,
    GOOD_CATEGORY_UNABLE_DELETE = 16303,
    GOOD_REGISTRATION_NOT_FOUND = 16400,
    GOOD_REGISTRATION_UNABLE_CREATE = 16401,
    GOOD_REGISTRATION_UNABLE_UPDATE = 16402,
    GOOD_REGISTRATION_UNABLE_DELETE = 16403,
    GOOD_WRITE_OFF_NOT_FOUND = 16500,
    GOOD_WRITE_OFF_UNABLE_CREATE = 16501,
    GOOD_WRITE_OFF_UNABLE_UPDATE = 16502,
    GOOD_WRITE_OFF_UNABLE_DELETE = 16503,
    GOOD_INVENTORY_NOT_FOUND = 16600,
    GOOD_INVENTORY_UNABLE_CREATE = 16601,
    GOOD_INVENTORY_UNABLE_UPDATE = 16602,
    GOOD_INVENTORY_UNABLE_DELETE = 16603,
    SUPPLIER_NOT_FOUND = 16700,
    SUPPLIER_UNABLE_CREATE = 16701,
    SUPPLIER_UNABLE_UPDATE = 16702,
    SUPPLIER_UNABLE_DELETE = 16703,

    //

    DOCUMENT_NOT_FOUND = 100,
    DOCUMENT_UNABLE_CREATE = 101,
    SMS_NOT_FOUND = 105,
    SMS_UNABLE_CREATE = 106,

    ORDER_TYPE_NOT_FOUND = 150,
    ORDER_TYPE_UNABLE_CREATE = 151,
    ORDER_STAGE_NOT_FOUND = 155,
    ORDER_STAGE_UNABLE_CREATE = 156,
    PRODUCT_TYPE_NOT_FOUND = 160,
    PRODUCT_TYPE_UNABLE_CREATE = 161,
    TRANSACTION_ITEM_NOT_FOUND = 165,
    TRANSACTION_ITEM_UNABLE_CREATE = 166,
    CLIENT_SOURCE_NOT_FOUND = 170,
    CLIENT_SOURCE_UNABLE_CREATE = 171,

    SALARY_NOT_FOUND = 180,
    SALARY_UNABLE_CREATE = 181,

    SETTINGS_TABLE_UNABLE_CREATE = 201,

    PLUGIN_NOT_FOUND = 230,
    PLUGIN_UNABLE_BIND = 231,

    PRICING_INVALID_OPERATION = 240,

    TRACKING_CODE_NOT_FOUND = 250,

    UNKNOWN_ERROR = 255,

    HTTP_BADREQUEST = 400,
    HTTP_UNAUTHORIZED = 401,
    HTTP_FORBIDDEN = 403,
    HTTP_NOT_FOUND = 404,
    HTTP_INTERNAL = 500,
}
