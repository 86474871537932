export { default } from "./va-auto-button.vue";

export interface IAutoButton {
    id?: string;
    type?: string;
    text?: string;
    icon?: string;
    tooltip?: string;
    tooltipTrigger?: string;
    tooltipPosition?: string;
    tooltipVariant?: string;
    variant?: string;

    click?(sender: any, e: any): void;
}
