import { IClientUseCase, IClient, ISelectedData, IReadQuery, IClientImportDto } from "@lib";
import { IClientCreateDto, IClientUpdateDto, IClientDeleteManyDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class ClientUseCase implements IClientUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<IClient>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IClient>>(`/companies/${companyId}/clients`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IClient> {
        try {
            const response = await Api.get<IClient>(`/companies/${companyId}/clients/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IClientCreateDto): Promise<IClient> {
        try {
            const response = await Api.post<IClient>(`/companies/${companyId}/clients`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IClientUpdateDto): Promise<IClient> {
        try {
            const response = await Api.put<IClient>(`/companies/${companyId}/clients/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/clients/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async deleteMany(companyId: string, dto: IClientDeleteManyDto): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/clients/actions/delete`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async import(companyId: string, file: File, dto: IClientImportDto): Promise<IClient[]> {
        // https://webdevblog.ru/zagruzka-fajlov-s-pomoshhju-vuejs-i-axios/
        //
        // загрузка нескольких файлов:
        //
        // const data = new FormData();
        // for (let i = 0; i < files.length; i++) {
        //     let file = this.files[i];
        //     data.append(`files[${i}]`, file);
        // }

        try {
            const data = new FormData();
            data.append("file", file);
            for (const key in dto) {
                data.append(key, (dto as any)[key]);
            }

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            const response = await Api.post<IClient[]>(`/companies/${companyId}/clients/actions/import`, data, config);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async export(companyId: string, query?: IReadQuery): Promise<Blob> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<Blob>(`/companies/${companyId}/clients/actions/export`, {
                params,
                responseType: "blob",
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
