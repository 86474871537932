import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BCard, BForm, BFormInput, BInputGroup, BFormGroup, BFormRadioGroup, BButton } from "bootstrap-vue";
import { Validator, ValidationObserver, ValidationProvider } from "vee-validate";

Validator.extend("money-min", {
    getMessage(field: any, params: any, data: any) {
        return (data && data.message) ?? "Неверное значение.";
    },
    validate(value: string, params: string[]) {
        if (params.length === 0) {
            throw new Error("Неверный параметр валидации");
        }

        const min = params[0];

        const money = parseFloat(value.replace(",", "."));
        const moneyMin = parseFloat(min.replace(",", "."));

        const result = money >= moneyMin;
        return {
            valid: result,
            data: { message: result ? "" : `Сумма не может быть меньше ${min}` },
        };
    },
});

@Component({
    components: {
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BFormRadioGroup,
        BButton,
        ValidationObserver,
        ValidationProvider,
    },
})
export default class YoomoneyForm extends Vue {
    @Prop({ type: String, required: true })
    private receiver!: string;

    @Prop({ type: String, required: true })
    private targets!: string;

    @Prop({ type: String, default: "PC" })
    private paymentTypeDefault!: string;

    @Prop({ type: String, default: "1000" })
    private sumDefault!: string;

    @Prop({ type: Number, default: 0 })
    private sumMin!: number;

    @Prop({ type: String, required: true })
    private label!: string;

    @Prop({ type: String, required: true })
    private successUrl!: string;

    private paymentType = this.paymentTypeDefault;
    private sum = this.sumDefault;

    private paymentTypeOptions = [
        { text: "ЮMoney", value: "PC" },
        { text: "Банковской картой", value: "AC" },
    ];

    @Watch("paymentTypeDefault")
    private onPaymentTypeChanged(value: string): void {
        this.paymentType = value;
    }

    @Watch("sumDefault")
    private onSumChanged(value: string): void {
        this.sum = value;
    }

    private async checkForm(e: Event): Promise<void> {
        const valresult = await (this.$refs["formValidator"] as any).validate();
        if (!valresult) {
            e.preventDefault();
            return;
        }

        // e.preventDefault();
        // this.$emit("test");
    }
}
