import { Control, DateRange, TextBox } from "@core/components/alt-ui/controls";
import { FilterController } from "@core/filters/filter-controller";
import { FilterPair, FilterType } from "@/utils/filter";
import { ITableFilter } from "@lib";
import { Footer } from "@core/controls/footer.control";

export type CompaniesFilter = Partial<{
    createdAt: FilterPair;
    utm_source: string;
}>;

export interface ICompaniesFilterContext {
    currentFilter: ITableFilter[];
}

export class CompaniesFilterController extends FilterController<ICompaniesFilterContext, void> {
    public id: string = "companies-filter";
    public title: string = "Фильтр";

    public filter = {
        createdAt: {
            control: new DateRange(),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.createdAt.control.value;
            },
            set: (date: FilterPair): void => {
                this.filter.createdAt.control.value = date;
            },
        },
        utm_source: {
            control: new TextBox(),

            type: FilterType.Equals,

            get: (): string => {
                return this.filter.utm_source.control.text;
            },
            set: (value: string): void => {
                this.filter.utm_source.control.text = value;
            },
        },
    };

    private ftFooter!: Footer;

    private context!: ICompaniesFilterContext;

    public onSave: ((filter: ITableFilter[]) => Promise<boolean>) | null = null;

    constructor() {
        super();

        this.initControls();
        this.initFooter();
    }

    public show(context: ICompaniesFilterContext): Promise<void> {
        this.context = context;
        this.initControls();
        this.initFooter();
        this.populateFilters();

        return super.show(context);
    }

    private initControls(): void {
        this.filter.createdAt.control = new DateRange();
        this.filter.createdAt.control.id = this.getControlId("createdAt");
        this.filter.createdAt.control.label = "Дата создания";

        this.filter.utm_source.control = new TextBox();
        this.filter.utm_source.control.id = this.getControlId("utmSource");
        this.filter.utm_source.control.label = "utm_source";
    }

    private initFooter(): void {
        this.ftFooter = new Footer({
            okText: "Сохранить",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateFilters(): void {
        if (!this.context.currentFilter) {
            return;
        }

        const filters = this.getLocalFilter(this.context.currentFilter);

        Object.entries(filters).forEach(([k, filter]) => {
            const key = k as keyof CompaniesFilter;

            if (!this.filter[key]) {
                return;
            }

            this.filter[key].set(filter as any);
        });
    }

    public get controls(): Control[] {
        return Object.entries(this.filter).map(([_, filter]) => filter.control);
    }

    public get footer(): Control {
        return this.ftFooter;
    }

    public get tableFilter(): ITableFilter[] {
        return this.getTableFilter(this.filter);
    }

    public async clickSave(): Promise<void> {
        if (!this.onSave) {
            return;
        }

        const result = await this.onSave(this.tableFilter);

        if (result) {
            this.hide();
        }
    }

    public clickCancel(): void {
        this.hide();
    }
}
