import { Vue, Component } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ICompany, IOrderStage, IOrderStageCreateDto, IOrderStageUpdateDto, IUser } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import VaTable from "@/components/common/va-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { OrderStageModal } from "./modals/order-stage.modal";
import { getTableColumns } from "./settings-lists-orderstages-defaults";

@Component({
    components: { BButton, VaTable, ModalComponent },
})
export default class SettingsListsOrderStages extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private stages: IOrderStage[] = [];

    private OrderStageUseCase = this.$alt.system.usecase.createOrderStageUseCase();

    private stageModal: OrderStageModal;

    public constructor() {
        super();

        this.stageModal = new OrderStageModal();
        this.stageModal.onCreate = this.create.bind(this);
        this.stageModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectOrderStages();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected openModalCreate(): void {
        this.stageModal.show();
    }

    protected openModalUpdate(stage: IOrderStage): void {
        this.stageModal.show(stage);
    }

    private async confirmCopy(stage: IOrderStage): Promise<void> {
        const result = await this.$alt.message.confirm(`Скопировать этап "${stage.name}"?`, "Копирование этапа", {
            acceptText: "Скопировать",
            color: "primary",
        });

        if (result) {
            await this.copy(stage);
        }
    }

    private async confirmDelete(stage: IOrderStage): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить этап: "${stage.name}"?`,
            "Удаление этапа",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(stage);
        }
    }

    private async selectOrderStages(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.stages = await this.OrderStageUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить этапы заявок:\n${e.message}`);
        }
    }

    private async create(dto: IOrderStageCreateDto): Promise<IOrderStage | null> {
        try {
            this.$alt.loader.show();
            const os = await this.OrderStageUseCase.create(this.company.id, dto);
            await this.selectOrderStages();
            this.$alt.toast.success("Этап успешно создан.", "Создание");
            return os;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать этап:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(stage: IOrderStage, dto: IOrderStageUpdateDto): Promise<IOrderStage | null> {
        try {
            this.$alt.loader.show();
            const os = await this.OrderStageUseCase.update(this.company.id, stage.id, dto);
            await this.selectOrderStages();
            this.$alt.toast.success(`Этап "${stage.name}" успешно изменён.`, "Изменение");
            return os;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить этап:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(stage: IOrderStage): Promise<IOrderStage | null> {
        try {
            this.$alt.loader.show();
            const dto: IOrderStageCreateDto = {
                //offices: stage.offices,
                //sequence: undefined,
                type: stage.type,
                name: stage.name + " - копия",
                description: stage.description,
                color: stage.color,
            };
            const os = await this.OrderStageUseCase.create(this.company.id, dto);
            await this.selectOrderStages();
            this.$alt.toast.success(`Этап "${stage.name}" успешно скопирован.`, "Копирование");
            return os;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать этап:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(stage: IOrderStage): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.OrderStageUseCase.archive(this.company.id, stage.id);
            await this.selectOrderStages();
            this.$alt.toast.success(`Этап "${stage.name}" успешно удалён.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить этап:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
