import { DataLoadException } from "@/exceptions";
import { ITrackResultDto, ITrackingUseCase } from "@lib";
import { Api } from "../axios";

export class TrackingUseCase implements ITrackingUseCase {
    public async track(code: string): Promise<ITrackResultDto> {
        try {
            const response = await Api.get<ITrackResultDto>(`/tracking/${code}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
