import tinymce, { Editor, Ui } from "tinymce";

export type MacroAutocomplete = {
    type: "autocompleteitem";
    text: string;
    value: string;
    nested?: MacroAutocomplete[];
};

const getNestedTokens = (value: string, macroAutocomplete: MacroAutocomplete[]): MacroAutocomplete[] => {
    const macroItem = macroAutocomplete.find((e: any) => e.value === value);

    if (!macroItem || !macroItem.nested) {
        return [];
    }

    return macroItem.nested;
};

const findDeepNestedTokens = (tokens: string[], macroAutocomplete: MacroAutocomplete[]): MacroAutocomplete[] => {
    const list = tokens.reduce((a, token) => {
        if (!a.length) {
            const matched = macroAutocomplete.find(e => e.value === token);

            return matched ? matched.nested ?? [] : a;
        }

        const matched = a.find(e => e.value === token);

        return matched ? matched.nested ?? [] : a;
    }, [] as MacroAutocomplete[]);

    const currentToken = tokens[tokens.length - 1];

    return list
        .filter(e => e.value.includes(currentToken))
        .map(e => {
            // Помечаем значение как конечное вложенное (!*) или просто вложенное (*)
            return {
                ...e,
                value: e.nested ? `*${e.value}` : `!*${e.value}`,
            };
        });
};

const getPreviousTokens = (text: string): string => {
    return (
        text
            .split("%")
            .map((e: string) => e.replace(/\(|\)/g, ""))
            .pop()
            ?.split(".")
            .slice(0, -1)
            .join(".") ?? ""
    );
};

export const autocompleterSpec = (
    editor: Editor,
    ch: string,
    macroAutocomplete: MacroAutocomplete[],
): Ui.InlineContent.AutocompleterSpec => {
    return {
        ch,

        fetch: (pattern, maxResults) => {
            return new tinymce.util.Promise((resolve, reject) => {
                const tokens = pattern.slice(1).split(".");

                if (tokens.length > 1) {
                    resolve(findDeepNestedTokens(tokens, macroAutocomplete));
                }

                resolve(macroAutocomplete.filter(e => e.text.includes(pattern) || e.value.includes(pattern)));
            });
        },

        onAction: (api, range, value, meta) => {
            editor.selection.setRng(range);

            const isEndNestingValue = value.startsWith("!*");
            const isContinueNestingValue = value.startsWith("*");

            if ([isEndNestingValue, isContinueNestingValue].some(e => e)) {
                const currentNodeText = (range.endContainer as any).outerText;
                const previousTokens = getPreviousTokens(currentNodeText);

                if (isEndNestingValue) {
                    const currentToken = value.slice(2);
                    editor.insertContent(`%(${previousTokens}.${currentToken})`);

                    api.hide();
                    return;
                }

                if (isContinueNestingValue) {
                    const currentToken = value.slice(1);
                    editor.insertContent(`%(${previousTokens}.${currentToken}`);
                    api.reload({});
                    return;
                }
            }

            const nested = getNestedTokens(value, macroAutocomplete);

            if (!nested.length) {
                editor.insertContent(`%(${value})`);
                api.hide();
                return;
            }

            editor.insertContent(`%(${value}`);

            api.reload({});
        },

        minChars: 0,
    };
};
