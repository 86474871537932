import { FieldType, IField } from "@lib";
import { Control } from "@core/components/alt-ui/controls";
import { Panel } from "@core/components/alt-ui/controls/panel";
import { IFieldSettingsContext } from "../field-settings";
import { Uuid } from "@/utils/uuid";

export default abstract class FieldSettingsItems extends Panel {
    protected readonly context: IFieldSettingsContext;
    protected readonly fieldType: FieldType | null;

    public constructor(context: IFieldSettingsContext, fieldType: FieldType | null) {
        super();
        this.id = Uuid.new();
        this.context = context;
        this.fieldType = fieldType;
        this.initializeControls();
    }

    protected abstract initializeControls(): void;

    public abstract populateControls(field: IField): void;

    public abstract get controls(): Control[];

    public abstract getField(): IField;

    public validate(): boolean {
        return true;
    }

    protected validateMacros(macro: string): boolean {
        if (!this.context.macros) {
            return true;
        }

        const customId = this.context.field?.customId;
        const assocs = customId
            ? this.context.macros.filter(a => a.macro === macro && a.field.customId !== customId)
            : this.context.macros.filter(a => a.macro === macro);

        return assocs.length === 0;
    }
}
