/** Идентификатор акции. */
export enum CompanyPaymentPromo {
    /** Регистрация. */
    Registration = "registration",

    /** Партнёрская программа. */
    Partner = "partner",

    /** Партнёрская программа: первая оплата гостя. */
    PartnerGuestPayment = "partner-guest-payment",
}
