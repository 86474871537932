import { IWork, Locale } from "@lib";
import { Localizer } from "@/i18n/localizer";

export function getTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.create && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (work: IWork) => context.openModalUpdate(work),
                        },
                        {
                            id: "copy",
                            description: "Скопировать",
                            icon: "CopyIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.create,
                            click: (work: IWork) => context.confirmCopy(work),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (work: IWork) => context.confirmDelete(work),
                        },
                    ],
                };
            },
        },
        {
            colId: "sequence",
            field: "sequence",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,
        },
        {
            colId: "name",
            field: "name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "description",
            field: "description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 300,
            hide: false,
        },
        {
            colId: "cost",
            field: "cost",
            headerName: "Себестоимость",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,

            cellRenderer: function (params: any) {
                // TODO: подсасывать локаль откуда-то
                const locale = Locale.RU;
                return Localizer.moneyFull(params.value, locale);
            },
        },
        {
            colId: "price",
            field: "price",
            headerName: "Цена",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,

            cellRenderer: function (params: any) {
                // TODO: подсасывать локаль откуда-то
                const locale = Locale.RU;
                return Localizer.moneyFull(params.value, locale);
            },
        },
    ];
}
