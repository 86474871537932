import { Vue, Component } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import moment from "moment";
import {
    ICompany,
    ISelectQuery,
    ISettingsTableUpdateDto,
    ITableSort,
    IUser,
    IWork,
    PermissionCommonSection,
    PermissionRight,
    TableType,
} from "@lib";
import { IWorkCreateDto, IWorkUpdateDto } from "@lib";
import VaTable from "@/components/common/va-table";
import { getTableColumns } from "./settings-lists-works-defaults";
import { WorksModal } from "./modals/works.modal";
import { ModalComponent } from "@core/components/alt-ui/modal";
import VaAutoButton from "@core/components/va-auto-button";
import { WorksImportContextOut, WorksImportModal } from "./modals/works-import.modal";
import { WorksExportContextOut, WorksExportModal } from "./modals/works-export.modal";
import SettingsListsWorksToolbar from "./settings-lists-works-toolbar.vue";

@Component({
    components: { BButton, VaTable, ModalComponent, VaAutoButton, SettingsListsWorksToolbar },
})
export default class SettingsListsWorks extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private works: IWork[] = [];
    private worksTotal: number = 0;
    private table: any = {
        settings: {
            limit: undefined,
            sort: [],
        },
        page: 1,
        search: "",
    };

    private WorkUseCase = this.$alt.system.usecase.createWorkUseCase();
    private SettingsTable = this.$alt.system.usecase.createSettingsTableUseCase();

    private worksModal: WorksModal;
    private worksImportModal: WorksImportModal;
    private worksExportModal: WorksExportModal;

    public constructor() {
        super();

        this.worksModal = new WorksModal();
        this.worksModal.onCreate = this.create.bind(this);
        this.worksModal.onUpdate = this.update.bind(this);

        this.worksImportModal = new WorksImportModal();
        this.worksImportModal.onImport = this.import.bind(this);

        this.worksExportModal = new WorksExportModal();
        this.worksExportModal.onExport = this.export.bind(this);
    }

    private get worksDataTotal(): number {
        return this.worksTotal ? this.worksTotal : 0;
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get tableType(): TableType {
        return TableType.Work;
    }

    private get limit(): number {
        return this.table.settings.limit ?? 10;
    }

    private get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    private get sort(): ITableSort[] {
        return this.table.settings.sort ?? [];
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectTableSettings();
            await this.selectWorks();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected openModalCreate(): void {
        this.worksModal.show();
    }

    protected openModalUpdate(work: IWork): void {
        this.worksModal.show(work);
    }

    private async openModalImport(): Promise<void> {
        await this.worksImportModal.show();
    }

    private async openModalExport(): Promise<void> {
        await this.worksExportModal.show();
    }

    private async import(context: WorksImportContextOut): Promise<boolean> {
        try {
            this.$alt.loader.show();

            if (!context.file) {
                this.$alt.toast.warning("Файл не выбран.");
                return false;
            }

            const tag = `import_${moment().format()}`;
            const dto = { tags: [tag] };
            await this.WorkUseCase.import(this.company.id, context.file, dto);
            this.$alt.toast.success("Данные успешно загружены.");
            await this.selectWorks();
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async export(context: WorksExportContextOut): Promise<boolean> {
        try {
            this.$alt.loader.show();

            const company = await this.$info.getCompany();
            const dto = { ...context.filters };
            const file = await this.WorkUseCase.export(company.id, dto);

            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = `export_${moment().format()}.csv`;
            link.click();
            setTimeout(() => URL.revokeObjectURL(link.href), 0);

            this.$alt.toast.success("Файл отправлен на скачивание.");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async confirmCopy(work: IWork): Promise<void> {
        const result = await this.$alt.message.confirm(`Скопировать работу "${work.name}"?`, "Копирование работы", {
            acceptText: "Скопировать",
            color: "primary",
        });

        if (result) {
            await this.copy(work);
        }
    }

    private async confirmDelete(work: IWork): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить работу: "${work.name}"?`,
            "Удаление работы",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(work);
        }
    }

    private async selectWorks(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            const sort = this.$alt.system.query.convertTableSort(this.sort);
            const search = this.table.search.length > 0 ? this.table.search : undefined;
            const query: ISelectQuery = { limit: this.limit, offset: this.skip, sort, search };
            const result = await this.WorkUseCase.select(this.company.id, query);

            this.works = result.data;
            this.worksTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить работу:\n${e.message}`);
        }
    }

    private async selectTableSettings(): Promise<void> {
        const table = await this.SettingsTable.get(this.company.id, this.user.id, this.tableType);
        this.table.settings = table ?? this.table.settings;
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            const table = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            if (!table) {
                throw new Error();
            }

            this.table.settings = table;
            await this.selectWorks();
        } catch {}
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            if (!settings) {
                throw new Error();
            }
            this.table.settings = settings;
            this.table.page = page;
            await this.selectWorks();
        } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.$alt.loader.show();
            this.table.page = page;
            await this.selectWorks();

            // добавить к запросу номер страницы: p=2
            // if (page === 1) super.queryRemove("p");
            // else super.queryAdd("p", page);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            // начинать поиск от 2 символов
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectWorks();
        } catch (e: any) {
            this.works = [];
        }
    }

    private async create(dto: IWorkCreateDto): Promise<IWork | null> {
        try {
            this.$alt.loader.show();

            const work = await this.WorkUseCase.create(this.company.id, dto);
            await this.selectWorks();
            this.$alt.toast.success(`Работа "${dto.name}" успешно создана.`, "Создание");

            return work;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать работу:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(work: IWork, dto: IWorkUpdateDto): Promise<IWork | null> {
        try {
            this.$alt.loader.show();

            const response = await this.WorkUseCase.update(this.company.id, work.id, dto);
            await this.selectWorks();
            this.$alt.toast.success(`Работа "${work.name}" успешно изменена.`, "Изменение");

            return response;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить работу:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async copy(work: IWork): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: IWorkCreateDto = {
                offices: work.offices as string[],
                sequence: work.sequence,
                name: work.name + " - копия",
                description: work.description,
                cost: work.cost,
                price: work.price,
                warranty: work.warranty,
            };
            await this.WorkUseCase.create(this.company.id, dto);
            await this.selectWorks();
            this.$alt.toast.success(`Работа "${work.name}" успешно скопирована.`, "Копирование");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось скопировать работу:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(work: IWork): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.WorkUseCase.archive(this.company.id, work.id);
            await this.selectWorks();
            this.$alt.toast.success(`Работа "${work.name}" успешно удалена.`, "Удаление");
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить работу:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
