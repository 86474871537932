import { Uuid } from "./uuid";

export type DelayedAction<T = void> = () => Promise<T>;

/**
 * Класс для выполнения операций с задержкой.
 *
 * *Пример 1 (instance):*
 *
 *     const do = new DelayedOperation();
 *     do.invoke(1000, () => { this.$event("event", payload); });
 *
 * *Пример 2 (static):*
 *
 *     DelayedOperation.invoke("event", 1000, () => { this.$event("event", payload); });
 */
export class DelayedOperation {
    private name: string;

    public constructor() {
        this.name = Uuid.new();
    }

    /**
     * Выполнить операцию один раз с задержкой.
     * @param time Через сколько миллисекунд выполнить операцию.
     * @param func Операция.
     */
    public invoke(time: number, func: Function): void {
        DelayedOperation.invoke(this.name, time, func);
    }

    //

    private static locks: any = {};

    /**
     * Выполнить операцию один раз с задержкой.
     * @param name Уникальное имя операции.
     * @param time Через сколько миллисекунд выполнить операцию.
     * @param func Операция.
     * @param reset Переназначить отложенную операцию, если она уже существует (по умолчанию `true`).
     */
    public static invoke(name: string, time: number, func: Function, reset = true): void {
        if (reset) {
            this.reset(name);
        }

        if (!this.locks[name]) {
            this.locks[name] = setTimeout(() => {
                func();
                this.deleteLock(name);
            }, time);
        }
    }

    // TODO: доделать
    /**
     * Выполнить операцию один раз с задержкой.
     * @param name Уникальное имя операции.
     * @param time Через сколько миллисекунд выполнить операцию.
     * @param func Операция.
     */
    public static async invokeAsync<T = void>(
        name: string,
        time: number,
        func: DelayedAction<T>,
        reset = true,
    ): Promise<T> {
        return new Promise((resolve, reject) => {
            if (reset) {
                this.reset(name);
            }

            if (!this.locks[name]) {
                this.locks[name] = setTimeout(async () => {
                    const result = await func();
                    this.deleteLock(name);
                    resolve(result);
                }, time);
            }
            reject();
        });
    }

    /**
     * Прервать задержанную операцию.
     * @param name Уникальное имя операции.
     */
    public static reset(name: string): void {
        if (this.locks[name]) {
            clearTimeout(this.locks[name]);
            this.deleteLock(name);
        }
    }

    private static deleteLock(name: string): void {
        if (this.locks[name]) {
            delete this.locks[name];
        }
    }
}

/** Выполнить операцию с задержкой. */
export function debounce(fn: Function, ms = 750): Function {
    let timeoutId: ReturnType<typeof setTimeout>;

    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
}
