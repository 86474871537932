import { Vue, Component, Watch } from "vue-property-decorator";
import { BModal } from "bootstrap-vue";
import {
    IShop,
    ISale,
    IAccount,
    IStore,
    IEmployee,
    IClient,
    ITable,
    IUser,
    ICompany,
    IReadQuery,
    ISaleStage,
    PaymentType,
    ISaleInfoChangeDto,
} from "@lib";
import { IClientSource, IDocument, DocumentType } from "@lib";
import { ISettingsTableUpdateDto, ISaleDeleteDto, ISaleDeleteManyDto } from "@lib";
import { FormType, ITableFilter, ITableSort, TableType } from "@lib";
import { PermissionType, PermissionCommonSection, PermissionRight } from "@lib";
import VaTable from "@/components/common/va-table";
import { Button, Icon } from "@core/components/alt-ui/controls";
import { MultiDropdown } from "@core/controls/multi-dropdown/multi-dropdown";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { SalePrintContext } from "@core/usecases/template/macro-replacers/sale-document.macro-replacer";
import { Printer } from "@core/usecases/template/printer";
import SaleCreateFormComponent from "./sale-create-form/sale-create-form.component.vue";
import SaleViewFormComponent from "./sale-view-form/sale-view-form.component.vue";
import { ISaleCreateFormContext, SaleCreateForm } from "./sale-create-form/sale-create-form";
import { ISaleViewFormContext, SaleViewForm } from "./sale-view-form/sale-view-form";
import { SaleChangeStageModal, SaleChangeStageModalAnswer } from "./sale-change-stage.modal";
import { ISaleCompleteModalData, SaleCompleteModal, SaleCompleteModalAnswer } from "./sale-complete.modal";
import { ISaleReopenModalData, SaleReopenModal, SaleReopenModalAnswer } from "./sale-reopen.modal";
import { SaleDeleteModal, SaleDeleteModalAnswer } from "./sale-delete.modal";
import SalesToolbar from "./sales-toolbar/sales-toolbar.vue";
import { getDefaultTableActions, getDefaultTableColumns } from "./sales-defaults";
import { ISalesFilterContext, SalesFilterController } from "./sales-filter-controller";

@Component({
    components: {
        BModal,
        VaTable,
        ModalComponent,
        SaleCreateFormComponent,
        SaleViewFormComponent,
        SalesToolbar,
    },
})
export default class Sales extends Vue {
    private SaleUseCase = this.$alt.system.usecase.createSaleUseCase();
    private ClientUseCase = this.$alt.system.usecase.createClientUseCase();
    private SettingsTable = this.$alt.system.usecase.createSettingsTableUseCase();

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private selectedShops: IShop[] = [];
    private documents: IDocument[] = [];
    private sales: ISale[] = [];
    private salesTotal: number = 0;
    private shops: IShop[] = [];
    private stores: IStore[] = [];
    private accounts: IAccount[] = [];
    private employees: IEmployee[] = [];
    private stages: ISaleStage[] = [];
    private clientSources: IClientSource[] = [];

    private saleCreateForm = new SaleCreateForm();
    private saleViewForm = new SaleViewForm();
    private changeStageModal: SaleChangeStageModal;
    private completeModal: SaleCompleteModal;
    private reopenModal: SaleReopenModal;
    private deleteModal: SaleDeleteModal;

    private needPaymentShow = false;

    private table: any = {
        settings: {
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
        },
        page: 1,
        search: "",

        forceUpdate: false,
        update(): void {
            this.forceUpdate = true;
        },
    };

    public filterController: SalesFilterController;

    public get filterContext(): ISalesFilterContext {
        return {
            shops: this.shops,
            stages: this.stages,
            employees: this.employees,

            defaultFilter: {
                shop: this.selectedShops.map(shop => shop.id),
            },

            currentFilter: this.filter,
        };
    }

    public constructor() {
        super();

        this.changeStageModal = new SaleChangeStageModal();
        this.completeModal = new SaleCompleteModal();
        this.reopenModal = new SaleReopenModal();
        this.deleteModal = new SaleDeleteModal();

        this.filterController = new SalesFilterController();
        this.filterController.onSave = this.saveFilter.bind(this);
    }

    private get formType(): FormType {
        return FormType.Sale;
    }

    private get tableType(): TableType {
        return TableType.Sale;
    }

    private get clientSourcesData(): IClientSource[] {
        return this.clientSources ?? [];
    }

    private get salesData(): ISale[] {
        return this.sales ?? [];
    }

    private get salesDataTotal(): number {
        return this.salesTotal ?? 0;
    }

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this, this.showChangeStageModal);
    }

    private get defaulActions(): any[] {
        return getDefaultTableActions(this);
    }

    private get columns(): any[] {
        // TODO: отрефакторить
        // берём дефолтный набор колонок
        // сначала сортируем их в соответсвии с тем, что лежит в базе
        // затем копируем свойства: ширину и видимость

        const dbColumns = this.table.settings.columns;
        const sorted = this.defaulColumns
            .sort((col1: any, col2: any) => {
                for (let i = 0; i < dbColumns.length; ++i) {
                    if (dbColumns[i].colId === col1.colId) {
                        return -1;
                    }
                    if (dbColumns[i].colId === col2.colId) {
                        return 1;
                    }
                }
                return 0;
            })
            .map((column: any) => {
                const dbColumn = dbColumns.find((dbc: any) => dbc.colId === column.colId);
                if (dbColumn) {
                    column.width = dbColumn.width;
                    column.hide = dbColumn.hide;
                }
                return column;
            });

        return sorted;
    }

    private get limit(): number {
        return this.table.settings.limit ?? 10;
    }

    private get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    private get sort(): ITableSort[] {
        return this.table.settings.sort ?? [];
    }

    private get filter(): ITableFilter[] {
        return this.table.settings.filter ?? [];
    }

    private get hasFilter(): boolean {
        return this.filter.length > 0 && !!this.filter.find(f => f.field !== "shop");
    }

    private get hasSales(): boolean {
        return this.salesData.length > 0 || this.hasFilter || this.table.search.length > 0;
    }

    private get can(): any {
        const secure = this.$secure;
        const shops = this.selectedShops;
        return {
            get update(): Function {
                return (sale: ISale): boolean => {
                    return secure.check(PermissionType.Shops, sale.shop, PermissionRight.SalesUpdate);
                };
            },
            get delete(): Function {
                return (sale: ISale): boolean => {
                    return secure.check(PermissionType.Shops, sale.shop, PermissionRight.SalesDelete);
                };
            },
            get createShop(): boolean {
                return secure.checkCommon(PermissionCommonSection.Shops, PermissionRight.Create);
            },
            get create(): boolean {
                for (const shop of shops) {
                    const valid = secure.check(PermissionType.Shops, shop.id, PermissionRight.SalesCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    @Watch("selectedShops", { deep: true, immediate: true })
    private onSelectedShopsUpdate(): void {
        const headerControls = this.$info.ui.getHeaderControls();

        const hdrShops = headerControls.find(c => c.id === "sales.header-shops") as MultiDropdown<IShop>;
        if (hdrShops) {
            if (!this.$alt.compareArrays(hdrShops.selectedItems, this.selectedShops, e => e.id)) {
                hdrShops.selectedItems = this.selectedShops;
            }
        }
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.shops = await this.$info.getShops();
            this.stores = await this.$info.getStores();
            this.accounts = await this.$info.getAccounts();
            this.employees = await this.$info.getEmployees();
            await this.initData();
            await this.initParams();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        await Promise.all([
            this.selectTableSettings(),
            //this.selectFormSettings(),
            this.selectSaleStages(),
            this.selectClientSources(),
            this.selectDocuments(),
        ]);

        const filterShops = this.filter.filter(item => item.field === "shop");
        this.selectedShops = this.shops.filter(s => !!filterShops.find(f => f.value === s.id));

        this.filterController.init(this.filterContext);

        if (!this.selectedShops.length && this.shops[0]) {
            this.filterController.filter.shop.set([this.shops[0].id]);

            this.saveFilter(this.filterController.tableFilter);
        }

        this.initHeader();

        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private initHeader(): void {
        const hdrShops = new MultiDropdown<IShop>();
        hdrShops.id = "sales.header-shops";
        hdrShops.items = this.shops;
        hdrShops.selectedItems = this.selectedShops;
        hdrShops.itemId = item => item.id;
        hdrShops.itemName = item => item.info.name;
        hdrShops.iconPackage = "feather";
        hdrShops.icon = "ShoppingCartIcon";
        hdrShops.locale = {
            Tooltip: "Выбрать магазин",
            ButtonSelectOne: "Выбрать один",
            ButtonSelectMultiple: "Выбрать несколько",
            ButtonSelectAll: "Выбрать все",
            TextNotSelected: "Магазин не выбран",
            TextSelectedAll: "Все магазины",
            TextSelectedMultiple: "Выбрано:",
            TextSelectedMultipleForms: ["магазин", "магазина", "магазинов"],
        };
        hdrShops.addChangedHandler((s, e) => {
            this.filterController.filter.shop.set(e.items.map(store => store.id));
            this.saveFilter(this.filterController.tableFilter);
        });

        const hdrRefresh = new Button();
        hdrRefresh.id = "sales.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrShops, hdrRefresh]);
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$route.query.new !== undefined) {
                await this.openFormCreate();
                return;
            }

            if (this.$route.query.id) {
                const saleId =
                    this.$route.query.id instanceof Array ? (this.$route.query.id[0] as string) : this.$route.query.id;
                const sale = await this.getSale(saleId);
                this.openFormUpdate(sale);
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            this.$alt.toast.error("Продажа не найдена.");
        }
    }

    private async openFormCreate(): Promise<void> {
        const result = await this.$alt.system.usecase.createCompanyPricingUseCase().checkCreateSale(this.company.id);
        if (!result) {
            const company = await this.$alt.system.usecase.createCompanyUseCase().get(this.company.id);
            company ? this.$info.setCompany(company) : undefined;
            this.needPaymentShow = true;
            return;
        }

        this.saleCreateForm.onOpening = async () => this.$router.push({ query: { new: "" } }).catch(() => {});
        this.saleCreateForm.onClosed = async () => this.$router.push({ query: undefined }).catch(_ => {});

        const context: ISaleCreateFormContext = {
            completeModal: this.completeModal,
            onPrintDocuments: (documents, sale) => this.saveDocumentsAndPrint(documents, sale),
        };

        this.saleCreateForm.show(context);
    }

    private openFormUpdate(sale: ISale): void {
        // this.saleViewForm.addVisibleChangedHandler((s, e) => {
        //     if (e.visible) {
        //         this.$router.push({ query: { id: sale.id } }).catch(() => {});
        //     } else {
        //         this.$router.push({ query: undefined }).catch(_ => {});
        //     }
        // });

        this.saleViewForm.onOpening = () => this.$router.push({ query: { id: sale.id } }).catch(() => {});
        this.saleViewForm.onClosed = () => this.$router.push({ query: undefined }).catch(_ => {});

        const context: ISaleViewFormContext = {
            sale: sale,
            onComplete: sale => this.showCompleteModal(sale),
            onReopen: sale => this.showReopenModal(sale),
            onDelete: sale => this.confirmDelete(sale),
        };

        this.saleViewForm.show(context);
    }

    private async showChangeStageModal(sale: ISale): Promise<void> {
        const context = {
            sale: sale,
            stages: this.stages,
        };

        const result = await this.changeStageModal.show(context);
        if (result?.answer === SaleChangeStageModalAnswer.Change && result.data) {
            await this.changeSaleStage(sale, result.data.stage, result.data.comment);
        } else if (result?.answer === SaleChangeStageModalAnswer.Complete) {
            await this.showCompleteModal(sale);
        } else if (result?.answer === SaleChangeStageModalAnswer.Reopen) {
            await this.showReopenModal(sale);
        }
    }

    private async showCompleteModal(sale: ISale): Promise<void> {
        if (!sale.shopRef) {
            return;
        }

        const documents = await this.$settings.getDocumentsSaleCreate();
        const documentsSelected = this.documents.filter(d => documents.includes(d.id));

        const context = {
            paidSum: sale.payments?.reduce((sum, payment) => sum + payment.value, 0.0) ?? 0.0,
            actualPrice: sale.goods?.reduce((sum, good) => sum + good.quantity * good.price, 0.0) ?? 0.0,
            discount: sale.info?.discount ?? undefined,

            shop: sale.shopRef,
            stages: this.stages,
            accounts: this.accounts,
            documents: this.documents,
            documentsSelected: documentsSelected,
        };

        const result = await this.completeModal.show(context);

        if (result?.answer === SaleCompleteModalAnswer.Complete && result.data) {
            await this.completeSale(sale, result.data);
        }
    }

    private async showReopenModal(sale: ISale): Promise<void> {
        const context = {
            stages: this.stages,
        };

        const result = await this.reopenModal.show(context);

        if (result?.answer === SaleReopenModalAnswer.Reopen && result.data) {
            await this.reopenSale(sale, result.data);
        }
    }

    private async confirmDelete(sale: ISale): Promise<void> {
        const result = await this.deleteModal.show([sale]);

        if (result?.answer === SaleDeleteModalAnswer.Delete && result.data) {
            await this.deleteSale(sale, result.data);
        }
    }

    private async confirmDeleteMany(sales: ISale[]): Promise<void> {
        const result = await this.deleteModal.show(sales);
        if (result?.answer === SaleDeleteModalAnswer.Delete && result.data) {
            await this.deleteMany(sales, result.data);
        }
    }

    public showFilter(): void {
        this.filterController.show(this.filterContext);
    }

    private async saveFilter(filter: ITableFilter[]): Promise<boolean> {
        try {
            const dto: ISettingsTableUpdateDto = { filter };
            const service = this.$alt.system.usecase.createSettingsTableUseCase();
            await service.update(this.company.id, this.user.id, TableType.Sale, dto);

            this.changeFilter(filter);

            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось сохранить настройки фильтра:\n${e.message}`);
            return false;
        }
    }

    private async refreshData(showToastOnSuccess = true): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();

            if (showToastOnSuccess) {
                this.$alt.toast.success("Данные обновлены.");
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async changeFilter(filter: ITableFilter[], withDataUpdate = true): Promise<void> {
        try {
            this.table.settings.filter = filter;

            const filterShops = this.filter.filter(item => item.field === "shop");
            this.selectedShops = this.shops.filter(s => !!filterShops.find(f => f.value === s.id));

            if (withDataUpdate) {
                await this.selectData(this.skip, this.limit, this.sort, this.filter);
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async selectTableSettings(): Promise<void> {
        let settings: ITable | null = null;
        try {
            settings = await this.SettingsTable.get(this.company.id, this.user.id, this.tableType);
        } catch {}
        this.applyTableSettings(settings ?? this.table.settings);
    }

    private applyTableSettings(settings: ITable | null): void {
        if (!settings) {
            throw new Error();
        }

        this.table.settings = settings;

        if (settings.filter && settings.filter.length > 0) {
            return;
        }
    }

    private async changeTableColumns(columns: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            // добавить к запросу номер страницы: p=2
            // if (page === 1) super.queryRemove("p");
            // else super.queryAdd("p", page);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async selectSaleStages(): Promise<void> {
        try {
            this.stages = await this.$alt.system.usecase.createSaleStageUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить этапы продаж:\n${e.message}`);
        }
    }

    private async selectClientSources(): Promise<void> {
        try {
            this.clientSources = await this.$alt.system.usecase.createClientSourceUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить источники килентов:\n${e.message}`);
        }
    }

    private async selectDocuments(): Promise<void> {
        try {
            this.documents = await this.$alt.system.usecase
                .createDocumentUseCase()
                .selectByType(this.company.id, DocumentType.ShopDocument);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить документы:\n${e.message}`);
        }
    }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const search = this.table.search.length > 0 ? this.table.search : undefined;
            const query: IReadQuery = { limit, offset, sort, search, filter };
            const result = await this.SaleUseCase.select(this.company.id, query);

            this.sales = result.data;
            this.salesTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить продажи:\n${e.message}`);
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            // начинать поиск от 2 символов
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.sales = [];
            this.salesTotal = 0;
        }
    }

    private async getSale(id: string): Promise<ISale> {
        try {
            const sale = await this.SaleUseCase.get(this.company.id, id);
            if (!sale) {
                throw new Error("Продажа не найдена.");
            }
            return sale;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить данные продажи:\n${e.message}`);
        }
    }

    private async changeSaleStage(orig: ISale, stage: ISaleStage, comment: string): Promise<ISale | null> {
        try {
            this.$alt.loader.show();
            const sale = await this.SaleUseCase.changeStage(this.company.id, orig.id, stage.id, comment);
            this.changeSale(sale, orig);
            this.$alt.toast.success("Этап успешно изменён.");
            return sale;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async completeSale(orig: ISale, dto: ISaleCompleteModalData): Promise<boolean> {
        try {
            this.$alt.loader.show();

            const infoDto: ISaleInfoChangeDto | undefined =
                dto.discount.value > 0 ? { discount: dto.discount } : undefined;

            const paymentDto = {
                type: PaymentType.Payment,
                accountId: dto.account.id,
                amount: dto.amount,
                description: "Оплата в магазине",
                userId: this.user.id,
            };

            const sale = await this.SaleUseCase.complete(
                this.company.id,
                orig.id,
                dto.stage.id,
                infoDto,
                undefined,
                paymentDto,
            );
            this.changeSale(sale, orig);
            this.saveDocumentsAndPrint(dto.documents, sale);
            this.$alt.toast.success("Продажа закрыта.");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async reopenSale(orig: ISale, dto: ISaleReopenModalData): Promise<boolean> {
        try {
            this.$alt.loader.show();

            const sale = await this.SaleUseCase.reopen(this.company.id, orig.id, dto.stage.id);
            this.changeSale(sale, orig);
            this.$alt.toast.success("Продажа снова открыта.");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async deleteSale(sale: ISale, deleteDto: ISaleDeleteDto): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: ISaleDeleteDto = {
                returnToStores: deleteDto.returnToStores,
                returnFromAccount: deleteDto.returnFromAccount,
                paymentDescription: "Возврат средств",
            };
            await this.SaleUseCase.delete(this.company.id, sale.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Продажа успешно удалена.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async deleteMany(sales: ISale[], dto: ISaleDeleteDto): Promise<void> {
        try {
            this.$alt.loader.show();
            const dtoMany: ISaleDeleteManyDto = {
                ids: sales.map(c => c.id),
                returnToStores: dto.returnToStores,
                returnFromAccount: dto.returnFromAccount,
                paymentDescription: "Возврат средств",
            };
            await this.SaleUseCase.deleteMany(this.company.id, dtoMany);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Продажи успешно удалены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async searchClients(search: string): Promise<IClient[]> {
        try {
            const limit: number = 10;
            const query: IReadQuery = { search, limit, offset: 0 };
            const result = await this.ClientUseCase.select(this.company.id, query);
            return result.data;
        } catch (e: any) {
            return [];
        }
    }

    private async changeSale(sale: ISale, saleOld: ISale): Promise<void> {
        this.saleViewForm.sale = sale;

        const index = this.sales.findIndex(o => o.id === saleOld.id);
        if (index >= 0) {
            this.sales[index] = sale;
            this.table.update();
        }
    }

    private saveDocumentsAndPrint(documents: IDocument[], sale: ISale): void {
        this.saveSelectedDocuments(documents);
        this.print(documents, sale);
    }

    private async saveSelectedDocuments(documents: IDocument[]): Promise<void> {
        try {
            const value = documents.map(d => d.id);
            await this.$settings.setDocumentsSaleCreate(value);
        } catch {}
    }

    private print(documents: IDocument[], sale: ISale): void {
        if (!sale.shopRef) {
            return;
        }

        const context: SalePrintContext = {
            company: this.company,
            shop: sale.shopRef,
            sale: sale,
        };

        Printer.printSaleDocuments(documents, context);
    }
}
