import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import VueSelect from "vue-select";
//import Multiselect from "vue-multiselect";
import { IOrder, IOrderType, IDocument, ICompany, IProductType, IOffice } from "@lib";
import { Printer } from "@/@core/usecases/template/printer";
import { OrderPrintContext } from "@core/usecases/template/macro-replacers/order-document.macro-replacer";

//import "vue-multiselect/dist/vue-multiselect.min.css";

// TODO: как организована печать документов в Ремонлайне:
// https://help.remonline.ru/ru/articles/2920487-%D0%B0%D0%B2%D1%82%D0%BE%D0%BC%D0%B0%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F-%D0%BF%D0%B5%D1%87%D0%B0%D1%82%D1%8C-%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%BE%D0%B2

// Какие типы документов есть в Ремонлайне:
// https://remonline.ru/features/program-for-printing-documents/

@Component({
    name: "order-view-stage",
    components: {
        BButton,
        VueSelect,
        //Multiselect
    },
})
export default class OrderViewPrint extends Vue {
    @Prop({ required: true })
    private order!: IOrder;

    @Prop({ required: true })
    private orderType!: IOrderType;

    @Prop({ type: Array, required: true })
    private productTypes!: IProductType[];

    @Prop({ type: Array, required: true })
    private documents!: IDocument[];

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private documentsSelected: IDocument[] = [];

    private company!: ICompany;

    @Watch("order", { immediate: true, deep: true })
    private onOrderChanged(): void {
        this.initValues();
    }

    public async mounted(): Promise<void> {
        try {
            this.company = await this.$info.getCompany();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private initValues(): void {
        this.documentsSelected = [];
    }

    private print(): void {
        if (!this.order.officeRef) {
            return;
        }

        const context: OrderPrintContext = {
            company: this.company,
            office: this.order.officeRef,
            order: this.order,
            orderType: this.orderType,
            productTypes: this.productTypes,
        };

        Printer.printOrderDocuments(this.documentsSelected, context);
    }
}
