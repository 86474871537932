








import axios from "axios";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Eula extends Vue {
    private linkEula = require("@/assets/pdf/eula.pdf");

    public mounted(): void {
        this.download();
    }

    private download(): void {
        axios({
            url: this.linkEula,
            method: "GET",
            responseType: "blob",
        }).then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "eula.pdf");
            document.body.appendChild(fileLink);

            fileLink.click();
        });
    }
}
