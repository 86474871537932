/** Тип этапа продажи. */
export enum SaleStageType {
    // TODO: Создана ???
    /** Новая. */
    New = "new",

    // TODO: заменить везде на New
    /** Создана. */
    Open = "open",

    // TODO: в сборке / в работе / в обработке / принята в работу
    // TODO: передаётся в доставку / готова к доставе / собрана
    // TODO: в доставке / передано в доставку
    // TODO: доставлена / готова к выдаче / ожидает в пункте выдачи
    // TODO: получена / выдана
    // TODO: возврат
    // TODO: отменена (+ причина)

    // TODO: В сборке ??? В обработке ???
    // TODO: inwork
    /** В работе. */
    Processing = "processing",

    /** В доставке. */
    Delivery = "delivery",

    /** Готова к выдаче. */
    Ready = "ready",

    // TODO: заменить везде на Closed
    /** Оплачена. */
    Paid = "paid",

    /** Закрыта. */
    Closed = "closed",

    /** Возврат. */
    Return = "return",

    /** Отменена. */
    Canceled = "canceled",
}


// TODO
// 
// Создано
// 
// Принято в работу
// Заказ оплачен. На данном этапе производится комплектация и упаковка отправления
// 
// Передано в доставку
// На данном этапе происходит транспортировка отправления до пункта выдачи
// 
// Ожидает в пункте выдачи
// Срок хранения отправления 5 дней
// 
// Получено

//

// TODO
// 
// В сборке у продавца
// Заказ оплачен, и продавец начал его собирать
// 
// Передаётся в доставку
// 
// В доставке
// На данном этапе происходит транспортировка товаров до пункта выдачи
// 
// Ожидает в пункте выдачи
// Срок хранения товаров 5 дней
// 
// Получено
