import { Vue, Component, Prop } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { IOrder } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { TextBox } from "@core/components/alt-ui/controls";

@Component({
    name: "order-view-title",
    components: { BButton, ValidationObserver, ControlComponent },
})
export default class OrderViewTitle extends Vue {
    @Prop({ required: true })
    private title!: string;

    @Prop({ required: true })
    private order!: IOrder | null;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    @Prop({ type: Boolean, default: false })
    private closed!: boolean;

    private editMode: boolean = false;
    private tbNumber = new TextBox();

    private editNumber(): void {
        if (!this.readonly && !this.closed && this.order && !this.editMode) {
            this.tbNumber = new TextBox();
            this.tbNumber.id = "order-view-title.number";
            this.tbNumber.validation = "required|numeric";
            this.tbNumber.text = this.order.number.toString() ?? "0";

            this.editMode = true;
        }
    }

    private async applyChanges(): Promise<void> {
        try {
            const result = await (this.$refs["orderTitleValidator"] as any).validate();
            if (!result) {
                return;
            }

            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            const companyId = this.order.company as string;
            const orderId = this.order.id;
            const number = parseInt(this.tbNumber.text);

            const usecase = this.$alt.system.usecase.createOrderUseCase();
            const order = await usecase.changeNumber(companyId, orderId, number);

            this.editMode = false;
            this.$emit("order-changed", order);
        } catch {
            this.$alt.toast.error("Не удалось изменить номер заявки.");
        }
    }

    private cancelChanges(): void {
        this.editMode = false;
    }
}
