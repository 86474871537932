import { IField } from "@lib";
import { Button, Control, Label } from "@core/components/alt-ui/controls";
// Важно импортировать именно из этого файла, т.к иначе мы получаем циклическую зависимость
// В компоненте модельного окна в случае, если он импортирует класс Panel отсюда же
import { Panel } from "@core/components/alt-ui/controls/panel";
import { ISelectOption } from "@core/types/common/select-options";
import { Uuid } from "@/utils/uuid";
import { ComboBoxItem } from "./combobox-item";

export default class ComboBoxItems extends Panel {
    public constructor() {
        super();
        this.initializeControls();
    }

    private lbLabel!: Label;
    private _items!: ComboBoxItem[];
    private pnlItems!: Panel;
    private btnAdd!: Button;

    protected initializeControls(): void {
        this.lbLabel = new Label();
        this.lbLabel.text = "Элементы списка";
        this.lbLabel.class = "ml-0.5 mb-0.25 text-sm";

        this._items = [];
        this.pnlItems = new Panel();
        this.addComboBoxItem();

        this.btnAdd = new Button();
        this.btnAdd.id = "items.add";
        this.btnAdd.addClickHandler(this.addComboBoxItem.bind(this));
        this.btnAdd.variant = "outline-primary";
        this.btnAdd.class = "w-100 mb-0.75";
        this.btnAdd.text = "+ Добавить элемент";
    }

    public populateControls(field: IField): void {
        this._items = [];
        field.items?.forEach((item: ISelectOption) => {
            const cbItem = this.generateItem(item);
            this._items.push(cbItem);
        });
        this.updateItemsPanel();
    }

    public get controls(): Control[] {
        return [this.lbLabel, this.pnlItems, this.btnAdd];
    }

    public get items(): ISelectOption[] {
        return this._items.map(item => item.toSelectOption());
    }

    private generateItem(option?: ISelectOption): ComboBoxItem {
        const item = new ComboBoxItem(option);
        item.class = "flex items-center mb-0.75";
        item.onMoveUp = () => this.moveToUp(item);
        item.onMoveDown = () => this.moveToDown(item);
        item.onDelete = () => this.removeItem(item);
        return item;
    }

    private addComboBoxItem(): void {
        const cbItem = this.generateItem();
        this._items.push(cbItem);
        this.pnlItems.addControl(cbItem);
    }

    private moveToUp(item: ComboBoxItem): void {
        if (item.id) {
            const index = this.findItemIndex(item.id);
            if (this._items.length > 1 && index > 0) {
                const temp = this._items[index - 1];
                this._items[index - 1] = item;
                this._items[index] = temp;
                this.updateItemsPanel();
            }
        }
    }

    private moveToDown(item: ComboBoxItem): void {
        if (item.id) {
            const index = this.findItemIndex(item.id);
            if (this._items.length > 1 && index < this._items.length - 1) {
                const temp = this._items[index + 1];
                this._items[index + 1] = item;
                this._items[index] = temp;
                this.updateItemsPanel();
            }
        }
    }

    private removeItem(item: ComboBoxItem): void {
        if (item.id) {
            const index = this.findItemIndex(item.id);
            this._items.splice(index, 1);
            this.updateItemsPanel();
        }
    }

    private updateItemsPanel(): void {
        this.pnlItems.clearControls();
        this.pnlItems.addControls(this._items);
    }

    private findItemIndex(id: Uuid): number {
        return this._items.findIndex(item => item.id === id);
    }
}
