import { EventHandler } from "@core/components/alt-ui";
import { Button, Panel } from "@core/components/alt-ui/controls";

type FooterAlignement = "start" | "end" | "center";

export class Footer extends Panel {
    public className = "Panel";

    public okText: string = "Сохранить";
    public cancelText: string = "Отменить";
    public alignement: FooterAlignement = "end";
    public reverse = false;

    public okHandler: EventHandler = () => {};
    public cancelHandler: EventHandler = () => {};

    private okBtn!: Button;
    private cancelBtn!: Button;

    private get panelAlignement(): string {
        const classMap = {
            center: "justify-center",
            start: "justify-start",
            end: "justify-end",
        };

        return classMap[this.alignement];
    }

    constructor({
        okText,
        cancelText,
        alignement,
        reverse,
        cancelHandler,
        okHandler,
    }: {
        okText?: string;
        cancelText?: string;
        alignement?: FooterAlignement;
        reverse?: boolean;
        okHandler: EventHandler;
        cancelHandler: EventHandler;
    }) {
        super();

        this.okText = okText ?? this.okText;
        this.cancelText = cancelText ?? this.cancelText;
        this.alignement = alignement ?? this.alignement;
        this.reverse = reverse ?? this.reverse;
        this.okHandler = okHandler;
        this.cancelHandler = cancelHandler;

        this.cancelBtn = new Button();
        this.okBtn = new Button();

        this.cancelBtn.id = "documents.cancel";
        this.cancelBtn.text = this.cancelText;
        this.cancelBtn.variant = "outline-danger";
        this.cancelBtn.addClickHandler((sender: any, e: any) => {
            this.cancelHandler(sender, e);
        });

        this.okBtn.id = "documents.save";
        this.okBtn.text = this.okText;
        this.okBtn.addClickHandler((sender: any, e: any) => {
            this.okHandler(sender, e);
        });

        this.class = `flex w-full space-x-2 ${this.panelAlignement}`;

        if (this.reverse) {
            this.okText && this.addControl(this.okBtn);
            this.cancelText && this.addControl(this.cancelBtn);
        } else {
            this.cancelText && this.addControl(this.cancelBtn);
            this.okText && this.addControl(this.okBtn);
        }
    }
}
