import { EventHandler } from "@core/components/alt-ui";
import { Control, Icon, TextBox } from "@core/components/alt-ui/controls";
import { Uuid } from "@/utils/uuid";
// Важно импортировать именно из этого файла, т.к иначе мы получаем циклическую зависимость
// В компоненте модельного окна в случае, если он импортирует класс Panel отсюда же
import { Panel } from "@core/components/alt-ui/controls/panel";
import { ISelectOption } from "@core/types/common/select-options";

export class ComboBoxItem extends Panel {
    private origId: string | undefined;

    private iconPoint!: Icon;
    private tbItem!: TextBox;
    private btnUp!: Icon;
    private btnDown!: Icon;
    private btnDelete!: Icon;

    public constructor(option?: ISelectOption) {
        super();
        this.origId = option?.id;
        this.initializeControls();
        this.id = option?.id ?? Uuid.new();
        this.text = option?.name ?? "";
    }

    public set text(text: string) {
        this.tbItem.text = text;
    }

    public set onMoveUp(handler: EventHandler) {
        this.btnUp.addClickHandler(handler);
    }

    public set onMoveDown(handler: EventHandler) {
        this.btnDown.addClickHandler(handler);
    }

    public set onDelete(handler: EventHandler) {
        this.btnDelete.addClickHandler(handler);
    }

    private initializeControls(): void {
        this.iconPoint = new Icon();
        this.iconPoint.id = "item.point";
        this.iconPoint.icon = "CheckIcon";
        this.iconPoint.class = "mr-0.75";
        this.iconPoint.style = "width: 2rem; height: 2rem;";

        this.tbItem = new TextBox();
        this.tbItem.id = "item";
        this.tbItem.class = "w-full";
        this.tbItem.text = "Новый элемент";

        this.btnUp = new Icon();
        this.btnUp.id = "item.up";
        this.btnUp.icon = "ArrowUpIcon";
        this.btnUp.class = "cursor-pointer hover:text-primary ml-0.75";
        this.btnUp.style = "width: 2rem; height: 2rem;";

        this.btnDown = new Icon();
        this.btnDown.id = "item.down";
        this.btnDown.icon = "ArrowDownIcon";
        this.btnDown.class = "cursor-pointer hover:text-primary ml-0.75";
        this.btnDown.style = "width: 2rem; height: 2rem;";

        this.btnDelete = new Icon();
        this.btnDelete.id = "item.delete";
        this.btnDelete.icon = "Trash2Icon";
        this.btnDelete.class = "cursor-pointer hover:text-danger ml-0.75";
        this.btnDelete.style = "width: 2rem; height: 2rem;";
    }

    public get controls(): Control[] {
        return [this.iconPoint, this.tbItem, this.btnUp, this.btnDown, this.btnDelete];
    }

    public toSelectOption(): ISelectOption {
        return {
            id: this.origId ?? this.tbItem.text.toLowerCase(),
            name: this.tbItem.text,
        };
    }
}
