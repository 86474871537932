import { Vue, Component, Prop } from "vue-property-decorator";
import { BCard, BCardBody, BCardHeader, BCardFooter } from "bootstrap-vue";
import { BCardTitle, BCardSubTitle, BCardText, BBadge } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { IUser } from "@lib";
import VaAutoTable from "@core/components/va-auto-table";
import ReportsViewChart from "./reports-view-chart.vue";
import ReportsViewTable from "./reports-view-table.vue";

@Component({
    name: "reports-view",
    components: {
        BCard,
        BCardBody,
        BCardHeader,
        BCardFooter,
        BCardTitle,
        BCardSubTitle,
        BCardText,
        BBadge,
        VueApexCharts,
        VaAutoTable,
        ReportsViewChart,
        ReportsViewTable,
    },
})
export default class ReportsView extends Vue {
    @Prop({ required: true })
    private report!: any;

    private user!: IUser;
    private userName: string = "";

    public async mounted(): Promise<void> {
        this.user = await this.$info.getUser();
        this.userName = this.user.info.officialName ?? "";
    }
}
