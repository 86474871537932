import { AltLoader } from "./alt-loader";
import { AltMessage } from "./alt-message";
import { AltToast } from "./alt-toast";
import { AltSystem } from "./alt-system";
import { ObjectUtils } from "../object.utils";
import { Formatter } from "../formatter";

export class AltPlugin {
    private _context: Vue | undefined;
    private readonly _system = new AltSystem();
    private readonly _loader = new AltLoader();
    private readonly _toast = new AltToast();
    private readonly _message = new AltMessage();

    /** Текущий контекст. */
    public set context(context: Vue) {
        this._context = context;
        this._toast.context = context;
        this._message.context = context;
    }

    /** Глобальные объекты системы. */
    public get system(): AltSystem {
        return this._system;
    }

    /** Крутилка загрузки. */
    public get loader(): AltLoader {
        return this._loader;
    }

    /** Всплывающее сообщение. */
    public get toast(): AltToast {
        return this._toast;
    }

    /** Сообщение. */
    public get message(): AltMessage {
        return this._message;
    }

    /** Форматирование данных. */
    public get formatter(): typeof Formatter {
        return Formatter;
    }

    /** Клонировать объект. */
    public clone<T = any>(value: T): T {
        return ObjectUtils.clone(value);
    }

    /** Сравнить массивы объектов по значениям. */
    public compareArrays<T = any>(array1: T[], array2: T[], getId: (el: T) => any): boolean {
        return ObjectUtils.compareArrays(array1, array2, getId);
    }

    /** Ожидание в миллисекундах. */
    public sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /** Проверка, включена ли скрытая функциональность. */
    public isFeatureEnabled(name: string): boolean {
        return !!localStorage.getItem(name);
    }

    /** Получить значение из локального хранилища. */
    public getFromLocalStorage(key: string): string | null {
        return localStorage.getItem(key);
    }

    /** Сохранить значение в локальное хранилище. Если значение `undefined` - удалить. */
    public setToLocalStorage(key: string, value?: string): void {
        if (!value) {
            this.removeFromLocalStorage(key);
            return;
        }

        localStorage.setItem(key, value);
    }

    /** Удалить значение из локального хранилища. */
    public removeFromLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }

    // /** Добавить параметр к запросу. */
    // public queryAdd(name: string, value: any): void {
    //     const query: any = JSON.parse(JSON.stringify(this._context?.$route.query));
    //     query[name] = value;
    //     this._context?.$router.replace({ query }).catch(() => {});
    // }

    // /** Удалить параметр из запроса. */
    // public queryRemove(name: string): void {
    //     if (this._context?.$route.query[name]) {
    //         const query: any = JSON.parse(JSON.stringify(this._context?.$route.query));
    //         delete query[name];
    //         this._context?.$router.replace({ query }).catch(() => {});
    //     }
    // }
}
