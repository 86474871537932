import {
    ITransactionUseCase,
    ITransaction,
    ISelectedData,
    ISelectQuery,
    ITransactionDeleteManyDto,
    ITransactionCreateDto,
} from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class TransactionUseCase implements ITransactionUseCase {
    public async select(companyId: string, query?: ISelectQuery): Promise<ISelectedData<ITransaction>> {
        try {
            const params = query;
            const response = await Api.get<ISelectedData<ITransaction>>(`/companies/${companyId}/transactions`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ITransaction> {
        try {
            const response = await Api.get<ITransaction>(`/companies/${companyId}/transactions/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ITransactionCreateDto): Promise<ITransaction> {
        try {
            const response = await Api.post<ITransaction>(`/companies/${companyId}/transactions`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/transactions/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async deleteMany(companyId: string, dto: ITransactionDeleteManyDto): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/transactions/actions/delete`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
