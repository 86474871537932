import { render, staticRenderFns } from "./order-view-stage.vue?vue&type=template&id=020772ea&scoped=true&"
import script from "./order-view-stage.ts?vue&type=script&lang=ts&"
export * from "./order-view-stage.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020772ea",
  null
  
)

export default component.exports