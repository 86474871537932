import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import { Grid } from ".";

@Component({
    name: "grid-component",
    components: {
        BRow,
        BCol,
        // https://forum.vuejs.org/t/unknown-custom-element-did-you-register-the-component-correctly/84027
        ControlComponent: () => import("./control.component"),
    },
})
export class GridComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Grid;

    public render(): VNode {
        if (this.handler.visible) {
            return <div>{this.renderRows()}</div>;
        } else {
            return <div></div>;
        }
    }

    private renderRows(): VNode[] {
        const rows: VNode[] = [];
        for (let rowIndex = 0; rowIndex < this.handler.rowCount; ++rowIndex) {
            rows.push(<b-row key={rowIndex}>{this.renderColumns(rowIndex)}</b-row>);
        }
        return rows;
    }

    private renderColumns(rowIndex: number): VNode[] {
        const columns: VNode[] = [];
        for (let colIndex = 0; colIndex < this.handler.columnCount; ++colIndex) {
            columns.push(<b-col key={colIndex}>{this.renderControl(rowIndex, colIndex)}</b-col>);
        }
        return columns;
    }

    private renderControl(rowIndex: number, colIndex: number): VNode {
        const control = this.handler.getControl(rowIndex, colIndex);
        if (control) {
            return <control-component handler={control} />;
        } else {
            return <div />;
        }
    }
}
