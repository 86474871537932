<template>
    <feather-icon icon="CircleIcon" :svgClasses="['fill-current h-1 w-1', textColor]" />
</template>

<script>
export default {
    name: "CellRendererVerified",
    computed: {
        textColor() {
            return this.params.value ? "text-primary" : "text-grey";
        },
    },
};
</script>
