import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { BModal, BvModalEvent } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import VueSelect from "vue-select";
import { ICompany, CompanyPaymentItem, ICompanyPaymentCreateDto } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { TextArea, TextBox } from "@core/components/alt-ui/controls";

@Component({
    name: "company-balance-add",
    components: { BModal, VueSelect, ValidationObserver, ControlComponent },
})
export default class CompanyBalanceAdd extends Vue {
    @PropSync("visible", { type: Boolean, default: false })
    private visibleSync!: boolean;

    @Prop({ type: Object })
    private company!: ICompany;

    private tbAmount = new TextBox();
    private taComment = new TextArea();

    @Watch("visible")
    private onVisibleChanged(): void {
        this.init();
    }

    private init(): void {
        this.tbAmount = new TextBox();
        this.tbAmount.id = "company-balance-add.amount";
        this.tbAmount.label = "Сумма";
        this.tbAmount.validation = "required|money|unsigned";
        this.tbAmount.text = "0";

        this.taComment = new TextArea();
        this.taComment.id = "company-balance-add.comment";
        this.taComment.label = "Комментарий";
    }

    private async save(bvModalEvent: BvModalEvent): Promise<void> {
        try {
            bvModalEvent.preventDefault();

            const valresult = await (this.$refs["balaceAddValidator"] as any).validate();
            if (!valresult) {
                return;
            }

            this.$alt.loader.show();

            const amountStr = this.tbAmount.text.replace(",", ".");
            const amount = parseFloat(amountStr);
            const comment = this.taComment.text.length > 0 ? this.taComment.text : undefined;

            const payment: ICompanyPaymentCreateDto = {
                item: CompanyPaymentItem.Promo,
                amount: amount,
                comment: comment,
                sender: "Alt SC", // TODO: to .env
            };

            await this.$alt.system.usecase.createAdminCompanyUseCase().payment(this.company.id, payment);

            this.$alt.toast.success("Баланс пополнен.");
            this.visibleSync = false;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
