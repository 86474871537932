import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Icon } from "./icon";

@Component({
    name: "icon-component",
})
export class IconComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Icon;

    public click(): void {
        this.handler.click();
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                // TODO: icon packages

                <feather-icon
                    id={this.handler.id}
                    icon={this.handler.icon}
                    class={this.handler.class}
                    style={this.handler.style}
                    //@click.stop="editControl(control)"
                    v-on:click={this.click}
                ></feather-icon>
            );
        } else {
            return <div />;
        }
    }
}
