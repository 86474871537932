import { DiscountType } from "@lib";
import { FieldControl } from "../field-control";
import { DiscountBox } from "@core/controls/discount-box";
import { Localizer } from "@/i18n/localizer";
import { Uuid } from "@/utils/uuid";
import * as filters from "@/filters";

export class DiscountBoxFieldControl extends FieldControl<DiscountBox> {
    protected convert(): DiscountBox {
        const control = new DiscountBox();
        control.id = `${this.field.id}_${Uuid.short()}`;
        control.label = this.field.title ?? "";
        if (this.context?.locale) {
            control.locale = this.context.locale;
        }
        return control;
    }

    public get value(): any {
        return this.control.value;
    }

    public set value(value: any) {
        if (!value || !value.value || !value.type) {
            return;
        }

        this.control.value = value;
    }

    public getValueFormatted(): string {
        return this.value.type === DiscountType.Percent
            ? `${this.formatNumber(this.value.value)}%`
            : this.formatMoney(this.value.value);
    }

    private formatNumber(value: string): string {
        if (!this.context?.locale) {
            return value;
        }

        const valueNum = parseFloat(value);
        return isNaN(valueNum) ? value : Localizer.number(valueNum, this.context.locale);
    }

    private formatMoney(value: string): string {
        if (!this.context?.locale) {
            return value;
        }

        const valueNum = parseFloat(value);
        return isNaN(valueNum) ? value : filters.money.moneyFormat(valueNum, { locale: this.context.locale });
    }
}
