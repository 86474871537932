import Vue, { VNode } from "vue";

export class AltMessage {
    private _context: Vue | undefined;

    public set context(context: Vue) {
        this._context = context;
    }

    public async show(text: string | VNode[], title?: string | VNode[], options?: any): Promise<void> {
        return await this._context?.$bvModal.msgBoxOk(text, {
            title: typeof title === "string" ? title.toUpperCase() : title,
            size: "sm",
            okVariant: options?.color ?? "primary",
            okTitle: options?.okText ?? "OK",
            hideHeaderClose: false,
            //centered: true,
            //noCloseOnBackdrop: true
        });
    }

    public async confirm(text: string | VNode[], title: string | VNode[], options?: any): Promise<boolean> {
        return await this._context?.$bvModal.msgBoxConfirm(text, {
            title: typeof title === "string" ? title.toUpperCase() : title,
            size: "sm",
            okVariant: options?.color ?? "warning",
            okTitle: options?.acceptText ?? options?.okText ?? "Подтвердить",
            cancelTitle: options?.cancelText ?? "Отмена",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
        });
    }
}
