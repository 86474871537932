import { IOrderTypeFieldGroup } from "@lib";
import { Control } from "@core/components/alt-ui/controls/control";
import { MacroFieldAssociation } from "../field-modal/field.modal";

export class OrderTypeGroups extends Control {
    private _items: IOrderTypeFieldGroup[] = [];

    public get items(): IOrderTypeFieldGroup[] {
        return this._items;
    }
    public set items(items: IOrderTypeFieldGroup[]) {
        this._items = items;
    }

    /** Уже задействованные макросы. */
    public macros: MacroFieldAssociation[] = [];

    // надо, чтобы задетектить сайдэффект
    public itemsId: string = "0";

    public getComponentName(): string {
        return "OrderTypeGroupsComponent";
    }
}
