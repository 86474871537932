import { Vue, Component } from "vue-property-decorator";
import { BTabs, BTab } from "bootstrap-vue";
import { Html } from "@core/components/alt-ui/controls";
import SettingsListsOrderStages from "./settings-lists-orderstages.vue";
import SettingsListsOrderTypes from "./settings-lists-ordertypes.vue";
import SettingsListsSaleStages from "./settings-lists-salestages.vue";
import SettingsListsProductTypes from "./settings-lists-producttypes.vue";
import SettingsListsTransactionItems from "./settings-lists-transactionitems.vue";
import SettingsListsClientSources from "./settings-lists-clientsources.vue";
import SettingsListsWorks from "./settings-lists-works.vue";
import SettingsListsCategories from "./settings-lists-categories.vue";
import SettingsListsSuppliers from "./settings-lists-suppliers.vue";

@Component({
    components: {
        BTabs,
        BTab,
        SettingsListsOrderTypes,
        SettingsListsOrderStages,
        SettingsListsSaleStages,
        SettingsListsProductTypes,
        SettingsListsTransactionItems,
        SettingsListsClientSources,
        SettingsListsWorks,
        SettingsListsCategories,
        SettingsListsSuppliers,
    },
})
export default class SettingsLists extends Vue {
    private activeTab: number = 0;

    private get tabs(): any[] {
        return [
            {
                id: "ordertypes",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.lists.ordertypes") : "",
                //iconPack: "feather",
                icon: "BookIcon",
                component: "settings-lists-order-types",
            },
            {
                id: "orderstages",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.lists.orderstages") : "",
                //iconPack: "feather",
                icon: "CheckCircleIcon",
                component: "settings-lists-order-stages",
            },
            {
                id: "producttypes",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.lists.producttypes") : "",
                //iconPack: "feather",
                icon: "SmartphoneIcon",
                component: "settings-lists-product-types",
            },
            {
                id: "salestages",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.lists.salestages") : "",
                //iconPack: "feather",
                icon: "CheckCircleIcon",
                component: "settings-lists-sale-stages",
            },
            {
                id: "categories",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.lists.categories") : "",
                //iconPack: "feather",
                icon: "BookmarkIcon",
                component: "settings-lists-categories",
            },
            {
                id: "transactionitems",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.lists.transactionitems") : "",
                //iconPack: "feather",
                icon: "CreditCardIcon",
                component: "settings-lists-transaction-items",
            },
            {
                id: "clientsources",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.lists.clientsources") : "",
                //iconPack: "feather",
                icon: "CompassIcon",
                component: "settings-lists-client-sources",
            },
            {
                id: "works",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.lists.works") : "",
                //iconPack: "feather",
                icon: "FileTextIcon",
                component: "settings-lists-works",
            },
            {
                id: "suppliers",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.lists.suppliers") : "",
                //iconPack: "feather",
                icon: "UsersIcon",
                component: "settings-lists-suppliers",
            },
        ];
    }

    public created(): void {
        if (this.$route.params.id) {
            const index = this.tabs.findIndex((t: any) => t.id === this.$route.params.id);
            if (index >= 0) {
                this.activeTab = index;
            }
        }
    }

    public mounted(): void {
        this.initHeader();
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "settings-lists.header-title";
        hdrTitle.html = `<h2 class="m-0">${this.$i18n.t("main.settings.lists.title")}</h2>`;

        this.$info.ui.setHeaderControls([hdrTitle]);
    }

    private get isLargeScreen(): boolean {
        return this.$info.ui.windowWidth >= 768;
    }

    private changeTab(tabIndex: number): void {
        this.activeTab = tabIndex;

        if (tabIndex === 0) {
            this.$router.push({ name: "settings-lists" }).catch(() => {});
            return;
        }

        const tab = this.tabs[tabIndex];
        if (tab) {
            this.$router.push({ name: "settings-lists", params: { id: tab.id } }).catch(() => {});
        }
    }
}
