import { Currency } from "@lib";
import { Gender } from "./gender";
import { AppException } from "@/exceptions";

export interface ICurrencyMorphemes {
    main: string[];
    fraction: string[];
    gender: Gender;
}

export function getCurrencyMorphemes(currency: string): ICurrencyMorphemes {
    switch (currency) {
        case Currency.RUB:
        case Currency.BYN:
            return {
                main: ["рубль", "рубля", "рублей"],
                fraction: ["копейка", "копейки", "копеек"],
                gender: Gender.Male,
            };
        case Currency.UAH:
            return {
                main: ["гривна", "гривны", "гривен"],
                fraction: ["копейка", "копейки", "копеек"],
                gender: Gender.Female,
            };
        case Currency.KZT:
            return {
                main: ["тенге", "тенге", "тенге"],
                fraction: ["тиын", "тиына", "тиынов"],
                gender: Gender.Male,
            };
        case Currency.GEL:
            return {
                main: ["лари", "лари", "лари"],
                fraction: ["тетри", "тетри", "тетри"],
                gender: Gender.Male,
            };
        case Currency.AMD:
            return {
                main: ["драм", "драма", "драмов"],
                fraction: ["лума", "лумы", "лум"],
                gender: Gender.Male,
            };
        case Currency.AZN:
            return {
                main: ["манат", "маната", "манатов"],
                fraction: ["гяпик", "гяпика", "гяпиков"],
                gender: Gender.Male,
            };
        case Currency.KGS:
            return {
                main: ["сом", "сомa", "сомов"],
                fraction: ["тыйын", "тыйына", "тыйынов"],
                gender: Gender.Male,
            };
        case Currency.TJS:
            return {
                main: ["сомони", "сомони", "сомони"],
                fraction: ["дирам", "дирама", "дирамов"],
                gender: Gender.Male,
            };
        case Currency.TMT:
            return {
                main: ["манат", "маната", "манатов"],
                fraction: ["тенге", "тенге", "тенге"],
                gender: Gender.Male,
            };
        case Currency.UZS:
            return {
                main: ["сум", "сума", "сумов"],
                fraction: ["тийин", "тийина", "тийинов"],
                gender: Gender.Male,
            };
        case Currency.MDL:
            return {
                main: ["лей", "лея", "леев"],
                fraction: ["бань", "баня", "баней"],
                gender: Gender.Male,
            };
        case Currency.USD:
            return {
                main: ["доллар", "доллара", "долларов"],
                fraction: ["цент", "цента", "центов"],
                gender: Gender.Male,
            };
        case Currency.EUR:
            return {
                main: ["евро", "евро", "евро"],
                fraction: ["цент", "цента", "центов"],
                gender: Gender.Male,
            };
    }

    throw new AppException("Неизвестная валюта.");
}
