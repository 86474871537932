export function initTableColumns(): any[] {
    return [
        {
            key: "actions",
            label: "",
            class: "column-width-min",
        },
        {
            key: "number",
            label: "#",
            class: "column-width-min",
        },
        {
            key: "name",
            label: "Наименование",
            class: "column-width-45",
        },
        {
            key: "account",
            label: "Счёт",
            class: "text-center",
        },
        {
            key: "quantity",
            label: "Количество",
            class: "text-center",
        },
        {
            key: "cost",
            label: "Расходы",
            class: "text-center",
        },
        {
            key: "price",
            label: "Выручка",
            class: "text-center",
        },
        {
            key: "discount",
            label: "Скидка",
            class: "text-center",
        },
        {
            key: "profit",
            label: "Прибыль",
            class: "text-center",
        },
        {
            key: "amount",
            label: "Начисления",
            class: "text-center",
        },
    ];
}
