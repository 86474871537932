
































































import { Component, Prop, Vue } from "vue-property-decorator";
import { BTableSimple, BThead, BTbody, BTfoot, BTh, BTr, BTd } from "bootstrap-vue";
import { Table, TableColumn } from "./table";

@Component({
    name: "table-component",
    components: {
        BTableSimple,
        BThead,
        BTfoot,
        BTbody,
        BTh,
        BTr,
        BTd,
        ControlComponent: () => import("../controls/control.component"),
    },
})
export default class TableComponent extends Vue {
    @Prop({ type: Object })
    private handler!: Table;

    public get items() {
        return this.handler.items;
    }

    public get columns() {
        return this.handler.columns;
    }

    public get actions() {
        return this.handler.actions;
    }

    public get footer() {
        return this.handler.footer;
    }

    public getStyles(column: TableColumn) {
        const styles: Record<string, string> = {};

        if (column.width) {
            styles.width = `${column.width}%`;
        }

        return styles;
    }

    public get columnsCount(): number {
        return this.columns.length + this.actions.length;
    }
}
