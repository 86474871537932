/** Статья платежа. */
export enum CompanyPaymentItem {
    /** Внесение. */
    Deposit = "deposit",

    /** Снятие. */
    Withdrawal = "withdrawal",

    /** Акция. */
    Promo = "promo",
    
    /** Прочее. */
    Other = "other",
}
