import { Control } from "./control";
import { EventHandler } from "..";

export class TagsChangedEventArgs {
    tags!: string[];
}

export class Tags extends Control {
    public label = "";
    public placeholder = "";
    public help = "";

    public getComponentName(): string {
        return "TagsComponent";
    }

    private _tags: string[] = [];
    public get tags(): string[] {
        return this._tags;
    }
    public set tags(tags: string[]) {
        if (tags === this._tags) {
            return;
        }

        this._tags = tags;
        this.notifyTagsChangedHandler();
    }

    public addTag(tag: string): void {
        this._tags.push(tag);
        this.notifyTagsChangedHandler();
    }

    private _tagsChangedHandlers = new Set<EventHandler<TagsChangedEventArgs>>();
    public addTagsChangedHandler(handler: EventHandler<TagsChangedEventArgs>): void {
        this._tagsChangedHandlers.add(handler);
    }
    public removeTagsChangedHandler(handler: EventHandler<TagsChangedEventArgs>): void {
        this._tagsChangedHandlers.delete(handler);
    }
    private notifyTagsChangedHandler(): void {
        const args: TagsChangedEventArgs = { tags: this._tags };
        for (const handler of this._tagsChangedHandlers) {
            handler(this, args);
        }
    }
}
