import _Vue from "vue";
import { AltPlugin, SecurePlugin, InfoPlugin, SettingsPlugin } from ".";

// https://www.mistergoodcat.com/post/vuejs-plugins-with-typescript
export function AltPluginSetInstaller(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$alt = new AltPlugin();
    Vue.prototype.$secure = new SecurePlugin();
    Vue.prototype.$info = new InfoPlugin();
    Vue.prototype.$settings = new SettingsPlugin();
}

declare module "vue/types/vue" {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Vue {
        /** Дополнительные удобные функции. */
        $alt: AltPlugin;

        /** Права доступа. */
        $secure: SecurePlugin;

        /** Хранилище данных. */
        $info: InfoPlugin;

        /** Настройки интерфейса. */
        $settings: SettingsPlugin;
    }
}
