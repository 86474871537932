import { Currency, IStore } from "@lib";
import { IGoodMacroOpenContext, IMacro } from "../macro";
import { MacroAutocomplete } from "@core/controls/document-template-editor/document-template-macros-autocompleter";
import { Warranty } from "@core/types/common/warranty";
import { ApiBaseUrl } from "@core/usecases/axios";
import { Localizer } from "@/i18n/localizer";
import * as filters from "@/filters";
import { companyMacroAutocomplete, CompanyMacroList } from "./company-macro-list";

export const goodMacroAutocomplete: MacroAutocomplete[] = [
    {
        type: "autocompleteitem",
        text: "Дата",
        value: "дата",
        nested: [{ type: "autocompleteitem", text: "Сегодня", value: "сегодня" }],
    },
    {
        type: "autocompleteitem",
        text: "Товар",
        value: "товар",
        nested: [
            { type: "autocompleteitem", text: "Артикул", value: "артикул" },
            { type: "autocompleteitem", text: "Наименование", value: "наименование" },
            { type: "autocompleteitem", text: "Описание", value: "описание" },
            { type: "autocompleteitem", text: "Себестоимость", value: "себестоимость" },
            { type: "autocompleteitem", text: "Цена", value: "цена" },
            { type: "autocompleteitem", text: "Количество", value: "количество" },
            { type: "autocompleteitem", text: "Гарантия", value: "гарантия" },
            { type: "autocompleteitem", text: "Штрихкод", value: "штрихкод" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Склад",
        value: "склад",
        nested: [
            { type: "autocompleteitem", text: "Номер", value: "номер" },
            { type: "autocompleteitem", text: "Название", value: "название" },
            { type: "autocompleteitem", text: "Описание", value: "описание" },
            { type: "autocompleteitem", text: "Валюта", value: "валюта" },
        ],
    },

    ...companyMacroAutocomplete,
];

export const GoodMacroList: IMacro<IGoodMacroOpenContext>[] = [
    // общее

    {
        alias: ["дата.сегодня", "date.now"],
        open: (context): string | undefined => filters.datetime.datetime(new Date().toUTCString(), "L"),
    },
    ...CompanyMacroList,

    // товар

    {
        alias: ["товар.артикул", "good.sku"],
        open: ({ good }): string | undefined => good.info.sku,
    },
    {
        alias: ["товар.наименование", "good.name"],
        open: ({ good }): string | undefined => good.info.name,
    },
    {
        alias: ["товар.описание", "good.description"],
        open: ({ good }): string | undefined => good.info.description,
    },
    {
        alias: ["товар.себестоимость", "good.cost"],
        open: ({ good }): string | undefined => {
            const locale = good?.storeRef?.info?.locale;
            return filters.money.moneyFormat(good.info.cost, { locale });
        },
    },
    {
        alias: ["товар.цена", "good.price"],
        open: ({ good }): string | undefined => {
            const locale = good?.storeRef?.info?.locale;
            return filters.money.moneyFormat(good.info.price, { locale });
        },
    },
    {
        alias: ["товар.количество", "good.quantity"],
        open: ({ good }): string | undefined => good.info.quantity.toString(),
    },
    {
        alias: ["товар.гарантия", "good.warranty"],
        open: ({ good }): string | undefined => Warranty.toString(good.info.warranty),
    },
    {
        alias: ["товар.штрихкод", "good.barcode"],
        open: ({ good }, args?: string[]): string | undefined => {
            if (!good.info.barcodes || good.info.barcodes.length === 0) {
                return "";
            }

            // %(товар.штрихкод:120,15)
            const width = args && args.length >= 1 ? args[0] : "120";
            const height = args && args.length >= 2 ? args[1] : "15";
            const barcode = good.info.barcodes[0];
            const url = `${ApiBaseUrl}barcodes/?code=${barcode.code}&type=${barcode.type}&height=${height}`;

            return `<img
                src="${url}"
                width="${width}"
                alt="Штрихкод ${barcode.code}"
                class="w-2 h-4 mr-0.5"
            />`;
        },
    },

    // склад

    {
        alias: ["склад.номер", "store.number"],
        open: ({ store }): string | undefined => store?.number?.toString(),
    },
    {
        alias: ["склад.название", "store.name"],
        open: ({ store }): string | undefined => store?.info.name,
    },
    {
        alias: ["склад.описание", "store.description"],
        open: ({ store }): string | undefined => store?.info.description,
    },
    {
        alias: ["склад.валюта", "store.currency"],
        open: ({ store }): string | undefined => Localizer.currencySymbol(store?.info.currency ?? Currency.RUB),
    },
];
