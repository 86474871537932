








import { Vue, Component } from "vue-property-decorator";
import { VBTooltip } from "bootstrap-vue";
import VaIcon from "@core/components/va-icon";

@Component({
    name: "CellRendererTags",
})
export default class CellRendererTags extends Vue {
    private params: any;

    private get tags(): any {
        return this.params.tags;
    }

    private get handler(): any {
        return this.params.handler;
    }
}
