import { TextFieldReceiver } from ".";
import { EventHandler } from "..";
import { Control } from "./control";

export class MultiSelectChangedEventArgs<T = any> {
    items!: T[];
}

export class MultiSelect<T = any> extends Control {
    public items: T[] = [];
    public label = "";
    public placeholder = "";
    public help = "";

    public textField: TextFieldReceiver<T> = (item: T) => item;
    public descriptionField: TextFieldReceiver<T> | null = null;

    public getComponentName(): string {
        return "MultiSelectComponent";
    }

    private _selectedItems: T[] = [];
    public get selectedItems(): T[] {
        return this._selectedItems;
    }
    public set selectedItems(items: T[]) {
        if (items === this._selectedItems) {
            return;
        }

        this._selectedItems = items;
        this.notifyChangedHandler();
    }

    private _changedHandlers = new Set<EventHandler<MultiSelectChangedEventArgs<T>>>();
    public addChangedHandler(handler: EventHandler<MultiSelectChangedEventArgs<T>>): void {
        this._changedHandlers.add(handler);
    }
    public removeChangedHandler(handler: EventHandler<MultiSelectChangedEventArgs<T>>): void {
        this._changedHandlers.delete(handler);
    }
    private notifyChangedHandler(): void {
        const args: MultiSelectChangedEventArgs<T> = {
            items: this._selectedItems,
        };
        for (const handler of this._changedHandlers) {
            handler(this, args);
        }
    }
}
