import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BTooltip } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider } from "vee-validate";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import { Validator } from "vee-validate";
import { FieldFormat } from "@lib";
import { AutoSuggest } from "./auto-suggest";
import validators from "@/views/main/settings/settings-company/modals/validators";

const PhoneRuleName = "phone-number";
Validator.extend(PhoneRuleName, validators.validatePhoneNumber);

@Component({
    name: "auto-suggest-component",
    components: { VueAutosuggest, ValidationProvider, BTooltip },
})
export class AutoSuggestComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: AutoSuggest;

    private get text(): string {
        if (this.handler.format === FieldFormat.Phone) {
            return this.phoneFormat(this.handler.text);
        }

        return this.handler.text;
    }

    private set text(text: string) {
        if (this.handler.format === FieldFormat.Phone) {
            this.handler.text = text.replace(/\s/g, "");
            return;
        }

        this.handler.text = text;
    }

    // TODO: вынести в utils???
    private phoneFormat(value: string): string {
        const formatter = new AsYouType({
            defaultCountry: "RU",
        });

        const defaultPrefix = "+";

        const possibleNeedPrefix = value.length === 1;
        const shouldAddPrefix = possibleNeedPrefix && !value.startsWith(defaultPrefix) && !value.startsWith("8");

        if (shouldAddPrefix) {
            return formatter.input(defaultPrefix + value);
        }

        const phoneNumber = parsePhoneNumber(value, {
            defaultCountry: "RU",
            extract: false,
        });

        if (!phoneNumber) {
            return value;
        }

        return formatter.input(value);
    }

    public get validation(): string | null {
        if (this.handler.format === FieldFormat.Phone) {
            return this.handler.validation ? `${this.handler.validation}|${PhoneRuleName}` : PhoneRuleName;
        }

        return this.handler.validation;
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderParts(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderParts(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        items.push(
            <vue-autosuggest
                id={this.handler.id}
                v-model={this.text}
                suggestions={[{ data: this.handler.items }]}
                input-props={{
                    id: this.handler.id,
                    class: {
                        "form-control": !errors.length,
                        "form-control is-invalid": errors.length,
                    },
                    name: this.handler.name,
                }}
                section-configs={{ default: { limit: this.handler.limit } }}
                get-suggestion-value={this.getSuggestionValue}
                render-suggestion={this.renderOption}
                class={this.handler.class}
                style={this.handler.style}
                disabled={this.handler.disabled}
                v-on:input={this.onInput}
                v-on:paste={this.onPaste}
                v-on:selected={this.onSelected}
            />,
        );

        if (errors[0]) {
            items.push(<small class="text-xs ml-1 text-danger">{errors[0]}</small>);
        }

        return items;
    }

    private renderOption(option: any): VNode {
        return <span>{this.getSuggestionValue(option)}</span>;
    }

    private getSuggestionValue(suggestion: { item: any }): string {
        return this.handler.textField(suggestion.item);
    }

    private onSelected(suggestion: { item: any }): void {
        this.handler.selectedItem = suggestion.item;
    }

    private onInput(): void {
        this.handler.search(this.handler.text);
    }

    private onPaste(event: any): void {
        const text = event.clipboardData?.getData("text");

        if (!text) {
            return;
        }

        event.preventDefault();

        this.text = "";
        this.text = text.trim();
    }
}
