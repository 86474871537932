import { ISale, Currency, Locale, SaleStageType, ISaleStage } from "@lib";
import { Printer } from "@/@core/usecases/template/printer";
import { Localizer } from "@/i18n/localizer";
import * as filters from "@/filters";
import { SalePrintContext } from "@/@core/usecases/template/macro-replacers/sale-document.macro-replacer";

export function getDefaultTableColumns(context: any, showChangeStageModalFunc: Function): any[] {
    const stages = context.stages as ISaleStage[];

    return [
        {
            colId: "actions",
            headerName: "Просмотр/удаление",
            width: 90,
            hide: false,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: (sale: ISale) => context.can.update(sale),
                            click: (sale: ISale) => context.openFormUpdate(sale),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: (sale: ISale) => context.can.delete(sale),
                            click: (sale: ISale) => context.confirmDelete(sale),
                        },
                    ],
                };
            },
        },
        // {
        //     colId: "checkboxes",
        //     headerName: "Флажок",
        //     width: 50,
        //     hide: false,
        //     filter: false,
        //     checkboxSelection: true,
        //     headerCheckboxSelectionFilteredOnly: true,
        //     headerCheckboxSelection: true
        // },
        {
            colId: "number",
            field: "number",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,

            cellRenderer: function (params: any) {
                const value = params.value as string;
                const sale = params.data as ISale;

                if (!value || !sale.shopRef) {
                    return "";
                }

                const printContext: SalePrintContext = {
                    company: context.company,
                    shop: sale.shopRef,
                    sale: sale,
                };

                return Printer.replaceSaleDocumentsGeneralMacros("%(Продажа.Номер)", printContext);
            },
        },
        {
            colId: "shop",
            field: "shop",
            headerName: "Магазин",
            sortable: false,
            filter: true,
            width: 160,
            hide: true,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                return {
                    name: params.data.shopRef?.info.name,
                };
            },
        },
        {
            colId: "created",
            field: "createdAt",
            headerName: "Дата продажи",
            sortable: true,
            filter: true,
            width: 160,
            hide: false,

            cellRenderer: function (params: any) {
                return filters.datetime.datetime(params.value, "L LT");
            },
        },
        {
            colId: "goods",
            field: "goods",
            headerName: "Наименования",
            sortable: false,
            filter: true,
            width: 180,
            hide: false,
            autoHeight: true,

            cellRenderer: function (params: any) {
                const goods = params.value;

                let str = "";
                for (const good of goods) {
                    str += `<p class="text-sm m-0">${good.name} (${good.quantity} шт.)</p>`;
                }
                return `<div class="py-0.5">${str}</div>`;
            },
        },
        {
            colId: "comment",
            field: "info.comment",
            headerName: "Комментарий",
            sortable: false,
            filter: true,
            width: 300,
            hide: true,
            autoHeight: true,

            cellRenderer: function (params: any) {
                if (params.value && params.value.length > 0) {
                    return `<div class="text-sm whitespace-pre-wrap m-0 py-0.5" style="line-height: 1.5rem;">${params.value}</div>`;
                } else {
                    return "";
                }
            },
        },
        {
            colId: "paid",
            field: "paid",
            headerName: "Оплачено",
            sortable: false,
            filter: true,
            width: 180,
            hide: false,
            autoHeight: true,

            cellRenderer: function (params: any) {
                const sale = params.data as ISale;

                if (!sale.payments) {
                    return "";
                }

                let sum = 0;
                for (const payment of sale.payments) {
                    sum += payment.value;
                }

                const currency = sale.shopRef?.info.currency ?? Currency.RUB;
                const locale = sale.shopRef?.info.locale ?? Locale.RU;
                return Localizer.currency(sum, currency, locale);
            },
        },
        {
            colId: "stage",
            field: "stage",
            headerName: "Статус",
            sortable: false,
            filter: true,
            width: 160,
            hide: false,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                const stage = stages.find(stage => stage.id === params.value);
                return {
                    color: stage ? stage.color : "dark",
                    name: stage ? stage.name : "-",
                    click: () => showChangeStageModalFunc(params.data),
                };
            },
        },
        // {
        //     colId: "client",
        //     field: "client",
        //     headerName: "Клиент",
        //     sortable: true,
        //     filter: true,
        //     width: 180,
        //     hide: false,
        //     cellClass: "va-grid-cell-multiline",
        //     autoHeight: true,

        //     cellRendererFramework: "CellRendererAvatar",
        //     cellRendererParams: (params: any) => {
        //         const client = params.value;
        //         let name = "";
        //         let description = "";
        //         if (client) {
        //             if (client.info.name) {
        //                 name = client.info.name;
        //             }
        //             // if (client.info.description) {
        //             //     description += "\n" + client.info.description;
        //             // }
        //             // if (client.info.type) {
        //             //     description += "\n" + (client.info.type === "company" ? "Юридическое лицо" : "Физическое лицо");
        //             // }
        //             // if (client.info.taxId) {
        //             //     description += "\nИНН: " + client.info.taxId;
        //             // }
        //             if (client.info.contacts && client.info.contacts.email) {
        //                 description += "\n" + client.info.contacts.email;
        //             }
        //             if (client.info.contacts && client.info.contacts.phone) {
        //                 name += "\n" + client.info.contacts.phone;
        //                 description += "\n" + client.info.contacts.phone;
        //             }
        //         }

        //         return {
        //             tooltip: client ? client.info.name : "Клиент",
        //             name: name,
        //             click: () => { $router.push({ name: "clients", params: { id: client.id } }).catch(() => {}); }
        //         };
        //     }
        // },
        {
            colId: "seller",
            field: "seller",
            headerName: "Продавец",
            sortable: false,
            filter: true,
            width: 220,
            hide: false,

            cellRendererFramework: "CellRendererAvatar",
            cellRendererParams: (params: any) => {
                const seller = params.data.sellerRef;
                return {
                    showAvatar: true,
                    avatar: seller?.info.avatar ?? "",
                    name: seller?.info.name ?? "",
                };
            },
        },
    ];
}

//

export function getDefaultTableActions(context: any): any[] {
    return [
        {
            name: "Удалить всё",
            icon: "Trash2Icon",
            click: (sales: ISale[]) => context.confirmDeleteMany(sales),
        },
    ];
}
