import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import {
    IOrder,
    IOrderType,
    IOrderTypeField,
    IOrderTypeFieldGroup,
    IOffice,
    OrderTypeFieldGroupType,
    IOrderInfoChangeDto,
} from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import VaIcon from "@core/components/va-icon/va-icon.vue";
import { FieldControl } from "@core/types/field-controls/field-control";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { DiscountBoxFieldControl } from "@core/types/field-controls/custom/discount-box.field-control";
import { AppException } from "@/exceptions";

@Component({
    components: {
        BButton,
        ValidationObserver,
        ControlComponent,
        VaIcon,
    },
})
export default class OrderViewInfo extends Vue {
    @Prop({ required: true })
    private order!: IOrder;

    @Prop({ required: true })
    private orderType!: IOrderType;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private visible = true;
    private editMode = false;
    private fieldGroup: IOrderTypeFieldGroup | null = null;
    private controls: FieldControl[] = [];

    private get title(): string {
        return this.fieldGroup?.title ?? "Информация";
    }

    public get icon(): string {
        return this.fieldGroup?.icon ?? "info";
    }

    @Watch("order", { immediate: true, deep: true })
    private onOrderChanged(): void {
        this.initValues();
    }

    @Watch("orderType", { immediate: true, deep: true })
    private onOrderTypeChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        if (!this.order || !this.orderType) {
            return;
        }

        this.editMode = false;
        this.fieldGroup = this.orderType.groups.find(field => field.id === OrderTypeFieldGroupType.Info) ?? null;
        this.visible = this.getFields().length > 0;

        this.initControls();
    }

    private initControls(): void {
        this.controls = [];

        const context = {
            locale: this.order.officeRef?.info?.locale,
        };

        for (const field of this.getFields()) {
            const fieldControl =
                field.id === "discount"
                    ? new DiscountBoxFieldControl(field, context)
                    : FieldControlFactory.fromField(field, context);

            fieldControl.value = this.getFieldValue(this.order, field);
            this.controls.push(fieldControl);
        }
    }

    public getFields(): IOrderTypeField[] {
        return (
            this.fieldGroup?.fields
                .filter(f => !f.hidden)
                .sort((a, b) =>
                    a.sequence !== undefined && b.sequence !== undefined ? a.sequence - b.sequence : -1,
                ) ?? []
        );
    }

    public getFieldValue(order: IOrder, field: IOrderTypeField): any {
        if (field.custom) {
            // TODO: remove details1
            const id = field.customId ?? field.details1 ?? field.id;
            return order.custom ? order.custom[id] : "";
        }

        return this.getStandartFieldValue(order, field);
    }

    private getStandartFieldValue(order: IOrder, field: IOrderTypeField): any {
        switch (field.id) {
            case "name":
                return order.info?.name;
            case "description":
                return order.info?.description;
            case "createDate":
                return order.info?.createDate;
            case "deadline":
                return order.info?.deadline;
            case "discount":
                return order.info?.discount;
        }

        return "";
    }

    private createDto(): { info: IOrderInfoChangeDto; custom: any } {
        const order = this.$alt.clone(this.order);

        for (const fieldControl of this.controls) {
            const value = fieldControl.value;
            this.populateCustomFieldValue(order, fieldControl.field, value);
            this.populateStandartFieldValue(order, fieldControl.field, value);
        }

        return {
            info: order.info,
            custom: order.custom,
        };
    }

    private populateCustomFieldValue(order: IOrder, field: IOrderTypeField, value: any): void {
        if (field.custom) {
            if (!order.custom) {
                order.custom = {};
            }

            // TODO: remove details1
            const id = field.customId ?? field.details1 ?? field.id;
            order.custom[id] = value;
            return;
        }
    }

    private populateStandartFieldValue(order: IOrder, field: IOrderTypeField, value: any): void {
        if (!order.info) {
            order.info = {} as any;
        }

        switch (field.id) {
            case "name":
                order.info.name = value;
                return;
            case "description":
                order.info.description = value;
                return;
            case "createDate":
                order.info.createDate = value;
                return;
            case "deadline":
                order.info.deadline = value;
                return;
            case "discount":
                order.info.discount = value;
                return;
            case "custom":
                // TODO: rename id
                if (order.custom && field.details1) {
                    order.custom[field.details1] = value;
                }
                return;
        }
    }

    private startEditInfo(): void {
        this.initControls();
        this.editMode = true;
    }

    private async applyEditInfo(): Promise<void> {
        try {
            const result = await (this.$refs["orderInfoValidator"] as any).validate();
            if (!result) {
                return;
            }

            if (!this.order) {
                throw new AppException("Заявка не задана.");
            }

            const dto = this.createDto();

            const usecase = this.$alt.system.usecase.createOrderUseCase();
            const order = await usecase.changeInfo(this.order.company as string, this.order.id, dto.info, dto.custom);

            this.editMode = false;
            this.$emit("order-changed", order);
        } catch {
            this.$alt.toast.error("Не удалось изменить данные заявки.");
        }
    }

    private cancelEditInfo(): void {
        this.initValues();
    }
}
