import { Vue, Component } from "vue-property-decorator";
import { BDropdown, BDropdownItem, VBTooltip } from "bootstrap-vue";
import { PermissionCommonSection, PermissionRight } from "@lib";
import VaAutoButton from "@core/components/va-auto-button";

@Component({
    name: "reports-toolbar",
    components: { BDropdown, BDropdownItem, VaAutoButton },
    directives: { "b-tooltip": VBTooltip },
})
export default class ReportsToolbar extends Vue {
    private get can(): any {
        const secure = this.$secure;
        return {
            get read(): boolean {
                return secure.checkCommon(PermissionCommonSection.Reports, PermissionRight.Read);
            },
        };
    }

    private showModal(): void {
        this.$emit("show-modal");
    }

    private printReport(): void {
        window.print();
    }
}
