import { IDocument, DocumentType, IDocumentCreateFromTemplateDto, IDocumentUpdateDto } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, Select, TextBox } from "@core/components/alt-ui/controls";
import { getDocumentTypes, ISelectOption } from "@core/types/common/select-options";
import { getOfficeGeneratTemplates, getShopGeneratTemplates } from "./settings-templates-documents-defaults";

export class SettingsTemplatesDocumentsModal extends Modal<IDocument> {
    private cbType!: Select<ISelectOption>;
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private cbTemplate!: Select<ISelectOption>;
    private btnEdit!: Button;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private document: IDocument | null = null;
    public onCreate: ((dto: IDocumentCreateFromTemplateDto) => Promise<IDocument | null>) | null = null;
    public onUpdate: ((document: IDocument, dto: IDocumentUpdateDto) => Promise<IDocument | null>) | null = null;
    public onEditClick: ((document: IDocument) => Promise<void>) | null = null;

    public constructor() {
        super("documents-modal", "");
        this.initializeControls();
    }

    public show(document?: IDocument): Promise<void> {
        this.document = document ?? null;
        this.title = document ? "Изменение документа" : "Новый документ";
        this.initializeControls();

        if (document) {
            this.populateControls(document);
        }

        return super.show();
    }

    private initializeControls(): void {
        this.cbType = new Select<ISelectOption>();
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.cbTemplate = new Select<ISelectOption>();
        this.btnEdit = new Button();
        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbName.id = "documents.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";
        this.tbName.text = "";

        this.tbDescription.id = "documents.description";
        this.tbDescription.label = "Описание";
        this.tbDescription.text = "";

        this.cbTemplate.id = "documents.template";
        this.cbTemplate.label = "Готовый шаблон";
        this.cbTemplate.items = getOfficeGeneratTemplates();
        this.cbTemplate.textField = (opt: ISelectOption) => opt.name;
        this.cbTemplate.selectedIndex = 0;
        this.cbTemplate.visible = true;

        // инициализация после зависимых контролов
        this.cbType.id = "documents.type";
        this.cbType.label = "Тип";
        this.cbType.items = getDocumentTypes();
        this.cbType.textField = (opt: ISelectOption) => opt.name;
        this.cbType.addChangedHandler(this.changeType.bind(this));
        this.cbType.selectedIndex = 0;
        this.cbType.visible = true;

        this.btnEdit.id = "documents.edit";
        this.btnEdit.text = "Редактировать шаблон";
        this.btnEdit.class = "w-full mt-0.75";
        this.btnEdit.variant = "outline-primary";
        this.btnEdit.visible = false;
        this.btnEdit.addClickHandler(this.clickEdit.bind(this));

        //

        this.btnCancel.id = "documents.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "documents.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(document: IDocument): void {
        this.cbType.visible = false;
        this.tbName.text = document.name;
        this.tbDescription.text = document.description ?? "";
        this.cbTemplate.visible = false;
        this.btnEdit.visible = true;
    }

    public get controls(): Control[] {
        return [this.cbType, this.tbName, this.tbDescription, this.cbTemplate, this.btnEdit, this.pnlFooter];
    }

    private changeType(sender: any, e: any): void {
        switch (e.item.id) {
            case DocumentType.OfficeGeneral:
                this.cbTemplate.items = getOfficeGeneratTemplates();
                this.cbTemplate.selectedIndex = 0;
                break;
            case DocumentType.ShopDocument:
                this.cbTemplate.items = getShopGeneratTemplates();
                this.cbTemplate.selectedIndex = 0;
                break;
        }
    }

    private async clickEdit(sender: any, e: any): Promise<void> {
        if (this.onEditClick && this.document) {
            this.hide();
            await this.onEditClick(this.document);
        }
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.document ? await this.updateDocument(this.document) : await this.createDocument();

        if (result) {
            this.hide(result);
        }
    }

    private async createDocument(): Promise<IDocument | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: IDocumentCreateFromTemplateDto = {
            type: (this.cbType.selectedItem?.id as DocumentType) ?? DocumentType.OfficeGeneral,
            name: this.tbName.text,
            description: this.tbDescription.text,
            templateId: this.cbTemplate.selectedItem?.id ?? "blank",
            //offices: [],
            //tags: [],
        };

        return await this.onCreate(dto);
    }

    private async updateDocument(document: IDocument): Promise<IDocument | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IDocumentUpdateDto = {
            name: this.tbName.text,
            description: this.tbDescription.text,
            //template: undefined,
            //offices: [],
            //tags: [],
        };

        return await this.onUpdate(document, dto);
    }
}
