import {
    FieldFormat,
    IClient,
    IClientCreateDto,
    IClientSource,
    IOffice,
    IOrderType,
    IOrderTypeField,
    IOrderTypeFieldGroup,
    OrderTypeFieldGroupType,
} from "@lib";
import { FieldControl } from "@core/types/field-controls/field-control";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { AutoSuggestFieldControl } from "@core/types/field-controls/custom/auto-suggest.field-control";
import { AutoSuggest } from "@core/components/alt-ui/controls";
import { Formatter } from "@/utils/formatter";
import { DelayedOperation } from "@/utils/delayed-operation";

export class OrderBlockClientHandler {
    public controls: FieldControl[] = [];
    public clientExisted: IClient | null = null;

    private office: IOffice | null = null;
    private orderType: IOrderType | null = null;
    private clientSources: IClientSource[] = [];
    private fieldGroup: IOrderTypeFieldGroup | null = null;

    public searchClients: ((search: string) => Promise<IClient[]>) | null = null;

    public get title(): string {
        return this.fieldGroup?.title ?? "Клиент";
    }

    public get icon(): string {
        return this.fieldGroup?.icon ?? "user";
    }

    public init(office: IOffice | null, orderType: IOrderType | null, clientSources: IClientSource[]): void {
        this.office = office;
        this.orderType = orderType;
        this.clientSources = clientSources;

        if (!this.office || !this.orderType) {
            return;
        }

        this.fieldGroup = this.orderType.groups.find(field => field.id === OrderTypeFieldGroupType.Client) ?? null;

        this.initControls();
    }

    private initControls(): void {
        this.clientExisted = null;
        this.controls = [];

        const context = {
            locale: this.office?.info?.locale,
            clientSources: this.clientSources,
        };

        for (const field of this.getFields()) {
            if (field.id === "client.name") {
                const fieldControl = new AutoSuggestFieldControl<IClient>(field, context);
                fieldControl.control.textField = client => this.formatClientName(client);
                fieldControl.control.search = (search: string) => this.startSearchClients(fieldControl.control, search);
                fieldControl.control.addChangedHandler((s, e) => this.setClient(e.item));
                this.controls.push(fieldControl);
                continue;
            } else if (field.id === "client.phone") {
                const fieldControl = new AutoSuggestFieldControl<IClient>(field, context);
                fieldControl.field.format = FieldFormat.Phone;
                fieldControl.control.textField = client => this.formatClientName(client);
                fieldControl.control.search = (search: string) => this.startSearchClients(fieldControl.control, search);
                fieldControl.control.addChangedHandler((s, e) => this.setClient(e.item));
                this.controls.push(fieldControl);
                continue;
            }

            const fieldControl = FieldControlFactory.fromField(field, context);
            this.controls.push(fieldControl);
        }
    }

    private getFields(): IOrderTypeField[] {
        return (
            this.fieldGroup?.fields
                .filter(f => !f.hidden)
                .sort((a, b) =>
                    a.sequence !== undefined && b.sequence !== undefined ? a.sequence - b.sequence : -1,
                ) ?? []
        );
    }

    private formatClientName(client: IClient): string {
        return client.info?.contacts?.phone
            ? `${client.info.name} (${Formatter.phone(client.info.contacts.phone)})`
            : client.info.name;
    }

    public setClient(client: IClient | null): void {
        this.clientExisted = client;

        if (!client) {
            this.initControls();
        } else {
            this.setControlValues(client);
        }
    }

    private async startSearchClients(handler: AutoSuggest<IClient>, search: string): Promise<void> {
        if (!this.searchClients) {
            return;
        }

        const searchClients = this.searchClients;

        DelayedOperation.invoke("search-clients", 600, async () => {
            search = search.trim();

            // начинать поиск от 2 символов
            if (search.length < 2) {
                handler.items = [];
                return;
            }

            handler.items = await searchClients(search);
        });
    }

    public getExistedClient(): IClient | null {
        return this.clientExisted;
    }

    public getDto(): IClientCreateDto | undefined {
        if (this.clientExisted) {
            return undefined;
        }

        const dto = {} as IClientCreateDto;

        for (const fieldControl of this.controls) {
            const value = fieldControl.value;
            this.populateCustomFieldValue(dto, fieldControl.field, value);
            this.populateStandartFieldValue(dto, fieldControl.field, value);
        }

        return dto;
    }

    private populateCustomFieldValue(dto: IClientCreateDto, field: IOrderTypeField, value: any): void {
        if (!field.custom) {
            return;
        }

        if (!dto.custom) {
            dto.custom = {};
        }

        // TODO: remove details1
        const id = field.customId ?? field.details1 ?? field.id;
        dto.custom[id] = value;
    }

    private populateStandartFieldValue(dto: IClientCreateDto, field: IOrderTypeField, value: any): void {
        switch (field.id) {
            case "client.type":
                dto.type = value;
                return;
            case "client.name":
                dto.name = value;
                return;
            case "client.description":
                dto.description = value;
                return;
            case "client.taxId":
                dto.taxId = value;
                return;
            case "client.email":
                if (!dto.contacts) {
                    dto.contacts = {};
                }

                dto.contacts.email = value;
                return;
            case "client.phone":
                if (!dto.contacts) {
                    dto.contacts = {};
                }

                dto.contacts.phone = value;
                return;
            case "client.source":
                dto.source = value;
                return;
            case "client.comment":
                dto.comment = value;
                return;
        }
    }

    //

    public clean(): void {
        this.initControls();
    }

    public setControlValues(client: IClient): void {
        for (const control of this.controls) {
            control.value = this.setControlValue(client, control.field);
        }
    }

    private setControlValue(client: IClient, field: IOrderTypeField): any {
        if (!client) {
            return "";
        }

        if (field.custom) {
            // TODO: remove details1
            const id = field.customId ?? field.details1 ?? field.id;
            return client.custom ? client.custom[id] : "";
        }

        return this.getStandartFieldValue(client, field);
    }

    private getStandartFieldValue(client: IClient, field: IOrderTypeField): any {
        switch (field.id) {
            case "client.type":
                return client.info?.type;
            case "client.name":
                return client.info?.name;
            case "client.description":
                return client.info?.description;
            case "client.taxId":
                return client.info?.taxId;
            case "client.email":
                return client.info?.contacts?.email;
            case "client.phone": {
                return client.info?.contacts?.phone;
            }
            case "client.source":
                return client.info?.source;
            case "client.comment":
                return client.comment;
        }

        return "";
    }
}
