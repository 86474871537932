import {
    ITransactionItem,
    TransactionItemTag,
    TransactionType,
    ITransactionItemCreateDto,
    ITransactionItemUpdateDto,
} from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, Select, MultiSelect, TextBox } from "@core/components/alt-ui/controls";
import { getTransactionItemTag, getTransactionType, ISelectOption } from "@core/types/common/select-options";

export class TransactionItemsModal extends Modal<ITransactionItem> {
    private tbSequence!: TextBox;
    private cbType!: Select<ISelectOption<TransactionType>>;
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private cbTags!: MultiSelect<ISelectOption<TransactionItemTag>>;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private transactionItem: ITransactionItem | null = null;
    public onCreate: ((dto: ITransactionItemCreateDto) => Promise<ITransactionItem | null>) | null = null;
    public onUpdate:
        | ((transactionItem: ITransactionItem, dto: ITransactionItemUpdateDto) => Promise<ITransactionItem | null>)
        | null = null;

    public constructor() {
        super("transactionItems-modal", "");
        this.initializeControls();
    }

    public show(transactionItem?: ITransactionItem): Promise<void> {
        this.transactionItem = transactionItem ?? null;
        this.title = transactionItem ? "Изменение статьи" : "Новая статья";
        this.initializeControls();

        if (transactionItem) {
            this.populateControls(transactionItem);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbSequence = new TextBox();
        this.cbType = new Select<ISelectOption<TransactionType>>();
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.cbTags = new MultiSelect<ISelectOption<TransactionItemTag>>();

        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbSequence.id = "transactionItem.sequence";
        this.tbSequence.label = "Порядок";
        this.tbSequence.validation = "required|numeric|min_value:1|max_value:999";
        this.tbSequence.visible = false;

        this.cbType.id = "transactionItem.type";
        this.cbType.label = "Тип";
        this.cbType.items = getTransactionType();
        this.cbType.textField = item => item.name;
        this.cbType.selectedIndex = this.cbType.items.length > 0 ? 0 : -1;

        this.tbName.id = "transactionItem.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.tbDescription.id = "transactionItem.description";
        this.tbDescription.label = "Описание";

        this.cbTags.id = "transactionItem.tags";
        this.cbTags.label = "Метки для автоматизации";
        this.cbTags.items = getTransactionItemTag();
        this.cbTags.textField = item => item.name;

        //

        this.btnCancel.id = "transactionItem.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "transactionItem.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(transactionItem: ITransactionItem): void {
        this.tbSequence.text = transactionItem.sequence.toString();
        this.tbSequence.visible = true;

        this.cbType.selectedIndex = this.cbType.items.findIndex(e => e.id === transactionItem.type);

        this.tbName.text = transactionItem.name;

        this.tbDescription.text = transactionItem.description ?? "";

        const tags = transactionItem.tags ?? [];

        this.cbTags.selectedItems = this.cbTags.items.filter(tag => tags.includes(tag.id));
    }

    public get controls(): Control[] {
        return [this.tbSequence, this.cbType, this.tbName, this.tbDescription, this.cbTags, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const result = this.transactionItem ? await this.update(this.transactionItem) : await this.create();

        if (result) {
            this.hide(result);
        }
    }

    private async create(): Promise<ITransactionItem | null> {
        if (!this.onCreate) {
            return null;
        }

        if (!this.cbType.selectedItem) {
            return null;
        }

        const tags = this.cbTags.selectedItems.map(e => e.id);

        const dto: ITransactionItemCreateDto = {
            type: this.cbType.selectedItem?.id,
            name: this.tbName.text,
            description: this.tbDescription.text,
            tags,
        };

        return await this.onCreate(dto);
    }

    private async update(transactionItem: ITransactionItem): Promise<ITransactionItem | null> {
        if (!this.onUpdate) {
            return null;
        }

        if (!this.cbType.selectedItem) {
            return null;
        }

        const tags = this.cbTags.selectedItems.map(e => e.id);

        const dto: ITransactionItemUpdateDto = {
            sequence: parseInt(this.tbSequence.text),
            type: this.cbType.selectedItem?.id,
            name: this.tbName.text,
            description: this.tbDescription.text,
            tags,
        };

        return await this.onUpdate(transactionItem, dto);
    }
}
