import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { BSidebar, BButton } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AdminCompanyUseCase } from "@core/usecases/admin/admin-company.usecase";
import CompanyBalanceAdd from "../company-balance-add/company-balance-add.vue";

@Component({
    name: "company-view-form",
    components: {
        BSidebar,
        BButton,
        VuePerfectScrollbar,
        CompanyBalanceAdd,
    },
})
export default class CompanyViewForm extends Vue {
    private AdminCompanyUseCase = new AdminCompanyUseCase();

    @PropSync("visible", { type: Boolean, default: false })
    private visibleSync!: boolean;

    @Prop({ type: Object, default: () => null })
    private company!: any | null;

    private balanceAddShow = false;

    @Watch("visible")
    private onVisibleChanged(visible: boolean): void {
        if (visible) {
            this.initValues();
        }
    }

    public mounted(): void {
        this.initValues();
    }

    private initValues(): void {
        //
    }

    private cancel(): void {
        this.visibleSync = false;
    }

    private addMoney(): void {
        this.balanceAddShow = true;
    }

    private async confirmDelete(): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить компанию: "${this.company.info.name}"?
            Будут удалены все заявки, продажи, филиалы, счета и остальное.`,
            "Удаление компании",
            { okText: "Удалить" },
        );

        if (result) {
            await this.delete(this.company);
        }
    }

    private async delete(company: any): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.AdminCompanyUseCase.delete(company.id);
            this.$alt.toast.success("Компания успешно удалена.");
            this.$emit("deleted", company);
            this.visibleSync = false;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
