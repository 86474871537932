import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BTooltip } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider } from "vee-validate";
import AltInput from "@core/components/alt-controls/alt-input.vue";
import { SuggestBox } from "./suggest-box";

@Component({
    name: "suggest-box-component",
    components: { AltInput, VueAutosuggest, ValidationProvider, BTooltip },
})
export class SuggestBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: SuggestBox;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.handler.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderParts(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderParts(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        if (this.handler.disabled) {
            items.push(
                <alt-input
                    id={this.handler.id}
                    placeholder={this.handler.placeholder}
                    disabled={this.handler.disabled}
                    autocomplete="off"
                    state={errors.length > 0 ? false : null}
                    v-model={this.handler.text}
                />,
            );
        } else {
            items.push(
                <vue-autosuggest
                    id={this.handler.id}
                    v-model={this.handler.text}
                    suggestions={[{ data: this.handler.items }]}
                    input-props={{
                        id: this.handler.id,
                        class: {
                            "form-control": !errors.length,
                            "form-control is-invalid": errors.length,
                        },
                        name: this.handler.label,
                    }}
                    //section-configs={{ default: { limit: this.handler.limit } }}
                    get-suggestion-value={this.getSuggestionValue}
                    render-suggestion={this.renderOption}
                    class={this.handler.class}
                    style={this.handler.style}
                    disabled={this.handler.disabled}
                    v-on:input={this.search}
                    v-on:selected={this.selected}
                />,
            );
        }

        if (errors[0]) {
            items.push(<small class="text-xs ml-1 text-danger">{errors[0]}</small>);
        }

        return items;
    }

    private renderOption(option: any): VNode {
        return <span>{this.getSuggestionValue(option)}</span>;
    }

    private getSuggestionValue(suggestion: { item: any }): string {
        return this.handler.textField(suggestion.item);
    }

    private search(): void {
        if (this.handler.search) {
            this.handler.search(this.handler.text);
        }
    }

    private selected(suggestion: { item: any }): void {
        this.handler.selectedItem = suggestion.item;
    }
}
