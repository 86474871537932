import { IAccount, ITransaction, ITransactionItem, TransactionType, ITransactionCreateDto, Locale } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Icon, Panel, Select, TextArea, TextBox } from "@core/components/alt-ui/controls";
import { Localizer } from "@/i18n/localizer";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface TransactionModalContext {
    transaction?: ITransaction; // транзакция для редактирования
    type: TransactionType;
    accounts: IAccount[];
    selectedAccounts: IAccount[];
    items: ITransactionItem[];
    userId: string;
}

export class TransactionModal extends Modal<TransactionModalContext> {
    private сbAccount!: Select<IAccount>;
    private сbItem!: Select<ITransactionItem>;
    private tbValue!: TextBox;
    private taDescription!: TextArea;

    private pnlFooterWrapper!: Panel;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;
    private btnTransfer!: Button;

    private context!: TransactionModalContext;
    public onCreate: ((dto: ITransactionCreateDto) => Promise<ITransaction | null>) | null = null;
    public onTransferClick: (() => void) | null = null;

    public constructor() {
        super("transaction-modal", "");
        this.initializeControls();
    }

    public show(context: TransactionModalContext): Promise<void> {
        this.context = context;
        this.title = context.transaction
            ? context.type === TransactionType.Income
                ? "Изменение прихода"
                : "Изменение расхода"
            : context.type === TransactionType.Income
            ? "Новый приход"
            : "Новый расход";

        this.initializeControls();

        if (context.transaction) {
            this.populateControls(context.transaction);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.сbAccount = new Select<IAccount>();
        this.сbItem = new Select<ITransactionItem>();
        this.tbValue = new TextBox();
        this.taDescription = new TextArea();
        this.pnlFooter = new Panel();
        this.pnlFooterWrapper = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();
        this.btnTransfer = new Button();

        //

        const selectedAccountId =
            this.context?.selectedAccounts.length > 0 ? this.context.selectedAccounts[0].id : null;

        this.сbAccount.id = "transaction.account";
        this.сbAccount.label = "Счёт";
        this.сbAccount.items = this.context?.accounts ?? [];
        this.сbAccount.textField = ac => ac.info.name;
        this.сbAccount.descriptionField = ac => ac.info.description;
        this.сbAccount.selectedIndex = this.сbAccount.items.findIndex(a => a.id === selectedAccountId);

        this.tbValue.id = "transaction.value";
        this.tbValue.label = "Сумма";
        this.tbValue.validation = "required|money|unsigned";
        this.tbValue.text = "";

        this.taDescription.id = "transaction.description";
        this.taDescription.label = "Описание";
        this.taDescription.validation = "required";
        this.taDescription.text = "";

        // после описания, т. к. может менять текст
        this.сbItem.id = "transaction.item";
        this.сbItem.label = "Статья";
        this.сbItem.items = this.context?.items.filter(i => i.type === this.context.type) ?? [];
        this.сbItem.textField = item => item.name;
        this.сbItem.descriptionField = item => item.description;
        this.сbItem.addChangedHandler((_, event) => {
            const item = event.item;
            if (item?.description && this.taDescription.text.length === 0) {
                this.taDescription.text = item.description;
            }
        });
        this.сbItem.selectedIndex = this.сbItem.items.length > 0 ? 0 : -1;

        //

        this.btnTransfer.id = "transaction.transfer";
        this.btnTransfer.help = "Перевод на другой счёт";
        this.btnTransfer.variant = "flat-primary";
        this.btnTransfer.icon = new Icon();
        this.btnTransfer.icon.icon = "RepeatIcon";
        this.btnTransfer.addClickHandler(this.clickTransfer.bind(this));

        this.btnCancel.id = "transaction.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "transaction.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooterWrapper.addControls([this.btnCancel, this.btnSave]);

        if (this.context?.type === TransactionType.Expense && this.context?.accounts.length > 1) {
            this.pnlFooter.class = "flex justify-between mt-2";
            this.pnlFooter.addControls([this.btnTransfer, this.pnlFooterWrapper]);
        } else {
            this.pnlFooter.class = "flex justify-end mt-2";
            this.pnlFooter.addControls([this.pnlFooterWrapper]);
        }
    }

    private populateControls(transaction: ITransaction): void {
        const account = this.context.accounts.find(a => a.id === transaction.account);
        const locale = account?.info.locale ?? Locale.RU;

        this.сbAccount.visible = false;
        this.сbItem.selectedIndex = this.сbItem.items.findIndex(i => i.id === transaction.item);
        const value = Math.abs(transaction.value ?? 0);
        this.tbValue.text = Localizer.number(value, locale);
        this.taDescription.text = transaction.description;
    }

    public get controls(): Control[] {
        return [this.сbAccount, this.сbItem, this.tbValue, this.taDescription, this.pnlFooter];
    }

    private async clickTransfer(sender: any, e: any): Promise<void> {
        if (!this.onTransferClick) {
            return;
        }

        await this.onTransferClick();

        this.hide();
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.context.transaction
            ? await this.updateTransaction(this.context.transaction)
            : await this.createTransaction();

        if (result) {
            this.hide(result);
        }
    }

    private async createTransaction(): Promise<ITransaction | null> {
        if (!this.onCreate) {
            return null;
        }

        if (!this.сbAccount.selectedItem) {
            return null;
        }

        if (!this.сbItem.selectedItem) {
            return null;
        }

        const account = this.сbAccount.selectedItem;
        const moneyStr = this.tbValue.text.replace(",", ".");
        const money = this.context.type === TransactionType.Income ? parseFloat(moneyStr) : -parseFloat(moneyStr);

        const dto: ITransactionCreateDto = {
            account: account.id,
            item: this.сbItem.selectedItem.id,
            value: money,
            description: this.taDescription.text,
            user: this.context.userId,
            //meta: undefined,
        };

        return await this.onCreate(dto);
    }

    private async updateTransaction(transaction: ITransaction): Promise<ITransaction | null> {
        return null;
    }
}
