/** Правовой тип. */
export enum LegalType {
    /** Физическое лицо. */
    Natural = "person",

    /** Юридическое лицо. */
    Juridical = "company",

    // Government = "government"
}
