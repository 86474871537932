import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BDropdown, BDropdownForm, BFormCheckbox } from "bootstrap-vue";
import { CheckBoxSelect } from "./check-box-select";

@Component({
    name: "check-box-select-component",
    components: {
        BDropdown,
        BDropdownForm,
        BFormCheckbox,
    },
})
export class CheckBoxSelectComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: CheckBoxSelect;

    public get options(): any[] {
        return this.handler.items.map(item => ({
            value: item,
            text: this.handler.textField ? this.handler.textField(item) : "",
            disabled: this.handler.disabledField ? this.handler.disabledField(item) : false,
            //style: undefined,
            //class: undefined,
        }));
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={`m-0 ${this.handler.containerClass}`} id={this.handler.id}>
                    <b-dropdown
                        dropup
                        right
                        text={this.handler.label}
                        variant="flat-primary"
                        class={`text-xs ${this.handler.class}`}
                        style={this.handler.style}
                    >
                        {this.renderDropdownForm()}
                    </b-dropdown>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderDropdownForm(): VNode {
        if (this.options.length > 0) {
            return <b-dropdown-form form-class="w-max">{this.renderCheckBoxes()}</b-dropdown-form>;
        } else {
            return <b-dropdown-form form-class="w-max">Нет элементов</b-dropdown-form>;
        }
    }

    private renderCheckBoxes(): VNode[] {
        const checkboxes: VNode[] = [];
        for (const option of this.options) {
            checkboxes.push(
                <b-form-checkbox
                    class={`ml-0.5 mb-50 ${option.class}`}
                    style={option.style}
                    disabled={option.disabled}
                    value={option.value}
                    v-model={this.handler.selectedItems}
                >
                    {option.text}
                </b-form-checkbox>,
            );
        }
        return checkboxes;
    }
}
