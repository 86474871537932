import { ICompany, IGood, IStore, ITemplateLabel } from "@lib";
import { IGoodMacroOpenContext, IMacro, IMacroReplacer } from "../macro";
import { GoodMacroList } from "./good-label.macro-list";
import { AppException } from "@/exceptions";

export type GoodPrintContext = { company: ICompany; stores: IStore[]; goods: IGood[] };

export class GoodLabelMacroReplacer implements IMacroReplacer<ITemplateLabel> {
    // %(макрос:аргумент1,аргумент2)
    private readonly regexp = /%\((?<macro>[\p{L}\p{N}.]*)(:?(?<args>[\p{L}\p{N}.,]*)?)\)/giu;
    // \p{L} - юникодные буквы
    // \p{N} - юникодные цифры
    // регулярки: https://developer.mozilla.org/ru/docs/Web/JavaScript/Guide/Regular_Expressions
    // юникодные символы: https://learn.javascript.ru/regexp-unicode
    // проверка регулярок: https://regex101.com/

    private readonly context: GoodPrintContext;

    public constructor(context: GoodPrintContext) {
        this.context = context;
    }

    public replace(model: ITemplateLabel): string {
        return this.replaceSimple(model.template, model);
    }

    public replaceSimple(template: string, model?: ITemplateLabel): string {
        return model?.single === true
            ? this.replaceSimpleSingle(template, model)
            : this.replaceSimpleMultiple(template, model);
    }

    private replaceSimpleSingle(template: string, model?: ITemplateLabel): string {
        if (!model?.width || !model?.height) {
            throw new AppException("[GoodLabelMacroReplacer] Не заданы размеры ценника.");
        }

        const border = model.border === false ? 0 : 1;
        const style = `width: ${model.width}mm; height: ${model.height}mm; border: ${border}px dashed black;`;

        let result = "";

        for (const good of this.context.goods) {
            for (let i = 0; i < good.info.quantity; ++i) {
                result += `<div style="${style}">`;
                result += this.replaceForGood(template, good);
                result += "</div>";

                result += '<p style="page-break-before: always"></p>';
            }
        }

        return result;
    }

    private replaceSimpleMultiple(template: string, model?: ITemplateLabel): string {
        if (!model?.width || !model?.height) {
            throw new AppException("[GoodLabelMacroReplacer] Не заданы размеры ценника.");
        }

        const border = model.border === false ? 0 : 1;
        const style = `width: ${model.width}mm; height: ${model.height}mm; border: ${border}px dashed black;`;

        let result = '<div style="display: flex; flex-wrap: wrap;">';

        for (const good of this.context.goods) {
            for (let i = 0; i < good.info.quantity; ++i) {
                result += `<div style="${style}">`;
                result += this.replaceForGood(template, good);
                result += "</div>";
            }
        }

        result += "</div>";
        return result;
    }

    private replaceForGood(template: string, good: IGood): string {
        return this.replaceRegexp(template, good, value => `<span style="white-space: pre-wrap;">${value}</span>`);
    }

    protected replaceRegexp(template: string, good: IGood, func: (value: string) => string): string {
        return template.replace(this.regexp, (match: string, macro: string, args: string) => {
            try {
                const argsArray = args.length > 0 ? args.substring(1).split(",") : undefined;
                const value = this.replaceMacro(macro, good, argsArray) ?? match;
                return func(value);
            } catch {
                //console.warn(`Не удалось раскрыть макрос: ${match}.`);
                return match;
            }
        });
    }

    private replaceMacro(macroName: string, good: IGood, args?: string[]): string | null {
        const macro = this.findMacro(macroName);
        if (!macro) {
            return null;
        }

        const store = this.context.stores.find(s => s.id === good.store);

        const context: IGoodMacroOpenContext = {
            company: this.context.company,
            good: good,
            store: store,
        };

        return macro.open(context, args) ?? "";
    }

    private findMacro(macroName: string): IMacro<IGoodMacroOpenContext> | null {
        macroName = macroName.toLowerCase();

        for (const macro of GoodMacroList) {
            for (const alias of macro.alias) {
                if (alias.toLowerCase() === macroName) {
                    return macro;
                }
            }
        }

        return null;
    }
}
