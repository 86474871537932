import { IMenuItem } from "./menu-item";

export const MenuItemsAdmin: IMenuItem[] = [
    {
        name: "Аналитика",
        route: "analytics",
        icon: "PieChartIcon",
        i18n: "admin.analytics.title",
        visible: (context: Vue) => true,
    },
    {
        name: "Компании",
        route: "companies",
        icon: "UsersIcon",
        i18n: "admin.companies.title",
        visible: (context: Vue) => true,
    },
    {
        name: "Пользователи",
        route: "users",
        icon: "UserIcon",
        i18n: "admin.users.title",
        visible: (context: Vue) => true,
    },
];
