import {
    BarcodeType,
    CompanyFeatureBarcodesValidationError,
    IBarcode,
    ICompanyFeatureBarcodesValidationResult,
} from "@lib";
import {
    Button,
    Control,
    Panel,
    TextBox,
    Select,
    Label,
    ComboBoxChangedEventArgs,
} from "@core/components/alt-ui/controls";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { ISelectOption } from "@core/types/common/select-options";
import { BarcodeControl, barcodeList } from "./barcode-control";
import Resources from "@/i18n/i18n";

export interface IBarcodeCreateContext {
    companyId: string;
    barcode?: IBarcode;
    barcodeIndex?: number;
    handler: BarcodeControl;
}

interface IBarcodeValidationResult {
    valid: boolean;
    message?: string;
}

export class BarcodeCreateModal extends Modal2<IBarcodeCreateContext> {
    private cbType!: Select<ISelectOption<BarcodeType>>;

    private pnlCode!: Panel;
    private tbCode!: TextBox;
    private btnGenerate!: Button;

    private lbError!: Label;

    private pnlFooter!: Panel;
    private btnDelete!: Button;
    private btnSave!: Button;

    private context: IBarcodeCreateContext | null = null;

    public onCreate: ((dto: IBarcode) => void) | null = null;
    public onUpdate: ((barcodeIndex: number, dto: IBarcode) => void) | null = null;
    public onDelete: ((barcodeIndex: number, dto: IBarcode) => void) | null = null;
    public onGenerate: ((type: BarcodeType) => Promise<IBarcode>) | null = null;
    public onValidate: ((dto: IBarcode) => Promise<ICompanyFeatureBarcodesValidationResult>) | null = null;

    public constructor() {
        super("barcode-create-modal", "");

        this.initializeControls();
    }

    public show(context: IBarcodeCreateContext): Promise<void> {
        this.context = context;
        this.title = context.barcode ? "Изменение штрихкода" : "Создание штрихкода";
        this.initializeControls();

        if (context.barcode) {
            this.populateControls(context.barcode);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.cbType = new Select();
        this.cbType.id = "barcode-create.type";
        this.cbType.label = "Тип";
        this.cbType.items = barcodeList;
        this.cbType.textField = e => e.name;
        this.setSelectedType();
        this.cbType.addChangedHandler(this.onTypeChanged.bind(this));

        this.tbCode = new TextBox();
        this.tbCode.id = "barcode-create.code";
        this.tbCode.label = "Код";
        this.tbCode.class = "w-full";

        this.btnGenerate = new Button();
        this.btnGenerate.id = "barcode-create.generate";
        this.btnGenerate.text = "Сгенерировать";
        this.btnGenerate.variant = "outline-secondary";
        this.btnGenerate.class = "mt-auto";
        this.btnGenerate.addClickHandler(this.clickGenerate.bind(this));

        this.pnlCode = new Panel();
        this.pnlCode.id = "barcode-create.panel";
        this.pnlCode.class = "space-x-4 flex";
        this.pnlCode.addControls([this.tbCode, this.btnGenerate]);

        this.lbError = new Label();
        this.lbError.id = "barcode-create.error";
        this.lbError.class = "text-danger";
        this.lbError.visible = false;

        this.btnDelete = new Button();
        this.btnDelete.id = "barcode-create.cancel";
        this.btnDelete.text = "Удалить";
        this.btnDelete.class = "mr-0.75";
        this.btnDelete.variant = "danger";
        this.btnDelete.addClickHandler(this.clickDelete.bind(this));
        this.btnDelete.visible = false;

        this.btnSave = new Button();
        this.btnSave.id = "barcode-create.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end";
        this.pnlFooter.addControl(this.btnDelete);
        this.pnlFooter.addControl(this.btnSave);
    }

    private async setSelectedType(): Promise<void> {
        const type = this.context?.handler.selectedType;
        if (!type) {
            this.cbType.selectedIndex = 0;
            return;
        }

        const selectedBarcodeIndex = barcodeList.findIndex(e => e.id === type);
        this.cbType.selectedIndex = selectedBarcodeIndex === -1 ? 0 : selectedBarcodeIndex;
    }

    private populateControls(barcode: IBarcode): void {
        this.cbType.selectedIndex = barcodeList.findIndex(e => e.id === barcode.type);
        this.tbCode.text = barcode.code;
        this.btnDelete.visible = true;
    }

    public get controls(): Control[] {
        return [this.cbType, this.pnlCode, this.lbError];
    }

    public get footer(): Control {
        return this.pnlFooter;
    }

    private async clickGenerate(): Promise<void> {
        if (!this.onGenerate || !this.cbType.selectedItem) {
            return;
        }

        const response = await this.onGenerate(this.cbType.selectedItem.id);
        this.tbCode.text = response.code;
    }

    private async clickDelete(sender: any, e: any): Promise<void> {
        if (!this.context?.barcode) {
            return;
        }

        this.delete(this.context.barcode);
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const result = this.context?.barcode ? await this.update(this.context.barcode) : await this.create();

        if (result) {
            this.hide();
        }
    }

    private async create(): Promise<boolean> {
        const valid = await this.validateForm();
        if (!valid) {
            return false;
        }

        if (!this.onCreate || !this.cbType.selectedItem) {
            return false;
        }

        const barcode: IBarcode = {
            type: this.cbType.selectedItem.id,
            code: this.tbCode.text,
        };

        this.onCreate(barcode);
        return true;
    }

    private onTypeChanged(sender: any, event: ComboBoxChangedEventArgs<ISelectOption<BarcodeType>>): void {
        const barcode = event.item;
        if (!barcode || !this.context) {
            return;
        }

        this.context.handler.selectedType = barcode.id;
    }

    private async update(barcode: IBarcode): Promise<boolean> {
        const valid = await this.validateForm();
        if (!valid) {
            return false;
        }

        if (!this.onUpdate || !this.cbType.selectedItem) {
            return false;
        }

        if (this.context?.barcodeIndex === undefined) {
            return false;
        }

        const updatedBarcode: IBarcode = {
            type: this.cbType.selectedItem.id,
            code: this.tbCode.text,
        };

        this.onUpdate(this.context.barcodeIndex, updatedBarcode);
        return true;
    }

    private delete(barcode: IBarcode): void | null {
        if (!this.onDelete) {
            return null;
        }

        if (this.context?.barcodeIndex === undefined) {
            return null;
        }

        return this.onDelete(this.context.barcodeIndex, barcode);
    }

    private async validateForm(): Promise<boolean> {
        const barcodeValidation = await this.validateBarcode();
        if (!barcodeValidation.valid) {
            this.lbError.text = barcodeValidation.message as string;
            this.lbError.visible = true;
            return false;
        }

        this.lbError.visible = false;
        return true;
    }

    private async validateBarcode(): Promise<IBarcodeValidationResult> {
        if (!this.cbType.selectedItem) {
            return {
                valid: false,
                message: Resources.t("main.features.barcodes.typenotspecified") as string,
            };
        }

        if (!this.tbCode.text) {
            return {
                valid: false,
                message: Resources.t("main.features.barcodes.codenotspecified") as string,
            };
        }

        if (!this.onValidate) {
            return {
                valid: false,
                message: Resources.t("main.features.barcodes.apinotspecified") as string,
            };
        }

        const dto: IBarcode = {
            type: this.cbType.selectedItem.id,
            code: this.tbCode.text,
        };

        const result = await this.onValidate(dto);

        if (!result.valid) {
            let message = "";

            switch (result.error) {
                case CompanyFeatureBarcodesValidationError.InvalidSyntax:
                    message = Resources.t("main.features.barcodes.invalidsyntax") as string;
                    break;
                case CompanyFeatureBarcodesValidationError.AlreadyExists:
                    message = Resources.t("main.features.barcodes.alreadyexists") as string;
                    break;
                default:
                    message = Resources.t("main.features.barcodes.unknownerror") as string;
                    break;
            }

            return {
                valid: false,
                message: message,
            };
        }

        return {
            valid: true,
        };
    }
}
