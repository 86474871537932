import { IDocument, ITemplateLabel } from "@lib";
import { DocumentPrinter, IPrintOptions } from "./document-printer";
import { GoodLabelMacroReplacer, GoodPrintContext } from "./macro-replacers/good-label.macro-replacer";
import {
    OrderDocumentMacroHtmlReplacer,
    OrderDocumentMacroReplacer,
    OrderPrintContext,
} from "./macro-replacers/order-document.macro-replacer";
import { OrderTableMacroReplacer } from "./macro-replacers/order-table.macro-replacer";
import { OrderTable2MacroReplacer } from "./macro-replacers/order-table2.macro-replacer";
import {
    SaleDocumentMacroHtmlReplacer,
    SaleDocumentMacroReplacer,
    SalePrintContext,
} from "./macro-replacers/sale-document.macro-replacer";
import { SaleTableMacroReplacer } from "./macro-replacers/sale-table.macro-replacer";
import { SaleTable2MacroReplacer } from "./macro-replacers/sale-table2.macro-replacer";

export class Printer {
    public static printOrderDocument(document: IDocument, context: OrderPrintContext): void {
        Printer.printOrderDocuments([document], context);
    }

    public static printOrderDocuments(documents: IDocument[], context: OrderPrintContext): void {
        if (documents.length === 0) {
            return;
        }

        const options: IPrintOptions<IDocument> = {
            getTemplate: m => m.template,
        };

        const replacers = [
            new OrderDocumentMacroHtmlReplacer(context),
            new OrderTable2MacroReplacer(context.order),
            new OrderTableMacroReplacer(context.order),
        ];
        const printer = new DocumentPrinter<IDocument>(replacers);
        printer.print(documents, options);
    }

    public static previewOrderDocuments(template: string, context: OrderPrintContext, model: IDocument): void {
        const options: IPrintOptions<IDocument> = {
            getTemplate: _ => template,
        };

        const replacers = [
            new OrderDocumentMacroHtmlReplacer(context),
            new OrderTable2MacroReplacer(context.order),
            new OrderTableMacroReplacer(context.order),
        ];
        const printer = new DocumentPrinter<IDocument>(replacers);
        printer.preview(model, options);
    }

    public static getPreviewHtmlOrderDocuments(template: string, context: OrderPrintContext, model: IDocument): string {
        const options: IPrintOptions<IDocument> = {
            getTemplate: _ => template,
        };

        const replacers = [
            new OrderDocumentMacroHtmlReplacer(context),
            new OrderTable2MacroReplacer(context.order),
            new OrderTableMacroReplacer(context.order),
        ];
        const printer = new DocumentPrinter<IDocument>(replacers);
        return printer.getPreviewHtml(model, options);
    }

    public static replaceOrderDocumentsGeneralMacros(
        template: string,
        context: OrderPrintContext,
        model?: IDocument,
    ): string {
        const replacers = [new OrderDocumentMacroReplacer(context)];
        const printer = new DocumentPrinter<IDocument>(replacers);
        return printer.replaceMacrosForTemplate(template, model);
    }

    //

    public static printSaleDocument(document: IDocument, context: SalePrintContext): void {
        Printer.printSaleDocuments([document], context);
    }

    public static printSaleDocuments(documents: IDocument[], context: SalePrintContext): void {
        if (documents.length === 0) {
            return;
        }

        const options: IPrintOptions<IDocument> = {
            getTemplate: m => m.template,
        };

        const replacers = [
            new SaleDocumentMacroHtmlReplacer(context),
            new SaleTable2MacroReplacer(context.sale),
            new SaleTableMacroReplacer(context.sale),
        ];
        const printer = new DocumentPrinter<IDocument>(replacers);
        printer.print(documents, options);
    }

    public static previewSaleDocuments(template: string, context: SalePrintContext, model: IDocument): void {
        const options: IPrintOptions<IDocument> = {
            getTemplate: _ => template,
        };

        const replacers = [
            new SaleDocumentMacroHtmlReplacer(context),
            new SaleTable2MacroReplacer(context.sale),
            new SaleTableMacroReplacer(context.sale),
        ];
        const printer = new DocumentPrinter<IDocument>(replacers);
        printer.preview(model, options);
    }

    public static getPreviewHtmlSaleDocuments(template: string, context: SalePrintContext, model: IDocument): string {
        const options: IPrintOptions<IDocument> = {
            getTemplate: _ => template,
        };

        const replacers = [
            new SaleDocumentMacroHtmlReplacer(context),
            new SaleTable2MacroReplacer(context.sale),
            new SaleTableMacroReplacer(context.sale),
        ];
        const printer = new DocumentPrinter<IDocument>(replacers);
        return printer.getPreviewHtml(model, options);
    }

    public static replaceSaleDocumentsGeneralMacros(
        template: string,
        context: SalePrintContext,
        model?: IDocument,
    ): string {
        const replacers = [new SaleDocumentMacroReplacer(context)];
        const printer = new DocumentPrinter<IDocument>(replacers);
        return printer.replaceMacrosForTemplate(template, model);
    }

    //

    public static printGoodLabel(label: ITemplateLabel, context: GoodPrintContext): void {
        Printer.printGoodLabels([label], context);
    }

    public static printGoodLabels(labels: ITemplateLabel[], context: GoodPrintContext): void {
        if (labels.length === 0) {
            return;
        }

        const options: IPrintOptions<ITemplateLabel> = {
            getTemplate: m => m.template,
        };

        const replacers = [new GoodLabelMacroReplacer(context)];
        const printer = new DocumentPrinter<ITemplateLabel>(replacers);
        printer.print(labels, options);
    }

    public static previewGoodLabels(template: string, context: GoodPrintContext, label: ITemplateLabel): void {
        const options: IPrintOptions<ITemplateLabel> = {
            getTemplate: _ => template,
            pageWidth: label.width * 3.8,
        };

        const replacers = [new GoodLabelMacroReplacer(context)];
        const printer = new DocumentPrinter<ITemplateLabel>(replacers);
        printer.preview(label, options);
    }

    public static getPreviewHtmlGoodLabels(template: string, context: GoodPrintContext, label: ITemplateLabel): string {
        const options: IPrintOptions<ITemplateLabel> = {
            getTemplate: _ => template,
        };

        const replacers = [new GoodLabelMacroReplacer(context)];
        const printer = new DocumentPrinter<ITemplateLabel>(replacers);
        return printer.getPreviewHtml(label, options);
    }
}
