import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton, BSidebar, VBTooltip } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ValidationObserver } from "vee-validate";
import {
    IShop,
    ISale,
    ISaleGood,
    IAccount,
    IStore,
    IEmployee,
    IUser,
    ICompany,
    ISalePaymentCreateDto,
    ISaleGoodDto,
    ISaleGoodCreateDto,
    ISaleGoodPaymentDto,
    ISaleGoodFromStoreDto,
    ISaleStage,
} from "@lib";
import { SaleStageType, IDocument, IDiscount, DiscountType, PaymentType } from "@lib";
import { ISaleCreateDto } from "@lib";
import VaCheckboxSelect from "@/components/common/va-checkbox-select";
import { VisibilityChangedEventArgs } from "@core/components/alt-ui";
import { ModalComponent } from "@core/components/alt-ui/modal";
import SaleCreateHeader from "./sale-create-header.vue";
import SaleCreateInfo from "./sale-create-info.vue";
import SaleCreateGoods from "./sale-create-goods.vue";
import { SaleCreateForm } from "./sale-create-form";
import { ISaleGoodItem } from "./sale-create-goods";
import { SaleBlockInfoHandler } from "./sale-block-info-handler";
import { ISaleCompleteModalData, SaleCompleteModalAnswer } from "../sale-complete.modal";
import { AppException } from "@/exceptions";

@Component({
    components: {
        BButton,
        BSidebar,
        ValidationObserver,
        VuePerfectScrollbar,
        ModalComponent,
        SaleCreateHeader,
        SaleCreateInfo,
        SaleCreateGoods,
        VaCheckboxSelect,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class SaleCreateFormComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: SaleCreateForm;

    @Prop({ type: Array, default: () => [] })
    private shops!: IShop[];

    @Prop({ type: Object, default: () => null })
    private shop!: IShop;

    @Prop({ type: Array, default: () => [] })
    private stores!: IStore[];

    @Prop({ type: Array, default: () => [] })
    private accounts!: IAccount[];

    @Prop({ type: Array, default: () => [] })
    private employees!: IEmployee[];

    @Prop({ type: Array, default: () => [] })
    private stages!: ISaleStage[];

    @Prop({ type: Array, default: () => [] })
    private documents!: IDocument[];

    public visible: boolean = false;

    private sale: ISale | null = null; // для режима просмотра текущей продажи

    private shopCurrent: IShop | null = null;
    private stage: ISaleStage | null = null;
    private discount: IDiscount | null = null;
    private comment: string | null = null;
    private seller: IEmployee | null = null;
    private goodsInit: ISaleGood[] = [];
    private goodItems: ISaleGoodItem[] = [];
    private total: number = 0.0;

    private updateTableKey = 1;
    private saleBlockInfoHandler = new SaleBlockInfoHandler();

    private company!: ICompany;
    private user!: IUser;
    private SaleUseCase = this.$alt.system.usecase.createSaleUseCase();

    private get readonly(): boolean {
        return this.sale !== null;
    }

    public async mounted(): Promise<void> {
        this.init();
        await this.initValues();
    }

    @Watch("handler")
    private onHandlerChanged(): void {
        this.init();
    }

    // @Watch("handler.visible")
    // private onDataChanged(): void {
    //     this.initValues();
    //     (this.$refs["sale-create-validator"] as any).reset();
    // }

    private init(): void {
        this.handler.addVisibleChangedHandler(this.onSidebarVisibleChanged.bind(this));
        //this.handler.addValidateHandler(this.onSidebarValidate.bind(this));

        this.saleBlockInfoHandler.onDiscountChanged = discount => this.changeDiscount(discount);
    }

    public close(): void {
        this.handler.hide();
    }

    private onSidebarVisibleChanged(sender: any, e: VisibilityChangedEventArgs): void {
        this.$nextTick(() => {
            this.visible = e.visible;

            if (this.visible) {
                this.initValues();
            }
        });
    }

    private async initValues(): Promise<void> {
        this.shopCurrent = this.shop;
        this.stage = null;
        this.discount = null;
        this.comment = null;
        this.seller = null;
        this.goodsInit = [];
        this.goodItems = [];
        this.total = 0.0;

        this.company = await this.$info.getCompany();
        this.user = await this.$info.getUser();

        this.saleBlockInfoHandler.clean();

        // if (this.editMode) {
        //     const shopId = this.sale?.shop;
        //     const stageId = this.sale?.stage;
        //     const sellerId = this.sale?.sellerRef?.id;

        //     this.shopCurrent = this.shops.find(s => s.id === shopId) ?? null;
        //     this.stage = this.stages.find(a => a.id === stageId) ?? null;
        //     this.discount = this.sale?.info?.discount ?? null;
        //     this.comment = this.sale?.info?.comment ?? null;
        //     this.seller = this.employees.find(e => e.user === sellerId) ?? null;
        //     this.goodsInit = this.sale?.goods ?? [];
        //     this.updateTotal();
        // }

        this.updateTable();
        this.updateTotal();
    }

    private async confirmCreate(): Promise<void> {
        if (this.goodItems.length === 0) {
            this.$alt.toast.warning("Нет ни одного товара для продажи.");
            return;
        }

        const result = await this.$alt.message.confirm("Сохранить текущие изменения?", "Создание продажи", {
            okText: "Сохранить",
        });

        if (result) {
            await this.create();
        }
    }

    private async startComplete(): Promise<void> {
        if (!this.handler.completeModal) {
            return;
        }

        if (this.goodItems.length === 0) {
            this.$alt.toast.warning("Нет ни одного товара для продажи.");
            return;
        }

        const documents = await this.$settings.getDocumentsSaleCreate();
        const documentsSelected = this.documents.filter(d => documents.includes(d.id));

        const context = {
            paidSum: 0.0,
            actualPrice: this.goodItems.reduce((sum, item) => sum + item.quantity * item.price, 0.0),
            discount: this.discount ?? undefined,
            shop: this.shopCurrent ?? undefined,
            stages: this.stages,
            accounts: this.accounts,
            documents: this.documents,
            documentsSelected: documentsSelected,
        };

        const result = await this.handler.completeModal.show(context);

        if (result?.answer === SaleCompleteModalAnswer.Complete && result.data) {
            await this.createAndComplete(result.data);
        }
    }

    private async create(): Promise<void> {
        try {
            this.$alt.loader.show();

            this.updateTotal();

            const shopId = this.getShopId();
            const saleDto = this.getSaleDto();
            const goodsDto = this.getGoodsDto();

            const sale = await this.SaleUseCase.create(this.company.id, shopId, saleDto, goodsDto);
            this.$emit("created", sale);
            this.close();

            this.$alt.toast.success("Продажа успешно создана.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async createAndComplete(dto: ISaleCompleteModalData): Promise<boolean> {
        try {
            this.$alt.loader.show();

            this.updateTotal();

            const shopId = this.getShopId();
            const saleDto = this.getSaleDto();
            if (!saleDto.info) {
                saleDto.info = {};
            }
            saleDto.info.discount = dto.discount;
            saleDto.stage = dto.stage.id;
            saleDto.done = true;

            const goodsDto = this.getGoodsDto();
            const paymentDto = this.getPaymentDto(dto.amount, dto.account);

            const sale = await this.SaleUseCase.create(this.company.id, shopId, saleDto, goodsDto, paymentDto);

            this.$emit("created", sale);
            this.handler.onPrintDocuments?.(dto.documents, sale);
            this.close();

            this.$alt.toast.success("Продажа успешно создана.");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private getShopId(): string {
        if (!this.shopCurrent) {
            throw new AppException("Магазин не выбран.");
        }

        return this.shopCurrent.id;
    }

    private getSaleDto(): ISaleCreateDto {
        if (this.goodItems.length === 0) {
            throw new AppException("Нет ни одного товара для продажи.");
        }

        if (!this.shopCurrent) {
            throw new AppException("Магазин не выбран.");
        }

        if (!this.seller) {
            throw new AppException("Продавец не выбран.");
        }

        if (!this.stage) {
            throw new AppException("Счёт не выбран.");
        }

        const infoDto = this.saleBlockInfoHandler.getInfoDto();
        //const customDto = this.saleBlockInfoHandler.getCustomDto();

        return {
            info: {
                createDate: new Date(),
                discount: infoDto.discount,
                comment: infoDto.comment,
            },
            stage: this.stage.id,
            seller: this.seller.user,
        };
    }

    private getGoodsDto(): ISaleGoodDto[] {
        const goodsDto: ISaleGoodDto[] = [];

        for (const item of this.goodItems) {
            const dto = item.goodRef ? this.getGoodFromStoreDto(item) : this.getGoodCreateDto(item);
            goodsDto.push(dto);
        }

        return goodsDto;
    }

    private getGoodCreateDto(item: ISaleGoodItem): ISaleGoodDto {
        const createDto: ISaleGoodCreateDto = {
            sku: item.sku,
            name: item.name,
            description: item.description,
            cost: item.cost,
            price: item.price,
            quantity: item.quantity,
            warranty: item.warranty,

            store: item.storeId,
        };

        let paymentDto: ISaleGoodPaymentDto | undefined;
        if (item.accountId) {
            paymentDto = {
                account: item.accountId,
                description: "Покупка товара",
            };
        }

        return {
            create: createDto,
            payment: paymentDto,
        };
    }

    private getGoodFromStoreDto(item: ISaleGoodItem): ISaleGoodDto {
        if (!item.storeId || !item.goodId) {
            throw new AppException(`Не указан склад в товаре "${item.name}".`);
        }

        const fromStoreDto: ISaleGoodFromStoreDto = {
            price: item.price,
            quantity: item.quantity,
            warranty: item.warranty,

            store: item.storeId,
            good: item.goodId,
        };

        return {
            fromStore: fromStoreDto,
        };
    }

    private getPaymentDto(amount: number, account: IAccount): ISalePaymentCreateDto {
        return {
            type: PaymentType.Payment,
            accountId: account.id,
            amount: amount,
            description: "Оплата в магазине",
            userId: this.user.id,
        };
    }

    private changeShop(shop: IShop): void {
        this.shopCurrent = shop;
    }

    private changeStage(stage: ISaleStage): void {
        this.stage = stage;
    }

    private changeDiscount(discount: IDiscount): void {
        this.discount = discount;
        this.updateTotal();
        this.updateTable();
    }

    private changeComment(comment: string): void {
        this.comment = comment;
    }

    private changeSeller(seller: IEmployee): void {
        this.seller = seller;
    }

    private changeGoods(items: ISaleGoodItem[]): void {
        this.goodItems = items;
        this.updateTotal();
    }

    private updateTotal(): number {
        let actual = 0.0;
        for (const good of this.goodItems) {
            actual += good.price * good.quantity;
        }

        let discountTotal = 0.0;
        if (this.discount?.type === DiscountType.Percent) {
            discountTotal = (actual * this.discount.value) / 100;
        } else if (this.discount?.type === DiscountType.Fixed) {
            discountTotal = this.discount.value;
        }

        this.total = actual - discountTotal;

        return this.total;
    }

    private updateTable(): void {
        this.updateTableKey += 1;
    }
}
