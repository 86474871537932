import { ISms } from "@lib";

export function getTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.create && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (sms: ISms) => context.openModalUpdate(sms),
                        },
                        {
                            id: "copy",
                            description: "Скопировать",
                            icon: "CopyIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.create,
                            click: (sms: ISms) => context.confirmCopy(sms),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (sms: ISms) => context.confirmDelete(sms),
                        },
                    ],
                };
            },
        },
        {
            colId: "number",
            field: "number",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,
        },
        /*{
            colId: "type",
            field: "type",
            headerName: "Тип",
            sortable: true,
            filter: true,
            width: 120,
            hide: false,

            cellRenderer: function(params: any) {
                const type = getSmsTypes().find(type => type.id === params.value);
                return type ? type.name : "-";
            },
        },*/
        {
            colId: "name",
            field: "name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "description",
            field: "description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 400,
            hide: false,
        },
        // {
        //     colId: "template",
        //     headerName: "Шаблон",
        //     width: 130,
        //     hide: false,
        //     headerClass: "text-transparent",

        //     cellRendererFramework: "CellRendererChip",
        //     cellRendererParams: (params: any) => {
        //         return {
        //             color: "primary",
        //             name: "Шаблон",
        //             //click: () => { $router.push({ name: "clients", params: { id: client.id } }).catch(() => {}); }
        //             click: () => clickTemplateFunc(params.data)
        //         };
        //     }
        // }
    ];
}
