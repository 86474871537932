import { Control, DateRange, IconPackage, MultiSelect, Select } from "@/@core/components/alt-ui/controls";
import { Footer } from "@/@core/controls/footer.control";
import { FilterController } from "@/@core/filters/filter-controller";
import { getPeriodDates, getPeriods, ISelectOption, Period } from "@/@core/types/common/select-options";
import { FilterType, FilterPair } from "@/utils/filter";
import { EmployeeOrderSaleReportScope, IAccount, IEmployee, IShop, IStore, ITableFilter, IUser } from "@lib";
import { IReport } from "./reports/report";
import { BaseChartReport } from "./reports/base-chart.report";
import { BaseTableReport } from "./reports/base-table.report";
import { OrdersTableReport } from "./reports/orders-table.report";
import { SalesTableReport } from "./reports/sales-table.report";
import { FinancesTableReport } from "./reports/finances-table.report";
import { FinancesItemsTableReport } from "./reports/finances-items-table.report";
import { ClientSourcesChartReport } from "./reports/client-sources-chart.report";
import { EmployeeOrdersTableReport } from "./reports/employee-orders-table.report";
import { StoreTableReport } from "./reports/store-table.report";
import { SaleGoodsTableReport } from "./reports/sale-goods-table.report";

export type ReportFilter = Partial<{
    type: string;
    period: string;
    dates: FilterPair;
    account?: string;
    employee?: string;
    employeeReportScope?: EmployeeOrderSaleReportScope;
    stores?: string[];
    shops?: string[];
}>;

export interface IReportFilterContext {
    accounts: IAccount[];
    employees: IEmployee[];
    user: IUser;
    stores: IStore[];
    shops: IShop[];

    defaultFilter: ReportFilter;
    currentFilter: ITableFilter[];
}

export enum ReportType {
    BaseChart = "base-chart",
    BaseTable = "base-table",
    OrdersTable = "orders-table",
    SalesTable = "sales-table",
    EmployeeTable = "employee-table",
    FinancesTable = "finances-table",
    FinancesItemsTable = "finances-items-table",
    ClientSourcesChart = "client-sources-chart",
    StoreTable = "store-table",
    SaleGoodsTable = "sale-goods-table",
}

export const EmployeeOrdersReportScopeOptions: ISelectOption<EmployeeOrderSaleReportScope>[] = [
    { id: EmployeeOrderSaleReportScope.Orders, name: "Заявки" },
    { id: EmployeeOrderSaleReportScope.Sales, name: "Продажи" },
    { id: EmployeeOrderSaleReportScope.OrdersAndSales, name: "Заявки и продажи" },
];

export enum ReportKind {
    Table = "table",
    Chart = "chart",
}

export const ReportTypeOptions: ISelectOption<ReportType>[] = [
    { id: ReportType.BaseTable, name: "Основные показатели (таблица)" },
    { id: ReportType.BaseChart, name: "Основные показатели (график)" },
    { id: ReportType.OrdersTable, name: "Отчёт по заявкам" },
    { id: ReportType.SalesTable, name: "Отчёт по продажам" },
    { id: ReportType.EmployeeTable, name: "Отчёт по заявкам/продажам сотрудника" },
    { id: ReportType.FinancesTable, name: "Движение денежных средств" },
    { id: ReportType.FinancesItemsTable, name: "Движение денежных средств по статьям" },
    { id: ReportType.ClientSourcesChart, name: "Источники клиентов (диаграмма)" },
    { id: ReportType.StoreTable, name: "Отчёт по складам" },
    { id: ReportType.SaleGoodsTable, name: "Отчёт по проданным товарам" },
];

export interface IReportRegistryItem {
    type: ReportType;
    report: IReport;
}

export function getReportRegistry(): IReportRegistryItem[] {
    return [
        {
            type: ReportType.BaseChart,
            report: new BaseChartReport(),
        },
        {
            type: ReportType.BaseTable,
            report: new BaseTableReport(),
        },
        {
            type: ReportType.OrdersTable,
            report: new OrdersTableReport(),
        },
        {
            type: ReportType.SalesTable,
            report: new SalesTableReport(),
        },
        {
            type: ReportType.FinancesTable,
            report: new FinancesTableReport(),
        },
        {
            type: ReportType.FinancesItemsTable,
            report: new FinancesItemsTableReport(),
        },
        {
            type: ReportType.ClientSourcesChart,
            report: new ClientSourcesChartReport(),
        },
        {
            type: ReportType.EmployeeTable,
            report: new EmployeeOrdersTableReport(),
        },
        {
            type: ReportType.StoreTable,
            report: new StoreTableReport(),
        },
        {
            type: ReportType.SaleGoodsTable,
            report: new SaleGoodsTableReport(),
        },
    ];
}

export class ReportFilterController extends FilterController<IReportFilterContext, void> {
    public id: string = "report-filter";
    public title: string = "Отчёт";

    public filter = {
        type: {
            control: new Select<ISelectOption<ReportType>>(),

            type: FilterType.Equals,

            get: (): string => {
                return this.filter.type.control.selectedItem?.id ?? ReportType.BaseChart;
            },
            set: (id: string): void => {
                const types = ReportTypeOptions;
                const type = types.find(e => e.id === id) as ISelectOption<ReportType>;
                const defaultType = types.find(e => e.id === ReportType.BaseChart) as ISelectOption<ReportType>;

                this.filter.type.control.selectedItem = type ?? defaultType;
            },
        },
        period: {
            control: new Select<ISelectOption<Period>>(),

            type: FilterType.Equals,

            get: (): string => {
                return this.filter.period.control.selectedItem?.id ?? Period.Custom;
            },
            set: (id: string): void => {
                const periods = getPeriods();
                const period = periods.find(e => e.id === id) as ISelectOption<Period>;
                const customPeriod = periods.find(e => e.id === Period.Custom) as ISelectOption<Period>;

                this.filter.period.control.selectedItem = period ?? customPeriod;
            },
        },
        dates: {
            control: new DateRange(),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.dates.control.value;
            },
            set: (date: FilterPair): void => {
                if (this.filter.period.get() !== Period.Custom) {
                    return;
                }

                this.filter.dates.control.value = date;
            },
        },
        account: {
            control: new Select<IAccount>(),

            type: FilterType.Equals,

            get: (): string => {
                return this.filter.account.control.selectedItem?.id ?? "";
            },
            set: (id: string): void => {
                const account = this.context.accounts.find(e => e.id === id);

                this.filter.account.control.selectedItem = account ?? this.context.accounts[0];
            },
        },
        employee: {
            control: new Select<IEmployee>(),

            type: FilterType.Equals,

            get: (): string => {
                return this.filter.employee.control.selectedItem?.user ?? "";
            },
            set: (id: string): void => {
                const employee = this.context.employees.find(e => e.user === id);

                this.filter.employee.control.selectedItem = employee ?? this.context.employees[0];
            },
        },
        employeeReportScope: {
            control: new Select<ISelectOption<EmployeeOrderSaleReportScope>>(),

            type: FilterType.Equals,

            get: (): string => {
                return this.filter.employeeReportScope.control.selectedItem?.id ?? "";
            },
            set: (id: string): void => {
                const employeeReportScope = EmployeeOrdersReportScopeOptions.find(e => e.id === id);

                this.filter.employeeReportScope.control.selectedItem =
                    employeeReportScope ?? EmployeeOrdersReportScopeOptions[0];
            },
        },
        stores: {
            control: new MultiSelect<IStore>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.stores.control.selectedItems.map(store => store.id);
            },
            set: (ids: string[]): void => {
                const stores = this.context.stores.filter(s => ids.includes(s.id));

                this.filter.stores.control.selectedItems = stores;
            },
        },
        shops: {
            control: new MultiSelect<IShop>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.shops.control.selectedItems.map(shop => shop.id);
            },
            set: (ids: string[]): void => {
                const shops = this.context.shops.filter(s => ids.includes(s.id));

                this.filter.shops.control.selectedItems = shops;
            },
        },
    };

    private ftFooter!: Footer;

    private context!: IReportFilterContext;

    public onSave: ((filter: ITableFilter[]) => Promise<boolean>) | null = null;

    constructor() {
        super();
    }

    public init(context: IReportFilterContext): void {
        this.context = context;
        this.initControls();
        this.initFooter();
        this.populateFilters();
    }

    public show(context: IReportFilterContext): Promise<void> {
        this.init(context);
        return super.show(context);
    }

    // Init

    private initControls(): void {
        this.filter.type.control = new Select<ISelectOption<ReportType>>();
        this.filter.type.control.id = this.getControlId("type");
        this.filter.type.control.label = "Тип отчёта";
        this.filter.type.control.items = ReportTypeOptions;
        this.filter.type.control.textField = e => e.name;
        this.filter.type.control.addChangedHandler((_, event) => {
            const type = event.item;
            if (!type) {
                return;
            }

            this.filter.period.control.visible = ReportType.StoreTable !== type.id;
            this.filter.dates.control.visible = ReportType.StoreTable !== type.id;

            this.filter.account.control.visible = [ReportType.FinancesTable, ReportType.FinancesItemsTable].includes(
                type.id,
            );

            this.filter.employee.control.visible = ReportType.EmployeeTable === type.id;
            this.filter.employeeReportScope.control.visible = ReportType.EmployeeTable === type.id;

            this.filter.stores.control.visible = ReportType.StoreTable === type.id;

            this.filter.shops.control.visible = ReportType.SaleGoodsTable === type.id;
        });

        this.filter.period.control = new Select<ISelectOption<Period>>();
        this.filter.period.control.id = this.getControlId("period");
        this.filter.period.control.label = "Период";
        this.filter.period.control.items = getPeriods();
        this.filter.period.control.textField = e => e.name;
        this.filter.period.control.addChangedHandler((_, event) => {
            const period = event.item;
            if (!period) {
                return;
            }

            this.filter.dates.control.disabled = period.id !== Period.Custom;

            const dates = getPeriodDates(period.id);
            if (dates) {
                this.filter.dates.control.value = dates;
            }
        });

        this.filter.dates.control = new DateRange();
        this.filter.dates.control.id = this.getControlId("dates");
        this.filter.dates.control.label = "Даты";
        this.filter.dates.control.addValueChangedHandler((_, event) => {
            if (!event.to || !event.from) {
                const now = new Date();
                const currentDate = `${now.getFullYear()}-${(now.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${now.getDate()}`;

                this.filter.dates.control.value = [currentDate, currentDate];
            }
        });

        this.filter.account.control = new Select<IAccount>();
        this.filter.account.control.id = this.getControlId("account");
        this.filter.account.control.label = "Счёт";
        this.filter.account.control.items = this.context?.accounts ?? [];
        this.filter.account.control.textField = e => e.info.name;
        this.filter.account.control.visible = false;

        this.filter.employee.control = new Select<IEmployee>();
        this.filter.employee.control.id = this.getControlId("employee");
        this.filter.employee.control.label = "Сотрудник";
        this.filter.employee.control.items = this.context?.employees ?? [];
        this.filter.employee.control.textField = e => e.userRef.info.name;
        this.filter.employee.control.iconPackage = IconPackage.Feater;
        this.filter.employee.control.icon = "UserIcon";
        this.filter.employee.control.selectedIndex = this.context.employees.findIndex(
            e => e.user === this.context?.user.id,
        );
        this.filter.employee.control.visible = false;

        this.filter.employeeReportScope.control = new Select<ISelectOption<EmployeeOrderSaleReportScope>>();
        this.filter.employeeReportScope.control.id = this.getControlId("employeeReportScope");
        this.filter.employeeReportScope.control.label = "Тип выборки";
        this.filter.employeeReportScope.control.items = EmployeeOrdersReportScopeOptions;
        this.filter.employeeReportScope.control.textField = e => e.name;
        this.filter.employeeReportScope.control.selectedIndex = 0;
        this.filter.employeeReportScope.control.visible = false;

        this.filter.stores.control = new MultiSelect<IStore>();
        this.filter.stores.control.id = this.getControlId("stores");
        this.filter.stores.control.label = "Склады";
        this.filter.stores.control.items = this.context?.stores ?? [];
        this.filter.stores.control.textField = s => s.info.name;
        this.filter.stores.control.visible = false;
        this.filter.stores.control;

        this.filter.shops.control = new MultiSelect<IShop>();
        this.filter.shops.control.id = this.getControlId("shops");
        this.filter.shops.control.label = "Магазины";
        this.filter.shops.control.items = this.context?.shops ?? [];
        this.filter.shops.control.textField = s => s.info.name;
        this.filter.shops.control.visible = false;
        this.filter.shops.control;
    }

    private initFooter(): void {
        this.ftFooter = new Footer({
            okText: "Сгенерировать",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateFilters(): void {
        if (this.context.defaultFilter) {
            Object.entries(this.context.defaultFilter).forEach(([k, filter]) => {
                const key = k as keyof ReportFilter;

                if (!this.filter[key]) {
                    return;
                }

                this.filter[key].set(filter as any);
            });
        }

        if (!this.context.currentFilter) {
            return;
        }

        const filters = this.getLocalFilter(this.context.currentFilter);

        Object.entries(filters).forEach(([k, filter]) => {
            const key = k as keyof ReportFilter;

            if (!this.filter[key]) {
                return;
            }

            this.filter[key].set(filter as any);
        });
    }

    public get controls(): Control[] {
        return Object.entries(this.filter).map(([_, filter]) => filter.control);
    }

    public get footer(): Control {
        return this.ftFooter;
    }

    // Init

    public get tableFilter(): ITableFilter[] {
        return this.getTableFilter(this.filter);
    }

    public get localFilter(): ReportFilter {
        return this.getLocalFilter(this.tableFilter);
    }

    public async clickSave(): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        if (!this.onSave) {
            return;
        }

        const result = await this.onSave(this.tableFilter);

        if (result) {
            this.hide();
        }
    }

    public clickCancel(): void {
        this.hide();
    }
}
