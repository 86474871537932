import { Vue, Component, Prop } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { ICompany, ISale } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { TextBox } from "@core/components/alt-ui/controls";
import { SalePrintContext } from "@core/usecases/template/macro-replacers/sale-document.macro-replacer";
import { Printer } from "@core/usecases/template/printer";

@Component({
    name: "sale-view-title",
    components: { BButton, ValidationObserver, ControlComponent },
})
export default class SaleViewTitle extends Vue {
    @Prop({ required: true })
    private title!: string;

    @Prop({ required: true })
    private sale!: ISale | null;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private company!: ICompany;
    private editMode: boolean = false;
    private tbNumber = new TextBox();

    public async mounted(): Promise<void> {
        await this.initValues();
    }

    private async initValues(): Promise<void> {
        this.company = await this.$info.getCompany();
    }

    private getNumberString(): string {
        if (!this.sale) {
            return "";
        }

        if (!this.sale.shopRef) {
            return this.sale.number.toString();
        }

        const printContext: SalePrintContext = {
            company: this.company,
            shop: this.sale.shopRef,
            sale: this.sale,
        };

        return Printer.replaceSaleDocumentsGeneralMacros("%(Продажа.Номер)", printContext);
    }

    private editNumber(): void {
        if (!this.readonly && this.sale && !this.sale.doneAt && !this.editMode) {
            this.tbNumber = new TextBox();
            this.tbNumber.id = "sale-view-title.number";
            this.tbNumber.validation = "required|numeric";
            this.tbNumber.text = this.sale.number.toString() ?? "0";

            this.editMode = true;
        }
    }

    private async applyChanges(): Promise<void> {
        try {
            const result = await (this.$refs["saleTitleValidator"] as any).validate();
            if (!result) {
                return;
            }

            if (!this.sale) {
                throw new Error("Продажа не задана.");
            }

            const companyId = this.sale.company;
            const saleId = this.sale.id;
            const number = parseInt(this.tbNumber.text);

            const usecase = this.$alt.system.usecase.createSaleUseCase();
            const sale = await usecase.changeNumber(companyId, saleId, number);

            this.editMode = false;
            this.$emit("sale-changed", sale);
        } catch {
            this.$alt.toast.error("Не удалось изменить номер продажи.");
        }
    }

    private cancelChanges(): void {
        this.editMode = false;
    }
}
