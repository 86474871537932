import { UseCaseFactory } from "@core/usecases/usecase.factory";
import parsePhoneNumber from "libphonenumber-js";

export default {
    validateEmployeeEmailRule: {
        //message: (data && data.message) || "Email is already in use.",
        getMessage(field: any, params: any, data: any) {
            return (data && data.message) || "Почта уже занята.";
        },
        async validate(value: string, oldEmails: string[]) {
            // https://codesandbox.io/s/c5s7m
            try {
                // TODO: добавить задержку при обращении к серверу

                value = value.toLowerCase();
                if (oldEmails.includes(value)) {
                    return { valid: true };
                }

                const taken = await new UseCaseFactory().createUserUseCase().isEmailTaken(value);
                return {
                    valid: !taken,
                    data: { message: taken ? "Почта уже занята." : "" },
                };
            } catch {
                return {
                    valid: false,
                    data: { message: "Не удалось проверить адрес." },
                };
            }
        },
    },

    validatePhoneNumber: {
        getMessage(field: any, params: any, data: any) {
            return "Неверный формат телефона";
        },

        async validate(value: string) {
            const phoneNumber = parsePhoneNumber(value, {
                defaultCountry: "RU",
                extract: false,
            });

            if (!phoneNumber) {
                return {
                    valid: false,
                };
            }

            return {
                valid: phoneNumber.isValid(),
            };
        },
    },
};
