import { IShop, IShopCreateDto, IShopUpdateDto, Currency, Locale } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Label, Panel, Select, TextBox, TextChangedEventArgs } from "@core/components/alt-ui/controls";
import { getCurrencies, getLocales, ISelectOption } from "@core/types/common/select-options";
import { PhoneControl } from "@core/controls/phone/phone-control";
import {
    createDemoCompany,
    createDemoSale,
    createDemoShop,
} from "@core/controls/document-template-editor/preview-data";
import { Printer } from "@/@core/usecases/template/printer";
import { SalePrintContext } from "@/@core/usecases/template/macro-replacers/sale-document.macro-replacer";

export class ShopModal extends Modal<IShop> {
    private tbName!: TextBox;
    private tbDescription!: TextBox;
    private tbEmail!: TextBox;
    private phPhone!: PhoneControl;
    private tbAddress!: TextBox;
    private cbCurrency!: Select<ISelectOption>;
    private cbLocale!: Select<ISelectOption>;
    private tbNumerationTemplate!: TextBox;
    private lblNumerationExample!: Label;
    private pnlNumerationTemplate!: Panel;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private demo = {
        company: createDemoCompany(),
        shop: createDemoShop(),
        sale: createDemoSale(),
    };

    private shop: IShop | null = null;
    public onCreate: ((dto: IShopCreateDto) => Promise<IShop | null>) | null = null;
    public onUpdate: ((shop: IShop, dto: IShopUpdateDto) => Promise<IShop | null>) | null = null;

    public constructor() {
        super("shop-modal", "");
        this.initializeControls();
    }

    public show(shop?: IShop): Promise<void> {
        this.shop = shop ?? null;
        this.title = shop ? "Изменение магазина" : "Новый магазин";
        this.initializeControls();

        if (shop) {
            this.populateControls(shop);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();
        this.tbEmail = new TextBox();
        this.phPhone = new PhoneControl();
        this.tbAddress = new TextBox();
        this.cbCurrency = new Select<ISelectOption>();
        this.cbLocale = new Select<ISelectOption>();
        this.tbNumerationTemplate = new TextBox();
        this.lblNumerationExample = new Label();
        this.pnlNumerationTemplate = new Panel();
        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbName.id = "shops.name";
        this.tbName.label = "Наименование";
        this.tbName.validation = "required";
        this.tbName.text = "";

        this.tbDescription.id = "shops.description";
        this.tbDescription.label = "Описание";
        this.tbDescription.text = "";

        this.tbEmail.id = "shops.email";
        this.tbEmail.label = "Почта";
        this.tbEmail.validation = "email";
        this.tbEmail.text = "";

        this.phPhone.id = "shops.phone";
        this.phPhone.label = "Телефон";
        this.phPhone.text = "";

        this.tbAddress.id = "shops.address";
        this.tbAddress.label = "Адрес";
        this.tbAddress.text = "";

        this.cbCurrency.id = "shops.currency";
        this.cbCurrency.label = "Валюта";
        this.cbCurrency.items = getCurrencies();
        this.cbCurrency.textField = (opt: ISelectOption) => opt.name;
        this.cbCurrency.selectedIndex = 0;

        this.cbLocale.id = "shops.locale";
        this.cbLocale.label = "Языковой стандарт";
        this.cbLocale.help =
            "Определяет формат чисел, денег и др.\nНапример, отделение дробной части в числе с помощью запятой или точки и т.п.";
        this.cbLocale.items = getLocales();
        this.cbLocale.textField = (opt: ISelectOption) => opt.name;
        this.cbLocale.descriptionField = (opt: ISelectOption) => opt.description;
        this.cbLocale.selectedIndex = 0;

        this.tbNumerationTemplate.id = "shops.numerationTemplate";
        this.tbNumerationTemplate.label = "Шаблон номера продажи";
        this.tbNumerationTemplate.help =
            "Шаблон для нумерации продаж в таблице и документах. Можно использовать макросы продажи, например, %(Продажа.Номер).";
        this.tbNumerationTemplate.addTextChangedHandler(this.changeNumerationTemplate.bind(this));
        this.tbNumerationTemplate.text = "%(Продажа.Номер)";

        this.lblNumerationExample.id = "shops.numerationExample";
        this.lblNumerationExample.class = "text-sm -mt-0.5";

        this.pnlNumerationTemplate.addControls([this.tbNumerationTemplate, this.lblNumerationExample]);

        //

        this.btnCancel.id = "shops.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "shops.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(shop: IShop): void {
        this.tbName.text = shop.info.name;
        this.tbDescription.text = shop.info.description ?? "";
        this.tbEmail.text = shop.info.contacts?.email ?? "";
        const numerationTemplate = shop.settings?.numerationTemplate;
        this.tbNumerationTemplate.text =
            numerationTemplate && numerationTemplate.length > 0 ? numerationTemplate : "%(Продажа.Номер)";
        this.phPhone.text = shop.info.contacts?.phone ?? "";
        this.tbAddress.text = shop.info.contacts?.address ?? "";

        const currencyIndex = this.cbCurrency.items.findIndex(i => i.id === shop.info.currency);
        this.cbCurrency.selectedIndex = currencyIndex >= 0 ? currencyIndex : 0;

        const localeIndex = this.cbLocale.items.findIndex(i => i.id === shop.info.locale);
        this.cbLocale.selectedIndex = localeIndex >= 0 ? localeIndex : 0;
    }

    public get controls(): Control[] {
        return [
            this.tbName,
            this.tbDescription,
            this.tbEmail,
            this.phPhone,
            this.tbAddress,
            this.cbCurrency,
            this.cbLocale,
            this.pnlNumerationTemplate,
            this.pnlFooter,
        ];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = this.shop ? await this.updateShop(this.shop) : await this.createShop();

        if (result) {
            this.hide(result);
        }
    }

    private async createShop(): Promise<IShop | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: IShopCreateDto = {
            info: {
                name: this.tbName.text,
                //shortName: shop.info.shortName,
                description: this.tbDescription.text,
                //avatar: shop.info.avatar,
                currency: this.cbCurrency.selectedItem?.id as Currency,
                locale: this.cbLocale.selectedItem?.id as Locale,
                contacts: {
                    email: this.tbEmail.text,
                    phone: this.phPhone.text,
                    address: this.tbAddress.text,
                },
            },
            settings: {
                numerationTemplate: this.tbNumerationTemplate.text,
            },
        };

        return await this.onCreate(dto);
    }

    private async updateShop(shop: IShop): Promise<IShop | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IShopUpdateDto = {
            info: {
                name: this.tbName.text,
                //shortName: shop.info.shortName,
                description: this.tbDescription.text,
                //avatar: shop.info.avatar,
                currency: this.cbCurrency.selectedItem?.id as Currency,
                locale: this.cbLocale.selectedItem?.id as Locale,
                contacts: {
                    email: this.tbEmail.text,
                    phone: this.phPhone.text,
                    address: this.tbAddress.text,
                },
            },
            settings: {
                numerationTemplate: this.tbNumerationTemplate.text,
            },
        };

        return await this.onUpdate(shop, dto);
    }

    private changeNumerationTemplate(sender: any, e: TextChangedEventArgs): void {
        let template = this.tbNumerationTemplate.text.trim();
        if (template.length === 0) {
            template = "%(Продажа.Номер)";
        }

        const printContext: SalePrintContext = {
            company: this.demo.company,
            shop: this.demo.shop,
            sale: this.demo.sale,
        };

        const example = Printer.replaceSaleDocumentsGeneralMacros(template, printContext);
        this.lblNumerationExample.text = `Пример: Продажа № ${example}`;
    }
}
