
































import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormInput, VBTooltip } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { AsYouType } from "libphonenumber-js";
import { Validator } from "vee-validate";
import validators from "@/views/main/settings/settings-company/modals/validators";
import { PhoneControl } from "./phone-control";
import parsePhoneNumber from "libphonenumber-js";

Validator.extend("phone-number", validators.validatePhoneNumber);

@Component({
    components: {
        BFormInput,
        ValidationProvider,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
})
export default class PhoneControlComponent extends Vue {
    @Prop({ type: Object })
    private handler!: PhoneControl;

    get formattedPhone() {
        return this.format(this.handler.text);
    }

    set formattedPhone(value: string) {
        this.handler.text = value.replace(/\s/g, "");
    }

    public get validationWithDefault() {
        const defaultValidation = "phone-number";

        if (this.handler.validation) {
            return defaultValidation + ":" + this.handler.validation;
        }

        return defaultValidation;
    }

    private format(value: string) {
        if (/[^+\d\s]/g.test(value)) {
            return value;
        }

        const formatter = new AsYouType({
            defaultCountry: "RU",
        });

        const defaultPrefix = "+";

        const possibleNeedPrefix = value.length === 1;
        const shouldAddPrefix = possibleNeedPrefix && !value.startsWith(defaultPrefix) && !value.startsWith("8");

        if (shouldAddPrefix) {
            return formatter.input(defaultPrefix + value);
        }

        const phoneNumber = parsePhoneNumber(value, {
            defaultCountry: "RU",
            extract: false,
        });

        if (!phoneNumber) {
            return value;
        }

        return formatter.input(value);
    }

    public onPaste(event: ClipboardEvent) {
        const text = event.clipboardData?.getData("text");

        if (!text) {
            return;
        }

        this.formattedPhone = "";
        this.formattedPhone = text.trim();

        const input = this.$refs.input as any;

        if (input) {
            input.$el.setSelectionRange(0, this.formattedPhone.length);
        }
    }
}
