import { ISalaryUseCase, ISalary, ISalaryCreateReservedDto, ISalaryDeleteDto } from "@lib";
import { OrderWithSalaries, SaleWithSalaries } from "@lib";
import { ISalarySelectDto, OrderWithSalariesDto, SaleWithSalariesDto } from "@lib";
import { ISalaryPayDto, ISalaryPayManyDto, ISalaryPaymentDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class SalaryUseCase implements ISalaryUseCase {
    public async selectPaid(companyId: string, employeeId: string, dto: ISalarySelectDto): Promise<ISalary[]> {
        try {
            const params = dto;
            const response = await Api.get<ISalary[]>(`/companies/${companyId}/employees/${employeeId}/salary/paid`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectReserved(companyId: string, employeeId: string): Promise<ISalary[]> {
        try {
            const response = await Api.get<ISalary[]>(
                `/companies/${companyId}/employees/${employeeId}/salary/reserved`,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectOrders(
        companyId: string,
        employeeId: string,
        dto: ISalarySelectDto,
    ): Promise<OrderWithSalariesDto[]> {
        try {
            const params = dto;
            const response = await Api.get<OrderWithSalaries[]>(
                `/companies/${companyId}/employees/${employeeId}/salary/orders`,
                { params },
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectSales(
        companyId: string,
        employeeId: string,
        dto: ISalarySelectDto,
    ): Promise<SaleWithSalariesDto[]> {
        try {
            const params = dto;
            const response = await Api.get<SaleWithSalaries[]>(
                `/companies/${companyId}/employees/${employeeId}/salary/sales`,
                { params },
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createReserved(
        companyId: string,
        employeeId: string,
        dto: ISalaryCreateReservedDto,
    ): Promise<ISalary> {
        try {
            const response = await Api.post<ISalary>(
                `/companies/${companyId}/employees/${employeeId}/salary/reserved`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async payOne(
        companyId: string,
        employeeId: string,
        salaryDto: ISalaryPayDto,
        paymentDto: ISalaryPaymentDto,
    ): Promise<ISalary> {
        try {
            const dto = { salary: salaryDto, payment: paymentDto };
            const response = await Api.post<ISalary>(`/companies/${companyId}/employees/${employeeId}/salary/pay`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async payMany(
        companyId: string,
        employeeId: string,
        salariesDto: ISalaryPayManyDto,
        paymentDto: ISalaryPaymentDto,
    ): Promise<ISalary> {
        try {
            const dto = { salaries: salariesDto, payment: paymentDto };
            const response = await Api.post<ISalary>(
                `/companies/${companyId}/employees/${employeeId}/salary/pay-many`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async payReserved(
        companyId: string,
        employeeId: string,
        salaryId: string,
        paymentDto: ISalaryPaymentDto,
    ): Promise<ISalary> {
        try {
            const dto = paymentDto;
            const response = await Api.put<ISalary>(
                `/companies/${companyId}/employees/${employeeId}/salary/${salaryId}/pay`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, employeeId: string, dto: ISalaryDeleteDto): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/employees/${employeeId}/salary/${dto.id}`, {
                data: dto,
            });
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
