import { IOrder, IOffice } from "@lib";
import { Currency, Locale } from "@lib";
import { getOrderSummary } from "./order-document.macro-list";
import {
    IOrderMacroInfo,
    IOrderTableContext,
    IOrderTableIterableContext,
    OrderTable2IterableMacroList,
    OrderTable2MacroList,
} from "./order-table2.macro-list";
import { Table2MacroReplacer } from "./table2.macro-replacer";

export class OrderTable2MacroReplacer extends Table2MacroReplacer {
    public readonly order: IOrder;
    public readonly currency: Currency;
    public readonly locale: Locale;
    public readonly info: IOrderMacroInfo;

    public constructor(order: IOrder) {
        super(OrderTable2MacroList, OrderTable2IterableMacroList);

        this.order = order;
        this.currency = order?.officeRef?.info?.currency ?? Currency.RUB;
        this.locale = order?.officeRef?.info?.locale ?? Locale.RU;
        this.info = getOrderSummary(order);
    }

    public getIterableContext(iterator: number): IOrderTableIterableContext {
        return {
            order: this.order,
            locale: this.locale,
            meta: {
                iterator,
            },
        };
    }

    public getContext(): IOrderTableContext {
        return {
            order: this.order,
            currency: this.currency,
            locale: this.locale,
            info: this.info,
        };
    }
}
