import { IPermission, PermissionCommonSection, PermissionRight, PermissionType } from "@lib";

export class SecurePlugin {
    private permissions: IPermission | null = null;

    public grant(permissions?: IPermission | null): void {
        this.permissions = permissions ?? null;
    }

    public reset(): void {
        this.permissions = null;
    }

    public isAdmin(): boolean {
        if (!this.permissions) {
            return false;
        }
        return !!this.permissions.admin;
    }

    public checkCommon(section: PermissionCommonSection, right: PermissionRight): boolean {
        const permissions = this.permissions as any;

        // permissions.admin
        // permissions.common.clients.read

        if (!permissions) {
            return false;
        }

        if (permissions.admin) {
            return true;
        }

        if (!permissions.common) {
            return false;
        }

        if (!permissions.common[section]) {
            return false;
        }

        return permissions.common[section][right];
    }

    public check(branchType: PermissionType, branchId: string, right: PermissionRight): boolean {
        const permissions = this.permissions as any;

        // permissions.admin
        // permissions.offices[id].admin
        // permissions.offices[id]["order-read"]

        if (!permissions) {
            return false;
        }

        if (permissions.admin) {
            return true;
        }

        if (!permissions[branchType]) {
            return false;
        }

        if (!permissions[branchType][branchId]) {
            return false;
        }

        if (permissions[branchType][branchId].admin) {
            return true;
        }

        return permissions[branchType][branchId][right];
    }
}
