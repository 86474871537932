import { ModalComponent } from "@core/components/alt-ui/modal";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormGroup, BFormInput, BFormTextarea, BInputGroupAppend, BButton } from "bootstrap-vue";
import { BListGroup, BListGroupItem, BCardText, BPagination } from "bootstrap-vue";
import { Currency, IAccount, IOffice, IOrder, IOrderPaymentCreateDto, IPayment, Locale } from "@lib";
import { OrderPaymentModal } from "./order-payment.modal";
import { Formatter } from "@/utils/formatter";

@Component({
    components: {
        BFormGroup,
        BFormTextarea,
        BFormInput,
        BButton,
        BInputGroupAppend,
        BListGroup,
        BListGroupItem,
        BCardText,
        BPagination,
        ModalComponent,
    },
})
export default class OrderViewPayments extends Vue {
    @Prop({ required: true })
    private order!: IOrder | null;

    @Prop({ type: Array, default: () => [] })
    private employees!: any[];

    @Prop({ type: Array, default: () => [] })
    private accounts!: IAccount[];

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private OrderPaymentUseCase = this.$alt.system.usecase.createOrderPaymentUseCase();
    private orderPaymentModal!: OrderPaymentModal;

    constructor() {
        super();

        this.orderPaymentModal = new OrderPaymentModal("order-view-tabs-payments-modal");
        this.orderPaymentModal.onCreate = this.createPayment.bind(this);
    }

    private get payments(): IPayment[] {
        if (!this.order || !this.order.payments) {
            return [];
        }

        return this.order.payments;
    }

    private get total(): number {
        if (!this.order || !this.order.payments) {
            return 0.0;
        }

        let sum = 0.0;
        for (const payment of this.order.payments) {
            sum += payment.value;
        }
        return sum;
    }

    private get currency(): Currency | undefined {
        return this.order?.officeRef?.info?.currency;
    }

    private get locale(): Locale | undefined {
        return this.order?.officeRef?.info?.locale;
    }

    private getEmployeeName(id: string): string {
        if (!this.employees) {
            return id;
        }
        const employee = this.employees.find((emp: any) => emp.user === id);
        return employee ? employee.userRef.info.name : id;
    }

    private onOrderChanged(order: IOrder): void {
        this.$emit("order-changed", order, this.order);
    }

    //

    public async showCreateModal(): Promise<void> {
        const user = await this.$info.getUser();
        await this.orderPaymentModal.show({
            accounts: this.accounts,
            userId: user.id,
        });
    }

    public async confirmPaymentDelete(payment: IPayment): Promise<void> {
        const money = Formatter.money(payment.value, { currency: this.currency, locale: this.locale });

        const confirm = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить платёж: "${payment.description}" (${money})?`,
            "Удаление платежа",
            { acceptText: "Удалить" },
        );

        if (confirm) {
            await this.deletePayment(payment);
        }
    }

    //

    private async createPayment(dto: IOrderPaymentCreateDto): Promise<IOrder | null> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const companyId = this.order.company;
            const officeId = this.order.office;
            const order = await this.OrderPaymentUseCase.create(companyId, officeId, this.order.id, dto);
            this.onOrderChanged(order);
            return order;
        } catch {
            this.$alt.toast.error("Не удалось добавить платёж.");
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async deletePayment(payment: IPayment): Promise<void> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const companyId = this.order.company;
            const officeId = this.order.office;
            const order = await this.OrderPaymentUseCase.delete(companyId, officeId, this.order.id, payment.id);
            this.onOrderChanged(order);
        } catch {
            this.$alt.toast.error("Не удалось удалить платёж.");
        } finally {
            this.$alt.loader.hide();
        }
    }
}
