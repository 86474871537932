import { Vue, Component } from "vue-property-decorator";
import { BBadge, VBTooltip } from "bootstrap-vue";
import {
    ISalary,
    IOrder,
    ISalarySelectDto,
    IEmployee,
    IAccount,
    ISettingsTableUpdateDto,
    OrderStageType,
    ITableFilter,
    TableType,
    PermissionType,
    PermissionRight,
    ISalaryPayManyDto,
    ISalaryPaymentDto,
    ISalaryCreateReservedDto,
    SalaryType,
    EmployeeSalaryType,
    IEmployeeSettingsUpdateDto,
    ISalaryDeleteDto,
    IUser,
    ICompany,
} from "@lib";
import VaAutoTable from "@core/components/va-auto-table";
import VaIcon from "@core/components/va-icon";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { Button, CheckBox, Dropdown, Icon } from "@core/components/alt-ui/controls";
import { SidebarComponent } from "@core/components/alt-ui/sidebar/sidebar.component";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { MultiDropdown } from "@core/controls/multi-dropdown/multi-dropdown";
import { getPeriodDates, ISelectOption, Period } from "@core/types/common/select-options";
import SalaryToolbar from "./salary-toolbar/salary-toolbar.vue";
import { initTableColumns } from "./salary-table-defaults";
import {
    ISalaryTableItem,
    SalaryTableItemType,
    SalaryTableOrderConverter,
    SalaryTablePaidConverter,
    SalaryTableSaleConverter,
} from "./salary-table";
import SalaryTableReservedConverter from "./salary-table/salary-table-reserved-converter";
import { SalaryProcessorFactory } from "./salary-processors";
import FilterModal from "@core/filters/filter-modal.vue";
import { ISalaryFilterContext, SalaryFilterController } from "./salary-filter-controller";
import { SalaryAddModal, SalaryAddType } from "./salary-add.modal";
import { ISalaryPayModalContext, SalaryPayModal } from "./salary-pay.modal";
import { ISalaryPayAllModalContext, SalaryPayAllModal } from "./salary-pay-all.modal";
import { FilterPair } from "@/utils/filter";

@Component({
    name: "salary",
    components: {
        BBadge,
        VaAutoTable,
        SalaryToolbar,
        VaIcon,
        SidebarComponent,
        FilterModal,
        ModalComponent,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class Salary extends Vue {
    private SalaryUseCase = this.$alt.system.usecase.createSalaryUseCase();

    private user!: IUser;
    private company!: ICompany;
    private employees: IEmployee[] = [];
    private employee: IEmployee | null = null;
    private employeeId = "";
    private accounts: IAccount[] = [];
    private salaries: ISalaryTableItem[] = [];
    private salariesTotal = 0;
    private tableFilter: ITableFilter[] = [];

    private columns = initTableColumns();

    private salaryFilterController: SalaryFilterController;

    private salaryAddModal: SalaryAddModal;
    private salaryPayModal: SalaryPayModal;
    private salaryPayAllModal: SalaryPayAllModal;

    public constructor() {
        super();

        this.salaryFilterController = new SalaryFilterController();
        this.salaryFilterController.onSave = this.applyFilter.bind(this);

        this.salaryAddModal = new SalaryAddModal();
        this.salaryAddModal.onCreate = this.createSalary.bind(this);

        this.salaryPayModal = new SalaryPayModal();
        this.salaryPayModal.onPay = this.paySalary.bind(this);

        this.salaryPayAllModal = new SalaryPayAllModal();
        this.salaryPayAllModal.onPayAll = this.payAllSalary.bind(this);
    }

    public get filterContext(): ISalaryFilterContext {
        const now = new Date();
        const currentDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, "0")}-${now.getDate()}`;

        const employee = this.employee;

        return {
            settings: {
                worksPercent: employee?.salary.work.value ?? 40,
                materialsPercent: employee?.salary.material.value ?? 40,
                salesPercent: employee?.salary.sale.value ?? 40,
            },

            defaultFilter: {
                type: [SalaryType.Works],
                stage: [OrderStageType.Ready],
                period: Period.Custom,
                dates: [currentDate, currentDate],
            },
            currentFilter: this.tableFilter,
        };
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get read(): Function {
                return (): boolean => {
                    return secure.check(PermissionType.Employees, this.employee?.user, PermissionRight.SalaryRead);
                };
            },
            get create(): Function {
                return (): boolean => {
                    return secure.check(PermissionType.Employees, this.employee?.user, PermissionRight.SalaryCreate);
                };
            },
            get delete(): Function {
                return (): boolean => {
                    return secure.check(PermissionType.Employees, this.employee?.user, PermissionRight.SalaryDelete);
                };
            },
        };
    }

    private get salariesUnpaid(): ISalaryTableItem[] {
        return this.salaries.filter(s => {
            if (
                (s.meta.order && !s.meta.work && !s.meta.material) ||
                (s.meta.sale && !s.meta.good) ||
                s.type === SalaryTableItemType.Reserved
            ) {
                return !s.paid;
            } else {
                return false;
            }
        });
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.accounts = await this.$info.getAccounts();
            this.employees = await this.$info.getEmployees();
            await this.initCurrentEmployee();
            await this.initData();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initCurrentEmployee(): Promise<void> {
        if (this.$route.params.id) {
            this.employeeId = this.$route.params.id;
            this.employee = this.employees.find(e => e.user === this.employeeId) ?? null;
        } else {
            this.employee = this.employees.find(e => e.user === this.user.id) ?? null;
            this.employeeId = this.employee ? (this.employee.user as string) : "";
        }
    }

    private async initData(): Promise<void> {
        await this.selectTableSettings();
        this.salaryFilterController.init(this.filterContext);

        this.initHeader();

        await this.selectData();
    }

    private initHeader(): void {
        const hdrEmployee = new MultiDropdown<IEmployee>();
        hdrEmployee.id = "salary.header-employee";
        hdrEmployee.toolbar = false;
        hdrEmployee.items = this.employees;
        hdrEmployee.selectedItems = this.employee ? [this.employee] : [];
        hdrEmployee.itemId = item => item.id;
        hdrEmployee.itemName = item => item.userRef.info.name;
        hdrEmployee.itemDescription = item => item.position;
        hdrEmployee.iconPackage = "feather";
        hdrEmployee.icon = "UserIcon";
        hdrEmployee.locale = {
            Tooltip: "Выбрать сотрудника",
            ButtonSelectOne: "Выбрать одного",
            ButtonSelectMultiple: "Выбрать несколько",
            ButtonSelectAll: "Выбрать все",
            TextNotSelected: "Сотрудник не выбран",
            TextSelectedAll: "Все сотрудники",
            TextSelectedMultiple: "Выбрано:",
            TextSelectedMultipleForms: ["сотрудник", "сотрудника", "сотрудников"],
        };
        hdrEmployee.addChangedHandler((s, e) => {
            if (e.items.length > 0) {
                this.changeEmployee(e.items[0]);
            }
        });

        const hdrRefresh = new Button();
        hdrRefresh.id = "salary.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrEmployee, hdrRefresh]);
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async changeEmployee(employee: IEmployee): Promise<void> {
        try {
            if (this.employeeId !== employee.user) {
                this.$alt.loader.show();
                this.employee = employee;
                this.employeeId = employee.user as string;
                await this.$router.push({ name: "salary", params: { id: this.employeeId } }).catch(() => {});
                await this.initData();
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async showFilterModal(): Promise<void> {
        this.salaryFilterController.show(this.filterContext);
    }

    private async showAddBonusModal(): Promise<void> {
        await this.salaryAddModal.show(SalaryAddType.Bonus);
    }

    private async showAddFineModal(): Promise<void> {
        await this.salaryAddModal.show(SalaryAddType.Fine);
    }

    private async showPayModal(item: ISalaryTableItem): Promise<void> {
        const context: ISalaryPayModalContext = {
            item,
            accounts: this.accounts,
            employee: this.employee as IEmployee,
        };

        await this.salaryPayModal.show(context);
    }

    private async showPayAllModal(): Promise<void> {
        const context: ISalaryPayAllModalContext = {
            items: this.salariesUnpaid,
            accounts: this.accounts,
            employee: this.employee as IEmployee,
        };

        await this.salaryPayAllModal.show(context);
    }

    private getOrderWorkName(order: IOrder, workId: string): string {
        const work = order.works?.find(w => w.id === workId);
        return work?.name ?? "";
    }

    private getOrderMaterialName(order: IOrder, materialId: string): string {
        const material = order.materials?.find(m => m.id === materialId);
        return material?.name ?? "";
    }

    protected async selectTableSettings(): Promise<void> {
        try {
            const service = this.$alt.system.usecase.createSettingsTableUseCase();
            const table = await service.get(this.company.id, this.user.id, TableType.Salary);
            if (!table) {
                throw new Error();
            }

            this.tableFilter = table.filter ?? [];
            this.salaryFilterController.setCurrentFilter(this.tableFilter);
        } catch {}
    }

    private async selectData(): Promise<void> {
        if (this.salaryFilterController.selectOnlyPaid) {
            const dates = this.salaryFilterController.filter.dates.get();

            const dto: ISalarySelectDto = {
                timezone: new Date().getTimezoneOffset().toString(),
                from: dates[0],
                to: dates[1],
            };

            const results = await this.SalaryUseCase.selectPaid(this.company.id, this.employeeId, dto);
            this.salaries = SalaryTablePaidConverter.convert(results, this.employeeId, this.accounts);
            this.salariesTotal = this.salaries.length;
        } else {
            this.salaries = [];

            await this.selectDataReserved();
            await this.selectDataOrders();
            await this.selectDataSales();

            this.salariesTotal = this.salaries.length;
        }
    }

    private async selectDataReserved(): Promise<void> {
        const salaries = await this.SalaryUseCase.selectReserved(this.company.id, this.employeeId);

        const items = SalaryTableReservedConverter.convert(salaries, this.employeeId);
        this.salaries.push(...items);
    }

    private async selectDataOrders(): Promise<void> {
        const selectedTypes = this.salaryFilterController.filter.type.get();
        const dates = this.salaryFilterController.filter.dates.get();
        const stages = this.salaryFilterController.filter.stage.get();

        if (
            selectedTypes.includes(SalaryType.Orders) ||
            selectedTypes.includes(SalaryType.Works) ||
            selectedTypes.includes(SalaryType.Materials)
        ) {
            if (!this.employee) {
                return;
            }

            const dto: ISalarySelectDto = {
                timezone: new Date().getTimezoneOffset().toString(),
                from: dates[0],
                to: dates[1],
                stages: stages,
            };
            const orders = await this.SalaryUseCase.selectOrders(this.company.id, this.employeeId, dto);

            const items = SalaryTableOrderConverter.convert(
                orders,
                this.employeeId,
                this.employee.salary.work.value,
                this.employee.salary.material.value,
            );
            this.salaries.push(...items);
        }
    }

    private async selectDataSales(): Promise<void> {
        const selectedTypes = this.salaryFilterController.filter.type.get();
        const dates = this.salaryFilterController.filter.dates.get();
        const stages = this.salaryFilterController.filter.stage.get();

        if (selectedTypes.includes(SalaryType.Sales)) {
            if (!this.employee) {
                return;
            }

            const dto: ISalarySelectDto = {
                timezone: new Date().getTimezoneOffset().toString(),
                from: dates[0],
                to: dates[1],
                stages: stages,
            };
            const sales = await this.SalaryUseCase.selectSales(this.company.id, this.employeeId, dto);

            const items = SalaryTableSaleConverter.convert(sales, this.employeeId, this.employee.salary.sale.value);
            this.salaries.push(...items);
        }
    }

    private async applyFilter(filter: ITableFilter[]): Promise<boolean> {
        try {
            this.$alt.loader.show();
            const dto: ISettingsTableUpdateDto = { filter };
            const tableService = this.$alt.system.usecase.createSettingsTableUseCase();
            await tableService.update(this.company.id, this.user.id, TableType.Salary, dto);

            const employeeSettings = this.salaryFilterController.employeeSettings;
            const employeeUseCase = this.$alt.system.usecase.createEmployeeUseCase();
            const employeeSettingsDto: IEmployeeSettingsUpdateDto = {
                salary: {
                    work: { type: EmployeeSalaryType.Percent, value: Number(employeeSettings.works) },
                    material: { type: EmployeeSalaryType.Percent, value: Number(employeeSettings.materials) },
                    sale: { type: EmployeeSalaryType.Percent, value: Number(employeeSettings.sales) },
                },
            };

            await employeeUseCase.updateSettings(this.company.id, this.employeeId, employeeSettingsDto);
            this.employees = await employeeUseCase.select(this.company.id);
            this.$info.setEmployees(this.employees);

            this.tableFilter = filter;
            this.salaryFilterController.setCurrentFilter(filter);
            await this.initCurrentEmployee();

            await this.selectData();
            this.$alt.loader.hide();

            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            this.$alt.loader.hide();

            return false;
        }
    }

    private async createSalary(type: SalaryAddType, dto: ISalaryCreateReservedDto): Promise<ISalary | null> {
        try {
            this.$alt.loader.show();
            const salary = await this.SalaryUseCase.createReserved(this.company.id, this.employeeId, dto);
            this.$alt.toast.success(type === SalaryAddType.Bonus ? "Премия создана." : "Штраф создан.");
            await this.selectData();
            return salary;
        } catch (e: any) {
            this.$alt.toast.error(
                type === SalaryAddType.Bonus
                    ? `Не удалось создать премию:\n${e.message}`
                    : `Не удалось создать штраф:\n${e.message}`,
            );
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async paySalary(account: IAccount, item: ISalaryTableItem): Promise<ISalary | null> {
        try {
            this.$alt.loader.show();
            const processor = SalaryProcessorFactory.Create(item.type);
            const salary = await processor.pay(this.company.id, account.id, item, this.SalaryUseCase);
            this.$alt.toast.success("Выплачено.");
            await this.reloadData();
            return salary;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось осуществить выплату:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async payAllSalary(salariesDto: ISalaryPayManyDto, paymentDto: ISalaryPaymentDto): Promise<ISalary | null> {
        try {
            this.$alt.loader.show();
            const salary = await this.SalaryUseCase.payMany(this.company.id, this.employeeId, salariesDto, paymentDto);
            this.$alt.toast.success("Выплачено.");
            await this.reloadData();
            return salary;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось осуществить выплату:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async confirmDelete(salary: ISalary): Promise<void> {
        const getTransactionId = (): string | null => {
            if (typeof salary.transaction === "string") {
                return salary.transaction;
            }

            if (salary.transaction?.id) {
                return salary.transaction.id;
            }

            return null;
        };

        const isTransactionExists = !!getTransactionId();

        const transactionHandler = new CheckBox();
        transactionHandler.text = "Удалить транзакцию и вернуть средства на счёт";
        transactionHandler.value = isTransactionExists ? true : false;
        transactionHandler.visible = !!isTransactionExists;

        const transactionNode = this.$createElement(ControlComponent, {
            props: {
                handler: transactionHandler,
            },
        });

        const labelNode = this.$createElement("p", {}, `Вы уверены, что хотите удалить выплату ${salary.number}?`);

        const result = await this.$alt.message.confirm([labelNode, transactionNode], "Удаление выплаты", {
            acceptText: "Удалить",
        });

        if (result) {
            await this.deleteSalary(salary, {
                id: salary.id,
                deleteTransaction: transactionHandler.value,
            });
        }
    }

    private async deleteSalary(salary: ISalary, dto: ISalaryDeleteDto): Promise<void> {
        try {
            const employeeId = this.employee?.user as string;
            await this.SalaryUseCase.delete(this.company.id, employeeId, dto);
            await this.selectData();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async reloadData(salary?: ISalary): Promise<void> {
        try {
            await this.selectData();

            await Promise.all([this.selectAccountsForUser(), this.selectEmployees()]);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectAccountsForUser(): Promise<void> {
        try {
            const accounts = await this.$alt.system.usecase
                .createEmployeeUseCase()
                .selectAccounts(this.company.id, this.user.id);
            this.$info.setAccounts(accounts);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить счета:\n${e.message}`);
        }
    }

    private async selectEmployees(): Promise<void> {
        try {
            const employees = await this.$alt.system.usecase.createEmployeeUseCase().select(this.company.id);
            this.$info.setEmployees(employees);
            this.employee = employees.find(e => e.user === this.employeeId) ?? null;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить сотрудников:\n${e.message}`);
        }
    }

    private async changePeriod(period: ISelectOption): Promise<void> {
        try {
            if (period.id === Period.Custom) {
                this.salaryFilterController.filter.period.set(period.id);
                this.showFilterModal();
            } else {
                this.salaryFilterController.filter.dates.set(getPeriodDates(period.id as Period) as FilterPair);
                this.salaryFilterController.filter.period.set(period.id);

                await this.applyFilter(this.salaryFilterController.tableFilter);
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }
}
