import { Control } from "@core/components/alt-ui/controls/control";
import { EventHandler } from "..";

export class DraggedEventArgs {
    item!: Control;
    oldIndex!: number;
    newIndex!: number;
}

export class Draggable extends Control {
    private _controls: Control[] = [];

    public get controls(): Control[] {
        return this._controls;
    }

    public getComponentName(): string {
        return "DraggableComponent";
    }

    public clearControls(): void {
        this._controls = [];
    }

    public addControl(control: Control): void {
        this._controls.push(control);
    }

    public addControls(controls: Control[]): void {
        this._controls.push(...controls);
    }

    private _draggedHandlers = new Set<EventHandler<DraggedEventArgs>>();
    public addDraggedHandler(handler: EventHandler<DraggedEventArgs>): void {
        this._draggedHandlers.add(handler);
    }
    public removeDraggedHandler(handler: EventHandler<DraggedEventArgs>): void {
        this._draggedHandlers.delete(handler);
    }
    // TODO: make in private
    public notifyDraggedHandlers(item: Control, oldIndex: number, newIndex: number): void {
        const args: DraggedEventArgs = {
            item: item,
            oldIndex: oldIndex,
            newIndex: newIndex,
        };
        for (const handler of this._draggedHandlers) {
            handler(this, args);
        }
    }
}
