import { Control } from "./control";
import { EventHandler } from "..";
import { Icon } from "./icon";
//import { ButtonComponent } from ".";

export class Button extends Control {
    public text = "";
    public variant = "primary";
    public help = "";
    public icon: Icon | null = null;

    public getComponentName(): string {
        return "ButtonComponent";
    }

    private _clickHandlers = new Set<EventHandler>();
    public addClickHandler(handler: EventHandler): void {
        this._clickHandlers.add(handler);
    }
    public removeClickHandler(handler: EventHandler): void {
        this._clickHandlers.delete(handler);
    }
    private notifyClickHandlers(): void {
        const args = {};
        for (const handler of this._clickHandlers) {
            handler(this, args);
        }
    }

    public click(): void {
        this.notifyClickHandlers();
    }

    // public get component(): typeof IControlComponent {
    //     return ButtonComponent;
    // }
}
