export abstract class ObjectUtils {
    /** Клонировать объект. */
    public static clone<T = any>(value: T): T {
        if (!value) {
            return value;
        }

        const clone = JSON.parse(JSON.stringify(value));

        if (typeof value === "object") {
            const obj = value as any;
            for (const field of Object.keys(obj)) {
                if (typeof obj[field] === "function") {
                    clone[field] = obj[field];
                }
            }

            clone.__proto__ = obj.__proto__;
        }

        return clone;
    }

    /**
     * Преобразовать сложный объект к "плоскому" виду. Например,
     * ```
     * obj = {
     *     a: 111,
     *     b: "aaa",
     *     c: {
     *         d: 222,
     *         e: true,
     *         f: {
     *             g: "bbb",
     *         },
     *     },
     * };
     * ```
     *
     * будет преобразован к виду:
     *
     * ```
     * result = {
     *     "a": 111,
     *     "b": "aaa",
     *     "c.d": 222,
     *     "c.e": true,
     *     "c.f.g": "bbb",
     * };
     * ```
     */
    public static flatten(obj: Record<string, any>): Record<string, any> {
        const result: Record<string, any> = {};

        for (const key in obj) {
            if (!Object.prototype.hasOwnProperty.call(obj, key)) {
                continue;
            }

            if (typeof obj[key] === "object" && obj[key] !== null) {
                const flatObject = ObjectUtils.flatten(obj[key]);

                for (const x in flatObject) {
                    if (!Object.prototype.hasOwnProperty.call(flatObject, x)) {
                        continue;
                    }

                    result[key + "." + x] = flatObject[x];
                }
            } else {
                result[key] = obj[key];
            }
        }

        return result;
    }

    /** Сравнить два массива объектов по значениям. */
    public static compareArrays<T = any>(array1: T[], array2: T[], getId: (el: T) => any): boolean {
        if (array1 === array2) {
            return true;
        }

        if (array1.length !== array2.length) {
            return false;
        }

        const set = new Set();

        for (const el1 of array1) {
            set.add(getId(el1));
        }

        for (const el2 of array2) {
            if (!set.has(getId(el2))) {
                return false;
            }
        }

        return true;
    }
}
