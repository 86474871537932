

































import { Vue, Component } from "vue-property-decorator";
import { VBTooltip } from "bootstrap-vue";
import VaIcon from "@core/components/va-icon";

@Component({
    name: "CellRendererDynamic",
    components: { VaIcon },
    directives: { "b-tooltip": VBTooltip },
})
export default class CellRendererDynamic extends Vue {
    private params: any;

    private get elements(): any {
        return this.params.elements;
    }
}
