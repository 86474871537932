import { IClientSource, IClientSourceCreateDto, IClientSourceUpdateDto } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, TextBox } from "@core/components/alt-ui/controls";

export class ClientSourcesModal extends Modal<IClientSource> {
    private tbSequence!: TextBox;
    private tbName!: TextBox;
    private tbDescription!: TextBox;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private clientSource: IClientSource | null = null;
    public onCreate: ((dto: IClientSourceCreateDto) => Promise<IClientSource | null>) | null = null;
    public onUpdate:
        | ((clientSource: IClientSource, dto: IClientSourceUpdateDto) => Promise<IClientSource | null>)
        | null = null;

    public constructor() {
        super("clientsources-modal", "");
        this.initializeControls();
    }

    public show(clientSource?: IClientSource): Promise<void> {
        this.clientSource = clientSource ?? null;
        this.title = clientSource ? "Изменение источника" : "Новый источник";
        this.initializeControls();

        if (clientSource) {
            this.populateControls(clientSource);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbSequence = new TextBox();
        this.tbName = new TextBox();
        this.tbDescription = new TextBox();

        this.pnlFooter = new Panel();
        this.btnCancel = new Button();
        this.btnSave = new Button();

        //

        this.tbSequence.id = "clientSource.sequence";
        this.tbSequence.label = "Порядок";
        this.tbSequence.validation = "required|numeric|min_value:1|max_value:999";
        this.tbSequence.visible = false;

        this.tbName.id = "clientSource.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.tbDescription.id = "clientSource.description";
        this.tbDescription.label = "Описание";

        //

        this.btnCancel.id = "clientSource.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave.id = "clientSource.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(clientSource: IClientSource): void {
        this.tbSequence.text = clientSource.sequence.toString();
        this.tbSequence.visible = true;

        this.tbName.text = clientSource.name;

        this.tbDescription.text = clientSource.description ?? "";
    }

    public get controls(): Control[] {
        return [this.tbSequence, this.tbName, this.tbDescription, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const result = this.clientSource ? await this.update(this.clientSource) : await this.create();

        if (result) {
            this.hide(result);
        }
    }

    private async create(): Promise<IClientSource | null> {
        if (!this.onCreate) {
            return null;
        }

        const dto: IClientSourceCreateDto = {
            name: this.tbName.text,
            description: this.tbDescription.text,
        };

        return await this.onCreate(dto);
    }

    private async update(clientSource: IClientSource): Promise<IClientSource | null> {
        if (!this.onUpdate) {
            return null;
        }

        const dto: IClientSourceUpdateDto = {
            sequence: parseInt(this.tbSequence.text),
            name: this.tbName.text,
            description: this.tbDescription.text,
        };

        return await this.onUpdate(clientSource, dto);
    }
}
