import { Component, Vue } from "vue-property-decorator";
import { IClient, IClientSource, ICompany, IUser, IField, IOrderTypeUpdateDto, IReadQuery } from "@lib";
import { IClientCreateDto, IClientUpdateDto, IClientDeleteManyDto } from "@lib";
import { ISettingsFormUpdateDto, ISettingsTableUpdateDto } from "@lib";
import { FormType, ITable, ITableFilter, ITableSort, TableType } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import VaTable, { TableApi } from "@/components/common/va-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { Button, Html, Icon } from "@core/components/alt-ui/controls";
import { ClientModal } from "./client.modal";
import { ModalSettingsModal } from "@/@core/controls/modal-settings/modal-settings.modal";
import { FieldModal } from "@core/controls/field-modal/field.modal";
import { getDefaultTableColumns, getDefaultTableActions, getDefaultFields } from "./clients-defaults";
import ClientsToolbar from "./clients-toolbar/clients-toolbar.vue";
import { Diagnostics } from "@/utils/diagnostics";

@Component({
    name: "clients",
    components: {
        VaTable,
        ClientsToolbar,
        ModalComponent,
    },
})
export default class Clients extends Vue {
    private ClientUseCase = this.$alt.system.usecase.createClientUseCase();
    private ClientSourceUseCase = this.$alt.system.usecase.createClientSourceUseCase();
    private SettingsForm = this.$alt.system.usecase.createSettingsFormUseCase();
    private SettingsTable = this.$alt.system.usecase.createSettingsTableUseCase();

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private clients: IClient[] = [];
    private sources: IClientSource[] = [];
    private clientsTotal: number = 0;

    private formFields: IField[] = getDefaultFields();

    private table: any = {
        settings: {
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
        },
        page: 1,
        search: "",
        api: new TableApi(),
    };

    private clientModal: ClientModal;
    private fieldModal: FieldModal;
    private fieldSettingsModal: ModalSettingsModal;

    public constructor() {
        super();

        this.clientModal = new ClientModal();
        this.clientModal.onCreate = this.create.bind(this);
        this.clientModal.onUpdate = this.update.bind(this);

        this.fieldModal = new FieldModal();

        this.fieldSettingsModal = new ModalSettingsModal({ fieldModal: this.fieldModal });
        this.fieldSettingsModal.onSave = this.saveModalFields.bind(this);
    }

    private get formType(): FormType {
        return FormType.Client;
    }

    private get tableType(): TableType {
        return TableType.Client;
    }

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this);
    }

    private get defaulActions(): any[] {
        return getDefaultTableActions(this);
    }

    private get clientsData(): IClient[] {
        return this.clients ? this.clients : [];
    }

    private get clientsDataTotal(): number {
        return this.clientsTotal ? this.clientsTotal : 0;
    }

    private get limit(): number {
        return this.table.settings.limit ?? 10;
    }

    private get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    private get sort(): ITableSort[] {
        return this.table.settings.sort ?? [];
    }

    private get filter(): ITableFilter[] {
        return this.table.settings.filter ?? [];
    }

    private get columns(): any[] {
        // TODO: отрефакторить
        // берём дефолтный набор колонок
        // сначала сортируем их в соответсвии с тем, что лежит в базе
        // затем копируем свойства: ширину и видимость

        const dbColumns = this.table.settings.columns;
        const sorted = this.defaulColumns
            .sort((col1: any, col2: any) => {
                for (let i = 0; i < dbColumns.length; ++i) {
                    if (dbColumns[i].colId === col1.colId) {
                        return -1;
                    }
                    if (dbColumns[i].colId === col2.colId) {
                        return 1;
                    }
                }
                return 0;
            })
            .map((column: any) => {
                const dbColumn = dbColumns.find((dbc: any) => dbc.colId === column.colId);
                if (dbColumn) {
                    column.width = dbColumn.width;
                    column.hide = dbColumn.hide;
                }
                return column;
            });

        return sorted;
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get read(): boolean {
                return true;
            },
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Clients, PermissionRight.Create);
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Clients, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Clients, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.initData();
            await this.initParams();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        await Promise.all([this.selectTableSettings(), this.selectFormSettings(), this.selectClientSources()]);

        this.initHeader();

        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "clients.header-title";
        hdrTitle.html = '<h2 class="m-0">Клиенты</h2>';

        const hdrRefresh = new Button();
        hdrRefresh.id = "clients.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrTitle, hdrRefresh]);
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$route.query.new !== undefined) {
                this.showCreateModal();
                return;
            }

            if (this.$route.query.id) {
                const clientId =
                    this.$route.query.id instanceof Array ? (this.$route.query.id[0] as string) : this.$route.query.id;
                const client = await this.getClient(clientId);
                this.showUpdateModal(client);
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            throw new Error("Клиент не найден.");
        }
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectFormSettings(): Promise<void> {
        const form = await this.SettingsForm.get(this.company.id, this.formType);
        this.formFields = form?.fields ?? getDefaultFields();
    }

    private async selectTableSettings(): Promise<void> {
        let settings: ITable | null = null;
        try {
            settings = await this.SettingsTable.get(this.company.id, this.user.id, this.tableType);
        } catch {}
        this.applyTableSettings(settings ?? this.table.settings);
    }

    private applyTableSettings(settings: ITable | null): void {
        if (!settings) {
            throw new Error();
        }

        this.table.settings = settings;

        if (settings.filter && settings.filter.length > 0) {
            return;
        }
    }

    private async changeTableColumns(columns: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            // добавить к запросу номер страницы: p=2
            // if (page === 1) super.queryRemove("p");
            // else super.queryAdd("p", page);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private showCreateModal(): void {
        this.clientModal.onModalShow = () => this.$router.push({ query: { new: "" } }).catch(() => {});
        this.clientModal.onModalHide = () => this.$router.push({ query: undefined }).catch(() => {});

        const context = {
            vue: this,
            sources: this.sources,
            fields: this.formFields,
            defaultFields: getDefaultFields(),
            settingsModal: this.fieldSettingsModal,
        };
        this.clientModal.show(context);
    }

    private showUpdateModal(client: IClient): void {
        this.clientModal.onModalShow = () => this.$router.push({ query: { id: client.id } }).catch(() => {});
        this.clientModal.onModalHide = () => this.$router.push({ query: undefined }).catch(() => {});

        const context = {
            vue: this,
            client: client,
            sources: this.sources,
            fields: this.formFields,
            defaultFields: getDefaultFields(),
            settingsModal: this.fieldSettingsModal,
        };
        this.clientModal.show(context);
    }

    private async confirmDelete(client: IClient): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить клиента: "${client.info.name}"?`,
            "Удаление клиента",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(client);
        }
    }

    private async confirmDeleteMany(clients: IClient[]): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить ${clients.length} клиентов?`,
            "Удаление клиентов",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.deleteMany(clients);
        }
    }

    private async selectClientSources(): Promise<void> {
        try {
            this.sources = await this.ClientSourceUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить источники:\n${e.message}`);
        }
    }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const search = this.table.search.length > 0 ? this.table.search : undefined;
            const query: IReadQuery = { limit, offset, sort, search, filter };
            const result = await this.ClientUseCase.select(this.company.id, query);

            this.clients = result.data;
            this.clientsTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить клиентов:\n${e.message}`);
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            // начинать поиск от 2 символов
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.clients = [];
            this.clientsTotal = 0;
        }
    }

    private async getClient(id: string): Promise<any> {
        try {
            const client = await this.ClientUseCase.get(this.company.id, id);
            if (!client) {
                throw new Error("Клиент не найден.");
            }

            return client;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить данные клиента:\n${e.message}`);
        }
    }

    private async create(dto: IClientCreateDto): Promise<IClient | null> {
        try {
            this.$alt.loader.show();
            const client = await this.ClientUseCase.create(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Клиент успешно создан.");
            return client;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(orig: IClient, dto: IClientUpdateDto): Promise<IClient | null> {
        try {
            this.$alt.loader.show();
            const client = await this.ClientUseCase.update(this.company.id, orig.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Клиент успешно изменён.");
            return client;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(client: IClient): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.ClientUseCase.delete(this.company.id, client.id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Клиент успешно удалён.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async deleteMany(clients: IClient[]): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: IClientDeleteManyDto = {
                ids: clients.map(c => c.id),
            };
            await this.ClientUseCase.deleteMany(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Клиенты успешно удалены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async saveModalFields(fields: IField[]): Promise<boolean> {
        try {
            this.$alt.loader.show();
            const dto: ISettingsFormUpdateDto = { fields };
            const form = await this.SettingsForm.update(this.company.id, this.formType, dto);
            this.formFields = form.fields;
            this.$alt.toast.success("Настройки формы сохранены.");
            await this.updateOrderTypes(this.formFields);
            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось сохранить настройки формы:\n${e.message}`);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async updateOrderTypes(clientFormFields: IField[]): Promise<void> {
        try {
            const orderTypes = await this.$alt.system.usecase.createOrderTypeUseCase().select(this.company.id);

            for (const orderType of orderTypes) {
                const clientGroup = orderType.groups.find(g => g.id === "client");
                if (!clientGroup) {
                    return;
                }

                let hasNewCustomFields = false;
                for (const field of clientFormFields) {
                    if (!field.custom) {
                        continue;
                    }

                    // TODO: избавиться от поиска по id, оставить только customId
                    const clientGroupField = clientGroup.fields.find(
                        f => (f.customId && field.customId && f.customId === field.customId) || f.id === field.id,
                    );
                    if (!clientGroupField) {
                        const newField = this.$alt.clone(field);
                        newField.hidden = true;
                        // В форме клиентов id - это objectId, а в типах заявок просто произвольная строка.
                        // Чтобы не было ошибки, удаляем id - тоогда он сгенерируется для формы клиентов.
                        // TODO: сделать в типе заявок тоже objectId
                        //newField.id = undefined as any;
                        //(newField as any)._id = undefined as any;

                        clientGroup.fields.push(newField);
                        hasNewCustomFields = true;
                    }
                }

                if (hasNewCustomFields) {
                    const dto: IOrderTypeUpdateDto = {
                        offices: orderType.offices as string[],
                        sequence: orderType.sequence,
                        name: orderType.name,
                        description: orderType.description,
                        groups: orderType.groups,
                    };
                    await this.$alt.system.usecase.createOrderTypeUseCase().update(this.company.id, orderType.id, dto);
                }
            }
        } catch (e: any) {
            Diagnostics.LogWarning(`Не удалось изменить типы заявок: ${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
