import {
    IUser,
    ICompany,
    IAuthLoadInfoResultDto,
    IEmployee,
    IOffice,
    IShop,
    IAccount,
    IStore,
    IPluginBinding,
    ICompanyPricing,
} from "@lib";
import { Control } from "@core/components/alt-ui/controls";
import { AuthUseCase } from "@core/usecases/auth/auth.usecase";
import { AppException } from "@/exceptions";

export class InfoPlugin {
    // пользователь

    private _user: IUser | null = null;

    public async getUser(): Promise<IUser> {
        if (this._user === null) {
            await this.loadAuthInfo();

            if (!this._user) {
                throw new AppException("Данные пользователя не были загружены.");
            }
        }

        return this._user;
    }

    public setUser(user: IUser): void {
        this._user = user;
    }

    // компания

    private _company: ICompany | null = null;

    public async getCompany(): Promise<ICompany> {
        if (!this._company) {
            await this.loadAuthInfo();

            if (!this._company) {
                throw new AppException("Данные компании не были загружены.");
            }
        }

        return this._company;
    }

    public setCompany(company: ICompany): void {
        this._company = company;
    }

    public async getPricing(): Promise<ICompanyPricing> {
        if (!this._company) {
            await this.loadAuthInfo();

            if (!this._company) {
                throw new AppException("Данные компании не были загружены.");
            }
        }

        return this._company.pricing;
    }

    public async getBalance(): Promise<number> {
        if (!this._company) {
            await this.loadAuthInfo();

            if (!this._company) {
                throw new AppException("Данные компании не были загружены.");
            }
        }

        return this._company.balance;
    }

    public setBalance(balance: number): void {
        if (this._company && this._company.balance !== balance) {
            const oldBalance = this._company.balance;
            this._company.balance = balance;
            this.notifyChangeBalanceListeners(balance, oldBalance);
        }
    }

    public updateBalance(): void {
        if (this._company) {
            this.notifyChangeBalanceListeners(this._company.balance, this._company.balance);
        }
    }

    private listenersBalanceChanged = new Set<Function>();
    public set onBalanceChanged(func: Function) {
        this.listenersBalanceChanged.add(func);
    }

    private notifyChangeBalanceListeners(balance: number, oldBalance: number): void {
        for (const listener of this.listenersBalanceChanged) {
            listener(balance, oldBalance);
        }
    }

    // сотрудники

    private _employees: IEmployee[] | null = null;

    public async getEmployees(): Promise<IEmployee[]> {
        if (!this._employees) {
            await this.loadAuthInfo();

            if (!this._employees) {
                throw new AppException("Данные сотрудников не были загружены.");
            }
        }

        return this._employees;
    }

    public setEmployees(employees: IEmployee[]): void {
        this._employees = employees;
    }

    // филиалы

    private _offices: IOffice[] | null = null;
    // private _orderCount: number;

    public async getOffices(): Promise<IOffice[]> {
        if (!this._offices) {
            await this.loadAuthInfo();

            if (!this._offices) {
                throw new AppException("Данные филиалов не были загружены.");
            }
        }

        return this._offices;
    }

    public setOffices(offices: IOffice[]): void {
        this._offices = offices;
    }

    // магазины

    private _shops: IShop[] | null = null;
    // private _saleCount: number;

    public async getShops(): Promise<IShop[]> {
        if (!this._shops) {
            await this.loadAuthInfo();

            if (!this._shops) {
                throw new AppException("Данные магазинов не были загружены.");
            }
        }

        return this._shops;
    }

    public setShops(shops: IShop[]): void {
        this._shops = shops;
    }

    // счета

    private _accounts: IAccount[] | null = null;

    public async getAccounts(): Promise<IAccount[]> {
        if (!this._accounts) {
            await this.loadAuthInfo();

            if (!this._accounts) {
                throw new AppException("Данные счетов не были загружены.");
            }
        }

        return this._accounts;
    }

    public setAccounts(accounts: IAccount[]): void {
        this._accounts = accounts;
    }

    // склады

    private _stores: IStore[] | null = null;

    public async getStores(): Promise<IStore[]> {
        if (!this._stores) {
            await this.loadAuthInfo();

            if (!this._stores) {
                throw new AppException("Данные складов не были загружены.");
            }
        }

        return this._stores;
    }

    public setStores(stores: IStore[]): void {
        this._stores = stores;
    }

    // плагины

    private _plugins: IPluginBinding[] | null = null;

    public async getPlugins(): Promise<IPluginBinding[]> {
        if (!this._plugins) {
            await this.loadAuthInfo();

            if (!this._plugins) {
                throw new AppException("Данные плагинов не были загружены.");
            }
        }

        return this._plugins;
    }

    public setPlugins(plugins: IPluginBinding[]): void {
        this._plugins = plugins;
    }

    //

    public async loadAuthInfo(): Promise<IAuthLoadInfoResultDto> {
        const info = await new AuthUseCase().loadInfo();
        this.setAuthInfo(info);
        return info;
    }

    public setAuthInfo(info: IAuthLoadInfoResultDto): void {
        this.clean();

        this._user = info.user.user;

        if (!info.company) {
            return;
        }

        this._company = info.company.company ?? null;

        this._employees = info.company.employees.sort((a, b) => {
            if (a.userRef.info.name > b.userRef.info.name) {
                return 1;
            }
            if (a.userRef.info.name < b.userRef.info.name) {
                return -1;
            }
            return 0;
        });
        this._offices = info.company.offices;
        this._shops = info.company.shops;
        this._accounts = info.company.accounts;
        this._stores = info.company.stores;
        this._plugins = info.company.plugins;
        // this._orderCount = info.company.orderCount;
        // this._saleCount = info.company.saleCount;
    }

    public clean(): void {
        this._user = null;
        this._company = null;

        this._employees = null;
        this._offices = null;
        this._shops = null;
        this._accounts = null;
        this._stores = null;
        this._plugins = null;
        // this._orderCount = null;
        // this._saleCount = null;
    }

    // Menu

    private _ui: UIHandler = new UIHandler();
    public get ui(): UIHandler {
        return this._ui;
    }
    // public set menu(menu: IMenuData | null) {
    //     this._menu = menu;
    //     this.notifyMenuDataChangedHandlers();
    // }

    // private _menuDataChangedHandlers: any[] = [];
    // public addMenuDataChangedHandler(handler: any): void {
    //     this._menuDataChangedHandlers.push(handler);
    // }
    // private notifyMenuDataChangedHandlers(): void {
    //     for (const handler of this._menuDataChangedHandlers) {
    //         handler(this._menu);
    //     }
    // }
}

class UIHandler {
    private _windowWidth = 0;
    public get windowWidth(): number {
        return this._windowWidth;
    }
    public set windowWidth(value: number) {
        this._windowWidth = value;
        for (const handler of this._windowWidthChangedHandlers) {
            handler(this._windowWidth);
        }
    }

    private _windowWidthChangedHandlers: any[] = [];
    public addWindowWidthChangedHandler(handler: any): void {
        this._windowWidthChangedHandlers.push(handler);
    }

    //

    public openSidebar(): void {
        for (const handler of this._openSidebarChangedHandlers) {
            handler();
        }
    }

    private _openSidebarChangedHandlers: any[] = [];
    public addOpenSidebarChangedHandler(handler: any): void {
        this._openSidebarChangedHandlers.push(handler);
    }

    //

    private _headerControls: Control[] = [];
    public getHeaderControls(): Control[] {
        return this._headerControls;
    }
    public setHeaderControls(controls: Control[]): void {
        this._headerControls = controls;

        for (const handler of this._headerControlsChangedHandlers) {
            handler();
        }
    }
    public cleanHeaderControls(): void {
        this.setHeaderControls([]);
    }

    private _headerControlsChangedHandlers: any[] = [];
    public addHeaderControlsChangedHandler(handler: any): void {
        this._headerControlsChangedHandlers.push(handler);
    }
}
