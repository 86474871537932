import moment from "moment";
import { IGoodWriteOff, IStore, Locale } from "@lib";
import { Localizer } from "@/i18n/localizer";

export function getDefaultTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить / Удалить",
            width: 90,
            hide: !context.can.update && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: (goodWriteOff: IGoodWriteOff) => context.can.update(goodWriteOff),
                            click: (goodWriteOff: IGoodWriteOff) => context.showModalUpdate(goodWriteOff),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: (goodWriteOff: IGoodWriteOff) => context.can.delete(goodWriteOff),
                            click: (goodWriteOff: IGoodWriteOff) => context.confirmDelete(goodWriteOff),
                        },
                    ],
                };
            },
        },
        {
            colId: "number",
            field: "number",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 100,
            hide: false,
        },
        {
            colId: "store",
            field: "store",
            headerName: "Склад",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                return {
                    name: params.data.storeRef?.info.name,
                };
            },
        },
        {
            colId: "employee",
            field: "employee",
            headerName: "Сотрудник",
            sortable: true,
            filter: true,
            width: 220,
            hide: false,

            cellRendererFramework: "CellRendererAvatar",
            cellRendererParams: (params: any) => {
                const employee = params.data.employeeRef;

                return {
                    showAvatar: true,
                    avatar: employee?.info.avatar ?? "",
                    name: employee?.info.name ?? "",
                };
            },
        },
        {
            colId: "date",
            field: "date",
            headerName: "Дата",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,

            cellRenderer: function (params: any) {
                return moment(params.value).format("DD.MM.YYYY HH:mm");
            },
        },
        {
            colId: "amount",
            field: "amount",
            headerName: "Сумма",
            sortable: true,
            filter: true,
            width: 150,
            hide: false,

            cellRenderer: function (params: any) {
                // TODO: брать локаль из склада
                const locale = Locale.RU;
                return Localizer.moneyFull(params.value, locale);
            },
        },
        {
            colId: "comment",
            field: "comment",
            headerName: "Комментарий",
            sortable: true,
            filter: true,
            width: 500,
            hide: false,
        },
    ];
}
