import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { IOffice, IOrderType, IProductType } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import VaIcon from "@core/components/va-icon/va-icon.vue";
import { OrderBlockProductHandler } from "./order-block-product-handler";

@Component({
    components: { ControlComponent, VaIcon },
})
export default class OrderCreateProduct extends Vue {
    @Prop({ required: true })
    private handler!: OrderBlockProductHandler;

    @Prop({ default: () => null })
    private office!: IOffice | null;

    @Prop({ default: () => null })
    private orderType!: IOrderType | null;

    @Prop({ default: () => [] })
    private productTypes!: IProductType[];

    @Watch("orderType", { immediate: true, deep: true })
    private onOrderTypeChanged(): void {
        this.initValues();
    }

    @Watch("office", { immediate: true, deep: true })
    private onOfficeChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        this.handler.init(this.office, this.orderType, this.productTypes);
    }
}
