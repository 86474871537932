import { ISalary, IOffice, IOrder, IOrderWork, DiscountType, Locale } from "@lib";
import { ISalaryTableItem, SalaryTableItemType, TableSalaryConverterResult } from ".";
import { moneyFormat } from "@/filters/money";

export default class SalaryTableOrderConverter {
    private orders: IOrder[];
    private userId: string;
    private percentWork: number;
    private percentMaterial: number | undefined;

    public constructor(orders: IOrder[], userId: string, percentWork: number, percentMaterial: number | undefined) {
        this.orders = orders;
        this.userId = userId;
        this.percentWork = percentWork;
        this.percentMaterial = percentMaterial;
    }

    public convert(): ISalaryTableItem[] {
        const items: ISalaryTableItem[] = [];
        for (const order of this.orders) {
            const salaryLink = this.getSalaryOrder(order);
            const paid = salaryLink ? salaryLink.paid : false;

            const item: ISalaryTableItem = {
                type: SalaryTableItemType.Order,
                number: salaryLink?.number,
                name: `Заявка №${order.number}`,
                profit: 0,
                amount: 0,
                meta: {
                    order,
                    employee: this.userId,
                    salary: salaryLink,
                    locale: order.officeRef?.info.locale ?? Locale.RU,
                    percentWork: this.percentWork,
                    percentMaterial: this.percentMaterial,
                },
                paid: paid,
                _rowVariant: paid ? "success" : undefined,
            };
            items.push(item);

            let orderProfit = 0;
            let orderAmount = 0;

            let result = this.convertWorks(order, item);
            items.push(...result.items);
            orderProfit += result.sumProfit;
            orderAmount += result.sumAmount;

            if (this.percentMaterial !== undefined) {
                result = this.convertMaterials(order, item);
                items.push(...result.items);
                orderProfit += result.sumProfit;
                orderAmount += result.sumAmount;
            }

            item.profit = orderProfit;
            item.amount = orderAmount;
        }
        return items;
    }

    private convertWorks(order: IOrder, parent: ISalaryTableItem): TableSalaryConverterResult {
        let sumProfit = 0;
        let sumAmount = 0;
        const locale = order.officeRef?.info.locale ?? Locale.RU;
        const items: ISalaryTableItem[] = [];

        for (const work of order.works ?? []) {
            if (work.employee !== this.userId) {
                continue;
            }

            const expenses = work.cost ? work.cost * work.quantity : 0;
            let incomes = work.price * work.quantity;
            let incomesHelp = `= ${moneyFormat(work.price, { locale })} * ${work.quantity}`;
            let discountValue = 0;
            let discountStr = "";
            let discountHelp: string | undefined;

            if (order.info?.discount) {
                if (order.info.discount.type === DiscountType.Percent) {
                    discountValue = (order.info.discount.value * incomes) / 100;
                    discountStr = `${order.info.discount.value}%`;
                    discountHelp = `= ${moneyFormat(discountValue, { locale })}`;
                    incomesHelp += ` - ${discountStr}`;
                } else if (order.info.discount.type === DiscountType.Fixed) {
                    discountValue = order.info.discount.value;
                    discountStr += moneyFormat(order.info.discount.value, { locale });
                    incomesHelp += ` - ${discountStr}`;
                }
            }

            incomes = incomes - discountValue;
            const profit = incomes - expenses;
            const amount = (profit * this.percentWork) / 100;
            const amountHelp = `= ${moneyFormat(profit, { locale })} * ${this.percentWork}%`;
            sumProfit += profit;
            sumAmount += amount;

            items.push({
                type: SalaryTableItemType.Work,
                name: work.name,
                quantity: work.quantity,
                expenses: expenses,
                expensesHelp: `= ${moneyFormat(work.cost ?? 0, { locale })} * ${work.quantity}`,
                incomes: incomes,
                incomesHelp: incomesHelp,
                discount: discountStr,
                discountHelp: discountHelp,
                profit: profit,
                amount: amount,
                amountHelp: amountHelp,
                meta: {
                    order,
                    work,
                    employee: this.userId,
                    locale: locale,
                    percentWork: this.percentWork,
                },
                parent: parent,
                paid: false,
                _rowVariant: parent._rowVariant,
            });
        }
        return { items, sumProfit, sumAmount };
    }

    private convertMaterials(order: IOrder, parent: ISalaryTableItem): TableSalaryConverterResult {
        let sumProfit = 0;
        let sumAmount = 0;
        const locale = order.officeRef?.info.locale ?? Locale.RU;
        const items: ISalaryTableItem[] = [];

        if (this.percentMaterial === undefined) {
            return { items, sumProfit: sumProfit, sumAmount };
        }

        for (const material of order.materials ?? []) {
            if (!material.work) {
                continue;
            }
            if (!this.isEmployeeWork(this.userId, material.work, order.works)) {
                continue;
            }

            const expenses = material.cost * material.quantity;
            let incomes = material.price * material.quantity;
            let incomesHelp = `= ${moneyFormat(material.price, { locale })} * ${material.quantity}`;
            let discountValue = 0;
            let discountStr = "";
            let discountHelp: string | undefined;

            if (order.info?.discount) {
                if (order.info.discount.type === DiscountType.Percent) {
                    discountValue = (order.info.discount.value * incomes) / 100;
                    discountStr = `${order.info.discount.value}%`;
                    discountHelp = `= ${moneyFormat(discountValue, { locale })}`;
                    incomesHelp += ` - ${discountStr}`;
                } // else if (order.info.discount.type === DiscountType.Fixed) {
                //     discountValue = order.info.discount.value;
                //     discountStr += moneyFormat(order.info.discount.value, { locale });
                //     incomesHelp += ` - ${discountStr}`;
                // }
            }

            incomes = incomes - discountValue;
            const profit = incomes - expenses;
            const amount = (profit * this.percentMaterial) / 100;
            const amountHelp = `= ${moneyFormat(profit, { locale })} * ${this.percentMaterial}%`;
            sumProfit += profit;
            sumAmount += amount;

            items.push({
                type: SalaryTableItemType.Material,
                name: material.name,
                quantity: material.quantity,
                expenses: expenses,
                expensesHelp: `= ${moneyFormat(material.cost, { locale })} * ${material.quantity}`,
                incomes: incomes,
                incomesHelp: incomesHelp,
                discount: discountStr,
                discountHelp: discountHelp,
                profit: profit,
                amount: amount,
                amountHelp: amountHelp,
                meta: {
                    order,
                    material,
                    employee: this.userId,
                    locale: locale,
                    percentMaterial: this.percentMaterial,
                },
                parent: parent,
                paid: false,
                _rowVariant: parent._rowVariant,
            });
        }
        return { items, sumProfit, sumAmount };
    }

    private isEmployeeWork(userId: string, workId: string, works: IOrderWork[] = []): boolean {
        const work = works.find(w => w.id === workId);
        return work?.employee ? work.employee === userId : false;
    }

    private getSalaryOrder(order: IOrder): ISalary | undefined {
        if (!(order as any).salaries) {
            return undefined;
        }
        for (const salary of (order as any).salaries as ISalary[]) {
            for (const subject of salary.subject) {
                if (subject.order) {
                    return salary;
                }
            }
        }
        return undefined;
    }

    public static convert(
        orders: IOrder[],
        userId: string,
        percentWork: number,
        percentMaterial: number | undefined,
    ): ISalaryTableItem[] {
        return new SalaryTableOrderConverter(orders, userId, percentWork, percentMaterial).convert();
    }
}
