import { Modal2Component } from "@core/components/alt-ui/modal-2/modal-2.component";
import VaTable from "@/components/common/va-table";
import { Component, Vue } from "vue-property-decorator";
import { GoodInventoryModal } from "./good-inventory.modal";
import {
    ICompany,
    IGoodInventory,
    IGoodInventoryCreateDto,
    IGoodInventoryUpdateDto,
    ISettingsTableUpdateDto,
    IStore,
    ITable,
    ITableFilter,
    ITableSort,
    IUser,
    PermissionRight,
    PermissionType,
    TableType,
} from "@lib";
import { getDefaultTableColumns } from "./good-inventories-defaults";
import GoodInventoriesToolbar from "./good-inventories-toolbar.vue";
import { Button, Html, Icon } from "@core/components/alt-ui/controls";
import { convertFilterBack } from "./good-inventories-filter-defaults";

@Component({
    name: "good-inventories",
    components: {
        VaTable,
        GoodInventoriesToolbar,
        Modal2Component,
    },
})
export default class GoodInventories extends Vue {
    private GoodInventoryUseCase = this.$alt.system.usecase.createGoodInventoryUseCase();
    private SettingsTableUseCase = this.$alt.system.usecase.createSettingsTableUseCase();

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private inventory: IGoodInventory[] = [];
    private inventoryTotal: number = 0;
    private stores: IStore[] = [];

    private goodInventoryModal: GoodInventoryModal;

    private table: any = {
        settings: {
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
        },
        page: 1,
        search: "",
    };

    constructor() {
        super();

        this.goodInventoryModal = new GoodInventoryModal("good-inventory-modal");
        this.goodInventoryModal.onCreate = this.create.bind(this);
        this.goodInventoryModal.onUpdate = this.update.bind(this);
        this.goodInventoryModal.onResume = this.resume.bind(this);
    }

    private get tableType(): TableType {
        return TableType.GoodInventory;
    }

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this);
    }

    private get inventoryData(): IGoodInventory[] {
        return this.inventory ? this.inventory : [];
    }

    private get inventoryDataTotal(): number {
        return this.inventoryTotal ? this.inventoryTotal : 0;
    }

    private get can(): any {
        const secure = this.$secure;
        const stores = this.stores;

        return {
            get read(): boolean {
                return true;
            },
            get update(): Function {
                return (goodInventory: IGoodInventory): boolean => {
                    return secure.check(PermissionType.Stores, goodInventory.store, PermissionRight.GoodsUpdate);
                };
            },
            get delete(): Function {
                return (goodInventory: IGoodInventory): boolean => {
                    return secure.check(PermissionType.Stores, goodInventory.store, PermissionRight.GoodsDelete);
                };
            },
            get create(): boolean {
                for (const store of stores) {
                    const valid = secure.check(PermissionType.Stores, store.id, PermissionRight.GoodsCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    private get columns(): any[] {
        // TODO: отрефакторить
        // берём дефолтный набор колонок
        // сначала сортируем их в соответсвии с тем, что лежит в базе
        // затем копируем свойства: ширину и видимость

        const dbColumns = this.table.settings.columns;
        const sorted = this.defaulColumns
            .sort((col1: any, col2: any) => {
                for (let i = 0; i < dbColumns.length; ++i) {
                    if (dbColumns[i].colId === col1.colId) {
                        return -1;
                    }
                    if (dbColumns[i].colId === col2.colId) {
                        return 1;
                    }
                }
                return 0;
            })
            .map((column: any) => {
                const dbColumn = dbColumns.find((dbc: any) => dbc.colId === column.colId);
                if (dbColumn) {
                    column.width = dbColumn.width;
                    column.hide = dbColumn.hide;
                }
                return column;
            });

        return sorted;
    }

    private get limit(): number {
        return this.table.settings.limit ?? 10;
    }

    private get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    private get sort(): ITableSort[] {
        return this.table.settings.sort ?? [];
    }

    private get filter(): ITableFilter[] {
        return this.table.settings.filter ?? [];
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.stores = await this.$info.getStores();
            await this.initData();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        await Promise.all([this.selectTableSettings()]);

        const filter = {
            store: this.stores.map(e => e.id),
        };
        this.table.settings.filter = convertFilterBack(filter);
        this.initHeader();
        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "good-inventories.header-title";
        hdrTitle.html = '<h2 class="m-0">Инвентаризации</h2>';

        const hdrRefresh = new Button();
        hdrRefresh.id = "good-inventories.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrTitle, hdrRefresh]);
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectTableSettings(): Promise<void> {
        let settings: ITable | null = null;
        try {
            settings = await this.SettingsTableUseCase.get(this.company.id, this.user.id, this.tableType);
        } catch {}
        this.applyTableSettings(settings ?? this.table.settings);
    }

    private applyTableSettings(settings: ITable | null): void {
        if (!settings) {
            throw new Error();
        }

        this.table.settings = settings;

        if (settings.filter && settings.filter.length > 0) {
            return;
        }
    }

    private async changeTableColumns(columns: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            const settings = await this.SettingsTableUseCase.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            const settings = await this.SettingsTableUseCase.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            const settings = await this.SettingsTableUseCase.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.inventory = [];
            this.inventoryTotal = 0;
        }
    }

    private async showModalCreate(): Promise<void> {
        await this.goodInventoryModal.show({
            userId: this.user.id,
            companyId: this.company.id,
            stores: this.stores,
            storeId: this.stores.length > 0 ? this.stores[0].id : "",
            message: this.$alt.message,
        });
    }

    private async showModalUpdate(goodInventory: IGoodInventory): Promise<void> {
        await this.goodInventoryModal.show({
            userId: this.user.id,
            companyId: this.company.id,
            stores: this.stores,
            storeId: this.stores.length > 0 ? this.stores[0].id : "",
            goodInventory,
            message: this.$alt.message,
        });
    }

    private async confirmDelete(goodInventory: IGoodInventory): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить инвентаризацию: #${goodInventory.number}?`,
            "Удаление инвентаризации",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(goodInventory);
        }
    }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            const result = await this.GoodInventoryUseCase.select(this.company.id);

            this.inventory = result.data;
            this.inventoryTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить инвентаризации:\n${e.message}`);
        }
    }

    private async create(dto: IGoodInventoryCreateDto): Promise<IGoodInventory | null> {
        try {
            this.$alt.loader.show();

            const inventory = await this.GoodInventoryUseCase.create(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Инвентаризация успешно создана.");
            return inventory;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(id: string, dto: IGoodInventoryUpdateDto): Promise<IGoodInventory | null> {
        try {
            this.$alt.loader.show();

            const inventory = await this.GoodInventoryUseCase.update(this.company.id, id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Инвентаризация успешно обновлена.");
            return inventory;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async resume(id: string): Promise<IGoodInventory | null> {
        try {
            this.$alt.loader.show();

            const inventory = await this.GoodInventoryUseCase.resume(this.company.id, id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Инвентаризация успешно возобновлена.");
            return inventory;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(goodInventory: IGoodInventory): Promise<void> {
        try {
            this.$alt.loader.show();

            await this.GoodInventoryUseCase.delete(this.company.id, goodInventory.id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Инвентаризация успешно удалена.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
