import { Vue, Component } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ICompany, IShop, IShopCreateDto, IShopUpdateDto, IUser } from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import VaTable from "@/components/common/va-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { ShopModal } from "./modals/shop.modal";
import { getTableColumns } from "./settings-company-shops-defaults";

@Component({
    components: { BButton, VaTable, ModalComponent },
})
export default class SettingsCompanyShops extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private shops: IShop[] = [];

    private ShopUseCase = this.$alt.system.usecase.createShopUseCase();

    private shopModal: ShopModal;

    public constructor() {
        super();

        this.shopModal = new ShopModal();
        this.shopModal.onCreate = this.create.bind(this);
        this.shopModal.onUpdate = this.update.bind(this);
    }

    private get columns(): any[] {
        return getTableColumns(this);
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get create(): boolean {
                return secure.checkCommon(PermissionCommonSection.Shops, PermissionRight.Create);
            },
            get read(): boolean {
                return true;
            },
            get update(): boolean {
                return secure.checkCommon(PermissionCommonSection.Shops, PermissionRight.Update);
            },
            get delete(): boolean {
                return secure.checkCommon(PermissionCommonSection.Shops, PermissionRight.Delete);
            },
        };
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            await this.selectShops();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    protected async openModalCreate(): Promise<void> {
        await this.shopModal.show();
    }

    protected async openModalUpdate(shop: IShop): Promise<void> {
        await this.shopModal.show(shop);
    }

    private async confirmDelete(shop: IShop): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить магазин: "${shop.info.name}"?`,
            "Удаление магазина",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(shop);
        }
    }

    private async selectShops(): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            this.shops = await this.ShopUseCase.select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить магазины:\n${e.message}`);
        }
    }

    private async selectShopsForUser(): Promise<void> {
        const shops = await this.$alt.system.usecase.createEmployeeUseCase().selectShops(this.company.id, this.user.id);
        this.$info.setShops(shops);
    }

    private async create(dto: IShopCreateDto): Promise<IShop | null> {
        try {
            this.$alt.loader.show();
            const sh = await this.ShopUseCase.create(this.company.id, dto);
            await this.selectShops();
            this.selectShopsForUser(); // асинхронно
            this.$alt.toast.success("Магазин успешно создан.", "Создание");
            return sh;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать магазин:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async update(shop: IShop, dto: IShopUpdateDto): Promise<IShop | null> {
        try {
            this.$alt.loader.show();
            const sh = await this.ShopUseCase.update(this.company.id, shop.id, dto);
            await this.selectShops();
            this.selectShopsForUser(); // асинхронно
            this.$alt.toast.success(`Магазин "${shop.info.name}" успешно изменён.`, "Изменение");
            return sh;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось изменить магазин:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(shop: IShop): Promise<boolean> {
        try {
            this.$alt.loader.show();
            await this.ShopUseCase.delete(this.company.id, shop.id);
            await this.selectShops();
            this.selectShopsForUser(); // асинхронно
            this.$alt.toast.success(`Магазин "${shop.info.name}" успешно удалён.`, "Удаление");
            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось удалить магазин:\n${e.message}`);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }
}
