import { IOrder } from "@lib";

export default class OrderViewController {
    public show: boolean;
    public order: IOrder | null;

    public onOpening: Function | null;
    public onClosed: Function | null;

    public constructor() {
        this.show = false;
        this.order = null;

        this.onOpening = null;
        this.onClosed = null;
    }

    public async open(order: IOrder): Promise<void> {
        if (this.onOpening) {
            await this.onOpening();
        }

        this.order = order;
        this.show = true;
    }

    public async close(): Promise<void> {
        this.show = false;

        if (this.onClosed) {
            await this.onClosed();
        }
    }
}
