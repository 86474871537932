import { IAuthSocialsUseCase, IAuthRegistrationResultDto, IAuthSocialsDto, IAuthLoginResultDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api, ApiAuth } from "../axios";

export class AuthSocialsUseCase implements IAuthSocialsUseCase {
    public async loadSocialsInfo(): Promise<any> {
        try {
            const response = await Api.get<void>("/auth/socials/info");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async getAuthorizationUri(social: string, redirectUri: string): Promise<string> {
        try {
            const params = { redirect: redirectUri };
            const response = await ApiAuth.get<string>(`/auth/socials/${social}/uri`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async register(social: string, dto: IAuthSocialsDto): Promise<IAuthRegistrationResultDto> {
        try {
            const response = await ApiAuth.post<IAuthRegistrationResultDto>(
                `/auth/socials/${social}/registration`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async login(social: string, dto: IAuthSocialsDto): Promise<IAuthLoginResultDto> {
        try {
            const response = await ApiAuth.post<IAuthLoginResultDto>(`/auth/socials/${social}/login`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async connect(social: string, dto: IAuthSocialsDto): Promise<void> {
        try {
            await Api.post<void>(`/auth/socials/${social}/connect`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async disconnect(social: string): Promise<void> {
        try {
            await Api.post<void>(`/auth/socials/${social}/disconnect`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
