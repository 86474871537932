import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";
import { BSidebar, BButton, BButtonGroup, BFormCheckbox } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export interface IToolboxOptions {
    columns?: boolean;
    export?: string[];
}

const DefaultToolboxOptions = {
    columns: true,
    export: [],
};

@Component({
    name: "va-table-toolbox",
    components: { BSidebar, BButton, BButtonGroup, BFormCheckbox, VuePerfectScrollbar },
})
export default class VaTableToolbox extends Vue {
    @Prop({ type: Object, default: () => {} })
    private toolboxOptions!: IToolboxOptions;

    @Prop({ type: Object, required: true })
    private gridOptions!: any;

    @Prop({ type: Array, required: true })
    private columns!: any[];

    @PropSync("show", { type: Boolean, default: false })
    private showSync!: boolean;

    private columnsSync: any[] = []; // текущие настройки колонок
    private columnsBackup: any[] = []; // первоначальные настройки колонок
    private buttonClicked = false;

    private get options(): IToolboxOptions {
        return { ...DefaultToolboxOptions, ...this.toolboxOptions };
    }

    private get columnApi(): any {
        return this.gridOptions.columnApi;
    }

    @Watch("showSync")
    private onShowChanged(): void {
        if (this.showSync) {
            this.columnsSync = this.$alt.clone(this.columns) as any[];
            for (const column of this.columnsSync) {
                column.visible = !column.hide;
            }
            this.columnsBackup = this.$alt.clone(this.columns) as any[];
            this.buttonClicked = false;
        } else {
            if (!this.buttonClicked) {
                this.onCancel();
            }
        }
    }

    private getColumnHide(column: any): boolean {
        return column.hide === undefined ? false : column.hide;
    }

    private onColumnCheck(column: any): void {
        column.hide = !column.visible;
        this.columnApi.setColumnVisible(column.colId, !column.hide);
    }

    private onExportToCsv(): void {
        // https://www.ag-grid.com/javascript-data-grid/csv-export/
        this.gridOptions.api.exportDataAsCsv({
            //fileName: "",
            columnSeparator: ";",
            processCellCallback: (params: any): string => {
                if (params.column.colId === "actions") {
                    return params.node.rowIndex + 1;
                }

                if (params.column.colDef.export) {
                    return params.column.colDef.export(params);
                }

                return params.value;
            },
            processHeaderCallback: (params: any): string => {
                //console.log(params.column.colDef);
                return params.column.colId;
            },
        });
    }

    private onSave(): void {
        this.buttonClicked = true;

        const columnsData = this.$alt.clone(this.columnsSync);
        this.$emit("save", columnsData);
        this.showSync = false;
    }

    private onCancel(): void {
        this.buttonClicked = true;

        for (const column of this.columnsBackup) {
            const hide = this.getColumnHide(column);
            this.columnApi.setColumnVisible(column.colId, !hide);

            const colSynced = this.columnsSync.find(c => c.colId === column.colId);
            if (colSynced) {
                colSynced.hide = hide;
            }
        }

        this.showSync = false;
    }
}
