import { Vue, Component } from "vue-property-decorator";
import { BTabs, BTab } from "bootstrap-vue";
import VaIcon from "@core/components/va-icon";
import { Html } from "@core/components/alt-ui/controls";
import SettingsCompanyGeneral from "./settings-company-general.vue";
import SettingsCompanyPayment from "./settings-company-payment.vue";
import SettingsCompanyPlugins from "./settings-company-plugins.vue";
import SettingsCompanyOffices from "./settings-company-offices.vue";
import SettingsCompanyShops from "./settings-company-shops.vue";
import SettingsCompanyAccounts from "./settings-company-accounts.vue";
import SettingsCompanyStores from "./settings-company-stores.vue";
import SettingsCompanyEmployees from "./settings-company-employees.vue";
//import SettingsCompanyRoles from "./settings-company-roles.vue";

@Component({
    components: {
        BTabs,
        BTab,
        VaIcon,
        SettingsCompanyGeneral,
        SettingsCompanyPayment,
        SettingsCompanyPlugins,
        SettingsCompanyOffices,
        SettingsCompanyShops,
        SettingsCompanyAccounts,
        SettingsCompanyStores,
        SettingsCompanyEmployees,
        //SettingsCompanyRoles,
    },
})
export default class SettingsCompany extends Vue {
    private activeTab: number = 0;

    private get tabs(): any[] {
        return [
            {
                id: "company",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.company.company") : "",
                iconPack: "alt",
                icon: "office",
                component: "settings-company-general",
            },
            {
                id: "payments",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.company.payments") : "",
                //iconPack: "material-icons",
                icon: "CreditCardIcon",
                component: "settings-company-payment",
            },
            {
                id: "plugins",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.company.plugins") : "",
                icon: "GridIcon",
                component: "settings-company-plugins",
            },
            {
                id: "employees",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.company.employees") : "",
                iconPack: "alt",
                icon: "team",
                component: "settings-company-employees",
            },
            {
                id: "offices",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.company.offices") : "",
                iconPack: "alt",
                icon: "houses",
                component: "settings-company-offices",
            },
            {
                id: "shops",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.company.shops") : "",
                //iconPack: "material-icons",
                icon: "ShoppingCartIcon",
                component: "settings-company-shops",
            },
            {
                id: "accounts",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.company.accounts") : "",
                iconPack: "alt",
                icon: "finance",
                component: "settings-company-accounts",
            },
            {
                id: "stores",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.company.stores") : "",
                //iconPack: "feather",
                icon: "PackageIcon",
                component: "settings-company-stores",
            },
        ];
    }

    public created(): void {
        if (this.$route.params.id) {
            const index = this.tabs.findIndex((t: any) => t.id === this.$route.params.id);
            if (index >= 0) {
                this.activeTab = index;
            }
        }
    }

    public mounted(): void {
        this.initHeader();
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "settings-company.header-title";
        hdrTitle.html = `<h2 class="m-0">${this.$i18n.t("main.settings.company.title")}</h2>`;

        this.$info.ui.setHeaderControls([hdrTitle]);
    }

    private get isLargeScreen(): boolean {
        return this.$info.ui.windowWidth >= 768;
    }

    private changeTab(tabIndex: number): void {
        this.activeTab = tabIndex;

        if (tabIndex === 0) {
            this.$router.push({ name: "settings-company" }).catch(() => {});
            return;
        }

        const tab = this.tabs[tabIndex];
        if (tab) {
            this.$router.push({ name: "settings-company", params: { id: tab.id } }).catch(() => {});
        }
    }
}
