import { ISaleStage, SaleStageType } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, Select } from "@core/components/alt-ui/controls";

export interface ISaleReopenModalContext {
    stages: ISaleStage[];
}

export interface ISaleReopenModalData {
    stage: ISaleStage;
}

export enum SaleReopenModalAnswer {
    Reopen = "reopen",
    Cancel = "cancel",
}

export interface ISaleReopenModalResult {
    answer: SaleReopenModalAnswer;
    data?: ISaleReopenModalData;
}

export class SaleReopenModal extends Modal<ISaleReopenModalContext, ISaleReopenModalResult> {
    private cbStage!: Select<ISaleStage>;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: ISaleReopenModalContext | null = null;

    public constructor() {
        super("sale-reopen-modal", "Открытие продажи");
        this.initializeControls();
    }

    public show(context: ISaleReopenModalContext): Promise<ISaleReopenModalResult> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context);
        return super.show();
    }

    protected initializeControls(): void {
        this.cbStage = new Select<ISaleStage>();
        this.cbStage.id = "sale-reopen.stage";
        this.cbStage.label = "Перевести продажу на этап";
        this.cbStage.items = this.context?.stages ?? [];
        this.cbStage.textField = s => s.name;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "sale-reopen.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "sale-reopen.save";
        this.btnSave.text = "Открыть";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(context: ISaleReopenModalContext): void {
        this.cbStage.selectedIndex = this.cbStage.items.findIndex(s => s.type === SaleStageType.Processing);
    }

    public get controls(): Control[] {
        return [this.cbStage, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide({ answer: SaleReopenModalAnswer.Cancel });
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.cbStage.selectedItem) {
            return;
        }

        const data: ISaleReopenModalData = {
            stage: this.cbStage.selectedItem,
        };

        this.hide({
            answer: SaleReopenModalAnswer.Reopen,
            data: data,
        } as ISaleReopenModalResult);
    }
}
