import { DiscountType, ISale, IShop, PaymentType } from "@lib";
import { Currency, Locale } from "@lib";
import {
    ISaleTableContext,
    ISaleTableContextInfo,
    ISaleTableIterableContext,
    SaleTable2IterableMacroList,
    SaleTable2MacroList,
} from "./sale-table2.macro-list";
import { Table2MacroReplacer } from "./table2.macro-replacer";

export class SaleTable2MacroReplacer extends Table2MacroReplacer {
    public readonly sale: ISale;
    public readonly currency: Currency;
    public readonly locale: Locale;
    public readonly info: ISaleTableContextInfo;

    public constructor(sale: ISale) {
        super(SaleTable2MacroList, SaleTable2IterableMacroList);

        this.sale = sale;
        this.currency = sale?.shopRef?.info?.currency ?? Currency.RUB;
        this.locale = sale?.shopRef?.info?.locale ?? Locale.RU;
        this.info = this.collectInfo();
    }

    public collectInfo(): ISaleTableContextInfo {
        const info = {
            goods: this.sale.goods.reduce(
                (result, good) => {
                    result.quantity += good.quantity;
                    result.sum += good.quantity * good.price;

                    return result;
                },
                { quantity: 0, sum: 0 },
            ),

            payments:
                this.sale.payments?.reduce((result, payment) => {
                    if (payment.type === PaymentType.Prepayment) {
                        result += payment.value;
                    }

                    return result;
                }, 0) ?? 0.0,

            discount: 0.0,
        };

        if (this.sale.info?.discount) {
            const disountTypes = {
                [DiscountType.Fixed]: this.sale.info.discount.value ?? 0.0,
                [DiscountType.Percent]: (info.goods.sum * this.sale.info.discount.value) / 100.0,
            };

            info.discount = disountTypes[this.sale.info.discount.type] ?? 0.0;
        }

        return info;
    }

    public getIterableContext(iterator: number): ISaleTableIterableContext {
        return {
            sale: this.sale,
            locale: this.locale,
            meta: {
                iterator,
            },
        };
    }

    public getContext(): ISaleTableContext {
        return {
            sale: this.sale,
            currency: this.currency,
            locale: this.locale,
            info: this.info,
        };
    }
}
