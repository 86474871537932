import { ISalary, ISalaryPayDto, ISalaryPaymentDto, ISalaryUseCase } from "@lib";
import { ISalaryProcessor } from "./salary-processor";
import { ISalaryTableItem } from "../salary-table";

export class SalaryOrderProcessor implements ISalaryProcessor {
    public async pay(
        companyId: string,
        accountId: string,
        item: ISalaryTableItem,
        usecase: ISalaryUseCase,
    ): Promise<ISalary> {
        const employeeId = item.meta.employee;
        const description = "Выплата за заявку";
        const subject = [
            {
                order: {
                    amount: item.amount,
                    ref: item.meta.order?.id as string,
                },
            },
        ];

        const salaryDto: ISalaryPayDto = {
            account: accountId,
            amount: item.amount,
            subject: subject,
            //comment: undefined,
        };

        const paymentDto: ISalaryPaymentDto = {
            account: accountId,
            description: description,
        };

        return await usecase.payOne(companyId, employeeId, salaryDto, paymentDto);
    }
}
