import {
    IReportUseCase,
    IReportBaseDto,
    IReportMonthlyDto,
    IClientSourcesReportDto,
    IClientSourcesReportResultDto,
    IEmployeeOrderSaleReportDto,
    IEmployeeOrderSaleReportResultDto,
    IStoreReportResultDto,
    IStoreReportDto,
    ISaleGoodsReportDto,
    ISaleGoodsReportResultDto,
} from "@lib";
import { IOrderReportDto, ISaleReportDto } from "@lib";
import { IFinancesReportDto, IFinancesReportResultDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class ReportUseCase implements IReportUseCase {
    public async base(companyId: string, dto: IReportBaseDto): Promise<IReportMonthlyDto> {
        try {
            const params = dto;
            const response = await Api.get<IReportMonthlyDto>(`/companies/${companyId}/reports/base`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async orders(companyId: string, dto: IReportBaseDto): Promise<IOrderReportDto[]> {
        try {
            const params = dto;
            const response = await Api.get<IOrderReportDto[]>(`/companies/${companyId}/reports/orders`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async sales(companyId: string, dto: IReportBaseDto): Promise<ISaleReportDto[]> {
        try {
            const params = dto;
            const response = await Api.get<ISaleReportDto[]>(`/companies/${companyId}/reports/sales`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async finances(companyId: string, dto: IFinancesReportDto): Promise<IFinancesReportResultDto> {
        try {
            const params = dto;
            const response = await Api.get<IFinancesReportResultDto>(`/companies/${companyId}/reports/finances`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async clients(companyId: string, dto: IClientSourcesReportDto): Promise<IClientSourcesReportResultDto[]> {
        try {
            const params = dto;
            const response = await Api.get<IClientSourcesReportResultDto[]>(`/companies/${companyId}/reports/clients`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async employeeOrderSale(
        companyId: string,
        dto: IEmployeeOrderSaleReportDto,
    ): Promise<IEmployeeOrderSaleReportResultDto[]> {
        try {
            const params = dto;
            const response = await Api.get<IEmployeeOrderSaleReportResultDto[]>(
                `/companies/${companyId}/reports/employee-order-sale`,
                { params },
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async stores(companyId: string, dto: IStoreReportDto): Promise<IStoreReportResultDto> {
        try {
            const params = dto;
            const response = await Api.get<IStoreReportResultDto>(`/companies/${companyId}/reports/stores`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async saleGoods(companyId: string, dto: ISaleGoodsReportDto): Promise<ISaleGoodsReportResultDto> {
        try {
            const params = dto;
            const response = await Api.get<ISaleGoodsReportResultDto>(`/companies/${companyId}/reports/saleGoods`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
