import { Composite } from "@core/components/alt-ui/controls";
import { FieldControl } from "../field-control";
import { FieldControlFactory } from "../field-control-factory";
import { Uuid } from "@/utils/uuid";

export class CompositeFieldControl extends FieldControl<Composite> {
    private _elements: FieldControl[] | undefined;

    protected convert(): Composite {
        this._elements = this.field.elements?.map(e => FieldControlFactory.fromField(e, this.context)) ?? [];

        const control = new Composite();
        control.id = `${this.field.id}_${Uuid.short()}`;
        control.label = this.field.title ?? "";
        control.help = this.field.description ?? "";
        control.elements = this.elements.map(e => e.control);
        return control;
    }

    public get elements(): FieldControl[] {
        return this._elements ?? [];
    }

    public get value(): any {
        return this.elements.map(e => e.value);
    }

    public set value(value: any) {
        if (!Array.isArray(value) || value.length !== this.elements.length) {
            return;
        }

        for (let i = 0; i < this.elements.length; ++i) {
            this.elements[i].value = value[i];
        }
    }

    public getValueFormatted(): string {
        // TODO: разделитель???
        return this.elements.map(e => e.getValueFormatted()).join();
    }
}
