import { Vue, Component } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BButton, BForm, BFormInput, BFormGroup, BCard, BLink } from "bootstrap-vue";
import { BCardTitle, BCardText, BFormCheckbox } from "bootstrap-vue";
import { BInputGroup, BInputGroupPrepend, BInputGroupAppend } from "bootstrap-vue";
import { IAdminAuthSpyLoginDto, IAuthLoginResultDto } from "@lib";
import VaLogo from "@/components/common/va-logo/va-logo-full.vue";

@Component({
    components: {
        BCard,
        BCardTitle,
        BLink,
        BCardText,
        BButton,
        BFormCheckbox,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        VaLogo,
    },
})
export default class SpyLogin extends Vue {
    private resources = {
        LabelTitleText: "ВХОД",
        LabelSubtitle1Text: "Приветствуем!",
        LabelSubtitle2Text: "Пожалуйста, войдите в аккаунт:",
        TextBoxEmailLabel: "Почта",
        TextBoxPasswordLabel: "Пароль",
        TextBoxTargetLabel: "Целевая почта",
        ButtonText: "Вход",
        ValidatorId: "loginForm",
    };

    private email: string = "";
    private password: string = "";
    private passwordVisible: boolean = false;
    private target: string = "";

    private togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    private async login(): Promise<void> {
        try {
            const valid = await (this.$refs[this.resources.ValidatorId] as any).validate();
            if (!valid) {
                return;
            }

            this.$alt.loader.show();

            const dto: IAdminAuthSpyLoginDto = {
                email: this.email,
                password: this.password,
                target: this.target,
            };

            const service = this.$alt.system.usecase.createAdminAuthUseCase();
            const result = await service.spyLogin(dto);

            if (result.data.company) {
                // company token
                this.applyCompanyData(result);
                // const navigate = (this.$router.currentRoute.query.to as string) ?? "/home";
                // await this.$router.push(navigate);
                await this.$router.push({ name: "orders" });
            } else {
                // user-only token
                this.applyUserOnlyData(result);
                await this.$router.push({ name: "switch-company" });
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async applyCompanyData(result: IAuthLoginResultDto): Promise<void> {
        this.$alt.system.token.cleanData();
        this.$alt.system.token.setCompanyToken(result.accessToken);

        this.$info.setAuthInfo(result.data);
        this.$settings.setUserInfo(result.data.user.user.id);
        if (result.data.company) {
            this.$secure.grant(result.data.company.permissions);
        }
    }

    private async applyUserOnlyData(result: IAuthLoginResultDto): Promise<void> {
        this.$alt.removeFromLocalStorage("companyType");
        this.$alt.removeFromLocalStorage("partner");
        this.$alt.removeFromLocalStorage("meta");

        this.$alt.system.token.cleanData();
        this.$alt.system.token.setUserOnlyToken(result.accessToken);

        this.$info.setAuthInfo(result.data);
        this.$settings.setUserInfo(result.data.user.user.id);
    }
}
