import { IAccount, IClientSource, IField, IProductType, IValidation, Locale } from "@lib";
import { Control } from "@core/components/alt-ui/controls";

export interface IFieldControlContext {
    locale?: Locale;
    clientSources?: IClientSource[];
    productTypes?: IProductType[];
    accounts?: IAccount[];
}

export abstract class FieldControl<TControl = Control> {
    protected readonly context?: IFieldControlContext;
    public readonly field: IField;
    public readonly control: TControl;

    public constructor(field: IField, context?: IFieldControlContext) {
        this.context = context;
        this.field = field;
        this.control = this.convert();
    }

    protected abstract convert(): TControl;

    public get id(): string {
        return this.field.id;
    }

    public get value(): any {
        return null;
    }

    public set value(value: any) {
        //
    }

    public getValueFormatted(): string {
        return this.value ? String(this.value) : "";
    }

    protected validationToString(validation?: IValidation): string {
        if (!validation) {
            return "";
        }

        let result = "";
        if (validation.required) {
            if (result.length > 0) {
                result += "|";
            }
            result += "required";
        }
        if (validation.email) {
            if (result.length > 0) {
                result += "|";
            }
            result += "email";
        }
        if (validation.money) {
            if (result.length > 0) {
                result += "|";
            }
            result += "money";
        }
        if (validation.unsigned) {
            if (result.length > 0) {
                result += "|";
            }
            result += "unsigned";
        }

        return result;
    }
}
