import { render, staticRenderFns } from "./sale-search-goods-old.vue?vue&type=template&id=2a04c18e&scoped=true&"
import script from "./sale-search-goods-old.ts?vue&type=script&lang=ts&"
export * from "./sale-search-goods-old.ts?vue&type=script&lang=ts&"
import style0 from "./sale-search-goods-old.vue?vue&type=style&index=0&id=2a04c18e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a04c18e",
  null
  
)

export default component.exports