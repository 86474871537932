/** Форматирование значения поля. */
export enum FieldFormat {
    /** Числовой. */
    Number = "number",

    /** Денежный. */
    Money = "money",

    /** Телефон. */
    Phone = "phone",
}
