/** Тип выплаты. */
export enum SalaryType {
    /** За заявки. */
    Orders = "orders",

    /** За работы. */
    Works = "works",

    /** За материалы. */
    Materials = "materials",

    /** За продажи. */
    Sales = "sales",
}
