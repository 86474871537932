import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import { BTabs, BTab } from "bootstrap-vue";
import { IOrder, IOrderWork, IOrderMaterial, IOrderStage, IAccount, IStore } from "@lib";
import OrderViewTimeline from "./order-view-tabs-timeline.vue";
import OrderViewWnm from "./order-view-tabs-wnm.vue";
import OrderViewPayments from "./order-view-tabs-payments.vue";
import OrderViewTasks from "./order-view-tabs-tasks.vue";
import VaCard from "@/components/common/va-card";

@Component({
    name: "order-view-tabs",
    components: {
        BTabs,
        BTab,
        OrderViewTimeline,
        OrderViewWnm,
        OrderViewPayments,
        OrderViewTasks,
        VaCard,
    },
})
export default class OrderViewTabs extends Vue {
    @Prop({ required: true })
    private order!: IOrder | null;

    @PropSync("timeline", { type: Array, required: true })
    private timelineSynced!: any[];

    @PropSync("works", { type: Array, required: true })
    private worksSynced!: IOrderWork[];

    @PropSync("materials", { type: Array, required: true })
    private materialsSynced!: IOrderMaterial[];

    @Prop({ type: Array, default: () => {} })
    private accounts!: IAccount[];

    @Prop({ type: Array, default: () => {} })
    private stores!: IStore[];

    @Prop({ type: Array, default: () => {} })
    private stages!: IOrderStage[];

    @Prop({ type: Array, default: () => [] })
    private employees!: any[];

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private changeOrder(order: IOrder, orderOld: IOrder): void {
        this.$emit("order-changed", order, orderOld);
    }

    private get isLargeScreen(): boolean {
        return this.$info.ui.windowWidth >= 768;
    }
}
