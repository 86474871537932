import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import AltInput from "@core/components/alt-controls/alt-input.vue";
import { PasswordBox } from "./password-box";

@Component({
    name: "password-box-component",
    components: { BInputGroup, BInputGroupAppend, AltInput, ValidationProvider },
})
export class PasswordBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: PasswordBox;

    private passwordVisible: boolean = false;

    private togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.handler.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderInput(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        // items.push(<b-form-input
        //     id={this.handler.id}
        //     type={this.handler.type}
        //     class={this.handler.class}
        //     style={this.handler.style}
        //     placeholder={this.handler.placeholder}
        //     disabled={this.handler.disabled}
        //     autocomplete="off"
        //     state={errors.length > 0 ? false : null}
        //     v-model={this.handler.text}
        // />);

        items.push(
            <b-input-group class={`input-group-merge ${errors.length > 0 ? "is-invalid" : ""}`}>
                <alt-input
                    id={this.handler.id}
                    type={this.passwordVisible ? "text" : "password"}
                    class={this.handler.class}
                    style={this.handler.style}
                    placeholder={this.handler.placeholder}
                    disabled={this.handler.disabled}
                    autocomplete="off"
                    state={errors.length > 0 ? false : null}
                    v-model={this.handler.text}
                />
                <b-input-group-append is-text>
                    <feather-icon
                        class="cursor-pointer"
                        icon={this.passwordVisible ? "EyeOffIcon" : "EyeIcon"}
                        v-on:click={this.togglePasswordVisibility}
                    />
                </b-input-group-append>
            </b-input-group>,
        );

        items.push(<small class="text-danger">{errors[0]}</small>);
        return items;
    }
}
