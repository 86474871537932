import { ISale } from "@lib";
import { Sidebar } from "@core/components/alt-ui/sidebar";
import { Control } from "@core/components/alt-ui/controls";

type SaleViewEventHandler = (sale: ISale) => Promise<void>;

export interface ISaleViewFormContext {
    sale: ISale;
    onComplete?: SaleViewEventHandler;
    onReopen?: SaleViewEventHandler;
    onDelete?: SaleViewEventHandler;
}

export class SaleViewForm extends Sidebar<ISaleViewFormContext> {
    public sale: ISale | null = null;
    public onComplete: SaleViewEventHandler | null = null;
    public onReopen: SaleViewEventHandler | null = null;
    public onDelete: SaleViewEventHandler | null = null;

    public onOpening: (() => void) | null = null;
    public onClosed: (() => void) | null = null;

    private context: ISaleViewFormContext | null = null;

    public constructor() {
        super("sale-view-form");
    }

    public show(context: ISaleViewFormContext): Promise<void> {
        this.context = context;

        if (this.onOpening) {
            this.onOpening();
        }

        this.sale = this.context.sale;
        this.onComplete = this.context.onComplete ?? null;
        this.onReopen = this.context.onReopen ?? null;
        this.onDelete = this.context.onDelete ?? null;

        return super.show();
    }

    public hide(): void {
        super.hide();

        if (this.onClosed) {
            this.onClosed();
        }
    }

    public get controls(): Control[] {
        return [];
    }
}
