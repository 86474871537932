import { IPluginBindingUseCase, IPluginBinding, PluginType } from "@lib";
import { IPluginBindingDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class PluginBindingUseCase implements IPluginBindingUseCase {
    public async select(companyId: string): Promise<IPluginBinding[]> {
        try {
            const response = await Api.get<IPluginBinding[]>(`/companies/${companyId}/plugins`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectByType(companyId: string, type: PluginType): Promise<IPluginBinding[]> {
        throw new Error("Method not implemented.");
    }

    public async get(companyId: string, id: string): Promise<IPluginBinding> {
        throw new Error("Method not implemented.");
    }

    public async bind(companyId: string, dto: IPluginBindingDto): Promise<IPluginBinding> {
        try {
            const response = await Api.post<IPluginBinding>(`/companies/${companyId}/plugins`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async configure(companyId: string, id: string, configuration: any): Promise<IPluginBinding> {
        try {
            const dto = configuration;
            const response = await Api.put<IPluginBinding>(`/companies/${companyId}/plugins/${id}/configuration`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async enable(companyId: string, id: string, enabled: boolean): Promise<IPluginBinding> {
        try {
            const response = await Api.put<IPluginBinding>(`/companies/${companyId}/plugins/${id}/enable`, {
                enabled,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async unbind(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete(`/companies/${companyId}/plugins/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
