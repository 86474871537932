import { Vue, Component } from "vue-property-decorator";
import { BTabs, BTab } from "bootstrap-vue";
import { Html } from "@core/components/alt-ui/controls";
import SettingsTemplatesDocuments from "./settings-templates-documents.vue";
import SettingsTemplatesSms from "./settings-templates-sms.vue";
import SettingsTemplatesLabels from "./settings-templates-labels.vue";

@Component({
    components: {
        BTabs,
        BTab,
        SettingsTemplatesDocuments,
        SettingsTemplatesSms,
        SettingsTemplatesLabels,
    },
})
export default class SettingsTemplates extends Vue {
    private activeTab: number = 0;

    private get tabs(): any[] {
        return [
            {
                id: "documents",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.templates.documents") : "",
                //iconPack: "feather",
                icon: "FileIcon",
                component: "settings-templates-documents",
            },
            {
                id: "sms",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.templates.sms") : "",
                //iconPack: "feather",
                icon: "MessageCircleIcon",
                component: "settings-templates-sms",
            },
            {
                id: "labels",
                label: this.isLargeScreen && this.$i18n ? this.$i18n.t("main.settings.templates.labels") : "",
                //iconPack: "feather",
                icon: "TagIcon",
                component: "settings-templates-labels",
            },
        ];
    }

    public created(): void {
        if (this.$route.params.id) {
            const index = this.tabs.findIndex((t: any) => t.id === this.$route.params.id);
            if (index >= 0) {
                this.activeTab = index;
            }
        }
    }

    public mounted(): void {
        this.initHeader();
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "settings-templetes.header-title";
        hdrTitle.html = `<h2 class="m-0">${this.$i18n.t("main.settings.templates.title")}</h2>`;

        this.$info.ui.setHeaderControls([hdrTitle]);
    }

    private get isLargeScreen(): boolean {
        return this.$info.ui.windowWidth >= 768;
    }

    private changeTab(tabIndex: number): void {
        this.activeTab = tabIndex;

        if (tabIndex === 0) {
            this.$router.push({ name: "settings-templates" }).catch(() => {});
            return;
        }

        const tab = this.tabs[tabIndex];
        if (tab) {
            this.$router.push({ name: "settings-templates", params: { id: tab.id } }).catch(() => {});
        }
    }
}
