import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { IOffice, IOrder, IOrderProduct, IProductType, OrderTypeFieldGroupType } from "@lib";
import { IOrderProductChangeDto, IOrderType, IOrderTypeField, IOrderTypeFieldGroup } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import VaIcon from "@core/components/va-icon/va-icon.vue";
import { FieldControl } from "@core/types/field-controls/field-control";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { AppException } from "@/exceptions";

@Component({
    components: { BButton, ValidationObserver, ControlComponent, VaIcon },
})
export default class OrderViewProduct extends Vue {
    @Prop({ type: Object, required: true })
    private order!: IOrder;

    @Prop({ type: Object, required: true })
    private product!: IOrderProduct;

    @Prop({ type: Array, required: true })
    private productTypes!: IProductType[];

    @Prop({ required: true })
    private orderType!: IOrderType;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private visible = true;
    private editMode = false;
    private fieldGroup: IOrderTypeFieldGroup | null = null;
    private controls: FieldControl[] = [];

    public get title(): string {
        return this.fieldGroup?.title ?? "Устройство";
    }

    public get icon(): string {
        return this.fieldGroup?.icon ?? "monitor";
    }

    @Watch("product", { immediate: true, deep: true })
    private onProductChanged(): void {
        this.initValues();
    }

    @Watch("orderType", { immediate: true, deep: true })
    private onOrderTypeChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        if (!this.product || !this.orderType) {
            return;
        }

        this.editMode = false;
        this.fieldGroup = this.orderType.groups.find(field => field.id === OrderTypeFieldGroupType.Product) ?? null;
        this.visible = this.getFields().length > 0;

        this.initControls();
    }

    private initControls(): void {
        this.controls = [];

        const context = {
            locale: this.order.officeRef?.info?.locale,
            productTypes: this.productTypes,
        };

        for (const field of this.getFields()) {
            const fieldControl = FieldControlFactory.fromField(field, context);
            fieldControl.value = this.getFieldValue(this.product, field);
            this.controls.push(fieldControl);
        }
    }

    public getFields(): IOrderTypeField[] {
        return (
            this.fieldGroup?.fields
                .filter(f => !f.hidden)
                .sort((a, b) =>
                    a.sequence !== undefined && b.sequence !== undefined ? a.sequence - b.sequence : -1,
                ) ?? []
        );
    }

    public getFieldValue(product: IOrderProduct, field: IOrderTypeField): any {
        if (field.custom) {
            // TODO: remove details1
            const id = field.customId ?? field.details1 ?? field.id;
            return product.custom ? product.custom[id] : "";
        }

        return this.getStandartFieldValue(product, field);
    }

    private getStandartFieldValue(product: IOrderProduct, field: IOrderTypeField): any {
        switch (field.id) {
            case "product.type":
                return product.info?.type;
            case "product.sku":
                return product.info?.sku;
            case "product.name":
                return product.info?.name;
            case "product.serial":
                return product.info?.serial;
            case "product.suite":
                return product.info?.suite;
            case "product.appearance":
                return product.info?.appearance;
            case "product.description":
                return product.info?.description;
            case "product.brand":
                return product.info?.brand;
            case "product.model":
                return product.info?.model;
        }

        return "";
    }

    private createDto(): IOrderProductChangeDto {
        const product = this.$alt.clone(this.product);

        for (const fieldControl of this.controls) {
            const value = fieldControl.value;
            this.populateCustomFieldValue(product, fieldControl.field, value);
            this.populateStandartFieldValue(product, fieldControl.field, value);
        }

        return {
            info: product.info,
            custom: product.custom,
        };
    }

    private populateCustomFieldValue(product: IOrderProduct, field: IOrderTypeField, value: any): void {
        if (field.custom) {
            if (!product.custom) {
                product.custom = {};
            }

            // TODO: remove details1
            const id = field.customId ?? field.details1 ?? field.id;
            product.custom[id] = value;
            return;
        }
    }

    private populateStandartFieldValue(product: IOrderProduct, field: IOrderTypeField, value: any): void {
        if (!product.info) {
            product.info = {} as any;
        }

        switch (field.id) {
            case "product.type":
                product.info.type = value;
                return;
            case "product.sku":
                product.info.sku = value;
                return;
            case "product.name":
                product.info.name = value;
                return;
            case "product.serial":
                product.info.serial = value;
                return;
            case "product.suite":
                product.info.suite = value;
                return;
            case "product.appearance":
                product.info.appearance = value;
                return;
            case "product.description":
                product.info.description = value;
                return;
            case "product.brand":
                product.info.brand = value;
                return;
            case "product.model":
                product.info.model = value;
                return;
        }
    }

    public startEditProduct(): void {
        this.initControls();
        this.editMode = true;
    }

    public async applyEditProduct(): Promise<void> {
        try {
            const result = await (this.$refs["orderProductValidator"] as any).validate();
            if (!result) {
                return;
            }

            if (!this.order) {
                throw new AppException("Заявка не задана.");
            }

            if (!this.product) {
                throw new AppException("Устройство не задано.");
            }

            const dto = this.createDto();

            const usecase = this.$alt.system.usecase.createOrderUseCase();
            const order = await usecase.changeProduct(
                this.order.company as string,
                this.order.id,
                this.product.id,
                dto,
            );

            this.editMode = false;
            this.$emit("order-changed", order);
        } catch {
            this.$alt.toast.error("Не удалось изменить устройство.");
        }
    }

    public cancelEditProduct(): void {
        this.initValues();
    }
}
