import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton, BTooltip } from "bootstrap-vue";
import VueSelect from "vue-select";
import { IEmployee, ISale } from "@lib";

@Component({
    name: "sale-view-seller",
    components: { BButton, BTooltip, VueSelect },
})
export default class SaleViewSeller extends Vue {
    @Prop({ required: true })
    private sale!: ISale | null;

    @Prop({ type: Array, required: true })
    private employees!: IEmployee[];

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private sellerIdBackup: string = "";
    private sellerId: string = "";

    private get isChanged(): boolean {
        return this.sellerId !== this.sellerIdBackup;
    }

    @Watch("sale", { immediate: true, deep: true })
    private onSaleChanged(): void {
        this.initValues();
    }

    private async initValues(): Promise<void> {
        if (this.sale && this.sale.sellerRef) {
            this.sellerId = this.sale.sellerRef?.id;
            this.sellerIdBackup = this.sellerId;
        } else {
            const user = await this.$info.getUser();
            this.sellerId = user.id;
            this.sellerIdBackup = this.sellerId;
        }
    }

    private async applyChanges(): Promise<void> {
        try {
            if (this.sellerId === "") {
                throw new Error("Сотрудник не задан.");
            }

            if (!this.sale) {
                throw new Error("Заявка не задана.");
            }

            const companyId = this.sale.company;
            const saleId = this.sale.id;
            const sellerId = this.sellerId;

            const usecase = this.$alt.system.usecase.createSaleUseCase();
            const sale = await usecase.changeSeller(companyId, saleId, sellerId);

            this.$emit("sale-changed", sale);
        } catch {
            this.$alt.toast.error("Не удалось изменить сотрудника.");
        }
    }

    private async cancelChanges(): Promise<void> {
        await this.initValues();
    }
}
