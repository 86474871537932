import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import { BTabs, BTab } from "bootstrap-vue";
import { IAccount, IStore, ISale, ISaleStage, ISaleGood, IDiscount } from "@lib";
import SaleViewTabsGoods from "./sale-view-tabs-goods.vue";
import SaleViewTabsTimeline from "./sale-view-tabs-timeline.vue";
import SaleViewTabsPayments from "./sale-view-tabs-payments.vue";
import VaCard from "@/components/common/va-card";

@Component({
    name: "sale-view-tabs",
    components: {
        BTabs,
        BTab,
        SaleViewTabsGoods,
        SaleViewTabsTimeline,
        SaleViewTabsPayments,
        VaCard,
    },
})
export default class SaleViewTabs extends Vue {
    @Prop({ required: true })
    private sale!: ISale;

    @Prop({ type: Array, default: () => {} })
    private stages!: ISaleStage[];

    @Prop({ type: Array, default: () => {} })
    private accounts!: IAccount[];

    @Prop({ type: Array, default: () => {} })
    private stores!: IStore[];

    @Prop({ type: Array, default: () => [] })
    private employees!: any[];

    @Prop({ default: () => null })
    private discount!: IDiscount | null;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private changeSale(sale: ISale, saleOld: ISale): void {
        this.$emit("sale-changed", sale, saleOld);
    }

    private get isLargeScreen(): boolean {
        return this.$info.ui.windowWidth >= 768;
    }
}
