import { IEmployeeUseCase, IEmployee } from "@lib";
import { IOffice, IPermission, IShop, IAccount, IStore } from "@lib";
import { IEmployeeCreateDto, IEmployeeUpdateDto, IEmployeeSettingsUpdateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class EmployeeUseCase implements IEmployeeUseCase {
    public async select(companyId: string): Promise<IEmployee[]> {
        try {
            const response = await Api.get<IEmployee[]>(`/companies/${companyId}/employees`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, userId: string): Promise<IEmployee> {
        try {
            const response = await Api.get<IEmployee>(`/companies/${companyId}/employees/${userId}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IEmployeeCreateDto): Promise<IEmployee> {
        try {
            const response = await Api.post<IEmployee>(`/companies/${companyId}/employees`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, userId: string, dto: IEmployeeUpdateDto): Promise<IEmployee> {
        try {
            const response = await Api.put<IEmployee>(`/companies/${companyId}/employees/${userId}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async updateSettings(
        companyId: string,
        userId: string,
        dto: IEmployeeSettingsUpdateDto,
    ): Promise<IEmployee> {
        try {
            const response = await Api.put<IEmployee>(`/companies/${companyId}/employees/${userId}/settings`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, userId: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/employees/${userId}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    //

    public async selectPermissions(companyId: string, userId: string): Promise<IPermission> {
        try {
            const response = await Api.get<IPermission>(`/companies/${companyId}/employees/${userId}/permissions`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectOffices(companyId: string, userId: string): Promise<IOffice[]> {
        try {
            const response = await Api.get<IOffice[]>(`/companies/${companyId}/employees/${userId}/offices`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectShops(companyId: string, userId: string): Promise<IShop[]> {
        try {
            const response = await Api.get<IShop[]>(`/companies/${companyId}/employees/${userId}/shops`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectAccounts(companyId: string, userId: string): Promise<IAccount[]> {
        try {
            const response = await Api.get<IAccount[]>(`/companies/${companyId}/employees/${userId}/accounts`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectStores(companyId: string, userId: string): Promise<IStore[]> {
        try {
            const response = await Api.get<IStore[]>(`/companies/${companyId}/employees/${userId}/stores`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
