import {
    IOrder,
    IOrderWork,
    IOrderMaterial,
    PaymentType,
    DiscountType,
    IOffice,
    IDocument,
    Locale,
    Currency,
} from "@lib";
import { Warranty } from "@core/types/common/warranty";
import { IMacroReplacer } from "../macro";
import * as filters from "@/filters";

export class OrderTableMacroReplacer implements IMacroReplacer<IDocument> {
    private readonly order: IOrder;
    private readonly styles: any;
    private readonly currency: Currency;
    private readonly locale: Locale;

    public constructor(order: IOrder) {
        this.order = order;
        this.styles = {
            table: "width: 100%; border: 0px solid black; border-collapse: collapse;",
            tr: "border: 1px solid black;",
            th: "border: 1px solid black; font-weight: bold;",
            td: "border: 1px solid black; padding: 1px 6px;",
            tdResultTitle: "text-align: right; border: 0px; padding: 1px 6px; font-weight: bold;",
            tdResultValue: "text-align: right; border: 1px solid black; padding: 1px 6px; font-weight: bold;",
        };

        this.currency = order?.officeRef?.info?.currency ?? Currency.RUB;
        this.locale = order?.officeRef?.info?.locale ?? Locale.RU;
    }

    public replace(model: IDocument): string {
        return this.replaceSimple(model.template, model);
    }

    public replaceSimple(template: string, model?: IDocument): string {
        const regexp = /%\(таблица\s*=\s*(?<table>([^~]|~(?!~))*)~~\)/giu;
        // \s* - любое количество пробельных символов
        // [^~] - любой символ, кроме тильды
        // ~(?!~) - тильда, если сразу за ней идёт не тильда
        // (?<table>([^~]|~(?!~))*) - все символы, кроме двух тильд подряд записать в переменную table

        /*const results = template.matchAll(regexp);
        for (const result of results) {
            if (!result.groups) break;

            const json = result.groups["table"];
            alert(json);

            const obj = JSON.parse(json);
            alert(JSON.stringify(obj));
        }*/

        return template.replace(regexp, (match: string, tableJson: string) => {
            try {
                // убираем html-тэги, которые могут присутствовать из-за переноса строк
                tableJson = tableJson.replaceAll(/<[^>]+>/gi, "");
                tableJson = tableJson.replaceAll("&nbsp;", "");
                const table = JSON.parse(tableJson);
                return this.makeTable(table);
            } catch {
                //console.warn(`Не удалось раскрыть макрос: ${match}.`);
                return match;
            }
        });
    }

    private makeTable(table: any): string {
        const rows = table["строки"] as any[];
        const columns = table["колонки"] as any[];
        const total = table["итого"] as any[];
        const settings = table["настройки"] as any;

        const summary = {
            number: 0,
            quantity: 0,
            sum: 0,
            discount: this.order.info?.discount?.value ?? 0,
            paid: this.getOrderPaymentsSum(),
        };

        let result = `<table style="${this.styles.table}"><tbody>`;
        result += this.makeTableHeader(columns, settings);
        result += this.makeTableRows(rows, summary, columns, settings);
        result += this.makeTableTotal(total, summary, columns, settings);
        result += "</tbody></table>";
        return result;
    }

    private getSettingByPath(settings: any, path: string): any {
        if (!settings) {
            return undefined;
        }

        let setting: any = settings;
        const parts = path.split(".");
        for (const part of parts) {
            if (setting[part] === undefined) {
                return undefined;
            }

            setting = setting[part];
        }

        return setting;
    }

    private makeTableHeader(columns: any[], settings: any): string {
        // настройки.заголовок.скрыто
        const trHidden = this.getSettingByPath(settings, "заголовок.скрыто") ?? "нет";
        // настройки.заголовок.стиль
        const trStyle = this.getSettingByPath(settings, "заголовок.стиль") ?? "";

        if (trHidden === "да") {
            return "";
        }

        let result = "";
        result += `<tr style="${this.styles.tr} ${trStyle}">`;
        for (const column of columns) {
            const title = column["заголовок"];
            result += `<th style="${this.styles.th}">${title}</th>`;
        }
        result += "</tr>";
        return result;
    }

    private makeTableRows(rows: any[], summary: any, columns: any[], settings: any): string {
        let result = "";
        for (const row of rows) {
            const field = row["поле"];
            const hidden = row["скрыто"] ? row["скрыто"] === "да" : false;

            const items = this.getWorkMaterialItems(field);
            if (items) {
                for (const item of items) {
                    summary["quantity"] += item.quantity;
                    summary["sum"] += item.quantity * item.price;

                    if (hidden) {
                        continue;
                    }

                    summary["number"] += 1;
                    result += this.makeTableRow(item, summary["number"], columns, settings);
                }
            }
        }
        return result;
    }

    private makeTableRow(item: IOrderWork | IOrderMaterial, number: number, columns: any[], settings: any): string {
        // настройки.строки.стиль
        const trStyle = this.getSettingByPath(settings, "строки.стиль") ?? "";

        let result = "";
        result += `<tr style="${this.styles.tr} ${trStyle}">`;
        for (const column of columns) {
            const field = column["поле"] ?? "";
            const style = column["стиль"] ?? "";
            const value = this.getWorkMaterialValue(field, item, number);
            result += `<td style="${this.styles.td} ${style}">${value}</td>`;
        }
        result += "</tr>";
        return result;
    }

    private makeTableTotal(total: any[], summary: any, columns: any[], settings: any): string {
        let result = "";
        for (const item of total) {
            const field = item["поле"];
            const title = item["заголовок"];
            const condition = item["условие"] ?? "да";
            const value = this.getTotalValue(field, summary);

            if (!this.evaluateConditions(condition, summary)) {
                continue;
            }

            result += "<tr>";
            result += `<td colspan="${columns.length - 1}" style="${this.styles.tdResultTitle}">${title}:</td>`;
            result += `<td style="${this.styles.tdResultValue}">${filters.money.moneyFormat(value, {
                locale: this.locale,
            })}</td>`;
            result += "</tr>";
        }
        return result;
    }

    private getWorkMaterialItems(field: string): (IOrderWork | IOrderMaterial)[] | undefined {
        switch (field.toLowerCase()) {
            case "работы":
                return this.order.works;
            case "материалы":
                return this.order.materials;
            case "работы+материалы":
                return this.getWorkPlusMaterialItems(this.order.works, this.order.materials);
        }

        throw new Error(`Неизвестный тип поля ${field}.`);
    }

    private getWorkPlusMaterialItems(
        works: IOrderWork[],
        materials: IOrderMaterial[],
    ): (IOrderWork | IOrderMaterial)[] {
        const wnm: (IOrderWork | IOrderMaterial)[] = [];
        for (const work of works) {
            let cost = 0;
            let price = work.price;
            const workMaterials = materials.filter(m => m.work === work.id);
            for (const material of workMaterials) {
                cost += material.cost;
                price += material.price;
            }

            wnm.push({
                ...work,
                cost: cost,
                price: price,
            });
        }
        return wnm;
    }

    private getWorkMaterialValue(field: string, workMaterial: IOrderWork | IOrderMaterial, number: number): string {
        switch (field.toLowerCase()) {
            case "номер":
                return number.toString();
            case "наименование":
                return workMaterial.name;
            case "описание":
                return workMaterial.description ?? "";
            case "гарантия":
                return Warranty.toString(workMaterial.warranty);
            case "количество":
                return workMaterial.quantity.toString();
            case "себестоимость":
                return workMaterial.cost ? filters.money.moneyFormat(workMaterial.cost, { locale: this.locale }) : "";
            case "цена":
                return filters.money.moneyFormat(workMaterial.price, { locale: this.locale });
            case "сумма":
                return filters.money.moneyFormat(workMaterial.quantity * workMaterial.price, { locale: this.locale });
        }

        throw new Error(`Неизвестный тип поля ${field}.`);
    }

    private getTotalValue(field: string, summary: any): number {
        const discoutValue = this.getDiscountValue(summary["sum"]);
        switch (field.toLowerCase()) {
            case "сумма":
                return summary["sum"];
            case "скидка":
                return discoutValue;
            case "оплачено":
                return summary["paid"];
            case "сумма-скидка":
                return summary["sum"] - discoutValue;
            case "сумма-оплачено":
                return summary["sum"] - summary["paid"];
            case "сумма-скидка-оплачено":
                return summary["sum"] - discoutValue - summary["paid"];
        }

        throw new Error(`Неизвестный тип поля ${field}.`);
    }

    private getOrderPaymentsSum(): number {
        if (!this.order.payments) {
            return 0;
        }

        let sum = 0;
        for (const payment of this.order.payments) {
            if (payment.type === PaymentType.Prepayment) {
                sum += payment.value;
            }
        }

        return sum;
    }

    private getDiscountValue(sum: number): number {
        if (!this.order.info?.discount) {
            return 0.0;
        }

        const discount = this.order.info.discount.value ?? 0.0;
        switch (this.order.info.discount.type) {
            case DiscountType.Fixed:
                return discount;
            case DiscountType.Percent:
                return (sum * discount) / 100;
        }

        return 0.0;
    }

    private evaluateConditions(condition: string, summary: any): boolean {
        switch (condition.toLowerCase()) {
            case "да":
                return true;
            case "нет":
                return false;
            case "скидка == 0":
                return summary["discount"] === 0;
            case "скидка != 0":
                return summary["discount"] !== 0;
            case "оплачено == 0":
                return summary["paid"] === 0;
            case "оплачено != 0":
                return summary["paid"] !== 0;
        }

        return false;
    }
}
