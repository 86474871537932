import {
    IOrder,
    IOrderStage,
    IPluginBinding,
    IPluginNotificationSendDto,
    ISms,
    OrderStageType,
    PluginType,
} from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    CheckBox,
    CheckBoxValueChangedEventArgs,
    ComboBoxChangedEventArgs,
    Control,
    Label,
    Panel,
    PhoneBox,
    Select,
    TextArea,
} from "@core/components/alt-ui/controls";
import { IOrderViewCompleteModalContext, OrderViewCompleteModal } from "./order-view-form/order-view-complete.modal";
import { Printer } from "@/@core/usecases/template/printer";
import { OrderPrintContext } from "@core/usecases/template/macro-replacers/order-document.macro-replacer";

export interface IOrderStageModalContext {
    order: IOrder;
    stages: IOrderStage[];
    plugins: IPluginBinding[];
    isSendSmsSelected: boolean;
    templates: ISms[];
    printContext: OrderPrintContext;
    completeModal: OrderViewCompleteModal;
    completeModalContext: IOrderViewCompleteModalContext;
}

type OnChangeStageCallback = (
    orig: IOrder,
    stage: IOrderStage,
    comment: string,
    bindingId?: string,
    notification?: IPluginNotificationSendDto,
) => Promise<IOrder | null>;

export class OrderStageModal extends Modal<IOrderStageModalContext> {
    private pnlMain!: Panel;
    private cbStage!: Select<IOrderStage>;
    private taComment!: TextArea;

    private chSendSms!: CheckBox;

    private pnlNotification!: Panel;
    private cbTemplate!: Select<ISms>;
    private tbPhone!: PhoneBox;
    private taMessage!: TextArea;
    private lbLength!: Label;

    private pnlDone!: Panel;
    private lblDoneTitle!: Label;
    private lblDoneDescription!: Label;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;
    private btnComplete!: Button;

    private context: IOrderStageModalContext | null = null;
    public onChange: OnChangeStageCallback | null = null;
    public onStartComplete: ((order: IOrder) => Promise<void>) | null = null;

    public constructor() {
        super("order-stage-modal", "Изменение этапа");
        this.initializeControls();
    }

    public show(context: IOrderStageModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context);
        return super.show();
    }

    protected initializeControls(): void {
        this.cbStage = new Select<IOrderStage>();
        this.cbStage.id = "order-stage.stage";
        this.cbStage.class = "mb-0.75";
        this.cbStage.label = "Выберите этап";
        this.cbStage.items = this.context?.stages ?? [];
        this.cbStage.textField = opt => opt.name;
        this.cbStage.selectedIndex = this.cbStage.items.length > 0 ? 0 : -1;
        this.cbStage.addChangedHandler(this.onStageChanged.bind(this));

        this.taComment = new TextArea();
        this.taComment.id = "order-stage.comment";
        this.taComment.label = "Комментарий к изменению этапа";
        this.taComment.text = "";
        this.taComment.class = "mb-0.75";

        this.chSendSms = new CheckBox();
        this.chSendSms.id = "order-stage.send-sms";
        this.chSendSms.text = "Отправить смс";
        this.chSendSms.value = false;
        this.chSendSms.visible = false;
        this.chSendSms.addValueChangedHandler(this.changeSendSmsStatus.bind(this));
        this.chSendSms.class = "mb-0.5";

        //

        this.cbTemplate = new Select<ISms>();
        this.cbTemplate.id = "order-stage.template";
        this.cbTemplate.label = "Шаблон";
        this.cbTemplate.items = this.context?.templates ?? [];
        this.cbTemplate.textField = opt => opt.name;
        this.cbTemplate.selectedIndex = this.cbTemplate.items.length > 0 ? 0 : -1;
        this.cbTemplate.addChangedHandler(this.onTemplateChanged.bind(this));
        this.cbTemplate.class = "mb-0.5";

        this.tbPhone = new PhoneBox();
        this.tbPhone.id = "order-stage.phone";
        this.tbPhone.label = "Телефон";
        this.tbPhone.validation = "required";
        this.tbPhone.class = "mb-0.5";

        this.taMessage = new TextArea();
        this.taMessage.id = "order-stage.message";
        this.taMessage.label = "Сообщение";
        this.taMessage.validation = "required";
        this.taMessage.addTextChangedHandler(this.changeMessage.bind(this));

        this.lbLength = new Label();
        this.lbLength.text = "";
        this.lbLength.class = "text-sm text-right -mt-0.5";

        this.pnlNotification = new Panel();
        this.pnlNotification.addControls([this.cbTemplate, this.tbPhone, this.taMessage, this.lbLength]);
        this.pnlNotification.visible = false;

        this.pnlMain = new Panel();
        this.pnlMain.addControls([this.cbStage, this.taComment, this.chSendSms, this.pnlNotification]);

        //

        this.lblDoneTitle = new Label();
        this.lblDoneTitle.text = "Заявка закрыта";
        this.lblDoneTitle.class = "text-danger font-semibold mb-0.5";

        this.lblDoneDescription = new Label();
        this.lblDoneDescription.text = "Чтобы изменить этап, сначала откройте заявку в карточке заявки.";
        this.lblDoneDescription.style = "white-space: normal !important;";

        this.pnlDone = new Panel();
        this.pnlDone.addControls([this.lblDoneTitle, this.lblDoneDescription]);
        this.pnlDone.visible = false;

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "order-stage.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "order-stage.save";
        this.btnSave.text = "Изменить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.btnComplete = new Button();
        this.btnComplete.id = "order-stage.complete";
        this.btnComplete.text = "Завершить заявку";
        this.btnComplete.class = "ml-0.75";
        this.btnComplete.variant = "primary";
        this.btnComplete.addClickHandler(this.completeOrder.bind(this));
        this.btnComplete.visible = false;

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave, this.btnComplete]);
    }

    private populateControls(context: IOrderStageModalContext): void {
        const index = this.cbStage.items.findIndex(i => i.id === context.order.stage);
        if (index >= 0) {
            this.cbStage.selectedIndex = index;
        }
        const isOrderDone = context.order.done;

        this.pnlMain.visible = !isOrderDone;
        this.pnlDone.visible = isOrderDone ?? false;
        this.btnSave.disabled = isOrderDone ?? false;

        this.chSendSms.value = (this.context?.isSendSmsSelected ?? false) && this.getFirstNotificationPlugin() !== null;
        this.pnlNotification.visible = false;
    }

    public get controls(): Control[] {
        return [this.pnlMain, this.pnlDone, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.onChange || !this.cbStage.selectedItem) {
            return;
        }

        // если заявка закрыта, этап не меняем
        if (this.context.order.done) {
            return;
        }

        // если этап не изменился, просто закрываем
        if (this.cbStage.selectedItem.id === this.context.order.stage) {
            return this.clickCancel(sender, e);
        }

        const comment = this.taComment.text;
        const stage = this.cbStage.selectedItem;
        const notification = this.chSendSms.value ? { to: this.tbPhone.text, message: this.taMessage.text } : undefined;
        const bindingId = this.getFirstNotificationPlugin()?.id;
        const result = await this.onChange(this.context.order, stage, comment, bindingId, notification);

        if (result) {
            this.hide(result);
        }
    }

    private async completeOrder(): Promise<void> {
        if (!this.context) {
            return;
        }

        this.hide();
        this.context.completeModal.show(this.context.completeModalContext);
    }

    private onStageChanged(sender: any, e: ComboBoxChangedEventArgs<IOrderStage>): void {
        const isOrderDone = this.context?.order.done;
        if (!isOrderDone && e.item?.type === OrderStageType.Closed) {
            this.btnSave.text = "Изменить только этап";
            this.btnSave.variant = "outline-primary";
            this.btnComplete.visible = true;
        } else {
            this.btnSave.text = "Изменить";
            this.btnSave.variant = "primary";
            this.btnComplete.visible = false;
        }

        if (e.item?.type === OrderStageType.Ready && this.isStageChanged()) {
            this.onStageTypeReady();
        } else {
            this.chSendSms.visible = false;
            this.chSendSms.value = false;
            this.pnlNotification.visible = false;
        }

        if (this.context) {
            this.pnlMain.visible = !isOrderDone;
            this.pnlDone.visible = isOrderDone ?? false;
            this.btnSave.disabled = isOrderDone ?? false;
        }
    }

    private onStageTypeReady(): void {
        if (!this.context || !this.getFirstNotificationPlugin() || this.context.templates.length === 0) {
            this.chSendSms.visible = false;
            return;
        }

        this.chSendSms.visible = true;
        this.tbPhone.text = this.context.order.clientRef?.info.contacts.phone ?? "";

        const template = this.cbTemplate.selectedItem
            ? Printer.replaceOrderDocumentsGeneralMacros(
                  this.cbTemplate.selectedItem.template,
                  this.context.printContext,
              )
            : "";
        this.taMessage.text = template;

        // First change to ready type - set value from settings
        if (this.context.isSendSmsSelected) {
            this.chSendSms.value = true;
            this.pnlNotification.visible = true;
            this.context.isSendSmsSelected = false;
            this.btnSave.text = "Отправить и сохранить";
        }
    }

    private getFirstNotificationPlugin(): IPluginBinding | null {
        const plugins = this.context?.plugins;
        if (!plugins) {
            return null;
        }

        for (const plugin of plugins) {
            if (plugin.type === PluginType.Sms && plugin.enabled) {
                return plugin;
            }
        }

        return null;
    }

    private isStageChanged(): boolean {
        if (!this.context || !this.cbStage.selectedItem) {
            return false;
        }

        return this.cbStage.selectedItem.id !== this.context.order.stage;
    }

    private changeSendSmsStatus(sender: any, e: CheckBoxValueChangedEventArgs): void {
        this.pnlNotification.visible = e.value;

        if (e.value && this.cbStage.selectedItem?.type === OrderStageType.Ready && this.isStageChanged()) {
            this.btnSave.text = "Отправить и сохранить";
        } else {
            this.btnSave.text = "Изменить";
        }
    }

    private changeMessage(sender: any, e: any): void {
        this.lbLength.text = `Символов: ${this.taMessage.text.length}`;
    }

    private onTemplateChanged(sender: any, e: ComboBoxChangedEventArgs<ISms>): void {
        if (!this.context) {
            return;
        }

        const template = e.item
            ? Printer.replaceOrderDocumentsGeneralMacros(e.item.template, this.context.printContext)
            : "";
        this.taMessage.text = template;
    }
}
