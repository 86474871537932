import { FieldType, IDiscount, IOrderTypeField, ISaleInfoChangeDto, IShop } from "@lib";
import { DiscountBoxFieldControl } from "@core/types/field-controls/custom/discount-box.field-control";
import { FieldControl } from "@core/types/field-controls/field-control";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { DiscountBox } from "@core/controls/discount-box";

export class SaleBlockInfoHandler {
    public controls: FieldControl[] = [];

    public onDiscountChanged: ((discount: IDiscount) => void) | null = null;

    private shop: IShop | null = null;

    public get icon(): string {
        return "InfoIcon";
    }

    public get title(): string {
        return "Информация";
    }

    public init(shop: IShop | null): void {
        this.shop = shop;

        if (!this.shop) {
            return;
        }

        this.initControls();
    }

    private initControls(): void {
        this.controls = [];

        const context = {
            locale: this.shop?.info?.locale,
        };

        for (const field of this.getFields()) {
            const fieldControl =
                field.id === "discount"
                    ? new DiscountBoxFieldControl(field, context)
                    : FieldControlFactory.fromField(field, context);

            this.controls.push(fieldControl);

            if (field.id === "discount" && this.onDiscountChanged) {
                const onDiscountChanged = this.onDiscountChanged;
                (fieldControl.control as DiscountBox).addValueChangedHandler((s, e) => onDiscountChanged(e.value));
            }
        }
    }

    private getFields(): IOrderTypeField[] {
        return [
            {
                id: "discount",
                title: "Скидка",
                sequence: 10,
            },
            {
                id: "comment",
                type: FieldType.Text,
                title: "Комментарий",
                sequence: 20,
            },
        ];
    }

    public getInfoDto(): ISaleInfoChangeDto {
        const dto: ISaleInfoChangeDto = {};

        for (const fieldControl of this.controls) {
            this.populateStandartFieldValue(dto, fieldControl.field, fieldControl.value);
        }

        return dto;
    }

    public getCustomDto(): any {
        const dto: any = {};

        for (const fieldControl of this.controls) {
            this.populateCustomFieldValue(dto, fieldControl.field, fieldControl.value);
        }

        return dto;
    }

    private populateCustomFieldValue(dto: any, field: IOrderTypeField, value: any): void {
        if (!field.custom) {
            return;
        }

        const id = field.customId ?? field.id;
        dto[id] = value;
    }

    private populateStandartFieldValue(dto: ISaleInfoChangeDto, field: IOrderTypeField, value: any): void {
        if (field.custom) {
            return;
        }

        switch (field.id) {
            // case "name":
            //     info.name = value;
            //     return;
            // case "description":
            //     info.description = value;
            //     return;
            case "createDate":
                dto.createDate = value;
                return;
            // case "deadline":
            //     info.deadline = value;
            //     return;
            case "discount":
                dto.discount = value;
                return;
            case "comment":
                dto.comment = value;
                return;
        }
    }

    public clean(): void {
        this.initControls();
    }
}
