import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BCard, BForm, BFormInput, BInputGroup, BFormGroup, BButton } from "bootstrap-vue";
import { Validator, ValidationObserver, ValidationProvider } from "vee-validate";
import { ICompany, IUser } from "@lib";

Validator.extend("money-min", {
    getMessage(field: any, params: any, data: any) {
        return (data && data.message) ?? "Неверное значение.";
    },
    validate(value: string, params: string[]) {
        if (params.length === 0) {
            throw new Error("Неверный параметр валидации");
        }

        const min = params[0];

        const money = parseFloat(value.replace(",", "."));
        const moneyMin = parseFloat(min.replace(",", "."));

        const result = money >= moneyMin;
        return {
            valid: result,
            data: { message: result ? "" : `Сумма не может быть меньше ${min}` },
        };
    },
});

@Component({
    components: {
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BButton,
        ValidationObserver,
        ValidationProvider,
    },
})
export default class CloudPaymentsForm extends Vue {
    @Prop({ type: String, required: true })
    private publicId!: string;

    @Prop({ type: String, required: true })
    private description!: string;

    @Prop({ type: String, default: "1000" })
    private amountDefault!: string;

    @Prop({ type: Number, default: 0 })
    private amountMin!: number;

    @Prop({ type: Object, required: true })
    private company!: ICompany;

    @Prop({ type: Object, required: true })
    private user!: IUser;

    private amount = this.amountDefault;

    @Watch("amountDefault")
    private onAmountChanged(value: string): void {
        this.amount = value;
    }

    private async pay(): Promise<void> {
        const valid = await (this.$refs["cloud-payment-form-validator"] as any).validate();
        if (!valid) {
            return;
        }

        const amount = parseFloat(this.amount.replace(",", "."));

        const widget = new (window as any).cp.CloudPayments();

        widget.pay(
            "charge", // auth или 'charge'
            {
                //options
                publicId: this.publicId, //id из личного кабинета
                description: this.description, //назначение
                amount: amount, //сумма
                currency: "RUB", //валюта
                accountId: this.company.id, //идентификатор плательщика (необязательно)
                //invoiceId: "1234567", //номер заказа  (необязательно)
                //email: "user@example.com", //email плательщика (необязательно)
                //skin: "mini", //дизайн виджета (необязательно)
                //autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
                data: {
                    companyId: this.company.id,
                    userId: this.user.id,
                    name: this.user.info.name,
                },
                // configuration: {
                //     common: {
                //         successRedirectUrl: "https://{ваш сайт}/success", // адреса для перенаправления
                //         failRedirectUrl: "https://{ваш сайт}/fail", // при оплате по Tinkoff Pay
                //     },
                // },
                // payer: {
                //     firstName: "Тест",
                //     lastName: "Тестов",
                //     middleName: "Тестович",
                //     birth: "1955-02-24",
                //     address: "тестовый проезд дом тест",
                //     street: "Lenina",
                //     city: "MO",
                //     country: "RU",
                //     phone: "123",
                //     postcode: "345",
                // },
            },
            {
                onSuccess: async (options: any) => {
                    // действие при успешной оплате
                    // console.log(options);
                    await this.$info.loadAuthInfo();
                    this.$info.updateBalance();
                    this.$emit("success");
                },
                onFail: (reason: any, options: any) => {
                    // действие при неуспешной оплате
                    // eslint-disable-next-line
                    console.warn(reason);
                    this.$alt.toast.error(`Не удалось осуществить оплату: ${reason}`);
                },
                // onComplete: (paymentResult: any, options: any) => {
                //     //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                //     //например вызов вашей аналитики
                //     // console.log("paymentResult");
                //     // console.log(paymentResult);
                //     // {
                //     //     "success": true,
                //     //     "message": null,
                //     //     "code": "0",
                //     //     "email": ""
                //     // }
                //     // console.log("options");
                //     // console.log(options);
                //     // {
                //     //     "publicId": "pk_3c3ccb7bc797899830659e01767ec",
                //     //     "description": "Оплата доступа Alt SC",
                //     //     "amount": 10,
                //     //     "currency": "RUB",
                //     //     "data": {
                //     //         "company": "610466d96682d33160d3dd5d"
                //     //     },
                //     //     "payer": {
                //     //         "firstName": "Тест",
                //     //         "lastName": "Тестов",
                //     //         "middleName": "Тестович",
                //     //         "birth": "1955-02-24",
                //     //         "address": "тестовый проезд дом тест",
                //     //         "street": "Lenina",
                //     //         "city": "MO",
                //     //         "country": "RU",
                //     //         "phone": "123",
                //     //         "postcode": "345"
                //     //     }
                //     // }
                // },
            },
        );
    }
}
