









import { Vue, Component, Prop } from "vue-property-decorator";
import VaCard from "@/components/common/va-card";
import { BetaFeatureControl } from "./beta-feature-control";
import { CheckBox } from "@/@core/components/alt-ui/controls";
import { CheckBoxComponent } from "@core/components/alt-ui/controls/check-box.component";
import { BBadge } from "bootstrap-vue";

@Component({
    components: {
        VaCard,
        BBadge,
        CheckBoxComponent,
    },
})
export default class BetaFeatureControlComponent extends Vue {
    @Prop({ type: Object })
    private handler!: BetaFeatureControl;

    get checkBoxControl() {
        const control = new CheckBox();

        control.id = `${this.handler.id}.check-box`;
        control.value = this.handler.value;
        control.text = this.handler.checkBoxText;
        control.addValueChangedHandler(event => {
            this.handler.value = event.value;

            this.handler.notifyChangedHandler();
        });

        return control;
    }
}
