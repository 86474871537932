/** Валюта. */
export enum Currency {
    /** Российский рубль */
    RUB = "RUB",

    /** Белорусский рубль */
    BYN = "BYN",

    /** Украинская гривна */
    UAH = "UAH",

    /** Казахстанский тенге */
    KZT = "KZT",

    /** Грузинский лари */
    GEL = "GEL",

    /** Армянский драм */
    AMD = "AMD",

    /** Азербайджанский манат */
    AZN = "AZN",

    /** Киргизский сом */
    KGS = "KGS",

    /** Таджикский сомони */
    TJS = "TJS",

    /** Туркменский манат */
    TMT = "TMT",

    /** Узбекский сум */
    UZS = "UZS",

    /** Молдавский лей */
    MDL = "MDL",

    /** Доллар США */
    USD = "USD",

    /** Евро ЕС */
    EUR = "EUR",
}
