import { IAccount, IGood, IStore } from "@lib";
import {
    CheckBox,
    CheckBoxValueChangedEventArgs,
    ComboBoxChangedEventArgs,
    Control,
    IconPackage,
    Panel,
    Select,
    TextArea,
    TextBox,
} from "@core/components/alt-ui/controls";
import { WarrantyControl } from "@core/controls/warranty.control";
import { Footer } from "@core/controls/footer.control";
import { WarrantyBox } from "@core/controls/warranty-box";
import { EventHandler } from "@core/components/alt-ui";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { ISaleGoodItem } from "./sale-create-goods";
import { SaleGoodModalSettings } from "@/utils/plugins/settings.plugin";
import { DelayedOperation } from "@/utils/delayed-operation";
import { StringUtils } from "@/utils/string.utils";

export interface ISaleGoodModalContext {
    accounts: IAccount[];
    stores: IStore[];
    settings?: SaleGoodModalSettings;
    searchGoods: (search: string) => Promise<IGood[]>;
}

export class SaleGoodModal extends Modal2<ISaleGoodModalContext> {
    private cbGoodSearch!: Select<IGood>;

    private tbSku!: TextBox;
    private tbName!: TextBox;
    private taDescription!: TextArea;
    private tbCost!: TextBox;
    private tbPrice!: TextBox;
    private tbQuantity!: TextBox;
    private wbWarranty!: WarrantyBox;
    private wcWarranty!: WarrantyControl;
    private chAccountWidthdrawal!: CheckBox;
    private cbAccount!: Select<IAccount>;
    private chStoreAddition!: CheckBox;
    private cbStore!: Select<IStore>;

    private pnlGetInfo!: Panel;
    private pnlCreateInfo!: Panel;

    private ftFooter!: Footer;

    private context: ISaleGoodModalContext | null = null;

    public onGetGoodFromStore: ((dto: ISaleGoodItem, orig: IGood) => Promise<boolean>) | null = null;
    public onCreateNewGood: ((dto: ISaleGoodItem) => Promise<boolean>) | null = null;

    private changeEnableAccountWithdrawalBound: EventHandler<CheckBoxValueChangedEventArgs> =
        this.changeEnableAccountWithdrawal.bind(this);
    private changeEnableStoreAdditionBound: EventHandler<CheckBoxValueChangedEventArgs> =
        this.changeEnableStoreAddition.bind(this);

    public constructor() {
        super("sale-create-good-modal", "Добавить товар");
        this.initializeControls();
    }

    public show(context: ISaleGoodModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        this.cbGoodSearch = new Select<IGood>();
        this.cbGoodSearch.id = `${this.id}.good`;
        this.cbGoodSearch.label = "Искать на складе";
        this.cbGoodSearch.items = [];
        this.cbGoodSearch.iconPackage = IconPackage.Feater;
        this.cbGoodSearch.icon = "SearchIcon";
        this.cbGoodSearch.selectedIndex = this.cbGoodSearch.items.length > 0 ? 0 : -1;
        this.cbGoodSearch.clearable = true;
        this.cbGoodSearch.textField = e => e.info.name;
        this.cbGoodSearch.descriptionField = this.goodDescriptionField.bind(this);
        this.cbGoodSearch.search = this.searchGood.bind(this);
        this.cbGoodSearch.addChangedHandler(this.selectGood.bind(this));
        this.cbGoodSearch.class = "mb-0.5";

        this.tbSku = new TextBox();
        this.tbSku.id = `${this.id}.sku`;
        this.tbSku.label = "Артикул";
        this.tbSku.class = "mb-0.5";

        this.tbName = new TextBox();
        this.tbName.id = `${this.id}.name`;
        this.tbName.label = "Наименование";
        this.tbName.validation = "required";
        this.tbName.class = "mb-0.5";

        this.taDescription = new TextArea();
        this.taDescription.id = `${this.id}.description`;
        this.taDescription.label = "Описание";
        this.taDescription.class = "mb-0.5";

        this.tbCost = new TextBox();
        this.tbCost.id = `${this.id}.cost`;
        this.tbCost.label = "Себестоимость";
        this.tbCost.validation = "required|money|unsigned";
        this.tbCost.class = "mb-0.5";

        this.tbPrice = new TextBox();
        this.tbPrice.id = `${this.id}.price`;
        this.tbPrice.label = "Цена продажи";
        this.tbPrice.validation = "required|money|unsigned";
        this.tbPrice.class = "mb-0.5";

        this.tbQuantity = new TextBox();
        this.tbQuantity.id = `${this.id}.quantity`;
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = "required|numeric|unsigned";
        this.tbQuantity.class = "mb-0.5";

        this.wbWarranty = new WarrantyBox();
        this.wbWarranty.id = `${this.id}.warranty-get`;
        this.wbWarranty.label = "Гарантия";
        this.wbWarranty.class = "mb-0.5";

        this.wcWarranty = new WarrantyControl();
        this.wcWarranty.id = `${this.id}.warranty-create`;
        this.wcWarranty.label = "Гарантия";
        this.wcWarranty.class = "mb-0.5";

        const accountWidthdrawalChecked =
            this.context?.settings?.accountWidthdrawal !== undefined ? this.context.settings.accountWidthdrawal : true;

        const selectedAccountId = this.context?.settings?.accountId;
        const selectedAccountIndex = this.context?.accounts?.findIndex(a => a.id === selectedAccountId) ?? -1;
        const selectedAccountIndexDefault = (this.context?.accounts ?? []).length > 0 ? 0 : -1;

        this.cbAccount = new Select<IAccount>();
        this.cbAccount.id = `${this.id}.account`;
        this.cbAccount.label = "Счёт";
        this.cbAccount.items = this.context?.accounts ?? [];
        this.cbAccount.textField = ac => ac.info.name;
        this.cbAccount.descriptionField = ac => ac.info.description;
        this.cbAccount.selectedIndex = selectedAccountIndex >= 0 ? selectedAccountIndex : selectedAccountIndexDefault;
        this.cbAccount.visible = accountWidthdrawalChecked;
        this.cbAccount.class = "mb-0.5";

        this.chAccountWidthdrawal = new CheckBox();
        this.chAccountWidthdrawal.id = `${this.id}.account-withdrawal`;
        this.chAccountWidthdrawal.text = "Вычесть со счёта";
        this.chAccountWidthdrawal.class = "mb-0.5 mt-0.5";
        this.chAccountWidthdrawal.addValueChangedHandler(this.changeEnableAccountWithdrawalBound);
        this.chAccountWidthdrawal.value = accountWidthdrawalChecked;

        const storeAdditionChecked =
            this.context?.settings?.storeAddition !== undefined ? this.context.settings.storeAddition : false;

        const selectedStoreId = this.context?.settings?.storeId;
        const selectedStoreIndex = this.context?.stores?.findIndex(a => a.id === selectedStoreId) ?? -1;
        const selectedStoreIndexDefault = (this.context?.stores ?? []).length > 0 ? 0 : -1;

        this.cbStore = new Select<IStore>();
        this.cbStore.id = `${this.id}.store`;
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = ac => ac.info.name;
        this.cbStore.descriptionField = ac => ac.info.description;
        this.cbStore.selectedIndex = selectedStoreIndex >= 0 ? selectedStoreIndex : selectedStoreIndexDefault;
        this.cbStore.visible = storeAdditionChecked;
        this.cbStore.class = "mb-0.5";

        this.chStoreAddition = new CheckBox();
        this.chStoreAddition.id = `${this.id}.store-addition`;
        this.chStoreAddition.text = "Добавить на склад";
        this.chStoreAddition.class = "mb-0.5 mt-0.5";
        this.chStoreAddition.addValueChangedHandler(this.changeEnableStoreAdditionBound);
        this.chStoreAddition.value = storeAdditionChecked;

        //

        this.pnlCreateInfo = new Panel();
        this.pnlCreateInfo.addControls([
            this.tbSku,
            this.tbName,
            this.taDescription,
            this.tbCost,
            this.tbPrice,
            this.tbQuantity,
            this.wcWarranty,
            this.chAccountWidthdrawal,
            this.cbAccount,
            this.chStoreAddition,
            this.cbStore,
        ]);

        this.pnlGetInfo = new Panel();
        this.pnlGetInfo.addControls([this.tbCost, this.tbPrice, this.tbQuantity, this.wbWarranty]);
        this.pnlGetInfo.visible = false;

        this.ftFooter = new Footer({
            okText: "Добавить",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    public get footer(): Control {
        return this.ftFooter;
    }

    public get controls(): Control[] {
        return [this.cbGoodSearch, this.pnlCreateInfo, this.pnlGetInfo];
    }

    private searchGood(search: string): void {
        if (!this.context) {
            return;
        }

        const searchGoods = this.context.searchGoods;
        search = search.trim();

        // начинать поиск от 2 символов
        if (search.length < 2) {
            this.cbGoodSearch.items = [];
            return;
        }

        DelayedOperation.invoke("search-goods", 700, async () => {
            this.cbGoodSearch.items = await searchGoods(search);
        });
    }

    private async selectGood(sender: any, e: ComboBoxChangedEventArgs<IGood>): Promise<void> {
        if (e.item) {
            this.showGetPanel(e.item);
        } else {
            this.showCreatePanel();
        }
    }

    private showCreatePanel(): void {
        this.pnlCreateInfo.visible = true;
        this.pnlGetInfo.visible = false;

        this.tbCost.text = "";
        this.tbCost.disabled = false;
        this.tbPrice.text = "";
        this.tbQuantity.label = "Количество";
        this.tbQuantity.validation = "required|numeric|unsigned";
        this.tbQuantity.text = "";
    }

    private showGetPanel(good: IGood): void {
        this.pnlCreateInfo.visible = false;
        this.pnlGetInfo.visible = true;

        const locale = this.cbStore.selectedItem?.info?.locale;
        const cost = good.info.cost ?? 0.0;
        const price = good.info.price ?? 0.0;
        const quantity = good.info.quantity ?? 0;

        this.tbCost.text = StringUtils.moneyNumberToString(cost, locale);
        this.tbCost.disabled = true;
        this.tbPrice.text = StringUtils.moneyNumberToString(price, locale);
        this.tbQuantity.label = `Количество (макс: ${quantity})`;
        this.tbQuantity.validation = `required|numeric|unsigned|min_value:1|max_value:${quantity}`;
        this.tbQuantity.text = "1";
        this.wbWarranty.value = good.info.warranty;
    }

    private goodDescriptionField(good: IGood): string {
        const store = this.getGoodStore(good);
        if (!store) {
            return `${good.info.quantity} шт.`;
        }

        return `${store.info.name} (${good.info.quantity} шт.)`;
    }

    private getGoodStore(good: IGood): IStore | undefined {
        return typeof good.store === "string" ? this.context?.stores.find(s => s.id === good.store) : good.store;
    }

    private changeEnableAccountWithdrawal(sender: any, e: CheckBoxValueChangedEventArgs): void {
        this.cbAccount.visible = e.value;
    }

    private changeEnableStoreAddition(sender: any, e: CheckBoxValueChangedEventArgs): void {
        this.cbStore.visible = e.value;
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const result = await this.getSaveResult();
        if (result) {
            this.hide(result);
        }
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async getSaveResult(): Promise<boolean> {
        if (this.cbGoodSearch.selectedItem) {
            return await this.getGood();
        }

        return await this.createGood();
    }

    private async createGood(): Promise<boolean> {
        if (!this.onCreateNewGood) {
            return false;
        }

        const dto: ISaleGoodItem = {
            sku: this.tbSku.text,
            name: this.tbName.text,
            description: this.taDescription.text,
            cost: StringUtils.moneyStringToNumber(this.tbCost.text),
            price: StringUtils.moneyStringToNumber(this.tbPrice.text),
            quantity: StringUtils.integerStringToNumber(this.tbQuantity.text),
            warranty: this.wcWarranty.value,
        };

        if (this.chAccountWidthdrawal.value && this.cbAccount.selectedItem) {
            dto.accountId = this.cbAccount.selectedItem.id;
        }

        if (this.chStoreAddition.value && this.cbStore.selectedItem) {
            dto.storeId = this.cbStore.selectedItem.id;
        }

        return await this.onCreateNewGood(dto);
    }

    private async getGood(): Promise<boolean> {
        if (!this.onGetGoodFromStore) {
            return false;
        }

        if (!this.cbStore.selectedItem || !this.cbGoodSearch.selectedItem) {
            return false;
        }

        const orig = this.cbGoodSearch.selectedItem;

        const dto: ISaleGoodItem = {
            sku: orig.info.sku,
            name: orig.info.name,
            description: orig.info.description,
            cost: orig.info.cost,
            price: StringUtils.moneyStringToNumber(this.tbPrice.text),
            quantity: StringUtils.integerStringToNumber(this.tbQuantity.text),
            warranty: this.wbWarranty.value,

            storeId: orig.store,
            goodId: orig.id,
            goodRef: orig,
        };

        return await this.onGetGoodFromStore(dto, orig);
    }
}
