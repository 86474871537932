import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Composite } from "./composite";

@Component({
    name: "composite-component",
    components: {
        ControlComponent: () => import("../controls/control.component"),
    },
})
export class CompositeComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Composite;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={`w-100 ${this.handler.class}`} style={this.handler.style}>
                    {this.renderParts()}
                </div>

                // <div class="alt-composite w-full" v-if="type === 'composite' && elements.length > 0">
                //     <div class="flex">
                //         <label for="" class="va-input--label" v-if="!noLabel">{{ title }}</label>
                //         <feather-icon
                //             icon="HelpCircleIcon"
                //             class="w-1 cursor-pointer text-grey hover:text-primary ml-0.5"
                //             v-b-tooltip.hover.left="description"
                //             v-if="description.length > 0"
                //         />
                //     </div>
                //     <div class="flex">
                //         <va-auto-control
                //             v-for="(element, index) of elements"
                //             :key="element.id"
                //             class="w-full mr-0.5"
                //             no-label
                //             :disabled="disabled || element.disabled"
                //             :control="element"
                //             v-model="valueEx[index]"
                //             @input="onInputElement($event, index)"
                //         ></va-auto-control>
                //     </div>
                // </div>
            );
        } else {
            return <div />;
        }
    }

    private renderParts(): VNode[] {
        const items: VNode[] = [];

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        items.push(this.renderElements());

        //items.push(<small class="text-danger">{errors[0]}</small>);
        return items;
    }

    private renderElements(): VNode {
        const controls: VNode[] = [];
        for (const control of this.handler.elements) {
            let cl = "w-100";
            if (controls.length > 0) {
                cl += " ml-0.25";
            }
            controls.push(<control-component class={cl} handler={control} />);
        }

        return <div class="flex">{controls}</div>;
    }
}
