import { IOrderWorkUseCase, IOrder } from "@lib";
import { IOrderWorkCreateDto, IOrderWorkUpdateDto } from "@lib";
import { DataLoadException } from "@/exceptions";
import { Api } from "../axios";

export class OrderWorkUseCase implements IOrderWorkUseCase {
    public async create(
        companyId: string,
        officeId: string,
        orderId: string,
        dto: IOrderWorkCreateDto,
    ): Promise<IOrder> {
        try {
            const response = await Api.post<IOrder>(
                `/companies/${companyId}/offices/${officeId}/orders/${orderId}/works`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(
        companyId: string,
        officeId: string,
        orderId: string,
        workId: string,
        dto: IOrderWorkUpdateDto,
    ): Promise<IOrder> {
        try {
            const response = await Api.put<IOrder>(
                `/companies/${companyId}/offices/${officeId}/orders/${orderId}/works/${workId}`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, officeId: string, orderId: string, workId: string): Promise<IOrder> {
        try {
            const response = await Api.delete<IOrder>(
                `/companies/${companyId}/offices/${officeId}/orders/${orderId}/works/${workId}`,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
