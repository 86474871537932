import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Label } from "./label";

@Component({
    name: "label-component",
})
export class LabelComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Label;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div>
                    <label id={this.handler.id} class={`text-nowrap ${this.handler.class}`} style={this.handler.style}>
                        {this.handler.text}
                    </label>
                </div>
            );
        } else {
            return <div />;
        }
    }
}
